import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Notification } from '@setapp/ui-kit';

import NotificationNeedHelpLink from '../notification-need-help-link/notification-need-help-link';

const ContactFamilyOwnerNotification = () => (
  <Notification type="danger">
    <FormattedMessage
      id="familyMemberSubscriptionInfo.statusNotifications.paymentFailed"
      defaultMessage="Looks like family owner got some payment issues, please contact him. You can leave family and pay by yourself if you want."
    />
    <NotificationNeedHelpLink />
  </Notification>
);

export default ContactFamilyOwnerNotification;
