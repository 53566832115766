export function base64ToArrayBuffer(b64: string): Uint8Array {
  return Uint8Array.from(atob(b64), (c) => c.charCodeAt(0));
}

export function encryptToSHA256String(str: string) {
  const buffer = new TextEncoder().encode(str);

  return window.crypto.subtle.digest('SHA-256', buffer)
    .then((hashBuffer) => Array.from(new Uint8Array(hashBuffer)))
    .then((hashArray) => hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''));
}

export async function verifySignature(publicKey: Uint8Array, signature: Uint8Array, payload: Uint8Array) {
  const algo = 'RSASSA-PKCS1-v1_5';
  const keyFormat = 'spki';

  const cryptoKey = await window.crypto.subtle.importKey(
    keyFormat,
    publicKey,
    { name: algo, hash: 'SHA-256' },
    false,
    ['verify'],
  );

  return window.crypto.subtle.verify(
    algo,
    cryptoKey,
    signature,
    payload,
  );
}
