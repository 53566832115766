import * as Sentry from '@sentry/react';
import type { SeverityLevel, BrowserOptions } from '@sentry/react';

type Tags = {
  tags: {
    [key: string]: string;
  };
}

type Config = {
  sentry: BrowserOptions & Tags;
};

type User = {
  [key: string]: unknown;
  id?: string;
  'ip_address'?: string;
  email?: string;
  username?: string;
};

type Context = {
  [key: string]: unknown;
};

const Logger = {
  initialize(config: Config) {
    const { tags, ...initOptions } = config.sentry;
    Sentry.init(initOptions);
    Sentry.setTags(tags);
  },

  setUserData({ id }: User) {
    Sentry.setUser({ id });
  },

  unsetUserData() {
    Sentry.setUser(null);
  },

  setContext(context: Context) {
    Sentry.setExtras(context);
  },

  /**
   * @param {String} message error message
   * @param {Object|String|Array} [details] error details
   */
  logError(message: string, details?: unknown) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(message, details);
    }

    Sentry.withScope((scope) => {
      if (details) {
        scope.setExtras({ details });
      }

      Sentry.captureMessage(message, 'error' as SeverityLevel);
    });
  },

  /**
   * Use this method for the cases when it's not required to report an issue to Sentry
   * but it's worth to write something to the console
   */
  logWarn(message: string, details?: { [key: string]: unknown }) {
    // eslint-disable-next-line no-console
    console.error(message, details ?? '');
  },

  captureException(error: Error) {
    Sentry.captureException(error);
  },
};

export default Logger;
