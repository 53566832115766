import RequestError from '@setapp/request-error';

import type { GiftCard, GiftCardType } from './gift-cards-reducer';

export const CARD_TYPES_REQUEST = 'GIFT_CARDS_TYPES_REQUEST';
export const CARD_TYPES_REQUEST_SUCCESS = 'GIFT_CARDS_TYPES_REQUEST_SUCCESS';
export const CARD_TYPES_REQUEST_ERROR = 'GIFT_CARDS_TYPES_REQUEST_ERROR';

export type FetchGiftCardTypesAction = {
  type: 'GIFT_CARDS_TYPES_REQUEST';
};
export type FetchGiftCardTypesSuccessAction = {
  type: 'GIFT_CARDS_TYPES_REQUEST_SUCCESS';
  payload: GiftCardType;
};
export type FetchGiftCardTypesErrorAction = {
  type: 'GIFT_CARDS_TYPES_REQUEST_ERROR';
  payload: RequestError;
};

export type GiftCardTypesAction =
  | FetchGiftCardTypesAction
  | FetchGiftCardTypesSuccessAction
  | FetchGiftCardTypesErrorAction;

export const GIFT_CARDS_REQUEST = 'GIFT_CARDS_REQUEST';
export const GIFT_CARDS_REQUEST_SUCCESS = 'GIFT_CARDS_REQUEST_SUCCESS';
export const GIFT_CARDS_REQUEST_ERROR = 'GIFT_CARDS_REQUEST_ERROR';
export const GIFT_CARDS_UPDATE_ITEM = 'GIFT_CARDS_UPDATE_ITEM';

export type FetchGiftCardsAction = {
  type: 'GIFT_CARDS_REQUEST';
};
export type FetchGiftCardsSuccessAction = {
  type: 'GIFT_CARDS_REQUEST_SUCCESS';
  payload?: GiftCard;
};
export type FetchGiftCardsErrorAction = {
  type: 'GIFT_CARDS_REQUEST_ERROR';
  payload: RequestError;
  error: true;
};
export type UpdateGiftCardAction = {
  type: 'GIFT_CARDS_UPDATE_ITEM';
  payload: GiftCard;
};

export type GiftCardsAction =
  | FetchGiftCardsAction
  | FetchGiftCardsSuccessAction
  | FetchGiftCardsErrorAction
  | UpdateGiftCardAction;
