import React, { useMemo, useEffect, useState, MouseEvent } from 'react';
import { Button } from '@setapp/ui-kit';

import type { SupportedSocialProvider } from 'config/social';
import googleClient from 'utils/social-clients/google-client';
import appleClient from 'utils/social-clients/apple-client';

import './social-auth-button.scss';
import appleIcon from './images/apple.svg';
import googleIcon from './images/google.svg';

export type ButtonSize = 'sm' | 'md' | 'lg';

type Props = {
  providerName: SupportedSocialProvider;
  oauthState: string;
  size?: ButtonSize;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
};

function getSocialClient(providerName: SupportedSocialProvider) {
  return providerName === 'apple' ? appleClient : googleClient;
}

const SocialAuthButton = (props: Props) => {
  const {
    size = 'md',
    oauthState,
    providerName,
    ...anchorProps
  } = props;

  const [authURL, setAuthURL] = useState('');

  const socialClient = useMemo(
    () => getSocialClient(providerName),
    [providerName],
  );

  useEffect(() => {
    const url = socialClient.getAuthURL(oauthState);
    setAuthURL(url);
  }, [oauthState, socialClient]);

  const providersIcons = {
    apple: appleIcon,
    google: googleIcon,
  };

  return (
    <Button
      {...anchorProps}
      block
      tag="a"
      variant="secondary-outline"
      size={size}
      disabled={!authURL}
      href={authURL}
    >
      <img
        className="social-auth-button__icon"
        src={providersIcons[providerName]}
        alt={providerName}
        width={28}
        height={28}
      />
    </Button>
  );
};

export default SocialAuthButton;
