// @flow

import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  type IntlShape,
} from 'react-intl';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import ActivationFeatureSelect from '../activation-feature-select/activation-feature-select';
import ActivationExtraDevicesDescription from './activation-extra-devices-description';


type Props = {|
  selectedExtraDevicesCount: number,
  maxExtraDevicesCount: number,
  activeExtraDevicesCount: number,
  pricePlan: PricePlan,
  intl: IntlShape,
  onChange: SyntheticEvent<HTMLSelectElement> => mixed,
|};

const messages = defineMessages({
  selectOptionLabel: {
    id: 'activateSubscription.extraDevices.selectOptionLabel',
    defaultMessage: '{devicesCount} {devicesCount, plural, one {extra device} other {extra devices}}, {period}',
  },
  noDevicesSelectOptionLabel: {
    id: 'activateSubscription.extraDevices.noDevicesSelectOptionLabel',
    defaultMessage: 'No extra devices',
  },
  paymentCycle: {
    id: 'activateSubscription.extraDevices.paymentCycle',
    defaultMessage: '{monthsCount, plural, one {monthly} =12 {annual} other {{monthsCount} months}}',
  },
});

const ActivationExtraDevicesSelect = (props: Props) => {
  const {
    maxExtraDevicesCount,
    selectedExtraDevicesCount,
    activeExtraDevicesCount,
    pricePlan,
    intl,
    onChange,
  } = props;

  const extraDevicesOptions = [{
    value: 0,
    label: intl.formatMessage(messages.noDevicesSelectOptionLabel),
  }];

  for (let devicesCount = 1; devicesCount <= maxExtraDevicesCount; devicesCount++) {
    extraDevicesOptions.push({
      value: devicesCount,
      label: intl.formatMessage(messages.selectOptionLabel, {
        devicesCount,
        period: intl.formatMessage(messages.paymentCycle, { monthsCount: pricePlan.paidMonth }),
      }),
    });
  }

  const pricePerMonth = (pricePlan.price / pricePlan.paidMonth) * selectedExtraDevicesCount;
  const priceFormatted = selectedExtraDevicesCount !== 0
    ? (
      <FormattedMessage
        id="activateSubscription.extraDevices.price"
        defaultMessage="{price}/mo"
        values={{
          price: <FormattedPrice currency={pricePlan.currency} price={pricePerMonth} />,
        }}
      />
    ) : null;
  const helpText = (
    <span className="text_color-secondary">
      <ActivationExtraDevicesDescription
        maxExtraDevicesCount={maxExtraDevicesCount}
        selectedExtraDevicesCount={selectedExtraDevicesCount}
        activeExtraDevicesCount={activeExtraDevicesCount}
      />
    </span>
  );

  return (
    <ActivationFeatureSelect
      id="activate-devices-count"
      label={<FormattedMessage
        id="activateSubscription.extraDevices.inputLabel"
        defaultMessage="Select extra devices"
             />}
      value={selectedExtraDevicesCount}
      price={priceFormatted}
      options={extraDevicesOptions}
      helpText={helpText}
      onChange={onChange}
    />
  );
};

export { ActivationExtraDevicesSelect as PureActivationExtraDevicesSelect };

export default injectIntl(ActivationExtraDevicesSelect);
