import React, { FC } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

import AccountInfoRow from 'components/shared/account-info-row/account-info-row';

type Props = {
  highlightDate?: boolean;
  nextPaymentDate: number;
};

const NextPaymentRow: FC<Props> = ({ highlightDate = false, nextPaymentDate }) => (
  <AccountInfoRow
    title={<FormattedMessage id="subscriptionInfo.nextPayment" defaultMessage="Next payment" />}
    value={(
      <FormattedDate
        value={nextPaymentDate * 1000}
        year="numeric"
        month="short"
        day="numeric"
      />
    )}
    valueClass={highlightDate ? 'text-danger' : ''}
    qaLabel="subscriptionNextPaymentDateText"
  />
);

export default NextPaymentRow;
