import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const FullscreenLayoutSecondaryContent = ({ children }: Props) => {
  return (
    <div className="fullscreen-layout__secondary-content">
      <div className="fullscreen-layout__sticky">
        <div className="fullscreen-layout__secondary-container">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FullscreenLayoutSecondaryContent;
