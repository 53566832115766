export const apiRoot = process.env.API_ROOT;
export const apiRootV1 = `${apiRoot ?? ''}/v1`;

export const apiURL = {
  account: `${apiRootV1}/me`,
  resendConfirmationEmail: `${apiRootV1}/me/resend_confirmation_email`,
  confirmEmail: `${apiRootV1}/me/confirm_email`,
  auth: `${apiRootV1}/auth`,
  logout: `${apiRootV1}/logout`,
  deleteAccount: `${apiRootV1}/me/remove`,
  token: `${apiRootV1}/token`,
  resumeSubscription: (subscriptionId: number) => `${apiRootV1}/subscriptions/${subscriptionId}/resume`,
  cancelSubscription: (subscriptionId: number) => `${apiRootV1}/subscriptions/${subscriptionId}/cancel`,
  cancelAllSubscriptions: `${apiRootV1}/subscriptions/actions/cancel_all`,
  resumeAllSubscriptions: `${apiRootV1}/subscriptions/actions/resume_all`,
  subscriptions: `${apiRootV1}/subscriptions`,
  retryPayment: `${apiRootV1}/subscriptions/actions/retry_payment`,
  activateSubscription: `${apiRootV1}/subscriptions/actions/activate`,
  orderSeatsSubscriptions: `${apiRootV1}/subscriptions/actions/order_seats`,
  cancelSeatsSubscriptions: `${apiRootV1}/subscriptions/actions/cancel_seats`,
  invitation: `${apiRootV1}/invites`,
  paymentConfig: `${apiRootV1}/payment_form_config`,
  paddleFormConfig: `${apiRootV1}/payment_form_config/paddle`,
  payment: `${apiRootV1}/payment_info`,
  paddlePaymentDetails: `${apiRootV1}/payment_info/paddle`,
  setappPaymentDetails: `${apiRootV1}/payment_info/setapp`,
  paymentDecline: `${apiRootV1}/payment_info/declined`,
  requestPasswordReset: `${apiRootV1}/password/request`,
  restorePass: `${apiRootV1}/password/reset`,
  usersInvitations: `${apiRootV1}/me/invites`,
  crossAuthToken: `${apiRootV1}/me/cross_auth_token`,
  loginCrossAuthToken: `${apiRootV1}/login_by_access_token`,
  devices: `${apiRootV1}/devices`,
  deactivateDevicesDeferred: `${apiRootV1}/devices/disconnect_deferred`,
  deactiveAllIosDevices: `${apiRootV1}/devices/disconnect_all_ios_devices`,
  socialAuth: `${apiRootV1}/auth`,
  linkSocialAccount: `${apiRootV1}/me/social`,
  referrals: `${apiRootV1}/referral`,
  referralInfo: `${apiRootV1}/referral/check_status`,
  rewards: `${apiRootV1}/rewards`,
  rewardsOrder: `${apiRootV1}/rewards/order`,
  appsList: `${apiRootV1}/applications/popular`,
  appInfo: `${apiRootV1}/applications/`,
  pricePlans: `${apiRootV1}/price_plans`,
  aiSpecialOfferPricePlans: `${apiRootV1}/ai_special_offer_price_plans`,
  changePricePlan: (subscriptionId: number) => `${apiRootV1}/subscriptions/${subscriptionId}/next_price_plan`,
  calculateNextPricePlan: (subscriptionId: number) => `${apiRootV1}/subscriptions/${subscriptionId}/next_price_plan/calculate`,
  changeTrialPricePlan: `${apiRootV1}/subscriptions/change_trial_price_plan`,
  startTrial: `${apiRootV1}/subscriptions/start_trial`,
  invoices: `${apiRootV1}/invoices`,
  invoice: `${apiRootV1}/invoices/%{invoiceId}`,
  downloadInvoice: `${apiRootV1}/invoices/%{invoiceId}/download?token=%{token}`,
  pushIdentifier: `${apiRootV1}/me/push_identifier`,
  giftCardTypes: `${apiRootV1}/gift_card_types`,
  giftCards: `${apiRootV1}/gift_cards`,
  redeemGiftCard: `${apiRootV1}/redeem_gift_card`,
  checkRedeemCode: (redeemCode: string) => `${apiRootV1}/check_code/${redeemCode}`,
  familyPlan: `${apiRootV1}/family`,
  dropFamilyPlan: `${apiRootV1}/family/drop`,
  familyMembers: `${apiRootV1}/family/members`,
  acceptFamilyInvite: `${apiRootV1}/family/members/actions/accept_invite`,
  featureFlags: `${apiRootV1}/feature_flags`,
  oauthClient: `${apiRootV1}/oauth/client`,
  oauthOptions: `${apiRootV1}/oauth/authorization`,
  customerOauth: `${apiRootV1}/customer_auth`,
  iosApps: `${apiRootV1}/ios/applications`,
  iosAuthToken: `${apiRootV1}/ios/auth_token`,
  iosInstallInfo: `${apiRootV1}/ios/install_info`,
  mobileStoreAccessInfo: `${apiRootV1}/ios/mobile_store_access_info`,
  appleAuth: `${apiRootV1}/auth/apple`,
  appleUser: `${apiRootV1}/auth/apple/user`,
  validateEmail: `${apiRootV1}/email_validation`,
  trialCheck: `${apiRootV1}/subscriptions/check_trial`,
};
