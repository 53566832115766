import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Cookies from 'js-cookie';
import { Button } from '@setapp/ui-kit';
import externalUrls from 'config/external-urls';
import { COOKIES_ROOT_DOMAIN } from 'config/app';

import './cookie-banner.scss';

const ACCEPTED_COOKIE_NAME = 'cookieAccepted';

type State = {
  showCookieBanner: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

class CookieBanner extends PureComponent<Props, State> {
  state = {
    showCookieBanner: !Cookies.get(ACCEPTED_COOKIE_NAME),
  };

  render() {
    const { showCookieBanner } = this.state;

    if (!showCookieBanner) {
      return null;
    }

    return (
      <div className="cookie-banner" role="alert">
        <div className="cookie-banner__text text_xs mr-3">
          <FormattedMessage
            id="cookieNotification.text"
            defaultMessage="Setapp uses cookies to personalize your experience on our website. By continuing to use this site, you agree to our {cookiePolicyLink}."
            values={{
              cookiePolicyLink: (
                <a
                  className="link"
                  href={externalUrls.cookiePolicy}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage
                    id="cookieBanner.cookieLink"
                    defaultMessage="cookie policy"
                  />
                </a>
              ),
            }}
          />
        </div>

        <Button
          className="cookie-banner__button"
          variant="light"
          size="sm"
          onClick={this.onConfirmCookie}
        >
          <FormattedMessage
            id="cookieBanner.confirmBtn"
            defaultMessage="Okay"
          />
        </Button>
      </div>
    );
  }

  onConfirmCookie = () => {
    Cookies.set(ACCEPTED_COOKIE_NAME, '1', { domain: COOKIES_ROOT_DOMAIN, expires: 365 * 5 });
    this.setState({ showCookieBanner: false });
  }
}

export default CookieBanner;
