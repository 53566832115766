import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import * as subscriptionStatuses from 'state/subscription/statuses';

import { useSubscriptionContext } from 'components/pages/subscription/context/subscription-context';

import PlanRow from '../plan-row/plan-row';
import StatusRow from '../status-row/status-row';
import AiCreditsRow from '../ai-credits-row/ai-credits-row';

const SubscriptionFree: FC = () => {
  const { subscription: { expirationDate } } = useSubscriptionContext();

  return (
    <>
      <PlanRow value={<FormattedMessage id="subscriptionInfo.pricePlanFree" defaultMessage="Free" />} />
      <StatusRow status={subscriptionStatuses.FREE} expirationDate={expirationDate} />
      <AiCreditsRow />
    </>
  );
};

export default SubscriptionFree;
