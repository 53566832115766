import React, { ReactNode, useState } from 'react';
import { Button, FormCheckbox } from '@setapp/ui-kit';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import urls from 'config/external-urls';

import './social-auth-newsletter-form.scss';

type Props = {
  onSubmit: (marketingSubscribed: boolean) => void;
  isProcessing: boolean;
  genericError: ReactNode;
}

const TermsLink = () => (
  <FormattedMessage
    id="socialAuthNewsletter.terms"
    defaultMessage="Accept {termsOfUse}, {privacyPolicy}, and {cookiePolicy}."
    values={{
      termsOfUse: (
        <a
          href={urls.termsOfUse}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage
            id="socialAuthNewsletter.termsOfUse"
            defaultMessage="Terms of Use"
          />
        </a>
      ),
      privacyPolicy: (
        <a
          href={urls.privacy}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage
            id="socialAuthNewsletter.privacyPolicy"
            defaultMessage="Privacy Policy"
          />
        </a>
      ),
      cookiePolicy: (
        <a
          href={urls.cookiePolicy}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage
            id="socialAuthNewsletter.cookiePolicy"
            defaultMessage="Cookie Policy"
          />
        </a>
      ),
    }}
  />
);


const SocialAuthNewsletterForm = ({ onSubmit, isProcessing, genericError }: Props) => {
  const [subscribeChecked, setSubscribeChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);

  const handleSubscribeCheckboxChange = () => {
    setSubscribeChecked(!subscribeChecked);
  };

  const handleTermsCheckboxChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleSubmit = () => {
    if (!termsChecked) {
      return setShowTermsError(true);
    }

    setShowTermsError(false);

    return onSubmit(subscribeChecked);
  };

  const termsErrorText = (
    <FormattedMessage
      id="socialAuthNewsletter.termsError"
      defaultMessage="Please agree with policies to continue."
    />
  );

  const renderErrorOnCondition = () => {
    const errorText = showTermsError ? termsErrorText : genericError;

    if (errorText) {
      return (
        <p className="text_sm text-danger social-auth-newsletter__error-message">
          {errorText}
        </p>
      );
    }

    return null;
  };

  return (
    <div className="social-auth-newsletter">
      <h3 className="mt-0 mb-6">
        <FormattedHTMLMessage
          id="socialAuthNewsletter.title"
          defaultMessage="Please accept the terms<br/> to continue"
        />
      </h3>
      <FormCheckbox
        id="subscribe-check"
        checked={subscribeChecked}
        onChange={handleSubscribeCheckboxChange}
        className="social-auth-newsletter__subscribe-check"
      >
        <FormattedMessage
          id="socialAuthNewsletter.subscribe"
          defaultMessage="I want to get pro advice on Mac apps and exclusive member offers."
        />
      </FormCheckbox>
      <FormCheckbox
        id="accept-terms"
        checked={termsChecked}
        onChange={handleTermsCheckboxChange}
        className="social-auth-newsletter__terms-check"
      >
        <TermsLink />
      </FormCheckbox>
      {renderErrorOnCondition()}
      <Button block size="lg" onClick={handleSubmit} disabled={isProcessing}>
        <FormattedMessage
          id="socialAuthNewsletter.continue"
          defaultMessage="Continue"
        />
      </Button>
    </div>
  );
};

export default SocialAuthNewsletterForm;
