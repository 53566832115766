import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';
import externalUrls from 'config/external-urls';

import auth from 'utils/auth';
import logger from 'utils/logger';

import {
  getAppsCount,
  getPrimarySubscription,
  getUserEmail,
  isUserFamilyMember,
} from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { acceptFamilyInvite } from 'state/family-plan/family-plan-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import DefaultError from 'components/shared/default-error/default-error';

import './family-invite-page.scss';

import familyPlanImage from './images/family-plan.svg';

const mapStateToProps = (state) => ({
  primarySubscription: getPrimarySubscription(state),
  email: getUserEmail(state),
  appsCount: getAppsCount(),
  isUserFamilyMember: isUserFamilyMember(state),
});

const mapActionsToProps = {
  fetchAllSubscriptions,
  acceptFamilyInvite,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

type State = {
  showConfirmStep: boolean;
  isValidationComplete: boolean;
  inviteId: string | null;
}

type InviteInfo = {
  id: string;
  email: string;
};

class FamilyInvitePage extends PureComponent<Props, State> {
  state: State = {
    showConfirmStep: false,
    isValidationComplete: false,
    inviteId: null,
  };

  componentDidMount() {
    const { location, fetchAllSubscriptions } = this.props;
    const query = queryString.parse(location.search);
    const { invite } = query;

    if (!invite) {
      logger.logWarn('Could not find invite id in url');

      this.redirectToFamilyInviteError();
    } else {
      this.checkInvitation(invite as string);
    }

    const dataToBeFetched = [
      fetchAllSubscriptions(),
    ];

    return Promise.all(dataToBeFetched)
      .catch((error) => {
        logger.logWarn('Could not load data for user subscription', error);
      });
  }

  render() {
    const { appsCount, primarySubscription } = this.props;
    const { isValidationComplete, showConfirmStep } = this.state;

    if (!isValidationComplete || !primarySubscription) {
      return (
        <FullscreenLayoutLoading />
      );
    }

    return (
      <FullscreenLayout>
        <FullscreenLayout.PrimaryContent>
          <div className="family-invite__primary-content">
            <a
              className="family-invite__logo"
              href={externalUrls.setappSite}
            >
              <AnimatedLogo hasCaption size="sm" />
            </a>
            {showConfirmStep ? (
              <>
                <h3 className="family-invite__primary-title">
                  <FormattedMessage
                    id="familyInvitePage.primary.confirmTitle"
                    defaultMessage="Your current plan will discontinue now"
                  />
                </h3>
                <p className="mb-8">
                  <strong>
                    <FormattedMessage
                      id="familyInvitePage.primary.confirmDescription"
                      defaultMessage="This action cannot be reversed. You will lose {activeDays, plural, one {{activeDays} day} other {{activeDays} days}} still left on your current plan and any extra device seats."
                      values={{
                        activeDays: this.getActiveSubscriptionDaysLeft(),
                      }}
                    />
                  </strong>
                  {' '}
                  <FormattedMessage
                    id="familyInvitePage.primary.devicesDescription"
                    defaultMessage="On Setapp Family, you can connect and use 1 Mac and 1 iOS device."
                  />
                </p>
                <Button
                  size="lg"
                  onClick={this.acceptFamilyInvite}
                  className="mb-3"
                >
                  <FormattedMessage
                    id="familyInvitePage.confirmButton"
                    defaultMessage="That’s fine, accept invite"
                  />
                </Button>
                <Button
                  size="lg"
                  variant="secondary-outline"
                  href={urls.subscription}
                >
                  <FormattedMessage
                    id="familyInvitePage.cancelButton"
                    defaultMessage="Join later"
                  />
                </Button>
              </>
            ) : (
              <>
                <h3 className="family-invite__primary-title">
                  <FormattedMessage
                    id="familyInvitePage.primary.title"
                    defaultMessage="Your friend invites you to their Setapp Family"
                  />
                </h3>
                <p className="mb-8">
                  <FormattedMessage
                    id="familyInvitePage.primary.description"
                    defaultMessage="Use all the apps on Setapp under your friend’s Family plan!"
                  />
                </p>
                <Button
                  size="lg"
                  onClick={this.onAccentInviteClick}
                >
                  <FormattedMessage
                    id="familyInvitePage.continueButton"
                    defaultMessage="Accept invite"
                  />
                </Button>
              </>
            )}
          </div>
        </FullscreenLayout.PrimaryContent>

        <FullscreenLayout.SecondaryContent>
          <div className="family-invite__secondary-content">
            <img
              src={familyPlanImage}
              alt="Family plan"
              width="554"
              height="320"
            />
            <h4 className="family-invite__secondary-title">
              <FormattedMessage
                id="familyInvitePage.secondary.title"
                defaultMessage="Discover Setapp Family plan"
              />
            </h4>
            <p className="mb-0">
              <FormattedMessage
                id="familyInvitePage.secondary.description"
                defaultMessage="Four participants, one price. Browse, install and use {appsCount}+ apps for Mac, web and iOS whenever you need them."
                values={{ appsCount }}
              />
            </p>
          </div>
        </FullscreenLayout.SecondaryContent>
      </FullscreenLayout>
    );
  }

  getActiveSubscriptionDaysLeft = () => {
    const { primarySubscription } = this.props;

    if (primarySubscription && (primarySubscription.expirationDate || primarySubscription.nextPaymentDate)) {
      const lastActiveSubscriptionDate = primarySubscription.expirationDate || primarySubscription.nextPaymentDate || 0;
      const dateNow = Date.now() / 1000;
      const activeSubscriptionDaysLeft = (lastActiveSubscriptionDate - dateNow) / (60 * 60 * 24);

      return Math.round(activeSubscriptionDaysLeft);
    }

    return 0;
  }

  checkInvitation(invite: string) {
    return auth.validateInvitationToken(invite)
      .then((invite: InviteInfo) => {
        this.setState({
          inviteId: invite.id,
        });
        this.compareUserEmail(invite.email);
      })
      .catch(() => {
        this.redirectToFamilyInviteError();
      });
  }

  compareUserEmail(invitedEmail: string) {
    const { email } = this.props;

    if (invitedEmail !== email) {
      this.redirectToFamilyInviteError();

      return;
    }

    this.setState({
      isValidationComplete: true,
    });
  }

  redirectToFamilyInviteError = () => {
    const { history } = this.props;

    history.push(urls.familyInviteError);
  }

  onAccentInviteClick = () => {
    const { primarySubscription, isUserFamilyMember } = this.props;

    if (isUserFamilyMember) {
      this.redirectToFamilyInviteError();

      return;
    }

    const activeSubscriptionDays = this.getActiveSubscriptionDaysLeft();

    if (primarySubscription && (
      primarySubscription.status === subscriptionStatuses.ACTIVE
      || primarySubscription.status === subscriptionStatuses.CANCELLED
    ) && activeSubscriptionDays > 0) {
      this.setState({
        showConfirmStep: true,
      });
    } else {
      this.acceptFamilyInvite();
    }
  }

  acceptFamilyInvite = () => {
    const { acceptFamilyInvite, history, showDangerNotification } = this.props;
    const { inviteId } = this.state;

    if (!inviteId) {
      return logger.logError('Could not find invite id while accept family invite');
    }

    return acceptFamilyInvite(inviteId)
      .then(() => {
        history.push({
          pathname: urls.successfulRegistration,
          search: '?family=true',
        });
      })
      .catch(() => {
        showDangerNotification(
          <DefaultError />
        );
        logger.logError(`Couldn't accept family invite ${inviteId}`);
      });
  }
}

export { FamilyInvitePage as PureFamilyInvitePage };

export default connector(FamilyInvitePage);
