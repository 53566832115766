import React, { useEffect } from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useResendEmail from 'components/hooks/use-resend-email/use-resend-email';
import { fetchUser, logout } from 'state/user/user-actions';
import { getUser } from 'state/root-reducer';
import urls from 'config/urls';

import './oauth-confirm-email-content.scss';

import mailIcon from '../images/mail-icon.svg';

const OAuthConfirmEmailContent = () => {
  // User is fetched inside oauth-authentication-guard
  const { email, emailConfirmed } = useSelector(getUser);
  const { resendEmail, isProcessing } = useResendEmail();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (emailConfirmed) {
      history.push(urls.oauthAuthorize + history.location.search);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailConfirmed]);

  const handleContinue = async () => {
    await dispatch(fetchUser());

    history.push(urls.oauthAuthorize + history.location.search);
  };

  const handleUseAnotherAccount = () => {
    dispatch(logout());

    const nextLocation = `${location.pathname}${location.search}`;
    const search = new URLSearchParams({ 'redirect_url': nextLocation }).toString();

    history.push(`${urls.oauthLogin}?${search}`);
  };

  const clickHereButton = (
    <Button variant="link" className="oauth-confirm-email__click-here" onClick={handleContinue}>
      <FormattedMessage
        id="oauth.confirmEmail.continue"
        defaultMessage="Click here"
      />
    </Button>
  );

  return (
    <div className="oauth-confirm-email">
      <img src={mailIcon} width="80" height="80" alt="" />

      <h3 className="mt-6 mb-6">
        <FormattedMessage
          id="oauth.confirmEmail.title"
          defaultMessage="Please confirm your email"
        />
      </h3>

      <p className="mb-8 text_lg">
        <FormattedHTMLMessage
          id="oauth.confirmEmail.description"
          defaultMessage="We’ve emailed you a confirmation link to <b>{email}</b>"
          values={{ email }}
        />
      </p>

      <Button
        block
        variant="primary"
        size="lg"
        className="mb-4"
        onClick={() => resendEmail()}
        disabled={isProcessing}
      >
        <FormattedMessage
          id="oauth.confirmEmail.resendEmail"
          defaultMessage="Resend email"
        />
      </Button>

      <Button block variant="primary-outline" size="lg" className="mb-7" onClick={handleUseAnotherAccount}>
        <FormattedMessage
          id="oauth.confirmEmail.anotherAccount"
          defaultMessage="Use another account"
        />
      </Button>

      <p>
        <FormattedMessage
          id="oauth.confirmEmail.alreadyConfirmed"
          defaultMessage="Already confirmed? { clickHereButton } to continue and sign in."
          values={{ clickHereButton }}
        />
      </p>
    </div>
  );
};

export default OAuthConfirmEmailContent;
