import qs from 'query-string';
import { RouterProps } from 'react-router-dom';

type Location = RouterProps['history']['location'];

const getShouldOpenChangePlanModal = (location: Location) => {
  const { 'show_change_plan_modal': showChangePlanModal } = qs.parse(location.search);

  return Boolean(showChangePlanModal);
};

export default getShouldOpenChangePlanModal;
