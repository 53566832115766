import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { Discount } from 'state/subscription/subscription-initial-state';

import { FormattedPrice, FormattedPeriod, FormattedTax } from 'components/shared/formatter/formatter';

type Props = {
  tax: number;
  price: number;
  currency: string;
  paidMonth: number;
  discount?: Discount;
};

const SubscriptionPrice = ({
  price, currency, paidMonth, tax, discount,
}: Props) => {
  const priceFormatted = <FormattedPrice price={price} currency={currency} />;
  const periodFormatted = <FormattedPeriod paidMonth={paidMonth} />;

  if (tax === 0) {
    return (
      <>
        {discount ? (
          <FormattedMessage
            id="subscriptionInfo.priceUnknownVatWithDiscount"
            defaultMessage="{price} {discountPrice}/{period} + tax"
            values={{
              price: <s>{priceFormatted}</s>,
              period: periodFormatted,
              discountPrice: <FormattedPrice price={discount.price} currency={currency} />,
            }}
          />
        ) : (
          <FormattedMessage
            id="subscriptionInfo.priceUnknownVat"
            defaultMessage="{price}/{period} + tax"
            values={{
              price: priceFormatted,
              period: periodFormatted,
            }}
          />
        )}

        {discount && (
          <div className="text_sm text_weight-regular text_color-success">
            <FormattedMessage
              id="subscriptionInfo.discountDescription"
              defaultMessage="You have a {discountRate}% discount on your first {period}!"
              values={{
                discountRate: discount.rate,
                period: periodFormatted,
              }}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {discount ? (
        <FormattedMessage
          id="subscriptionInfo.priceWithVatWithDiscount"
          defaultMessage="{price} {discountPrice}+Tax/{period} (Tax ≈ {tax})"
          values={{
            price: <s>{priceFormatted}</s>,
            tax: <FormattedTax tax={tax} currency={currency} />,
            period: periodFormatted,
            discountPrice: <FormattedPrice price={discount.price} currency={currency} />,
          }}
        />
      ) : (
        <FormattedMessage
          id="subscriptionInfo.priceWithVat"
          defaultMessage="{price}+Tax/{period} (Tax ≈ {tax})"
          values={{
            price: priceFormatted,
            tax: <FormattedTax tax={tax} currency={currency} />,
            period: periodFormatted,
          }}
        />
      )}

      {discount && (
        <div className="text_sm text_weight-regular text_color-success">
          <FormattedMessage
            id="subscriptionInfo.discountDescription"
            defaultMessage="You have a {discountRate}% discount on your first {period}!"
            values={{
              discountRate: discount.rate,
              period: periodFormatted,
            }}
          />
        </div>
      )}
    </>
  );
};

SubscriptionPrice.defaultProps = {
  discount: undefined,
};

export default SubscriptionPrice;
