import request from 'utils/request';
import { apiURL } from 'config/api';
import * as paymentProviders from 'services/payment-details-api/payment-providers';
import type { InvoicingInformation, CardDetails } from '../payment-details-api';

export type AddSetappPaymentDetailsStrategy = 'success' | 'fail';

export type SetappPaymentDetailsPayload = {
  strategy: AddSetappPaymentDetailsStrategy;
};

export type SetappPaymentDetails = InvoicingInformation & {
  provider: typeof paymentProviders.SETAPP;
  type: 'card';
  details: CardDetails;
};

class SetappPaymentDetailsApi {
  createPaymentDetails(paymentDetails: SetappPaymentDetailsPayload): Promise<SetappPaymentDetails> {
    return request.post(apiURL.setappPaymentDetails, {
      body: paymentDetails,
    });
  }

  updatePaymentDetails(paymentDetails: SetappPaymentDetailsPayload): Promise<SetappPaymentDetails> {
    return request.patch(apiURL.setappPaymentDetails, {
      body: paymentDetails,
    });
  }
}

export default SetappPaymentDetailsApi;
