import urls from './urls';

export type DeprecatedSocialProvider = 'facebook';
export type SupportedSocialProvider = 'apple' | 'google';

const basePath = process.env.BASE_PATH || '';

export const SOCIAL_PROVIDERS: Array<SupportedSocialProvider> = ['apple', 'google'];

export const SOCIAL_PROVIDER_NAMES: Record<SupportedSocialProvider, string> = {
  apple: 'Apple',
  google: 'Google',
};

export const DEPRECATED_SOCIAL_PROVIDER_NAMES: Record<DeprecatedSocialProvider, string> = {
  facebook: 'Facebook',
};

export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const GOOGLE_AUTH_SCOPE = 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
export const GOOGLE_REDIRECT_URL = `${window.location.origin}${basePath}${urls.socialAuth}/google`;
