import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import PanelBox from 'components/shared/panel-box/panel-box';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import './switch-to-family-plan.scss';

type Props = {
  pricePlan: PricePlan;
  onSwitchToFamilyPlanClick: () => Promise<void>;
  maxFamilySeats?: number;
};

const SwitchToFamilyPlan = (props: Props) => {
  const {
    pricePlan: {
      paidMonth,
      price,
      currency,
    },
    onSwitchToFamilyPlanClick,
    maxFamilySeats,
  } = props;

  return (
    <PanelBox>
      <PanelBox.Content>
        <div className="h4 switch-to-family-plan__title">
          <FormattedMessage
            id="familyPlan.switchToFamilyPlan.peoplePerFamily"
            defaultMessage="You + {familySeatsCount} {familySeatsCount, plural, one {person} other {people}}"
            values={{ familySeatsCount: maxFamilySeats }}
          />

          <div className="switch-to-family-plan__price" data-qa="switchToFamilyPlanPrice">
            <FormattedPrice
              price={price}
              currency={currency}
            />
          </div>

          <FormattedMessage
            id="familyPlan.switchToFamilyPlan.period"
            defaultMessage="{paidMonth, plural, one {per month} =12 {billed annually} other {per {paidMonth} months}}"
            values={{ paidMonth }}
          />
        </div>

        <Button
          block
          className="switch-to-family-plan__button"
          onClick={onSwitchToFamilyPlanClick}
          data-qa="switchToFamilyPlanButton"
        >
          <FormattedMessage id="familyPlan.switchToFamilyPlan.button" defaultMessage="Switch to Family plan" />
        </Button>
      </PanelBox.Content>
    </PanelBox>
  );
};

export default SwitchToFamilyPlan;
