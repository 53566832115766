import React from 'react';
import type { ReactNode } from 'react';
import SidebarNavItem from '../sidebar-nav-item/sidebar-nav-item';

import './sidebar-nav-log-out-link.scss';

import logoutIcon from './images/logout-icon.svg';

type Props = {
  children: ReactNode;
  onClick: () => void;
  userEmail: string;
}

const SidebarNavLogOutLink = ({ children, onClick, userEmail }: Props) => {
  const onLinkClick = (e) => {
    e.preventDefault();

    onClick();
  };

  return (
    <SidebarNavItem onClick={onLinkClick} className="log-out-link" href="#">
      <span className="log-out-link__title">
        {children}
        <img className="log-out-link__icon" src={logoutIcon} alt="" />
      </span>
      <span className="log-out-link__email" title={userEmail}>{userEmail}</span>
    </SidebarNavItem>
  );
};

export default SidebarNavLogOutLink;
