import React from 'react';
import type { ReactNode, ChangeEvent, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { FormField } from '@setapp/ui-kit';
import type { InjectedIntlProps } from 'react-intl';

import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import OuterForm from 'components/shared/outer-form/outer-form';
import CurrentPasswordField from 'components/shared/form/current-password-field/current-password-field';
import TextDelimiter from 'components/shared/text-delimiter/text-delimiter';
import SocialAuthButtons from 'components/shared/social-auth-buttons/social-auth-buttons';

import urls from 'config/urls';

import './login-form.scss';


type Props = {
  onFormSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isRequestProcessing?: boolean;
  email?: string;
  password?: string;
  innerRedirectUri?: string;
  emailError?: ReactNode;
  passwordError?: ReactNode;
  genericError?: ReactNode;
  captcha?: ReactNode;
} & InjectedIntlProps;

const messages = defineMessages({
  emailPlaceholder: {
    id: 'outer.login.emailPlaceholder',
    defaultMessage: 'Your email',
  },
  passwordPlaceholder: {
    id: 'outer.login.passwordPlaceholder',
    defaultMessage: 'Your password',
  },
});

const LoginForm = (props: Props) => {
  const {
    emailError,
    passwordError,
    genericError,
    isRequestProcessing,
    email,
    password,
    innerRedirectUri,
    onEmailChange,
    onPasswordChange,
    onFormSubmit,
    intl,
    captcha,
  } = props;

  return (
    <OuterForm
      title={<FormattedMessage id="outer.signIn.title" defaultMessage="Sign in to your account" />}
      submitBtnCaption={<FormattedMessage id="outer.signIn.submitBtnCaption" defaultMessage="Sign in" />}
      footer={(
        <p className="text-center">
          <Link className="link-outer" to={urls.signup}>
            <FormattedMessage id="outer.login.signUpLinkLabel" defaultMessage="Create an account" />
          </Link>
        </p>
      )}
      onSubmit={onFormSubmit}
      errorMessage={genericError}
      isSubmitProcessing={isRequestProcessing}
      qaLabel="loginForm"
    >
      <SocialAuthButtons
        className="login-social-buttons"
        signupParams={{ innerRedirectUri }}
      />

      <TextDelimiter>
        <FormattedMessage id="outer.login.signInWithEmail" defaultMessage="or sign in with email" />
      </TextDelimiter>

      <FormField
        id="email"
        type="email"
        name="email"
        autoComplete="email"
        label={<FormattedMessage id="outer.login.emailLabel" defaultMessage="Email" />}
        placeholder={intl.formatMessage(messages.emailPlaceholder)}
        helpText={emailError}
        invalid={Boolean(emailError)}
        onChange={onEmailChange}
        value={email}
        autoFocus
        qaLabel="emailFormGroup"
      />
      <CurrentPasswordField
        id="password"
        name="password"
        label={<FormattedMessage id="outer.login.passwordLabel" defaultMessage="Password" />}
        placeholder={intl.formatMessage(messages.passwordPlaceholder)}
        helpText={passwordError}
        invalid={Boolean(passwordError)}
        value={password}
        email={email}
        onChange={onPasswordChange}
        qaLabel="passwordFormGroup"
      />

      {captcha}
    </OuterForm>
  );
};

LoginForm.defaultProps = {
  isRequestProcessing: false,
  emailError: '',
  passwordError: '',
  genericError: '',
  email: '',
  password: '',
  innerRedirectUri: '',
};

export { LoginForm as PureLoginForm };

export default injectIntl(LoginForm);
