import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';

import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import { getShowEstimatedVatIncludedWarning } from 'state/root-reducer';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import InfoBlock from '../info-block/info-block';

type Props = {
  canChangeBillingPeriod: boolean;
  primarySubscription: Subscription;
  pricePlan: PricePlan;
  onChangePeriod: () => void;
};

const BillingPeriodInfo = (props: Props) => {
  const {
    canChangeBillingPeriod,
    primarySubscription,
    pricePlan,
    onChangePeriod,
  } = props;
  const showEstimatedVatIncludedWarning = useSelector(getShowEstimatedVatIncludedWarning);

  const { nextPaymentDate } = primarySubscription;
  const pricePlanMetadata = getPricePlanMetadata(pricePlan);

  const renewInfo = nextPaymentDate ? (
    <>
      <FormattedMessage
        id="manageSubscriptionModal.currentPlanStep.renewInfo"
        defaultMessage="{price}, renews on {date}."
        values={{
          price: <FormattedPrice price={pricePlan.price} currency={pricePlan.currency} />,
          date: <FormattedDate value={nextPaymentDate * 1000} year="numeric" month="short" day="numeric" />,
        }}
      />
      {showEstimatedVatIncludedWarning && (
        <>
          <br />
          <FormattedMessage
            id="manageSubscriptionModal.currentPlanStep.includesVAT"
            defaultMessage="Price includes estimated VAT for your location."
          />
        </>
      )}
    </>
  ) : null;

  const changePeriodButton = canChangeBillingPeriod && (
    <Button
      variant="link"
      className="text_lg"
      onClick={onChangePeriod}
    >
      <FormattedMessage
        id="manageSubscriptionModal.currentPlanStep.changePeriodCTA"
        defaultMessage="Change period"
      />
    </Button>
  );

  return (
    <InfoBlock
      title={pricePlanMetadata.billingPeriod}
      description={renewInfo}
      rightBlock={changePeriodButton}
    />
  );
};

export default BillingPeriodInfo;
