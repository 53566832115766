import type { ResolvedFeatureFlags } from 'utils/feature-flags';

export const REQUEST = 'FEATURE_FLAGS_REQUEST';
export const REQUEST_SUCCESS = 'FEATURE_FLAGS_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'FEATURE_FLAGS_REQUEST_ERROR';

export type FeatureFlagsRequest = {
  type: 'FEATURE_FLAGS_REQUEST';
};
export type FeatureFlagsRequestSuccess = {
  type: 'FEATURE_FLAGS_REQUEST_SUCCESS';
  featureFlags: ResolvedFeatureFlags;
};
export type FeatureFlagsRequestError = {
  type: 'FEATURE_FLAGS_REQUEST_ERROR';
};

export type FeatureFlagsAction =
  FeatureFlagsRequest |
  FeatureFlagsRequestSuccess |
  FeatureFlagsRequestError;
