import React from 'react';

import OauthAuthenticationGuard from 'components/shared/access-control/oauth-authentication-guard';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import ModalRoot from 'components/layout/app-layout/modal-root/modal-root';
import OAuthConfirmEmailContent from './oauth-confirm-email-content/oauth-confirm-email-content';

const OAuthConfirmEmail = () => {
  return (
    <FullscreenLayout>
      <OauthAuthenticationGuard>
        <FullscreenLayout.PrimaryContent wideContainer>
          <OAuthConfirmEmailContent />
        </FullscreenLayout.PrimaryContent>
      </OauthAuthenticationGuard>
      <ModalRoot />
    </FullscreenLayout>
  );
};

export default OAuthConfirmEmail;
