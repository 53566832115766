import React, { type ComponentProps } from 'react';

import { FormattedMessage } from 'react-intl';
import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import { type Subscription } from 'state/subscription/subscription-initial-state';
import { UserAICredits, type User } from 'state/user/user-initial-state';
import {
  AI_EXPERT_ANNUAL,
  AI_ENTHUSIAST_ANNUAL,
} from 'state/price-plans/price-plans-types';

import { AI_OFFER_PRICE_PLANS } from 'config/price-plans';

import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';
import { isAiEnthusiastPricePlan, isAiExpertPricePlan } from 'services/price-plans/utils';

import analytics, { events } from 'utils/analytics';

import AiOfferPlanCard from '../components/ai-offer-plan-card/ai-offer-plan-card';
import useSwitchToAiOffer from './use-switch-to-ai-offer';

export type AiOfferPlanConfig = ComponentProps<typeof AiOfferPlanCard>;

const priceWithoutDiscountMapper = [
  {
    tierType: AI_ENTHUSIAST_ANNUAL,
    priceWithoutDiscount: 14.99,
  }, {
    tierType: AI_EXPERT_ANNUAL,
    priceWithoutDiscount: 23.99,
  }
];

const modelMessageCount: Record<'enthusiast' | 'expert', UserAICredits['example']> = {
  enthusiast: { gpt35: 5000, gpt4Turbo: 250, whisper: 625, dalle2: 150 },
  expert: { gpt35: 10000, gpt4Turbo: 500, whisper: 1250, dalle2: 300 },
};

const currentButtonTitle = (
  <FormattedMessage id="aiOfferPlan.selectButton.current" defaultMessage="Your current plan" />
);

const selectButtonTitle = (
  <FormattedMessage id="aiOfferPlan.selectButton" defaultMessage="Choose" />
);

type Props = {
  user: User;
  pricePlans: Array<PricePlan>;
  primarySubscription: Subscription | undefined;
  selectedPlansPeriod: 1 | 12;
  shouldShowMonthlyPlans: boolean;
  isAiSpecialOfferPage: boolean;
}

const useAiOfferPlansConfig = ({
  user,
  pricePlans,
  primarySubscription,
  selectedPlansPeriod,
  shouldShowMonthlyPlans,
  isAiSpecialOfferPage
}: Props): AiOfferPlanConfig[] => {
  const { openChangePlanModal } = useSwitchToAiOffer();

  if (!primarySubscription) {
    return [];
  }

  const { pricePlan: currentPricePlan } = primarySubscription;
  const currentPlanMetadata = getPricePlanMetadata(currentPricePlan);

  const currentPlanConfig: AiOfferPlanConfig = {
    pricePlan: currentPricePlan,
    title: currentPlanMetadata.title,
    description: currentPlanMetadata.description,
    buttonTitle: currentButtonTitle,
    isCurrent: true,
    aiCreditsLimit: user.aiCredits.limit,
    aiMessagesExample: user.aiCredits.example,
    onSelectPlanClick: () => null // current plan button is disabled, so no need to handle click
  };

  const aiOfferPlans = AI_OFFER_PRICE_PLANS.map((planMetadata) => {
    const pricePlan = pricePlans.find((plan) => plan.tierType === planMetadata.tierType);

    if (!pricePlan) {
      return null;
    }

    if (pricePlan.tierType === currentPricePlan.tierType) {
      return null;
    }

    if (selectedPlansPeriod !== pricePlan.paidMonth) {
      return null;
    }

    const isEnthusiastPlan = isAiEnthusiastPricePlan(pricePlan.tierType);

    const planFeatures = isAiSpecialOfferPage ? pricePlan.features : currentPricePlan.features;

    const { macSeatsCount = 0, iosSeatsCount = 0 } = planFeatures ?? {};

    const hasMoreThanOneDevice = macSeatsCount + iosSeatsCount > 1;
    // this affects only iOS Plan, let's check it this way
    const hasOnlyIosDevices = macSeatsCount === 0 && iosSeatsCount > 0;

    if (isEnthusiastPlan && (hasOnlyIosDevices || hasMoreThanOneDevice)) {
      return null;
    }

    if (pricePlan.paidMonth === 1 && !shouldShowMonthlyPlans) {
      return null;
    }

    const isPromoted = isAiExpertPricePlan(pricePlan.tierType);

    const priceWithoutDiscount = priceWithoutDiscountMapper.find((item) => (
      pricePlan.tierType.includes(item.tierType)
    ))?.priceWithoutDiscount;

    const isPricePlanDiscounted = priceWithoutDiscount
      && Math.floor(priceWithoutDiscount) > Math.floor(pricePlan.price / pricePlan.paidMonth);

    const onSelectPlanClick = () => {
      openChangePlanModal(pricePlan);
      analytics.trackEvent(events.AI_PLUS_SELECT_PLAN, {
        eventLabel: pricePlan.tierType,
        eventLabel2: currentPricePlan.tierType,
      });
    };

    return {
      pricePlan,
      isPromoted,
      title: planMetadata.title,
      description: planMetadata.description,
      buttonTitle: selectButtonTitle,
      priceWithoutDiscount: isPricePlanDiscounted && isAiSpecialOfferPage ? priceWithoutDiscount : undefined,
      aiCreditsLimit: pricePlan.features?.vendorApiUsageLimitInCredits ?? 0,
      aiMessagesExample: modelMessageCount[isEnthusiastPlan ? 'enthusiast' : 'expert'],
      onSelectPlanClick,
    } satisfies AiOfferPlanConfig;
  });

  const filteredAiOfferPlans = aiOfferPlans.filter(Boolean) as Array<AiOfferPlanConfig>;

  return [currentPlanConfig, ...filteredAiOfferPlans];
};

export default useAiOfferPlansConfig;
