import React, { FC } from 'react';

import * as subscriptionStatuses from 'state/subscription/statuses';

import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import { useSubscriptionContext } from 'components/pages/subscription/context/subscription-context';

import ExpirationDateRow from '../expiration-date-row/expiration-date-row';
import PlanRow from '../plan-row/plan-row';
import PriceRow from '../price-row/price-row';
import StatusRow from '../status-row/status-row';
import VatNoteRow from '../vat-note-row/vat-note-row';
import AiCreditsRow from '../ai-credits-row/ai-credits-row';

const SubscriptionBlocked: FC = () => {
  const { taxAmount, pricePlan, subscription } = useSubscriptionContext();

  const price = pricePlan?.price;
  const tierType = pricePlan?.tierType;
  const currency = pricePlan?.currency;
  const paidMonth = pricePlan?.paidMonth;
  const expirationDate = subscription?.expirationDate;

  return (
    <>
      <PlanRow value={<PricePlanTitle tierType={tierType} />} />

      <PriceRow price={price} currency={currency} paidMonth={paidMonth} tax={taxAmount} />

      <StatusRow status={subscriptionStatuses.BLOCKED} expirationDate={expirationDate} />

      {expirationDate && <ExpirationDateRow expirationDate={expirationDate} isSubscriptionBlocked />}

      <AiCreditsRow />

      {taxAmount === 0 && <VatNoteRow />}
    </>
  );
};

export default SubscriptionBlocked;
