import * as events from './events';

const categories = {
  GLOBAL: 'Global Actions',
  SIGNUP_PAGE: 'Sign Up Page',
  PAYMENT_INFO: 'Payment Info',
  HEADER: 'Header',
  ACCOUNT: 'Account',
  REMOVE_PAYMENT_DETAILS: 'Remove Payment Details',
  DELETE_ACCOUNT: 'Delete Account',
  CANCEL_SUBSCRIPTION: 'Cancel Subscription',
  DEACTIVATE_DEVICE: 'Deactivate Device',
  ADDITIONAL_SEATS: 'Additional Seats',
  SUBSCRIPTION: 'Subscription Block',
  SUBSCRIPTION_ACTIVATION: 'Subscription Activation Flow',
  REFERRAL: 'Invite Friends Page',
  SUCCESSFUL_REGISTRATION_PAGE: 'Successful Registration Page',
  GLOBAL_NOTIFICATIONS: 'Global Notifications',
  GIFT_CARDS: 'Gift Cards',
  FAMILY_PLAN: 'Family Plan',
  SELECT_PLAN_PAGE: 'Select Plan Page',
  IOS_ACTIVATION: 'iOS Activation',
  GET_APP_ON_IOS_FORM: 'Get App on iOS Form',
  REDEEM_CODE: 'Redeem Code',
  SPECIAL_OFFER: 'Partnership MC 1P',
  DESKTOP_CCR_TEST: 'Desktop CCR',
  AI_PLUS: 'AI Plus',
  SETAPP_MOBILE_EU: 'Setapp Mobile EU',
};

const gtmEvents = {
  [events.SIGNUP_SUCCESS]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Sign Up Success',
  },

  [events.SIGNUP_TOKEN_VALID]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Token Valid',
  },

  [events.SIGNUP_TOKEN_INVALID]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Token Invalid',
  },

  [events.SIGNUP_TOKEN_EMPTY]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Token Empty',
  },

  [events.SIGNUP_EMAIL_OPT_IN]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Email Opt-In',
  },

  [events.WELCOME_PAGE_OPEN_DESKTOP]: {
    eventCategory: categories.SUCCESSFUL_REGISTRATION_PAGE,
    eventAction: 'Login to Desktop',
  },

  [events.WELCOME_PAGE_DOWNLOAD_STARTED]: {
    eventCategory: categories.SUCCESSFUL_REGISTRATION_PAGE,
    eventAction: 'Download Start',
  },

  [events.WELCOME_PAGE_DIRECT_DOWNLOAD_CLICK]: {
    eventCategory: categories.SUCCESSFUL_REGISTRATION_PAGE,
    eventAction: 'Direct Link',
  },

  [events.WELCOME_PAGE_DOWNLOAD_BUTTON_CLICK]: {
    eventCategory: categories.SUCCESSFUL_REGISTRATION_PAGE,
    eventAction: 'Download Button Click',
  },

  [events.LOGIN_SUCCESS]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Login Success',
  },

  [events.LOGIN_ERROR]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Login Error',
  },

  [events.NEW_PASSWORD_REQUEST]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'New Password Request',
  },

  [events.NEW_PASSWORD_SET]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'New Password Set',
  },

  [events.LOGOUT_SUCCESS]: {
    eventCategory: categories.SIGNUP_PAGE,
    eventAction: 'Log Out',
  },

  [events.PAYMENT_DETAILS_ADD]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Payment Details Add',
  },

  [events.PAYMENT_DETAILS_ADD_CLICK]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Click Add Payment Details',
  },

  [events.PAYMENT_DETAILS_COUNTRY_SELECT]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Country Add', // TODO: "Add" -> "Select"???
  },

  [events.PAYMENT_DETAILS_PAYMENT_METHOD_SELECT]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Payment Method Select',
  },

  [events.PAYMENT_DETAILS_FORM_ERROR]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Form Error',
  },

  [events.PAYMENT_DETAILS_DECLINE_TYPE_SENT]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Decline Sent to Backend',
  },

  [events.PAYMENT_DETAILS_PAYMENT_INFO_API_ERROR]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Received Error from Backend',
  },

  [events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Form Error Braintree',
  },

  [events.PAYMENT_DETAILS_CHECKOUT_LOADED]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Form Checkout Loaded',
  },

  [events.PAYMENT_DETAILS_UI_LOADED]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Form UI Loaded',
  },

  [events.PAYMENT_DETAILS_FORM_DISPLAYED]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Form Shown',
  },

  [events.PAYMENT_DETAILS_START_TRIAL_CLICK]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Click Start Trial',
  },

  [events.PAYMENT_DETAILS_CLICK_UPSEL]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Click Upsel',
  },

  [events.PAYMENT_DETAILS_CHANGE_PLAN]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Click Change Plan',
  },

  [events.PAYMENT_DETAILS_CONFIRM_CHANGE_PLAN]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Confirm Change Plan',
  },

  [events.PAYMENT_DETAILS_SKIP]: {
    eventCategory: categories.PAYMENT_INFO,
    eventAction: 'Skip',
  },

  [events.REMOVE_PAYMENT_DETAILS_MODAL_OPEN]: {
    eventCategory: categories.REMOVE_PAYMENT_DETAILS,
    eventAction: 'Open Dialog',
  },

  [events.REMOVE_PAYMENT_DETAILS_CONFIRM]: {
    eventCategory: categories.REMOVE_PAYMENT_DETAILS,
    eventAction: 'Confirm',
  },

  [events.HEADER_DOWNLOAD_LINK_CLICK]: {
    eventCategory: categories.HEADER,
    eventAction: 'Download Link Click',
  },

  [events.ACCOUNT_EMAIL_CHANGED]: {
    eventCategory: categories.ACCOUNT,
    eventAction: 'Email Change',
  },

  [events.DELETE_ACCOUNT_CONFIRM]: {
    eventCategory: categories.DELETE_ACCOUNT,
    eventAction: 'Confirm',
  },

  [events.ACCOUNT_PASSWORD_CHANGED]: {
    eventCategory: categories.ACCOUNT,
    eventAction: 'Password Change',
  },

  [events.CANCEL_SUBSCRIPTION_OPEN]: {
    eventCategory: categories.CANCEL_SUBSCRIPTION,
    eventAction: 'Open Dialog',
  },

  [events.CANCEL_SUBSCRIPTION_CHOOSE_REASON]: {
    eventCategory: categories.CANCEL_SUBSCRIPTION,
    eventAction: 'Choose Reason',
  },

  [events.CANCEL_SUBSCRIPTION_SUCCESS]: {
    eventCategory: categories.CANCEL_SUBSCRIPTION,
    eventAction: 'Confirm',
  },

  [events.CANCEL_SUBSCRIPTION_PAUSE_CLICK]: {
    eventCategory: categories.CANCEL_SUBSCRIPTION,
    eventAction: 'Click Pause Subscription',
  },

  [events.CANCEL_SUBSCRIPTION_CONTINUE_CLICK]: {
    eventCategory: categories.CANCEL_SUBSCRIPTION,
    eventAction: 'Click Continue',
  },

  [events.CANCEL_SUBSCRIPTION_SURVEY_CLICK]: {
    eventCategory: categories.CANCEL_SUBSCRIPTION,
    eventAction: 'Click Fill Survey',
  },

  [events.DEACTIVATE_DEVICE_MODAL_OPEN]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Click Disconnect Device',
  },

  [events.DEVICE_DEACTIVATED]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Device Disconnected',
  },

  [events.DEVICE_DEACTIVE_ALL_IOS_CLICK]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Click Disconnect All iOS Devices',
  },

  [events.DEVICE_ALL_IOS_DEACTIVATED]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'All iOS Devices Disconnected',
  },

  [events.BUY_SEAT_MODAL_OPEN]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Click Add Devices',
  },

  [events.BUY_SEAT_AMOUNT_SELECTED]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Choose Add Devices Amount',
  },

  [events.BUY_SEAT_PAYMENT_CLICK]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Click Pay for Devices',
  },

  [events.SEAT_WAS_BOUGHT]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Devices Added',
  },

  [events.BUY_SEAT_PAYMENT_ERROR]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Add Devices Error',
  },

  [events.CANCEL_SEAT_MODAL_OPEN]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Click Remove Devices',
  },

  [events.CANCEL_SEAT_AMOUNT_SELECTED]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Choose Remove Devices Amount',
  },

  [events.SEAT_CANCELED]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Devices Removed',
  },

  [events.REACTIVATE_SEAT_MODAL_OPEN]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Click Reactivate Device',
  },

  [events.SEAT_REACTIVATED]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Device Reactivated',
  },

  [events.DEVICES_PAGE_CHANGE_PLAN_CLICK]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Click Change Plan',
  },

  [events.DEVICE_LIMIT_REACHED]: {
    eventCategory: categories.ADDITIONAL_SEATS,
    eventAction: 'Device Limit Reached',
  },

  [events.SUBSCRIPTION_BLOCK_DOWNLOAD_CLICK]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Download Link Click',
  },

  [events.SUBSCRIPTION_BLOCK_DOWNLOAD_START]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Download Start',
  },

  [events.SUBSCRIPTION_BLOCK_CLICK_BUY_PLAN]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Click Buy plan',
  },

  [events.SUBSCRIPTION_BLOCK_REFERRAL_CLICK]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Click Referral',
  },

  [events.SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_CLICK]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Click Manage Subscription',
  },

  [events.SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_FROM_URL]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Show Manage Subscription From URL',
  },

  [events.SUBSCRIPTION_PLAN_MODAL_OPEN]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Open Plan Dialog',
  },

  [events.SUBSCRIPTION_PLAN_CHANGED]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Change Plan',
  },

  [events.SUBSCRIPTION_GET_PLAN]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Get a Plan',
  },

  [events.SUBSCRIPTION_ACTIVATION_MODAL_OPEN]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Open Subscription Activation Dialog',
  },

  [events.SUBSCRIPTION_ACTIVATED]: {
    eventCategory: categories.SUBSCRIPTION_ACTIVATION,
    eventAction: 'Subscription activated',
  },

  [events.FAMILY_PLAN_LEAVE_CLICK]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Leave Family Click',
  },

  [events.FAMILY_PLAN_LEAVE_SUCCESS]: {
    eventCategory: categories.SUBSCRIPTION,
    eventAction: 'Leave Family Success',
  },

  [events.SUBSCRIPTION_ACTIVATION_CHANGE_DEVICES_COUNT]: {
    eventCategory: categories.SUBSCRIPTION_ACTIVATION,
    eventAction: 'Choose Extra Devices Number',
  },

  [events.SUBSCRIPTION_ACTIVATION_CHANGE_PRICE_PLAN]: {
    eventCategory: categories.SUBSCRIPTION_ACTIVATION,
    eventAction: 'Choose Plan',
  },

  [events.SUBSCRIPTION_ACTIVATED_DOWNLOAD_START]: {
    eventCategory: categories.SUBSCRIPTION_ACTIVATION,
    eventAction: 'Download Start',
  },

  [events.SUBSCRIPTION_ACTIVATED_LAUNCH_DESKTOP]: {
    eventCategory: categories.SUBSCRIPTION_ACTIVATION,
    eventAction: 'Launch Desktop',
  },

  [events.REFERRAL_SHARE_CLICK]: {
    eventCategory: categories.REFERRAL,
    eventAction: 'Share Referral Click',
  },

  [events.REFERRAL_SEND_INVITATION_CLICK]: {
    eventCategory: categories.REFERRAL,
    eventAction: 'Click Send Invitation',
  },

  [events.CROSS_AUTH_SUCCESS]: {
    eventCategory: categories.GLOBAL,
    eventAction: 'Cross Auth Success',
  },

  [events.CROSS_AUTH_ERROR]: {
    eventCategory: categories.GLOBAL,
    eventAction: 'Cross Auth Error',
  },

  [events.SWITCH_TO_ANNUAL_LINK_CLICK]: {
    eventCategory: categories.GLOBAL_NOTIFICATIONS,
    eventAction: 'Switch To Annual Plan Link Click',
  },

  [events.GIFT_CARD_REDEEM_MANUALLY]: {
    eventCategory: categories.GIFT_CARDS,
    eventAction: 'Redeem In Account',
  },

  [events.FAMILY_PLAN_MEMBER_CREATED_ACCOUNT]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'User Created An Account',
  },

  [events.FAMILY_PLAN_DROP_CLICK]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Drop Family Click',
  },

  [events.FAMILY_PLAN_DROP_SUCCESS]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Drop Family Success',
  },
  [events.FAMILY_PLAN_SWITCH_CLICK]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Switch To Family Plan Click',
  },

  [events.FAMILY_PLAN_SWITCH_SUCCESS]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Switch To Family Plan Success',
  },

  [events.FAMILY_PLAN_PAYMENT_INFO_ADDED]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Payment Info Added',
  },

  [events.FAMILY_PLAN_INVITE_SENT]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Invite Sent',
  },

  [events.FAMILY_PLAN_INVITE_EXISTING_USER]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Invited User Has An Account',
  },

  [events.FAMILY_PLAN_REMOVE_MEMBER_CLICK]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Remove Member Click',
  },

  [events.FAMILY_PLAN_REMOVE_MEMBER_SUCCESS]: {
    eventCategory: categories.FAMILY_PLAN,
    eventAction: 'Remove Member Success',
  },

  [events.SELECT_PLAN_PAGE_CHANGE_PLAN_CLICK]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Click Change Plan',
  },

  [events.SELECT_PLAN_PAGE_PLAN_SELECT]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Select Plan',
  },

  [events.SELECT_PLAN_PAGE_ARE_YOU_SURE_CONFIRM]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Are You Sure Confirm',
  },

  [events.SELECT_PLAN_PAGE_CONFIRM_CHANGE_PLAN]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Confirm Change Plan',
  },

  [events.SELECT_PLAN_PAGE_CHANGE_BILLING_PERIOD_CLICK]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Click Change Billing Period',
  },

  [events.SELECT_PLAN_PAGE_CHANGE_BILLING_PERIOD_SELECT]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Select Billing Period',
  },

  [events.SELECT_PLAN_PAGE_CONFIRM_CHANGE_BILLING_PERIOD]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Confirm Change Billing Period',
  },

  [events.SELECT_PLAN_PAGE_CANCEL_SUBSCRIPTION_CLICK]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Click Cancel Subscription',
  },

  [events.SELECT_PLAN_PAGE_ERROR]: {
    eventCategory: categories.SELECT_PLAN_PAGE,
    eventAction: 'Error Occurred',
  },
  [events.IOS_ACTIVATION_APP_CLICK]: {
    eventCategory: categories.IOS_ACTIVATION,
    eventAction: 'App Click',
  },
  [events.IOS_ACTIVATION_APP_STORE_CLICK]: {
    eventCategory: categories.IOS_ACTIVATION,
    eventAction: 'Go to App Store Click',
  },
  [events.IOS_ACTIVATION_ACTIVATE_CLICK]: {
    eventCategory: categories.IOS_ACTIVATION,
    eventAction: 'Activate Click',
  },
  [events.IOS_ACTIVATION_REMINDER_SHOWN]: {
    eventCategory: categories.IOS_ACTIVATION,
    eventAction: 'Reminder Shown',
  },
  [events.IOS_ACTIVATION_REMINDER_APP_STORE_CLICK]: {
    eventCategory: categories.IOS_ACTIVATION,
    eventAction: 'Reminder Go to App Store Click',
  },
  [events.GET_APP_IOS_FORM_FORM_SHOWN]: {
    eventCategory: categories.GET_APP_ON_IOS_FORM,
    eventAction: 'Form shown',
  },
  [events.GET_APP_IOS_FORM_STEP_2_SHOWN]: {
    eventCategory: categories.GET_APP_ON_IOS_FORM,
    eventAction: 'Step 2 shown',
  },
  [events.GET_APP_IOS_FORM_STEP_DONE_CLICK]: {
    eventCategory: categories.GET_APP_ON_IOS_FORM,
    eventAction: 'Done Click',
  },
  [events.REDEEM_CODE_CLICK]: {
    eventCategory: categories.REDEEM_CODE,
    eventAction: 'Click Redeem Code',
  },
  [events.REDEEM_CODE_ERROR]: {
    eventCategory: categories.REDEEM_CODE,
    eventAction: 'Redeem Error',
  },
  [events.REDEEM_CODE_SUCCESS]: {
    eventCategory: categories.REDEEM_CODE,
    eventAction: 'Redeem Successful',
  },
  [events.LEARN_ABOUT_REDEEM]: {
    eventCategory: categories.REDEEM_CODE,
    eventAction: 'Click Learn About Codes',
  },
  [events.SPECIAL_OFFER_BANNER_CLICK]: {
    eventCategory: categories.SPECIAL_OFFER,
    eventAction: 'Click Banner',
  },

  // Desktop CCR test events
  [events.DESKTOP_CCR_TEST_CLICK]: {
    eventCategory: categories.DESKTOP_CCR_TEST,
    eventAction: 'Click',
  },
  [events.DESKTOP_CCR_TEST_POPUP_CLICK]: {
    eventCategory: categories.DESKTOP_CCR_TEST,
    eventAction: 'Click Pop up',
  },

  [events.AI_PLUS_UPGRADE_PLAN_CLICK]: {
    eventCategory: categories.AI_PLUS,
    eventAction: 'Click Upgrade Plan',
  },
  [events.AI_PLUS_LANDING_PAGE_VIEW]: {
    eventCategory: categories.AI_PLUS,
    eventAction: 'Landing page view',
  },
  [events.AI_PLUS_SELECT_PLAN]: {
    eventCategory: categories.AI_PLUS,
    eventAction: 'Select Plan',
  },
  [events.AI_PLUS_CONFIRM_CHANGE_PLAN]: {
    eventCategory: categories.AI_PLUS,
    eventAction: 'Confirm Change Plan',
  },

  [events.SETAPP_MOBILE_EU_SET_UP_FREE_TRIAL]: {
    eventCategory: categories.SETAPP_MOBILE_EU,
    eventAction: 'Set Up Free Trial',
  },
  [events.SETAPP_MOBILE_EU_ERROR_OCCURED]: {
    eventCategory: categories.SETAPP_MOBILE_EU,
    eventAction: 'Error Occured',
  },
};

export default gtmEvents;
