import { useState, useEffect } from 'react';
import request from 'utils/request';
import { apiURL } from 'config/api';
import Logger from 'utils/logger';

const { SETAPP_MOBILE_CLIENT_ID } = process.env;

const createInstallationUrl = (distribPackage: string, installToken: string): string => (
  `marketplace-kit://install?alternativeDistributionPackage=${distribPackage}&installVerificationToken=${installToken}`
);

const useMobileInstallationUrl = () => {
  const [urlError, setUrlError] = useState<string | null>(null);
  const [mobileInstallationUrl, setMobileInstallationUrl] = useState<string | null>(null);

  useEffect(() => {
    async function fetchMobileInstallationUrl() {
      try {
        const { alternativeDistributionPackage, installVerificationToken } = await request.post(apiURL.iosInstallInfo, {
          body: { clientId: SETAPP_MOBILE_CLIENT_ID },
        });
        const url = createInstallationUrl(alternativeDistributionPackage, installVerificationToken);
        setMobileInstallationUrl(url);
      } catch (error: any) {
        setMobileInstallationUrl(null);
        Logger.logError(error);
        setUrlError(error.message);
      }
    }

    fetchMobileInstallationUrl();
  }, []);

  return {
    mobileInstallationUrl,
    urlError,
  };
};

export default useMobileInstallationUrl;
