import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { getUser } from 'state/root-reducer';

import UpgradePlanCard from 'components/shared/setapp-mobile-upgrade-card/setapp-mobile-upgrade-card';

import CustomerOauthContent from './customer-oauth-content';


const CustomerOauthUpgradePlan = () => {
  const { accountCurrency } = useSelector(getUser);

  const taxInfo = accountCurrency === 'EUR' ? (
    <FormattedHTMLMessage
      id="customer-oauth.upgradePlan.vatInfo"
      defaultMessage="This is an estimated price for your location. The final amount may vary depending on applicable VAT."
    />
  ) : (
    <FormattedHTMLMessage
      id="customer-oauth.upgradePlan.vatUsdInfo"
      defaultMessage="Tax may apply, depending on your country of residence."
    />
  );

  return (
    <CustomerOauthContent
      titleText={
        <FormattedMessage
          id="customer-oauth.upgradePlan.title"
          defaultMessage="Upgrade your plan to keep using Setapp Mobile"
        />
      }
    >
      <UpgradePlanCard isCustomerOauthVariant />
      <p className="mt-7">{taxInfo}</p>
    </CustomerOauthContent>
  );
};

export default CustomerOauthUpgradePlan;
