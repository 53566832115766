import React from 'react';
import type { ReactElement, ReactNode } from 'react';

import './buttons-row.scss';

type Props = {
  children: ReactNode;
};

const ButtonsRow = (props: Props) => {
  const { children } = props;

  return (
    <div className="buttons-row">
      {React.Children.map(children, (child) => (
        // Child is null when false, true, null or undefined passed
        child != null ? React.cloneElement(child as ReactElement, { className: 'buttons-row__item' }) : null
      ))}
    </div>
  );
};

export default ButtonsRow;
