import React, { Component } from 'react';
import type { ReactNode } from 'react';
import { connect } from 'react-redux';
import Script from 'react-load-script';
import classNames from 'classnames';
import { AnimatedLogo } from '@setapp/ui-kit';

import analytics, { events } from 'utils/analytics';
import healthMetrics from 'utils/health-metrics';

import PaddleFormConfigApi from 'services/payment-details-api/paddle/paddle-form-config-api';
import type { PaddleFormConfig } from 'services/payment-details-api/paddle/paddle-form-config-api';
import type { PaymentDetailsPayload, UpdatePaymentDetailsPayload } from 'services/payment-details-api/paddle/paddle-payment-details-api';
import type { PaymentDetailsOptions } from 'services/payment-details-api/payment-details-api';

import { createPaddlePaymentDetails, updatePaddlePaymentDetails } from 'state/payment-method/payment-method-actions';
import type { PaymentDetailsState } from 'state/payment-method/payment-method-initial-state';
import { getUser, getPaymentMethod } from 'state/root-reducer';

import DefaultError from 'components/shared/default-error/default-error';

import trackPaddleEvent from './paddle-checkout-events-tracker';

import './paddle-payment-form.scss';

const PADDLE_SDK_URL = 'https://cdn.paddle.com/paddle/paddle.js';

type Props = {
  userLocale: string;
  userEmail: string;
  paymentMethod: PaymentDetailsState;
  isPaymentDetailsLoading: boolean;
  createPaymentDetails: (payload: PaymentDetailsPayload) => Promise<void>;
  updatePaymentDetails: (payload: UpdatePaymentDetailsPayload) => Promise<void>;

  paymentDetailsOptions?: PaymentDetailsOptions;
  onSuccessSubmit?: () => void;
};

type State = {
  formConfig: PaddleFormConfig | null;
  paddleUILoaded: boolean;
  paddleFormLoadingTimeStart: number | null;
  formError: ReactNode;
};

// Only fields used by our logic are described
type PaddleCheckoutData = {
  checkout: {
    id: string;
  };
};

const paddleLocales = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  pt: 'pt',
  es: 'es',
  it: 'it',
  uk: 'en',
  zh: 'zh-Hans',
};

class PaddlePaymentForm extends Component<Props, State> {
  state: State = {
    formConfig: null,
    paddleUILoaded: false,
    paddleFormLoadingTimeStart: null,
    formError: null,
  };

  paddleFormConfigApi: PaddleFormConfigApi;

  constructor(props: Props) {
    super(props);

    this.paddleFormConfigApi = new PaddleFormConfigApi();
  }

  componentDidMount() {
    this.sendFormDisplayedAnalyticsEvent();
    this.setState({ paddleFormLoadingTimeStart: new Date().getTime() });
    this.fetchFormConfig();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { paddleUILoaded: prevPaddleUILoaded } = prevState;
    const { paddleUILoaded } = this.state;

    if (!prevPaddleUILoaded && paddleUILoaded) {
      this.trackFormLoaded();
    }
  }

  render() {
    const { isPaymentDetailsLoading } = this.props;
    const { formConfig, paddleUILoaded, formError } = this.state;
    const containerClasses = classNames([
      'paddle-form__container',
      { 'paddle-form__container_hidden': !paddleUILoaded || isPaymentDetailsLoading },
    ]);

    return (
      <div className="paddle-form">
        {(!paddleUILoaded || isPaymentDetailsLoading) && (
          <div className="paddle-form__loading">
            <AnimatedLogo animate />
          </div>
        )}

        <div className={containerClasses} />

        {formError && (
          <div className="mt-3 text-danger">
            {formError}
          </div>
        )}

        {formConfig && (
          <Script
            url={PADDLE_SDK_URL}
            onLoad={this.handlePaddleScriptLoaded}
            onError={this.handlePaddleScriptLoadingError}
          />
        )}
      </div>
    );
  }

  trackFormLoaded = () => {
    const { paddleFormLoadingTimeStart } = this.state;
    const now = new Date().getTime();

    if (!paddleFormLoadingTimeStart) {
      return;
    }

    const formLoadingDuration = now - paddleFormLoadingTimeStart;

    healthMetrics.trackTiming({ name: 'paddle_form_load_time', value: formLoadingDuration });
  };

  handlePaddleScriptLoaded = () => {
    this.initPaddleSDK();
    this.openPaddleCheckout();
  };

  handlePaddleScriptLoadingError = () => {
    this.showFormLoadingError();
  };

  handlePaddleUILoaded = () => {
    analytics.trackEvent(events.PAYMENT_DETAILS_UI_LOADED, { eventLabel: 'Paddle' });
    this.setState({ paddleUILoaded: true });
  };

  handlePaddleCheckoutComplete = (checkoutData: PaddleCheckoutData) => {
    const {
      onSuccessSubmit,
      createPaymentDetails,
      updatePaymentDetails,
      paymentDetailsOptions,
      paymentMethod,
    } = this.props;

    const paymentDetailsPromise = paymentMethod.data
      ? updatePaymentDetails({ ...paymentDetailsOptions, subscriptionId: checkoutData.checkout.id })
      : createPaymentDetails({ ...paymentDetailsOptions, checkoutId: checkoutData.checkout.id });

    return paymentDetailsPromise
      .then(() => {
        if (onSuccessSubmit) {
          onSuccessSubmit();
        }
      })
      .catch((error) => {
        const [firstError] = error.getAll();

        analytics.trackEvent(events.PAYMENT_DETAILS_PAYMENT_INFO_API_ERROR, {
          eventLabel: firstError.message,
          eventLabel2: error,
        });
        this.setState({ formError: firstError.message });
      });
  };

  fetchFormConfig() {
    return this.paddleFormConfigApi.fetchConfig()
      .then((config) => {
        this.setState({ formConfig: config });
      })
      .catch(() => {
        this.showFormLoadingError();
      });
  }

  initPaddleSDK() {
    const { formConfig } = this.state;

    if (!formConfig) {
      throw new Error('Form config is not fetched');
    }

    if (process.env.ENVIRONMENT !== 'production') {
      (window as $TSFixMe).Paddle.Environment.set('sandbox');
    }

    (window as $TSFixMe).Paddle.Setup({
      vendor: formConfig.vendorId,
      eventCallback: trackPaddleEvent,
    });
  }

  openPaddleCheckout() {
    const { userLocale, userEmail } = this.props;
    const { formConfig } = this.state;

    if (!formConfig) {
      throw new Error('Form config is not fetched');
    }

    const [locale] = userLocale.split('-');
    const paddleLocale = paddleLocales[locale!];

    (window as $TSFixMe).Paddle.Checkout.open({
      override: (formConfig as $TSFixMe).checkoutUrl,
      method: 'inline',
      email: userEmail,
      locale: paddleLocale,
      frameTarget: 'paddle-form__container',
      frameInitialHeight: 366,
      frameStyle: 'width: 100%; min-width:312px; background-color: transparent; border: none;',
      loadCallback: this.handlePaddleUILoaded,
      successCallback: this.handlePaddleCheckoutComplete,
    });
  }

  showFormLoadingError() {
    this.setState({
      formError: <DefaultError />,
    });
  }

  sendFormDisplayedAnalyticsEvent() {
    analytics.trackEvent(events.PAYMENT_DETAILS_FORM_DISPLAYED, { eventLabel: 'Paddle' });
  }
}

export { PaddlePaymentForm as PurePaddlePaymentForm };

const mapActionsToProps = {
  createPaymentDetails: createPaddlePaymentDetails,
  updatePaymentDetails: updatePaddlePaymentDetails,
};

const mapStateToProps = (state) => {
  const user = getUser(state);

  return {
    userLocale: user.locale,
    userEmail: user.email,
    paymentMethod: getPaymentMethod(state),
    isPaymentDetailsLoading: getPaymentMethod(state).isLoading,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(PaddlePaymentForm);
