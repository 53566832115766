import React, { Component, ReactElement } from 'react';
import {
  FormattedDate,
  FormattedMessage,
} from 'react-intl';
import type { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, ButtonClose } from '@setapp/ui-kit';

import urls from 'config/urls';
import validate from 'utils/auth-validation';

import FormContainer from 'components/shared/form/form-container/form-container';
import DefaultError from 'components/shared/default-error/default-error';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';

import * as subscriptionStatuses from 'state/subscription/statuses';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import {
  getPrimarySubscription,
  getSubscriptions,
  getUser,
  getUserEmail,
} from 'state/root-reducer';
import { fetchSubscription } from 'state/subscription/subscription-actions';
import { deleteAccount } from 'state/user/user-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import DeleteAccountForm from './delete-account-form';
import secondaryImage from './images/secondary.svg';


import './delete-account-page.scss';


type Props = {
  primarySubscription?: Subscription;
  fetchSubscription: () => Promise<void>;
  showDangerNotification: (message: ReactElement) => void;
  isSubscriptionLoading: boolean;
  isUserLoading: boolean;
  email: string;
  history: RouteComponentProps['history'];
  deleteAccount: (password: string) => Promise<void>;
};

class DeleteAccountPage extends Component<Props> {
  componentDidMount() {
    const { fetchSubscription, showDangerNotification } = this.props;

    return fetchSubscription()
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  render() {
    const {
      primarySubscription,
      isUserLoading,
      email,
      isSubscriptionLoading,
    } = this.props;

    if (!primarySubscription || isSubscriptionLoading) {
      return <FullscreenLayoutLoading />;
    }

    const { status, nextPaymentDate } = primarySubscription;
    const isActiveUser = status === subscriptionStatuses.ACTIVE;

    return (
      <FullscreenLayout>
        <FullscreenLayout.PrimaryContent>
          <div className="delete-account-primary__content">
            <div className="h3 mb-5" data-qa="pageTitle">
              <FormattedMessage
                id="deleteAccountPage.title"
                defaultMessage="Delete your account"
              />
            </div>

            <p className="mb-6">
              <FormattedMessage
                id="deleteAccountPage.descriptionPassword"
                defaultMessage="This will permanently delete your account. Once you type in your password and click the button below, you won’t be able to use Setapp anymore."
              />
            </p>

            <p className="mb-8">
              <FormattedMessage
                id="deleteAccountPage.descriptionAction"
                defaultMessage="This action cannot be undone."
              />
            </p>

            <FormContainer
              initialValues={{ password: '' }}
              validate={this.validateForm}
              onSubmit={this.onFormSubmit}
            >
              {({
                fields,
                fieldsErrors,
                formError,
                isProcessing,
                onFieldChange,
                onSubmit,
              }) => <DeleteAccountForm
                fields={fields}
                // @ts-expect-error Record<string, ReactNode> is not assignable to type Record<string, string>, fix it
                fieldsErrors={fieldsErrors}
                formError={formError}
                isProcessing={isProcessing || isUserLoading}
                onFieldChange={onFieldChange}
                onSubmit={onSubmit}
                email={email}
                    />}
            </FormContainer>

            <Button
              variant="secondary-outline"
              block
              className="mt-3"
              onClick={this.handleCloseClick}
            >
              <FormattedMessage id="deleteAccountPage.cancelButton" defaultMessage="Cancel" />
            </Button>

            {isActiveUser && nextPaymentDate && (
              <p className="mt-8" data-qa="subscriptionInfoMessage">
                <FormattedMessage
                  id="deleteAccountPage.descriptionSubscription"
                  defaultMessage="P.S. Your paid membership is active until {subscriptionEndDate}. If you want to use it until the end, {cancelSubscriptionLink} now and come back after it expires."
                  values={{
                    cancelSubscriptionLink: (
                      <a href={urls.cancelSubscription}>
                        <FormattedMessage id="deleteAccountPage.cancelSubscriptionLink" defaultMessage="cancel your plan" />
                      </a>
                    ),
                    subscriptionEndDate: (
                      <FormattedDate
                        value={nextPaymentDate * 1000}
                        year="numeric"
                        month="short"
                        day="numeric"
                      />
                    ),
                  }}
                />
              </p>
            )}

            <div className="delete-account-primary__close-btn">
              <ButtonClose onClick={this.handleCloseClick} />
            </div>
          </div>
        </FullscreenLayout.PrimaryContent>

        <FullscreenLayout.SecondaryContent>
          <div className="delete-account-secondary__content">
            <div className="h4 text-center mb-6">
              <FormattedMessage
                id="deleteAccountPage.secondaryTitle"
                defaultMessage="Thanks a lot for being with us"
              />
              <span className="delete-account-secondary__heart-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21">
                  <path fill="#F57D00" fillRule="evenodd" d="M22 5.998c0 4.927-3.667 9.853-11 14.78C3.667 15.85 0 10.925 0 5.998 0-.928 9.382-1.477 10.817 2.465l.183.377.183-.377C12.618-1.477 22-.965 22 5.998z" />
                </svg>
              </span>
            </div>
            <img src={secondaryImage} alt="" className="img-responsive delete-account-secondary__image" />

            <div className="delete-account-secondary__close-btn">
              <ButtonClose onClick={this.handleCloseClick} />
            </div>
          </div>
        </FullscreenLayout.SecondaryContent>
      </FullscreenLayout>
    );
  }

  validateForm(fields: {password: string}) {
    return validate(fields, {
      password: {
        required: <FormattedMessage
          id="deleteAccountPage.validation.emptyPassword"
          defaultMessage="Password is required"
                  />,
      },
    });
  }

  onFormSubmit = (fields: {password: string}) => {
    const { password } = fields;

    return this.deleteAccount(password);
  };

  deleteAccount(currentPassword: string) {
    const { history, deleteAccount } = this.props;

    return deleteAccount(currentPassword)
      .then(() => {
        history.push(urls.successfulAccountDelete);
      });
  }

  handleCloseClick = () => {
    const { history } = this.props;

    history.push(urls.account);
  };
}

const mapStateToProps = (state) => ({
  primarySubscription: getPrimarySubscription(state),
  isSubscriptionLoading: getSubscriptions(state).isLoading,
  isUserLoading: getUser(state).isLoading,
  email: getUserEmail(state),
});

const mapActionsToProps = {
  fetchSubscription,
  showDangerNotification,
  deleteAccount,
};

export { DeleteAccountPage as PureDeleteAccountPage };

export default connect(mapStateToProps, mapActionsToProps)(DeleteAccountPage);
