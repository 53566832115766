// theme styles should be imported first to avoid overlap with component styles
import '@setapp/ui-kit/styles/theme.scss';

import React, { Component, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import queryString from 'query-string';

import urls from 'config/urls';

import { detectLocale } from 'utils/intl';
import SocialAuthInitiator from 'utils/social-auth-initiator';
import request from 'utils/request';
import injectAuthMiddleware from 'utils/request-middleware/inject-auth-middleware';
import injectUserLanguageMiddleware from 'utils/request-middleware/inject-user-language-middleware';
import requestNewAuthTokenMiddleware from 'utils/request-middleware/request-new-auth-token-middleware';
import analytics from 'utils/analytics';
import openDesktopAppIfRequired from 'utils/desktop-app-opener';
import healthMetrics from 'utils/health-metrics';

import { getUser } from 'state/root-reducer';
import { setUILocale, setUserLoggedOut } from 'state/user/user-actions';
import { initFeatureFlags } from 'state/feature-flags/feature-flags-actions';

import AppInitializationError from 'components/shared/app-initialization-error/app-initialization-error';

import './root.scss';

type ComponentProps = {
  userId: number | null;
  userEmail: string;
  children: ReactNode;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  initFeatureFlags: () => Promise<void>;
  setUILocale: (locale: string) => Promise<void>;
  setUserLoggedOut: () => unknown;
};

type State = {
  initializationComplete: boolean;
  initializationError: boolean;
};

const mapStateToProps = (state) => {
  const { id: userId, email: userEmail } = getUser(state);

  return {
    userId,
    userEmail,
  };
};

const mapActionsToProps = {
  initFeatureFlags,
  setUILocale,
  setUserLoggedOut,
};

const connector = connect(mapStateToProps, mapActionsToProps);

export type Props = ComponentProps & ConnectedProps<typeof connector>;

class Root extends Component<Props, State> {
  state = {
    initializationComplete: false,
    initializationError: false,
  };

  socialAuthInitiator: SocialAuthInitiator;

  constructor(props: Props) {
    super(props);

    this.socialAuthInitiator = new SocialAuthInitiator();

    request.useMiddleware([
      injectAuthMiddleware,
      injectUserLanguageMiddleware,
      requestNewAuthTokenMiddleware({
        onTokenError() {
          props.setUserLoggedOut();
          props.history.push(urls.login);
        },
      }),
    ]);
  }

  async componentDidMount() {
    const {
      initFeatureFlags,
      setUILocale,
      location,
      history,
    } = this.props;
    const { cid } = queryString.parse(location.search);

    const isSocialAuthStarted = this.socialAuthInitiator.initiateIfProviderInURL(location);

    if (isSocialAuthStarted) {
      return;
    }

    if (process.env.NODE_ENV === 'production') {
      this.initAnalytics(cid as string);
    }

    openDesktopAppIfRequired(history, queryString.parse(location.search));
    healthMetrics.initialize();

    try {
      await setUILocale(detectLocale());
    } catch (e) {
      this.setState({ initializationError: true });

      return;
    }

    await initFeatureFlags();

    this.setState({ initializationComplete: true });

    analytics.trackPageView();
  }

  componentDidUpdate(prevProps: Props) {
    const { location: currentLocation } = this.props;
    const { location: previousLocation } = prevProps;
    const isLocationChanged = currentLocation.pathname !== previousLocation.pathname;

    if (isLocationChanged) {
      analytics.trackPageView();
    }
  }

  render() {
    const { children } = this.props;
    const { initializationError, initializationComplete } = this.state;

    if (initializationError) {
      return <AppInitializationError />;
    }

    if (initializationComplete) {
      return (
        <IntlProvider>
          {children}
        </IntlProvider>
      );
    }

    return null;
  }

  initAnalytics(cid: string) {
    analytics.initialize({
      cid,
      preparePayload: (payload) => {
        const {
          userEmail,
          userId,
        } = this.props;

        return {
          ...payload,
          userEmail,
          userId,
        };
      },
    });
  }
}

export default connector(Root);
