import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { getPriceWithGracePeriod } from 'services/price-plans/utils';

import type { Subscription } from 'state/subscription/subscription-initial-state';

import NextPaymentDateEstimation from 'components/shared/next-payment-date-estimation/next-payment-date-estimation';
import PaymentButton from 'components/shared/payment-button/payment-button';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentForm from 'components/shared/payment-form/payment-form';
import TaxInfo from 'components/shared/tax-info/tax-info';

import NextPaymentInfo from 'components/user-flow/shared/next-payment-info/next-payment-info';

type Props = {
  isPaymentMethodCreated: boolean;
  isProcessing: boolean;
  primarySubscription: Subscription;
  onRetryPayment: () => Promise<void>;
  onUpdatePaymentDetailsClick: () => void;
};

const PaymentRetryInfoStep = (props: Props) => {
  const {
    isPaymentMethodCreated,
    isProcessing,
    primarySubscription,
    onRetryPayment,
    onUpdatePaymentDetailsClick,
  } = props;

  const { pricePlan, gracePeriod } = primarySubscription;
  const { currency, paidMonth, price } = pricePlan;

  // After retrying payment BE will return subscription without the grace period.
  // But we still need to show the grace period in the UI, so we store it in the ref.
  const gracePeriodRef = useRef(gracePeriod);

  const totalAmount = getPriceWithGracePeriod(pricePlan, gracePeriodRef.current);

  const renderPaymentCta = () => {
    if (isPaymentMethodCreated) {
      return (
        <PaymentButton
          forceProcessing={isProcessing}
          onClick={onRetryPayment}
        />
      );
    }

    return (
      <PaymentForm
        braintreeFormOptions={{
          submitBtnTitle: (
            <FormattedMessage
              id="paymentRetryInfoStep.payButtonTitle"
              defaultMessage="Pay now"
            />
          ),
          loadingText: (
            <FormattedMessage
              id="paymentRetryInfoStep.payButtonLoadingText"
              defaultMessage="Payment in progress…"
            />
          ),
        }}
        paymentMethodOptions={{
          amount: totalAmount,
          currencyCode: currency,
        }}
        onSuccessSubmit={onRetryPayment}
      />
    );
  };

  const renderUpdatePaymentDetailsButton = () => {
    if (!isPaymentMethodCreated) return null;

    return (
      <Button
        className="mt-3"
        block
        variant="secondary-outline"
        size="lg"
        disabled={isProcessing}
        onClick={onUpdatePaymentDetailsClick}
      >
        <PaymentDetailsActionText action="update" />
      </Button>
    );
  };

  return (
    <>
      <h3 className="mt-0 mb-6">
        <FormattedMessage
          id="paymentRetryInfoStep.title"
          defaultMessage="Retrying your payment"
        />
      </h3>
      <p className="mb-8 text_lg">
        <FormattedMessage
          id="paymentRetryInfoStep.description"
          defaultMessage="If you are sure your payment details are correct, we can retry your payment now."
        />
      </p>

      <NextPaymentInfo
        showPricePlanPeriodSwitcher={false}
        pricePlan={pricePlan}
        gracePeriod={gracePeriodRef.current}
      />

      <div className="mt-8 mb-5">
        {renderPaymentCta()}
        {renderUpdatePaymentDetailsButton()}
      </div>

      <NextPaymentDateEstimation
        price={price}
        currency={currency}
        paidMonth={paidMonth}
      />
      <div className="mt-2">
        <TaxInfo />
      </div>
    </>
  );
};

export default PaymentRetryInfoStep;
