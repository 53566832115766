import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';


import type { Subscription } from 'state/subscription/subscription-initial-state';
import urls from 'config/urls';
import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';
import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import PlanRow from './plan-row/plan-row';
import PriceRow from './price-row/price-row';
import NextPaymentRow from './next-payment-row/next-payment-row';
import VatNoteRow from './vat-note-row/vat-note-row';
import AdditionalSeatsAmountRow from './additional-seats-amount-row/additional-seats-amount-row';

import './additional-seats-info.scss';

export type Props = {
  subscription: Subscription & {
    seatsNumber: number;
  };
}

const AdditionalSeatsInfo: FC<Props> = ({ subscription }) => {
  const { tax, pricePlan, nextPricePlan, nextPaymentDate, seatsNumber } = subscription;
  // Display next price plan info if user changed plan. It's made by design.
  const {
    price, currency, paidMonth, tierType,
  } = nextPricePlan || pricePlan;

  return (
    <div data-qa="additionalSeatsBlock">
      <h5 className="additional-seats-info-title">
        <FormattedMessage
          id="subscriptionInfo.additionalSeats.title"
          defaultMessage="Additional seats"
        />
      </h5>
      <AdditionalSeatsAmountRow
        additionalSeatsAmount={seatsNumber}
      />

      <PlanRow value={<PricePlanTitle tierType={tierType} />} />

      <PriceRow
        price={price}
        tax={tax}
        currency={currency}
        paidMonth={paidMonth}
      />

      {nextPaymentDate && <NextPaymentRow nextPaymentDate={nextPaymentDate} />}

      <VatNoteRow />

      <AccountControlButtons>
        <Button
          variant="secondary-outline"
          data-qa="manageExtraSeatsBtn"
          tag={Link}
          to={urls.devices}
        >
          <FormattedMessage
            id="subscriptionInfo.additionalSeats.manageExtraSeatsButton"
            defaultMessage="Manage extra seats"
          />
        </Button>
      </AccountControlButtons>
    </div>
  );
};

export default AdditionalSeatsInfo;
