import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import logger from 'utils/logger';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { FAMILY_ANNUAL, FAMILY_MONTHLY } from 'state/price-plans/price-plans-types';
import {
  getDisplayedPricePlan,
  getFamilyPricePlans,
  getPrimarySubscription,
  isPaymentMethodCreated as isPaymentMethodCreatedSelector,
  isPaymentMethodFetched as isPaymentMethodFetchedSelector,
  isSuspendedSubscription as isSuspendedSubscriptionSelector,
  isSuspendedSubscriptionWithOldPricePlan as isSuspendedSubscriptionWithOldPricePlanSelector,
} from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';
import {
  activateOldSuspendedSubscription,
  activateSubscription,
  changePricePlan,
  fetchAllSubscriptions,
} from 'state/subscription/subscription-actions';

import useMultiplePaymentWaiting from 'components/hooks/use-multiple-payment-waiting/use-multiple-payment-waiting';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';

import ButtonBack from 'components/shared/button-back/button-back';
import DefaultError from 'components/shared/default-error/default-error';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentForm from 'components/shared/payment-form/payment-form';

import PaymentErrorStep from 'components/user-flow/shared/payment-error-step/payment-error-step';

import CheckoutPricePlanStep from '../checkout-price-plan-step/checkout-price-plan-step';
import FamilySubscriptionActivated from '../family-subscription-activated/family-subscription-activated';

type Step = 'checkoutPricePlanStep'
  | 'paymentErrorStep'
  | 'updatePaymentDetailsStep'
  | 'successActivationStep';

const BACK_STEP_MAPPING: {[key in Step]?: Step} = {
  paymentErrorStep: 'checkoutPricePlanStep',
  updatePaymentDetailsStep: 'checkoutPricePlanStep',
};

const ActivateFamilyFlow = () => {
  const dispatch = useDispatch();

  const displayedPricePlan = useSelector(getDisplayedPricePlan);
  const familyPricePlans = useSelector(getFamilyPricePlans);
  const primarySubscription = useSelector(getPrimarySubscription);
  const isPaymentMethodCreated = useSelector(isPaymentMethodCreatedSelector);
  const isPaymentMethodFetched = useSelector(isPaymentMethodFetchedSelector);
  const isSuspendedSubscription = useSelector(isSuspendedSubscriptionSelector);
  const isSuspendedSubscriptionWithOldPricePlan = useSelector(isSuspendedSubscriptionWithOldPricePlanSelector);

  const [step, setStep] = useState<Step>('checkoutPricePlanStep');
  const [isProcessing, setIsProcessing] = useState(false);
  const [switchedToAnnual, setSwitchedToAnnual] = useState(
    displayedPricePlan?.paidMonth === 12
  );

  useEffect(() => {
    try {
      if (!primarySubscription) {
        dispatch(fetchAllSubscriptions());
      }
      if (!isPaymentMethodFetched) {
        dispatch(fetchPaymentMethod());
      }
      if (!familyPricePlans.length) {
        dispatch(fetchPricePlans());
      }
    } catch {
      dispatch(showDangerNotification(<DefaultError />));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If user successfully activated family subscription and try to reload page we show him the success activation step
  useEffect(() => {
    if (
      primarySubscription
      && primarySubscription.status === subscriptionStatuses.ACTIVE
      && step === 'checkoutPricePlanStep'
    ) {
      setStep('successActivationStep');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primarySubscription]);

  const { waitMultipleForPaymentSuccess } = useMultiplePaymentWaiting({
    onSuccessPayment: () => {
      setIsProcessing(false);
      setStep('successActivationStep');
    },
    onFailedPayment: () => {
      setIsProcessing(false);
      setStep('paymentErrorStep');
    },
  });

  if (!primarySubscription || !isPaymentMethodFetched || !familyPricePlans.length) {
    return <FullscreenLayoutLoading />;
  }

  const familyMonthlyPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_MONTHLY);
  const familyAnnualPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_ANNUAL);

  const nextPricePlan = switchedToAnnual ? familyAnnualPricePlan : familyMonthlyPricePlan;

  // This check only for TS
  if (!nextPricePlan) {
    return null;
  }

  const handleChangePlanPeriod = () => {
    setSwitchedToAnnual((switchedToAnnual) => !switchedToAnnual);
  };

  const handleCheckoutPricePlan = async () => {
    setIsProcessing(true);

    try {
      if (isSuspendedSubscriptionWithOldPricePlan) {
        await dispatch(activateOldSuspendedSubscription(primarySubscription, nextPricePlan.id));
      } else if (isSuspendedSubscription) {
        await dispatch(changePricePlan(primarySubscription.id, nextPricePlan.id, { fetchSubscription: false }));
      } else {
        await dispatch(activateSubscription({ primaryPricePlanId: nextPricePlan.id }));
      }

      await waitMultipleForPaymentSuccess();
    } catch (error) {
      setIsProcessing(false);

      logger.logError('Activate family for suspended user flow: Error during subscription activation', error);
      dispatch(showDangerNotification(<DefaultError />));
    }
  };

  const handleBackButtonClick = () => {
    const backStep = BACK_STEP_MAPPING[step];

    if (backStep) {
      setStep(backStep);
    }
  };

  const renderBackButton = () => {
    const backStep = BACK_STEP_MAPPING[step];

    if (!backStep) {
      return null;
    }

    return (
      <>
        <div className="mb-5">
          <ButtonBack
            disabled={isProcessing}
            onClick={handleBackButtonClick}
          />
        </div>
      </>
    );
  };

  const renderStep = () => {
    switch (step) {
      case 'checkoutPricePlanStep':
        return (
          <CheckoutPricePlanStep
            isPaymentMethodCreated={isPaymentMethodCreated}
            isProcessing={isProcessing}
            switchedToAnnual={switchedToAnnual}
            showUpdatePaymentDetailsButton
            pricePlan={nextPricePlan}
            gracePeriod={primarySubscription.gracePeriod}
            onChangePlanPeriod={handleChangePlanPeriod}
            onCheckoutPricePlan={handleCheckoutPricePlan}
            onPaymentDetailsSaved={handleCheckoutPricePlan}
            onUpdatePaymentDetailsClick={() => setStep('updatePaymentDetailsStep')}
          />
        );
      case 'paymentErrorStep':
        return (
          <PaymentErrorStep
            isProcessing={isProcessing}
            onRetry={() => setStep('checkoutPricePlanStep')}
            onUpdatePaymentDetails={() => setStep('updatePaymentDetailsStep')}
          />
        );
      case 'updatePaymentDetailsStep':
        return (
          <>
            <h3 className="mt-0 mb-3">
              <PaymentDetailsActionText action="update" />
            </h3>
            <PaymentForm
              onSuccessSubmit={() => setStep('checkoutPricePlanStep')}
            />
          </>
        );
      case 'successActivationStep':
        return <FamilySubscriptionActivated />;
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (step === 'successActivationStep') {
      return renderStep();
    }

    return (
      <FullscreenLayout withLogo>
        <FullscreenLayout.PrimaryContent wideContainer>
          {renderBackButton()}
          {renderStep()}
        </FullscreenLayout.PrimaryContent>
      </FullscreenLayout>
    );
  };

  return renderContent();
};

export default ActivateFamilyFlow;
