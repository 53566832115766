import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedDate, FormattedMessage } from 'react-intl';
import type { RouteComponentProps } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';

import { getPrimarySubscription } from 'state/root-reducer';
import { Subscription } from 'state/subscription/subscription-initial-state';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import urls from 'config/urls';

import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import DefaultError from 'components/shared/default-error/default-error';

import byeIllustration from './images/bye.png';
import byeIllustration2x from './images/bye@2x.png';
import byeIllustration3x from './images/bye@3x.png';

import './successful-cancellation-page.scss';


export type Props = {
  history: {
    push: RouteComponentProps['history']['push'];
  };
  fetchAllSubscriptions: () => Promise<void>;
  showDangerNotification: (node: ReactElement) => void;
  primarySubscription: Subscription | undefined;
}

const SuccessfulCancellationPage = ({
  history,
  fetchAllSubscriptions,
  showDangerNotification,
  primarySubscription,
}: Props) => {
  useEffect(() => {
    fetchAllSubscriptions().catch(() => showDangerNotification(<DefaultError />));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (primarySubscription && !primarySubscription.expirationDate) {
      history.push(urls.subscription);
    }
  }, [primarySubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!primarySubscription) return <FullscreenLayoutLoading />;

  return (
    <FullscreenLayout>
      <FullscreenLayout.PrimaryContent withLogo>
        <div className="successful-cancellation-page__primary-content">
          <h3 className="mb-4" data-qa="pageTitle">
            <FormattedMessage id="successfulCancellation.title" defaultMessage="Your membership was canceled" />
          </h3>

          <div className="text_lg mb-8">
            <p className="mb-6">
              <FormattedMessage
                id="successfulCancellation.description.firstLine"
                defaultMessage="We've canceled your membership effective {expirationDate}. Enjoy Setapp until then."
                values={{
                  expirationDate: (
                    <strong>
                      <FormattedDate
                        value={(primarySubscription.expirationDate ?? 0) * 1000}
                        year="numeric"
                        month="short"
                        day="numeric"
                      />
                    </strong>
                  )
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="successfulCancellation.description.secondLine"
                defaultMessage="See your email for confirmation."
              />
            </p>
          </div>

          <Button onClick={() => history.push(urls.subscription)} size="lg">
            <FormattedMessage id="successfulCancellation.takeMeToAccountButton" defaultMessage="Take me to account" />
          </Button>
        </div>
      </FullscreenLayout.PrimaryContent>
      <FullscreenLayout.SecondaryContent>
        <div className="successful-cancellation-page__secondary-content">
          <img
            src={byeIllustration}
            srcSet={`${byeIllustration2x} 2x, ${byeIllustration3x} 3x`}
            alt="Bye illustration"
          />
        </div>
      </FullscreenLayout.SecondaryContent>
    </FullscreenLayout>
  );
};

export { SuccessfulCancellationPage as PureSuccessfulCancellationPage };

const mapStateToProps = (state) => ({
  primarySubscription: getPrimarySubscription(state),
});

const mapActionsToProps = {
  fetchAllSubscriptions,
  showDangerNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(SuccessfulCancellationPage);
