import React, { FC } from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';
import { createPortal } from 'react-dom';

import './fixed-button.scss';

type Props = {
  mobileInstallationUrl: string;
  handleInstallClick?: () => void;
}

const SetappMobileFixedBtn: FC<Props> = ({ mobileInstallationUrl, handleInstallClick }) => {
  const markup = (
    <div className="setapp-mobile-fixed">
      <Button block href={mobileInstallationUrl} onClick={handleInstallClick}>
        <FormattedMessage id="mobileOnboard.fixed.install" defaultMessage="Install Setapp Mobile" />
      </Button>
    </div>
  );

  return createPortal(markup, document.body);
};

export default SetappMobileFixedBtn;
