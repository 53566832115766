import { Button } from '@setapp/ui-kit';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import urls from 'config/urls';

const ActivateSubscriptionTrial: FC = () => (
  <div className="mt-10" data-testid="activateSubscriptionTrial">
      <h5>
        <FormattedMessage id="subscriptionInfo.activateSubscriptionTrial.title" defaultMessage="Start your Setapp free trial" />
      </h5>
      <p className="mb-8">
        <FormattedMessage
          id="subscriptionInfo.activateSubscriptionTrial.description"
          defaultMessage="Add your payment details and pick a Setapp plan to kick off your 7-day free trial."
        />
      </p>
      <Button href={urls.signupAddPaymentMethod}>
        <FormattedMessage id="subscriptionInfo.activateSubscriptionTrial.cta" defaultMessage="Set up free trial" />
      </Button>
  </div>
);


export default ActivateSubscriptionTrial;
