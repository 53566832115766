import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { removeHasAppAccessIssue } from 'components/pages/app-access-issue/utils/app-access-issue-storage';

const AppAccessGranted = () => {
  useEffect(() => {
    removeHasAppAccessIssue();
  }, []);

  return (
    <div>
      <h3>
        <FormattedMessage
          id="appAccessGranted.title"
          defaultMessage="All done! Your subscription is now active"
        />
      </h3>
      <div className="mb-10">
        <FormattedMessage
          id="appAccessGranted.subTitle"
          defaultMessage="Just close this page to use Setapp Mobile and its apps."
        />
      </div>
    </div>
  );
};


export default AppAccessGranted;
