import { getTokenManager } from '../service-locators';

const tokenManager = getTokenManager();

const injectAuth = (next) => (url, options) => {
  const { withoutTokenAuth, ...requestOptions } = options;

  if (withoutTokenAuth) {
    return next(url, requestOptions);
  }

  return next(url, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers,
      Authorization: `Bearer ${tokenManager.getAccessToken()}`,
    },
  });
};

export default injectAuth;
