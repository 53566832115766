import { useState } from 'react';
import Cookies from 'js-cookie';
import { useWizardListener } from '@mwkt/react-magic-frame';

import { COOKIES_ROOT_DOMAIN } from 'config/app';
import * as authConfig from 'config/auth';

const IS_STRICT_ENV = process.env.ENV_NAME !== 'development';

const COOKIES_SETTINGS = {
  domain: COOKIES_ROOT_DOMAIN,
  secure: authConfig.SECURE_COOKIES,
  sameSite: IS_STRICT_ENV ? 'Lax' : 'none',
};

const useAccessTokenGuard = () => {
  const [isAccessTokenSet, setIsAccessTokenSet] = useState(false);

  useWizardListener('wizard:setAccessToken', (data) => {
    Cookies.set(authConfig.ACCESS_TOKEN_COOKIE, data.accessToken, COOKIES_SETTINGS);
    Cookies.set(
      authConfig.REFRESH_TOKEN_COOKIE,
      data.refreshToken,
      { ...COOKIES_SETTINGS, expires: authConfig.REMEMBER_ME_PERIOD },
    );

    setIsAccessTokenSet(true);
  });

  return isAccessTokenSet;
};

export default useAccessTokenGuard;
