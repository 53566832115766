import React, { ReactNode } from 'react';
import type { SyntheticEvent } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import Form from 'react-bootstrap/lib/Form';
import { FormField, Button } from '@setapp/ui-kit';

import './family-invite-form.scss';

type Props = InjectedIntlProps & {
  isFamilyProcessing: boolean;
  email: string;
  emailError: ReactNode;
  onFieldChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent<HTMLFormElement>) => Promise<void>;
};

const messages = defineMessages({
  emailPlaceholder: {
    id: 'familyInviteForm.placeholder',
    defaultMessage: 'Type an email to invite',
  },
});

const FamilyInviteForm = (props: Props) => {
  const {
    intl,
    onFieldChange,
    email,
    emailError,
    onSubmit,
    isFamilyProcessing,
  } = props;

  return (
    <>
      <Form
        onSubmit={onSubmit}
        className="family-invite-form"
        noValidate
      >
        <div className="family-invite-form__container">
          <div className="family-invite-form__input">
            <FormField
              className="input-lg"
              id="email"
              name="email"
              type="email"
              value={email}
              invalid={Boolean(emailError)}
              helpText={emailError}
              placeholder={intl.formatMessage(messages.emailPlaceholder)}
              label={intl.formatMessage(messages.emailPlaceholder)}
              onChange={onFieldChange}
            />
          </div>
          <div className="family-invite-form__action">
            <Button
              type="submit"
              block
              size="lg"
              disabled={isFamilyProcessing}
            >
              <FormattedMessage
                id="familyInviteForm.submitButton"
                defaultMessage="Invite now"
              />
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default injectIntl(FamilyInviteForm);
