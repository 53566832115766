import React from 'react';
import type { ReactNode } from 'react';

import './sidebar-nav-section.scss';

type Props = {
  children: ReactNode;
}

const SidebarNavSection = ({ children }: Props) => (
  <ul className="sidebar-nav__section">
    {children}
  </ul>
);

export default SidebarNavSection;
