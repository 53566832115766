import React, { FC } from 'react';
import { useSubscriptionContext } from 'components/pages/subscription/context/subscription-context';
import FamilyOwner from './family-owner/family-owner';
import FamilyMember from './family-member/family-member';
import OldFamilyMember from './old-family-member/old-family-member';

const FamilySubscriptionInfo: FC = () => {
  const { taxAmount, pricePlan, subscription, familyInfoType } = useSubscriptionContext();

  switch (familyInfoType) {
    case 'owner':
      return (
        <FamilyOwner
          tax={taxAmount}
          subscription={subscription}
          pricePlan={pricePlan}
        />
      );
    case 'member':
      return (
        <FamilyMember
          expirationDate={subscription.expirationDate}
        />
      );
    case 'oldMember':
      return (
        <OldFamilyMember
          expirationDate={subscription.expirationDate}
          status={subscription.status}
        />
      );
    default:
      return null;
  }
};

export default FamilySubscriptionInfo;
