import RequestError from '@setapp/request-error';

import type { RedeemCode } from './redeem-code-initial-state';

export const REDEEM_CODE_REQUEST = 'REDEEM_CODE_REQUEST';
export const REDEEM_CODE_REQUEST_SUCCESS = 'REDEEM_CODE_REQUEST_SUCCESS';
export const REDEEM_CODE_REQUEST_ERROR = 'REDEEM_CODE_REQUEST_ERROR';

export type FetchRedeemCodeAction = {
  type: 'REDEEM_CODE_REQUEST';
};
export type FetchRedeemCodeSuccessAction = {
  type: 'REDEEM_CODE_REQUEST_SUCCESS';
  payload?: RedeemCode;
};
export type FetchRedeemCodeErrorAction = {
  type: 'REDEEM_CODE_REQUEST_ERROR';
  payload: RequestError;
  error: true;
};

export type RedeemCodeAction =
  | FetchRedeemCodeAction
  | FetchRedeemCodeSuccessAction
  | FetchRedeemCodeErrorAction
