import React, { ReactNode, SyntheticEvent, useCallback } from 'react';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
} from 'react-intl';
import {
  FormField,
  ButtonLoading,
  Button,
  FormFieldWrapper,
} from '@setapp/ui-kit';
import FormControl from 'react-bootstrap/lib/FormControl';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import classnames from 'classnames';

import SocialAuthButtons from 'components/shared/social-auth-buttons/social-auth-buttons';

import './login-form.scss';

export type LoginFormFields = {
  email: string;
  password: string;
  captcha?: string;
}

type Props = {
  onForgotPassword: (email: string) => void;
  onFormSubmit: (e: SyntheticEvent<HTMLFormElement>) => void;
  onEmailChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  onPasswordChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  isRequestProcessing: boolean;
  emailError: ReactNode;
  passwordError: ReactNode;
  genericError: ReactNode;
  email: string;
  password: string;
  captcha?: ReactNode;
  innerRedirectUri: string;
} & InjectedIntlProps;

const messages = defineMessages({
  emailPlaceholder: {
    id: 'oauth.login.emailPlaceholder',
    defaultMessage: 'Your email',
  },
  passwordPlaceholder: {
    id: 'oauth.login.passwordPlaceholder',
    defaultMessage: 'Your password',
  },
});

const LoginForm = (props: Props) => {
  const {
    intl,
    captcha,
    email,
    emailError,
    password,
    passwordError,
    genericError,
    onFormSubmit,
    onEmailChange,
    onPasswordChange,
    onForgotPassword,
    innerRedirectUri,
    isRequestProcessing,
  } = props;

  const handleForgotButtonClick = useCallback(() => onForgotPassword(email), [onForgotPassword, email]);

  const errorClasses = classnames('form-error text-danger text_sm', {
    hidden: !genericError,
  });

  return (
    <div className="oauth-login-form__container">
      <form onSubmit={onFormSubmit} noValidate data-qa="loginForm">
        <h3 className="oauth-login-form__title">
          <FormattedMessage id="oauth.login.title" defaultMessage="Sign in to your account" />
        </h3>

        <FormField
          autoFocus
          bsSize="lg"
          id="email"
          type="email"
          name="email"
          autoComplete="email"
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          helpText={emailError}
          invalid={Boolean(emailError)}
          onChange={onEmailChange}
          value={email}
          qaLabel="emailFormGroup"
        />

        <FormFieldWrapper
          id="password"
          invalid={Boolean(passwordError)}
          helpText={passwordError}
          qaLabel="passwordFormGroup"
        >
          <InputGroup>
            <FormControl
              bsSize="lg"
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              placeholder={intl.formatMessage(messages.passwordPlaceholder)}
              value={password}
              onChange={onPasswordChange}
            />
            <InputGroup.Addon>
              <Button variant="link" onClick={handleForgotButtonClick}>
                <FormattedMessage id="oauth.login.forgotLinkTitle" defaultMessage="Forgot?" />
              </Button>
            </InputGroup.Addon>
          </InputGroup>
        </FormFieldWrapper>

        <p className={errorClasses}>{genericError}</p>

        {captcha}

        <ButtonLoading
          block
          size="lg"
          type="submit"
          disabled={isRequestProcessing}
          isLoading={isRequestProcessing}
        >
          <FormattedMessage id="oauth.login.submitBtnCaption" defaultMessage="Sign In" />
        </ButtonLoading>

        <div className="text-center oauth-login-form__social-buttons-caption">
          <FormattedMessage id="oauth.login.continueWithLabel" defaultMessage="Or continue with" />
        </div>

        <SocialAuthButtons
          size="lg"
          className="oauth-login-form__social-buttons"
          signupParams={{ innerRedirectUri }}
        />
      </form>
    </div>
  );
};

LoginForm.defaultProps = {
  isRequestProcessing: false,
  emailError: '',
  passwordError: '',
  genericError: '',
  email: '',
  password: '',
};

export { LoginForm as PureLoginForm };

export default injectIntl(LoginForm);
