import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { removeQueryParams } from 'utils/location';
import { getAppsCount } from 'state/root-reducer';

import macImage from 'components/shared/shared-images/mac-tablet-iphone@1x.png';
import macImage2x from 'components/shared/shared-images/mac-tablet-iphone@2x.png';
import macImage3x from 'components/shared/shared-images/mac-tablet-iphone@3x.png';

import './popup-about-download.scss';

export const SHOULD_SHOW_POPUP_QUERY = 'showPopup';

const PopupAboutDownload = () => {
  const history = useHistory();
  const appsCount = useSelector(getAppsCount);
  const [popupState, setPopupState] = useState<'appear' | 'disappear' | 'hide'>('hide');

  useEffect(() => {
    const query = queryString.parse(history.location.search);

    setPopupState(SHOULD_SHOW_POPUP_QUERY in query ? 'appear' : 'hide');

    removeQueryParams(history, SHOULD_SHOW_POPUP_QUERY);
  }, [history]);

  const hidePopup = () => setPopupState('disappear');

  const backgroundClasses = classNames(
    'popup-about-download__background', {
      'popup-about-download__background_disappear': popupState === 'disappear',
    }
  );

  const contentClasses = classNames(
    'popup-about-download__content', {
      'popup-about-download__content_disappear': popupState === 'disappear',
    }
  );

  if (popupState === 'hide') return null;

  return (
    <section className={backgroundClasses}>
      <div
        className={contentClasses}
        role="dialog"
        aria-modal="true"
        aria-labelledby="popup-title"
        aria-describedby="popup-text"
      >
        <img
          className="popup-about-download__image"
          src={macImage}
          srcSet={`${macImage2x} 2x, ${macImage3x} 3x`}
          width="311"
          height="181"
          alt=""
        />
        <h4 className="mt-0" id="popup-title">
          <FormattedMessage
            id="ios-apps.popup-about-download.title"
            defaultMessage="Download Setapp on your Mac"
          />
        </h4>
        <span id="popup-text" className="mb-8">
          <p>
            <FormattedMessage
              id="ios-apps.popup-about-download.check-inbox"
              defaultMessage="Check your inbox, we’ve sent you an email with an installation link."
            />
          </p>
          <FormattedMessage
            id="ios-apps.popup-about-download.open-and-download"
            defaultMessage="Open it on your Mac, click Download, and find your tools among {appsCount}+ Mac, Web, and iOS apps."
            values={{ appsCount }}
          />
          {' '}
          <b>
            <FormattedMessage
              id="ios-apps.popup-about-download.included"
              defaultMessage="Free and already included in your plan."
            />
          </b>
        </span>
        <Button
          block
          onClick={hidePopup}
        >
          <FormattedMessage
            id="ios-apps.popup-about-download.button"
            defaultMessage="Ok"
          />
        </Button>
      </div>
    </section>
  );
};

export default PopupAboutDownload;
