// @flow

import React from 'react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';

import type { Node, ElementConfig } from 'react';
import type { IntlShape } from 'react-intl';

import { FormFieldWrapper, Tooltip } from '@setapp/ui-kit';
import FormControlFeedback from 'react-bootstrap/lib/FormControlFeedback';

import globalMessages from '../../../../../global-messages';

import './braintree-field.scss';
import { useBrainTreeFieldsContext, type BrainTreeFieldsContextType } from '../braintree-fields-context';
import lock from './images/lock.svg';


type Props = {|
  id: string,
  label: string,
  placeholder?: string,
  name: 'number' | 'cvv' | 'expirationDate',
  disabled?: boolean,
  errorMessage?: Node,
  tooltip: Node,
  intl: IntlShape,
  ...ElementConfig<typeof FormFieldWrapper>,
|};

const BraintreeFormField = (props: Props) => {
  const {
    id,
    placeholder,
    disabled,
    name,
    tooltip,
    intl,
    ...wrapperProps
  } = props;

  const { focusedField, isInitialized }: BrainTreeFieldsContextType = useBrainTreeFieldsContext();

  const fieldClasses = classNames(
    'form-control braintree-field',
    { 'form-control-disabled': disabled },
    { 'braintree-field_focus': focusedField === name },
  );

  return (
    <FormFieldWrapper {...wrapperProps} id={id}>
      {tooltip && (
        <Tooltip
          trigger="click"
          position="bottom"
          content={tooltip}
          useContext
        >
          <button
            type="button"
            className="btn braintree-field__tooltip-button"
            aria-label={intl.formatMessage(globalMessages.btnTooltipAccessibilityLabel)}
          >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 7C0 3.13379 3.13379 0 7 0C10.8662 0 14 3.13379 14 7C14 10.8662 10.8662 14 7 14C3.13379 14 0 10.8662 0 7ZM6 4C6 3.90039 6.01465 3.80469 6.04199 3.71387C6.16406 3.30078 6.54688 3 7 3C7.55273 3 8 3.44727 8 4C8 4.55273 7.55273 5 7 5C6.44727 5 6 4.55273 6 4ZM7 6C6.44727 6 6 6.44727 6 7V10C6 10.5527 6.44727 11 7 11C7.55273 11 8 10.5527 8 10V7C8 6.62988 7.79883 6.30664 7.5 6.13379C7.35352 6.04883 7.18262 6 7 6Z" fill="#E5E5E5" />
            </svg>
          </button>
        </Tooltip>
      )}
      <div id={id} className={fieldClasses}>
        {placeholder && !isInitialized && <span>{placeholder}</span>}
      </div>

      <FormControlFeedback>
        <div>
          <img src={lock} alt="" />
        </div>
      </FormControlFeedback>
    </FormFieldWrapper>
  );
};

BraintreeFormField.defaultProps = {
  disabled: false,
  errorMessage: '',
  placeholder: '',
  tooltip: null,
};


export { BraintreeFormField as PureBraintreeFormField };

export default injectIntl(BraintreeFormField);
