import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { getIsSetappMobileAvailable } from 'state/root-reducer';

import { isPowerUserPricePlan } from 'services/price-plans/utils';

import ErrorMessage from 'components/shared/error-message/error-message';
import PricePerMonth from 'components/shared/price-per-month/price-per-month';
import successIcon from './images/success-icon.svg';

import './price-plan-card.scss';

type Props = Readonly<{
  isCurrent?: boolean;
  isPrimary?: boolean;
  isLoading?: boolean;
  errorMessage?: ReactNode;
  pricePlan: PricePlan;
  title: ReactNode;
  description: ReactNode;
  buttonTitle: ReactNode;
  onSelectPlanClick: (pricePlan: PricePlan) => void;
}>;

const PricePlanCard = (props: Props) => {
  const {
    isCurrent = false,
    isPrimary = false,
    isLoading = false,
    pricePlan,
    title,
    description,
    buttonTitle,
    errorMessage,
    onSelectPlanClick,
  } = props;
  const isSetappMobileAvailable = useSelector(getIsSetappMobileAvailable);
  const showSetappMobileAvailablePoint = isSetappMobileAvailable && isPowerUserPricePlan(pricePlan.tierType);

  const cardClasses = classNames('price-plan-card', {
    'price-plan-card_current': isCurrent,
    'price-plan-card_primary price-plan-card_with-subtitle': isPrimary,
  });

  if (!pricePlan.features) {
    return null;
  }

  const macNumbersMessage = isSetappMobileAvailable ? (
    <FormattedMessage
      id="pricePlanCard.macNumbers"
      defaultMessage="{macNumber} {macNumber, plural, one {Mac} other {Macs}}"
      values={{ macNumber: pricePlan.features.macSeatsCount }}
    />
  ) : (
    <FormattedMessage
      id="pricePlanCard.macNumbers.for"
      defaultMessage="For {macNumber} {macNumber, plural, one {Mac} other {Macs}}"
      values={{ macNumber: pricePlan.features.macSeatsCount }}
    />
  );

  const iosNumbersMessage = isSetappMobileAvailable ? (
    <FormattedMessage
      id="pricePlanCard.iosNumbers"
      defaultMessage="{iosNumber} iOS {iosNumber, plural, one {device} other {devices}}{shouldIncludeSetappMobile, select, true {&nbsp;+ Setapp Mobile} other {}}"
      values={{
        iosNumber: pricePlan.features.iosSeatsCount,
        shouldIncludeSetappMobile: showSetappMobileAvailablePoint
      }}
    />
  ) : (
    <FormattedMessage
      id="pricePlanCard.iosNumbers.for"
      defaultMessage="For {iosNumber} iOS {iosNumber, plural, one {device} other {devices}}"
      values={{ iosNumber: pricePlan.features.iosSeatsCount }}
    />
  );

  return (
    <div className={cardClasses}>
      <div className="price-plan-card__header">
        <div>
          <div className="h4 mt-0 mb-0">{title}</div>
          {isPrimary && (
            <div className="price-plan-card__subtitle text_sm text_weight-medium text_color-secondary">
              <FormattedMessage
                id="pricePlanCard.subtitle"
                defaultMessage="Best value for your Mac and iOS devices"
              />
            </div>
          )}
        </div>
        {isCurrent && <img src={successIcon} className="mt-1" alt="Current plan icon" />}
      </div>
      <div className="price-plan-card__body text_lg">
        <div className="h2 mb-5">
          <PricePerMonth pricePlan={pricePlan} />
        </div>
        <p className="mb-4">{description}</p>
        <ul className="price-plan-card__list">
          {Boolean(pricePlan.features.macSeatsCount) && (
            <li className="price-plan-card__list-item mb-2">
              {macNumbersMessage}
            </li>
          )}
          {Boolean(pricePlan.features.iosSeatsCount) && (
            <li className="price-plan-card__list-item mb-2">
              {iosNumbersMessage}
            </li>
          )}
        </ul>
      </div>
      <ButtonLoading
        size="lg"
        disabled={isCurrent}
        isLoading={isLoading}
        variant={isCurrent ? 'primary-outline' : 'primary'}
        block
        onClick={() => onSelectPlanClick(pricePlan)}
      >
        {buttonTitle}
      </ButtonLoading>
      {errorMessage && (
        <ErrorMessage className="mt-4">{errorMessage}</ErrorMessage>
      )}
    </div>
  );
};

export default PricePlanCard;
