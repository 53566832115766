import { apiURL } from 'config/api';
import request from 'utils/request';

type CustomerOauthCodeResult = {
  redirectUrl: string;
}

const getCustomerOauthCode = async ({ deviceId, clientId, redirectUrl }): Promise<CustomerOauthCodeResult> => {
  try {
    const data = await request.post(`${apiURL.customerOauth}/auth_code`, {
      body: { deviceId, clientId, redirectUrl },
    });

    return data;
  } catch (error: any) {
    throw error.errors?.[0]?.message || 'UNKNOWN_ERROR';
  }
};

export default getCustomerOauthCode;
