import React from 'react';

import Modal from 'components/shared/modal/modal';
import ConfirmLoseDiscount from 'components/shared/confirm-lose-discount/confirm-lose-discount';

type Props = {
  show: boolean;
  onHide: () => void;
  onExited?: () => void;
  onConfirm: () => void;
};

const ConfirmLoseDiscountModal = ({ show, onHide, onExited, onConfirm }: Props) => (
  <Modal
    fullScreen
    show={show}
    onHide={onHide}
    onExited={onExited}
  >
    <ConfirmLoseDiscount
      onConfirm={onConfirm}
      onGoBackward={onHide}
    />
  </Modal>
);

export default ConfirmLoseDiscountModal;
