import React from 'react';

import type { GiftCardType as GiftCardTypeType } from 'state/gift-cards/gift-cards-reducer';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import PanelBox from 'components/shared/panel-box/panel-box';

import GiftCard from '../gift-card/gift-card';

import './gift-card-type.scss';

type Props = GiftCardTypeType & {
  onClick: (number) => unknown;
};

const GiftCardType = ({
  price, currency, paidMonths, onClick, id,
}: Props) => {
  function onKeyPress(event) {
    const { key } = event;

    if (key !== ' ' && key !== 'Enter') {
      return;
    }

    event.preventDefault();
    onClick(id);
  }

  // WARNING: we hardcode 3 gift card types for now. In production we expect only 1, 3 and 12 months gift cards
  const isBetterValueGift = paidMonths === 12;

  return (
    <div role="button" tabIndex={0} className="gift-card-type" onClick={() => onClick(id)} onKeyPress={onKeyPress}>
      <PanelBox>
        <PanelBox.Content>
          {isBetterValueGift && (
            <div className="gift-card-type__badge">
              <span className="gift-card-type__badge-text">-10%</span>
            </div>
          )}
          <div className="gift-card-type__price-row">
            <div className="gift-card-type__price">
              <FormattedPrice price={price} currency={currency} />
            </div>
            {isBetterValueGift && (
              <div className="gift-card-type__price gift-card-type__price_crossed">
                <FormattedPrice price={119.88} currency={currency} />
              </div>
            )}
          </div>
          <GiftCard paidMonths={paidMonths} />
        </PanelBox.Content>
      </PanelBox>
    </div>
  );
};

export default GiftCardType;
