import React, { PureComponent } from 'react';
import type { ReactNode } from 'react';
import { connect } from 'react-redux';

import type {
  PaymentDetailsOptions,
  PaymentMethodType,
  PaymentProviderName,
} from 'services/payment-details-api/payment-details-api';

import BraintreePaymentForm from 'components/payment-details/payment-form/payment-form';

import { getUser } from 'state/root-reducer';
import { UserLocale } from 'state/user/user-initial-state';

import SetappFakePaymentForm from './setapp-fake-payment-form/setapp-fake-payment-form';
import PaddlePaymentForm from './paddle-payment-form/paddle-payment-form';

export type BraintreeFormOptions = Partial<{
  paymentMethod: PaymentMethodType;
  submitBtnTitle: ReactNode;
  loadingText: ReactNode;
}>

type Props = {
  provider: PaymentProviderName;
  userLocale: UserLocale;
  onSuccessSubmit?: () => unknown;
  paymentDetailsOptions?: PaymentDetailsOptions;
  braintreeFormOptions?: BraintreeFormOptions;
  paymentMethodOptions: {
    amount: number;
    currencyCode: string;
  };
};

class PaymentForm extends PureComponent<Props> {
  static defaultProps = {
    paymentMethodOptions: {
      amount: 0,
      currencyCode: 'USD',
    },
  };

  render() {
    const {
      provider,
      userLocale,
      paymentDetailsOptions,
      braintreeFormOptions,
      paymentMethodOptions,
      onSuccessSubmit,
    } = this.props;

    // Setapp fake form is used only on test env for e2e tests
    if (provider === 'setapp' && process.env.ENV_NAME !== 'production') {
      return <SetappFakePaymentForm onSuccessSubmit={onSuccessSubmit} />;
    }

    if (provider === 'paddle') {
      return <PaddlePaymentForm paymentDetailsOptions={paymentDetailsOptions} onSuccessSubmit={onSuccessSubmit} />;
    }

    return (
      <BraintreePaymentForm
        {...braintreeFormOptions}
        paymentDetailsOptions={paymentDetailsOptions}
        paymentMethodOptions={paymentMethodOptions}
        onSuccessSubmit={onSuccessSubmit}
        userLocale={userLocale}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  provider: getUser(state).paymentProvider,
  userLocale: getUser(state).locale,
});

export default connect(mapStateToProps)(PaymentForm);
