// @flow

import { type RouterHistory } from 'react-router-dom';
import desktopAppHelper from './desktop-app-helper';
import { removeQueryParams } from './location';
import logger from './logger';

export default function openDesktopIfRequired(history: RouterHistory, query: {[string]: string}) {
  const OPEN_DESKTOP_QUERY_PARAM = 'openDesktop';
  const APP_ID_QUERY_PARAM = 'appId';

  const isOpenDesktopInQuery = OPEN_DESKTOP_QUERY_PARAM in query;

  if (!isOpenDesktopInQuery) {
    return Promise.resolve();
  }

  const appId = query[APP_ID_QUERY_PARAM] || null;

  removeQueryParams(history, OPEN_DESKTOP_QUERY_PARAM, APP_ID_QUERY_PARAM);

  return desktopAppHelper.openDesktopApp({ appId })
    .catch((error) => {
      logger.logWarn('Could not open Setapp desktop client. Looks like it\'s not available at the moment', error);
    });
}
