import React from 'react';
import type { FormEvent, ReactNode } from 'react';
import classnames from 'classnames';

import { ButtonLoading } from '@setapp/ui-kit';

import './outer-form.scss';

type Props = {
  title: ReactNode;
  subTitle?: ReactNode;
  submitBtnCaption: ReactNode;
  footer?: ReactNode;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isSubmitProcessing?: boolean;
  errorMessage?: ReactNode;
  children?: ReactNode;
  hideSubmit?: boolean;
  qaLabel?: string;
};

const OuterForm = (props: Props) => {
  const {
    title,
    subTitle,
    submitBtnCaption,
    footer,
    onSubmit,
    errorMessage,
    isSubmitProcessing = false,
    qaLabel,
    children,
    hideSubmit = false,
  } = props;
  const errorClasses = classnames('form-error text-danger', {
    hidden: !errorMessage,
  });
  const btnClasses = classnames({
    processing: isSubmitProcessing,
    hidden: hideSubmit,
  });

  return (
    <div className="outer-form__container outer-form__container_wide">
      <div className="outer-form__wrapper">
        <form className="outer-form" onSubmit={onSubmit} noValidate data-qa={qaLabel}>
          <h1 className="h5 text-center">{title}</h1>
          {subTitle && <p className="text-center mb-6">{subTitle}</p>}

          {children}

          <p className={errorClasses}>{errorMessage}</p>
          <ButtonLoading
            block
            className={btnClasses}
            type="submit"
            disabled={isSubmitProcessing}
            isLoading={isSubmitProcessing}
            data-qa="submitButton"
          >
            {submitBtnCaption}
          </ButtonLoading>
        </form>
        {footer}
      </div>
    </div>
  );
};

export default OuterForm;
