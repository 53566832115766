import { ComponentProps } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Dispatch } from 'state/state-types';
import modalComponentsByModalType, { ModalType } from 'components/modals';

/**
 * Modal props are calculated based on modal type.
 * We make 'show', 'onHide', and 'onExited' optional because
 * in modal-root.tsx we handle these props, no need to pass them in actions
 */
export type ModalProps<T extends ModalType = ModalType>
  = Omit<ComponentProps<typeof modalComponentsByModalType[T]>, 'show' | 'onHide' | 'onExited' | 'history'> & {
    show?: boolean;
    onHide?: () => void;
    onExited?: () => void;
  };

type ModalState = {
  modalType?: ModalType;
  modalProps?: ModalProps;
  isVisible: boolean;
};

type ModalPayload = {
  modalType?: ModalType;
  modalProps?: ModalProps;
};

export const initialState: ModalState = {
  modalType: undefined,
  modalProps: undefined,
  isVisible: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<ModalPayload>) {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
      state.isVisible = true;
    },
    removeModal(state) {
      state.modalType = undefined;
      state.modalProps = undefined;
      state.isVisible = false;
    },
    hideModal(state) {
      state.isVisible = false;
    },
  },
});

const {
  showModal: showModalAction,
  hideModal: hideModalAction,
  removeModal: removeModalAction
} = modalSlice.actions;

export const showModal = <T extends ModalType>(
  modalType: T,
  modalProps?: ModalProps<T>
) => (dispatch: Dispatch) => dispatch(showModalAction({ modalType, modalProps }));
export const hideModal = hideModalAction;
export const removeModal = removeModalAction;

export default modalSlice.reducer;
