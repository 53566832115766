import React, { Component, SyntheticEvent, ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  FormattedMessage, defineMessages, injectIntl,
} from 'react-intl';
import { FormField, Button } from '@setapp/ui-kit';

import type { InjectedIntlProps } from 'react-intl';

import validate from 'utils/auth-validation';

import { updateUser } from 'state/user/user-actions';
import { getUser } from 'state/root-reducer';

import type { UpdateUserParams } from 'state/user/user-actions';

import analytics, { events } from 'utils/analytics';

import { showSuccessNotification } from 'state/notifier/notifier-reducer';

const messages = defineMessages({
  emailRequired: {
    id: 'accountSettings.personalInfo.validation.emptyEmail',
    defaultMessage: 'Email is required',
  },
  emailInvalid: {
    id: 'accountSettings.personalInfo.validation.invalidEmail',
    defaultMessage: 'Invalid email',
  },
  passwordRequired: {
    id: 'accountSettings.personalInfo.validation.emptyPassword',
    defaultMessage: 'Password is required',
  },
  nameLabel: {
    id: 'accountSettings.personalInfo.nameLabel',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'accountSettings.personalInfo.namePlaceholder',
    defaultMessage: 'Enter your name',
  },
  emailLabel: {
    id: 'accountSettings.personalInfo.emailLabel',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'accountSettings.personalInfo.emailPlaceholder',
    defaultMessage: 'Enter your email',
  },
  passwordLabel: {
    id: 'accountSettings.personalInfo.passwordLabel',
    defaultMessage: 'Setapp password',
  },
  passwordPlaceholder: {
    id: 'accountSettings.personalInfo.passwordPlaceholder',
    defaultMessage: 'Enter your Setapp password',
  },
  successUpdateNotification: {
    id: 'accountSettings.personalInfo.successNotification',
    defaultMessage: 'Personal information successfully updated.',
  },
});

type Props = {
  updateUser: (params: UpdateUserParams) => Promise<void>;
  email: string;
  name: string;
  isLoading: boolean;
  showSuccessNotification: (message: ReactElement | string) => void;
} & InjectedIntlProps;

type State = {
  fields: {
    currentPassword: string;
    name: string;
    email: string;
  };
  fieldsErrors: {
    currentPassword: string;
    name: string;
    email: string;
  };
  showPassword: boolean;
};

class UserInfo extends Component<Props, State> {
  static defaultProps = {
    name: '',
    isLoading: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      fields: {
        currentPassword: '',
        name: props.name,
        email: props.email,
      },
      fieldsErrors: {
        name: '',
        email: '',
        currentPassword: '',
      },
      showPassword: false,
    };
  }

  render() {
    const {
      intl,
      name,
      email,
      isLoading,
    } = this.props;
    const {
      fieldsErrors,
      fields,
      showPassword,
    } = this.state;

    return (
      <div>
        <h5 className="account-settings-section__title">
          <FormattedMessage
            id="accountSettings.personalInfo.title"
            defaultMessage="Personal information"
          />
        </h5>
        <form onSubmit={this.onFormSubmit} noValidate>
          <FormField
            id="name"
            name="name"
            type="text"
            defaultValue={name}
            label={intl.formatMessage(messages.nameLabel)}
            placeholder={intl.formatMessage(messages.namePlaceholder)}
            onChange={this.onFormChange}
            helpText={fieldsErrors.name}
            invalid={Boolean(fieldsErrors.name)}
            autoComplete="new-name"
            showLabel
          />
          <FormField
            id="email"
            name="email"
            type="email"
            defaultValue={email}
            label={intl.formatMessage(messages.emailLabel)}
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            onChange={this.onFormChange}
            helpText={fieldsErrors.email}
            invalid={Boolean(fieldsErrors.email)}
            autoComplete="new-email"
            showLabel
          />
          {showPassword && (
            <FormField
              id="currentPasswordForEmailChange"
              name="currentPassword"
              type="password"
              value={fields.currentPassword}
              label={intl.formatMessage(messages.passwordLabel)}
              placeholder={intl.formatMessage(messages.passwordPlaceholder)}
              onChange={this.onFormChange}
              helpText={fieldsErrors.currentPassword}
              invalid={Boolean(fieldsErrors.currentPassword)}
              autoComplete="new-password"
              showLabel
            />
          )}

          <Button
            variant="secondary-outline"
            type="submit"
            size="lg"
            disabled={isLoading}
            data-qa="updateInformationBtn"
          >
            <FormattedMessage
              id="accountSettings.personalInfo.updateButton"
              defaultMessage="Update info"
            />
          </Button>
        </form>
      </div>
    );
  }

  getValidation() {
    const { intl } = this.props;

    return {
      email: {
        required: intl.formatMessage(messages.emailRequired),
        emailFormat: intl.formatMessage(messages.emailInvalid),
      },
      currentPassword: {
        required: intl.formatMessage(messages.passwordRequired),
      },
    };
  }

  onFormChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setFieldValue(e.currentTarget.name, e.currentTarget.value);
    const { showPassword } = this.state;

    if (e.currentTarget.name === 'email' && !showPassword) {
      this.showPasswordField();
    }
  };

  hidePasswordField() {
    this.setState({ showPassword: false });
  }

  showPasswordField() {
    this.setState({ showPassword: true });
  }

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { fields } = this.state;
    const { props } = this;
    const { email, updateUser } = this.props;

    const changedFields = ['email', 'name']
      .filter((fieldName) => fields[fieldName] !== props[fieldName])
      .reduce((result: Record<string, string>, fieldName: string) => ({
        ...result,
        [fieldName]: fields[fieldName],
      }), {});

    if (!Object.keys(changedFields).length) {
      this.hidePasswordField();
      this.displaySuccessNotification();

      return;
    }

    if (changedFields.email) {
      changedFields.currentPassword = fields.currentPassword;
    }

    const fieldsErrors = validate(changedFields, this.getValidation());
    const formHasError = Object.keys(fieldsErrors).some((field) => fieldsErrors[field]);

    // @ts-expect-error TS(2739): Type 'Messages<string>' is missing the following p... Remove this comment to see the full error message
    this.setState({ fieldsErrors });

    if (!formHasError) {
      const oldUserEmail = email;

      updateUser(changedFields)
        .then(() => {
          if (changedFields.email) {
            analytics.trackEvent(events.ACCOUNT_EMAIL_CHANGED, { eventLabel: oldUserEmail });
            this.setFieldValue('currentPassword', '');
          }
          this.hidePasswordField();

          this.displaySuccessNotification();
        })
        .catch((error) => {
          this.setState({ ...error.getSimplifiedErrors() });
        });
    }
  };

  setFieldValue(name: string, value: string) {
    this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
  }

  displaySuccessNotification() {
    const { intl } = this.props;
    const { showSuccessNotification } = this.props;

    showSuccessNotification(intl.formatMessage(messages.successUpdateNotification));
  }
}

export { UserInfo };

const mapStateToProps = (state) => (getUser(state));
const mapActionsToProps = {
  updateUser,
  showSuccessNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(UserInfo));
