import React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';

import CollapsibleGroup from 'components/shared/collapsible-group/collapsible-group';

const messages = defineMessages({
  faqItem1Title: {
    id: 'tryFamilyPlan.faq.item1.title',
    defaultMessage: 'How do I switch to Family from my current plan?',
  },
  faqItem1Body: {
    id: 'tryFamilyPlan.faq.item1.body',
    defaultMessage: 'Go to Subscription tab in your Setapp profile on the web and click Manage subscription.',
  },
  faqItem2Title: {
    id: 'tryFamilyPlan.faq.item2.title',
    defaultMessage: 'How do I add people to my Family plan?',
  },
  faqItem2Body: {
    id: 'tryFamilyPlan.faq.item2.body',
    defaultMessage: 'After you get this plan, you will be able to send email invites to new Family participants in Manage Family tab in your Setapp profile.',
  },
  faqItem3Title: {
    id: 'tryFamilyPlan.faq.item3.title',
    defaultMessage: 'What if I want to remove someone from my Family plan?',
  },
  faqItem3Body: {
    id: 'tryFamilyPlan.faq.item3.body',
    defaultMessage: 'Add or remove your Family participants in Manage Family tab in your Setapp profile.',
  },
  faqItem4Title: {
    id: 'tryFamilyPlan.faq.item4.title',
    defaultMessage: 'Can I add an existing Setapp subscriber to my Family plan?',
  },
  faqItem4Body: {
    id: 'tryFamilyPlan.faq.item4.body',
    defaultMessage: 'Yes! Invite anyone you want to your Setapp Family! Note that existing Setapp users switching to Setapp Family will lose any days they have left on their current plan.',
  },
  faqItem5Title: {
    id: 'tryFamilyPlan.faq.item5.title',
    defaultMessage: 'What if someone on my Family plan wants to switch to their own plan?',
  },
  faqItem5Body: {
    id: 'tryFamilyPlan.faq.item5.body',
    defaultMessage: 'Your Family participants can switch to their own Setapp plan at any time.',
  },
});

type Props = InjectedIntlProps;

const FaqList = (props: Props) => {
  const { intl } = props;

  const faqItems = Array(5).fill(null).map((_, index) => ({
    title: intl.formatMessage(messages[`faqItem${index + 1}Title`]),
    body: intl.formatMessage(messages[`faqItem${index + 1}Body`]),
  }));

  return (
    <CollapsibleGroup withBullet withCaret panels={faqItems} />
  );
};

export default injectIntl(FaqList);
