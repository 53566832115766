import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import CollapsibleGroup from 'components/shared/collapsible-group/collapsible-group';

import './setapp-mobile-faq.scss';

const panels = [
  {
    title: (
      <FormattedMessage
        id="setappMobileFaq.faq.item1.title"
        defaultMessage="Can I install Setapp Mobile outside of the EU?"
      />
    ),
    body: (
      <FormattedMessage
        id="setappMobileFaq.faq.item1.body"
        defaultMessage={'No, Setapp Mobile is only available in the EU. This opportunity has appeared from Apple\'s policy changes, driven by the Digital Markets Act requirements in the EU. Apple doesn\'t allow alternative app marketplaces in other regions. People from outside the EU with "iOS", "Mac + iOS", "Power User", "AI Expert", or "Family" plans can still {installAndActivate} via Setapp.'}
        values={{
          installAndActivate: (
            <a
              href="https://support.setapp.com/hc/en-us/articles/360017849339-Before-you-start-using-iOS-apps"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="setappMobileFaq.faq.item1.bodyContinuation"
                defaultMessage="install and activate iOS apps"
              />
            </a>
          ),
        }}
      />
    )
  }, {
    title: (
      <FormattedMessage
        id="setappMobileFaq.faq.item2.title"
        defaultMessage="Can I install Setapp Mobile on my iPad?"
      />
    ),
    body: (
      <FormattedMessage
        id="setappMobileFaq.faq.item2.body"
        defaultMessage="No, as Apple provides opportunities to distribute alternative app marketplaces only on iPhones."
      />
    )
  }, {
    title: (
      <FormattedMessage
        id="setappMobileFaq.faq.item3.title"
        defaultMessage="I'm an iPhone user in the EU, but I can't install Setapp Mobile. What should I do?"
      />
    ),
    body: (
      <FormattedMessage
        id="setappMobileFaq.faq.item3.body"
        defaultMessage="Please make sure your iPhone runs iOS 17.4 or later. Open Settings on your iPhone, go to General > Software Update, and install the latest update if there's any. Then, try installing Setapp Mobile."
      />
    )
  }, {
    title: (
      <FormattedMessage
        id="setappMobileFaq.faq.item4.title"
        defaultMessage="The Install Setapp Mobile button still doesn't work…"
      />
    ),
    body: (
      <FormattedMessage
        id="setappMobileFaq.faq.item4.body"
        defaultMessage="You may face this issue when using a third-party browser like Chrome or Opera. If that's the case, try opening this page in Safari."
      />
    )
  }, {
    title: (
      <FormattedMessage
        id="setappMobileFaq.faq.item5.title"
        defaultMessage='I get the error "Safari cannot open the page because the address is invalid." What should I do?'
      />
    ),
    body: (
      <FormattedMessage
        id="setappMobileFaq.faq.item5.body"
        defaultMessage="Your iOS version is probably lower than 17.4. Open Settings on your iPhone, go to General > Software Update, and install the latest update. Then, try installing Setapp Mobile again."
      />
    )
  }
];

const SetappMobileFaq = () => {
  const formattedPanels = panels.map(({ title, body }) => ({
    title: <p className="setapp-mobile-faq-item">{title}</p>,
    body
  }));

  return <CollapsibleGroup withBullet withCaret panels={formattedPanels} />;
};

export default injectIntl(SetappMobileFaq);
