import React from 'react';
import { render } from 'react-dom';
import queryString from 'query-string';
import { Modal } from '@setapp/ui-kit';

import ReactApp from './app';

import logger from './utils/logger';
import loggerConfig from './config/logger';

import { getSignupMetadata } from './utils/service-locators';

class BrowserApplication {
  initialize() {
    if (process.env.NODE_ENV === 'production') {
      logger.initialize(loggerConfig);
    }

    this.saveSignupMetadata();

    this.render();
  }

  saveSignupMetadata() {
    const impactRadiusClickId = queryString.parse(window.location.search).clickid;
    const signupMetadata = getSignupMetadata();

    if (impactRadiusClickId) {
      signupMetadata.save({ impactRadiusClickId });
    }
  }

  render() {
    Modal.setAppElement('#root');

    render(
      <ReactApp />,
      document.getElementById('root'),
    );
  }
}

// Create application instance only out of unit testing env
if (process.env.NODE_ENV !== 'test') {
  const application = new BrowserApplication();
  application.initialize();
}

export default BrowserApplication;
