// @flow

import React from 'react';

import PaymentMethodPresentation from '../payment-method-presentation/payment-method-presentation';

type Props = {
  email: string,
};

const PayPalInfo = ({ email }: Props) => <PaymentMethodPresentation details={[`PayPal ${email}`]} />;

export default PayPalInfo;
