import * as paymentProviders from 'services/payment-details-api/payment-providers';
import { apiURL } from 'config/api';
import request from 'utils/request';
import BraintreePaymentDetailsApi from './braintree/braintree-payment-details-api';
import PaddlePaymentDetailsApi from './paddle/paddle-payment-details-api';
import SetappPaymentDetailsApi from './setapp/setapp-payment-details-api';

import type {
  PaddlePaymentDetails,
  PaymentDetailsPayload as PaddleCreatePaymentDetailsPayload,
  UpdatePaymentDetailsPayload as PaddleUpdatePaymentDetailsPayload,
} from './paddle/paddle-payment-details-api';

import type {
  BraintreePaymentDetails,
  BraintreePaymentDetailsPayload,
} from './braintree/braintree-payment-details-api';

import type {
  SetappPaymentDetails,
  SetappPaymentDetailsPayload,
} from './setapp/setapp-payment-details-api';

export type { BraintreePaymentDetailsPayload, PaddleCreatePaymentDetailsPayload, PaddleUpdatePaymentDetailsPayload };

/** Common payment details options for all providers. */
export type PaymentDetailsOptions = {
  /** Add and update payment details synchronously. */
  sync?: boolean;
};

export type PaymentProviderName =
  typeof paymentProviders.BRAINTREE
  | typeof paymentProviders.PADDLE
  | typeof paymentProviders.SETAPP;

export type PaymentMethodType = 'card' | 'paypal';

export type PaymentCardType =
  | 'americanexpress'
  | 'dinnersclub'
  | 'discover'
  | 'jcb'
  | 'maestro'
  | 'mastercard'
  | 'visa';

export type CardDetails = {
  cardExpM: number;
  cardExpY: number;
  cardLast4: string;
  cardType: PaymentCardType;
};

export type InvoicingInformation = {
  company: string;
  vatId: string;
  streetName: string;
  addressDetail: string;
  buildingNumber: string;
  city: string;
  postalCode: string;
};

export type PaymentDetails =
  | BraintreePaymentDetails
  | PaddlePaymentDetails
  | SetappPaymentDetails;

export type PaymentDeclineTypePayload = {
  declineReason: 'soft' | 'hard';
};

export default class PaymentDetailsApi {
  braintreePaymentDetailsApi: BraintreePaymentDetailsApi;

  paddlePaymentDetailsApi: PaddlePaymentDetailsApi;

  setappPaymentDetailsApi: SetappPaymentDetailsApi;

  constructor() {
    this.braintreePaymentDetailsApi = new BraintreePaymentDetailsApi();
    this.paddlePaymentDetailsApi = new PaddlePaymentDetailsApi();
    this.setappPaymentDetailsApi = new SetappPaymentDetailsApi();
  }

  fetchPaymentDetails(): Promise<PaymentDetails | null> {
    return request.get(apiURL.payment)
      /**
       * API returns an empty array if the payment details is not set
       * TODO: remove condition when API returns null for an empty payment details
       */
      .then((data) => (Array.isArray(data) ? null : data));
  }

  deletePaymentDetails(): Promise<void> {
    return request.delete(apiURL.payment);
  }

  createBraintreePaymentDetails(payload: BraintreePaymentDetailsPayload): Promise<BraintreePaymentDetails> {
    return this.braintreePaymentDetailsApi.createPaymentDetails(payload);
  }

  updateBraintreePaymentDetails(payload: BraintreePaymentDetailsPayload): Promise<BraintreePaymentDetails> {
    return this.braintreePaymentDetailsApi.updatePaymentDetails(payload);
  }

  createPaddlePaymentDetails(payload: PaddleCreatePaymentDetailsPayload): Promise<PaddlePaymentDetails> {
    return this.paddlePaymentDetailsApi.createPaymentDetails(payload);
  }

  updatePaddlePaymentDetails(payload: PaddleUpdatePaymentDetailsPayload): Promise<PaddlePaymentDetails> {
    return this.paddlePaymentDetailsApi.updatePaymentDetails(payload);
  }

  createSetappPaymentDetails(payload: SetappPaymentDetailsPayload): Promise<SetappPaymentDetails> {
    return this.setappPaymentDetailsApi.createPaymentDetails(payload);
  }

  updateSetappPaymentDetails(payload: SetappPaymentDetailsPayload): Promise<SetappPaymentDetails> {
    return this.setappPaymentDetailsApi.updatePaymentDetails(payload);
  }

  sendPaymentDeclineType(payload: PaymentDeclineTypePayload): Promise<void> {
    return request.post(apiURL.paymentDecline, {
      body: payload,
    });
  }
}
