import React, { FC, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import DefaultError from 'components/shared/default-error/default-error';

import urls from 'config/urls';

import { getFeatureFlags } from 'state/root-reducer';

import useGetPlanForUpgrade from 'components/hooks/use-upgrade-plan-setapp-mobile/use-upgrade-plan-setapp-mobile';

import CustomerOauthOpenSetapp from './partials/customer-oauth-open-setapp';
import CustomerOauthUpgradePlan from './partials/customer-oauth-upgrade-plan';

import getCustomerOauthCode from './utils/customer-oauth-request';
import { CustomerOauthErrors } from './utils/customer-oauth-errors';
import {
  storeCustomerOauthError,
  storeCustomerOauthParams,
  getStoredCustomerOauthParams,
  clearCustomerOauthStorage,
} from './utils/customer-oauth-storage';
import CustomerOauthNotEnoughSeats from './partials/customer-oauth-not-enough-seats';

type RenderPath = keyof typeof CustomerOauthErrors | 'NO_PARAMS' | 'SUCCESS_DONE' | undefined;

/**
 * New auth flow is the authorization of first party clients, that is, our apps or our framework.
 * Token works with the Customer API.
 * The existing OAuth (Sign in with Setapp) is the authorization of third party clients, i.e. vendor apps.
 * Token works with the Vendor API.
 */
const CustomerOauthPage: FC = () => {
  const location = useLocation();
  const [customerRedirectUrl, setCustomerRedirectUrl] = useState<string>();
  const [renderPath, setRenderPath] = useState<RenderPath>();
  const { iosEuBetaTestEnabled } = useSelector(getFeatureFlags);

  // closed beta is in progress, so we need to check if it is disabled
  const isNewSetappMobileFlowEnabled = iosEuBetaTestEnabled.value === 0;

  const searchParams = new URLSearchParams(location.search);
  const customerOauthParamsFromUrl = {
    redirectUrl: searchParams.get('oauth_params[redirect_url]'),
    clientId: searchParams.get('oauth_params[client_id]'),
    deviceId: searchParams.get('oauth_params[device_id]'),
  };

  const { isUpgradeSearchFinished, planForUpgrade } = useGetPlanForUpgrade();

  const paramsToUse = Object.values(customerOauthParamsFromUrl).every((v) => Boolean(v))
    ? customerOauthParamsFromUrl
    : getStoredCustomerOauthParams();

  const { redirectUrl, clientId, deviceId } = paramsToUse || {};

  useEffect(() => {
    if (!isUpgradeSearchFinished) {
      return;
    }

    if (!redirectUrl || !deviceId || !clientId) {
      setRenderPath('NO_PARAMS');

      return;
    }

    getCustomerOauthCode({ deviceId, clientId, redirectUrl })
      .then(({ redirectUrl }) => {
        if (isNewSetappMobileFlowEnabled && planForUpgrade) {
          setRenderPath('OFFER_UPGRADE');

          return;
        }

        clearCustomerOauthStorage();
        setRenderPath('SUCCESS_DONE');
        setCustomerRedirectUrl(redirectUrl);
      })
      .catch((error: keyof typeof CustomerOauthErrors) => {
        storeCustomerOauthError(error);
        storeCustomerOauthParams({ deviceId, clientId, redirectUrl });

        if (error === 'APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION' && isNewSetappMobileFlowEnabled) {
          const updatedError = planForUpgrade && isUpgradeSearchFinished ? 'OFFER_UPGRADE' : error;
          storeCustomerOauthError(updatedError);
          setRenderPath(updatedError);

          return;
        }

        setRenderPath(error);
      });
  }, [planForUpgrade, isUpgradeSearchFinished]);

  const renderBlock = () => {
    switch (renderPath) {
      case 'NO_PARAMS':
      case 'UNKNOWN_ERROR':
        return <DefaultError />;
      case 'CUSTOMER_SUBSCRIPTION_INACTIVE':
      case 'TRIAL_ALREADY_USED_ON_DEVICE':
      case 'BLOCKED_CUSTOMER':
        return <Redirect to={urls.subscription} />;
      case 'NOT_ENOUGH_SEATS':
        return <CustomerOauthNotEnoughSeats />;
      case 'OFFER_UPGRADE':
        return <CustomerOauthUpgradePlan />;
      case 'APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION':
        return <Redirect to={urls.restrictSetappMobile} />;
      case 'SUCCESS_DONE':
        return <CustomerOauthOpenSetapp redirectUrl={customerRedirectUrl!} />;
      case 'EMPTY_PAYMENT_DETAILS':
      case 'APP_ACCESS_FORBIDDEN_FOR_TRIAL_SUBSCRIPTION':
        return <Redirect to={urls.signupAddPaymentMethod} />;
      default:
        return <FullscreenLayoutLoading />;
    }
  };

  return (
    <FullscreenLayout withFooter>
      <FullscreenLayout.PrimaryContent withLogo>
        {renderBlock()}
      </FullscreenLayout.PrimaryContent>
    </FullscreenLayout>
  );
};

export default CustomerOauthPage;
