import React, { PureComponent, ReactElement } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import type { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import RequestError from '@setapp/request-error';
import httpStatuses from 'http-status';

import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import { getUserEmail, isUsersEmailConfirmed } from 'state/root-reducer';
import { resendConfirmationEmail, fetchUser, confirmEmail } from 'state/user/user-actions';
import { State as RootState } from 'state/state-types';
import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import type { FetchUserAction } from 'state/user/user-actions';
import { ModalProps, showModal } from 'state/modal/modal-reducer';
import type { ModalType } from 'components/modals';
import DefaultError from 'components/shared/default-error/default-error';

import { removeQueryParams } from 'utils/location';

import './signup-confirm-email.scss';
import emailImage from './images/email.svg';
import successImage from './images/success.svg';

const REPEAT_RESEND_TIMEOUT = 60;
const CONFIRM_EMAIL_QUERY_PARAM = 'email_confirmation_token';

type Props = {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  isUsersEmailConfirmed: boolean;
  userEmail: string;
  resendConfirmationEmail: ({ captcha }: { captcha?: string }) => Promise<void>;
  showModal: (modalType: ModalType, modalProps?: ModalProps) => void;
  showDangerNotification: (message: ReactElement) => void;
  showSuccessNotification: (message: ReactElement) => void;
  fetchUser: FetchUserAction;
  confirmEmail: (email: string) => Promise<void>;
};

type State = {
  showSuccessMessage: boolean;
  isProcessing: boolean;
  resendTimerValue: number;
};

class SignupConfirmEmail extends PureComponent<Props, State> {
  state = {
    showSuccessMessage: false,
    isProcessing: false,
    resendTimerValue: REPEAT_RESEND_TIMEOUT,
  }

  resendTimerInterval!: ReturnType<typeof setInterval>;
  fetchUserInterval!: ReturnType<typeof setInterval>

  componentDidMount() {
    const { isUsersEmailConfirmed } = this.props;

    if (isUsersEmailConfirmed) {
      this.redirectToAccount();
    }

    this.processQueryParams();
  }

  componentDidUpdate(prevProps: Props) {
    const { isUsersEmailConfirmed } = this.props;

    if (!prevProps.isUsersEmailConfirmed && isUsersEmailConfirmed) {
      this.stopUserPooling();
      this.stopRepeatResendTimer();
      this.redirectToAccount();
    }
  }

  componentWillUnmount() {
    this.stopRepeatResendTimer();
    this.stopUserPooling();
  }

  render() {
    const { userEmail } = this.props;
    const { showSuccessMessage, isProcessing, resendTimerValue } = this.state;

    return (
      <div className="confirm-email">
        <img
          src={emailImage}
          className="img-responsive mb-6"
          alt=""
        />
        <h3 className="mb-6">
          <FormattedMessage
            id="signupConfirmEmail.title"
            defaultMessage="Please confirm your email"
          />
        </h3>
        <p className="mb-6 text_lg">
          <FormattedMessage
            id="signupConfirmEmail.confirmText"
            defaultMessage="We’ve emailed you a confirmation link to {email}"
            values={{
              email: <strong>{userEmail}</strong>,
            }}
          />
        </p>

        {showSuccessMessage ? (
          <p className="text_color-success mb-0">
            <img className="mr-2 confirm-email__success-icon" src={successImage} alt="" />
            <FormattedMessage
              id="signupConfirmEmail.resendSuccess"
              defaultMessage="We’ve emailed you once more, please check your inbox."
            />
          </p>
        ) : (
          <Button
            variant="link"
            disabled={isProcessing}
            onClick={this.handleResendClick}
          >
            {isProcessing ? (
              <FormattedMessage
                id="signupConfirmEmail.resendTimerMessage"
                defaultMessage="Repeat resend available in {seconds, plural, one {{seconds} second} other {{seconds} seconds}}."
                values={{
                  seconds: resendTimerValue,
                }}
              />
            ) : (
              <FormattedMessage
                id="signupConfirmEmail.resendButton"
                defaultMessage="Did not receive the email? Click here to resend."
              />
            )}
          </Button>
        )}
      </div>
    );
  }

  processQueryParams() {
    const { location, history } = this.props;
    const query = queryString.parse(location.search);

    const emailConfirmationToken = query[CONFIRM_EMAIL_QUERY_PARAM];

    if (emailConfirmationToken) {
      this.confirmEmail();
    }

    if (CONFIRM_EMAIL_QUERY_PARAM in query) {
      removeQueryParams(history, CONFIRM_EMAIL_QUERY_PARAM);
    }
  }

  confirmEmail(): Promise<void> {
    const { location, confirmEmail, showSuccessNotification } = this.props;
    const query = queryString.parse(location.search);

    return confirmEmail(query[CONFIRM_EMAIL_QUERY_PARAM] as string)
      .then(() => {
        showSuccessNotification(
          <FormattedMessage
            id="signupConfirmEmail.success"
            defaultMessage="Email address successfully verified."
          />,
        );
      })
      .catch((error) => this.onEmailConfirmationError(error));
  }

  onEmailConfirmationError(error: RequestError) {
    const { showDangerNotification } = this.props;
    const isTokenInvalid = error.status === httpStatuses.BAD_REQUEST && error.getFieldError('emailConfirmationToken');

    if (isTokenInvalid) {
      showDangerNotification(error.getFieldError('emailConfirmationToken').toString());
    } else {
      showDangerNotification(<DefaultError />);
    }
  }

  handleResendClick = () => {
    this.resendEmail();
  };

  resendEmail = (captcha?: string) => {
    const { resendConfirmationEmail, showDangerNotification } = this.props;

    return resendConfirmationEmail({ captcha })
      .then(this.onResendEmailSuccess)
      .catch((error) => {
        const captchaError = error.getFieldError('captcha');
        const isCaptchaRequired = captchaError && captchaError.isRequired();

        if (isCaptchaRequired) {
          this.showCaptchaModal();
        } else {
          showDangerNotification(<DefaultError />);
        }
      });
  };

  onResendEmailSuccess = () => {
    this.setState({ showSuccessMessage: true });
    this.startUserPooling();

    setTimeout(() => {
      this.setState({ showSuccessMessage: false });
      this.startRepeatResendTimer();
    }, 5000);
  };

  startRepeatResendTimer = () => {
    this.setState({ isProcessing: true });

    this.resendTimerInterval = setInterval(() => {
      const { resendTimerValue } = this.state;

      if (resendTimerValue > 0) {
        this.setState({ resendTimerValue: resendTimerValue - 1 });
      }

      if (resendTimerValue === 1) {
        this.stopRepeatResendTimer();
      }
    }, 1000);
  };

  stopRepeatResendTimer = () => {
    clearInterval(this.resendTimerInterval);
    this.setState({ isProcessing: false });
    this.setState({ resendTimerValue: REPEAT_RESEND_TIMEOUT });
  }

  showCaptchaModal = () => {
    const { showModal } = this.props;

    showModal('RESEND_EMAIL_CAPTCHA', {
      onConfirm: this.handleCaptchaModalConfirm,
    });
  };

  handleCaptchaModalConfirm = (captcha: string) => {
    this.resendEmail(captcha);
  };

  startUserPooling = () => {
    const { fetchUser } = this.props;

    this.stopUserPooling();
    this.fetchUserInterval = setInterval(() => fetchUser(), 5000);
  }

  stopUserPooling = () => {
    clearInterval(this.fetchUserInterval);
  }

  redirectToAccount = () => {
    const { history } = this.props;

    history.push(urls.root);
  }
}

const mapStateToProps = (state: RootState) => ({
  userEmail: getUserEmail(state),
  isUsersEmailConfirmed: isUsersEmailConfirmed(state),
});

const mapActionsToProps = {
  showModal,
  resendConfirmationEmail,
  showDangerNotification,
  showSuccessNotification,
  fetchUser,
  confirmEmail,
};

export { SignupConfirmEmail as PureSignupConfirmEmail };

export default connect(mapStateToProps, mapActionsToProps)(SignupConfirmEmail);
