import React, { PureComponent, ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';


import urls from 'config/urls';

import PageTitle from 'components/shared/page-title/page-title';
import DefaultError from 'components/shared/default-error/default-error';

import { activateFamilyPlan, fetchFamilyPlan } from 'state/family-plan/family-plan-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import {
  getFamilyPlan,
  getPrimaryPricePlan,
  isFamilyStatusPending,
  isPaymentMethodCreated,
  getPaymentMethod,
  isSubscriptionTrial,
  isSubscriptionNew,
  canUserSwitchToFamily,
} from 'state/root-reducer';

import { showDangerNotification } from 'state/notifier/notifier-reducer';

import * as pricePlanTypes from 'state/price-plans/price-plan-groups';

import analytics, { events } from 'utils/analytics';
import FamilyPlanBenefits from '../family-plan-benefits/family-plan-benefits';
import FamilyPlanFaq from '../family-plan-faq/family-plan-faq';
import FamilyPlanPending from './family-plan-pending';
import SwitchToFamilyPlan from './switch-to-family-plan';
import SwitchToFamilyPlanModal from './switch-to-family-plan-modal/switch-to-family-plan-modal';
import familyImage from './images/family.svg';

import './switch-to-family-plan-page.scss';

type Props = {
  familyPricePlan: PricePlan;
  primaryPricePlan: PricePlan;
  isFamilyStatusPending: boolean;
  activateFamilyPlan: (pricePlan: PricePlan) => Promise<void>;
  isPaymentMethodCreated: boolean;
  familySeats?: number;
  isPaymentMethodLoading: boolean;
  fetchPaymentMethod: () => Promise<void>;
  isTrialOrNewUser: boolean;
  switchToFamilyAmount: number;
  canUserSwitchToFamily: boolean;
  isFamilyLoading: boolean;
  isSubscriptionNew: boolean;
  fetchFamilyPlan: () => Promise<void>;
  history: RouteComponentProps['history'];
  showDangerNotification: (message: ReactElement) => void;
};

type State = {
  showSwitchToFamilyPlanModal: boolean;
  showAddPaymentDetailsStep: boolean;
  showPaymentDetailsForm: boolean;
};

class SwitchToFamilyPlanPage extends PureComponent<Props, State> {
  state = {
    showSwitchToFamilyPlanModal: false,
    showAddPaymentDetailsStep: false,
    showPaymentDetailsForm: false,
  };

  fetchFamilyInterval;

  componentDidMount() {
    const {
      canUserSwitchToFamily,
      history,
      isFamilyStatusPending,
    } = this.props;

    if (!canUserSwitchToFamily) {
      history.push(urls.root);
    }

    if (isFamilyStatusPending) {
      this.startFamilyPlanPooling();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isFamilyStatusPending } = this.props;

    if (prevProps.isFamilyStatusPending && !isFamilyStatusPending) {
      this.stopFamilyPlanPooling();
    }
  }

  componentWillUnmount() {
    this.stopFamilyPlanPooling();
  }

  render() {
    const {
      familyPricePlan,
      primaryPricePlan,
      isFamilyStatusPending,
      familySeats,
      isPaymentMethodLoading,
      isTrialOrNewUser,
      switchToFamilyAmount,
      isFamilyLoading,
      isSubscriptionNew,
    } = this.props;

    const {
      showSwitchToFamilyPlanModal,
      showAddPaymentDetailsStep,
      showPaymentDetailsForm,
    } = this.state;

    const newFamilyOwnerMessage = (
      <div className="switch-to-family-plan__activation-message">
        <PageTitle>
          <FormattedMessage
            id="familyPlan.newFamilyOwner"
            defaultMessage="Activate your Family plan by signing into the desktop app."
          />
        </PageTitle>
      </div>
    );

    // Indicates family subscription without active trial (registered from the site's pricing page)
    const isNewFamilySubscription = primaryPricePlan.group === pricePlanTypes.FAMILY && isSubscriptionNew;

    return (
      <div>
        <PageTitle>
          <FormattedMessage id="familyPlan.pageTitle" defaultMessage="Setapp Family" />
        </PageTitle>
        <Row>
          <Col lg={5}>
            <p>
              <FormattedMessage
                id="familyPlan.pageDescription"
                defaultMessage="An easy way to share Setapp subscription with your dearest."
              />
            </p>
            <div className="mt-7">
              <FamilyPlanBenefits maxFamilySeats={familySeats} />
            </div>
            {isNewFamilySubscription && newFamilyOwnerMessage}
            {!isNewFamilySubscription && (
              <div className="switch-to-family-plan__panel" data-qa="switchToFamilyPlanPanel">
                {isFamilyStatusPending
                  ? <FamilyPlanPending />
                  : (
                    <SwitchToFamilyPlan
                      maxFamilySeats={familySeats}
                      onSwitchToFamilyPlanClick={this.showSwitchToFamilyPlanModal}
                      pricePlan={familyPricePlan}
                    />
                  )}
              </div>
            )}
          </Col>
          <Col lg={7} className="hidden visible-lg">
            <img
              className="img-responsive switch-to-family-plan__illustration"
              src={familyImage}
              width={555}
              height={525}
              alt=""
            />
          </Col>
          <SwitchToFamilyPlanModal
            isModalShown={showSwitchToFamilyPlanModal}
            onModalHide={this.hideSwitchToFamilyPlanModal}
            pricePlan={familyPricePlan}
            onConfirmSwitchClick={this.onConfirmSwitchClick}
            showAddPaymentDetailsStep={showAddPaymentDetailsStep}
            showPaymentDetailsForm={showPaymentDetailsForm}
            onAddPaymentDetailsClick={this.showPaymentDetailsForm}
            onAddPaymentDetailsSuccess={this.onAddPaymentDetailsSuccess}
            isPaymentMethodLoading={isPaymentMethodLoading}
            isFamilyLoading={isFamilyLoading}
            isTrialOrNewUser={isTrialOrNewUser}
            switchToFamilyAmount={switchToFamilyAmount}
          />
        </Row>
        <div className="switch-to-family-plan__faq">
          <p className="h4">
            <FormattedMessage id="familyPlan.faqTitle" defaultMessage="Frequently asked questions" />
          </p>
          <FamilyPlanFaq maxFamilySeats={familySeats} pricePlan={familyPricePlan} />
        </div>
      </div>
    );
  }

  hideSwitchToFamilyPlanModal = () => {
    this.setState({ showSwitchToFamilyPlanModal: false });
  };

  showSwitchToFamilyPlanModal = () => {
    const { fetchPaymentMethod } = this.props;
    const { showDangerNotification } = this.props;

    this.setState({
      showSwitchToFamilyPlanModal: true,
      showPaymentDetailsForm: false,
      showAddPaymentDetailsStep: false,
    });

    analytics.trackEvent(events.FAMILY_PLAN_SWITCH_CLICK);

    return fetchPaymentMethod()
      .catch(() => {
        this.hideSwitchToFamilyPlanModal();
        showDangerNotification(<DefaultError />);
      });
  };

  onConfirmSwitchClick = () => {
    const { isPaymentMethodCreated } = this.props;

    if (!isPaymentMethodCreated) {
      this.setState({ showAddPaymentDetailsStep: true });

      return;
    }

    this.activateFamilyPlan();
  };

  showPaymentDetailsForm = () => {
    this.setState({ showPaymentDetailsForm: true });
  };

  onAddPaymentDetailsSuccess = () => {
    this.activateFamilyPlan();
    analytics.trackEvent(events.FAMILY_PLAN_PAYMENT_INFO_ADDED);
  };

  activateFamilyPlan = () => {
    const { activateFamilyPlan, familyPricePlan } = this.props;
    const { showDangerNotification } = this.props;

    return activateFamilyPlan(familyPricePlan)
      .then(() => {
        this.hideSwitchToFamilyPlanModal();
        this.startFamilyPlanPooling();
      }).catch(() => {
        this.hideSwitchToFamilyPlanModal();
        showDangerNotification(<DefaultError />);
      });
  };

  startFamilyPlanPooling() {
    const POOLING_INTERVAL = 3000;
    const { fetchFamilyPlan } = this.props;

    this.fetchFamilyInterval = setInterval(fetchFamilyPlan, POOLING_INTERVAL);
  }

  stopFamilyPlanPooling() {
    clearInterval(this.fetchFamilyInterval);
  }
}

export { SwitchToFamilyPlanPage as PureSwitchToFamilyPlanPage };

const mapStateToProps = (state) => ({
  familyPricePlan: getFamilyPlan(state).price,
  primaryPricePlan: getPrimaryPricePlan(state),
  isFamilyStatusPending: isFamilyStatusPending(state),
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  familySeats: getFamilyPlan(state).seats,
  isPaymentMethodLoading: getPaymentMethod(state).isLoading,
  isTrialOrNewUser: isSubscriptionTrial(state) || isSubscriptionNew(state),
  switchToFamilyAmount: getFamilyPlan(state).switchAmount,
  isFamilyLoading: getFamilyPlan(state).isLoading,
  canUserSwitchToFamily: canUserSwitchToFamily(state),
  isSubscriptionNew: isSubscriptionNew(state),
});

const mapActionsToProps = {
  activateFamilyPlan,
  fetchPaymentMethod,
  fetchFamilyPlan,
  showDangerNotification,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
  // @ts-expect-error In props price plans are defined, but actuallu they can be undefined in store TODO: fix it
)(withRouter(SwitchToFamilyPlanPage));
