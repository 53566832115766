// @flow

import React from 'react';
import type { Node } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import classnames from 'classnames';
import { ButtonLoading } from '@setapp/ui-kit';

import type { BraintreePaymentDetailsPayload } from 'services/payment-details-api/payment-details-api';

import BraintreeVerified from 'components/shared/braintree-verified/braintree-verified';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';

import PaymentMethodFormVatContainer from '../payment-method-form-vat-container/payment-method-form-vat-container';

import BraintreeFormField from './braintree-field/braintree-field';
import fieldsMessages from './form-fields-messages';

import './credit-card-form.scss';

type Props = {
  merchantId: string,
  isLoading: boolean,
  formError: Node,
  fieldsWithError: {
    [string]: Node,
  },
  onFieldChange: (string, string) => mixed,
  onFormSubmit: SyntheticEvent<HTMLFormElement> => mixed,
  onApplePayButtonClick: () => mixed,
  isPaymentMethodCreated: boolean,
  paymentInfo: BraintreePaymentDetailsPayload,
  submitBtnTitle?: Node,
  intl: IntlShape,
  captcha: ?Node,
  loadingText: ?Node,
  isInitialized: boolean,
  withApplePay: boolean,
  withGooglePay: boolean,
  isApplePayLoading: boolean,
  isGooglePayInit: boolean,
};

const CreditCardForm = (props: Props) => {
  const {
      onFormSubmit,
      onApplePayButtonClick,
      fieldsWithError,
      paymentInfo,
      isLoading,
      onFieldChange,
      formError,
      merchantId,
      isPaymentMethodCreated,
      intl,
      submitBtnTitle,
      captcha,
      loadingText,
      isInitialized,
      withApplePay,
      isApplePayLoading,
      withGooglePay,
      isGooglePayInit,
    } = props,
    errorClasses = classnames('form-error text-danger credit-card-form-error', {
      hidden: !formError,
    }),
    applePayButtonClasses = classnames('apple-pay-button apple-pay-button-black', {
      'apple-pay-button-appearance': !isApplePayLoading,
    }),
    {
      number: numberError,
      expirationDate: expirationDateError,
      cvv: cvvError,
    } = fieldsWithError;

  const showPaymentContainer = (withGooglePay && isGooglePayInit) || withApplePay;
  const googlePayContainerClasses = classnames([
    'google-pay-container',
    { 'google-pay-container_active': withGooglePay && isGooglePayInit },
  ]);
  const paymentContainerClasses = classnames([
    { 'credit-card-form__payment-container': showPaymentContainer },
  ]);

  return (
    <>
      <div className={paymentContainerClasses}>
        {withApplePay && (
          <ButtonLoading
            disabled={isApplePayLoading}
            isLoading={isApplePayLoading}
            onClick={onApplePayButtonClick}
            className={applePayButtonClasses}
          />
        )}

        {withGooglePay && (
          <div id="google-pay-container" className={googlePayContainerClasses} />
        )}
      </div>

      {showPaymentContainer && (
        <div className="credit-card-form__divider">
          <div className="credit-card-form__divider-line" />
          <div className="credit-card-form__divider-text">
            <FormattedMessage
              id="paymentDetails.creditCard.divider"
              defaultMessage="or"
            />
          </div>
        </div>
      )}

      <form className="credit-card-form" onSubmit={onFormSubmit} noValidate>
        <BraintreeFormField
          id="number"
          name="number"
          invalid={Boolean(numberError)}
          helpText={numberError}
          disabled={isLoading}
          label={intl.formatMessage(fieldsMessages.cardNumber)}
          showLabel
        />

        <Row>
          <Col sm={7}>
            <BraintreeFormField
              id="expirationDate"
              name="expirationDate"
              invalid={Boolean(expirationDateError)}
              helpText={expirationDateError}
              disabled={isLoading}
              label={intl.formatMessage(fieldsMessages.cardExpirationDate)}
              showLabel
              placeholder={intl.formatMessage(fieldsMessages.cardExpirationPlaceholder)}
            />
          </Col>

          <Col sm={5}>
            <BraintreeFormField
              id="cvv"
              name="cvv"
              invalid={Boolean(cvvError)}
              helpText={cvvError}
              disabled={isLoading}
              label={intl.formatMessage(fieldsMessages.cardCvv)}
              showLabel
              tooltip={
                <div className="credit-card-form__tooltip-content">
                  <FormattedMessage
                    id="paymentDetails.creditCard.cvvTooltip"
                    defaultMessage="The 3 or 4 digit security code on the back of your card."
                  />
                </div>
              }
            />
          </Col>
        </Row>

        <div className="credit-card-form__vat-fields">
          <PaymentMethodFormVatContainer
            fieldsWithError={fieldsWithError}
            paymentInfo={paymentInfo}
            isLoading={isLoading}
            onFieldChange={onFieldChange}
          />
        </div>

        {captcha}

        <p className={errorClasses}>{formError}</p>

        <ButtonLoading
          className="credit-card-form-submit-btn"
          block
          type="submit"
          disabled={isLoading}
          isLoading={isLoading}
          loadingText={isInitialized ? loadingText : ''}
        >
          {getBtnTitle()}
        </ButtonLoading>

        <BraintreeVerified merchantId={merchantId} />
      </form>
    </>
  );

  function getBtnTitle() {
    if (submitBtnTitle) {
      return submitBtnTitle;
    }

    return <PaymentDetailsActionText action={isPaymentMethodCreated ? 'update' : 'add'} />;
  }
};

CreditCardForm.defaultProps = {
  submitBtnTitle: null,
};

export { CreditCardForm as PureCreditCardForm };

export default injectIntl(CreditCardForm);
