import classNames from 'classnames';
import React from 'react';
import type { SyntheticEvent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import { PriceRadio } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';

import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';
import { isAiPricePlan } from 'services/price-plans/utils';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { getIsSetappMobileAvailable } from 'state/root-reducer';

import PricePerMonth from 'components/shared/price-per-month/price-per-month';

import './price-plan-radio.scss';

type Props = InjectedIntlProps & {
  isSelected: boolean;
  pricePlan: PricePlan;
  currentPricePlan: PricePlan;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
};

const PricePlanRadio = (props: Props) => {
  const {
    intl, isSelected, pricePlan, currentPricePlan, onChange,
  } = props;
  const isSetappMobileAvailable = useSelector(getIsSetappMobileAvailable);

  if (!pricePlan.features) {
    return null;
  }

  const { macSeatsCount, iosSeatsCount, maxMembersCount, vendorApiUsageLimitInCredits } = pricePlan.features;
  const pricePlanMetadata = getPricePlanMetadata(pricePlan);

  const isCurrentPlan = pricePlan.tierType === currentPricePlan.tierType;
  const showPromoText = !isCurrentPlan && isSelected;

  const rootClasses = classNames('price-plan-radio', {
    'price-plan-radio_with-promo-text': showPromoText,
  });
  const descriptionClasses = classNames({
    'price-plan-radio__description': !isSelected,
  });
  const priceClasses = classNames({
    'price-plan-radio__price': !isSelected,
  });

  const renderPromoText = () => {
    let promoText;

    if (pricePlan.price > currentPricePlan.price) {
      promoText = (
        <FormattedMessage
          id="manageSubscriptionModal.changePlanStep.promoText.higherPlan"
          defaultMessage="Nice choice! Let’s recalculate your regular payment amount."
        />
      );
    } else if (pricePlan.price < currentPricePlan.price) {
      promoText = (
        <FormattedMessage
          id="manageSubscriptionModal.changePlanStep.promoText.lowerPlan"
          defaultMessage="Good call! Enjoy new plan features at a lower price."
        />
      );
    } else {
      promoText = (
        <FormattedMessage
          id="manageSubscriptionModal.changePlanStep.promoText.equalPlan"
          defaultMessage="Smart choice! Enjoy new plan features at the same price."
        />
      );
    }

    return (
      <div className="price-plan-radio__promo-text">
        {promoText}
      </div>
    );
  };

  const title = (
    <>
      <span className="price-plan-radio__title">
        {pricePlanMetadata.titleFull}
        {isAiPricePlan(pricePlan.tierType) && (
          <div className="price-plan-radio__ai-plan-label">
            <div className="price-plan-radio__ai-plan-label-content">
              <FormattedMessage
                id="manageSubscriptionModal.changePlanStep.pricePlanItem.aiPlanLabel"
                defaultMessage="AI+ plan"
              />
            </div>
          </div>
        )}
      </span>
      {isCurrentPlan && (
        <span className="price-plan-radio__current-plan-label ml-2">
          (
          <FormattedMessage
            id="manageSubscriptionModal.changePlanStep.pricePlanItem.currentPlanLabel"
            defaultMessage="current"
          />
          )
        </span>
      )}
    </>
  );
  const description = (
    <>
      <div className={descriptionClasses}>
        {
          intl.formatMessage(
            pricePlanMetadata.availableDevicesMessage,
            {
              macSeatsCount,
              iosSeatsCount,
              maxMembersCount,
              vendorApiUsageLimitInCredits,
              isSetappMobileAvailable,
            },
          )
        }
      </div>
      {showPromoText && renderPromoText()}
    </>
  );
  const price = (
    <span className={priceClasses}>
      <PricePerMonth pricePlan={pricePlan} />
    </span>
  );

  return (
    <div className={rootClasses}>
      <PriceRadio
        value={pricePlan.priceKey}
        checked={isSelected}
        // FIXME: Fix `title` prop type in the PriceRadio, it's `string`
        // now because of intersection with `InputHTMLAttributes` type
        title={title as unknown as string}
        description={description}
        price={price}
        onChange={onChange}
      />
    </div>
  );
};

export default injectIntl(PricePlanRadio);
