import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Notification } from '@setapp/ui-kit';
import useSuspendedActivationHandler from 'components/pages/subscription/hooks/use-suspended-activation-handler';

const GetPlanNotification = () => {
  const handleGetPlan = useSuspendedActivationHandler();

  return (
    <Notification type="info">
      <span>
        <FormattedMessage
          id="getPlanNotification.text"
          defaultMessage="You’ve canceled your subscription. Ready to jump back in? Choose a subscription plan that’s best for you."
        />
        <div className="mt-3">
          <Button size="sm" onClick={handleGetPlan}>
            <FormattedMessage
              id="getPlanNotification.btn"
              defaultMessage="Get a plan"
            />
          </Button>
        </div>
      </span>
    </Notification>
  );
};

export default GetPlanNotification;
