import React from 'react';
import type { ReactNode, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { getFeatureFlags } from 'state/root-reducer';

import { useSidebarNavContext } from '../sidebar-nav-context';

import './sidebar-nav-item.scss';

type Props = {
  children: ReactNode;
  href?: string;
  target?: string;
  external?: boolean;
  className?: string;
  iconLink?: string | null;
  labelType?: 'new' | 'beta';
  onClick?: (e: MouseEvent) => void;
};

const SidebarNavItem = ({
  children, external, iconLink, className, onClick, href, target, labelType, ...linkProps
}: Props) => {
  const { onSelect } = useSidebarNavContext();
  const { mcLicensePromo } = useSelector(getFeatureFlags);
  const anchorClasses = classNames(
    'sidebar-nav__link',
    { 'sidebar-nav__link_external': external },
    className,
  );
  const labelClasses = classNames(
    'ml-2 text_weight-bold sidebar-nav__item-label',
    {
      'sidebar-nav__item-label_new': labelType === 'new',
      'sidebar-nav__item-label_beta': labelType === 'beta',
    },
  );

  const getLabelText = () => {
    if (labelType === 'beta') return <FormattedMessage id="navigation.betaLabel" defaultMessage="Beta" />;

    if (labelType === 'new') {
      return mcLicensePromo.value ? (
         <FormattedMessage id="navigation.mcPromoLabel" defaultMessage="Deal" />
      ) : (
         <FormattedMessage id="navigation.newLabel" defaultMessage="New" />
      );
    }

    return null;
  };

  const labelText = getLabelText();

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }

    if (onSelect) {
      onSelect();
    }
  };

  return (
    <li role="presentation" className="sidebar-nav__item">
      <a {...linkProps} href={href} target={target} className={anchorClasses} onClick={handleClick}>
        {children}
        {iconLink && <img className="sidebar-nav__item-icon" src={iconLink} alt="" />}
        {labelType && <span className={labelClasses}>{labelText}</span>}
      </a>
    </li>
  );
};

export default SidebarNavItem;
