import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getAppsCount, isIosOnlyUser } from 'state/root-reducer';
import appIcon from './images/icons-app.svg';
import discoverIcon from './images/icons-discover.svg';
import handpickedIcon from './images/icons-handpicked.svg';
import receiptIcon from './images/icons-receipt.svg';
import updatesIcon from './images/icons-updates.svg';
import walletIcon from './images/icons-wallet.svg';

import './price-plan-benefits.scss';

const PricePlanBenefits = () => {
  const appsCount = useSelector(getAppsCount);
  const isIosOnly = useSelector(isIosOnlyUser);

  const benefits = [{
    icon: appIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.apps"
        defaultMessage="Unlimited access to {appsCount}+ apps"
        values={{
          appsCount: Math.floor(appsCount / 10) * 10,
        }}
      />
    ),
  }, {
    icon: updatesIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.updates"
        defaultMessage="Free updates and no ads"
      />
    ),
  }, {
    icon: discoverIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.discover"
        defaultMessage="Easy apps search"
      />
    ),
  }, {
    icon: handpickedIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.install"
        defaultMessage="One-click install"
      />
    ),
  }, {
    icon: walletIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.fee"
        defaultMessage="A single flat fee"
      />
    ),
  }, {
    icon: receiptIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.payments"
        defaultMessage="Card, PayPal & invoice"
      />
    ),
  }];

  const iosBenefits = [{
    icon: appIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.iosApps"
        defaultMessage="Unlimited access to 26 apps"
      />
    ),
  }, {
    icon: handpickedIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.iosInstall"
        defaultMessage="Two-click install"
      />
    ),
  }, {
    icon: updatesIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.iosUpdates"
        defaultMessage="Free updates"
      />
    ),
  }, {
    icon: walletIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.iosFee"
        defaultMessage="A single flat fee"
      />
    ),
  }, {
    icon: discoverIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.iosDiscover"
        defaultMessage="No ads"
      />
    ),
  }, {
    icon: receiptIcon,
    text: (
      <FormattedMessage
        id="pricePlanBenefits.iosPayments"
        defaultMessage="Card, PayPal & invoice"
      />
    ),
  }];

  const choosenBenefits = isIosOnly ? iosBenefits : benefits;

  return (
    <>
      <h5 className="ml-10">
        <FormattedMessage
          id="pricePlanBenefits.title"
          defaultMessage="All plans include"
        />
      </h5>
      <ul className="price-plan-benefits">
        {choosenBenefits.map(({ icon, text }) => (
          <li key={icon} className="price-plan-benefits__item mb-6">
            <img className="mr-4" src={icon} width="24" height="24" alt="" />
            {text}
          </li>
        ))}
      </ul>
    </>
  );
};

export default PricePlanBenefits;
