// Old family modals are deprecated and are going to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';

import { getCurrentFamilyMember, getFamilyPlan } from 'state/root-reducer';
import { removeFamilyMember } from 'state/family-plan/family-plan-actions';
import { showSuccessNotification, showDangerNotification } from 'state/notifier/notifier-reducer';

import analytics, { events } from 'utils/analytics';


const mapStateToProps = (state) => ({
  familyMember: getCurrentFamilyMember(state),
  isFamilyProcessing: getFamilyPlan(state).isLoading,
});

const mapActionsToProps = {
  removeFamilyMember,
  showSuccessNotification,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = {
  show: boolean;
  onHide: () => void;
} & ConnectedProps<typeof connector>

class LeaveFamilyModal extends PureComponent<Props> {
  render() {
    const {
      show,
      onHide,
      isFamilyProcessing,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        title={
          <FormattedMessage
            id="leaveFamilyModal.title"
            defaultMessage="Leaving Family"
          />
        }
      >
        <div>
          <div className="text-center mb-8">
            <FormattedMessage
              id="leaveFamilyModal.description"
              defaultMessage="After leaving the family, you'll be switched to the Setapp Monthly plan."
            />
          </div>

          <Button
            variant="danger-outline"
            block
            onClick={this.handleConfirmClick}
            disabled={isFamilyProcessing}
          >
            <FormattedMessage
              id="leaveFamilyModal.submitButton"
              defaultMessage="Confirm & Leave"
            />
          </Button>

          <Button
            block
            onClick={onHide}
            className="mt-3"
          >
            <FormattedMessage
              id="leaveFamilyModal.cancelButton"
              defaultMessage="Cancel"
            />
          </Button>
        </div>
      </Modal>
    );
  }

  handleConfirmClick = () => {
    const { removeFamilyMember, familyMember } = this.props;

    if (!familyMember) {
      throw new Error('No current family member found');
    }

    return removeFamilyMember(familyMember.id)
      .then(this.handleSuccessfulFamilyLeave)
      .catch(() => {
        const { showDangerNotification, onHide } = this.props;

        showDangerNotification(<DefaultError />);
        onHide();
      });
  };

  handleSuccessfulFamilyLeave = () => {
    const { showSuccessNotification, onHide } = this.props;

    analytics.trackEvent(events.FAMILY_PLAN_LEAVE_SUCCESS);

    onHide();

    showSuccessNotification(<FormattedMessage
      id="subscriptionInfo.leaveFamily.succeed"
      defaultMessage="You have been successfully switched to a Monthly plan."
                            />);
  };
}

export default connector(LeaveFamilyModal);
