import { App } from './apps-initial-state';

export const REQUEST = 'APPS_REQUEST';
export const REQUEST_SUCCESS = 'APPS_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'APPS_REQUEST_ERROR';

export type AppsRequestAction = {
  type: 'APPS_REQUEST';
};

export type AppsRequestSuccessAction = {
  type: 'APPS_REQUEST_SUCCESS';
  payload: App[];
};

export type AppsRequestErrorAction = {
  type: 'APPS_REQUEST_ERROR';
};

export type AppsAction = AppsRequestAction | AppsRequestSuccessAction | AppsRequestErrorAction;
