import React from 'react';
import type { ReactNode, MouseEvent } from 'react';
import TabPane from 'react-bootstrap/lib/TabPane';
import TabContainer from 'react-bootstrap/lib/TabContainer';
import TabContent from 'react-bootstrap/lib/TabContent';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';

import './custom-tabs.scss';

type TabConfig = {
  key: string;
  title: ReactNode;
  content: ReactNode;
};

type Props = {
  tabs: Array<TabConfig>;
  tabsId: string;
  isContentAnimation?: boolean;
  onTabClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onTabSelect?: (key: string) => void;
  activeKey?: string;
  defaultActiveKey?: string;
};

const CustomTabs = (props: Props) => {
  const {
    tabs,
    tabsId,
    onTabClick,
    onTabSelect,
    activeKey,
    defaultActiveKey,
    isContentAnimation = true,
  } = props;

  if (!tabs.length || !tabs[0]) {
    return null;
  }

  return (
    <TabContainer
      id={tabsId}
      className="custom-tabs"
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey || tabs[0].key}
      onSelect={onTabSelect}
    >
      <div>
        <Nav bsStyle="pills" className="custom-tabs-navigation">
          {tabs.map((tab) => (
            <NavItem
              eventKey={tab.key}
              key={tab.key}
              className="nav-item"
              onClick={onTabClick}
            >
              {tab.title}
            </NavItem>
          ))}
        </Nav>
        <TabContent animation={isContentAnimation}>
          {tabs.map((tab) => <TabPane unmountOnExit eventKey={tab.key} key={tab.key}>{tab.content}</TabPane>)}
        </TabContent>
      </div>
    </TabContainer>
  );
};

export default CustomTabs;
