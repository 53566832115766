import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import './balloon.scss';

const tailPositions = {
  bottomLeft: 'balloon-bottom balloon-bottom-left',
  bottomRight: 'balloon-bottom balloon-bottom-right',
  leftBottom: 'balloon-left balloon-left-bottom',
  leftTop: 'balloon-left balloon-left-top',
  topLeft: 'balloon-top balloon-top-left',
  topRight: 'balloon-top balloon-top-right',
  rightTop: 'balloon-right balloon-right-top',
  rightBottom: 'balloon-right balloon-right-bottom',
  withoutTail: 'balloon-without-tail',
};

type Props = {
  children: ReactNode;
  position: 'top' | 'bottom';
  onEntered?: () => void;
  tailPosition?: keyof typeof tailPositions;
  show?: boolean;
  animationDuration?: number;
} & typeof defaultProps;

const defaultProps = {
  tailPosition: 'bottomLeft',
  show: true,
  animationDuration: 500,
};

const Balloon = (props: Props) => {
  const {
    children,
    position,
    onEntered,
    tailPosition,
    show,
    animationDuration,
  } = props;

  return (
    <CSSTransition
      in={show}
      unmountOnExit
      classNames={`balloon-${position}`}
      timeout={animationDuration}
      onEntered={onEntered}
    >
      <div className={classnames('balloon', tailPositions[tailPosition])}>
        {children}
      </div>
    </CSSTransition>);
};

Balloon.defaultProps = defaultProps;

export default Balloon;
