import RequestError from '@setapp/request-error';
import type { PaymentDetails } from 'services/payment-details-api/payment-details-api';

export const REQUEST = 'PAYMENT_METHOD_REQUEST';
export const REQUEST_SUCCESS = 'PAYMENT_METHOD_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'PAYMENT_METHOD_REQUEST_ERROR';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';

export type PaymentDetailsRequest = {
  type: 'PAYMENT_METHOD_REQUEST';
};
export type PaymentDetailsRequestSuccess = {
  type: 'PAYMENT_METHOD_REQUEST_SUCCESS';
  payload?: PaymentDetails;
};
export type PaymentDetailsRequestError = {
  type: 'PAYMENT_METHOD_REQUEST_ERROR';
  payload: RequestError;
  error: true;
};
export type RemovePaymentDetails = {
  type: 'REMOVE_PAYMENT_METHOD';
};

export type PaymentDetailsAction =
  | PaymentDetailsRequest
  | PaymentDetailsRequestSuccess
  | PaymentDetailsRequestError
  | RemovePaymentDetails;
