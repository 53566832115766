// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import urls from 'config/external-urls';
import * as paymentMethodTypes from 'state/payment-method/payment-method-types';
import type { PaymentMethodType } from 'services/payment-details-api/payment-details-api';
import PaymentMethodPresentation from '../payment-method-presentation/payment-method-presentation';


import './paddle-payment-details.scss';

type Props = {|
  type: ?PaymentMethodType
|};

const PaddlePaymentDetails = ({ type }: Props) => (
  <PaymentMethodPresentation
    details={[
      // type is null for the old user with Paddle provider but without the saved card details
      type === paymentMethodTypes.CREDIT_CARD || !type
        ? (
          <span className="text_lg">
            **** **** **** ****
            <svg
              className="paddle-details__secure-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8 1c2.21 0 4 1.79 4 4v2h1c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1H3c-.552 0-1-.448-1-1V8c0-.552.448-1 1-1h1V5c0-2.21 1.79-4 4-4zm0 2c-1.054 0-1.918.816-1.995 1.85L6 5v2h4V5c0-1.105-.895-2-2-2z"
              />
            </svg>
          </span>
        ) : (
          <span className="text_lg">
            <FormattedMessage
              id="paddlePaymentDetails.payPalInfo"
              defaultMessage="You’re using PayPal as a payment method"
            />
          </span>
        ),
      (
        <span className="paddle-details__description" key={1}>
          <FormattedMessage
            id="paddlePaymentDetails.descriptionSecured"
            defaultMessage="Encrypted and secured by {paddleLink}"
            values={{
              paddleLink: <a href={urls.paddleSite}>Paddle</a>,
            }}
          />
        </span>
      ),
    ]}
  />
);

export default PaddlePaymentDetails;
