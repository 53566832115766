import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';

import type { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';
import { isAiPricePlan, isFeaturedFamilyPricePlan } from 'services/price-plans/utils';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import PaymentButton from 'components/shared/payment-button/payment-button';

type Props = {
  isProcessing: boolean;
  nextPricePlan: PricePlan;
  currentPricePlan: PricePlan;
  nextPricePlanCalculationData: NextPricePlanCalculationData;
  onConfirmLessDevices: () => Promise<void>;
};

const ConfirmLessDevicesStep = (props: Props) => {
  const {
    isProcessing,
    nextPricePlan,
    nextPricePlanCalculationData,
    onConfirmLessDevices,
    currentPricePlan,
  } = props;

  const currentPricePlanMetadata = getPricePlanMetadata(currentPricePlan);
  const nextPricePlanMetadata = getPricePlanMetadata(nextPricePlan);
  const isPlanWithSamePrice = currentPricePlan.price === nextPricePlan.price;
  const showPaymentButton = ['charge', 'instant'].includes(nextPricePlanCalculationData.strategy);

  const buttonTitle = (
    <FormattedMessage
      id="changePricePlanFlow.confirmLessDevicesStep.CTA"
      defaultMessage="Confirm"
    />
  );

  const renderDescription = () => {
    if (isFeaturedFamilyPricePlan(nextPricePlan.tierType)) {
      const { macSeatsCount = 0, iosSeatsCount = 0 } = currentPricePlan.features ?? {};

      return (
        <FormattedMessage
          id="changePricePlanFlow.confirmLessDevicesStep.descriptionFamilyPricePlan"
          defaultMessage={`
            Your current {currentPricePlanTitle} plan has {currentPricePlanMacSeatsCount, plural,
              =0 {}
              other {# Mac}
            } {currentPricePlanSeatsType, select,
              single {}
              multi {+}
            } {currentPricePlanIOSSeatsCount, plural,
              =0 {}
              other {# iOS}
            } device {currentPricePlanSeatsCount, plural,
              one {seat}
              other {seats}
            }. On {nextPricePlanTitle} plan, you will have {nextPricePlanMacSeatsCount} Mac and {nextPricePlanIOSSeatsCount} iOS device seat per user. All additional devices will be disconnected.
          `}
          values={{
            currentPricePlanTitle: currentPricePlanMetadata.title,
            currentPricePlanMacSeatsCount: macSeatsCount,
            currentPricePlanIOSSeatsCount: iosSeatsCount,
            currentPricePlanSeatsType: macSeatsCount > 0 && iosSeatsCount > 0 ? 'multi' : 'single',
            currentPricePlanSeatsCount: macSeatsCount + iosSeatsCount,
            nextPricePlanTitle: nextPricePlanMetadata.title,
            nextPricePlanMacSeatsCount: nextPricePlan.features?.macSeatsCount,
            nextPricePlanIOSSeatsCount: nextPricePlan.features?.iosSeatsCount,
          }}
        />
      );
    }

    if (isPlanWithSamePrice) {
      return (
        <FormattedMessage
          id="changePricePlanFlow.confirmLessDevicesStep.descriptionSamePrice"
          defaultMessage="If you switch, all devices you’ve connected that are not covered by your new plan will be disconnected right away."
        />
      );
    }

    if (isAiPricePlan(currentPricePlan.tierType)) {
      const {
        macSeatsCount: currentPlanMacSeatsCount,
        iosSeatsCount: currentPlanIosSeatsCount = 0,
      } = currentPricePlan.features!;
      const {
        macSeatsCount: nextPlanMacSeatsCount = 0,
        iosSeatsCount: nextPlanIosSeatsCount = 0,
      } = nextPricePlan.features ?? {};

      return (
        <>
          <FormattedMessage
            id="changePricePlanFlow.confirmLessDevicesStep.descriptionLessAiCredits"
            defaultMessage="If you switch, your AI usage limit will decrease"
          />
          {currentPlanMacSeatsCount > nextPlanMacSeatsCount && (
            <>
              ,{' '}
              <FormattedMessage
                id="changePricePlanFlow.confirmLessDevicesStep.descriptionLessAiCredits.macDevicesPart"
                defaultMessage="you’ll lose {macDevicesDifference} Mac devices"
                values={{ macDevicesDifference: currentPlanMacSeatsCount - nextPlanMacSeatsCount }}
              />
            </>
          )}
          {(currentPlanIosSeatsCount && !nextPlanIosSeatsCount) ? (
            <>
              ,{' '}
              <FormattedMessage
                id="changePricePlanFlow.confirmLessDevicesStep.descriptionLessAiCredits.iosDevicesPart"
                defaultMessage="and won’t be able to use Setapp on iOS"
              />
            </>
          ) : null}
          .
        </>
      );
    }

    return (
      <FormattedMessage
        id="changePricePlanFlow.confirmLessDevicesStep.description"
        defaultMessage="If you switch to the {nextPricePlanTitle} plan, all the devices outside this plan will be automatically disconnected after {nextPaymentDate}."
        values={{
          nextPricePlanTitle: nextPricePlanMetadata.title,
          nextPaymentDate: (
            <strong>
              <FormattedDate
                value={nextPricePlanCalculationData.nextPaymentDate * 1000}
                year="numeric"
                month="long"
                day="numeric"
              />
            </strong>
          ),
        }}
      />
    );
  };

  return (
    <>
      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="changePricePlanFlow.confirmLessDevicesStep.title"
          defaultMessage="Are you sure?"
        />
      </h3>

      <div className="mb-8 text_lg">
        {renderDescription()}
      </div>

      {showPaymentButton ? (
        <PaymentButton
          title={buttonTitle}
          onClick={onConfirmLessDevices}
        />
      ) : (
        <ButtonLoading
          variant="primary"
          size="lg"
          block
          disabled={isProcessing}
          isLoading={isProcessing}
          onClick={onConfirmLessDevices}
        >
          {buttonTitle}
        </ButtonLoading>
      )}
    </>
  );
};

export default ConfirmLessDevicesStep;
