export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const SIGNUP_TOKEN_VALID = 'SIGNUP_TOKEN_VALID';
export const SIGNUP_TOKEN_INVALID = 'SIGNUP_TOKEN_INVALID';
export const SIGNUP_TOKEN_EMPTY = 'SIGNUP_TOKEN_EMPTY';
export const SIGNUP_EMAIL_OPT_IN = 'SIGNUP_EMAIL_OPT_IN';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST';
export const NEW_PASSWORD_SET = 'NEW_PASSWORD_SET';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const WELCOME_PAGE_DOWNLOAD_STARTED = 'WELCOME_PAGE_DOWNLOAD_STARTED';
export const WELCOME_PAGE_OPEN_DESKTOP = 'WELCOME_PAGE_OPEN_DESKTOP';
export const WELCOME_PAGE_DIRECT_DOWNLOAD_CLICK = 'WELCOME_PAGE_DIRECT_DOWNLOAD_CLICK';
export const WELCOME_PAGE_DOWNLOAD_BUTTON_CLICK = 'WELCOME_PAGE_DOWNLOAD_BUTTON_CLICK';

export const PAYMENT_DETAILS_CHECKOUT_LOADED = 'PAYMENT_DETAILS_CHECKOUT_LOADED';
export const PAYMENT_DETAILS_UI_LOADED = 'PAYMENT_DETAILS_UI_LOADED';
export const PAYMENT_DETAILS_FORM_DISPLAYED = 'PAYMENT_DETAILS_FORM_DISPLAYED';
export const PAYMENT_DETAILS_PAYMENT_METHOD_SELECT = 'PAYMENT_DETAILS_PAYMENT_METHOD_SELECT';
export const PAYMENT_DETAILS_COUNTRY_SELECT = 'PAYMENT_DETAILS_COUNTRY_SELECT';
export const PAYMENT_DETAILS_ADD = 'PAYMENT_DETAILS_ADD';
export const PAYMENT_DETAILS_ADD_CLICK = 'PAYMENT_DETAILS_ADD_CLICK';
export const PAYMENT_DETAILS_FORM_ERROR = 'PAYMENT_DETAILS_FORM_ERROR';
export const PAYMENT_DETAILS_DECLINE_TYPE_SENT = 'PAYMENT_DETAILS_DECLINE_TYPE_SENT';
export const PAYMENT_DETAILS_PAYMENT_INFO_API_ERROR = 'PAYMENT_DETAILS_PAYMENT_INFO_API_ERROR';
export const PAYMENT_DETAILS_CLICK_UPSEL = 'PAYMENT_DETAILS_CLICK_UPSEL';
export const PAYMENT_DETAILS_CHANGE_PLAN = 'PAYMENT_DETAILS_CHANGE_PLAN';
export const PAYMENT_DETAILS_CONFIRM_CHANGE_PLAN = 'PAYMENT_DETAILS_CONFIRM_CHANGE_PLAN';
export const PAYMENT_DETAILS_FORM_ERROR_BRAINTREE = 'PAYMENT_DETAILS_FORM_ERROR_BRAINTREE';
export const PAYMENT_DETAILS_START_TRIAL_CLICK = 'PAYMENT_DETAILS_START_TRIAL_CLICK';
export const PAYMENT_DETAILS_SKIP = 'PAYMENT_DETAILS_SKIP';

export const REMOVE_PAYMENT_DETAILS_MODAL_OPEN = 'REMOVE_PAYMENT_DETAILS_MODAL_OPEN';
export const REMOVE_PAYMENT_DETAILS_CONFIRM = 'REMOVE_PAYMENT_DETAILS_CONFIRM';

export const HEADER_DOWNLOAD_LINK_CLICK = 'HEADER_DOWNLOAD_LINK_CLICK';

export const ACCOUNT_PASSWORD_CHANGED = 'ACCOUNT_PASSWORD_CHANGED';
export const ACCOUNT_EMAIL_CHANGED = 'ACCOUNT_EMAIL_CHANGED';

export const DELETE_ACCOUNT_CONFIRM = 'DELETE_ACCOUNT_CONFIRM';

export const CANCEL_SUBSCRIPTION_OPEN = 'CANCEL_SUBSCRIPTION_OPEN';
export const CANCEL_SUBSCRIPTION_CHOOSE_REASON = 'CANCEL_SUBSCRIPTION_CHOOSE_REASON';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_PAUSE_CLICK = 'CANCEL_SUBSCRIPTION_PAUSE_CLICK';
export const CANCEL_SUBSCRIPTION_CONTINUE_CLICK = 'CANCEL_SUBSCRIPTION_CONTINUE_CLICK';
export const CANCEL_SUBSCRIPTION_SURVEY_CLICK = 'CANCEL_SUBSCRIPTION_SURVEY_CLICK';

export const SUBSCRIPTION_ACTIVATION_MODAL_OPEN = 'SUBSCRIPTION_ACTIVATION_MODAL_OPEN';
export const SUBSCRIPTION_ACTIVATION_CHANGE_PRICE_PLAN = 'SUBSCRIPTION_ACTIVATION_CHANGE_PRICE_PLAN';
export const SUBSCRIPTION_ACTIVATION_CHANGE_DEVICES_COUNT = 'SUBSCRIPTION_ACTIVATION_CHANGE_DEVICES_COUNT';
export const SUBSCRIPTION_ACTIVATED = 'SUBSCRIPTION_ACTIVATED';
export const SUBSCRIPTION_ACTIVATED_DOWNLOAD_START = 'SUBSCRIPTION_ACTIVATED_DOWNLOAD_START';
export const SUBSCRIPTION_ACTIVATED_LAUNCH_DESKTOP = 'SUBSCRIPTION_ACTIVATED_LAUNCH_DESKTOP';

export const SUBSCRIPTION_PLAN_MODAL_OPEN = 'SUBSCRIPTION_PLAN_MODAL_OPEN';
export const SUBSCRIPTION_PLAN_CHANGED = 'SUBSCRIPTION_PLAN_CHANGED';

export const SUBSCRIPTION_GET_PLAN = 'SUBSCRIPTION_GET_PLAN';

export const DEACTIVATE_DEVICE_MODAL_OPEN = 'DEACTIVATE_DEVICE_MODAL_OPEN';
export const DEVICE_DEACTIVATED = 'DEVICE_DEACTIVATED';
export const DEVICE_DEACTIVE_ALL_IOS_CLICK = 'DEVICE_DEACTIVE_ALL_IOS_CLICK';
export const DEVICE_ALL_IOS_DEACTIVATED = 'DEVICE_ALL_IOS_DEACTIVATED';
export const DEVICE_LIMIT_REACHED = 'DEVICE_LIMIT_REACHED';

export const BUY_SEAT_MODAL_OPEN = 'BUY_SEAT_MODAL_OPEN';
export const BUY_SEAT_AMOUNT_SELECTED = 'BUY_SEAT_AMOUNT_SELECTED';
export const BUY_SEAT_PAYMENT_CLICK = 'BUY_SEAT_PAYMENT_CLICK';
export const SEAT_WAS_BOUGHT = 'SEAT_WAS_BOUGHT';
export const BUY_SEAT_PAYMENT_ERROR = 'BUY_SEAT_PAYMENT_ERROR';
export const CANCEL_SEAT_MODAL_OPEN = 'CANCEL_SEAT_MODAL_OPEN';
export const CANCEL_SEAT_AMOUNT_SELECTED = 'CANCEL_SEAT_AMOUNT_SELECTED';
export const SEAT_CANCELED = 'SEAT_CANCELED';
export const REACTIVATE_SEAT_MODAL_OPEN = 'REACTIVATE_SEAT_MODAL_OPEN';
export const SEAT_REACTIVATED = 'SEAT_REACTIVATED';

export const DEVICES_PAGE_CHANGE_PLAN_CLICK = 'DEVICES_PAGE_CHANGE_PLAN_CLICK';

export const SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_FROM_URL = 'SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_FROM_URL';
export const SUBSCRIPTION_BLOCK_DOWNLOAD_CLICK = 'SUBSCRIPTION_BLOCK_DOWNLOAD_CLICK';
export const SUBSCRIPTION_BLOCK_DOWNLOAD_START = 'SUBSCRIPTION_BLOCK_DOWNLOAD_START';
export const SUBSCRIPTION_BLOCK_CLICK_BUY_PLAN = 'SUBSCRIPTION_BLOCK_CLICK_BUY_PLAN';
export const SUBSCRIPTION_BLOCK_REFERRAL_CLICK = 'SUBSCRIPTION_BLOCK_REFERRAL_CLICK';
export const SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_CLICK = 'SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_CLICK';

export const REFERRAL_SHARE_CLICK = 'REFERRAL_SHARE_CLICK';
export const REFERRAL_SEND_INVITATION_CLICK = 'REFERRAL_SEND_INVITATION_CLICK';

export const CROSS_AUTH_SUCCESS = 'CROSS_AUTH_SUCCESS';
export const CROSS_AUTH_ERROR = 'CROSS_AUTH_ERROR';

export const SWITCH_TO_ANNUAL_LINK_CLICK = 'SWITCH_TO_ANNUAL_LINK_CLICK';

export const GIFT_CARD_REDEEM_MANUALLY = 'GIFT_CARD_REDEEM_MANUALLY';

export const FAMILY_PLAN_MEMBER_CREATED_ACCOUNT = 'FAMILY_PLAN_MEMBER_CREATED_ACCOUNT';
export const FAMILY_PLAN_DROP_CLICK = 'FAMILY_PLAN_DROP_CLICK';
export const FAMILY_PLAN_DROP_SUCCESS = 'FAMILY_PLAN_DROP_SUCCESS';
export const FAMILY_PLAN_LEAVE_CLICK = 'FAMILY_PLAN_LEAVE_CLICK';
export const FAMILY_PLAN_LEAVE_SUCCESS = 'FAMILY_PLAN_LEAVE_SUCCESS';
export const FAMILY_PLAN_SWITCH_CLICK = 'FAMILY_PLAN_SWITCH_CLICK';
export const FAMILY_PLAN_SWITCH_SUCCESS = 'FAMILY_PLAN_SWITCH_SUCCESS';
export const FAMILY_PLAN_PAYMENT_INFO_ADDED = 'FAMILY_PLAN_PAYMENT_INFO_ADDED';
export const FAMILY_PLAN_INVITE_SENT = 'FAMILY_PLAN_INVITE_SENT';
export const FAMILY_PLAN_INVITE_EXISTING_USER = 'FAMILY_PLAN_INVITE_EXISTING_USER';
export const FAMILY_PLAN_REMOVE_MEMBER_CLICK = 'FAMILY_PLAN_REMOVE_MEMBER_CLICK';
export const FAMILY_PLAN_REMOVE_MEMBER_SUCCESS = 'FAMILY_PLAN_REMOVE_MEMBER_SUCCESS';

export const SELECT_PLAN_PAGE_CHANGE_PLAN_CLICK = 'SELECT_PLAN_PAGE_CHANGE_PLAN_CLICK';
export const SELECT_PLAN_PAGE_PLAN_SELECT = 'SELECT_PLAN_PAGE_PLAN_SELECT';
export const SELECT_PLAN_PAGE_ARE_YOU_SURE_CONFIRM = 'SELECT_PLAN_PAGE_ARE_YOU_SURE_CONFIRM';
export const SELECT_PLAN_PAGE_CONFIRM_CHANGE_PLAN = 'SELECT_PLAN_PAGE_CONFIRM_CHANGE_PLAN';
export const SELECT_PLAN_PAGE_CHANGE_BILLING_PERIOD_CLICK = 'SELECT_PLAN_PAGE_CHANGE_BILLING_PERIOD_CLICK';
export const SELECT_PLAN_PAGE_CHANGE_BILLING_PERIOD_SELECT = 'SELECT_PLAN_PAGE_CHANGE_BILLING_PERIOD_SELECT';
export const SELECT_PLAN_PAGE_CONFIRM_CHANGE_BILLING_PERIOD = 'SELECT_PLAN_PAGE_CONFIRM_CHANGE_BILLING_PERIOD';
export const SELECT_PLAN_PAGE_CANCEL_SUBSCRIPTION_CLICK = 'SELECT_PLAN_PAGE_CANCEL_SUBSCRIPTION_CLICK';
export const SELECT_PLAN_PAGE_ERROR = 'SELECT_PLAN_PAGE_ERROR';

export const IOS_ACTIVATION_APP_CLICK = 'IOS_ACTIVATION_APP_CLICK';
export const IOS_ACTIVATION_APP_STORE_CLICK = 'IOS_ACTIVATION_APP_STORE_CLICK';
export const IOS_ACTIVATION_ACTIVATE_CLICK = 'IOS_ACTIVATION_ACTIVATE_CLICK';
export const IOS_ACTIVATION_REMINDER_SHOWN = 'IOS_ACTIVATION_REMINDER_SHOWN';
export const IOS_ACTIVATION_REMINDER_APP_STORE_CLICK = 'IOS_ACTIVATION_REMINDER_APP_STORE_CLICK';

export const GET_APP_IOS_FORM_FORM_SHOWN = 'GET_APP_IOS_FORM_FORM_SHOWN';
export const GET_APP_IOS_FORM_STEP_2_SHOWN = 'GET_APP_IOS_FORM_STEP_2_SHOWN';
export const GET_APP_IOS_FORM_STEP_DONE_CLICK = 'GET_APP_IOS_FORM_STEP_DONE_CLICK';

export const REDEEM_CODE_CLICK = 'REDEEM_CODE_CLICK';
export const REDEEM_CODE_ERROR = 'REDEEM_CODE_ERROR';
export const REDEEM_CODE_SUCCESS = 'REDEEM_CODE_SUCCESS';
export const LEARN_ABOUT_REDEEM = 'LEARN_ABOUT_REDEEM';

export const SPECIAL_OFFER_BANNER_CLICK = 'SPECIAL_OFFER_BANNER_CLICK';

// Desktop CCR test events
export const DESKTOP_CCR_TEST_CLICK = 'DESKTOP_CCR_TEST_CLICK';
export const DESKTOP_CCR_TEST_POPUP_CLICK = 'DESKTOP_CCR_TEST_POPUP_CLICK';

export const AI_PLUS_UPGRADE_PLAN_CLICK = 'AI_PLUS_UPGRADE_PLAN_CLICK';
export const AI_PLUS_LANDING_PAGE_VIEW = 'AI_PLUS_LANDING_PAGE_VIEW';
export const AI_PLUS_SELECT_PLAN = 'AI_PLUS_SELECT_PLAN';
export const AI_PLUS_CONFIRM_CHANGE_PLAN = 'AI_PLUS_CONFIRM_CHANGE_PLAN';

export const SETAPP_MOBILE_EU_SET_UP_FREE_TRIAL = 'SETAPP_MOBILE_EU_SET_UP_FREE_TRIAL';
export const SETAPP_MOBILE_EU_ERROR_OCCURED = 'SETAPP_MOBILE_EU_ERROR_OCCURED';
