import React from 'react';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import { redirectToOAuthServer } from 'utils/oauth';
import useOAuthClientInfo from 'components/hooks/use-oauth-client-info/use-oauth-client-info';
import AuthorizationForm from '../authorization-form/authorization-form';
import OAuthError from '../oauth-error/oauth-error';

import './authorization-container.scss';

const OAuthAuthorizationContainer = () => {
  const {
    data,
    isError,
    isLoading,
    authScope,
    redirectUrl,
  } = useOAuthClientInfo({ allowRedirect: true });

  const handleFormSubmit = () => redirectToOAuthServer(redirectUrl, true);

  const handleFormCancellation = () => redirectToOAuthServer(redirectUrl, false);

  if (isError) {
    return <OAuthError />;
  }

  if (isLoading) {
    return (
      <div className="authorization-container__loading-container full-height">
        <div className="authorization-container__loading-center">
          <AnimatedLogo animate />
        </div>
      </div>
    );
  }

  if (data) {
    return (
      <AuthorizationForm
        appName={data.name}
        iconUrl={data.iconUrl}
        authScope={authScope}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancellation}
      />
    );
  }

  return null;
};

export default OAuthAuthorizationContainer;
