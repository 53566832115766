import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as pricePlanTypes from 'state/price-plans/price-plans-types';

type Props = {
  tierType: string;
};

const PricePlanTitle = ({ tierType }: Props) => {
  switch (tierType) {
    case pricePlanTypes.MONTHLY:
    case pricePlanTypes.ADDITIONAL_SEAT_MONTHLY: {
      return (
        <FormattedMessage
          id="pricePlans.title.monthly"
          defaultMessage="Monthly"
        />
      );
    }

    case pricePlanTypes.ANNUAL:
    case pricePlanTypes.CMM_UPSALE: {
      return (
        <FormattedMessage
          id="pricePlans.title.annual"
          defaultMessage="Annual"
        />
      );
    }

    case pricePlanTypes.EDU: {
      return (
        <FormattedMessage
          id="pricePlans.title.edu"
          defaultMessage="Educational"
        />
      );
    }

    case pricePlanTypes.CYBER_MONDAY: {
      return (
        <FormattedMessage
          id="pricePlans.title.cyberMonday"
          defaultMessage="Cyber Monday Deal"
        />
      );
    }

    case pricePlanTypes.FAMILY_MONTHLY:
    case pricePlanTypes.OLD_FAMILY_MONTHLY: {
      return (
        <FormattedMessage
          id="pricePlans.title.familyMonthly"
          defaultMessage="Family plan, monthly"
        />
      );
    }

    case pricePlanTypes.FAMILY_ANNUAL:
    case pricePlanTypes.OLD_FAMILY_ANNUAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.familyAnnual"
          defaultMessage="Family plan, annual"
        />
      );
    }

    case pricePlanTypes.MAC_MONTHLY:
    case pricePlanTypes.NEW_MONTHLY_1_MAC: {
      return (
        <FormattedMessage
          id="pricePlans.title.macMonthly"
          defaultMessage="Mac plan, monthly"
        />
      );
    }

    case pricePlanTypes.MAC_PLUS_IOS_MONTHLY:
    case pricePlanTypes.NEW_MONTHLY_1_MAC_UNLIM_IOS: {
      return (
        <FormattedMessage
          id="pricePlans.title.macAndIosMonthly"
          defaultMessage="Mac + iOS plan, monthly"
        />
      );
    }

    case pricePlanTypes.POWER_USER_MONTHLY:
    case pricePlanTypes.NEW_MONTHLY_4_MAC_UNLIM_IOS: {
      return (
        <FormattedMessage
          id="pricePlans.title.powerMonthly"
          defaultMessage="Power User plan, monthly"
        />
      );
    }

    case pricePlanTypes.MAC_ANNUAL:
    case pricePlanTypes.NEW_ANNUAL_1_MAC: {
      return (
        <FormattedMessage
          id="pricePlans.title.macAnnual"
          defaultMessage="Mac plan, annual"
        />
      );
    }

    case pricePlanTypes.MAC_PLUS_IOS_ANNUAL:
    case pricePlanTypes.NEW_ANNUAL_1_MAC_UNLIM_IOS: {
      return (
        <FormattedMessage
          id="pricePlans.title.macAndIosAnnual"
          defaultMessage="Mac + iOS plan, annual"
        />
      );
    }

    case pricePlanTypes.POWER_USER_ANNUAL:
    case pricePlanTypes.NEW_ANNUAL_4_MAC_UNLIM_IOS: {
      return (
        <FormattedMessage
          id="pricePlans.title.powerAnnual"
          defaultMessage="Power User plan, annual"
        />
      );
    }

    case pricePlanTypes.TWO_MACS_MONTHLY: {
      return (
        <FormattedMessage
          id="pricePlans.title.twoMacsMonthly"
          defaultMessage="Two Macs plan, monthly"
        />
      );
    }

    case pricePlanTypes.TWO_MACS_ANNUAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.twoMacsAnnual"
          defaultMessage="Two Macs plan, annual"
        />
      );
    }

    case pricePlanTypes.IOS_MONTHLY: {
      return (
        <FormattedMessage
          id="pricePlans.title.iosMonthly"
          defaultMessage="iOS plan, monthly"
        />
      );
    }

    case pricePlanTypes.IOS_ANNUAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.iosAnnual"
          defaultMessage="iOS plan, annual"
        />
      );
    }

    case pricePlanTypes.EDU_TRIAL:
      return (
        <FormattedMessage
          id="pricePlans.title.newEdu"
          defaultMessage="Education plan"
        />
      );

    case pricePlanTypes.EDU_ANNUAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.newEduAnnual"
          defaultMessage="Education plan, annual"
        />
      );
    }

    case pricePlanTypes.AI_ENTHUSIAST_TRIAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.aiEnthusiastTrial"
          defaultMessage="AI Enthusiast plan"
        />
      );
    }

    case pricePlanTypes.AI_ENTHUSIAST_MONTHLY: {
      return (
        <FormattedMessage
          id="pricePlans.title.aiEnthusiastMonthly"
          defaultMessage="AI Enthusiast plan, monthly"
        />
      );
    }

    case pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_PREV:
    case pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_FULL:
    case pricePlanTypes.AI_ENTHUSIAST_ANNUAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.aiEnthusiastAnnual"
          defaultMessage="AI Enthusiast plan, annual"
        />
      );
    }

    case pricePlanTypes.AI_EXPERT_TRIAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.aiExpertTrial"
          defaultMessage="AI Expert plan"
        />
      );
    }

    case pricePlanTypes.AI_EXPERT_MONTHLY: {
      return (
        <FormattedMessage
          id="pricePlans.title.aiExpertMonthly"
          defaultMessage="AI Expert plan, monthly"
        />
      );
    }

    case pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_PREV:
    case pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_FULL:
    case pricePlanTypes.AI_EXPERT_ANNUAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.aiExpertAnnual"
          defaultMessage="AI Expert plan, annual"
        />
      );
    }

    case pricePlanTypes.IOS_ADVANCED_TRIAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.iosAdvancedTrial"
          defaultMessage="iOS Advanced plan"
        />
      );
    }

    case pricePlanTypes.IOS_ADVANCED_MONTHLY: {
      return (
        <FormattedMessage
          id="pricePlans.title.iosAdvancedMonthly"
          defaultMessage="iOS Advanced plan, monthly"
        />
      );
    }

    case pricePlanTypes.IOS_ADVANCED_ANNUAL: {
      return (
        <FormattedMessage
          id="pricePlans.title.iosAdvancedAnnual"
          defaultMessage="iOS Advanced plan, annual"
        />
      );
    }

    default: {
      return (
        <FormattedMessage
          id="pricePlans.title.custom"
          defaultMessage="Custom"
        />
      );
    }
  }
};

export default PricePlanTitle;
