import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Notification, Button } from '@setapp/ui-kit';

import { getSubscriptions } from 'state/root-reducer';

import useReactivateSubscription from 'components/hooks/use-reactivate-subscription/use-reactivate-subscription';

import NotificationNeedHelpLink from '../notification-need-help-link/notification-need-help-link';

import './status-cancelled-notification.scss';

const StatusCancelledNotification = () => {
  const isSubscriptionProcessing = useSelector(getSubscriptions).isLoading;
  const { onReactivateButtonClick } = useReactivateSubscription();

  return (
    <Notification type="info">
      <div className="status-cancelled-notification__container">
        <p className="mb-0">
          <FormattedMessage
            id="subscriptionInfo.statusNotifications.cancelled"
            defaultMessage="You have cancelled your subscription. You can use the apps on Setapp until the end of your billing period, after that they’ll become unavailable."
          />
        </p>
        <div className="status-cancelled-notification__action">
          <Button
            size="sm"
            onClick={onReactivateButtonClick}
            disabled={isSubscriptionProcessing}
            data-qa="reactivateBtn"
          >
            <FormattedMessage
              id="subscriptionInfo.reactivateCancelled.btnReactivate"
              defaultMessage="Renew subscription"
            />
          </Button>
        </div>
      </div>
      <NotificationNeedHelpLink />
    </Notification>
  );
};

export default StatusCancelledNotification;
