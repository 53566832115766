import React, { FC } from 'react';

import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';

import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import { useSubscriptionContext } from 'components/pages/subscription/context/subscription-context';

import PlanRow from '../plan-row/plan-row';
import PriceRow from '../price-row/price-row';
import StatusRow from '../status-row/status-row';
import NextPaymentRow from '../next-payment-row/next-payment-row';
import VatNoteRow from '../vat-note-row/vat-note-row';
import ScheduledDowngradeMessage from '../scheduled-downgrade-message/scheduled-downgrade-message';
import AiCreditsRow from '../ai-credits-row/ai-credits-row';

const SubscriptionActive: FC = () => {
  const { taxAmount, pricePlan, subscription, isScheduledDowngrade } = useSubscriptionContext();

  const price = pricePlan?.price;
  const tierType = pricePlan?.tierType;
  const currency = pricePlan?.currency;
  const paidMonth = pricePlan?.paidMonth;
  const discount = subscription?.discount;
  const expirationDate = subscription?.expirationDate;
  const nextPaymentDate = subscription?.nextPaymentDate;

  return (
    <div data-testid="subscriptionActive">
      <PlanRow value={<PricePlanTitle tierType={tierType} />} />

      <PriceRow
        price={price}
        currency={currency}
        paidMonth={paidMonth}
        tax={taxAmount}
        discount={discount}
      />

      <StatusRow
        status={subscriptionStatuses.ACTIVE}
        expirationDate={expirationDate}
      />

      {nextPaymentDate && <NextPaymentRow nextPaymentDate={nextPaymentDate} />}

      <AiCreditsRow />

      {isScheduledDowngrade && (
        <div className="mt-5 mb-3" data-testid="scheduledDowngradeMessage">
          <ScheduledDowngradeMessage
            nextPricePlan={subscription.nextPricePlan as PricePlan}
            nextPaymentDate={subscription?.nextPaymentDate ?? 0}
          />
        </div>
      )}

      {taxAmount === 0 && <VatNoteRow />}
    </div>
  );
};

export default SubscriptionActive;
