import { useSelector } from 'react-redux';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import type { PreselectedStepValue } from 'components/modals/manage-subscription/manage-subscription';
import { showModal } from 'state/modal/modal-reducer';
import { isManageSubscriptionAllowed as getIsManageSubscriptionAllowed } from 'state/root-reducer';
import analytics, { events } from 'utils/analytics';

const useOpenManageSubscriptionModal = () => {
  const isManageSubscriptionAllowed = useSelector(getIsManageSubscriptionAllowed);

  const showModalAction = useDispatchAction(showModal);

  return (step?: Maybe<PreselectedStepValue>, preselectedNextPlanId?: Maybe<string>) => {
    if (!isManageSubscriptionAllowed) return;

    if (step) {
      analytics.trackEvent(events.SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_FROM_URL, {
        eventLabel: step,
      });
      showModalAction(
        'MANAGE_SUBSCRIPTION',
        {
          selectedStep: step,
          preselectedNextPlanId: preselectedNextPlanId ? parseInt(preselectedNextPlanId, 10) : undefined,
        },
      );
    } else {
      analytics.trackEvent(events.SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_CLICK);
      showModalAction('MANAGE_SUBSCRIPTION');
    }
  };
};

export default useOpenManageSubscriptionModal;
