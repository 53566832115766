import React from 'react';
import SupportLink from 'components/shared/support-link/support-link';

const NotificationNeedHelpLink = () => (
  <div className="mt-4">
    <SupportLink />
  </div>
);

export default NotificationNeedHelpLink;
