import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as statuses from 'state/gift-cards/gift-card-statuses';
import type { GiftCardStatus as GiftCardStatusType } from 'state/gift-cards/gift-card-statuses';

type Props = {
  status: GiftCardStatusType;
};

const GifCardStatus = ({ status }: Props) => {
  const statusTitles = {
    [statuses.NOT_USED]: <FormattedMessage id="giftCardStatus.notUsed" defaultMessage="Not Used" />,
    [statuses.USED]: <FormattedMessage id="giftCardStatus.used" defaultMessage="Activated" />,
    [statuses.PENDING]: (
      <FormattedMessage
        id="giftCardStatus.pending"
        defaultMessage="Pending. The card will become active as soon as your payment falls through."
      />
    ),
  };

  return (
    <span className={status === statuses.USED ? 'text-success' : ''}>
      {statusTitles[status]}
    </span>
  );
};

export default GifCardStatus;
