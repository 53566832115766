import React from 'react';
import { FormattedMessage } from 'react-intl';

import CancelSubscriptionStepLayout from '../cancel-subscription-step-layout/cancel-subscription-step-layout';

import heartImage from './images/heart.svg';
import sorryGuyIllustration from './images/sorry-guy.png';
import sorryGuyIllustration2x from './images/sorry-guy@2x.png';
import sorryGuyIllustration3x from './images/sorry-guy@3x.png';

import './intro-step.scss';


export type Props = {
  onContinueButtonClick: () => void;
  onCloseButtonClick: () => void;
};

const IntroStep = ({ onContinueButtonClick, onCloseButtonClick }: Props) => (
  <CancelSubscriptionStepLayout
    illustration={
      <img
        src={sorryGuyIllustration}
        srcSet={`${sorryGuyIllustration2x} 2x, ${sorryGuyIllustration3x} 3x`}
        alt="Sorry guy illustration"
        width="220"
        height="158"
      />
    }
    title={
      <FormattedMessage
        id="cancelSubscription.intro.title"
        defaultMessage="So this is goodbye then…"
      />
    }
    onPrimaryButtonClick={onContinueButtonClick}
    primaryButtonText={
      <FormattedMessage id="cancelSubscription.intro.finishCancellationButton" defaultMessage="Cancel subscription" />
    }
    onSecondaryButtonClick={onCloseButtonClick}
    secondaryButtonText={
      <FormattedMessage id="cancelSubscription.intro.cancelButton" defaultMessage="Keep using Setapp" />
    }
  >
    <div className="intro-step__description">
      <p className="mb-6">
        <FormattedMessage
          id="cancelSubscription.intro.description.firstLine"
          defaultMessage="One doesn’t come across great customers like you very often… So thanks for using Setapp — we loved having you!"
        />
      </p>
      <p>
        <FormattedMessage
          id="cancelSubscription.intro.description.secondLine"
          defaultMessage="You are welcome back anytime {heartIcon}"
          values={{ heartIcon: <img className="ml-1" src={heartImage} alt="Heart" /> }}
        />
      </p>
    </div>
  </CancelSubscriptionStepLayout>
);

export default IntroStep;
