// @flow

import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import type { IntlShape } from 'react-intl';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import * as pricePlanType from 'state/price-plans/price-plans-types';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import ActivationFeatureSelect from '../activation-feature-select/activation-feature-select';
import ActivatePagePlanDescription from './activate-page-plan-description/activate-page-plan-description';


type Props = {|
  pricePlans: Array<PricePlan>,
  currentPricePlan: PricePlan,
  onChange: (e: SyntheticEvent<HTMLSelectElement>) => mixed,
  intl: IntlShape,
|};

const messages = defineMessages({
  personalPlanTitle: {
    id: 'activateSubscription.selectPricePlan.personalPlanTitle',
    defaultMessage: 'Personal plan, {period}',
  },
  eduPlanTitle: {
    id: 'activateSubscription.selectPricePlan.eduPlanTitle',
    defaultMessage: 'Edu plan, {period}',
  },
  familyPlanTitle: {
    id: 'activateSubscription.selectPricePlan.familyPlanTitle',
    defaultMessage: 'Family plan, {period}',
  },
  new1MacPlanTitle: {
    id: 'activateSubscription.selectPricePlan.new1MacPlanTitle',
    defaultMessage: 'Mac plan, {period}',
  },
  new1MacUnlimIosPlanTitle: {
    id: 'activateSubscription.selectPricePlan.new1MacUnlimIosPlanTitle',
    defaultMessage: 'Mac + iOS plan, {period}',
  },
  new4MacUnlimIosPlanTitle: {
    id: 'activateSubscription.selectPricePlan.new4MacUnlimIosPlanTitle',
    defaultMessage: 'Power User plan, {period}',
  },
  customPlanTitle: {
    id: 'activateSubscription.selectPricePlan.customPlanTitle',
    defaultMessage: 'Custom plan, {period}',
  },
  billingCycle: {
    id: 'activateSubscription.selectPricePlan.billingCycle',
    defaultMessage: '{monthsCount, plural, one {monthly} =12 {annual} other {{monthsCount} months}}',
  },
});

const pricePlanTitles = {
  [pricePlanType.MONTHLY]: messages.personalPlanTitle,
  [pricePlanType.ANNUAL]: messages.personalPlanTitle,
  [pricePlanType.EDU]: messages.eduPlanTitle,
  [pricePlanType.OLD_FAMILY_MONTHLY]: messages.familyPlanTitle,
  [pricePlanType.OLD_FAMILY_ANNUAL]: messages.familyPlanTitle,
  [pricePlanType.NEW_MONTHLY_1_MAC]: messages.new1MacPlanTitle,
  [pricePlanType.NEW_ANNUAL_1_MAC]: messages.new1MacPlanTitle,
  [pricePlanType.NEW_MONTHLY_1_MAC_UNLIM_IOS]: messages.new1MacUnlimIosPlanTitle,
  [pricePlanType.NEW_ANNUAL_1_MAC_UNLIM_IOS]: messages.new1MacUnlimIosPlanTitle,
  [pricePlanType.NEW_MONTHLY_4_MAC_UNLIM_IOS]: messages.new4MacUnlimIosPlanTitle,
  [pricePlanType.NEW_ANNUAL_4_MAC_UNLIM_IOS]: messages.new4MacUnlimIosPlanTitle,
};

function getPricePlanLabel(pricePlan: PricePlan, intl) {
  const { tierType, paidMonth } = pricePlan;

  if (tierType in pricePlanTitles) {
    return intl.formatMessage(pricePlanTitles[tierType], {
      period: intl.formatMessage(messages.billingCycle, {
        monthsCount: paidMonth,
      }),
    });
  }

  return intl.formatMessage(messages.customPlanTitle, {
    period: intl.formatMessage(messages.billingCycle, {
      monthsCount: paidMonth,
    }),
  });
}

const SelectPricePlan = (props: Props) => {
  const {
    pricePlans,
    currentPricePlan,
    onChange,
    intl,
  } = props;

  const pricePlanOptions = pricePlans.map((pricePlan) => ({
    value: pricePlan.id,
    label: getPricePlanLabel(pricePlan, intl),
  }));
  const currentPrice = <FormattedMessage
    id="activatePage.priceInfo"
    defaultMessage="{price}/mo"
    values={{
      price: <FormattedPrice
        currency={currentPricePlan.currency}
        price={currentPricePlan.price / currentPricePlan.paidMonth}
             />,
    }}
                       />;
  const planDescription = (
    <div className="text_color-success text_sm">
      <ActivatePagePlanDescription
        currentPricePlan={currentPricePlan}
      />
    </div>
  );

  return (
    <ActivationFeatureSelect
      id="activatePricePlan"
      label={<FormattedMessage id="selectPricePlan.label" defaultMessage="Select price plan" />}
      value={currentPricePlan.id}
      options={pricePlanOptions}
      price={currentPrice}
      helpText={planDescription}
      onChange={onChange}
    />
  );
};

export { SelectPricePlan as PureSelectPricePlan };

export default injectIntl(SelectPricePlan);
