// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import type { ReactElement, ChangeEventHandler, FormEventHandler } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import type { DeviceSeat } from 'services/devices-to-seats-mapper/devices-to-seats-mapper';
import DeviceSeatCheckbox from './device-seat-checkbox';

import './select-seats-form.scss';


type Props = {
  deviceSeats: Array<DeviceSeat>;
  nextSeatsPaymentDate: number;
  selectedSeats: Array<string>;
  canSelectSeats?: boolean;
  formError?: ReactElement;
  onSeatCheckboxChange: ChangeEventHandler;
  onSubmit: FormEventHandler;
}

const SelectSeatsForm = (props: Props) => {
  const {
    deviceSeats,
    nextSeatsPaymentDate,
    selectedSeats,
    canSelectSeats,
    formError,
    onSeatCheckboxChange,
    onSubmit,
  } = props;

  return (
    <form onSubmit={onSubmit} noValidate data-qa="selectDevicesToDisconnectForm">
      <FormattedMessage
        id="cancelAdditionalSeats.selectDevicesDescription"
        defaultMessage="Select the device you would like to remove starting {nextPaymentDate}."
        values={{
          nextPaymentDate: <FormattedDate
            value={nextSeatsPaymentDate * 1000}
            year="numeric"
            month="short"
            day="numeric"
                           />,
        }}
      />

      {deviceSeats.map(({ device, id: seatId }, index) => (
        <div className={index === 0 ? 'mt-10' : 'mt-8'} key={seatId} data-qa="selectDevicesToDisconnectItem">
          <DeviceSeatCheckbox
            id={`device-seat-checkbox-${seatId}`}
            name="selectedSeats"
            value={seatId}
            device={device ?? undefined}
            disabled={!selectedSeats.includes(seatId) && !canSelectSeats}
            checked={selectedSeats.includes(seatId)}
            onChange={onSeatCheckboxChange}
          />
        </div>
      ))}

      {formError && (
        <div className="select-seats-form__error text_sm text_color-danger">
          {formError}
        </div>
      )}

      <Button
        type="submit"
        size="lg"
        block
        className="select-seats-form__submit"
        data-qa="selectDevicesToDisconnectContinueButton"
      >
        <FormattedMessage id="cancelAdditionalSeats.selectDevicesSubmit" defaultMessage="Continue" />
      </Button>
    </form>
  );
};

SelectSeatsForm.defaultProps = {
  canSelectSeats: true,
};

export default SelectSeatsForm;
