import React from 'react';
import type { ChangeEventHandler, ReactElement } from 'react';

import './price-plan-switcher.scss';


export type SwitcherData = {
  name: ReactElement;
  price: ReactElement;
  value: string;
  discountMessage?: ReactElement;
  priceAfterTrial?: ReactElement;
}

type Props = {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  pricePlanSwitcherData: SwitcherData[];
  tierType: string;
  isPricePlanSelected: boolean;
};

const PricePlanSwitcher = ({ pricePlanSwitcherData, tierType, onChange, isPricePlanSelected }: Props) => (
  <div className="price-plan-switcher__wrapper">
    <p className="mt-3 mb-0">Billing period</p>
    {pricePlanSwitcherData.map(({ value, name, price, discountMessage, priceAfterTrial } : SwitcherData, idx) => (
      <div key={idx} className="price-plan-switcher">
        <input
          name="price-plan-switcher-radio"
          type="radio"
          value={value}
          autoComplete="off"
          checked={isPricePlanSelected && (tierType === value)}
          onChange={onChange}
          id={`price-plan-switcher-radio-${idx}`}
          className="price-plan-switcher__input"
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label className="price-plan-switcher__label mb-0 " htmlFor={`price-plan-switcher-radio-${idx}`}>
          <div className="price-plan-switcher__text-wrapper mt-3 mb-3">
            <h4 className="mt-0 mb-0">{name}</h4>
            <h4 className="mt-0 mb-0">{price}</h4>
          </div>
          <div className="price-plan-switcher__text-wrapper">
            <p className="text_md">{priceAfterTrial}</p>
            {discountMessage && (
              <p className="price-plan-switcher__discount text_md text_weight-bold">
                {discountMessage}
              </p>
            )}
          </div>
        </label>
      </div>
    ))}
  </div>
);

export default PricePlanSwitcher;
