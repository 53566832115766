import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { SubscriptionStatus } from './statuses';


export type Subscription = Readonly<{
  id: number;
  status: SubscriptionStatus;

  // nextPaymentDate can be null for free subscription
  nextPaymentDate: number | null;

  // expirationDate can be null for free subscription
  expirationDate: number | null;
  paymentPending: boolean;
  lastPaymentFailed: boolean;
  pricePlan: PricePlan;
  nextPricePlan: PricePlan | null;
  tax: number;

  gracePeriod?: GracePeriod;
  discount?: Discount;
}>;

export type SubscriptionsState = Readonly<{
  list: Array<Subscription>;
  isLoading: boolean;
}>;

const initialState: SubscriptionsState = {
  list: [],
  isLoading: false,
};

export type GracePeriod = Readonly<{
  debtAmount: number;
  debtCurrency: string;
  startDate: number;
  endDate: number;
}>;

export type Discount = Readonly<{
  rate: number;
  price: number;
  promoCode?: string;
}>;

export default initialState;
