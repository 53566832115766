
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import type { Subscription } from 'state/subscription/subscription-initial-state';
import type { Device } from 'state/devices/devices-initial-state';
import type { DeviceSeat } from 'services/devices-to-seats-mapper/devices-to-seats-mapper';

import { getPrimarySubscription } from 'state/root-reducer';
import { isStatusActive } from 'services/subscriptions/subscription';
import EmptySeatItem from './empty-seat-item';
import DeviceItem from './device-item';


import './devices-list.scss';

type Props = {
  devicesInSeat: Array<DeviceSeat>;
  onDisconnectClick: (device: Device) => void;
  onReactivateClick: (subscription: Subscription, device?: Device) => void;
  primarySubscription?: Subscription;
};

class DevicesList extends PureComponent<Props> {
  render() {
    const {
      devicesInSeat,
      onDisconnectClick,
      onReactivateClick,
      primarySubscription,
    } = this.props;

    return (
      <ul className="devices-list">
        {devicesInSeat.map((deviceSeat, id) => (
          <li key={id} className="devices-list-row">
            {deviceSeat.device
              ? <DeviceItem
                subscription={deviceSeat.subscription}
                device={deviceSeat.device}
                isMainPlanSeat={deviceSeat.isMainPlanSeat}
                isTrial={deviceSeat.isTrial}
                onDisconnectClick={onDisconnectClick}
                onReactivateClick={onReactivateClick}
                isPrimarySubscriptionActive={primarySubscription && isStatusActive(primarySubscription)}
                />
              : <EmptySeatItem
                subscription={deviceSeat.subscription}
                isTrial={deviceSeat.isTrial}
                onReactivateClick={onReactivateClick}
                isPrimarySubscriptionActive={primarySubscription && isStatusActive(primarySubscription)}
                />}
          </li>
        ))}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  primarySubscription: getPrimarySubscription(state),
});

export { DevicesList as PureDevicesList };

export default connect(
  mapStateToProps,
)(DevicesList);
