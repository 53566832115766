import React from 'react';
import type { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import logger from 'utils/logger';

import UnknownUIError from 'components/shared/unknown-ui-error/unknown-ui-error';

type Props = {
  children: ReactNode;
  fullscreen: boolean;
};

type State = {
  error: Error | null;
};

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
  };

  static defaultProps = {
    fullscreen: false,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidUpdate(prevProps: Props) {
    const { children } = this.props;
    if (prevProps.children !== children) {
      // avoid saving old error in instance from other children
      // eslint-disable-next-line
      this.setState({error: null});
    }
  }

  componentDidCatch(error: Error) {
    logger.captureException(error);
  }

  render() {
    const { error } = this.state;
    const { children, fullscreen } = this.props;
    if (error) {
      return (
        <IntlProvider locale="en">
          <UnknownUIError error={error} fullscreen={fullscreen} />
        </IntlProvider>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
