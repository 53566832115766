export const SPECIAL_OFFER_CAMPAIGNS = [
  'offer_2022_setapp',
  'offer_2022_setapp_1pass',
  'offer_2022_setapp_mc',
  'offer_2022_setapp_1pass_mc',
] as const;
export const SETAPP_MOBILE_CAMPAIGN = 'setapp_mobile' as const;

// All campaigns
export const CAMPAIGNS = [
  ...SPECIAL_OFFER_CAMPAIGNS,
] as const;

export type SpecialOfferCampaign = typeof SPECIAL_OFFER_CAMPAIGNS[number];

export type Campaign = typeof CAMPAIGNS[number];
