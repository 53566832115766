import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Row } from '@setapp/ui-kit';
import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';
import PaymentDetails from 'components/payment-details/payment-details-view/payment-details-view';
import PageTitle from 'components/shared/page-title/page-title';
import * as subscriptionStatuses from 'state/subscription/statuses';

import {
  getAdditionalSeatsSubscriptionInfo,
  getBusinessAccountDetails,
  isFreeUser as getIsFreeUser,
  creditCardExpiration as getCreditCardExpiration,
  canRemovePaymentDetails as getCanRemovePaymentDetails,
  isSuspendedSubscriptionWithOldPricePlan as getIsSuspendedSubscriptionWithOldPricePlan,
  isSubscriptionNew as getIsSubscriptionNew,
  isSubscriptionFree as getIsSubscriptionFree,
  isSubscriptionBlocked as getIsSubscriptionBlocked,
} from 'state/root-reducer';
import { Subscription } from 'state/subscription/subscription-initial-state';
import useTrialAvailabilityStatus from 'components/hooks/use-trial-availability-status/use-trial-availability-status';
import SubscriptionAction from './components/subscription-action/subscription-action';
import SubscriptionBanners from './components/subscription-banners/subscription-banners';
import AdditionalSeatsInfo from './components/subscription-info/additional-seats-info';
import SubscriptionInfo from './components/subscription-info/subscription-info';
import { SubscriptionProvider } from './context/subscription-context';
import useFetchSubscriptionInfoData from './hooks/use-fetch-subscription-info-data';
import useHandleInitialModalOpening from './hooks/use-handle-initial-modal-opening';
import useManagePaymentDetails from './hooks/use-manage-payment-details';

const SubscriptionPage: FC = () => {
  const isFreeUser = useSelector(getIsFreeUser);
  const additionalSubscription = useSelector(getAdditionalSeatsSubscriptionInfo);
  const creditCardExpiration = useSelector(getCreditCardExpiration);
  const businessAccountDetails = useSelector(getBusinessAccountDetails);
  const canRemovePaymentDetails = useSelector(getCanRemovePaymentDetails);
  const isSuspendedSubscriptionWithOldPricePlan = useSelector(getIsSuspendedSubscriptionWithOldPricePlan);
  const isSubscriptionNew = useSelector(getIsSubscriptionNew);
  const isSubscriptionFree = useSelector(getIsSubscriptionFree);
  const isSubscriptionBlocked = useSelector(getIsSubscriptionBlocked);

  const handleInitialModalOpening = useHandleInitialModalOpening();
  const { isLoading: isSubscriptionInfoLoading, data } = useFetchSubscriptionInfoData({
    onCompleted: handleInitialModalOpening,
  });
  const { isLoading: isTrialStatusLoading } = useTrialAvailabilityStatus();

  const { addPaymentDetails, removePaymentDetails } = useManagePaymentDetails();

  const isLoading = isSubscriptionInfoLoading || isTrialStatusLoading;

  const {
    primarySubscription,
    isUserFamilyOwner,
    isUserPlainFamilyMember,
    isPaymentMethodCreated,
    hasPriceFeatures,
    paymentMethod,
  } = data;

  const isSubscriptionCancelled = primarySubscription?.status === subscriptionStatuses.CANCELLED;
  const isPaymentDetailsVisible = !isFreeUser && !isUserPlainFamilyMember;

  if (isLoading) return <AppLayoutLoading />;

  return (
    <>
      <PageTitle>
        <FormattedMessage id="subscriptionInfo.title" defaultMessage="Subscription" />
      </PageTitle>
      <Row>
        <Col md={8} lg={isPaymentDetailsVisible ? 6 : 10} data-testid="subscriptionInfo">
          <SubscriptionProvider
            hasPriceFeatures={hasPriceFeatures}
            subscription={primarySubscription as Subscription}
            isPaymentMethodCreated={isPaymentMethodCreated}
            isFamilyOwner={isUserFamilyOwner}
            isFamilyMember={isUserPlainFamilyMember}
            isSubscriptionNew={isSubscriptionNew}
            isSubscriptionFree={isSubscriptionFree}
            isSubscriptionBlocked={isSubscriptionBlocked}
            isSubscriptionCancelled={isSubscriptionCancelled}
          >
            <SubscriptionInfo />
            <SubscriptionAction />
          </SubscriptionProvider>

          {additionalSubscription && <AdditionalSeatsInfo subscription={additionalSubscription} />}
        </Col>
        {isPaymentDetailsVisible && (
          <Col md={8} lg={6} data-testid="paymentInfo">
            <PaymentDetails
              creditCardExpiration={creditCardExpiration}
              paymentMethod={paymentMethod.data}
              businessAccountDetails={businessAccountDetails}
              allowRemoving={canRemovePaymentDetails}
              allowUpdating={!isSuspendedSubscriptionWithOldPricePlan}
              onAddClick={addPaymentDetails}
              onRemoveClick={removePaymentDetails}
            />
          </Col>
        )}
      </Row>
      <SubscriptionBanners />
    </>
  );
};

export default SubscriptionPage;
