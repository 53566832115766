import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import type { RouteComponentProps } from 'react-router-dom';

import { login, type LoginData } from 'state/user/user-actions';
import FadeTransition from 'components/shared/fade-transition/fade-transition';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import analytics, { events } from 'utils/analytics';
import auth from 'utils/auth';
import urls from 'config/urls';
import RecoverPasswordForm, { type RecoverPasswordFields } from './recover-password-step/recover-password-step';
import LoginStep, { type LoginFormFields } from './login-step/login-step';


type ScreenNumber = 0 | 1;
const SCREEN_LOGIN: ScreenNumber = 0;
const SCREEN_RECOVER_PASSWORD: ScreenNumber = 1;

const screensOrder: ScreenNumber[] = [SCREEN_LOGIN, SCREEN_RECOVER_PASSWORD];

type Props = {
  login: (data: LoginData) => Promise<void>;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
};

const OAuthLoginPage = ({ location, history, login }: Props) => {
  const [email, setEmail] = useState(queryString.parse(location.search).email as string || '');
  const [currentScreen, setCurrentScreen] = useState<ScreenNumber>(SCREEN_LOGIN);

  const getNextStepURL = useCallback(() => {
    const { redirect_url: redirectUrl } = queryString.parse(location.search);

    return redirectUrl as string || urls.root;
  }, [location.search]);

  const handleBackClick = useCallback(() => {
    setCurrentScreen((currentScreen) => {
      return screensOrder[Math.max(screensOrder.indexOf(currentScreen) - 1, 0)] ?? currentScreen;
    });
  }, []);

  const handleForgotPassword = useCallback((email: string) => {
    setEmail(email);
    setCurrentScreen(SCREEN_RECOVER_PASSWORD);
  }, []);

  const handleLoginFormSubmit = useCallback(async (fields: LoginFormFields) => {
    try {
      await login(fields);

      analytics.trackEvent(events.LOGIN_SUCCESS);
      history.push(getNextStepURL());
    } catch (error) {
      analytics.trackEvent(events.LOGIN_ERROR);

      throw error;
    }
  }, [history, getNextStepURL, login]);

  const handleRecoverPasswordFormSubmit = useCallback(async (fields: RecoverPasswordFields) => {
    await auth.requestPasswordReset(fields);

    setEmail(fields.email);
    analytics.trackEvent(events.NEW_PASSWORD_REQUEST);
  }, []);

  return (
    <FullscreenLayout>
      <FullscreenLayout.PrimaryContent withLogo wideContainer>
        <FadeTransition current={currentScreen}>
          <LoginStep
            email={email}
            socialInnerRedirectUrl={getNextStepURL()}
            onSubmit={handleLoginFormSubmit}
            onForgotPassword={handleForgotPassword}
          />
          <RecoverPasswordForm
            email={email}
            onBack={handleBackClick}
            onSubmit={handleRecoverPasswordFormSubmit}
          />
        </FadeTransition>
      </FullscreenLayout.PrimaryContent>
    </FullscreenLayout>
  );
};

export { OAuthLoginPage as PureOAuthLoginPage };

export default connect(null, { login })(OAuthLoginPage);
