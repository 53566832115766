import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type { SubscriptionStatus } from 'state/subscription/statuses';
import PlanRow from '../../plan-row/plan-row';
import StatusRow from '../../status-row/status-row';

type Props = {
  status: SubscriptionStatus;
  expirationDate?: Maybe<number>;
};

const OldFamilyMember: FC<Props> = ({ status, expirationDate }) => (
  <div data-testid="oldFamilyMember">
    <PlanRow value={<FormattedMessage id="familyMemberSubscriptionInfo.PlanValue" defaultMessage="Family" />} />

    <StatusRow
      status={status}
      expirationDate={expirationDate}
    />
  </div>
);

export default OldFamilyMember;
