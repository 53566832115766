// Old family modals are deprecated and are going to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';
import { removeFamilyMember } from 'state/family-plan/family-plan-actions';
import { getFamilyPlan } from 'state/root-reducer';
import analytics, { events } from 'utils/analytics/index';

import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';
import defaultMemberAvatar from './images/default-member-avatar.svg';

import './old-remove-family-member-modal.scss';


const mapStateToProps = (state) => ({
  isLoading: getFamilyPlan(state).isLoading,
});

const mapActionsToProps = {
  removeFamilyMember,
  showDangerNotification,
  showSuccessNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = {
  member: FamilyMember;
  onHide: () => void;
  onExited: () => void;
  show: boolean;
} & ConnectedProps<typeof connector>

class OldRemoveFamilyMemberModal extends Component<Props> {
  render() {
    const {
      member, isLoading, onHide, onExited, show,
    } = this.props;
    const { name, email, avatarUrl } = member;
    const title = name || email;

    return (
      <Modal
        onHide={onHide}
        onExited={onExited}
        show={show}
        title={
          <FormattedMessage
            id="removeFamilyMemberModal.title"
            defaultMessage="Removal of a Family member"
          />
        }
      >
        <div>
          <div className="text-center">
            <img
              src={(avatarUrl && avatarUrl.length) ? avatarUrl : defaultMemberAvatar}
              className="remove-family-member-modal__avatar"
              alt=""
            />
            <div className="remove-family-member-modal__title h5">{title}</div>
            {name && <div className="remove-family-member-modal__subtitle">{email}</div>}
            <div className="remove-family-member-modal__description">
              <FormattedMessage
                id="removeFamilyMemberModal.description"
                defaultMessage="You're about to take {name} off your Family plan. They will be switched to monthly subscription."
                values={{
                  name: name || <FormattedMessage
                    id="removeFamilyMemberModal.namePlaceholder"
                    defaultMessage="this member"
                                />,
                }}
              />
            </div>
          </div>

          <Button
            variant="danger-outline"
            block
            onClick={this.onConfirm}
            disabled={isLoading}
            data-qa="removeMemberSubmit"
          >
            <FormattedMessage id="removeFamilyMemberModal.submitButton" defaultMessage="Remove" />
          </Button>

          <Button block onClick={onHide} className="mt-3">
            <FormattedMessage id="removeFamilyMemberModal.cancelButton" defaultMessage="Cancel" />
          </Button>
        </div>
      </Modal>
    );
  }

  onConfirm = () => {
    const { member, removeFamilyMember, onHide } = this.props;
    const { showSuccessNotification, showDangerNotification } = this.props;
    const { name, email, id } = member;

    return removeFamilyMember(id)
      .then(() => {
        onHide();

        showSuccessNotification(
          <FormattedMessage
            id="activatedFamilyPlan.removeFamilyMember.successNotification"
            defaultMessage="{title} removed successfully."
            values={{ title: name || email }}
          />
        );
        analytics.trackEvent(events.FAMILY_PLAN_REMOVE_MEMBER_SUCCESS);
      })
      .catch(() => {
        onHide();

        showDangerNotification(<DefaultError />);
      });
  }
}

export default connector(OldRemoveFamilyMemberModal);
