import HTTPStatus from 'http-status';
import auth from '../auth';

const requestNewAuthToken = (middlewareOptions = {}) => {
  let newTokenRequestPromise = null;

  return (next) => async (url, options) => {
    const response = await next(url, options);

    if (response.status !== HTTPStatus.UNAUTHORIZED) {
      return response;
    }

    // Use the previous token request if it's in progress or make a new one if not
    newTokenRequestPromise = newTokenRequestPromise ?? auth.requestNewAccessToken();

    try {
      await newTokenRequestPromise;
    } catch (error) {
      if (middlewareOptions.onTokenError) {
        middlewareOptions.onTokenError(error);
      }

      // Return the original "Unauthenticated" response
      return response;
    } finally {
      // Clear cached promise for the token request
      // TODO: fix eslint warning
      // eslint-disable-next-line require-atomic-updates
      newTokenRequestPromise = null;
    }

    return next(url, options);
  };
};

export default requestNewAuthToken;
