// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import type { Device } from 'state/devices/devices-initial-state';

import InfoRadioButton from 'components/shared/info-radio-button/info-radio-button';
import PickActiveDeviceItem from './pick-active-device-item/pick-active-device-item';

import './pick-active-device-form.scss';


type Props = {
  devices: Array<Device>;
  isLoading: boolean;
  onBackToPlansClick: () => void;
  onPickActiveDevice: (selectedDeviceId: number) => void;
};

type State = {
  selectedDeviceId: number | null;
}

class PickActiveDeviceForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selectedDeviceId: null,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangeSelected = this.onChangeSelected.bind(this);
  }

  render() {
    const { devices, onBackToPlansClick, isLoading } = this.props;
    const { selectedDeviceId } = this.state;

    return (
      <div>
        <div className="pick-active-device-form__description">
          <FormattedMessage
            id="pickActiveDeviceForm.formDescription"
            defaultMessage="Educational plan can only be used on one device. To finish the switch, choose a device that will be disconnected from Setapp."
          />
        </div>
        <form
          className="pick-active-device-form"
          onSubmit={this.onFormSubmit}
          noValidate
        >
          <fieldset className="form-group">
            <legend className="sr-only">
              <FormattedMessage
                id="pickActiveDeviceForm.PickDeviceLegend"
                defaultMessage="Pick your device for the plan"
              />
            </legend>
            {devices
              .map((device) => {
                const isDevicePicked = device.id === selectedDeviceId;

                return (
                  <div className="pick-active-device-form" key={device.id}>
                    <InfoRadioButton
                      name="device"
                      value={device.id}
                      onChange={this.onChangeSelected}
                      checked={isDevicePicked}
                    >
                      <PickActiveDeviceItem
                        device={device}
                        active={isDevicePicked}
                      />
                    </InfoRadioButton>
                  </div>
                );
              })}
          </fieldset>

          <Button
            block
            type="submit"
            disabled={!selectedDeviceId || isLoading}
          >
            <FormattedMessage
              id="pickActiveDeviceForm.PickSubmitButton"
              defaultMessage="Pick and proceed"
            />
          </Button>
        </form>
        <Button
          variant="secondary-outline"
          block
          disabled={isLoading}
          onClick={onBackToPlansClick}
        >
          <FormattedMessage
            id="pickActiveDeviceForm.BackToPlansButton"
            defaultMessage="Back to plans"
          />
        </Button>
      </div>
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { onPickActiveDevice } = this.props;
    const { selectedDeviceId } = this.state;

    if (!selectedDeviceId) return;

    onPickActiveDevice(selectedDeviceId);
  }

  onChangeSelected(e) {
    this.setState({ selectedDeviceId: Number(e.currentTarget.value) });
  }
}

export default PickActiveDeviceForm;
