import React, { PureComponent } from 'react';
import { Button } from '@setapp/ui-kit';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import type { RouteComponentProps } from 'react-router-dom';

import { getUser, isAppLandingFlow } from 'state/root-reducer';
import urls from 'config/urls';
import welcomePageIllustration from './images/welcome-page-new-illustration.png';
import welcomePageIllustration2x from './images/welcome-page-new-illustration@2x.png';
import welcomePageIllustration3x from './images/welcome-page-new-illustration@3x.png';

import './social-auth-welcome-page.scss';

type Props = {
  userName: string;
  history: RouteComponentProps['history'];
  isAppLandingFlow: boolean;
  isPaymentInfoRequired: boolean;
}

class SocialAuthWelcomePage extends PureComponent<Props> {
  render() {
    const {
      history,
      userName,
      isAppLandingFlow,
      isPaymentInfoRequired,
    } = this.props;
    const getFirstName = (name: string) => name.split(' ')[0];

    const redirectToNextStep = () => {
      if (isPaymentInfoRequired) {
        history.replace(urls.signupAddPaymentMethod);

        return;
      }

      history.replace(isAppLandingFlow ? urls.successfulRegistrationSingleApp : urls.successfulRegistration);
    };

    return (
      <div className="social-auth-welcome-page text-center" data-qa="socialAuthWelcomePage">
        <h3 className="mt-0" data-qa="title">
          {userName && (
            <>
              <FormattedMessage
                id="socialAuthWelcomePage.greeting"
                defaultMessage="{name}, welcome to Setapp!"
                values={{ name: getFirstName(userName) }}
              />
              {' '}
            </>
          )}
        </h3>
        <p className="text_lg mb-7" data-qa="description">
          <FormattedMessage
            id="socialAuthWelcomePage.description"
            defaultMessage="Explore dozens of apps. Power up your workflow. Just one subscription."
          />
        </p>
        <img
          src={welcomePageIllustration}
          srcSet={`${welcomePageIllustration2x} 2x, ${welcomePageIllustration3x} 3x`}
          alt="Illustration with Setapp interface and tools icons"
          className="img-responsive mb-10"
        />
        <Button
          block
          size="lg"
          onClick={redirectToNextStep}
          className="social-auth-welcome-page__button mb-10"
          data-qa="button"
        >
          <FormattedMessage
            id="socialAuthWelcomePage.btnContinue"
            defaultMessage="Continue"
          />
        </Button>
      </div>
    );
  }
}

export { SocialAuthWelcomePage as PureSocialAuthWelcomePage };

const mapStateToProps = (state) => ({
  userName: getUser(state).name,
  isPaymentInfoRequired: getUser(state).paymentInfoRequired,
  isAppLandingFlow: isAppLandingFlow(state),
});

export default connect(mapStateToProps)(SocialAuthWelcomePage);
