// @flow

import React from 'react';
import formatDate from 'date-fns/format';

import PaymentMethodPresentation from '../payment-method-presentation/payment-method-presentation';

const cardDetails = {
  americanexpress: {
    name: 'AMEX',
  },
  dinnersclub: {
    name: 'Diners Club',
  },
  discover: {
    name: 'Discover',
  },
  jcb: {
    name: 'JCB',
  },
  maestro: {
    name: 'Maestro',
  },
  mastercard: {
    name: 'MasterCard',
  },
  visa: {
    name: 'Visa',
  },
  default: {
    name: 'Maestro',
  },
};

type Props = {
  cardType: 'americanexpress'
    | 'dinnersclub'
    | 'discover'
    | 'jcb'
    | 'maestro'
    | 'mastercard'
    | 'visa',
  cardLast4: string,
  cardExpM: number,
  cardExpY: number,
};

const CreditCardInfo = (props: Props) => {
  const {
    cardType, cardLast4, cardExpY, cardExpM,
  } = props;
  const card = cardDetails[cardType] || {};
  const expires = formatDate(new Date(cardExpY, cardExpM - 1), 'MM/yyyy');

  return (
    <PaymentMethodPresentation
      details={[
        card.name ? `${card.name} **** **** **** ${cardLast4}` : `**** **** **** ${cardLast4}`,
        expires,
      ]}
    />
  );
};

export default CreditCardInfo;
