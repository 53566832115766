import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { getPriceWithGracePeriod } from 'services/price-plans/utils';

import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { GracePeriod } from 'state/subscription/subscription-initial-state';

import NextPaymentDateEstimation from 'components/shared/next-payment-date-estimation/next-payment-date-estimation';
import PaymentButton from 'components/shared/payment-button/payment-button';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentForm from 'components/shared/payment-form/payment-form';
import TaxInfo from 'components/shared/tax-info/tax-info';

import NextPaymentInfo from 'components/user-flow/shared/next-payment-info/next-payment-info';

type Props = {
  isPaymentMethodCreated: boolean;
  isProcessing: boolean;
  switchedToAnnual?: boolean;
  showPricePlanPeriodSwitcher?: boolean;
  showUpdatePaymentDetailsButton?: boolean;
  pricePlan: PricePlan;
  gracePeriod?: GracePeriod;
  onChangePlanPeriod?: () => void;
  onCheckoutPricePlan: () => Promise<void>;
  onPaymentDetailsSaved: () => Promise<void>;
  onUpdatePaymentDetailsClick?: () => void;
};

const CheckoutPricePlanStep = (props: Props) => {
  const {
    isPaymentMethodCreated,
    isProcessing,
    switchedToAnnual = false,
    showPricePlanPeriodSwitcher = true,
    showUpdatePaymentDetailsButton = false,
    pricePlan,
    gracePeriod,
    onChangePlanPeriod,
    onCheckoutPricePlan,
    onPaymentDetailsSaved,
    onUpdatePaymentDetailsClick,
  } = props;

  const { currency, paidMonth, price } = pricePlan;
  const totalAmount = getPriceWithGracePeriod(pricePlan, gracePeriod);

  return (
    <>
      <h3 className="mb-6">
        <FormattedMessage
          id="checkoutPricePlanStep.title"
          defaultMessage="Let’s start your subscription"
        />
      </h3>

      <NextPaymentInfo
        isProcessing={isProcessing}
        switchedToAnnual={switchedToAnnual}
        showPricePlanPeriodSwitcher={showPricePlanPeriodSwitcher}
        pricePlan={pricePlan}
        gracePeriod={gracePeriod}
        onChangePlanPeriod={onChangePlanPeriod}
      />

      <div className="mt-6 mb-6">
        {isPaymentMethodCreated ? (
          <>
            <PaymentButton
              forceProcessing={isProcessing}
              onClick={onCheckoutPricePlan}
            />
            {showUpdatePaymentDetailsButton && (
              <Button
                className="mt-3"
                block
                variant="secondary-outline"
                size="lg"
                disabled={isProcessing}
                onClick={onUpdatePaymentDetailsClick}
              >
                <PaymentDetailsActionText action="update" />
              </Button>
            )}
          </>
        ) : (
          <PaymentForm
            braintreeFormOptions={{
              submitBtnTitle: (
                <FormattedMessage
                  id="checkoutPricePlanStep.payButtonTitle"
                  defaultMessage="Pay now"
                />
              ),
              loadingText: (
                <FormattedMessage
                  id="checkoutPricePlanStep.payButtonLoadingText"
                  defaultMessage="Payment in progress…"
                />
              ),
            }}
            paymentMethodOptions={{
              amount: totalAmount,
              currencyCode: currency,
            }}
            onSuccessSubmit={onPaymentDetailsSaved}
          />
        )}
      </div>

      <NextPaymentDateEstimation
        price={price}
        currency={currency}
        paidMonth={paidMonth}
      />
      <div className="mt-2">
        <TaxInfo />
      </div>
    </>
  );
};

export default CheckoutPricePlanStep;
