import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import FormControl from 'react-bootstrap/lib/FormControl';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';
import { Button } from '@setapp/ui-kit';
import { useDispatch } from 'react-redux';

import analytics, { events } from 'utils/analytics';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import type { ReferralsState } from 'state/referrals/referrals-initial-state';

import './referral-status.scss';

const messages = defineMessages({
  failedCopyNotification: {
    id: 'referral.copyNotifications.error',
    defaultMessage: 'Oh, something went wrong. Please copy the link manually',
  },
  socialSharingText: {
    id: 'referral.socialSharingText',
    defaultMessage: 'Have a Mac? \nThen I totally recommend this cool service called Setapp. When there’s a task - convert image, edit PDF, fix WiFi, you name it - just search for a solution in Setapp. \n\nJoin with my link and get a free month if you subscribe.',
  },
});

type Props = {
  referrals: ReferralsState;
} & InjectedIntlProps;

const ReferralStatus = ({ referrals, intl }: Props) => {
  const dispatch = useDispatch();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const sendShareButtonClickEvent = (buttonType: string) => {
    analytics.trackEvent(events.REFERRAL_SHARE_CLICK, { eventLabel: buttonType });
  };

  const onCopyFromField = () => {
    sendShareButtonClickEvent('link_copied');
  };

  const onCopyLinkClick = () => {
    const isLinkCopied = copy(referrals.url);

    sendShareButtonClickEvent('link');

    if (isLinkCopied) {
      setIsLinkCopied(true);
    } else {
      dispatch(showDangerNotification(intl.formatMessage(messages.failedCopyNotification)));
    }
  };

  const handleWhatsappClick = () => {
    sendShareButtonClickEvent('whatsapp');
  };

  const getShareWindowOptions = () => {
    const width = 640;
    const height = 300;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    return `height=${height},width=${width},top=${top},left=${left}`;
  };

  const openTwitterSharingWindow = () => {
    sendShareButtonClickEvent('twitter');

    const tweetText = intl.formatMessage(messages.socialSharingText).replace('Setapp', '@Setapp'),
      { url } = referrals;

    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${url}`,
      'twitterSharing',
      getShareWindowOptions(),
    );
  };

  const openFacebookSharingWindow = () => {
    const facebookText = intl.formatMessage(messages.socialSharingText);
    const quote = encodeURIComponent(facebookText);

    sendShareButtonClickEvent('facebook');

    window.open(
      `https://www.facebook.com/sharer.php?u=${encodeURIComponent(referrals.url)}&quote=${quote}&hashtag=%23setapp`,
      'facebookSharing',
      getShareWindowOptions(),
    );
  };

  const getSocialBtns = () => {
    const messageText = intl.formatMessage(messages.socialSharingText);
    const whatsappText = encodeURIComponent(messageText);
    const { url } = referrals;
    const whatsappLink = `https://api.whatsapp.com/send?text=${whatsappText}%0A${encodeURI(url)}`;

    return (
      <div className="referral-social">
        <a
          className="btn btn-referral-whatsapp"
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleWhatsappClick}
        >
          <span className="sr-only">
            <FormattedMessage
              id="referral.socialShareWhatsapp"
              defaultMessage="Send in Whatsapp"
            />
          </span>
        </a>
        <button
          type="button"
          className="btn btn-referral-facebook ml-3"
          onClick={openFacebookSharingWindow}
        >
          <span className="sr-only">
            <FormattedMessage
              id="referral.socialShareFacebook"
              defaultMessage="Share"
            />
          </span>
        </button>
        <button
          type="button"
          className="btn btn-referral-twitter ml-3"
          onClick={openTwitterSharingWindow}
        >
          <span className="sr-only">
            <FormattedMessage
              id="referral.socialShareTwitter"
              defaultMessage="Tweet"
            />
          </span>
        </button>
      </div>
    );
  };

  return (
    <div className="referral-link">
      <div className="referral-link__container">
        <p className="mb-4">
          <FormattedMessage
            id="referral.linkInputTitle"
            defaultMessage="Invite a friend to Setapp and both get a free month of Setapp once your friend starts their paid subscription. And then invite another one!"
          />
        </p>
        <div className="referral-link__input-container">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */}
          <label htmlFor="referral-copy-link" className="sr-only">
            <FormattedMessage id="referral.referralLinkLabel" defaultMessage="Your referral link" />
          </label>
          <FormControl
            id="referral-copy-link"
            type="text"
            className="referral-link__input input-lg"
            readOnly
            value={referrals.url}
            onCopy={onCopyFromField}
          />
          <Button
            size="lg"
            onClick={onCopyLinkClick}
            className="referral-link__button"
            variant="primary"
          >
            {isLinkCopied ? (
              <FormattedMessage
                id="referral.copiedCaption"
                defaultMessage="Link copied!"
              />
            ) : (
              <FormattedMessage
                id="referral.copyCaption"
                defaultMessage="Copy link"
              />
            )}
          </Button>
        </div>
      </div>

      <div className="referral-link__actions">
        <p className="mb-4">
          <FormattedMessage
            id="referral.inbiteOnSocials"
            defaultMessage="Or invite on socials:"
          />
        </p>
        {getSocialBtns()}
      </div>
    </div>
  );
};

export default injectIntl(ReferralStatus);
