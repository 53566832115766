import isEmail from 'validator/lib/isEmail';
import isAlpha from 'validator/lib/isAlpha';
import type { Messages } from 'react-intl';
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

const required = (value) => !!value;

const passwordMatchMinLength = (value) => value.length >= 8;

const emailFormat = (value) => isEmail(`${value}`) || !value;

const isPasswordsMatch = ([oldPass, newPass]) => oldPass === newPass;

const passwordContainsUpper = (value) => value.split('').some((char) => isAlpha(char) && char === char.toUpperCase());

const passwordContainsLower = (value) => value.split('').some((char) => isAlpha(char) && char === char.toLowerCase());

const passwordContainsBothLowerAndUpper = (value) => passwordContainsUpper(value) && passwordContainsLower(value);

const passwordFormat = (value) => (passwordMatchMinLength(value) && passwordContainsBothLowerAndUpper(value)) || !value;

export const validators = {
  required,
  passwordMatchMinLength,
  passwordFormat,
  emailFormat,
  isPasswordsMatch,
  passwordContainsUpper,
  passwordContainsLower,
  passwordContainsBothLowerAndUpper,
};

type ValidationErrorMessage = ReactElement<FormattedMessage> | FormattedMessage.MessageDescriptor | string;

export type ValidationRules = {
  [fieldName in string]: {
    [rule in keyof typeof validators]?: ValidationErrorMessage | {
      fields: string[];
      message: ValidationErrorMessage;
    }
  }
};

export default function validate(fields, rules: ValidationRules): Messages {
  return Object.keys(fields)
    .reduce((errors, fieldName) => ({
      ...errors,
      [fieldName]: Object.keys(rules[fieldName] || [])
        .reduce((error, rule) => {
          const validatorFunc = validators[rule];
          const fieldValidators = rules[fieldName];
          const fieldValue = fieldValidators && fieldValidators[rule].fields
            ? fieldValidators[rule].fields.map((field) => fields[field])
            : fields[fieldName];
          const errorMessage = fieldValidators && (fieldValidators[rule].message || fieldValidators[rule]);

          return validatorFunc(fieldValue) ? error : errorMessage;
        }, ''),
    }), {});
}
