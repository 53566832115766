import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { connect } from 'react-redux';
import { Notification } from '@setapp/ui-kit';

import { removeNotification } from 'state/notifier/notifier-reducer';
import type { Notification as NotificationType } from 'state/notifier/notifier-initial-state';
import { getNotification } from 'state/root-reducer';

import './notifications-container.scss';

type Props = {
  notifications: Array<NotificationType>;
  removeNotification: (id: string) => void;
};

const NotificationContainer = ({ notifications, removeNotification }: Props) => {
  const fadeDuration = 200;

  return (
    <TransitionGroup className="notifications__list">
      {
        notifications.map((notification) => (
          <CSSTransition
            classNames="notifier"
            timeout={fadeDuration}
            key={notification.id}
          >
            <div className="notifications__item">
              <Notification
                onClose={() => removeNotification(notification.id)}
                type={notification.type}
                withIcon={notification.withIcon}
                size="sm"
              >
                {notification.message}
              </Notification>
            </div>
          </CSSTransition>
        ))
      }
    </TransitionGroup>
  );
};

const mapStateToProps = (state) => ({
  notifications: getNotification(state),
});

export default connect(mapStateToProps, { removeNotification })(NotificationContainer);
