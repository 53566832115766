import React, { ComponentProps } from 'react';
import type { ReactNode } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import './fade-transition.scss';

type Props = {
  children: ReactNode;
  current: number;
  className?: string;
} & ComponentProps<'div'>;

const TRANSITION_DURATION = 200;

const FadeTransition = (props: Props) => {
  const { current = 0, children, className, ...divProps } = props;
  const childrenArray = React.Children.toArray(children);

  if (current < 0 || current > childrenArray.length - 1) {
    throw new Error('Invalid current child index provided');
  }

  const currentChild = childrenArray[current];

  const transitionClassnames = `fade-transition ${className ?? ''}`;

  return (
    <SwitchTransition>
      <CSSTransition
        key={currentChild!['key']} // Children.toArray adds key so it always exists
        timeout={TRANSITION_DURATION}
        classNames={{
          enter: 'fade-transition_enter',
          enterActive: 'fade-transition_enter-active',
          exit: 'fade-transition_exit',
          exitActive: 'fade-transition_exit-active',
        }}
      >
        <div className={transitionClassnames} {...divProps}>{currentChild}</div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default FadeTransition;
