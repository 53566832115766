import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import isAfter from 'date-fns/isAfter';

import { getFamilyOwner, getPrimaryPricePlanFeatures } from 'state/root-reducer';

import './family-member.scss';
import warningIcon from './images/warning-icon.svg';

export type Props = {
  expirationDate: number | null;
};

const FamilyMember: FC<Props> = ({ expirationDate }) => {
  const familyOwner = useSelector(getFamilyOwner);
  const subscriptionFeatures = useSelector(getPrimaryPricePlanFeatures);

  const isSubscriptionExpired = expirationDate !== null && isAfter(Date.now(), expirationDate * 1000);

  /* istanbul ignore next */
  const { macSeatsCount = 0, iosSeatsCount = 0 } = subscriptionFeatures ?? {};

  const ownerEmail = <strong>{familyOwner?.email}</strong>;

  const renderDescription = () => {
    if (isSubscriptionExpired) {
      return (
        <>
          <div className="family-member__inactive-status">
            <img
              className="mr-2"
              src={warningIcon}
              width={16}
              height={16}
              alt=""
            />
            <span className="text-danger">
              <FormattedMessage id="familyPlainMemberInfo.inactiveWarning" defaultMessage="Membership inactive." />
            </span>
          </div>
          <p className="ml-6">
            <FormattedMessage
              id="familyPlainMemberInfo.description.inactiveInfo"
              defaultMessage="Family plan you are a part of is inactive. Wait for {ownerEmail} to reactivate the plan or purchase your own Setapp plan."
              values={{ ownerEmail }}
            />
          </p>
        </>
      );
    }

    return (
      <p>
        <FormattedMessage
          id="familyPlainMemberInfo.description.familyInfo"
          defaultMessage="Your Setapp membership is covered under {ownerEmail} Family subscription."
          values={{ ownerEmail }}
        />
        <br />
        {Boolean(macSeatsCount || iosSeatsCount) && (
          <FormattedMessage
            id="familyPlainMemberInfo.description.devicesInfo"
            defaultMessage="You can use Setapp on {macSeatsCount, plural, one {# Mac} other {# Macs}}{iosSeatsCount, plural, =0 {} one {\u00A0+ 1 iOS device} other {\u00A0+ # iOS devices}}."
            values={{ macSeatsCount, iosSeatsCount }}
          />
        )}
      </p>
    );
  };

  return (
    <div data-testid="familyMember">
      <h5 className="mt-2">
        <FormattedMessage id="familyPlainMemberInfo.title" defaultMessage="Family plan participant" />
      </h5>
      <div className="family-member__description mt-4 mb-8">
        {renderDescription()}
      </div>
    </div>
  );
};

export default FamilyMember;
