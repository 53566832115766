import React, { SyntheticEvent } from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';

import { type OAuthScope } from 'utils/oauth';
import PermissionList from '../permission-list/permission-list';


import './authorization-form.scss';

type Props = {
  appName: string;
  iconUrl: string;
  authScope: Array<OAuthScope>;
  onSubmit: (event: SyntheticEvent<HTMLFormElement>) => void;
  onCancel: (event: SyntheticEvent) => void;
};

const AuthorizationForm = (props: Props) => {
  const {
    appName,
    iconUrl,
    authScope,
    onSubmit,
    onCancel,
  } = props;

  const handleFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(event);
  };

  return (
    <form noValidate onSubmit={handleFormSubmit}>
      <h3>
        <FormattedMessage
          id="oauth.authorizationForm.title"
          defaultMessage="Authorize {appName}"
          values={{ appName }}
        />
      </h3>

      <PermissionList
        scope={authScope}
        appName={appName}
        iconUrl={iconUrl}
      />

      <Button
        block
        type="submit"
        className="authorization-form__submit"
        data-qa="authorizeBtn"
      >
        <FormattedMessage id="oauth.authorizationForm.btnSubmit" defaultMessage="Authorize" />
      </Button>
      <Button
        block
        variant="primary-outline"
        onClick={onCancel}
        data-qa="cancelBtn"
      >
        <FormattedMessage id="oauth.authorizationForm.btnCancel" defaultMessage="Cancel" />
      </Button>
    </form>
  );
};

export default AuthorizationForm;
