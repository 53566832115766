import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import {
  isSubscriptionWithDiscount as isSubscriptionWithDiscountSelector,
} from 'state/root-reducer';
import { showModal } from 'state/modal/modal-reducer';

import './activate-subscription-now.scss';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';

const ActivateSubscriptionNow: FC = () => {
  const history = useHistory();

  const showModalAction = useDispatchAction(showModal);

  const isSubscriptionWithDiscount = useSelector(isSubscriptionWithDiscountSelector);

  const redirectToActivatePage = () => history.push(urls.activateSubscription);

  const handleActivateSubscription = () => {
    if (isSubscriptionWithDiscount) {
      showModalAction('CONFIRM_LOSE_DISCOUNT', {
        onConfirm: redirectToActivatePage,
      });
    } else {
      redirectToActivatePage();
    }
  };

  return (
    <div className="mt-10" data-testid="activateSubscriptionNow">
      <h5>
        <FormattedMessage
          id="subscriptionInfo.activateNow.title"
          defaultMessage="Subscribe and pay now"
        />
      </h5>
      <p className="mb-8">
        <FormattedMessage
          id="subscriptionInfo.activateNow.description"
          defaultMessage="Loving Setapp? You can abandon the trial and begin your subscription now. Click below to jump on a plan!"
        />
      </p>
      <Button
        className="activate-subscription-now__button"
        variant="primary"
        size="lg"
        data-qa="activateSubscriptionBtn"
        onClick={handleActivateSubscription}
      >
        <FormattedMessage
          id="subscriptionInfo.activateNow.button"
          defaultMessage="Pay now"
        />
      </Button>
    </div>
  );
};

export default ActivateSubscriptionNow;
