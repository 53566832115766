import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { showDangerNotification } from 'state/notifier/notifier-reducer';

import DefaultError from 'components/shared/default-error/default-error';

import RetryChargeButton from '../retry-charge-button/retry-charge-button';

type Props = {
  onButtonClick: () => Promise<void>;
};

const RetryChargeContainer = ({ onButtonClick }: Props) => {
  const dispatch = useDispatch();
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const disableButtons = () => setIsButtonsDisabled(true);

  const enableButtons = () => setIsButtonsDisabled(false);

  const handleRetryChargeError = () => {
    enableButtons();

    dispatch(showDangerNotification(<DefaultError />));
  };

  const makeCharge = async () => {
    try {
      await onButtonClick();
    } catch {
      handleRetryChargeError();
    }
  };

  const onRetryChargeClick = () => {
    disableButtons();
    makeCharge();
  };

  return (
    <div className="retry-charge-container">
      <RetryChargeButton
        disabled={isButtonsDisabled}
        onClick={onRetryChargeClick}
      />
    </div>
  );
};

export default RetryChargeContainer;
