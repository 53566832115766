import React from 'react';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';

import FamilyMembersItem from '../family-members-item/family-members-item';

import './family-members-list.scss';

type Props = {
  isFamilyProcessing: boolean;
  maxMembersCount: number;
  members: FamilyMember[];
  showRemoveFamilyMemberModal: (member: FamilyMember) => void;
  showRevokeFamilyInviteModal: (member: FamilyMember) => void;
  resendFamilyInvite: (member: FamilyMember) => Promise<void>;
};

const FamilyMembersList = (props: Props) => {
  const {
    members,
    maxMembersCount,
    resendFamilyInvite,
    isFamilyProcessing,
    showRemoveFamilyMemberModal,
    showRevokeFamilyInviteModal,
  } = props;

  return (
    <>
      <p className="h5 mb-5">
        <FormattedMessage
          id="familyMembers.title"
          defaultMessage="Your Family participants: {membersCount} of {membersMaxCount}"
          values={{
            membersCount: members.length,
            membersMaxCount: maxMembersCount,
          }}
        />
      </p>
      <Row className="mb-1 text_color-secondary text_sm">
        <Col xs={9} lg={7}>
          <FormattedMessage
            id="familyMembers.list.email"
            defaultMessage="Email"
          />
        </Col>
        <Col className="family-members__status" xs={3} lg={2}>
          <FormattedMessage
            id="familyMembers.list.status"
            defaultMessage="Status"
          />
        </Col>
        <Col className="family-members__actions" lg={3}>
          <FormattedMessage
            id="familyMembers.list.actions"
            defaultMessage="Actions"
          />
        </Col>
      </Row>
      <ul className="family-members">
        {members.map((member) => (
          <li key={member.id}>
            <FamilyMembersItem
              member={member}
              resendFamilyInvite={resendFamilyInvite}
              isFamilyProcessing={isFamilyProcessing}
              showRevokeFamilyInviteModal={showRevokeFamilyInviteModal}
              showRemoveFamilyMemberModal={showRemoveFamilyMemberModal}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default FamilyMembersList;
