export enum TrialAvailabilityStatus {
  trialAllowed = 'trial_allowed',
  trialNotAllowed = 'trial_not_allowed',
  trialAllowedPaymentDetailsNeeded = 'trial_allowed_payment_details_needed',
  unknown = 'unknown',
}

export type TrialStatus = {
  status: TrialAvailabilityStatus;
  description: string;
}

export type TrialStatusState = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  data: Maybe<TrialStatus>;
  error: Maybe<Error>;
}
