// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import type { ReactElement } from 'react';
import classnames from 'classnames';
import { FormattedPrice } from 'components/shared/formatter/formatter';

import './plan-item.scss';

type Props = {
  active?: boolean;
  title: ReactElement;
  subtitle: ReactElement;
  description: ReactElement;
  price: number;
  priceDescription: ReactElement;
  currency: string;
  paidMonth: number;
  isTestAnnualPlan: boolean;
};

const PricePlanItem = ({
  active, title, subtitle, description, price, currency, paidMonth, priceDescription, isTestAnnualPlan,
}: Props) => {
  const radioContainerClasses = classnames(
    'price-plan-item',
    { 'price-plan-item_active': active }
  );
  const pricePerMonth = (price / paidMonth);

  return (
    <span className={radioContainerClasses}>
      <span className="price-plan-item__text-container">
        <span className="price-plan-item__title" data-qa="pricePlanItemTitle">{title}</span>
        <span className="price-plan-item__subtitle">{subtitle}</span>
        <span className="price-plan-item__description">{description}</span>
      </span>
      <span className="price-plan-item__price-container">
        <span className="price-plan-item__price">
          <FormattedPrice currency={currency} price={isTestAnnualPlan ? price : pricePerMonth} />
        </span>
        <span className="price-plan-item__price-description">{priceDescription}</span>
      </span>
    </span>
  );
};

export default PricePlanItem;
