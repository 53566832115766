import React, { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import httpStatuses from 'http-status';
import queryString from 'query-string';

import urls from 'config/urls';
import externalUrls from 'config/external-urls';

import auth from 'utils/auth';

import { getAppsCount } from 'state/root-reducer';

import validateUserEmail from 'services/user/user-api';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import signUpHoc from 'components/pages/signup/signup-page-hoc';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import macAndIphoneImage3x from 'components/shared/shared-images/mac-tablet-iphone@3x.png';
import macAndIphoneImage2x from 'components/shared/shared-images/mac-tablet-iphone@2x.png';
import macAndIphoneImage from 'components/shared/shared-images/mac-tablet-iphone@1x.png';

import searchIcon from './images/search.svg';
import installIcon from './images/install.svg';
import updateIcon from './images/update.svg';

import './family-signup.scss';

const mapStateToProps = () => ({
  appsCount: getAppsCount(),
});

type SignupFormFields = {
  email: string;
  inviteId: string;
};

const connector = connect(mapStateToProps);

type Props = RouteComponentProps & ConnectedProps<typeof connector> & {
  signUpForm: ReactNode;
  setFields: (fields: Partial<SignupFormFields>) => void;
};

type State = {
  isValidationComplete: boolean;
}

type InviteInfo = {
  id: string;
  email: string;
};

class FamilySignUp extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isValidationComplete: false,
    };
  }

  componentDidMount(): void {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    const { invite } = query;

    if (!invite) {
      this.redirectToFamilyInviteError();
    } else {
      this.checkInvitation(invite as string);
    }
  }

  render() {
    const { isValidationComplete } = this.state;

    if (!isValidationComplete) {
      return (
        <FullscreenLayoutLoading />
      );
    }

    const { signUpForm, appsCount } = this.props;

    return (
      <FullscreenLayout>
        <FullscreenLayout.PrimaryContent>
          <div className="family-signup__primary-content">
            <a
              className="family-signup__logo"
              href={externalUrls.setappSite}
            >
              <AnimatedLogo hasCaption size="sm" />
            </a>

            <h3 className="family-signup__primary-title">
              <FormattedMessage id="familySignup.primary.title" defaultMessage="Create an account to accept the invite" />
            </h3>
            {signUpForm}
          </div>
        </FullscreenLayout.PrimaryContent>
        <FullscreenLayout.SecondaryContent>
          <div className="family-signup__secondary-content">
            <img
              src={macAndIphoneImage}
              srcSet={`${macAndIphoneImage2x} 2x, ${macAndIphoneImage3x} 3x`}
              alt="Setapp on Mac and iPhone"
              width="596"
              height="351"
            />
            <h4 className="family-signup__secondary-title">
              <FormattedMessage
                id="familySignup.secondary.title"
                defaultMessage="Setapp is a toolkit of {appsCount}+ apps for everyday tasks on Mac, web and iOS"
                values={{
                  appsCount,
                }}
              />
            </h4>
            <div className="family-signup__secondary-advantages">
              <div className="family-signup__secondary-advantages-item">
                <img
                  src={searchIcon}
                  alt=""
                />
                <p className="family-signup__secondary-advantages-item-caption">
                  <FormattedMessage
                    id="familySignup.secondary.advantages.search"
                    defaultMessage="Type in your task and find apps"
                  />
                </p>
              </div>
              <div className="family-signup__secondary-advantages-item">
                <img
                  src={installIcon}
                  alt=""
                />
                <p className="family-signup__secondary-advantages-item-caption">
                  <FormattedMessage
                    id="familySignup.secondary.advantages.install"
                    defaultMessage="Install and use as many as you want"
                  />
                </p>
              </div>
              <div className="family-signup__secondary-advantages-item">
                <img
                  src={updateIcon}
                  alt=""
                />
                <p className="family-signup__secondary-advantages-item-caption">
                  <FormattedMessage
                    id="familySignup.secondary.advantages.update"
                    defaultMessage="Get automatic app updates"
                  />
                </p>
              </div>
            </div>
          </div>
        </FullscreenLayout.SecondaryContent>
      </FullscreenLayout>
    );
  }

  checkInvitation(invite: string) {
    return auth.validateInvitationToken(invite)
      .then((invite: InviteInfo) => {
        this.validateUserEmail(invite.email, invite.id);
      })
      .catch(() => {
        this.redirectToFamilyInviteError();
      });
  }

  validateUserEmail = (email: string, inviteId: string) => {
    const { setFields } = this.props;

    return validateUserEmail(email)
      .then(() => {
        setFields({
          email,
          inviteId,
        });

        this.setState({ isValidationComplete: true });
      })
      .catch((error) => {
        if (error.status === httpStatuses.CONFLICT) {
          // Email is already exists
          this.redirectToAcceptFamilyInvite();
        } else {
          this.redirectToFamilyInviteError();
        }
      });
  }

  redirectToAcceptFamilyInvite = () => {
    const { location, history } = this.props;

    history.push({
      pathname: urls.acceptFamilyInvite,
      search: location.search,
    });
  }

  redirectToFamilyInviteError = () => {
    const { history } = this.props;

    history.push(urls.familyInviteError);
  }
}

export { FamilySignUp as PureFamilySignUp };

export default signUpHoc({
  withSocial: false,
  emailDisabled: true,
  type: 'family',
  simplified: true,
  withPasswordRequirementsPopup: false,
})(connector(FamilySignUp));
