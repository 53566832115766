import React, { type FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import urls from 'config/urls';

import { isAiEnthusiastPricePlan } from 'services/price-plans/utils';

import { getFeatureFlags, getUserAiCredits } from 'state/root-reducer';
import { showModal } from 'state/modal/modal-reducer';

import analytics, { events } from 'utils/analytics';

import { useSubscriptionContext } from 'components/pages/subscription/context/subscription-context';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';

import InfoField from 'components/shared/info-field/info-field';
import InfoButton from 'components/shared/info-button/info-button';

import './ai-credits-row.scss';

const AiCreditsFieldValue: FC = () => {
  const history = useHistory();
  const showModalAction = useDispatchAction(showModal);
  const { left, limit, example } = useSelector(getUserAiCredits);
  const { pricePlan, isUpgradeToAiPlanAvailable } = useSubscriptionContext();

  const handleOpenManageSubscriptionModal = () => {
    analytics.trackEvent(events.SUBSCRIPTION_BLOCK_MANAGE_SUBSCRIPTION_CLICK);
    showModalAction('MANAGE_SUBSCRIPTION', { selectedStep: 'manage_plan' });
  };

  const handleUpgradeButtonClick = () => {
    analytics.trackEvent(events.AI_PLUS_UPGRADE_PLAN_CLICK, {
      eventLabel2: pricePlan.tierType,
    });

    if (isAiEnthusiastPricePlan(pricePlan.tierType)) {
      handleOpenManageSubscriptionModal();
    } else {
      history.push(urls.aiOffer);
    }
  };

  return (
    <div className="ai-credits-field-value">
      <span>
        <FormattedNumber value={left} />/<FormattedNumber value={limit} />
      </span>
      <InfoButton>
        <FormattedMessage
          id="subscriptionInfo.aiCredits.info.title"
          defaultMessage="Your plan covers {creditsLimit} credits"
          values={{ creditsLimit: limit }}
        />
        <div className="ai-credits-field-value__info-content">
          <FormattedMessage
            id="subscriptionInfo.aiCredits.info.content"
            defaultMessage="Credits reset to the original amount every month, when a new billing cycle starts. You can upgrade your plan to get more credits anytime.{break}{creditsLimit} credits = {limitInGpt35messages} messages with GPT-3.5 Turbo, or {limitInGpt40messages} messages with GPT-4o, or {limitInWhisperMinutes} minutes with Whisper."
            values={{
              break: <br />,
              creditsLimit: limit,
              limitInGpt35messages: example.gpt35,
              limitInGpt40messages: example.gpt4Turbo * 2,
              limitInWhisperMinutes: example.whisper,
            }}
          />
        </div>
      </InfoButton>
      {isUpgradeToAiPlanAvailable && (
        <button className="btn btn_link" onClick={handleUpgradeButtonClick}>
          <FormattedMessage
            id="subscriptionInfo.aiCredits.upgradeButton"
            defaultMessage="Upgrade plan"
          />
        </button>
      )}
    </div>
  );
};

const AiCreditsRow: FC = () => {
  const { enableAiOfferPlans } = useSelector(getFeatureFlags);

  if (!enableAiOfferPlans.value) {
    return null;
  }

  return (
    <InfoField
      title={
        <FormattedMessage
          id="subscriptionInfo.aiCredits.title"
          defaultMessage="AI credits"
        />
      }
      value={<AiCreditsFieldValue />}
    />
  );
};

export default AiCreditsRow;
