import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';

import { useSidebarNavContext } from '../sidebar-nav-context';

import './sidebar-nav-header.scss';

type Props = {
  children: ReactNode;
};

const SidebarNavHeader = ({ children }: Props) => {
  const { expanded, onToggle } = useSidebarNavContext();
  const rootClasses = classNames(
    'sidebar-nav__toggle',
    { 'sidebar-nav__toggle_expanded': expanded }
  );

  return (
    <div className="sidebar-nav__header">
      <button
        type="button"
        className={rootClasses}
        onClick={onToggle}
        aria-expanded={expanded}
      >
        {children}
      </button>
    </div>
  );
};


export default SidebarNavHeader;
