// @flow
import React, { useContext } from 'react';

export type BrainTreeFieldsContextType = {|
  focusedField: ?string,
  isInitialized: boolean,
|};

const defaultContextValue: BrainTreeFieldsContextType = {
  focusedField: null,
  isInitialized: false,
};

const BrainTreeFieldsContext = React.createContext<BrainTreeFieldsContextType>(defaultContextValue);

export default BrainTreeFieldsContext;

export const useBrainTreeFieldsContext = (): BrainTreeFieldsContextType => useContext(BrainTreeFieldsContext);
