import React, { PureComponent } from 'react';
import type { SyntheticEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import {
  inviteFamilyMember,
  fetchFamilyPlan,
  removeFamilyMember,
  resendInvite,
} from 'state/family-plan/family-plan-actions';
import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import {
  getPricePlansList,
  getFamilyMembersExceptCurrent,
  isFamilyFull,
  getFamilyPlan,
  getPrimarySubscription,
} from 'state/root-reducer';
import { showModal } from 'state/modal/modal-reducer';

import validate from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';

import DefaultError from 'components/shared/default-error/default-error';
import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';

import FamilyInviteForm from './family-invite-form/family-invite-form';
import FamilyMembersList from './family-members-list/family-members-list';

import './manage-family.scss';

import InviteIcon from './images/icons-invite.svg';

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  primarySubscription: getPrimarySubscription(state),
  pricePlansList: getPricePlansList(state),
  familyMembers: getFamilyMembersExceptCurrent(state),
  isFamilyFull: isFamilyFull(state),
  isFamilyProcessing: getFamilyPlan(state).isLoading,
});

const mapActionsToProps = {
  inviteFamilyMember,
  showDangerNotification,
  showSuccessNotification,
  fetchPricePlans,
  fetchFamilyPlan,
  fetchAllSubscriptions,
  removeFamilyMember,
  resendInvite,
  showModal,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

type State = {
  fields: {
    email: string;
  };
  fieldsErrors: {
    email?: ReactNode;
  };
};

class ManageFamilyPage extends PureComponent<Props, State> {
  state: State = {
    fields: {
      email: '',
    },
    fieldsErrors: {
      email: '',
    },
  };

  validation = {
    email: {
      required:
        <FormattedMessage
          id="manageFamily.validation.emptyEmail"
          defaultMessage="Please type in an email to send the invite."
        />,
      emailFormat:
        <FormattedMessage
          id="manageFamily.validation.invalidEmail"
          defaultMessage="Hmm… that doesn’t look like an email."
        />,
    },
  };

  componentDidMount() {
    const {
      fetchPricePlans,
      fetchFamilyPlan,
      fetchAllSubscriptions,
    } = this.props;

    return Promise.all([
      fetchPricePlans(),
      fetchFamilyPlan(),
      fetchAllSubscriptions(),
    ])
      .catch(() => {
        const { showDangerNotification } = this.props;

        showDangerNotification(<DefaultError />);
      });
  }

  render() {
    const { fieldsErrors, fields } = this.state;
    const {
      pricePlansList,
      familyMembers,
      isFamilyFull,
      isFamilyProcessing,
      primarySubscription,
    } = this.props;

    if (!pricePlansList.length || !primarySubscription) {
      return <AppLayoutLoading />;
    }

    if (!primarySubscription.pricePlan || !primarySubscription.pricePlan.features) {
      return null;
    }

    const { macSeatsCount = 0, iosSeatsCount = 0, maxMembersCount = 0 } = primarySubscription.pricePlan.features;

    return (
      <>
        <div className="manage-family-page__hero">
          <div>
            <h3 className="mb-5 mt-0">
              <FormattedMessage id="manageFamily.title" defaultMessage="Manage your Setapp Family" />
            </h3>
            <h5 className="mb-4">
              <FormattedMessage id="manageFamily.subtitle" defaultMessage="Add or remove participants" />
            </h5>
            <p className="mb-10">
              <FormattedMessage
                id="manageFamily.description.members"
                defaultMessage="Invite up to {maxMembersCount, plural, one {1 person} other {{maxMembersCount} people}} to join the family."
                values={{
                  maxMembersCount,
                }}
              />
              <br />
              <FormattedMessage
                id="manageFamily.description.devices"
                defaultMessage="Each participant can use Setapp on {macSeatsCount, plural, one {1 Mac} other {# Macs}}{iosSeatsCount, plural, =0 {} one {\u00A0+ 1 iOS device} other {\u00A0+ # iOS devices}}."
                values={{
                  macSeatsCount,
                  iosSeatsCount,
                }}
              />
            </p>
          </div>
        </div>
        <div className="manage-family-page__invites">
          {!isFamilyFull && (
            <>
              <h5 className="mb-6">
                <FormattedMessage id="manageFamily.invites.title" defaultMessage="Send out your invites:" />
              </h5>
              <FamilyInviteForm
                onFieldChange={this.onInviteEmailChange}
                email={fields.email}
                emailError={fieldsErrors.email}
                onSubmit={this.onInviteSubmit}
                isFamilyProcessing={isFamilyProcessing}
              />
            </>
          )}
          {familyMembers.length ? (
            <div className="mt-10">
              <FamilyMembersList
                members={familyMembers}
                maxMembersCount={maxMembersCount}
                resendFamilyInvite={this.resendFamilyInvite}
                isFamilyProcessing={isFamilyProcessing}
                showRemoveFamilyMemberModal={this.showRemoveFamilyMemberModal}
                showRevokeFamilyInviteModal={this.showRevokeFamilyInviteModal}
              />
            </div>
          ) : (
            <div className="mt-5 text_color-secondary manage-family-page__invites-empty">
              <img className="mr-2" src={InviteIcon} width={20} height={20} alt="" />
              <FormattedMessage id="manageFamily.invites.empty" defaultMessage="You haven't invited any participants yet" />
            </div>
          )}
        </div>
      </>
    );
  }

  onInviteEmailChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ fields: { email: e.currentTarget.value } });
  };

  onInviteSubmit = (e: SyntheticEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const { fields } = this.state;

    const fieldsErrors = validate(fields, this.validation);
    const formHasError = Object.keys(fieldsErrors).some((field) => fieldsErrors[field]);

    this.setState({ fieldsErrors });

    if (formHasError) {
      return Promise.resolve();
    }

    return this.inviteToFamily(fields.email);
  };

  inviteToFamily = (email: string) => {
    const { inviteFamilyMember, showSuccessNotification, showDangerNotification } = this.props;
    const { fields } = this.state;

    return inviteFamilyMember(email)
      .then(() => {
        analytics.trackEvent(events.FAMILY_PLAN_INVITE_SENT);

        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            email: '',
          },
        }));

        showSuccessNotification(
          <FormattedMessage
            id="manageFamily.notifications.inviteSent"
            defaultMessage="Invite to {email} sent"
            values={{
              email: <strong>{fields.email}</strong>,
            }}
          />
        );
      })
      .catch((error) => {
        const emailError = error.getFieldError('email');
        const isFamilyInviteUnavailable = emailError && emailError.isConflicted();

        if (emailError) {
          const emailMessage = isFamilyInviteUnavailable ? (
            <FormattedMessage
              id="manageFamily.validation.unavailable"
              defaultMessage="This user cannot be invited to a Family"
            />
          ) : emailError.toString();

          showDangerNotification(
            <FormattedMessage
              id="manageFamily.notifications.unavailable"
              defaultMessage="User cannot be added"
            />
          );

          this.setState((prevState) => ({
            fieldsErrors: {
              ...prevState.fieldsErrors,
              email: emailMessage,
            },
          }));

          if (isFamilyInviteUnavailable) {
            analytics.trackEvent(events.FAMILY_PLAN_INVITE_EXISTING_USER);
          }
        } else {
          showDangerNotification(
            <DefaultError />
          );
        }
      });
  };

  handleRevokeFamilyInviteClick = ({ id, email }: FamilyMember) => this.removeFamilyMember(id, () => {
    const { showSuccessNotification } = this.props;

    showSuccessNotification(
      <FormattedMessage
        id="manageFamily.notifications.revokeInvite"
        defaultMessage="Invite to {email} canceled"
        values={{
          email: <strong>{email}</strong>,
        }}
      />
    );
  });

  handleRemoveFamilyMemberClick = ({ id, email }: FamilyMember) => this.removeFamilyMember(id, () => {
    const { showSuccessNotification } = this.props;

    analytics.trackEvent(events.FAMILY_PLAN_REMOVE_MEMBER_SUCCESS);
    showSuccessNotification(
      <FormattedMessage
        id="manageFamily.notifications.removeMember"
        defaultMessage="Participant {email} removed"
        values={{
          email: <strong>{email}</strong>,
        }}
      />
    );
  });

  removeFamilyMember = (familyMemberId: number, onSuccess: () => void) => {
    const { removeFamilyMember, showDangerNotification } = this.props;

    return removeFamilyMember(familyMemberId)
      .then(onSuccess)
      .catch(() => {
        showDangerNotification(
          <DefaultError />
        );
      });
  };

  resendFamilyInvite = ({ id, email }: FamilyMember) => {
    const { resendInvite, showSuccessNotification, showDangerNotification } = this.props;

    return resendInvite(id)
      .then(() => {
        showSuccessNotification(
          <FormattedMessage
            id="manageFamily.notifications.resendInvite"
            defaultMessage="Invite to {email} sent again"
            values={{
              email: <strong>{email}</strong>,
            }}
          />
        );
      })
      .catch(() => {
        showDangerNotification(
          <DefaultError />
        );
      });
  };

  showRemoveFamilyMemberModal = (member: FamilyMember) => {
    const { showModal, isFamilyProcessing } = this.props;

    showModal('REMOVE_FAMILY_MEMBER', {
      member,
      onRemoveFamilyMemberClick: this.handleRemoveFamilyMemberClick,
      isLoading: isFamilyProcessing,
    });

    analytics.trackEvent(events.FAMILY_PLAN_REMOVE_MEMBER_CLICK);
  };

  showRevokeFamilyInviteModal = (member: FamilyMember) => {
    const { showModal, isFamilyProcessing } = this.props;

    showModal('REVOKE_FAMILY_INVITE', {
      member,
      onRevokeFamilyInviteClick: this.handleRevokeFamilyInviteClick,
      isLoading: isFamilyProcessing,
    });
  };
}

export { ManageFamilyPage as PureManageFamilyPage };

export default connector(ManageFamilyPage);
