import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import { showModal } from 'state/modal/modal-reducer';
import { isChangePlanAllowed as getIsChangePlanAllowed } from 'state/root-reducer';
import analytics, { events } from 'utils/analytics';

const useOpenChangePlanModal = () => {
  const location = useLocation();
  const isChangePlanAllowed = useSelector(getIsChangePlanAllowed);
  const showModalAction = useDispatchAction(showModal);

  return useCallback(() => {
    if (!isChangePlanAllowed) return;

    const searchParams = new URLSearchParams(location.search);

    const selectedPlanType = searchParams.get('selected_plan');

    showModalAction('CHANGE_PLAN', { selectedPlanType });
    analytics.trackEvent(events.SUBSCRIPTION_PLAN_MODAL_OPEN);
  }, [location, isChangePlanAllowed]);
};

export default useOpenChangePlanModal;
