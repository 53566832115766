import type { Dispatch } from 'state/state-types';
import request from 'utils/request';
import { apiURL } from 'config/api';
import * as actionTypes from './referrals-action-types';

// eslint-disable-next-line import/prefer-default-export
export function fetchReferrals() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.get(apiURL.referrals)
      .then((data) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });

        throw error;
      });
  };
}
