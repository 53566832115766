import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import classNames from 'classnames';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';
import * as statuses from 'state/family-plan/family-member-statuses';

import './family-members-item.scss';

type Props = {
  isFamilyProcessing: boolean;
  member: FamilyMember;
  resendFamilyInvite: (member: FamilyMember) => Promise<void>;
  showRevokeFamilyInviteModal: (member: FamilyMember) => void;
  showRemoveFamilyMemberModal: (member: FamilyMember) => void;
};

const familyMembersStatus = {
  [statuses.ACTIVE]: <FormattedMessage id="familyMembersItem.status.active" defaultMessage="Active" />,
  [statuses.PENDING]: <FormattedMessage id="familyMembersItem.status.pending" defaultMessage="Invite sent" />,
};

const FamilyMembersItem = (props: Props) => {
  const {
    member,
    resendFamilyInvite,
    isFamilyProcessing,
    showRemoveFamilyMemberModal,
    showRevokeFamilyInviteModal,
  } = props;

  const title = member.name || member.email;
  const statusClasses = classNames('family-members-item__status', {
    'text_color-success': member.status === statuses.ACTIVE,
  });

  return (
    <div className="family-members-item">
      <Row>
        <Col className="family-members-item__info" xs={8} lg={7}>
          {member.avatarUrl ? (
            <img
              src={member.avatarUrl}
              className="family-members-item__avatar"
              alt=""
            />
          ) : (
            <div className="family-members-item__avatar">
              {title.slice(0, 1).toUpperCase()}
            </div>
          )}
          {member.email}
        </Col>
        <Col className={statusClasses} xs={4} lg={2}>
          {familyMembersStatus[member.status]}
        </Col>
        <Col className="family-members-item__actions" lg={3}>
          {member.status === statuses.ACTIVE ? (
            <Button
              className="ml-5 text_weight-medium"
              variant="link"
              onClick={() => showRemoveFamilyMemberModal(member)}
              disabled={isFamilyProcessing}
            >
              <FormattedMessage id="familyMembersItem.action.remove" defaultMessage="Remove" />
            </Button>
          ) : (
            <>
              <Button
                className="family-members-item__actions-button text_weight-medium"
                variant="link"
                onClick={() => showRevokeFamilyInviteModal(member)}
                disabled={isFamilyProcessing}
              >
                <FormattedMessage id="familyMembersItem.action.revoke" defaultMessage="Revoke" />
              </Button>

              <Button
                className="family-members-item__actions-button text_weight-medium"
                variant="link"
                onClick={() => resendFamilyInvite(member)}
                disabled={isFamilyProcessing}
              >
                <FormattedMessage id="familyMembersItem.action.resend" defaultMessage="Resend" />
              </Button>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FamilyMembersItem;
