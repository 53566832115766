import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'state/root-reducer';
import { fetchTrialStatusThunk } from 'state/trial-status/trial-status-reducer';
import {
  getTrialAvailabilityError,
  getTrialAvailabilityIsFetched,
  getTrialAvailabilityIsLoading,
  getTrialAvailabilityStatus,
} from 'state/trial-status/trial-status-selectors';
import { TrialAvailabilityStatus, TrialStatus } from 'state/trial-status/trial-status-types';
import useDispatchAction from '../use-dispatch-action/use-dispatch-action';

type UseTrialAvailabilityStatusOutput = {
  isLoading: boolean;
  isFetched: boolean;
  status: Maybe<TrialAvailabilityStatus>;
  error: Maybe<Error>;
};

type UseTrialAvailabilityStatusInput = {
  skip?: boolean;
  onCompleted?: (status: TrialStatus) => void;
};

const TRIAL_AVAILABLE_STATUSES = [
  TrialAvailabilityStatus.trialAllowed,
  TrialAvailabilityStatus.trialAllowedPaymentDetailsNeeded,
];

const useTrialAvailabilityStatus = ({
  skip,
  onCompleted,
}: UseTrialAvailabilityStatusInput = {}): UseTrialAvailabilityStatusOutput => {
  const isLoading = useSelector(getTrialAvailabilityIsLoading);
  const isFetched = useSelector(getTrialAvailabilityIsFetched);
  const status = useSelector(getTrialAvailabilityStatus);
  const error = useSelector(getTrialAvailabilityError);
  const user = useSelector(getUser);

  const fetchTrialStatus = useDispatchAction(fetchTrialStatusThunk);

  const isAvailable = status && TRIAL_AVAILABLE_STATUSES.includes(status);

  const computedStatus = isAvailable ? TrialAvailabilityStatus.trialAllowed : status;

  const skipDueToData = isLoading || error || isFetched;
  const skipDueToUserState = !user?.desktopCcRequired;
  const skipDueToConditions = skip || skipDueToData || skipDueToUserState;

  useEffect(() => {
    if (skipDueToConditions) return;

    fetchTrialStatus({ onCompleted });
  }, [skipDueToConditions, onCompleted]);

  return {
    isLoading,
    isFetched,
    status: computedStatus,
    error,
  };
};

export default useTrialAvailabilityStatus;
