import React, { PureComponent } from 'react';
import type { ChangeEvent, FormEvent } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { Notification } from '@setapp/ui-kit';

import urls from 'config/urls';
import externalUrls from 'config/external-urls';

import validate from 'utils/auth-validation';
import { removeQueryParams } from 'utils/location';

import {
  getGiftCardTypesList,
  getGiftCardsList,
  getGiftCards,
  isUserFamilyMember,
  isUserPlainFamilyMember,
  isMembershipPriceIncreaseEnabled,
} from 'state/root-reducer';
import { fetchGiftCardTypes, fetchGiftCards, redeemGiftCard } from 'state/gift-cards/gift-cards-actions';
import type { GiftCard, GiftCardType as GiftCardTypeType } from 'state/gift-cards/gift-cards-reducer';
import { showSuccessNotification, showDangerNotification } from 'state/notifier/notifier-reducer';

import DefaultError from 'components/shared/default-error/default-error';
import InfoButton from 'components/shared/info-button/info-button';
import PageTitle from 'components/shared/page-title/page-title';

import RedeemGiftCard from './redeem-gift-card/redeem-gift-card';
import GiftCardPurchased from './gift-card-purchased/gift-card-purchased';
import GiftCardType from './gift-card-type/gift-card-type';
import GiftCardsList from './gift-cards-list/gift-cards-list';
import heartImage from './images/heart.svg';

import './gift-cards-page.scss';

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  giftCardTypes: getGiftCardTypesList(state),
  purchasedGiftCards: getGiftCardsList(state),
  isPurchasedGiftCardsProcessing: getGiftCards(state).isLoading,
  isFamilyMember: isUserFamilyMember(state),
  isPlainFamilyMember: isUserPlainFamilyMember(state),
  membershipPriceIncrease: isMembershipPriceIncreaseEnabled(state).membershipPriceIncrease,
});

const mapActionsToProps = {
  fetchGiftCardTypes,
  fetchGiftCards,
  redeemGiftCard,
  showDangerNotification,
  showSuccessNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps & InjectedIntlProps;

type State = {
  codeError: string;
  code: string;
};

const messages = defineMessages({
  emptyCode: {
    id: 'giftCards.validation.emptyCode',
    defaultMessage: 'Enter the code first',
  },
});

class GiftCardsPage extends PureComponent<Props, State> {
  validation = {
    code: {
      required: messages.emptyCode,
    },
  };

  constructor(props: Props) {
    super(props);
    const query = queryString.parse(props.location.search);

    this.state = {
      codeError: '',
      code: query.gift_card as string || '',
    };
  }

  componentDidMount() {
    const {
      fetchGiftCardTypes,
      fetchGiftCards,
      showDangerNotification,
      isPlainFamilyMember,
      history,
      membershipPriceIncrease
    } = this.props;

    if (isPlainFamilyMember || membershipPriceIncrease) {
      history.push(urls.subscription);
    }

    return Promise.all([
      fetchGiftCardTypes(),
      fetchGiftCards(),
    ])
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  render() {
    const {
      purchasedGiftCards,
      giftCardTypes,
      isFamilyMember,
      isPlainFamilyMember,
      isPurchasedGiftCardsProcessing,
    } = this.props;
    const { code, codeError } = this.state;

    return (
      <div>
        <PageTitle>
          <FormattedMessage id="giftCards.pageTitle" defaultMessage="Gift cards" />
        </PageTitle>

        {isFamilyMember && (
          <div className="gift-cards-page__family-notification">
            <Notification type="info">
              <FormattedMessage
                id="giftCards.familyLimitationMessage"
                defaultMessage="As a Family {role}, you can buy Gift card as a present but not apply them to your own subscription."
                values={{
                  role: isPlainFamilyMember ? (
                    <FormattedMessage
                      id="family.regularMemberRole"
                      defaultMessage="member"
                    />
                  ) : (
                    <FormattedMessage
                      id="family.ownerRole"
                      defaultMessage="owner"
                    />
                  ),
                }}
              />
            </Notification>
          </div>
        )}

        {!isFamilyMember && (
          <>
            <p className="h5 mb-6">
              <FormattedMessage id="giftCards.redeem.title" defaultMessage="Have a gift card code? Activate it here." />
            </p>
            <RedeemGiftCard
              onSubmit={this.onRedeemGiftCardFormSubmit}
              onFieldChange={this.onCodeFieldChange}
              isProcessing={isPurchasedGiftCardsProcessing}
              codeError={codeError}
              code={code}
            />
            <p className="gift-cards-page__info">
              <FormattedMessage
                id="giftCards.info"
                defaultMessage="Code will apply to your current plan"
              />
              &nbsp;
              <InfoButton>
                <FormattedMessage
                  id="giftCards.infoHint"
                  defaultMessage="Your current Setapp plan will be extended by the number of days covered by the gift card cash value."
                />
              </InfoButton>
            </p>
          </>
        )}

        {Boolean(purchasedGiftCards.length) && (
          <div>
            <p className="h5 gift-cards-page__section-title">
              <FormattedMessage id="giftCards.purchasedCardsTitle" defaultMessage="Purchased gift cards" />
            </p>
            <GiftCardsList<GiftCard>
              items={purchasedGiftCards}
              itemComponent={GiftCardPurchased}
              qaLabel="purchased-list"
            />
          </div>
        )}

        <p className="h5 gift-cards-page__section-title">
          <FormattedMessage id="giftCards.shopCardsTitle" defaultMessage="Shop cards" />
        </p>
        <p className="mb-3">
          <FormattedMessage
            id="giftCards.shopCardsDescription"
            defaultMessage="Perfect for Mac lovers {heartImage} Click a card to select."
            values={{
              heartImage: <img src={heartImage} alt="" />,
            }}
          />
        </p>

        <GiftCardsList<GiftCardTypeType>
          onItemClick={this.redirectToSiteGiftCardsStore}
          items={giftCardTypes}
          itemComponent={GiftCardType}
          qaLabel="types-list"
        />
      </div>
    );
  }

  redirectToSiteGiftCardsStore() {
    window.location.assign(externalUrls.siteGiftCardsStore);
  }

  onCodeFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const code = e.currentTarget.value;

    this.setState({ codeError: '', code });
  };

  onRedeemGiftCardFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    const { intl } = this.props;
    const { code } = this.state;
    e.preventDefault();

    const codeErrorMessage = validate({ code }, this.validation).code;

    if (codeErrorMessage) {
      this.setState({ codeError: intl.formatMessage(codeErrorMessage) });

      return;
    }

    this.setState({ codeError: '' });
    this.redeemGiftCard();
  };

  redeemGiftCard() {
    const { redeemGiftCard } = this.props;
    const { code } = this.state;

    return redeemGiftCard(code)
      .then(this.onRedeemGiftCardSuccess)
      .catch((error) => this.onRedeemGiftCardFailed(error.getSimplifiedErrors().genericError));
  }

  onRedeemGiftCardSuccess = () => {
    const { history, showSuccessNotification } = this.props;
    removeQueryParams(history, 'gift_card');

    showSuccessNotification(
      <FormattedMessage id="giftCards.redeemSuccess" defaultMessage="You successfully activated gift card." />,
    );
  };

  onRedeemGiftCardFailed = (errorMessage: string) => {
    this.setState({ codeError: errorMessage });
  };
}

export { GiftCardsPage as PureGiftCardsPage };

export default connector(injectIntl(GiftCardsPage));
