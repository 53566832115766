import { detectLocale, browserToServerLocale } from 'utils/intl';

const injectUserLanguage = (next) => (url, options) => {
  const language = browserToServerLocale(detectLocale());

  return next(url, {
    ...options,
    headers: {
      ...options.headers,
      'Accept-Language': language,
    },
  });
};

export default injectUserLanguage;
