import React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import Form from 'react-bootstrap/lib/Form';
import { FormField, Button } from '@setapp/ui-kit';

import './member-invitation-form.scss';

import type { KeyboardEvent, ReactNode, SyntheticEvent } from 'react';
import type { InjectedIntlProps } from 'react-intl';

import PanelBox from 'components/shared/panel-box/panel-box';

type Props = {
  onFieldChange: (e: KeyboardEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent<HTMLFormElement>) => Promise<void>;
  email: string;
  emailError: ReactNode;
  isProcessing: boolean;
} & InjectedIntlProps;

const messages = defineMessages({
  emailPlaceholder: {
    id: 'familyMemberInvitationForm.email.placeholder',
    defaultMessage: 'Email of your family member',
  },
});

const MemberInvitationForm = ({
  isProcessing, onFieldChange, email, emailError, onSubmit, intl,
}: Props) => (
  <PanelBox>
    <PanelBox.Content>
      <Form
        onSubmit={onSubmit}
        className="member-invitation-form"
        data-qa="memberInvitationForm"
        noValidate
      >
        <FormField
          id="email"
          name="email"
          type="email"
          value={email}
          invalid={Boolean(emailError)}
          helpText={emailError}
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          label={intl.formatMessage(messages.emailPlaceholder)}
          onChange={onFieldChange}
        />

        <Button
          type="submit"
          disabled={isProcessing}
          className="member-invitation-form__submit"
          data-qa="sendInviteButton"
        >
          <FormattedMessage
            id="familyMemberInvitationForm.submitButton"
            defaultMessage="Send invite"
          />
        </Button>
      </Form>
    </PanelBox.Content>
  </PanelBox>
);

export { MemberInvitationForm as PureMemberInvitationForm };

export default injectIntl(MemberInvitationForm);
