// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import InfoRadioButton from 'components/shared/info-radio-button/info-radio-button';
import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import PricePlanDetails from '../price-plan-descriptions/price-plan-details';
import PricePlanDescription from '../price-plan-descriptions/price-plan-description';
import PricePlanItem from './plan-item/plan-item';


import './change-plan-form.scss';

type Props = {
  pricePlans: Array<PricePlan>;
  selectedPlan?: PricePlan;
  currentPlan?: PricePlan;
  isLoading: boolean;
  onSubmit: () => void;
  onPlanChange: (planId: number) => void;
};

const testAnnualPricePlans = [
  pricePlanTypes.NEW_ANNUAL_1_MAC,
  pricePlanTypes.NEW_ANNUAL_1_MAC_UNLIM_IOS,
  pricePlanTypes.NEW_ANNUAL_4_MAC_UNLIM_IOS,
];

const ChangePlanForm = (props: Props) => {
  const {
    selectedPlan, pricePlans, isLoading, currentPlan,
  } = props;
  const isCurrentPlanSelected = currentPlan && selectedPlan && selectedPlan.id === currentPlan.id;

  return (
    <form onSubmit={onFormSubmit} noValidate>
      <fieldset className="form-group">
        <legend className="sr-only">
          <FormattedMessage
            id="pricePlans.changePlanModal.changePlanLegend"
            defaultMessage="Choose price plan"
          />
        </legend>

        {pricePlans
          .map((pricePlan) => {
            const isSelectedPlan = Boolean(selectedPlan && pricePlan.id === selectedPlan.id);
            const isTestAnnualPlan = testAnnualPricePlans.includes(pricePlan.tierType);

            return (
              <div className="change-plan-form__item" key={pricePlan.id} data-qa="pricePlanItem">
                <InfoRadioButton
                  name="pricePlan"
                  value={pricePlan.id}
                  onChange={onChangeSelected}
                  checked={isSelectedPlan}
                  autoFocus={isSelectedPlan}
                >
                  <PricePlanItem
                    price={pricePlan.price}
                    currency={pricePlan.currency}
                    paidMonth={pricePlan.paidMonth}
                    active={isSelectedPlan}
                    isTestAnnualPlan={isTestAnnualPlan}
                    title={<PricePlanTitle tierType={pricePlan.tierType} />}
                    subtitle={<PricePlanDescription newPlan={pricePlan} />}
                    description={<PricePlanDetails pricePlan={pricePlan} />}
                    priceDescription={isTestAnnualPlan ? (
                      <FormattedMessage
                        id="pricePlans.changePlanModal.priceAnnualDescription"
                        defaultMessage="/year"
                      />
                    ) : (
                      <FormattedMessage
                        id="pricePlans.changePlanModal.priceDescription"
                        defaultMessage="/month"
                      />
                    )}
                  />
                </InfoRadioButton>
              </div>
            );
          })}
      </fieldset>

      <Button
        type="submit"
        disabled={isCurrentPlanSelected || isLoading}
        block
        className="change-plan-form__button"
      >
        <FormattedMessage
          id="pricePlans.changePlanModal.button"
          defaultMessage="Change plan"
        />
      </Button>

      <div className="change-plan__note text-center">
        <FormattedMessage
          id="pricePlans.changePlanModal.changePlanNote"
          defaultMessage="NOTE: The price does not include tax where applicable."
        />
      </div>
    </form>
  );

  function onFormSubmit(e) {
    e.preventDefault();

    props.onSubmit();
  }

  function onChangeSelected(e) {
    props.onPlanChange(Number(e.currentTarget.value));
  }
};

export default ChangePlanForm;
