import React from 'react';
import { FormattedMessage } from 'react-intl';

import externalUrls from 'config/external-urls';

import analytics, { events } from 'utils/analytics';

import './partnership-banner.scss';

import partnersIcon from './images/partners-icon.png';
import partnersIcon2x from './images/partners-icon@2x.png';
import partnersIcon3x from './images/partners-icon@3x.png';
import partnersIconSmall from './images/partners-icon-small.png';
import partnersIconSmall2x from './images/partners-icon-small@2x.png';
import partnersIconSmall3x from './images/partners-icon-small@3x.png';
import setappIcon from './images/setapp-icon.png';
import setappIcon2x from './images/setapp-icon@2x.png';
import setappIcon3x from './images/setapp-icon@3x.png';

const PartnershipBanner = () => {
  const handleBannerClick = () => {
    analytics.trackEvent(events.SPECIAL_OFFER_BANNER_CLICK, { eventLabel: 'Top' });
  };

  return (
    <div className="special-offer-banner">
      <a
        className="special-offer-banner__content"
        href={externalUrls.partnershipStore}
        onClick={handleBannerClick}
      >
        <img
          className="special-offer-banner__partners-icon"
          src={partnersIcon}
          srcSet={`${partnersIcon2x} 2x, ${partnersIcon3x} 3x`}
          width={142}
          height={116}
          alt=""
        />

        <h6 className="special-offer-banner__title">
          <FormattedMessage
            id="partnershipBanner.title"
            defaultMessage="Pro’s Essentials are here."
          />
          {' '}
          <span className="special-offer-banner__title_underlined">
            <FormattedMessage
              id="partnershipBanner.titleUnderlined"
              defaultMessage="Get now at a very special price."
            />
          </span>
        </h6>

        <img
          className="special-offer-banner__setapp-icon"
          src={setappIcon}
          srcSet={`${setappIcon2x} 2x, ${setappIcon3x} 3x`}
          width={115}
          height={106}
          alt=""
        />

        <img
          className="special-offer-banner__partners-icon_small"
          src={partnersIconSmall}
          srcSet={`${partnersIconSmall2x} 2x, ${partnersIconSmall3x} 3x`}
          width={246}
          height={116}
          alt=""
        />
      </a>
    </div>
  );
};

export default PartnershipBanner;
