import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';
import { SPECIAL_OFFER_CAMPAIGNS } from 'state/user/campaign-types';

import type { Action } from '../state-types';
import * as familyActionTypes from '../family-plan/family-plan-action-types';

import initialState, { type UserAICredits } from './user-initial-state';
import type { UserState } from './user-initial-state';

import * as userActionTypes from './user-actions-types';
import * as userTypes from './user-types';
import * as flow from './registration-flow-types';

const user = (state: UserState = initialState, action: Action): UserState => {
  switch (action.type) {
    case userActionTypes.LOGIN: {
      return { ...state, isAuthenticated: true };
    }

    case userActionTypes.SET_USER_INFO: {
      return { ...state, ...action.payload };
    }

    case userActionTypes.LOGOUT: {
      return { ...state, isAuthenticated: false };
    }

    case userActionTypes.SET_USER_PAYMENT_INFO: {
      return { ...state, paymentInfoRequired: action.payload };
    }

    case userActionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case userActionTypes.REQUEST_SUCCESS:
    case userActionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    case familyActionTypes.REMOVE_MEMBER: {
      if (!state.familyMember || state.familyMember.isOwner) {
        return state;
      }

      return {
        ...state,
        familyMember: null,
        isTeamMember: false,
      };
    }

    case familyActionTypes.REQUEST_SUCCESS: {
      if (!action.payload) {
        return state;
      }

      const isTeamMember = action.payload.isTeam;
      const [currentFamilyMember] = action.payload.members.filter((member) => member.isCurrentUser);

      // keep user.familyMember up to date with family state
      return {
        ...state,
        familyMember: currentFamilyMember || null,
        isTeamMember,
      };
    }

    case familyActionTypes.DROP: {
      return {
        ...state,
        familyMember: null,
        isTeamMember: false,
      };
    }

    case userActionTypes.SET_SIGNUP_GIFT_CARD_CODE: {
      return {
        ...state,
        signupGiftCardCode: action.payload,
      };
    }

    case userActionTypes.SET_SIGNUP_PROMO_CODE: {
      return {
        ...state,
        signupPromoCode: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default user;

export const getEmail = (state: UserState): string => state.email;
export const getId = (state: UserState): number | null => state.id;
export const getAiCredits = (user: UserState): UserAICredits => user.aiCredits;
export const getCurrentFamilyMember = (state: UserState): FamilyMember | null => state.familyMember;
export const isAuthenticated = (state: UserState): boolean => state.isAuthenticated;
export const isPaymentInfoRequired = (state: UserState): boolean => state.paymentInfoRequired;
export const isFree = (state: UserState): boolean => state.type === userTypes.FREE;
export const isEmailConfirmed = (state: UserState): boolean => state.emailConfirmed;
export const isEduUserWithInvalidEmail = (state: UserState): boolean => !state.isEduEmail
  && state.registrationFlow === flow.EDU;
export const isReferralUser = (state: UserState): boolean => state.isReferral
  && !isFree(state)
  && state.registrationFlow !== flow.EDU;
export const isUpsaleFlow = (state: UserState): boolean => state.registrationFlow === flow.UPSALE;
export const isAppLandingFlow = (state: UserState): boolean => state.registrationFlow === flow.APP_LANDING;
export const canSwitchToEduPlan = (state: UserState): boolean => state.canSwitchToEduPlan;
export const isRegistrationCompleted = (state: UserState): boolean => !state.paymentInfoRequired;
export const isPlainFamilyMember = (state: UserState): boolean => Boolean(state.familyMember
  && !state.familyMember.isOwner);
export const isFamilyMember = (state: UserState): boolean => Boolean(state.familyMember);
export const isFamilyOwner = (state: UserState): boolean => Boolean(state.familyMember && state.familyMember.isOwner);
export const isSpecialOfferCampaign = (state: UserState): boolean => Boolean(
  state.campaign && SPECIAL_OFFER_CAMPAIGNS.includes(state.campaign)
);
export const hasPriceFeatures = (state: UserState): boolean => Boolean(state.hasPriceFeatures);
export const getSignupGiftCardCode = (state: UserState): string | undefined => state.signupGiftCardCode;
export const getSignupPromoCode = (state: UserState): string | undefined => state.signupPromoCode;
export const canUseReferralProgram = (state: UserState): boolean => !isFree(state) && !isPlainFamilyMember(state);
export const getIsSetappMobileAvailable = (state: UserState) => state.isSetappMobileAvailable;
export const getShowEstimatedVatIncludedWarning = (state: UserState) => state.isSetappMobileAvailable || state.accountCurrency === 'EUR';
