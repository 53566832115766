import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Notification } from '@setapp/ui-kit';

import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';

import NotificationNeedHelpLink from '../notification-need-help-link/notification-need-help-link';

import './soon-payment-notification.scss';

type Props = {
  isPaymentProcessing: boolean;
  onAddPaymentDetailsClick: () => void;
};

const SoonPaymentNotification = ({ isPaymentProcessing, onAddPaymentDetailsClick }: Props) => {
  return (
    <Notification type="info">
      <div className="soon-payment-notification__container">
        <p className="mb-0">
          <FormattedMessage
            id="subscriptionInfo.statusNotifications.paymentDayLessThenInTwoWeeks"
            defaultMessage="Your trial period is ending soon. Please add your payment details to keep using Setapp."
          />
        </p>
        <div className="soon-payment-notification__action">
          <Button
            size="sm"
            onClick={onAddPaymentDetailsClick}
            disabled={isPaymentProcessing}
          >
            <PaymentDetailsActionText action="add" />
          </Button>
        </div>
      </div>
      <NotificationNeedHelpLink />
    </Notification>
  );
};

export default SoonPaymentNotification;
