import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';
import MemberItem from './member-item/member-item';

import './members-list.scss';

type Props = {
  members: Array<FamilyMember>;
  showRemoveMemberModal: (member: FamilyMember) => void;
  resendInvite: (member: FamilyMember) => Promise<void>;
  revokeInvite: (member: FamilyMember) => Promise<void>;
  isFamilyProcessing: boolean;
};

const MembersList = ({
  members, resendInvite, revokeInvite, showRemoveMemberModal, isFamilyProcessing,
}: Props) => (
  <div>
    <p className="h5">
      <FormattedMessage
        id="familyMembersList.title"
        defaultMessage="Manage Family members:"
      />
    </p>
    <ul className="family-members-list" data-qa="familyMembersList">
      {members.map((member) => (
        <li key={member.id}>
          <MemberItem
            member={member}
            isFamilyProcessing={isFamilyProcessing}
            onRevokeInviteClick={revokeInvite}
            onResendInviteClick={resendInvite}
            onRemoveMemberClick={showRemoveMemberModal}
          />
        </li>
      ))}
    </ul>
  </div>
);

export default MembersList;
