import RequestError from '@setapp/request-error';

import type { PaymentDetails } from 'services/payment-details-api/payment-details-api';

export type PaymentDetailsState = {
  data: PaymentDetails | null;
  isLoading: boolean;
  isFetched: boolean;
  error: RequestError | null;
};

const initialState: PaymentDetailsState = {
  data: null,
  isLoading: false,
  isFetched: false,
  error: null,
};

export default initialState;
