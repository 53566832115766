import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { ActionCreator } from 'redux';

/**
 * A React hook that provides an ability to use action creators with already applied dispatch function.
 *
 * @param action - The action creator function.
 * @returns The action creator with the dispatch function applied.
 *
 * @template RT - Returned action type.
 * @template AC - Action creator type.
 */
const useDispatchAction = <RT, AC extends ActionCreator<RT>>(action: AC): AC => {
  const dispatch = useDispatch();

  return useCallback((...args: any[]) => dispatch(action(...args)), [dispatch, action]) as AC;
};

export default useDispatchAction;
