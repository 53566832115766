import React, { FC } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

import AccountInfoRow from 'components/shared/account-info-row/account-info-row';

type Props = {
  nextPaymentDate: number;
  highlightDate: boolean;
};

const TrialEndDateRow: FC<Props> = ({ highlightDate, nextPaymentDate }) => (
  <AccountInfoRow
    title={<FormattedMessage id="subscriptionInfo.trialEndDate" defaultMessage="Trial ends" />}
    valueClass={highlightDate ? 'text-danger' : ''}
    value={(
      <FormattedDate
        value={nextPaymentDate * 1000}
        year="numeric"
        month="short"
        day="numeric"
      />
      )}
    qaLabel="subscriptionTrialEndDateText"
  />
);

export default TrialEndDateRow;
