import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';

import { getFeatureFlags } from 'state/root-reducer';
import type { SubscriptionStatus } from 'state/subscription/statuses';

import PromoBanner from 'components/shared/promo-banner/promo-banner';

import illustration from './images/illustration.svg';
import illustrationOld from './images/illustration-old.svg';


type Props = {
  status: SubscriptionStatus;
}

const ReferralBanner = ({ status }: Props) => {
  const { enableUsersReferralRefresh } = useSelector(getFeatureFlags);

  const trackEvent = useCallback(() => {
    analytics.trackEvent(events.SUBSCRIPTION_BLOCK_REFERRAL_CLICK, { eventLabel: status.toString() });
  }, [status]);

  return (
    <PromoBanner
      image={enableUsersReferralRefresh.value ? illustration : illustrationOld}
      buttonVariant={enableUsersReferralRefresh.value ? 'primary-outline' : 'primary'}
      title={enableUsersReferralRefresh.value ? (
        <FormattedMessage
          id="referralBanner.title"
          defaultMessage="Bring a friend to Setapp"
        />
      ) : (
        <FormattedMessage
          id="referralBanner.titleOld"
          defaultMessage="Use Setapp for free"
        />
      )}
      message={enableUsersReferralRefresh.value ? (
        <FormattedMessage
          id="referralBanner.text"
          defaultMessage="Share your love of apps with friends! Invite a friend to Setapp and both get one free month of Setapp once your friend starts their paid subscription."
        />
      ) : (
        <FormattedMessage
          id="referralBanner.textOld"
          defaultMessage="Invite friends to try Setapp and earn a free month for every successful referral."
        />
      )}
      buttonText={
        <FormattedMessage
          id="referralBanner.buttonText"
          defaultMessage="Share Setapp"
        />
      }
      buttonTag={Link}
      buttonUrl={urls.referral}
      onButtonClick={trackEvent}
    />
  );
};

export default ReferralBanner;
