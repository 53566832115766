// @flow

export type OAuthStatePayload = {
  signupParams?: ?{},
  ...
};

export default class OauthState {
  state = {};

  static parse(stateString: string) {
    try {
      const state = JSON.parse(atob(stateString));

      return new OauthState(state);
    } catch (e) {
      throw new Error('Invalid state');
    }
  }

  constructor(state: {payload?: OAuthStatePayload}) {
    this.state = { payload: {}, ...state };
  }

  toString() {
    return btoa(JSON.stringify(this.state));
  }

  get payload(): OAuthStatePayload {
    return this.state.payload;
  }

  set payload(payload: OAuthStatePayload) {
    this.state.payload = payload;
  }
}
