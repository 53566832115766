import React, { FormEvent, ReactNode } from 'react';
import classnames from 'classnames';

import { ButtonLoading } from '@setapp/ui-kit';

type Props = {
  submitBtnCaption: ReactNode;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isSubmitProcessing?: boolean;
  errorMessage?: ReactNode;
  children: ReactNode;
  hideSubmit?: boolean;
  qaLabel?: string;
};

const SimplifiedForm = ({
  submitBtnCaption,
  onSubmit,
  errorMessage,
  isSubmitProcessing = false,
  qaLabel = '',
  children,
  hideSubmit = false,
}: Props) => {
  const errorClasses = classnames('form-error text-danger', {
    hidden: !errorMessage,
  });
  const btnClasses = classnames({
    processing: isSubmitProcessing,
    hidden: hideSubmit,
  });

  return (
    <form onSubmit={onSubmit} noValidate data-qa={qaLabel}>
      {children}
      <p className={errorClasses}>{errorMessage}</p>
      <ButtonLoading
        block
        className={btnClasses}
        type="submit"
        disabled={isSubmitProcessing}
        isLoading={isSubmitProcessing}
        data-qa="submitButton"
      >
        {submitBtnCaption}
      </ButtonLoading>
    </form>
  );
};

export default SimplifiedForm;
