import React, { useState } from 'react';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';

import ButtonBack from 'components/shared/button-back/button-back';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentForm from 'components/shared/payment-form/payment-form';

import PaymentErrorStep from 'components/user-flow/shared/payment-error-step/payment-error-step';

import useFetchDataForPaymentRetry from './hooks/use-fetch-data-for-payment-retry';
import useRetryPayment from './hooks/use-retry-payment';

import PaymentRetryInfoStep from './components/payment-retry-info-step/payment-retry-info-step';

type Step = 'paymentRetryInfoStep'
  | 'paymentErrorStep'
  | 'updatePaymentDetailsStep';

const BACK_STEP_MAPPING: {[key in Step]?: Step} = {
  paymentErrorStep: 'paymentRetryInfoStep',
  updatePaymentDetailsStep: 'paymentRetryInfoStep',
};

const PaymentRetryFlow = () => {
  const [step, setStep] = useState<Step>('paymentRetryInfoStep');

  const { isLoading, data } = useFetchDataForPaymentRetry();
  const { isPaymentMethodCreated, primarySubscription } = data;

  const { isProcessing: isRetryPaymentProcessing, retryPayment } = useRetryPayment({
    onFailed: () => setStep('paymentErrorStep'),
  });

  if (isLoading) {
    return <FullscreenLayoutLoading />;
  }

  const handleBackButtonClick = () => {
    const backStep = BACK_STEP_MAPPING[step];

    if (backStep) {
      setStep(backStep);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 'paymentRetryInfoStep':
        return (
          <PaymentRetryInfoStep
            isPaymentMethodCreated={isPaymentMethodCreated}
            isProcessing={isRetryPaymentProcessing}
            primarySubscription={primarySubscription}
            onRetryPayment={retryPayment}
            onUpdatePaymentDetailsClick={() => setStep('updatePaymentDetailsStep')}
          />
        );
      case 'paymentErrorStep':
        return (
          <PaymentErrorStep
            onRetry={() => setStep('paymentRetryInfoStep')}
            onUpdatePaymentDetails={() => setStep('updatePaymentDetailsStep')}
          />
        );
      case 'updatePaymentDetailsStep':
        return (
          <>
            <h3 className="mt-0 mb-8">
              <PaymentDetailsActionText action="update" />
            </h3>
            <PaymentForm
              onSuccessSubmit={() => setStep('paymentRetryInfoStep')}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <FullscreenLayout withLogo>
      <FullscreenLayout.PrimaryContent wideContainer>
        <div className="mb-5">
          <ButtonBack
            visible={Boolean(BACK_STEP_MAPPING[step])}
            onClick={handleBackButtonClick}
          />
        </div>
        {renderStep()}
      </FullscreenLayout.PrimaryContent>
    </FullscreenLayout>
  );
};

export default PaymentRetryFlow;
