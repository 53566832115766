import React, { ReactElement } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';

type BasePricePlanMetadata = {
  title: ReactElement;
  titleFull: ReactElement;
  availableDevicesMessage: FormattedMessage.MessageDescriptor;
  description?: ReactElement;
};

export type PricePlanMetadata = BasePricePlanMetadata & {
  tierType: string;
  billingPeriod: ReactElement;
};

const messages = defineMessages({
  customPlanAvailableDevices: {
    id: 'pricePlans.metadata.customPlan.availableDevicesText',
    defaultMessage: 'The one you’re using right now.',
  },
  macPlanAvailableDevices: {
    id: 'pricePlans.metadata.macPlan.availableDevicesText',
    defaultMessage: 'You have {macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}} device in this plan.',
  },
  macPlusIosPlanAvailableDevices: {
    id: 'pricePlans.metadata.macPlusIosPlan.availableDevicesText',
    defaultMessage: 'You have {macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}} + {iosSeatsCount} iOS devices in this plan.',
  },
  twoMacsPlanAvailableDevices: {
    id: 'pricePlans.metadata.twoMacsPlan.availableDevicesText',
    defaultMessage: 'You have {macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}} in this plan. Crystal clear.',
  },
  powerUserPlanAvailableDevices: {
    id: 'pricePlans.metadata.powerUserPlan.availableDevicesText',
    defaultMessage: 'You have {macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}} + {iosSeatsCount} iOS devices {isSetappMobileAvailable, select, true {+ access to Setapp Mobile } other {}}in this plan.',
  },
  eduPlanAvailableDevices: {
    id: 'pricePlans.metadata.eduPlan.availableDevicesText',
    defaultMessage: '1 Mac for students and teachers. Annual only.',
  },
  familyPlanAvailableDevices: {
    id: 'pricePlans.metadata.familyPlan.availableDevicesText',
    defaultMessage: 'You have {macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}} {iosSeatsCount, select, 0 {} other {+ {iosSeatsCount, plural, one {1 iOS device} other {{iosSeatsCount} iOS devices}}}} for you +{maxMembersCount}.',
  },
  aiEnthusiastPlanAvailableDevices: {
    id: 'pricePlans.metadata.aiEnthusiastPlan.availableDevicesText',
    defaultMessage: 'You have {macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}} + {vendorApiUsageLimitInCredits} AI credits in this plan.',
  },
  aiExpertPlanAvailableDevices: {
    id: 'pricePlans.metadata.aiExpertPlan.availableDevicesText',
    defaultMessage: 'You have {macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}} + {iosSeatsCount} iOS devices + {vendorApiUsageLimitInCredits} AI credits in this plan.',
  },
  iosAdvancedAvailableDevices: {
    id: 'pricePlans.metadata.iosAdvancedPlan.availableDevicesText',
    defaultMessage: 'You have {iosSeatsCount} iOS devices + access to Setapp Mobile in this plan.',
  }
});

const billingPeriodTrialMessage = (
  <FormattedMessage
    id="pricePlans.metadata.billingPeriod.trial"
    defaultMessage="Trial plan"
  />
);

const billingPeriodMonthlyMessage = (
  <FormattedMessage
    id="pricePlans.metadata.billingPeriod.monthly"
    defaultMessage="Billed monthly"
  />
);

const billingPeriodAnnualMessage = (
  <FormattedMessage
    id="pricePlans.metadata.billingPeriod.annual"
    defaultMessage="Billed annually"
  />
);

const customPlanMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.customPlan.title"
      defaultMessage="Custom"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.customPlan.titleFull"
      defaultMessage="Custom plan"
    />
  ),
  availableDevicesMessage: messages.customPlanAvailableDevices,
};

const macPlanMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.macPlan.title"
      defaultMessage="Mac"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.macPlan.titleFull"
      defaultMessage="Mac plan"
    />
  ),
  availableDevicesMessage: messages.macPlanAvailableDevices,
  description: (
    <FormattedMessage
      id="pricePlans.metadata.macPlan.description"
      defaultMessage="Supercharge your beloved Mac with awesome apps."
    />
  ),
};

const macPlusIosPlanMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.macPlusIosPlan.title"
      defaultMessage="Mac + iOS"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.macPlusIosPlan.titleFull"
      defaultMessage="Mac + iOS plan"
    />
  ),
  availableDevicesMessage: messages.macPlusIosPlanAvailableDevices,
  description: (
    <FormattedMessage
      id="pricePlans.metadata.macPlusIosPlan.description"
      defaultMessage="If you do everything on your Mac, iPhone and iPad."
    />
  ),
};

const twoMacsPlanMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.twoMacsPlan.title"
      defaultMessage="Two Macs"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.twoMacsPlan.titleFull"
      defaultMessage="Two Macs plan"
    />
  ),
  availableDevicesMessage: messages.twoMacsPlanAvailableDevices,
};

const powerUserPlanMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.powerUserPlan.title"
      defaultMessage="Power User"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.powerUserPlan.titleFull"
      defaultMessage="Power User plan"
    />
  ),
  availableDevicesMessage: messages.powerUserPlanAvailableDevices,
  description: (
    <FormattedMessage
      id="pricePlans.metadata.powerUserPlan.description"
      defaultMessage="Got several Macs? Get this plan for maximum flexibility."
    />
  ),
};

const iosPlanMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
    id="pricePlans.metadata.iosPlan.title"
    defaultMessage="iOS"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.iosPlan.titleFull"
      defaultMessage="iOS User plan"
    />
  ),
  availableDevicesMessage: messages.customPlanAvailableDevices,
  description: (
    <FormattedMessage
      id="pricePlans.metadata.iosPlan.description"
      defaultMessage="For people who can’t imagine life without their iPhone and iPad."
    />
  ),
};

const eduPlanMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.eduPlan.title"
      defaultMessage="Education"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.eduPlan.titleFull"
      defaultMessage="Education plan"
    />
  ),
  availableDevicesMessage: messages.eduPlanAvailableDevices,
};

const familyPlanMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.familyPlan.title"
      defaultMessage="Family"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.familyPlan.titleFull"
      defaultMessage="Family plan"
    />
  ),
  availableDevicesMessage: messages.familyPlanAvailableDevices,
};

const aiEnthusiastMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.aiEnthusiastPlan.title"
      defaultMessage="AI Enthusiast"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.aiEnthusiastPlan.title"
      defaultMessage="AI Enthusiast"
    />
  ),
  description: (
    <FormattedMessage
      id="pricePlans.metadata.aiEnthusiastPlan.description"
      defaultMessage="For the open-minded willing to up their game with AI."
    />
  ),
  availableDevicesMessage: messages.aiEnthusiastPlanAvailableDevices,
};

const aiExpertMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.aiExpertPlan.title"
      defaultMessage="AI Expert"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.aiExpertPlan.title"
      defaultMessage="AI Expert"
    />
  ),
  description: (
    <FormattedMessage
      id="pricePlans.metadata.aiExpertPlan.description"
      defaultMessage="For power users looking to make AI their everyday helper."
    />
  ),
  availableDevicesMessage: messages.aiExpertPlanAvailableDevices,
};

const iosAdvancedMetadata: BasePricePlanMetadata = {
  title: (
    <FormattedMessage
      id="pricePlans.metadata.iosAdvancesPlan.title"
      defaultMessage="iOS Advanced"
    />
  ),
  titleFull: (
    <FormattedMessage
      id="pricePlans.metadata.iosAdvancesPlan.title"
      defaultMessage="iOS Advanced"
    />
  ),
  availableDevicesMessage: messages.iosAdvancedAvailableDevices,
};

export const PRICE_PLAN_METADATA_MAPPER: {[key: string]: PricePlanMetadata} = {
  // main plans
  [pricePlanTypes.MAC_MONTHLY]: {
    ...macPlanMetadata,
    tierType: pricePlanTypes.MAC_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.MAC_ANNUAL]: {
    ...macPlanMetadata,
    tierType: pricePlanTypes.MAC_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.MAC_TRIAL]: {
    ...macPlanMetadata,
    tierType: pricePlanTypes.MAC_TRIAL,
    billingPeriod: billingPeriodTrialMessage,
  },
  [pricePlanTypes.MAC_PLUS_IOS_MONTHLY]: {
    ...macPlusIosPlanMetadata,
    tierType: pricePlanTypes.MAC_PLUS_IOS_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.MAC_PLUS_IOS_ANNUAL]: {
    ...macPlusIosPlanMetadata,
    tierType: pricePlanTypes.MAC_PLUS_IOS_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.MAC_PLUS_IOS_TRIAL]: {
    ...macPlusIosPlanMetadata,
    tierType: pricePlanTypes.MAC_PLUS_IOS_TRIAL,
    billingPeriod: billingPeriodTrialMessage,
  },
  [pricePlanTypes.TWO_MACS_MONTHLY]: {
    ...twoMacsPlanMetadata,
    tierType: pricePlanTypes.TWO_MACS_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.TWO_MACS_ANNUAL]: {
    ...twoMacsPlanMetadata,
    tierType: pricePlanTypes.TWO_MACS_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.POWER_USER_MONTHLY]: {
    ...powerUserPlanMetadata,
    tierType: pricePlanTypes.POWER_USER_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.POWER_USER_ANNUAL]: {
    ...powerUserPlanMetadata,
    tierType: pricePlanTypes.POWER_USER_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.POWER_USER_TRIAL]: {
    ...powerUserPlanMetadata,
    tierType: pricePlanTypes.POWER_USER_TRIAL,
    billingPeriod: billingPeriodTrialMessage,
  },
  // others
  [pricePlanTypes.IOS_MONTHLY]: {
    ...iosPlanMetadata,
    tierType: pricePlanTypes.IOS_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.IOS_ANNUAL]: {
    ...iosPlanMetadata,
    tierType: pricePlanTypes.IOS_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.IOS_TRIAL]: {
    ...iosPlanMetadata,
    tierType: pricePlanTypes.IOS_TRIAL,
    billingPeriod: billingPeriodTrialMessage,
  },
  [pricePlanTypes.EDU_TRIAL]: {
    ...eduPlanMetadata,
    tierType: pricePlanTypes.EDU_TRIAL,
    billingPeriod: billingPeriodTrialMessage,
  },
  [pricePlanTypes.EDU_ANNUAL]: {
    ...eduPlanMetadata,
    tierType: pricePlanTypes.EDU_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.FAMILY_MONTHLY]: {
    ...familyPlanMetadata,
    tierType: pricePlanTypes.FAMILY_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.FAMILY_ANNUAL]: {
    ...familyPlanMetadata,
    tierType: pricePlanTypes.FAMILY_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.AI_ENTHUSIAST_MONTHLY]: {
    ...aiEnthusiastMetadata,
    tierType: pricePlanTypes.AI_ENTHUSIAST_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.AI_ENTHUSIAST_ANNUAL]: {
    ...aiEnthusiastMetadata,
    tierType: pricePlanTypes.AI_ENTHUSIAST_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_FULL]: {
    ...aiEnthusiastMetadata,
    tierType: pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_FULL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_PREV]: {
    ...aiEnthusiastMetadata,
    tierType: pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_PREV,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.AI_EXPERT_MONTHLY]: {
    ...aiExpertMetadata,
    tierType: pricePlanTypes.AI_EXPERT_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.AI_EXPERT_ANNUAL]: {
    ...aiExpertMetadata,
    tierType: pricePlanTypes.AI_EXPERT_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_FULL]: {
    ...aiExpertMetadata,
    tierType: pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_FULL,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_PREV]: {
    ...aiExpertMetadata,
    tierType: pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_PREV,
    billingPeriod: billingPeriodAnnualMessage,
  },
  [pricePlanTypes.IOS_ADVANCED_TRIAL]: {
    ...iosAdvancedMetadata,
    tierType: pricePlanTypes.IOS_ADVANCED_TRIAL,
    billingPeriod: billingPeriodTrialMessage,
  },
  [pricePlanTypes.IOS_ADVANCED_MONTHLY]: {
    ...iosAdvancedMetadata,
    tierType: pricePlanTypes.IOS_ADVANCED_MONTHLY,
    billingPeriod: billingPeriodMonthlyMessage,
  },
  [pricePlanTypes.IOS_ADVANCED_ANNUAL]: {
    ...iosAdvancedMetadata,
    tierType: pricePlanTypes.IOS_ADVANCED_ANNUAL,
    billingPeriod: billingPeriodAnnualMessage,
  },
};

export default function getPricePlanMetadata(pricePlan: PricePlan): PricePlanMetadata {
  const metadata = PRICE_PLAN_METADATA_MAPPER[pricePlan.tierType];

  if (metadata) {
    return metadata;
  }

  // fallback to custom plan
  return {
    ...customPlanMetadata,
    tierType: pricePlanTypes.CUSTOM,
    billingPeriod: pricePlan.paidMonth === 1 ? billingPeriodMonthlyMessage : billingPeriodAnnualMessage,
  };
}
