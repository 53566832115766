import { combineReducers } from 'redux';
import { createSelector } from '@reduxjs/toolkit';

import buildListReducer, { getList, ListState } from '../list-reducer-factory';
import * as giftCardsActionTypes from './gift-cards-action-types';
import type { GiftCardsAction } from './gift-cards-action-types';
import type { GiftCardStatus } from './gift-card-statuses';

export type GiftCardType = {
  id: number;
  paidMonths: number;
  price: number;
  currency: string;
};

export type GiftCard = {
  id: string;
  paidMonths: number;
  code: string;
  status: GiftCardStatus;
  orderDate: number;
  price: string; // TODO: change to number after SW-1970 is fixed
  currency: string;
  buyerInfo: {
    name: string;
    avatarUrl: string;
  } | null;
};

export type GiftCardTypesState = ListState<GiftCardType>;

export type PurchasedGiftCardsState = ListState<GiftCard>;

export type GiftCardsState = Readonly<{
  cardTypes: GiftCardTypesState;
  purchasedCards: PurchasedGiftCardsState;
}>;

const cardTypesReducer = buildListReducer<GiftCardType>({
  request: giftCardsActionTypes.CARD_TYPES_REQUEST,
  requestSuccess: giftCardsActionTypes.CARD_TYPES_REQUEST_SUCCESS,
  requestError: giftCardsActionTypes.CARD_TYPES_REQUEST_ERROR,
});

const purchasedCardsListReducer = buildListReducer<GiftCard>({
  request: giftCardsActionTypes.GIFT_CARDS_REQUEST,
  requestSuccess: giftCardsActionTypes.GIFT_CARDS_REQUEST_SUCCESS,
  requestError: giftCardsActionTypes.GIFT_CARDS_REQUEST_ERROR,
});

const purchasedCardsReducer = (state: PurchasedGiftCardsState, action: GiftCardsAction): PurchasedGiftCardsState => {
  switch (action.type) {
    case giftCardsActionTypes.GIFT_CARDS_UPDATE_ITEM: {
      const newGiftCard = action.payload;

      return {
        ...state,
        list: state.list.map((card) => (card.id === newGiftCard.id ? newGiftCard : card)),
      };
    }

    default: {
      return purchasedCardsListReducer(state, action);
    }
  }
};

export { purchasedCardsReducer };

export default combineReducers<GiftCardsState>({
  cardTypes: cardTypesReducer,
  purchasedCards: purchasedCardsReducer as typeof purchasedCardsListReducer,
});

export const getCardTypes = (state: GiftCardsState) => state.cardTypes;
export const getCardTypesList = createSelector(getCardTypes, getList);

export const getGiftCards = (state: GiftCardsState) => state.purchasedCards;
export const getGiftCardsList = createSelector(getGiftCards, getList);
export const getGiftCardsSortedList = createSelector(
  getGiftCardsList,
  (cards) => cards.sort((a, b) => b.orderDate - a.orderDate)
);
