import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';
import urls from 'config/urls';

import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';

import './delete-account.scss';

const DeleteAccount = () => (
  <div>
    <h5 className="delete-account-title">
      <FormattedMessage id="accountSettings.deleteAccount.title" defaultMessage="Delete account" />
    </h5>

    <p>
      <FormattedMessage
        id="accountSettings.deleteAccount.description"
        defaultMessage="Upon removal, your account becomes unavailable and all the data in it is deleted."
      />
    </p>

    <AccountControlButtons>
      <Button
        tag={Link}
        size="lg"
        to={urls.deleteAccount}
        variant="danger-outline"
        className="delete-account-btn"
      >
        <FormattedMessage id="accountSettings.deleteAccount.btn" defaultMessage="Delete account" />
      </Button>
    </AccountControlButtons>
  </div>
);

export default DeleteAccount;
