// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import RequestError from '@setapp/request-error';

import type { Node, AbstractComponent, Config } from 'react';

import { type ResolvedFeatureFlags } from 'utils/feature-flags';

import FormContainer from 'components/shared/form/form-container/form-container';

import { savePaymentMethod } from 'state/payment-method/payment-method-actions';
import { getPaymentMethod, isPaymentDetailsLoading, getUser, getFeatureFlags } from 'state/root-reducer';

import type { PaymentDetailsState } from 'state/payment-method/payment-method-initial-state';
import type { BraintreePaymentDetailsPayload, PaymentDetailsOptions } from 'services/payment-details-api/payment-details-api';
import type { PaymentFormConfig } from '../payment-form-config-type';

type PassThroughProps = {|
  submitBtnTitle: Node,
  loadingText: Node,
  paymentMethodOptions: {
    amount: number,
    currencyCode: string,
  },
|};

type DefaultProps = {|
  onSuccessSubmit: ?() => mixed,
  onFailedSubmit: ?(RequestError) => mixed,
|};

type OwnProps = {|
  ...PassThroughProps,
  ...DefaultProps,
  formConfig: PaymentFormConfig,
  paymentDetailsOptions: PaymentDetailsOptions,
  isFormConfigLoading: boolean,
  initializationError: Node,
  userLocale: string,
|};

type Props = {|
  ...OwnProps,
  paymentMethod: PaymentDetailsState,
  isPaymentDetailsLoading: boolean,
  savePaymentMethod: (BraintreePaymentDetailsPayload) => Promise<void>,
  featureFlags: ResolvedFeatureFlags,
|};

type InjectedProps = {|
  ...PassThroughProps,
  paymentInfo: BraintreePaymentDetailsPayload,
  brainTreeAuthToken: string,
  threeDSecureRequired: boolean,
  setLoading: boolean => mixed,
  setGenericError: Node => mixed,
  setErrorFields: ({[string]: Node}) => mixed,
  onFieldChange: (string, string) => mixed,
  onFormSubmit: () => Promise<mixed>,
  merchantId: string,
  isLoading: boolean,
  formError: Node,
  fieldsWithError: {
    [string]: Node,
  },
  isPaymentMethodCreated: boolean,
  captcha: Node,
  countries: $PropertyType<PaymentFormConfig, 'countries'>,
  userLocale: string,
  featureFlags: ResolvedFeatureFlags,
|};

const wrapComponent = (WrappedComponent: AbstractComponent<InjectedProps>): AbstractComponent<Props> => {
  class PaymentMethodFormWrapper extends Component<Props> {
    static defaultProps = {
      /**
       * Bug in eslint-plugin-react
       * TODO: remove both comments after updating to v7.17+
       */
      // eslint-disable-next-line react/default-props-match-prop-types
      onSuccessSubmit: null,
      // eslint-disable-next-line react/default-props-match-prop-types
      onFailedSubmit: null,
    };

    render() {
      const {
        formConfig: {
          token,
          threeDSecureRequired,
          additional: {
            merchantId,
          },
          countries,
        },
        paymentMethod,
        isPaymentDetailsLoading,
        userLocale,
        submitBtnTitle,
        paymentMethodOptions,
        isFormConfigLoading,
        initializationError,
        loadingText,
        featureFlags,
      } = this.props;

      let company = '',
        vatId = '',
        streetName = '',
        addressDetail = '',
        buildingNumber = '',
        city = '',
        postalCode = '';

      if (paymentMethod.data) {
        ({
          company,
          vatId,
          streetName,
          addressDetail,
          buildingNumber,
          city,
          postalCode,
        } = paymentMethod.data);
      }

      return (
        <FormContainer
          onSubmit={this.submitForm}
          initialValues={{
            country: '',
            nonce: '',
            company,
            vatId,
            streetName,
            addressDetail,
            buildingNumber,
            city,
            postalCode,
          }}
        >
          {
            ({
              fields,
              fieldsErrors,
              formError,
              formContainer,
              captcha,
              isProcessing: isFormProcessing,
            }) => <WrappedComponent
              merchantId={merchantId}
              threeDSecureRequired={threeDSecureRequired}
              isLoading={isFormConfigLoading || isPaymentDetailsLoading || isFormProcessing}
              setLoading={formContainer.setProcessing}
              fieldsWithError={fieldsErrors}
              formError={initializationError || formError}
              setGenericError={formContainer.setFormError}
              setErrorFields={formContainer.setFieldsErrors}
              onFieldChange={formContainer.setField}
              onFormSubmit={formContainer.submitForm}
              brainTreeAuthToken={token}
              countries={countries}
              paymentInfo={fields}
              isPaymentMethodCreated={Boolean(paymentMethod.data)}
              userLocale={userLocale}
              featureFlags={featureFlags}
              submitBtnTitle={submitBtnTitle}
              paymentMethodOptions={paymentMethodOptions}
              captcha={captcha}
              loadingText={loadingText}
                  />
          }
        </FormContainer>
      );
    }

    submitForm = (fields: BraintreePaymentDetailsPayload) => {
      const { paymentDetailsOptions, savePaymentMethod } = this.props;

      const payload = {
        ...fields,
        ...paymentDetailsOptions,
      };

      return savePaymentMethod(payload)
        .then(() => {
          const { onSuccessSubmit } = this.props;

          if (onSuccessSubmit) {
            onSuccessSubmit();
          }
        })
        .catch((error: RequestError) => {
          const { onFailedSubmit } = this.props;

          if (onFailedSubmit) {
            onFailedSubmit(error);
          }

          throw error;
        });
    }
  }

  return PaymentMethodFormWrapper;
};

const mapStateToProps = (state) => ({
  paymentMethod: getPaymentMethod(state),
  isPaymentDetailsLoading: isPaymentDetailsLoading(state),
  userLocale: getUser(state).locale,
  featureFlags: getFeatureFlags(state),
});

const mapActionsToProps = {
  savePaymentMethod,
};

export { wrapComponent as wrapWithoutStore };

type WrappedComponentType = AbstractComponent<InjectedProps>;
type ReturnedComponentType = AbstractComponent<Config<OwnProps, DefaultProps>>;

const paymentMethodFormWrapper = (WrappedComponent: WrappedComponentType): ReturnedComponentType => (
  connect(mapStateToProps, mapActionsToProps)(wrapComponent(WrappedComponent))
);

export default paymentMethodFormWrapper;
