import React, { Component, ReactElement } from 'react';
import type { ComponentType } from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import urls from 'config/urls';

import * as subscriptionStatuses from 'state/subscription/statuses';
import type { FetchAllSubscriptionsAction } from 'state/subscription/subscription-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import DefaultError from 'components/shared/default-error/default-error';
import SubscriptionActivated from 'components/shared/subscription-activated/subscription-activated';
import { getStoredCustomerOauthError } from 'components/pages/customer-oauth-page/utils/customer-oauth-storage';
import { CustomerOauthErrors } from 'components/pages/customer-oauth-page/utils/customer-oauth-errors';
import { getHasAppAccessIssue } from 'components/pages/app-access-issue/utils/app-access-issue-storage';
import { getIsInsideSetappMobileFlow } from 'components/pages/setapp-mobile/utils/setapp-mobile-flow-storage';
import { SETAPP_MOBILE_SUBSCRIPTION_NOTIF_QUERY } from 'components/pages/setapp-mobile/partials/onboarding-screen/onboarding-screen';

const DELAY_FOR_PENDING_PAYMENT = 3000;

type PaymentWaitingProps = {
  history: RouteComponentProps['history'];
  primarySubscription: Subscription;
  fetchAllSubscriptions: FetchAllSubscriptionsAction;
  showDangerNotification: (message: ReactElement) => void;
};

type State = {
  isSubscriptionActivated: boolean;
};

const customerOauthErrorsToWaitPayment = [
  CustomerOauthErrors.TRIAL_ALREADY_USED_ON_DEVICE,
  CustomerOauthErrors.CUSTOMER_SUBSCRIPTION_INACTIVE,
  CustomerOauthErrors.BLOCKED_CUSTOMER,
];

/**
 * @deprecated: better to use `useMultiplePaymentWaiting` hook.
 *
 * This HOC monitors the start of payment when the `subscription.paymentPending` state change
 * from `false` to `true`. After payment start HOC will wait for a specified amount of time then
 * re-fetch a subscription one time. If subscription is paid, show success screen. Otherwise
 * redirect user to the `/subscription` page where `PermanentNotificationContainer` component will
 * continue to wait for payment result.
 */
function withPaymentWaiting<T extends object>(WrappedComponent: ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  class WithPaymentWaiting extends Component<T & PaymentWaitingProps, State> {
    static displayName: string = `withPaymentWaiting(${displayName})`;

    waitForPaymentTimeoutId: ReturnType<typeof setTimeout> | null = null;

    isInsideAppAccessIssueFlow: boolean = false;
    isInsideCustomerOauthFlow: boolean = false;
    isFromSetappMobile: boolean = false;
    shouldWaitForFullActivation: boolean = false;

    state: State = {
      isSubscriptionActivated: false
    };

    componentDidMount() {
      const { primarySubscription } = this.props;

      const storedCustomerOauthError = getStoredCustomerOauthError();
      if (storedCustomerOauthError && customerOauthErrorsToWaitPayment.includes(storedCustomerOauthError)) {
        this.isInsideCustomerOauthFlow = true;
      }
      this.isFromSetappMobile = Boolean(getIsInsideSetappMobileFlow());
      this.isInsideAppAccessIssueFlow = Boolean(getHasAppAccessIssue());

      this.shouldWaitForFullActivation = this.isInsideCustomerOauthFlow
        || this.isFromSetappMobile
        || this.isInsideAppAccessIssueFlow;

      if (primarySubscription.paymentPending) {
        this.waitForPaymentSuccess();
      }
    }

    componentDidUpdate(prevProps: PaymentWaitingProps) {
      if (this.shouldWaitForPaymentSuccess(prevProps)) {
        this.waitForPaymentSuccess();
      }
    }

    componentWillUnmount() {
      if (this.waitForPaymentTimeoutId) clearTimeout(this.waitForPaymentTimeoutId);
    }

    render() {
      const { isSubscriptionActivated } = this.state;

      if (isSubscriptionActivated) {
        return <SubscriptionActivated />;
      }

      return <WrappedComponent {...this.props as T} />;
    }

    shouldWaitForPaymentSuccess(prevProps: PaymentWaitingProps) {
      const { primarySubscription } = this.props;
      if (!primarySubscription) {
        return false;
      }

      // In Customer Oauth and App Access Issue flows we should wait for full activation, not just pending payment
      if (this.shouldWaitForFullActivation && primarySubscription.status !== subscriptionStatuses.ACTIVE) {
        return true;
      }

      return (!prevProps.primarySubscription || !prevProps.primarySubscription.paymentPending)
        && primarySubscription.paymentPending;
    }

    waitForPaymentSuccess() {
      const { fetchAllSubscriptions, showDangerNotification } = this.props;

      // I would clear it even in usual flow, but don't want to break anything
      if (this.shouldWaitForFullActivation && this.waitForPaymentTimeoutId) {
        clearTimeout(this.waitForPaymentTimeoutId);
      }

      return new Promise<void>((resolve) => {
        this.waitForPaymentTimeoutId = setTimeout(async () => {
          await fetchAllSubscriptions();
          const { primarySubscription } = this.props;

          // Do not redirect to subscription page, wait for full activation if in
          // Customer Oauth/Access Issue/Onboarding Setapp Mobile flow
          if (this.shouldWaitForFullActivation) {
            if (primarySubscription.status !== subscriptionStatuses.ACTIVE) {
              return; // block other redirects if in flow
            }

            if (this.isInsideCustomerOauthFlow) {
              this.redirectToCustomerOauthPage();
            } else if (this.isInsideAppAccessIssueFlow) {
              this.redirectToAppAccessGrantedPage();
            } else if (this.isFromSetappMobile) {
              this.redirectToSetappMobilePage();
            }
          }

          // redirect to subscription page to continue to wait for payment result
          if (primarySubscription.paymentPending) {
            this.redirectToSubscriptionPage();
          // show success screen after success payment
          } else if (primarySubscription.status === subscriptionStatuses.ACTIVE) {
            this.setState({ isSubscriptionActivated: true });
          // otherwise show error and redirect to subscription page
          } else {
            showDangerNotification(<DefaultError />);
            this.redirectToSubscriptionPage();
          }

          resolve();
        }, DELAY_FOR_PENDING_PAYMENT);
      });
    }

    redirectToSubscriptionPage = () => {
      const { history } = this.props;

      history.replace(urls.subscription);
    };

    redirectToCustomerOauthPage = () => {
      const { history } = this.props;

      history.replace(urls.customerOauth);
    };

    redirectToSetappMobilePage = () => {
      const { history } = this.props;

      const setappMobileUrl = `${urls.setappMobile}?${SETAPP_MOBILE_SUBSCRIPTION_NOTIF_QUERY}=true`;

      history.push(setappMobileUrl);
    }

    redirectToAppAccessGrantedPage = () => {
      const { history } = this.props;

      history.replace(urls.appAccessGranted);
    };
  }

  return WithPaymentWaiting;
}

export default withPaymentWaiting;
