import React from 'react';
import { FormattedMessage } from 'react-intl';

import discoverIcon from './images/discover-icon.svg';
import updatesIcon from './images/updates-icon.svg';
import supportIcon from './images/support-icon.svg';

const SecondStepSecondaryContent = () => (
  <>
    <h4 className="redeem__secondary-title">
      <FormattedMessage
        id="redeem.secondary.title.codeSuccess"
        defaultMessage="Code redeemed! Now, sign up to Setapp to use the offer."
      />
    </h4>
    <div className="redeem__secondary-advantages">
      <div className="redeem__secondary-advantages-item">
        <img
          src={discoverIcon}
          alt="Discover icon"
        />
        <p className="redeem__secondary-advantages-item-caption">
          <FormattedMessage
            id="redeem.secondary.advantages.discoverApps"
            defaultMessage="Discover apps for better workflow"
          />
        </p>
      </div>
      <div className="redeem__secondary-advantages-item">
        <img
          src={updatesIcon}
          alt="Updates icon"
        />
        <p className="redeem__secondary-advantages-item-caption">
          <FormattedMessage
            id="redeem.secondary.advantages.getUpdates"
            defaultMessage="Get updates for installed apps"
          />
        </p>
      </div>
      <div className="redeem__secondary-advantages-item">
        <img
          src={supportIcon}
          alt="Support icon"
        />
        <p className="redeem__secondary-advantages-item-caption">
          <FormattedMessage
            id="redeem.secondary.advantages.reachSupport"
            defaultMessage="Reach out to 24/7 support"
          />
        </p>
      </div>
    </div>
  </>
);

export default SecondStepSecondaryContent;
