import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import DefaultError from 'components/shared/default-error/default-error';

import { showModal } from 'state/modal/modal-reducer';
import { resendConfirmationEmail } from 'state/user/user-actions';
import { showSuccessNotification, showDangerNotification } from 'state/notifier/notifier-reducer';

const useResendEmail = () => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const resendEmail = async (captcha: string | null = null) => {
    setIsProcessing(true);

    try {
      await dispatch(resendConfirmationEmail({ captcha }));

      dispatch(showSuccessNotification((
        <FormattedMessage
          id="useResendEmail.emailResent"
          defaultMessage="Confirmation email sent."
        />
      )));
    } catch (error: any) {
      const captchaError = error.getFieldError('captcha');
      const isCaptchaRequired = captchaError && captchaError.isRequired();

      if (isCaptchaRequired) {
        dispatch(showModal('RESEND_EMAIL_CAPTCHA', { onConfirm: resendEmail }));
      } else {
        dispatch(showDangerNotification(<DefaultError />));
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    resendEmail,
    isProcessing,
  };
};

export default useResendEmail;
