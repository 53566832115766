import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { DeviceIcon, DeviceIconUnused, Button } from '@setapp/ui-kit';

import type { Device } from 'state/devices/devices-initial-state';
import type { DeviceSeat } from 'services/devices-to-seats-mapper/devices-to-seats-mapper';

import './device-list-item.scss';

export type Props = {
  deviceSeat: DeviceSeat;
  onDisconnectClick: (device: Device) => void;
  platform: 'macos' | 'ios' | 'ios_eu';
};

class DeviceListItem extends PureComponent<Props> {
  render() {
    const {
      deviceSeat,
      platform,
    } = this.props;

    return (
      <div className="device-list-item">
        {deviceSeat.device ? (
          <>
            <div className="device-list-item__icon">
              <DeviceIcon device={deviceSeat.device.model} size={32} />
            </div>
            <div className="device-list-item__name mr-10">
              <div className="text_weight-medium">{deviceSeat.device.name}</div>
              <div className="text_color-secondary">
                <FormattedMessage
                  id="devicesPage.deviceListItem.lastUsed"
                  defaultMessage="Last used: {lastUsedDate}"
                  values={{
                    lastUsedDate: (
                      <FormattedDate
                        value={deviceSeat.device.lastUsedDate * 1000}
                        year="numeric"
                        month="short"
                        day="numeric"
                      />
                    ),
                  }}
                />
              </div>
            </div>
            <Button
              variant="link"
              onClick={this.handleDisconnectClick}
            >
              <FormattedMessage id="devicesPage.deviceListItem.disconnect" defaultMessage="Disconnect" />
            </Button>
          </>
        ) : (
          <>
            <div className="device-list-item__icon">
              <DeviceIconUnused size={32} isIos={platform !== 'macos'} />
            </div>
            <div className="text_weight-medium">
              {platform === 'macos' ? (
                <FormattedMessage id="devicesPage.deviceListItem.emptyMac" defaultMessage="Available Mac device" />
              ) : (
                <FormattedMessage id="devicesPage.deviceListItem.emptyIos" defaultMessage="Available iOS device" />
              )}
            </div>
          </>
        )}
      </div>
    );
  }

  handleDisconnectClick = () => {
    const { onDisconnectClick, deviceSeat } = this.props;

    if (deviceSeat.device) {
      onDisconnectClick(deviceSeat.device);
    }
  };
}

export default DeviceListItem;
