export type Device = Readonly<{
  id: number;
  lastUsedDate: number;
  expirationDate: number | null;
  model: string;
  name: string;
  platform?: 'macos' | 'ios' | 'ios_eu';
}>;

export type DevicesState = Readonly<{
  list: Array<Device>;
  isLoading: boolean;
}>;

const initialState: DevicesState = {
  list: [],
  isLoading: false,
};

export default initialState;
