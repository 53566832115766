import React from 'react';
import { FormattedMessage } from 'react-intl';

// TODO: add type definitions to @setapp/request-error and use them
type FieldError = {
  message: string;
  code?: string;
  isRequired: () => boolean;
  isInvalid: () => boolean;
  isConflicted: () => boolean;
};

type Props = {
  error: FieldError;
}

const CaptchaRequiredMessage = () => <FormattedMessage id="captchaError.required" defaultMessage="Captcha required" />;

const CaptchaError = ({ error }: Props) => {
  if (error.isRequired()) {
    return <CaptchaRequiredMessage />;
  }

  return <>{error.message}</>;
};

CaptchaError.RequiredMessage = CaptchaRequiredMessage;

export default CaptchaError;
