// @flow
import Authenticator from '@setapp/customer-authenticator';
import TokenManager from '@setapp/auth-tokens-manager';
import SignUpMetadataManager from '@setapp/signup-metadata';
import { apiRootV1 } from 'config/api';
import { COOKIES_ROOT_DOMAIN } from 'config/app';
import * as authConfig from 'config/auth';

import SubscriptionsApi from 'services/subscriptions/subscriptions-api';

const singletons = {};

function getSingleton<Instance>(name: string, makeInstance: () => Instance): Instance {
  if (!singletons[name]) {
    singletons[name] = makeInstance();
  }

  return singletons[name];
}

export const getTokenManager = () => getSingleton('TokenManager', () => (
  new TokenManager({
    cookiesDomain: COOKIES_ROOT_DOMAIN,
    secureCookies: authConfig.SECURE_COOKIES,
    rememberPeriod: authConfig.REMEMBER_ME_PERIOD,
    accessTokenName: authConfig.ACCESS_TOKEN_COOKIE,
    refreshTokenName: authConfig.REFRESH_TOKEN_COOKIE,
  })
));

export const getAuthenticator = () => getSingleton('Authenticator', () => (
  new Authenticator({
    apiRoot: apiRootV1,
    cookiesDomain: COOKIES_ROOT_DOMAIN,
    tokenManager: getTokenManager(),
  })
));

export const getSignupMetadata = () => getSingleton('SignUpMetadataManager', () => (
  new SignUpMetadataManager({
    cookiesDomain: COOKIES_ROOT_DOMAIN,
  })
));

export const getSubscriptionsApiClient = (): SubscriptionsApi => (
  getSingleton('SubscriptionsApi', () => new SubscriptionsApi())
);
