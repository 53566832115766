import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import DefaultError from 'components/shared/default-error/default-error';
import { showSuccessNotification, showDangerNotification } from 'state/notifier/notifier-reducer';
import { resumeAllSubscriptions } from 'state/subscription/subscription-actions';
import { hideModal, showModal } from 'state/modal/modal-reducer';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import { isPaymentMethodCreated as isPaymentMethodCreatedSelector } from 'state/root-reducer';

const useReactivateSubscription = () => {
  const dispatch = useDispatch();
  const isPaymentMethodCreated = useSelector(isPaymentMethodCreatedSelector);

  const onSuccess = () => {
    dispatch(showSuccessNotification(
      <FormattedMessage
        id="subscriptionInfo.reactivateCancelled.reactivateSuccess"
        defaultMessage="Your subscription is active again!"
      />
    ));
    dispatch(hideModal());
  };

  const onError = () => {
    dispatch(showDangerNotification(<DefaultError />));
  };

  const reactivate = async () => {
    try {
      await dispatch(resumeAllSubscriptions());
      onSuccess();
    } catch (error) {
      onError();
    }
  };

  const onReactivateButtonClick = () => {
    if (isPaymentMethodCreated) {
      reactivate();
    } else {
      dispatch(showModal('SET_PAYMENT_DETAILS', {
        onPaymentDetailsSaved: reactivate,
        title: <PaymentDetailsActionText action="add" />,
      }));
    }
  };

  return { onReactivateButtonClick };
};

export default useReactivateSubscription;
