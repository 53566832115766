import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import { showModal } from 'state/modal/modal-reducer';
import { type PricePlan } from 'state/price-plans/price-plans-initial-state';

const useSwitchToAiOffer = () => {
  const showModalAction = useDispatchAction(showModal);

  const openChangePlanModal = (selectedPlan: PricePlan) => {
    showModalAction('MANAGE_SUBSCRIPTION', {
      selectedStep: 'change_to_selected_plan',
      selectedNextPlan: selectedPlan,
    });
  };

  return {
    openChangePlanModal
  };
};

export default useSwitchToAiOffer;
