// @flow

type TaskFn = (void) => Promise<mixed>;
type Options = {|
  timeout: number,
  shouldContinue: (err: mixed, result: mixed) => boolean,
  delayFirstTask: boolean,
|};

const defaultOptions: Options = {
  timeout: 5000,
  // by default continue only on error
  shouldContinue: (error) => Boolean(error),
  delayFirstTask: false,
};

export default function createPoller(taskFn: TaskFn, options?: $Shape<Options>) {
  const { timeout, shouldContinue, delayFirstTask } = { ...defaultOptions, ...options };
  let timeoutId;
  let isStopped = true;
  let poll;

  function startPolling(): Promise<mixed> {
    isStopped = false;

    function schedulePoll() {
      timeoutId = setTimeout(poll, timeout);
    }

    return new Promise((resolve, reject) => {
      poll = () => {
        if (isStopped) {
          reject(null);

          return;
        }

        taskFn()
          .then((result) => {
            if (shouldContinue(null, result)) {
              schedulePoll();
            } else {
              resolve(result);
            }
          })
          .catch((error) => {
            if (shouldContinue(error)) {
              schedulePoll();
            } else {
              reject(error);
            }
          });
      };

      if (delayFirstTask) {
        schedulePoll();
      } else {
        poll();
      }
    });
  }

  function stopPolling() {
    if (isStopped) {
      return;
    }

    isStopped = true;

    // cancel pending task timeout
    clearTimeout(timeoutId);

    /**
     * Make main promise fail immediately.
     * Otherwise it will never be called as we cancelled pending timeout
     */
    poll();
  }

  return [startPolling, stopPolling];
}
