import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { AnimatedLogo } from '@setapp/ui-kit';

type Props = {
  children: ReactNode;
  withLogo?: boolean;
  wideContainer?: boolean;
};

const FullscreenLayoutPrimaryContent = (props: Props) => {
  const { children, withLogo = false, wideContainer = false } = props;
  const containerClasses = classNames('fullscreen-layout__primary-container', {
    'fullscreen-layout__primary-container_with-logo': withLogo,
    'fullscreen-layout__primary-container_wide': wideContainer,
  });

  return (
    <div className="fullscreen-layout__primary-content">
      <div className={containerClasses} data-qa="fullscreenLayoutContainer">
        {withLogo && (
          <div className="fullscreen-layout__setapp-logo">
            <AnimatedLogo size="sm" hasCaption />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default FullscreenLayoutPrimaryContent;
