import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import './password-requirements-popup.scss';

type Props = {
  isMatchMinLength: boolean;
  isContainsLower: boolean;
  isContainsUpper: boolean;
  showErrorState: boolean;
  showPopup: boolean;
  position?: 'left' | 'right';
  className?: string;
}

const PasswordRequirementsPopup = ({
  isMatchMinLength,
  isContainsUpper,
  isContainsLower,
  showErrorState,
  showPopup,
  position,
  className,
}: Props) => {
  const minLengthClasses = classNames([
    'password-requirements-popup__item',
    { 'password-requirements-popup__item_valid': isMatchMinLength },
    { 'password-requirements-popup__item_invalid': !isMatchMinLength && showErrorState },
  ]);

  const atLeastOneUpperClasses = classNames([
    'password-requirements-popup__item',
    { 'password-requirements-popup__item_valid': isContainsUpper },
    { 'password-requirements-popup__item_invalid': !isContainsUpper && showErrorState },
  ]);

  const atLeastOneLowerClasses = classNames([
    'password-requirements-popup__item',
    { 'password-requirements-popup__item_valid': isContainsLower },
    { 'password-requirements-popup__item_invalid': !isContainsLower && showErrorState },
  ]);

  const listClasses = classNames(
    'password-requirements-popup__tooltip',
    {
      'password-requirements-popup__tooltip_active': showPopup,
    },
  );

  const popupClasses = classNames(
    'password-requirements-popup',
    className,
    {
      'password-requirements-popup_left': position === 'left',
    },
  );

  return (
    <div className={popupClasses}>
      <div className={listClasses}>
        <ul className="password-requirements-popup__list">
          <li className={minLengthClasses}>
            <FormattedMessage
              id="passwordRequirementsPopup.minLengthRequirement"
              defaultMessage="8 characters minimum."
            />
          </li>
          <li className={atLeastOneUpperClasses}>
            <FormattedMessage
              id="passwordRequirementsPopup.atLeastOneUpperRequirement"
              defaultMessage="At least 1 uppercase letter."
            />
          </li>
          <li className={atLeastOneLowerClasses}>
            <FormattedMessage
              id="passwordRequirementsPopup.atLeastOneLowerRequirement"
              defaultMessage="At least 1 lowercase letter."
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default injectIntl<Props>(PasswordRequirementsPopup);
