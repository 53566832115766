import { AnyAction } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ListState<T = any> = Readonly<{
  list: Array<T>;
  isLoading: boolean;
  error: Error | null;
}>;

type ListActionsKeys = {
  request: string;
  requestSuccess: string;
  requestError: string;
}

const initialState: ListState = {
  list: [],
  isLoading: false,
  error: null,
};

/**
 * Returns simple list reducer
 * @param {object} actions
 * @param {string} actions.request
 * @param {string} actions.requestSuccess
 * @param {string} actions.requestError
 * @returns {function({object}=, {object})}
 */
export default function buildListReducer<T>(actions: ListActionsKeys) {
  return (state: ListState<T> = initialState, action: AnyAction): ListState<T> => {
    switch (action.type) {
      case actions.request: {
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      }
      case actions.requestSuccess: {
        if (!action.payload) {
          return { ...state, isLoading: false };
        }

        return {
          ...state,
          list: action.payload,
          isLoading: false,
        };
      }
      case actions.requestError: {
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      }
      default: {
        return state;
      }
    }
  };
}

export const getList = <State extends ListState>(state: State) => state.list as State['list'];
