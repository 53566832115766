import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { FamilyStatus } from 'state/family-plan/family-plan-statuses';
import type { MemberStatus } from './family-member-statuses';

export type FamilyMember = {
  id: number;
  email: string;
  name: string;
  avatarUrl: string;
  status: MemberStatus;
  isCurrentUser: boolean;
  isOwner: boolean;
};

export type FamilyPlan = {
  id: number | null;
  status: FamilyStatus | null;
  seats: number;
  switchAmount: number;
  members: Array<FamilyMember>;
  price: PricePlan | null;
  isLoading: boolean;
  isTeam: boolean;
  error: Error | null;
};

export type FamilyPlanState = Readonly<FamilyPlan>;

const initialState: FamilyPlanState = {
  id: null,
  status: null,
  seats: 0,
  switchAmount: 0,
  members: [],
  price: null,
  isLoading: false,
  isTeam: false,
  error: null,
};

export default initialState;
