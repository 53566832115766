import React from 'react';
import { FormattedMessage } from 'react-intl';

import BasePermissionItem from './base-permission-item/base-permission-item';
import macbookIcon from './images/macbook.svg';

type Props = {
  appName: string;
}

const PermissionAppDataItem = ({ appName }: Props) => (
  <BasePermissionItem
    icon={macbookIcon}
    title={<FormattedMessage
      id="oauth.permission.appData.title"
      defaultMessage="Application data"
           />}
    subTitle={<FormattedMessage
      id="oauth.permission.appData.subTitle"
      defaultMessage="{appName} will check if your Setapp subscription is active, and count how often do you use the app (this statistics is shared with Setapp)"
      values={{ appName }}
              />}
    qaLabel="permissionItemAppData"
  />
);

export default PermissionAppDataItem;
