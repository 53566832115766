import copy from 'copy-to-clipboard';
import React, { PureComponent, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from '@setapp/ui-kit';

import * as giftCardsStatuses from 'state/gift-cards/gift-card-statuses';
import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import type { GiftCardStatus as GiftCardStatusType } from 'state/gift-cards/gift-card-statuses';

import InfoField from 'components/shared/info-field/info-field';
import PanelBox from 'components/shared/panel-box/panel-box';

import GiftCard from '../gift-card/gift-card';

import GiftCardStatus from './gift-card-status/gift-card-status';

import './gift-card-purchased.scss';

type Props = {
  paidMonths: number;
  code: string;
  status: GiftCardStatusType;
  showDangerNotification: (message: ReactElement) => void;
  showSuccessNotification: (message: ReactElement) => void;
};

class PurchasedGiftCard extends PureComponent<Props> {
  render() {
    const { paidMonths, status, code } = this.props;
    const isPending = status === giftCardsStatuses.PENDING;

    return (
      <PanelBox fullHeight>
        <PanelBox.Content>
          <GiftCard paidMonths={paidMonths} isActive={!isPending} />

          {!isPending && (
            <InfoField
              vertical
              title={<FormattedMessage id="giftCard.codeTitle" defaultMessage="Code" />}
              value={(
                <span data-qa={code}>
                  {code}
                  <Button
                    variant="link"
                    className="btn-link-no-spacing gift-card-purchased__copy-code-btn"
                    onClick={this.onCopyCodeClick}
                  >
                    <FormattedMessage id="giftCard.copyCodeBtn" defaultMessage="Copy" />
                  </Button>
                </span>
              )}
            />
          )}

          <InfoField
            vertical
            title={<FormattedMessage id="giftCard.statusTitle" defaultMessage="Status" />}
            value={<GiftCardStatus status={status} />}
            qaLabel={status}
          />
        </PanelBox.Content>
      </PanelBox>
    );
  }

  onCopyCodeClick = () => {
    const { code } = this.props;
    const { showSuccessNotification, showDangerNotification } = this.props;

    const isLinkCopied = copy(code);

    if (isLinkCopied) {
      showSuccessNotification(
        <FormattedMessage
          id="giftCard.codeCopiedNotification"
          defaultMessage="Code successfully copied"
        />
      );
    } else {
      showDangerNotification(
        <FormattedMessage
          id="giftCard.codeCopyError"
          defaultMessage="Oh, something went wrong. Please copy the code manually"
        />
      );
    }
  }
}

export { PurchasedGiftCard as PurePurchasedGiftCard };

const mapActionsToProps = {
  showDangerNotification,
  showSuccessNotification,
};

export default connect(
  null,
  mapActionsToProps,
)(PurchasedGiftCard);
