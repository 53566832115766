import React from 'react';

import './app-layout-loading.scss';

const AppLayoutLoading = () => (
  <div className="app-layout-loading">
    <div className="app-layout-loading__title mt-2" />
    <div className="app-layout-loading__subtitle mt-10" />
    <div className="app-layout-loading__text mt-7" />
  </div>
);

export default AppLayoutLoading;
