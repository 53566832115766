import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getPricePlansList,
  getPrimarySubscription,
  isFreeUser as isFreeUserSelector
} from 'state/root-reducer';
import {
  isIosAdvancedPricePlan,
  isPowerUserPricePlan,
  isAiExpertPricePlan,
  isIosOnlyPricePlan,
  isAiEnthusiastPricePlan,
  isFamilyPricePlan
} from 'services/price-plans/utils';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';

interface UpgradeSearchResult {
  isUpgradeSearchFinished: boolean;
  currentPricePlan?: PricePlan;
  planForUpgrade?: PricePlan;
}

const useUpgradePlanSetappMobile = (): UpgradeSearchResult => {
  const fetchPricePlansAction = useDispatchAction(fetchPricePlans);
  const fetchSubscriptionAction = useDispatchAction(fetchAllSubscriptions);

  const [result, setResult] = useState<UpgradeSearchResult>({ isUpgradeSearchFinished: false });
  const isFreeUser = useSelector(isFreeUserSelector);
  const pricePlans = useSelector(getPricePlansList);
  const primarySubscription = useSelector(getPrimarySubscription);

  useEffect(() => {
    if (!primarySubscription) {
      fetchSubscriptionAction();
      setResult({ isUpgradeSearchFinished: false });

      return;
    }

    if (!pricePlans.length) {
      fetchPricePlansAction();
      setResult({ isUpgradeSearchFinished: false });

      return;
    }

    const currentPricePlan = primarySubscription.nextPricePlan ?? primarySubscription.pricePlan;
    const { tierType, paidMonth } = currentPricePlan;
    const isCurrentAnnual = paidMonth === 12;

    const isIosAdvanced = isIosAdvancedPricePlan(tierType);
    const isPowerUser = isPowerUserPricePlan(tierType);
    const isAiExpert = isAiExpertPricePlan(tierType);
    const isFamilyPlan = isFamilyPricePlan(currentPricePlan);

    const isAiEnthusiast = isAiEnthusiastPricePlan(tierType);
    const isIosOnly = isIosOnlyPricePlan(tierType);

    if (isIosAdvanced || isPowerUser || isAiExpert || isFreeUser || isFamilyPlan) {
      setResult({ isUpgradeSearchFinished: true });

      return;
    }

    let tierTypeForUpgrade = isCurrentAnnual ? pricePlanTypes.POWER_USER_ANNUAL : pricePlanTypes.POWER_USER_MONTHLY;

    if (isIosOnly) {
      tierTypeForUpgrade = isCurrentAnnual ? pricePlanTypes.IOS_ADVANCED_ANNUAL : pricePlanTypes.IOS_ADVANCED_MONTHLY;
    }

    if (isAiEnthusiast) {
      tierTypeForUpgrade = isCurrentAnnual ? pricePlanTypes.AI_EXPERT_ANNUAL : pricePlanTypes.AI_EXPERT_MONTHLY;
    }

    const planForUpgrade = pricePlans.find((plan) => plan.tierType === tierTypeForUpgrade);

    setResult({
      currentPricePlan,
      planForUpgrade,
      isUpgradeSearchFinished: true,
    });
  }, [primarySubscription, pricePlans, isFreeUser]);

  return result;
};

export default useUpgradePlanSetappMobile;
