import uniq from 'uniq';
import cookies from 'js-cookie';
import { COOKIES_ROOT_DOMAIN } from 'config/app';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE, LOCALE_ALIASES } from 'config/locales';

const LOCALE_COOKIE = 'locale'; // also used on Setapp site. Change carefully.

/**
 * @param {string} alias
 * @return {string|null}
 */
const getLocaleByAlias = (alias) => LOCALE_ALIASES[alias] || null;

/**
 * Returns normalized browser's locales
 * @return {array}
 */
const getBrowserLocales = () => {
  const locales = []
    .concat(
      navigator.languages,
      navigator.language,
      navigator.userLanguage,
      navigator.browserLanguage,
      navigator.systemLanguage,
    )
    .filter((locale) => locale)
    .map((locale) => getLocaleByAlias(locale) || locale);

  return uniq(locales, null, true);
};

/**
 * Returns first match between browser's and supported locales or default locale if there's no intersections.
 * @param {array} proposedLocales
 * @returns {string}
 */
const pickLocale = (proposedLocales) => {
  const browserLocales = getBrowserLocales();
  let result = null;

  for (let i = 0; i < browserLocales.length && result === null; i++) {
    if (proposedLocales.indexOf(browserLocales[i]) !== -1) {
      result = browserLocales[i];
    }
  }

  if (result === null) {
    result = DEFAULT_LOCALE;
  }

  return result;
};

/**
 * Saves given locale in cookies on top-level domain.
 * @param {string} locale
 */
export const setCookieLocale = (locale) => {
  const oneYear = 365;

  cookies.set(LOCALE_COOKIE, locale, {
    domain: COOKIES_ROOT_DOMAIN,
    expires: oneYear,
  });
};

/**
 * Return saved locale if present or detects it based on supported locales and browser's preferences
 * @returns {string}
 */
export const detectLocale = () => cookies.get(LOCALE_COOKIE) || pickLocale(SUPPORTED_LOCALES);

export const loadLocaleData = (locale) => {
  const [language] = locale.split('-');

  return Promise.all([
    /**
     * Dynamic imports with the template literals cause exceptions during linting
     * See https://github.com/babel/babel-eslint/issues/799
     */
    /* eslint-disable prefer-template */
    import('react-intl/locale-data/' + language),
    import('../lang/translations/' + locale),
    /* eslint-enable */
  ]);
};

/**
 * Converts browser's locale format for the server
 * @param {string} locale
 * @returns {string}
 */
export const browserToServerLocale = (locale) => locale.replace('-', '_');

/**
 * Converts server's locale format for the browser
 * @param {string} locale
 * @returns {string}
 */
export const serverToBrowserLocale = (locale) => locale.replace('_', '-');
