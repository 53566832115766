// @flow

import React from 'react';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';

import { getActivePricePlanByType, getMonthlyPlan } from 'state/root-reducer';

import * as PricePlanType from 'state/price-plans/price-plans-types';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

type Props = {
  currentPricePlan: PricePlan,
  monthlyPricePlan: ?PricePlan,
  annualPricePlan: ?PricePlan,
};

const calculateDiscount = (regularPrice, currentPrice) => (
  Math.round(((regularPrice - currentPrice) / regularPrice) * 100)
);

const ActivatePagePlanDescription = ({ monthlyPricePlan, currentPricePlan, annualPricePlan }: Props) => {
  if (!monthlyPricePlan || !currentPricePlan || !annualPricePlan) {
    return null;
  }

  const regularMonthlyPrice = monthlyPricePlan.price;
  const currentMonthlyPrice = currentPricePlan.price / currentPricePlan.paidMonth;
  const regularAnnualPricePerMonth = annualPricePlan.price / annualPricePlan.paidMonth;

  const currentAnnualDiscountPerMonth = calculateDiscount(currentMonthlyPrice, regularAnnualPricePerMonth);
  const regularAnnualDiscountPerMonth = calculateDiscount(regularMonthlyPrice, regularAnnualPricePerMonth);
  const defaultDiscountPerMonth = calculateDiscount(regularMonthlyPrice, currentMonthlyPrice);


  switch (currentPricePlan.tierType) {
    case PricePlanType.MAC_MONTHLY:
    case PricePlanType.MAC_PLUS_IOS_MONTHLY:
    case PricePlanType.TWO_MACS_MONTHLY:
    case PricePlanType.POWER_USER_MONTHLY:
    case PricePlanType.MONTHLY: {
      return (
        <FormattedMessage
          id="activatePage.planDescription.monthly"
          defaultMessage="Or save {discount}% annually"
          values={{ discount: currentAnnualDiscountPerMonth }}
        />
      );
    }

    case PricePlanType.MAC_ANNUAL:
    case PricePlanType.MAC_PLUS_IOS_ANNUAL:
    case PricePlanType.TWO_MACS_ANNUAL:
    case PricePlanType.POWER_USER_ANNUAL:
    case PricePlanType.ANNUAL: {
      return (
        <FormattedMessage
          id="activatePage.planDescription.annual"
          defaultMessage="You save {discount}% a year"
          values={{ discount: regularAnnualDiscountPerMonth }}
        />
      );
    }

    case PricePlanType.OLD_FAMILY_MONTHLY:
    case PricePlanType.OLD_FAMILY_ANNUAL: {
      return (
        <FormattedMessage id="activatePage.planDescription.family" defaultMessage="Setapp with those you love most" />
      );
    }

    case PricePlanType.EDU: {
      return (
        <FormattedMessage id="activatePage.planDescription.edu" defaultMessage="Study is fun with all tools at hand" />
      );
    }

    default:
      return (
        <FormattedMessage
          id="activatePage.planDescription.custom"
          defaultMessage="You save {discount}%"
          values={{ discount: defaultDiscountPerMonth }}
        />
      );
  }
};


const mapStateToProps = (state) => ({
  annualPricePlan: getActivePricePlanByType(state, PricePlanType.ANNUAL),
  monthlyPricePlan: getMonthlyPlan(state),
});

export { ActivatePagePlanDescription as PureActivatePagePlanDescription };

export default connect(
  mapStateToProps,
  null,
)(ActivatePagePlanDescription);
