import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { getSupportUrl } from 'utils/support';

import './auth-desktop-error.scss';

const AuthDesktopError = () => (
  <div className="auth-desktop-error text-center">
    <h3 className="mb-10">
      <FormattedMessage
        id="authDesktopError.title"
        defaultMessage="Hmm, server didn’t respond."
      />
    </h3>
    <Button
      block
      size="lg"
      className="auth-desktop-error__button"
      onClick={() => window.location.reload()}
    >
      <FormattedMessage id="authDesktopError.reloadLink" defaultMessage="Try again" />
    </Button>
    <div className="mt-10">
      <FormattedMessage
        id="authDesktopError.subTitle"
        defaultMessage="Error persists?"
      />
      {' '}
      <a href={getSupportUrl()} target="_blank" rel="noopener noreferrer">
        <FormattedMessage
          id="authDesktopError.supportLinkText"
          defaultMessage="Ask our Support Team"
        />
      </a>
    </div>
  </div>
);

export default AuthDesktopError;
