import React from 'react';
import { FormattedMessage } from 'react-intl';
import PanelBox from 'components/shared/panel-box/panel-box';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

const FamilyPlanPending = () => (
  <PanelBox>
    <PanelBox.Content>
      <AnimatedLogo animate />
      <div className="h4">
        <FormattedMessage id="familyPlan.switchToFamilyPlan.paymentPending" defaultMessage="Your payment is on its way. We'll email you once it's through." />
      </div>
    </PanelBox.Content>
  </PanelBox>
);

export default FamilyPlanPending;
