import React, { type FC, type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import urls from 'config/urls';

import { logout } from 'state/user/user-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import DefaultError from 'components/shared/default-error/default-error';

import './not-allowed.scss';

type NotAllowedType = 'family' | 'new' | 'free';

const COPY_MAPPER: Record<NotAllowedType, {
  title: ReactNode;
  description: ReactNode;
  btnHref: string;
  btnCaption: ReactNode;
}> = {
  family: {
    title:
      <FormattedMessage
        id="aiOffer.notAllowed.family.title"
        defaultMessage="Sorry, AI+ plans are for personal use only"
      />,
    description:
      <FormattedMessage
        id="aiOffer.notAllowed.family.description"
        defaultMessage="If you’re part of a Family plan, you need to switch to a personal plan first."
      />,
    btnHref: urls.account,
    btnCaption:
      <FormattedMessage
        id="aiOffer.notAllowed.family.btnCaption"
        defaultMessage="Go to my account"
      />
  },
  new: {
    title:
      <FormattedMessage
        id="aiOffer.notAllowed.new.title"
        defaultMessage="Sorry, you need to start your trial first"
      />,
    description:
      <FormattedMessage
        id="aiOffer.notAllowed.new.description"
        defaultMessage="Please note that Setapp AI+ plans are not available to Families and Teams at the moment."
      />,
    btnHref: urls.activateSubscription,
    btnCaption:
      <FormattedMessage
        id="aiOffer.notAllowed.new.btnCaption"
        defaultMessage="Choose plan"
      />
  },
  free: {
    title:
      <FormattedMessage
        id="aiOffer.notAllowed.free.title"
        defaultMessage="Sorry, AI+ requires a paid membership"
      />,
    description:
      <FormattedMessage
        id="aiOffer.notAllowed.free.description"
        defaultMessage="We can't offer you AI+ plan because you have a free Setapp plan."
      />,
    btnHref: urls.subscription,
    btnCaption:
      <FormattedMessage
        id="aiOffer.notAllowed.free.btnCaption"
        defaultMessage="OK"
      />
  },
};

interface Props {
  type: NotAllowedType;
}

const NotAllowed: FC<Props> = ({ type }) => {
  const dispatch = useDispatch();
  const { title, description, btnHref, btnCaption } = COPY_MAPPER[type];

  const handleLogoutClick = () => {
    try {
      dispatch(logout());
    } catch {
      dispatch(showDangerNotification(<DefaultError />));
    }
  };

  return (
    <FullscreenLayout withLogo>
      <div className="not-allowed">
        <h3 className="mb-6">{title}</h3>
        <p className="mb-8">{description}</p>
        <Button
          as={Link}
          href={btnHref}
          block
        >
          {btnCaption}
        </Button>
        <p className="mt-6">
          <FormattedMessage
            id="aiOffer.notAllowed.note"
            defaultMessage="Already have a personal Setapp account?"
          />
          {' '}
          <button className="btn btn_link" onClick={handleLogoutClick}>
            <FormattedMessage
              id="aiOffer.notAllowed.note.signIn"
              defaultMessage="Sign in"
            />
          </button>
        </p>
      </div>
    </FullscreenLayout>
  );
};

export default NotAllowed;
