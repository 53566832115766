import { createSelector } from '@reduxjs/toolkit';
import { State } from 'state/configure-store';
import { TrialStatusState } from './trial-status-types';

export const getTrialStatus = (state: State) => state.trialStatus;

export const getTrialAvailabilityStatus = createSelector(
  getTrialStatus,
  (state: TrialStatusState) => state.data?.status,
);

export const getTrialAvailabilityDescription = createSelector(
  getTrialStatus,
  (state: TrialStatusState) => state.data?.description,
);

export const getTrialAvailabilityIsLoading = createSelector(
  getTrialStatus,
  (state: TrialStatusState) => state.status === 'loading',
);

export const getTrialAvailabilityIsFetched = createSelector(
  getTrialStatus,
  (state: TrialStatusState) => state.status === 'succeeded',
);

export const getTrialAvailabilityError = createSelector(
  getTrialStatus,
  (state: TrialStatusState) => state.error
);
