import React, { Component } from 'react';
import type { ReactNode, SyntheticEvent } from 'react';
import Collapse from 'react-bootstrap/lib/Collapse';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import classnames from 'classnames';

import './expandable.scss';

type Props = {
  label: ReactNode;
  onExpandableClick: () => void;
  children: ReactNode;
  isOpen: boolean;
  help: string;
  type: 'link' | 'checkbox';
  controlPosition: 'top' | 'bottom';
};

// TODO: make uncontrolled. Handle opening state inside
class Expandable extends Component<Props> {
  static defaultProps = {
    type: 'link',
    isOpen: false,
    help: '',
    controlPosition: 'top',
  };

  render() {
    const {
      children,
      help,
      isOpen,
      controlPosition,
    } = this.props;
    const expandableClasses = classnames('expandable', {
      'expandable-show': isOpen,
    });

    const expandableControl = (
      <div className="expandable-title">
        {help && (
          <span className="help-text">
            {help}
            {' '}
          </span>
        )}
        {this.getTitle()}
      </div>
    );

    return (
      <div className={expandableClasses}>
        {controlPosition === 'top' && expandableControl}
        <Collapse in={isOpen}>
          <div>
            {children}
          </div>
        </Collapse>
        {controlPosition === 'bottom' && expandableControl}
      </div>
    );
  }

  onTitleClick = (e: SyntheticEvent<HTMLAnchorElement | HTMLInputElement>) => {
    const { type, onExpandableClick } = this.props;

    if (type === 'link') {
      e.preventDefault();
    }

    onExpandableClick();
  };

  getTitle() {
    const { type, isOpen, label } = this.props;

    return type === 'link' ? (
      // eslint-disable-next-line jsx-a11y/no-redundant-roles,jsx-a11y/interactive-supports-focus,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events
      <a
        className="expandable-link"
        onClick={this.onTitleClick}
        role="button"
        aria-expanded={isOpen}
      >
        {label}
        {' '}
        <span className="arrow" />
      </a>
    ) : (
      <Checkbox
        onChange={this.onTitleClick}
        role="button"
        checked={isOpen}
        className="expandable__checkbox"
      >
        {label}
      </Checkbox>
    );
  }
}

export default Expandable;
