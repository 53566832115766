// eslint-disable-next-line import/prefer-default-export
export const CustomerOauthErrors = {
  // no free seats
  NOT_ENOUGH_SEATS: 'NOT_ENOUGH_SEATS',
  // customer subscription doesn’t allow Setapp Mobile store usage, like Teams or Family
  APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION: 'APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION',
  // blocked subscription, payment is stuck
  BLOCKED_CUSTOMER: 'BLOCKED_CUSTOMER',
  // canceled subscription and no days left, can either renew or choose a new plan
  CUSTOMER_SUBSCRIPTION_INACTIVE: 'CUSTOMER_SUBSCRIPTION_INACTIVE',
  // “new” customer if the trial plan isn’t EU or payment info isn’t set or payment info isn’t in EUR
  EMPTY_PAYMENT_DETAILS: 'EMPTY_PAYMENT_DETAILS',
  // “new” customer if the trial plan doesn’t have access to EU marketplace, should choose a new plan
  APP_ACCESS_FORBIDDEN_FOR_TRIAL_SUBSCRIPTION: 'APP_ACCESS_FORBIDDEN_FOR_TRIAL_SUBSCRIPTION',
  // “new” customer if the trial was already used on the device or other credit card. Then he is like just "new" user
  TRIAL_ALREADY_USED_ON_DEVICE: 'TRIAL_ALREADY_USED_ON_DEVICE',
  // customer has active plan that doesn't allow Setapp Mobile store usage
  OFFER_UPGRADE: 'OFFER_UPGRADE', // SHOW PRICE PLAN CARD, show Switch to {plan}/View plans button
  // general errors
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};
