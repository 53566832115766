import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import type { Dispatch } from 'state/state-types';
import initialState, { NotifierState, Notification } from './notifier-initial-state';

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification(state, action: PayloadAction<Notification>) {
      state.list.push(action.payload);
    },
    clearAllNotifications(state) {
      state.list = [];
    },
    removeNotificationById(state, action: PayloadAction<string>) {
      state.list = state.list.filter((notification) => notification.id !== action.payload);
    },
  },
});

export default notificationSlice.reducer;

const {
  addNotification,
  clearAllNotifications,
  removeNotificationById,
} = notificationSlice.actions;

export const getList = (state: NotifierState) => state.list;

type NotificationMessage = Notification['message'];
type NotificationOptions = Omit<Notification, 'id' | 'type' | 'message'>;

export const removeNotification = (id: string) => (dispatch: Dispatch) => {
  dispatch(removeNotificationById(id));
};

export const showNotification = (notification: Omit<Notification, 'id'>) => (dispatch: Dispatch) => {
  const notificationId = uuidv4();

  dispatch(addNotification({ ...notification, id: notificationId }));

  if (!notification.permanent) {
    const defaultDelay = 7000;

    setTimeout(() => {
      dispatch(removeNotification(notificationId));
    }, notification.delay || defaultDelay);
  }

  return notificationId;
};

export function showInfoNotification(message: NotificationMessage, options?: NotificationOptions) {
  return showNotification({ ...options, message, type: 'info' });
}

export function showWarningNotification(message: NotificationMessage, options?: NotificationOptions) {
  return showNotification({ ...options, message, type: 'warning' });
}

export function showDangerNotification(message: NotificationMessage, options?: NotificationOptions) {
  return showNotification({ ...options, message, type: 'danger' });
}

export function showSuccessNotification(message: NotificationMessage, options?: NotificationOptions) {
  return showNotification({ ...options, message, type: 'success' });
}

export const clearNotifications = () => (dispatch: Dispatch) => {
  dispatch(clearAllNotifications());
};
