/* istanbul ignore file */

import React from 'react';
import { Button } from '@setapp/ui-kit';

import analytics, { events } from 'utils/analytics';

import Modal from 'components/shared/modal/modal';

import './install-desktop-remainder-modal.scss';

import download from './images/download.png';
import download2x from './images/download@2x.png';
import download3x from './images/download@3x.png';

type Props = {
  show: boolean;
  onExited: () => void;
  onHide: () => void;
};

const InstallDesktopRemainderModal = (props: Props) => {
  const { show, onExited, onHide } = props;

  const handleHide = () => {
    analytics.trackEvent(events.DESKTOP_CCR_TEST_POPUP_CLICK, { eventLabel: 'Close' });
    onHide();
  };

  const handleButtonClick = () => {
    analytics.trackEvent(events.DESKTOP_CCR_TEST_POPUP_CLICK, { eventLabel: 'I understand' });
    onExited();
  };

  return (
    <Modal
      onHide={handleHide}
      onExited={onExited}
      show={show}
      title={(
        <>
          <img
            className="install-desktop-remainder-modal__image"
            src={download}
            srcSet={`${download2x} 2x, ${download3x} 3x`}
            alt=""
            width="120"
            height="136"
          />
          <div className="mt-9">
            Make sure to install Setapp
          </div>
        </>
      )}
    >
      <p className="mt-3 mb-8">
        To use apps on Setapp, remember to install Setapp desktop app on your Mac.
      </p>
      <Button
        block
        size="lg"
        onClick={handleButtonClick}
      >
        I understand
      </Button>
    </Modal>
  );
};

export default InstallDesktopRemainderModal;
