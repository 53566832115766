import React, { FC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';


import { PRICE_PLAN_METADATA_MAPPER } from 'services/price-plans/price-plan-metadata-mapper';

import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import warningIcon from './images/icons-warning.svg';

import './scheduled-downgrade-message.scss';

type Props = {
  nextPricePlan: PricePlan;
  nextPaymentDate: number;
};

const ScheduledDowngradeMessage: FC<Props> = ({ nextPricePlan, nextPaymentDate }) => {
  const nextPricePlanMetadata = PRICE_PLAN_METADATA_MAPPER[nextPricePlan.tierType];
  const nextPricePlanTitle = nextPricePlanMetadata?.title;

  return (
    <div className="scheduled-downgrade-message">
      <img className="mr-2" src={warningIcon} alt="Warning icon" />
      <FormattedMessage
        id="scheduledDowngradeMessage.text"
        defaultMessage="You’ll switch to the {nextPricePlanTitle} plan on {nextPaymentDate}."
        values={{
          nextPricePlanTitle,
          nextPaymentDate: <FormattedDate value={nextPaymentDate * 1000} year="numeric" month="short" day="numeric" />,
        }}
      />
    </div>
  );
};

export default ScheduledDowngradeMessage;
