import React, { KeyboardEvent, PureComponent, ReactNode, SyntheticEvent } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import DropFamilySection from 'components/shared/drop-family/drop-family-section/drop-family-section';

import PageTitle from 'components/shared/page-title/page-title';
import DefaultError from 'components/shared/default-error/default-error';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';

import {
  inviteFamilyMember, resendInvite, removeFamilyMember,
} from 'state/family-plan/family-plan-actions';
import {
  getFamilyPlan, getFamilyMembersExceptCurrent, isFamilyFetched, isFamilyFull,
} from 'state/root-reducer';
import { showModal } from 'state/modal/modal-reducer';

import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';

import validate from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';
import FamilyPlanBenefits from '../family-plan-benefits/family-plan-benefits';
import MembersList from './members-list/members-list';
import MemberInvitationForm from './member-invitation-form/member-invitation-form';


import './activated-family-plan.scss';

const mapStateToProps = (state) => ({
  members: getFamilyMembersExceptCurrent(state),
  isFamilyProcessing: getFamilyPlan(state).isLoading,
  isFamilyFetched: isFamilyFetched(state),
  isFamilyFull: isFamilyFull(state),
  familySeats: getFamilyPlan(state).seats,
  familyPricePlan: getFamilyPlan(state).price,
});

const mapActionsToProps = {
  inviteFamilyMember,
  resendInvite,
  removeFamilyMember,
  showModal,
  showDangerNotification,
  showSuccessNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = ConnectedProps<typeof connector>;

type State = {
  fields: {
    email: string;
  };
  fieldsErrors: {
    email: ReactNode;
  };
};

class ActivatedFamilyPlan extends PureComponent<Props, State> {
  state = {
    fields: {
      email: '',
    },
    fieldsErrors: {
      email: '',
    },
  };

  validation = {
    email: {
      required: <FormattedMessage id="activatedFamilyPlan.validation.emptyEmail" defaultMessage="Email is required" />,
      emailFormat: <FormattedMessage id="activatedFamilyPlan.validation.invalidEmail" defaultMessage="Invalid email" />,
    },
  };

  render() {
    const {
      familySeats, isFamilyProcessing, members, isFamilyFull, familyPricePlan,
    } = this.props;
    const { fieldsErrors, fields } = this.state;

    return (
      <div>
        <PageTitle>
          <FormattedMessage id="activatedFamilyPlan.pageTitle" defaultMessage="My Setapp Family" />
        </PageTitle>
        <Row>
          <Col lg={6}>
            {!isFamilyFull && (
              <div className="activated-family-plan__form-container">
                <p className="h5 activated-family-plan__section-title">
                  <FormattedMessage
                    id="activatedFamilyPlan.invitationSection.title"
                    defaultMessage="Invite people to join:"
                  />
                </p>
                <MemberInvitationForm
                  isProcessing={isFamilyProcessing}
                  onFieldChange={this.onEmailChange}
                  email={fields.email}
                  emailError={fieldsErrors.email}
                  onSubmit={this.onInvitationSubmit}
                />
              </div>
            )}
            {Boolean(members.length) && (
              <MembersList
                members={members}
                resendInvite={this.resendInvite}
                revokeInvite={this.revokeInvite}
                showRemoveMemberModal={this.showRemoveMemberModal}
                isFamilyProcessing={isFamilyProcessing}
              />
            )}
            <DropFamilySection
              familyPricePlan={familyPricePlan}
              onDropFamilyClick={this.showDropFamilyModal}
            />

          </Col>
          <Col lg={5} lgOffset={1}>
            <div className="activated-family-plan__benefits">
              <FamilyPlanBenefits maxFamilySeats={familySeats} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  onInvitationSubmit = (e: SyntheticEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const { fields } = this.state;

    // eslint-disable-next-line react/no-access-state-in-setstate
    const fieldsErrors = validate(fields, this.validation);
    const formHasError = Object.keys(fieldsErrors).some((field) => fieldsErrors[field]);

    // @ts-expect-error TS(2741): Property 'email' is missing in type 'Messages<stri... Remove this comment to see the full error message
    this.setState({ fieldsErrors });

    if (formHasError) {
      return Promise.resolve();
    }

    return this.sendInvite(fields.email);
  };

  sendInvite = (email: string) => {
    const { inviteFamilyMember } = this.props;

    return inviteFamilyMember(email)
      .then(() => {
        analytics.trackEvent(events.FAMILY_PLAN_INVITE_SENT);

        this.setState((prevState) => ({
          fields: {
            ...prevState.fields,
            email: '',
          },
        }));

        const { showSuccessNotification } = this.props;
        showSuccessNotification((
          <FormattedMessage
            id="activatedFamilyPlan.invitationSending.succeed"
            defaultMessage="Invite successfully sent."
          />
        ));
      })
      .catch((error) => {
        const emailError = error.getFieldError('email');
        const isEmailUsed = emailError && emailError.isConflicted();

        if (emailError) {
          const emailMessage = isEmailUsed ? (
            <FormattedMessage
              id="activatedFamilyPlan.invitationSending.userAlreadyExists"
              defaultMessage="This person already in Setapp."
            />
          ) : emailError.toString();

          this.setState((prevState) => ({
            fieldsErrors: {
              ...prevState.fieldsErrors,
              email: emailMessage,
            },
          }));

          if (isEmailUsed) {
            analytics.trackEvent(events.FAMILY_PLAN_INVITE_EXISTING_USER);
          }
        } else {
          const { showDangerNotification } = this.props;

          showDangerNotification(<DefaultError />);
        }
      });
  };

  resendInvite = ({ id, email }: FamilyMember) => {
    const { resendInvite } = this.props;

    return resendInvite(id)
      .then(() => {
        const { showSuccessNotification } = this.props;
        showSuccessNotification((
          <FormattedMessage
            id="activatedFamilyPlan.memberItem.invitationResending.succeed"
            defaultMessage="Invite to {email} successfully resent."
            values={{ email }}
          />
        ));
      })
      .catch(() => {
        const { showDangerNotification } = this.props;
        showDangerNotification(<DefaultError />);
      });
  };

  removeMember = (familyMemberId: number, onSuccess: () => void) => {
    const { removeFamilyMember } = this.props;

    return removeFamilyMember(familyMemberId)
      .then(onSuccess)
      .catch(() => {
        const { showDangerNotification } = this.props;
        showDangerNotification(<DefaultError />);
      });
  };

  revokeInvite = ({ id, email, name }: FamilyMember) => this.removeMember(id, () => {
    const { showSuccessNotification } = this.props;
    showSuccessNotification((
      <FormattedMessage
        id="activatedFamilyPlan.memberItem.revokeInvite.succeed"
        defaultMessage="Invite to {title} successfully revoked."
        values={{ title: name || email }}
      />
    ));
  });

  onEmailChange = (e: KeyboardEvent<HTMLInputElement>) => {
    this.setState({ fields: { email: e.currentTarget.value } });
  };

  showRemoveMemberModal = (member: FamilyMember) => {
    const { showModal } = this.props;

    showModal('OLD_REMOVE_FAMILY_MEMBER', { member });

    analytics.trackEvent(events.FAMILY_PLAN_REMOVE_MEMBER_CLICK);
  };

  showDropFamilyModal = () => {
    const { showModal } = this.props;

    showModal('DROP_FAMILY');

    analytics.trackEvent(events.FAMILY_PLAN_DROP_CLICK, {
      eventLabel: 'Family page',
    });
  };
}

export { ActivatedFamilyPlan as PureActivatedFamilyPlan };

export default connector(ActivatedFamilyPlan);
