import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import DefaultError from 'components/shared/default-error/default-error';

import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { getFeaturedPricePlans, getPrimarySubscription } from 'state/root-reducer';

import SetappMobileProblems from './partials/problem-screen/problem-screen';
import SetappMobileOnboarding from './partials/onboarding-screen/onboarding-screen';

import useCheckSetappMobileAccess from './utils/use-check-setapp-mobile-access';

const SetappMobilePage: FC = () => {
  const featuredPricePlans = useSelector(getFeaturedPricePlans);
  const primarySubscription = useSelector(getPrimarySubscription);
  const showDangerNotificationAction = useDispatchAction(showDangerNotification);
  const fetchPricePlansAction = useDispatchAction(fetchPricePlans);
  const fetchSubscriptionsAction = useDispatchAction(fetchAllSubscriptions);
  const arePricePlansFetched = Boolean(featuredPricePlans.length);

  const accessStatus = useCheckSetappMobileAccess();

  useEffect(() => {
    async function fetchData() {
      try {
        if (!arePricePlansFetched) {
          await fetchPricePlansAction();
        }
        if (!primarySubscription?.id) {
          await fetchSubscriptionsAction();
        }
      } catch {
        showDangerNotificationAction(<DefaultError />);
      }
    }
    fetchData();
  }, []);

  if (accessStatus === undefined) {
    return <FullscreenLayoutLoading />;
  }

  return (
    <>
      {accessStatus !== 'ACCESS_GRANTED'
        ? <SetappMobileProblems problemType={accessStatus} />
        : <SetappMobileOnboarding />}
    </>
  );
};

export default SetappMobilePage;
