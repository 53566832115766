import { PRICE_PLAN_METADATA_MAPPER, PricePlanMetadata } from 'services/price-plans/price-plan-metadata-mapper';

import * as pricePlanTypes from 'state/price-plans/price-plans-types';

export const PROMOTED_PRICE_PLANS: Array<PricePlanMetadata> = [
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.MAC_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.MAC_PLUS_IOS_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.POWER_USER_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.MAC_ANNUAL]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.MAC_PLUS_IOS_ANNUAL]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.POWER_USER_ANNUAL]!,
];

export const PROMOTED_IOS_PRICE_PLANS: Array<PricePlanMetadata> = [
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.IOS_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.IOS_ANNUAL]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.MAC_PLUS_IOS_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.MAC_PLUS_IOS_ANNUAL]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.POWER_USER_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.POWER_USER_ANNUAL]!,
];

export const IOS_PRICE_PLANS: Array<PricePlanMetadata> = [
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.IOS_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.IOS_ANNUAL]!,
];

export const FAMILY_PRICE_PLANS: Array<PricePlanMetadata> = [
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.FAMILY_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.FAMILY_ANNUAL]!,
];

export const AI_OFFER_PRICE_PLANS: Array<PricePlanMetadata> = [
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.AI_ENTHUSIAST_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.AI_ENTHUSIAST_ANNUAL]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_FULL]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_PREV]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.AI_EXPERT_MONTHLY]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.AI_EXPERT_ANNUAL]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_FULL]!,
  PRICE_PLAN_METADATA_MAPPER[pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_PREV]!,
];

// FIXME: improve logic on how to determine the base price plan
export const BASE_PRICE_PLAN = {
  monthlyPrice: 9.99,
  annualPrice: 107.88,
};
