import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';

export type Props = {
  show: boolean;
  isLoading: boolean;
  member: FamilyMember;
  onHide: () => void;
  onExited: () => void;
  onRevokeFamilyInviteClick: (familyMember: FamilyMember) => Promise<void>;
};

const RevokeFamilyInviteModal = ({
  member,
  isLoading,
  onHide,
  onExited,
  show,
  onRevokeFamilyInviteClick,
}: Props) => {
  const handleRevokeFamilyInviteClick = () => {
    onHide();

    return onRevokeFamilyInviteClick(member);
  };

  return (
    <Modal
      onHide={onHide}
      onExited={onExited}
      show={show}
      title={
        <FormattedMessage
          id="revokeFamilyInviteModal.title"
          defaultMessage="Revoke invite"
        />
      }
    >
      <>
        <div className="mb-8">
          <FormattedMessage
            id="revokeFamilyInviteModal.description"
            defaultMessage="Invitee {email} will no longer be able to join your Family via the link we’ve sent to them."
            values={{
              email: <strong>{member.email}</strong>,
            }}
          />
        </div>

        <Button
          block
          size="lg"
          onClick={handleRevokeFamilyInviteClick}
          disabled={isLoading}
        >
          <FormattedMessage id="revokeFamilyInviteModal.submitButton" defaultMessage="Revoke invite" />
        </Button>

        <div className="text-center text_weight-medium">
          <Button size="lg" variant="link" onClick={onHide} className="mt-6">
            <FormattedMessage id="revokeFamilyInviteModal.cancelButton" defaultMessage="Cancel" />
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default RevokeFamilyInviteModal;
