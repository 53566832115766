import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonLoading } from '@setapp/ui-kit';

import analytics, { events } from 'utils/analytics';
import logger from 'utils/logger';

import { removeFamilyMember } from 'state/family-plan/family-plan-actions';
import { showSuccessNotification, showDangerNotification } from 'state/notifier/notifier-reducer';
import { getCurrentFamilyMember } from 'state/root-reducer';

import useSubscriptionBlockedWaiting
  from 'components/hooks/use-subscription-blocked-waiting/use-subscription-blocked-waiting';

import DefaultError from 'components/shared/default-error/default-error';
import Modal from 'components/shared/modal/modal';

type Props = {
  show: boolean;
  onHide: () => void;
};

const FamilyMemberSwitchOwnPlanModal = (props: Props) => {
  const { show, onHide } = props;

  const dispatch = useDispatch();
  const familyMember = useSelector(getCurrentFamilyMember);
  const [isProcessing, setIsProcessing] = useState(false);

  const { waitForSubscriptionBlocked } = useSubscriptionBlockedWaiting();

  const handleConfirmClick = async () => {
    if (!familyMember) {
      logger.logError('FamilyMemberSwitchOwnPlanModal: No current family member found');
      throw new Error('No current family member found');
    }

    setIsProcessing(true);

    try {
      await dispatch(removeFamilyMember(familyMember.id));

      analytics.trackEvent(events.FAMILY_PLAN_LEAVE_SUCCESS);

      await waitForSubscriptionBlocked();

      dispatch(showSuccessNotification(
        <FormattedMessage
          id="familyMemberSwitchOwnPlanModal.successMessage"
          defaultMessage="All done. Get a Setapp plan to continue using your favorite apps."
        />
      ));
    } catch (error) {
      dispatch(showDangerNotification(<DefaultError />));
    }

    setIsProcessing(false);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={
        <FormattedMessage
          id="familyMemberSwitchOwnPlanModal.title"
          defaultMessage="Confirm switch"
        />
      }
    >
      <div className="mb-6">
        <FormattedMessage
          id="familyMemberSwitchOwnPlanModal.description"
          defaultMessage="You are leaving this group and will be able to activate your own Setapp plan."
        />
      </div>

      <ButtonLoading
        className="mb-6"
        block
        isLoading={isProcessing}
        disabled={isProcessing}
        onClick={handleConfirmClick}
      >
        <FormattedMessage
          id="familyMemberSwitchOwnPlanModal.submitButton"
          defaultMessage="Proceed with switch"
        />
      </ButtonLoading>

      <Button
        block
        variant="link"
        disabled={isProcessing}
        onClick={onHide}
      >
        <FormattedMessage
          id="familyMemberSwitchOwnPlanModal.cancelButton"
          defaultMessage="Cancel"
        />
      </Button>
    </Modal>
  );
};

export default FamilyMemberSwitchOwnPlanModal;
