import request from 'utils/request';
import { apiURL } from 'config/api';
import analytics, { events } from 'utils/analytics';
import type { Dispatch } from '../state-types';
import * as giftCardsActionTypes from './gift-cards-action-types';


export const fetchGiftCardTypes = () => (dispatch: Dispatch) => {
  dispatch({
    type: giftCardsActionTypes.CARD_TYPES_REQUEST,
  });

  return request.get(apiURL.giftCardTypes)
    .then((data) => {
      dispatch({
        type: giftCardsActionTypes.CARD_TYPES_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: giftCardsActionTypes.CARD_TYPES_REQUEST_ERROR,
        error: true,
        payload: error,
      });
    });
};

export const fetchGiftCards = () => (dispatch: Dispatch) => {
  dispatch({
    type: giftCardsActionTypes.GIFT_CARDS_REQUEST,
  });

  return request.get(apiURL.giftCards)
    .then((data) => {
      dispatch({
        type: giftCardsActionTypes.GIFT_CARDS_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: giftCardsActionTypes.GIFT_CARDS_REQUEST_ERROR,
        error: true,
        payload: error,
      });
    });
};

export const redeemGiftCard = (code: string) => (dispatch: Dispatch) => {
  dispatch({
    type: giftCardsActionTypes.GIFT_CARDS_REQUEST,
  });

  return request.post(apiURL.redeemGiftCard, { body: { code } })
    .then((data) => {
      analytics.trackEvent(events.GIFT_CARD_REDEEM_MANUALLY, {
        eventLabel: 'Free months',
        eventValue: data.paidMonths,
      });

      dispatch({
        type: giftCardsActionTypes.GIFT_CARDS_UPDATE_ITEM,
        payload: data,
      });

      // TODO: Use item action instead
      dispatch({ type: giftCardsActionTypes.GIFT_CARDS_REQUEST_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: giftCardsActionTypes.GIFT_CARDS_REQUEST_ERROR,
        error: true,
        payload: error,
      });

      return Promise.reject(error);
    });
};

export const fetchGiftCard = (code = '') => () => request.get(`${apiURL.giftCards}/${code}`);
