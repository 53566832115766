// @flow

import React from 'react';

import * as paymentMethodTypes from 'state/payment-method/payment-method-types';
import * as paymentProviders from 'services/payment-details-api/payment-providers';
import type { PaymentDetails } from 'services/payment-details-api/payment-details-api';
import CreditCardInfo from './card-info/card-info';
import PayPalInfo from './paypal-info/paypal-info';
import PaddlePaymentDetails from './paddle-payment-details/paddle-payment-details';


type Props = {|
  paymentMethod: PaymentDetails,
|};

const PaymentMethodDetails = ({ paymentMethod }: Props) => {
  // Return generic paddle payment details view if there are no details available
  if (paymentMethod.provider === paymentProviders.PADDLE && !paymentMethod.details) {
    return <PaddlePaymentDetails type={paymentMethod.type} />;
  }

  if (paymentMethod.type === paymentMethodTypes.PAYPAL) {
    // $FlowFixMe: Typings are broken during TS migration
    return <PayPalInfo {...paymentMethod.details} />;
  }

  if (paymentMethod.type === paymentMethodTypes.CREDIT_CARD) {
    // $FlowFixMe: Typings are broken during TS migration
    return <CreditCardInfo {...paymentMethod.details} />;
  }

  return null;
};

export default PaymentMethodDetails;
