import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  nextPaymentDate: number;
  onConfirm: () => void;
};

const ConfirmCancelFamilyStep = (props: Props) => {
  const { nextPaymentDate, onConfirm } = props;

  return (
    <>
      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="confirmCancelFamilyStep.title"
          defaultMessage="Cancel subscription?"
        />
      </h3>

      <div className="mb-8 text_lg">
        <FormattedMessage
          id="confirmCancelFamilyStep.description"
          defaultMessage="Are you sure? This will cancel your Family plan subscription on {cancelDate}. Family participants will switch to inactive Mac plan."
          values={{
            cancelDate: (
              <strong>
                <FormattedDate
                  value={nextPaymentDate * 1000}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              </strong>
            ),
          }}
        />
      </div>

      <Button
        variant="primary"
        size="lg"
        block
        onClick={onConfirm}
      >
        <FormattedMessage
          id="confirmCancelFamilyStep.CTA"
          defaultMessage="Cancel subscription"
        />
      </Button>
    </>
  );
};

export default ConfirmCancelFamilyStep;
