import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import SetappMobileUpgradeCard from 'components/shared/setapp-mobile-upgrade-card/setapp-mobile-upgrade-card';
import { SetappMobileProblemsType } from '../../utils/setapp-mobile-problem-codes';

import IosAdvancedPlanCard from './ios-advanced-plan-card';

import './problem-banner.scss';

type ProblemBannerProps = {
  problemType?: SetappMobileProblemsType;
};

const ProblemBanner: FC<ProblemBannerProps> = ({ problemType }) => {
  if (problemType === 'EU_LOCATION_REQUIRED') {
    return (
      <div className="problem-banner">
        <p className="problem-banner__title">
          <FormattedMessage
            id="setappMobilePage.locationProblem.title"
            defaultMessage="Looks like you're outside the EU"
          />
        </p>
        <p className="problem-banner__description">
          <FormattedMessage
            id="setappMobilePage.locationProblem.description"
            defaultMessage="Setapp Mobile is not available in your location."
          />
        </p>
      </div>
    );
  }

  if (problemType === 'DEVICE_ERROR') {
    return (
      <div className="problem-banner">
        <p className="problem-banner__title">
          <FormattedMessage
            id="setappMobilePage.deviceProblem.title"
            defaultMessage="This doesn’t look like an iPhone"
          />
        </p>
        <p className="problem-banner__description">
          <FormattedMessage
            id="setappMobilePage.deviceProblem.description"
            defaultMessage="To install Setapp Mobile, open this page on an iPhone running iOS 17.4 or later."
          />
        </p>
      </div>
    );
  }

  if (problemType === 'OFFER_UPGRADE') {
    return <SetappMobileUpgradeCard />;
  }

  if (problemType === 'EMPTY_PAYMENT_DETAILS' || problemType === 'APP_ACCESS_FORBIDDEN_FOR_TRIAL_SUBSCRIPTION') {
    return <IosAdvancedPlanCard shouldShowTrial={true} />;
  }

  if (problemType === 'BLOCKED_CUSTOMER' || problemType === 'CUSTOMER_SUBSCRIPTION_INACTIVE') {
    return <IosAdvancedPlanCard shouldShowTrial={false} />;
  }

  if (problemType === 'FIRST_TIME_VISIT') {
    return (
      <Button block size="lg" href={urls.setappMobile}>
        <FormattedMessage
          id="setappMobilePage.btn.firstTimeGet"
          defaultMessage="Get Setapp Mobile"
        />
      </Button>
    );
  }

  return null;
};

export default ProblemBanner;
