const HAS_APP_ACCESS_ISSUE_KEY = 'hasAppAccessIssue';
const HAS_APP_ACCESS_ISSUE_VALUE = 'true';

export const getHasAppAccessIssue = () => {
  return sessionStorage.getItem(HAS_APP_ACCESS_ISSUE_KEY) === HAS_APP_ACCESS_ISSUE_VALUE;
};

export const setHasAppAccessIssue = () => {
  sessionStorage.setItem(HAS_APP_ACCESS_ISSUE_KEY, HAS_APP_ACCESS_ISSUE_VALUE);
};

export const removeHasAppAccessIssue = () => {
  sessionStorage.removeItem(HAS_APP_ACCESS_ISSUE_KEY);
};
