import React from 'react';
import { FormattedMessage } from 'react-intl';

import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';

import externalUrls from 'config/external-urls';

type DownloadLinkProps = {
  onClick: () => void;
}

type ImmediatelyDownloadLinkProps = {
  customClass?: string;
  onDirectDownloadClick: () => void;
}

const DownloadLink = ({ onClick }: DownloadLinkProps) => (
  <SetappDownloadLinkWrapper>
    <a
      className="link-outer"
      href={externalUrls.downloadDesktopClient}
      title="Download Setapp client"
      onClick={onClick}
    >
      <FormattedMessage
        id="successfulRegistration.downloadDesktopText"
        defaultMessage="download manually"
      />
    </a>
  </SetappDownloadLinkWrapper>
);

const ImmediatelyDownloadLink = ({ onDirectDownloadClick, customClass }: ImmediatelyDownloadLinkProps) => (
  <span className={customClass ? `${customClass}` : 'lead'}>
    <FormattedMessage
      id="successfulRegistration.downloadLinkSection"
      defaultMessage="If your download didn’t start, {link}."
      values={{
        link: (<DownloadLink onClick={onDirectDownloadClick} />),
      }}
    />
  </span>
);

export default ImmediatelyDownloadLink;
