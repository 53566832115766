import React, { ReactNode, Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import externalUrls from 'config/external-urls';
import urls from 'config/urls';

import { getUserSignupGiftCardCode, getUser, getUserSignupPromoCode } from 'state/root-reducer';

import signUpHoc from 'components/pages/signup/signup-page-hoc';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import FirstStepPrimaryContent
  from 'components/pages/redeem/first-step/primary-content/first-step-primary-content';
import FirstStepSecondaryContent
  from 'components/pages/redeem/first-step/secondary-content/first-step-secondary-content';
import SecondStepPrimaryContent
  from 'components/pages/redeem/second-step/primary-content/second-step-primary-content';
import SecondStepSecondaryContent
  from 'components/pages/redeem/second-step/secondary-content/second-step-secondary-content';

import macAndIphoneImage from 'components/shared/shared-images/mac-tablet-iphone@1x.png';
import macAndIphoneImage2x from 'components/shared/shared-images/mac-tablet-iphone@2x.png';
import macAndIphoneImage3x from 'components/shared/shared-images/mac-tablet-iphone@3x.png';

import './redeem.scss';

type Props = {
  signUpForm: ReactNode;
  isGiftCardCodeValid: boolean;
  isPromoCodeValid: boolean;
  location: RouteComponentProps['location'];
  isUserAuthenticated: boolean;
  history: RouteComponentProps['history'];
}

type State = {
  redeemCodeParsedFromUrl?: string;
}

class RedeemPage extends Component<Props, State> {
  constructor(props) {
    super(props);

    const query = queryString.parse(props.location.search);
    const parsedCode = Array.isArray(query.code) ? query.code[0] : query.code;

    this.state = {
      redeemCodeParsedFromUrl: parsedCode === null ? undefined : parsedCode,
    };
  }

  componentDidMount() {
    const { isUserAuthenticated, history } = this.props;

    if (isUserAuthenticated) {
      history.push(urls.giftCards);
    }
  }

  render() {
    const { isGiftCardCodeValid, signUpForm, isPromoCodeValid } = this.props;
    const { redeemCodeParsedFromUrl } = this.state;
    const isRedeemCodeValid = isGiftCardCodeValid || isPromoCodeValid;

    return (
      <FullscreenLayout>
        <FullscreenLayout.PrimaryContent>
          <div className="redeem__primary-content">
            <a
              className="redeem__logo"
              href={externalUrls.setappSite}
            >
              <AnimatedLogo hasCaption size="sm" />
            </a>
            {isRedeemCodeValid
              ? <SecondStepPrimaryContent isPromoCodeValid={isPromoCodeValid} signUpForm={signUpForm} />
              : <FirstStepPrimaryContent defaultRedeemCode={redeemCodeParsedFromUrl} />}
          </div>
        </FullscreenLayout.PrimaryContent>

        <FullscreenLayout.SecondaryContent>
          <div className="redeem__secondary-content">
            <img
              src={macAndIphoneImage}
              srcSet={`${macAndIphoneImage2x} 2x, ${macAndIphoneImage3x} 3x`}
              alt="Setapp on Mac and iPhone"
              width="596"
              height="351"
            />
            {isRedeemCodeValid ? <SecondStepSecondaryContent /> : <FirstStepSecondaryContent />}
          </div>
        </FullscreenLayout.SecondaryContent>
      </FullscreenLayout>
    );
  }
}

export { RedeemPage as PureRedeemPage };

const mapStateToProps = (state) => ({
  isGiftCardCodeValid: Boolean(getUserSignupGiftCardCode(state)),
  isPromoCodeValid: Boolean(getUserSignupPromoCode(state)),
  isUserAuthenticated: getUser(state).isAuthenticated,
});

export default signUpHoc({
  withSocial: true,
  emailDisabled: false,
  type: 'default',
  simplified: true,
  submitButtonText: (
    <FormattedMessage
      id="redeem.primary.btnCaption.createAccount"
      defaultMessage="Create account"
    />
  ),
})(connect(mapStateToProps)(RedeemPage));
