import { Referrals } from './referrals-initial-state';

export const REQUEST = 'REFERRALS_REQUEST';
export const REQUEST_SUCCESS = 'REFERRALS_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'REFERRALS_REQUEST_ERROR';

export type ReferralsRequestAction = {
  type: 'REFERRALS_REQUEST';
};

export type ReferralsRequestSuccessAction = {
  type: 'REFERRALS_REQUEST_SUCCESS';
  payload?: Referrals;
};

export type ReferralsRequestErrorAction = {
  type: 'REFERRALS_REQUEST_ERROR';
};

export type ReferralsAction = ReferralsRequestAction | ReferralsRequestSuccessAction | ReferralsRequestErrorAction;
