import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const logger = {
  sentry: {
    dsn: 'https://65dc08dc06924f23a7ca1a73ea26f18c@sentry.io/106672',
    allowUrls: [
      `${window.location.hostname}${process.env.BASE_PATH}/static/js`,
    ],
    release: process.env.VERSION,
    environment: process.env.ENVIRONMENT,
    ignoreErrors: [
      'document.getElementsByClassName("docs-homescreen-gb-container")[0].style',
      'undefined is not an object (evaluating \'divPos.x.toString\')',
      'document.getElementById(\'adwired\').getBoundingClientRect',
      // Google Re-CAPTCHA: https://github.com/google/recaptcha/issues/269
      'Non-Error promise rejection captured with value: Timeout',
      // Some nasty Chrome extension
      'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
      // Looks like Evernote web clipper errors
      'There is no clipping info for given tab',
    ],
    // Sentry serializes Objects passed to `captureException`, sometimes properties come as `[Object]` string
    normalizeDepth: 5,
    tags: {
      commitHash: process.env.COMMIT_HASH || '',
    },
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    ],
    tracesSampleRate: 0.2,
  },
};

export default logger;
