import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { hideModal } from 'state/modal/modal-reducer';
import { showSuccessNotification } from 'state/notifier/notifier-reducer';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';

const useHandlePaymentDetailsAdded = () => {
  const hideModalAction = useDispatchAction(hideModal);
  const showSuccessNotificationAction = useDispatchAction(showSuccessNotification);

  const onPaymentDetailsSaved = useCallback(() => {
    hideModalAction();
    showSuccessNotificationAction(
      <FormattedMessage id="paymentDetails.notifications.add" defaultMessage="Payment details successfully added." />,
    );
  }, []);

  return onPaymentDetailsSaved;
};

export default useHandlePaymentDetailsAdded;
