import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import './successful-registration-from-desktop-app.scss';

type Props = {
  isTokenRequestProcessing?: boolean;
  isDesktopOpened?: boolean;
  onOpenSetappClick: () => void;
};

const SuccessfulRegistrationWhenOpenedDesktopApp = (props: Props) => {
  const { isTokenRequestProcessing, onOpenSetappClick, isDesktopOpened } = props;

  return (
    <div className="text-center" data-testid="from-desktop-view">
      <h1 className="h4 successful-registration-from-desktop__title">
        <FormattedMessage
          id="successfulRegistration.fromDesktop.title"
          defaultMessage="Your account is all set."
        />
      </h1>
      <h5 className="successful-registration-from-desktop__sub-title">
        <FormattedMessage
          id="successfulRegistration.fromDesktop.subTitle"
          defaultMessage="Now you can sign into Setapp on your Mac and explore your new apps!"
        />
      </h5>
      <Button
        onClick={onOpenSetappClick}
        disabled={isTokenRequestProcessing}
      >
        {
          isDesktopOpened
            ? <FormattedMessage
              id="successfulRegistration.fromDesktop.goToAccountBtnTitle"
              defaultMessage="Go to my account"
              />
            : <FormattedMessage
              id="successfulRegistration.fromDesktop.goToSetappBtnTitle"
              defaultMessage="Go to Setapp"
              />
        }
      </Button>
    </div>
  );
};

SuccessfulRegistrationWhenOpenedDesktopApp.defaultProps = {
  isTokenRequestProcessing: false,
  isDesktopOpened: false,
};

export default SuccessfulRegistrationWhenOpenedDesktopApp;
