import { createSelector } from '@reduxjs/toolkit';
import type { Action } from 'state/state-types';
import * as subscriptionsListService from 'services/subscriptions/subscriptions-list';
import * as subscriptionService from 'services/subscriptions/subscription';
import type { PricePlanFeatures } from 'state/price-plans/price-plans-initial-state';
import initialState, { Subscription, SubscriptionsState } from './subscription-initial-state';
import * as actionTypes from './subscription-action-types';
import * as subscriptionStatuses from './statuses';


const subscriptions = (state: SubscriptionsState = initialState, action: Action): SubscriptionsState => {
  switch (action.type) {
    // TODO: describe UPDATE_REQUEST separately when the single subscription loading state is implemented
    case actionTypes.UPDATE_REQUEST:
    case actionTypes.ORDER_REQUEST:
    case actionTypes.LIST_REQUEST: {
      return {
        ...state,
        isLoading: !action.meta.silent,
      };
    }

    case actionTypes.LIST_REQUEST_SUCCESS: {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }

      return { list: action.payload, isLoading: false };
    }

    case actionTypes.ORDER_REQUEST_SUCCESS: {
      return {
        list: state.list.concat(action.payload),
        isLoading: false,
      };
    }

    case actionTypes.UPDATE_REQUEST_SUCCESS: {
      // Support one subscription as well as the subscriptions array
      const updatedSubscriptions = Array.isArray(action.payload) ? action.payload : [action.payload];
      const newSubscriptions = state.list
        .map((currentSubscription) => (
          updatedSubscriptions
            .find((updatedSubscription) => updatedSubscription.id === currentSubscription.id) ?? currentSubscription
        ));

      return {
        ...state,
        // TODO: remove loading from the subscription when the single subscription loading state is implemented
        isLoading: false,
        list: newSubscriptions,
      };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default subscriptions;

/*
 * Single subscription selectors
 */

export const isCancelAllowed = (state: Subscription) => [
  subscriptionStatuses.TRIAL,
  subscriptionStatuses.ACTIVE,
  subscriptionStatuses.GRACE,
  subscriptionStatuses.BLOCKED,
].includes(state.status) && !state.paymentPending;

export const isChangePlanAllowed = (state: Subscription) => [
  subscriptionStatuses.TRIAL,
  subscriptionStatuses.ACTIVE,
  subscriptionStatuses.GRACE,
  subscriptionStatuses.BLOCKED,
  subscriptionStatuses.CANCELLED,
].includes(state.status) && !state.paymentPending;

export const isManageSubscriptionAllowedByStatus = (state: Subscription) => [
  subscriptionStatuses.ACTIVE,
  subscriptionStatuses.GRACE,
].includes(state.status);

export const isMonthly = (state: Subscription) => state.pricePlan.paidMonth === 1;
export const isPlanChangeScheduled = (state: Subscription) => Boolean(state.nextPricePlan);

/*
 * List selectors
 */
export const getList = (state: SubscriptionsState) => state.list;

export const isFetched = (state: SubscriptionsState) => Boolean(getList(state).length);

/**
 * @deprecated use subscriptions list service
 */
export const getTotalSeatsNumber = (subscriptions: Array<Subscription> | Subscription) => {
  const subscriptionsArray = Array.isArray(subscriptions) ? subscriptions : [subscriptions];

  return subscriptionsListService.getTotalSeatsCount(subscriptionsArray);
};

// TODO: probably remove and use the service directly from the components
export const getPrimary = (state: SubscriptionsState) => subscriptionsListService
  .getPrimarySubscription(getList(state));

export const getAllAdditional = (state: SubscriptionsState) => (
  subscriptionsListService.getAdditionalSeats(getList(state))
);

export const isLastAdditionalSeatPaymentFailed = (subscriptions: Array<Subscription>) => (
  subscriptions.filter((subscription) => subscription.lastPaymentFailed).length > 0
);

export const getAllActiveAdditional = (state: SubscriptionsState): Array<Subscription> => {
  const activeAdditionalSubscriptions = getAllAdditional(state).filter(subscriptionService.isActive);

  return activeAdditionalSubscriptions.length ? activeAdditionalSubscriptions : [];
};

export const getPrimaryFeatures: (state: SubscriptionsState) => PricePlanFeatures = createSelector(
  getPrimary,
  (subscription) => subscription?.pricePlan?.features ?? { macSeatsCount: 0 },
);

export const getMaxAvailableSeatsNumber: (state: SubscriptionsState) => number = createSelector(
  getPrimary,
  (subscription) => (subscription ? subscription.pricePlan.maxSeatsCount : 0),
);

export const isPrimaryInactive: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => (subscription ? !subscriptionService.isActive(subscription) : false),
);

export const isPrimaryTrial: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => (subscription ? subscriptionService.isStatusTrial(subscription) : false),
);

export const isPrimaryNew: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => (subscription ? subscriptionService.isStatusNew(subscription) : false),
);

export const isPrimaryFree: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => (subscription ? subscriptionService.isStatusFree(subscription) : false),
);

export const isPrimaryBlocked: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => (subscription ? subscriptionService.isStatusBlocked(subscription) : false),
);

export const isPrimaryPlanMonthly: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => (subscription ? isMonthly(subscription) : false),
);

export const isNextPrimaryPlanAnnual: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription?: Subscription) => {
    if (!subscription || !subscription.nextPricePlan) {
      return false;
    }

    return subscription.nextPricePlan.paidMonth === 12;
  },
);

export const isPrimaryPlanChangeAllowed: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => (subscription ? isChangePlanAllowed(subscription) : false),
);

export const isPrimaryPlanManageAllowed: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => {
    if (!subscription || subscription.paymentPending) {
      return false;
    }

    // For trial price plans that are forbidden to switch to another price plan,
    // need to show manage subscription for possibility to cancel subscription
    if (
      subscription.status === subscriptionStatuses.TRIAL
      && subscription.pricePlan.features?.trialSwitchFromStrategy === 'forbidden'
    ) {
      return true;
    }

    return isManageSubscriptionAllowedByStatus(subscription);
  },
);

export const isCancelPrimaryAllowed: (state: SubscriptionsState) => boolean = createSelector(
  getPrimary,
  (subscription) => (subscription ? isCancelAllowed(subscription) : false),
);

export const getPrimarySeatsCount: (state: SubscriptionsState) => number = createSelector(
  getPrimary,
  (subscription) => (subscription ? getTotalSeatsNumber(subscription) : 0),
);

export const getActiveAdditionalSeatsNumber = (state: SubscriptionsState) => (
  getTotalSeatsNumber(getAllActiveAdditional(state))
);

export const getAdditionalSeatsInfo = (state: SubscriptionsState) => {
  const [initialData] = getAllActiveAdditional(state);

  if (!initialData) {
    return null;
  }

  return {
    ...initialData,
    seatsNumber: getActiveAdditionalSeatsNumber(state),
    pricePlan: {
      ...initialData.pricePlan,
      price: subscriptionsListService.getTotalPrice(getAllActiveAdditional(state)),
    },
  };
};

export const getPrimaryPricePlan = createSelector(
  getPrimary,
  (primarySubscription) => (primarySubscription ? primarySubscription.pricePlan : null),
);
