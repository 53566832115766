import classNames from 'classnames';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';

import external from 'config/external-urls';
import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';
import { removeQueryParams } from 'utils/location';

import type { DeviceSeat } from 'services/devices-to-seats-mapper/devices-to-seats-mapper';
import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';
import * as subscriptionService from 'services/subscriptions/subscription';
import { isAiExpertPricePlan } from 'services/price-plans/utils';

import { fetchDevices } from 'state/devices/devices-reducer';
import type { Device } from 'state/devices/devices-initial-state';
import { ModalProps, showModal } from 'state/modal/modal-reducer';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import {
  getAdditionalSeatPlan,
  getDevices,
  getDevicesInSeat,
  getPricePlansList,
  getPurchasedSeatsCount,
  getPrimarySubscription,
  getSubscriptions,
  isAdditionalSeatPurchaseAvailable,
  isAllAvailableSeatsPurchased,
  isFreeUser,
  isIosOnlyUser,
  isUserPlainFamilyMember,
  isUserFamilyMember,
  isSubscriptionWithDiscount,
  hasPriceFeatures,
  hasTrialSeats,
} from 'state/root-reducer';

import { getStoredCustomerOauthError } from 'components/pages/customer-oauth-page/utils/customer-oauth-storage';
import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';
import ButtonsRow from 'components/shared/buttons-row/buttons-row';
import DefaultError from 'components/shared/default-error/default-error';
import { FormattedPeriod, FormattedPrice } from 'components/shared/formatter/formatter';
import PageTitle from 'components/shared/page-title/page-title';
import type { ModalType } from 'components/modals';

import DeviceListItem from './devices-list/device-list-item';
import DevicesList from './devices-list/devices-list';
import IosDevicesBlock from './ios-devices-block/ios-devices-block';

import './devices-page.scss';

const OPEN_MODAL_QUERY_PARAM = 'openBuySeatDialog';

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  devicesInSeat: getDevicesInSeat(state),
  seatPlan: getAdditionalSeatPlan(state),
  isProcessing: getDevices(state).isLoading || getSubscriptions(state).isLoading,
  isAdditionalSeatPurchaseAvailable: isAdditionalSeatPurchaseAvailable(state),
  isPricePlansFetched: Boolean(getPricePlansList(state).length),
  currentSeatsAmount: getPurchasedSeatsCount(state),
  primarySubscription: getPrimarySubscription(state),
  hasTrialSeats: hasTrialSeats(state),
  isIosOnlyUser: isIosOnlyUser(state),
  isUserPlainFamilyMember: isUserPlainFamilyMember(state),
  isUserFamilyMember: isUserFamilyMember(state),
  isFreeUser: isFreeUser(state),
  isAllAvailableSeatsPurchased: isAllAvailableSeatsPurchased(state),
  hasPriceFeatures: hasPriceFeatures(state),
  isSubscriptionWithDiscount: isSubscriptionWithDiscount(state),
});

const mapActionsToProps = {
  fetchDevices,
  fetchPricePlans,
  fetchAllSubscriptions,
  showModal,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

// We add more accurate type here so that showModal method is typed correctly
type Props = Pick<RouteComponentProps, 'history' | 'location'>
  & Omit<ConnectedProps<typeof connector>, 'showModal'>
  & { showModal: <T extends ModalType>(modalType: T, modalProps?: ModalProps<T>) => void };

class DevicesPage extends PureComponent<Props> {
  componentDidMount() {
    const {
      fetchAllSubscriptions,
      fetchPricePlans,
      fetchDevices,
      showDangerNotification,
    } = this.props;

    this.openBuySeatModalIfRequired();

    return Promise
      .all([
        fetchDevices(),
        fetchAllSubscriptions(),
        fetchPricePlans(),
      ])
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  componentDidUpdate() {
    this.openBuySeatModalIfRequired();
  }

  render() {
    const {
      seatPlan,
      isAdditionalSeatPurchaseAvailable,
      devicesInSeat,
      isPricePlansFetched,
      currentSeatsAmount,
      primarySubscription,
      hasTrialSeats,
      isUserPlainFamilyMember,
      isFreeUser,
      isIosOnlyUser,
      isAllAvailableSeatsPurchased,
      isProcessing,
      hasPriceFeatures,
      isUserFamilyMember,
    } = this.props;

    if (!primarySubscription || !isPricePlansFetched) {
      return <AppLayoutLoading />;
    }

    const { status: subscriptionStatus, nextPricePlan, pricePlan } = primarySubscription;

    const showDowngradeNotification = hasPriceFeatures
      // trial user has max price plan, no need to show this notification
      && subscriptionStatus !== subscriptionStatuses.TRIAL
      && nextPricePlan
      && !isUserPlainFamilyMember
      && ((
        nextPricePlan?.features?.macSeatsCount
        && pricePlan?.features?.macSeatsCount
        && nextPricePlan.features.macSeatsCount < pricePlan.features.macSeatsCount
      ) || (
        nextPricePlan?.features?.iosSeatsCount
        && pricePlan?.features?.iosSeatsCount
        && nextPricePlan.features.iosSeatsCount < pricePlan.features.iosSeatsCount
      ) || (
        !nextPricePlan?.features?.iosSeatsCount
        && pricePlan?.features?.iosSeatsCount
      ));

    const isAiExpertPlan = isAiExpertPricePlan(primarySubscription.pricePlan.tierType);
    const topPricePlans = [pricePlanTypes.POWER_USER_ANNUAL, pricePlanTypes.POWER_USER_MONTHLY];
    const showUpgradePlanButton = hasPriceFeatures
      && !topPricePlans.includes(primarySubscription.pricePlan.tierType)
      && !isIosOnlyUser
      && !isUserFamilyMember
      && !isAiExpertPlan
      && primarySubscription.pricePlan.tierType !== pricePlanTypes.SETAPP_FOR_BACKEND_TRIAL
      && primarySubscription.pricePlan.tierType !== pricePlanTypes.SETAPP_FOR_BACKEND_MONTHLY;

    const headerClassnames = classNames('devices-page__header', { 'devices-page__header_ios-only': isIosOnlyUser });

    const legacyPlanDevicesInSeat = devicesInSeat.filter((deviceInSeat) => (
      !deviceInSeat.device || deviceInSeat.device.platform !== 'ios_eu'
    ));

    const legacyPlanActiveDevicesAmount = legacyPlanDevicesInSeat
      .filter((deviceInSeat) => Boolean(deviceInSeat.device)).length;

    const connectedIosEuDevices = devicesInSeat.filter((deviceInSeat) => deviceInSeat.device?.platform === 'ios_eu');
    const {
      iosSeatsCount,
      iosEuSeatsCount = connectedIosEuDevices.length,
    } = primarySubscription.pricePlan.features ?? {};

    return (
      <div>
        <div className={headerClassnames}>
          <PageTitle>
            <FormattedMessage id="devicesPage.title" defaultMessage="Manage devices" />
          </PageTitle>

          {!isIosOnlyUser && (
            <div className="h5 mb-4">
              {isUserPlainFamilyMember && !hasPriceFeatures ? (
                <FormattedMessage id="devicesPage.subtitleFamily" defaultMessage="Family plan includes one active device" />
              ) : (
                <FormattedMessage id="devicesPage.subtitle" defaultMessage="See where your Setapp is connected" />
              )}
            </div>
          )}

          {!hasPriceFeatures && !isUserPlainFamilyMember && !isFreeUser && !hasTrialSeats && (
            <div className="mb-4">
              { seatPlan && !isAllAvailableSeatsPurchased ? (
                <FormattedMessage
                  id="devicesPage.description"
                  defaultMessage="You have {basicPlanSeatsAmount} {basicPlanSeatsAmount, plural, one {device} other {devices}} in your Setapp plan. Add up to {extraPlanSeatsAmount} extra Mac or iOS {extraPlanSeatsAmount, plural, one {device} other {devices}} at {price}/{period} each."
                  values={{
                    basicPlanSeatsAmount: currentSeatsAmount,
                    extraPlanSeatsAmount: primarySubscription.pricePlan.maxSeatsCount - currentSeatsAmount,
                    price: (
                      <FormattedPrice
                        price={seatPlan.price}
                        currency={seatPlan.currency}
                      />
                    ),
                    period: <FormattedPeriod paidMonth={seatPlan.paidMonth} />,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="devicesPage.reachedLimitDescription"
                  defaultMessage="You have reached your device limit."
                />
              )}
            </div>
          )}

          {!hasPriceFeatures && seatPlan && hasTrialSeats && primarySubscription.nextPricePlan && (
            <>
              <div>
                <FormattedMessage
                  id="devicesPage.trialDescriptionDevices"
                  defaultMessage="You have {currentSeats} {currentSeats, plural, one {device} other {devices}} in your Setapp plan. Connect up to {availableSeats} extra {availableSeats, plural, one {device} other {devices}} on your trial period for free."
                  values={{
                    currentSeats: primarySubscription.nextPricePlan.seatsCount,
                    availableSeats: primarySubscription.nextPricePlan.maxSeatsCount
                      - primarySubscription.nextPricePlan.seatsCount,
                  }}
                />
              </div>
              <div>
                <FormattedMessage
                  id="devicesPage.trialDescriptionPrice"
                  defaultMessage="After that, each extra device will cost {price}/{period}."
                  values={{
                    price: (
                      <FormattedPrice
                        price={seatPlan.price}
                        currency={seatPlan.currency}
                      />
                    ),
                    period: <FormattedPeriod paidMonth={seatPlan.paidMonth} />,
                  }}
                />
              </div>
            </>
          )}

          {hasPriceFeatures && !isIosOnlyUser && this.getFeaturedPlansDescription()}

          {showDowngradeNotification && primarySubscription.nextPaymentDate && primarySubscription.nextPricePlan && (
            <div className="mt-4 devices-page__downgrade-notification">
              <div>
                <FormattedMessage
                  id="devicesPage.downgradeNotification.description"
                  defaultMessage="You are switching to the {plan} plan on {date}."
                  values={{
                    date: (
                      <FormattedDate
                        value={primarySubscription.nextPaymentDate * 1000}
                        year="numeric"
                        month="short"
                        day="numeric"
                      />
                    ),
                    plan: getPricePlanMetadata(primarySubscription.nextPricePlan).title,
                  }}
                />
              </div>
              <div>
                <FormattedMessage
                  id="devicesPage.downgradeNotification.disconnect"
                  defaultMessage="All the devices outside your new plan will be automatically disconnected."
                />
              </div>
            </div>
          )}
        </div>

        {!hasPriceFeatures && (
          <>
            <div className="h5 mb-5">
              {!hasTrialSeats && currentSeatsAmount <= 1 && (
                <FormattedMessage
                  id="devicesPage.singleAvailableDevice"
                  defaultMessage="Your device"
                />
              )}

              {!hasTrialSeats && currentSeatsAmount > 1 && (
                <FormattedMessage
                  id="devicesPage.availableDevices"
                  defaultMessage="You’re using {activeDevicesAmount} out of {currentSeatsAmount} devices"
                  values={{
                    activeDevicesAmount: legacyPlanActiveDevicesAmount,
                    currentSeatsAmount: legacyPlanDevicesInSeat.length,
                  }}
                />
              )}

              {hasTrialSeats && (
                <FormattedMessage
                  id="devicesPage.availableDevicesTrial"
                  defaultMessage="Your devices"
                />
              )}
            </div>

            <DevicesList
              devicesInSeat={legacyPlanDevicesInSeat}
              onDisconnectClick={this.handleDisconnectDeviceAction}
              onReactivateClick={this.handleReactivateSeatClick}
            />

            <ButtonsRow>
              {
                isAdditionalSeatPurchaseAvailable && !isAllAvailableSeatsPurchased && (
                  <Button
                    onClick={this.handleBuySeatClick}
                    disabled={isProcessing}
                    data-qa="buy-additional-seat-button"
                    size="lg"
                  >
                    <FormattedMessage id="devicesPage.buySeatButtonTitle" defaultMessage="Add extra devices" />
                  </Button>
                )
              }

              {this.canCancelSeat() && (
                <Button
                  variant="secondary-outline"
                  onClick={this.handleCancelSeatsClick}
                  disabled={isProcessing}
                  data-qa="cancel-additional-seat-button"
                  size="lg"
                >
                  <FormattedMessage id="devicesPage.cancelSeatButtonTitle" defaultMessage="Remove devices" />
                </Button>
              )}
            </ButtonsRow>
          </>
        )}

        {hasPriceFeatures && primarySubscription.pricePlan.features && !isIosOnlyUser && (
          <>
            <div className="h5 mb-2">
              <FormattedMessage
                id="devicesPage.macDevices"
                defaultMessage="Your Mac {macSeatsCount, plural, one {device} other {devices}}"
                values={{
                  macSeatsCount: primarySubscription.pricePlan.features.macSeatsCount,
                }}
              />
            </div>
            {isUserFamilyMember ? (
              <div className="text_color-secondary mb-6">
                <FormattedMessage
                  id="devicesPage.connectMacDeviceFamily"
                  defaultMessage="Browse and install apps from Setapp desktop app on Mac."
                />
              </div>
            ) : (
              <div className="text_color-secondary mb-6">
                <FormattedMessage
                  id="devicesPage.connectMacDevice"
                  defaultMessage="Sign in to Setapp on your Mac to connect it."
                />
              </div>
            )}
            <ul className="devices-page__list">
              {this.getFeaturedMacDevicesInSeat().map((deviceSeat, id) => (
                <li key={id} className="mb-2">
                  <DeviceListItem
                    deviceSeat={deviceSeat}
                    onDisconnectClick={this.handleDisconnectDeviceAction}
                    platform="macos"
                  />
                </li>
              ))}
            </ul>
          </>
        )}

        <IosDevicesBlock
          devicesInSeat={devicesInSeat}
          iosSeatsCount={iosSeatsCount}
          iosEuSeatsCount={iosEuSeatsCount}
          isUserFamilyMember={isUserFamilyMember}
          isIosOnlyUser={isIosOnlyUser}
          onDisconnectDevice={this.handleDisconnectDeviceAction}
          onDisconnectAllIos={this.handleDisconnectAllIos}
        />

        {hasPriceFeatures && primarySubscription.pricePlan.features && showUpgradePlanButton && (
          <Button
            className="mt-10"
            size="lg"
            onClick={() => this.handleUpgradePlan(true)}
            data-qa="upgradeButton"
          >
            <FormattedMessage id="devicesPage.upgradeButton" defaultMessage="Upgrade now" />
          </Button>
        )}
      </div>
    );
  }

  handleBuySeatClick = () => {
    this.showBuySeatDialog();
  };

  onSuccessfulDisconnect = () => {
    const { history } = this.props;

    if (getStoredCustomerOauthError() === 'NOT_ENOUGH_SEATS') {
      history.push(urls.customerOauth);
    }
  }

  handleDisconnectDeviceAction = (device: Device) => {
    const { showModal } = this.props;

    const onSucess = device.platform === 'ios_eu' ? this.onSuccessfulDisconnect : undefined;

    analytics.trackEvent(events.DEACTIVATE_DEVICE_MODAL_OPEN, { eventLabel: device.id.toString() });
    showModal('DISCONNECT_DEVICE', { device, onSuccessfulDisconnect: onSucess });
  };

  handleDisconnectAllIos = () => {
    const { showModal } = this.props;

    analytics.trackEvent(events.DEVICE_DEACTIVE_ALL_IOS_CLICK);
    showModal('DISCONNECT_DEVICE', { device: 'all-ios-devices', onSuccessfulDisconnect: this.onSuccessfulDisconnect  });
  };

  handleReactivateSeatClick = (subscription: Subscription, device?: Device) => {
    analytics.trackEvent(events.REACTIVATE_SEAT_MODAL_OPEN, { eventLabel: device ? device.id.toString() : null });

    const { showModal } = this.props;
    showModal('REACTIVATE_ADDITIONAL_SEAT', { device: device || null, subscription });
  };

  handleCancelSeatsClick = () => {
    const { showModal, devicesInSeat } = this.props;

    analytics.trackEvent(events.CANCEL_SEAT_MODAL_OPEN, { eventLabel: devicesInSeat.length.toString() });
    showModal('CANCEL_ADDITIONAL_SEAT');
  };

  canCancelSeat() {
    const { devicesInSeat } = this.props;

    return devicesInSeat
      .map((deviceSeat) => deviceSeat.subscription)
      .filter(subscriptionService.isAdditionalSeat)
      .some(subscriptionService.isActive);
  }

  openBuySeatModalIfRequired() {
    if (this.shouldOpenBuySeatModal()) {
      this.showBuySeatDialog();
      this.removeOpenModalQueryParam();
    }
  }

  shouldOpenBuySeatModal() {
    const { location, isAllAvailableSeatsPurchased } = this.props;
    const query = queryString.parse(location.search);

    return OPEN_MODAL_QUERY_PARAM in query && !isAllAvailableSeatsPurchased;
  }

  removeOpenModalQueryParam() {
    const { history } = this.props;

    removeQueryParams(history, OPEN_MODAL_QUERY_PARAM);
  }

  showBuySeatDialog() {
    const { showModal, devicesInSeat } = this.props;

    analytics.trackEvent(events.BUY_SEAT_MODAL_OPEN, { eventLabel: devicesInSeat.length.toString() });
    showModal('BUY_ADDITIONAL_SEAT');
  }

  getFeaturedMacDevicesInSeat(): Array<DeviceSeat> {
    const { devicesInSeat, primarySubscription } = this.props;

    const { macSeatsCount } = primarySubscription?.pricePlan.features ?? {};

    if (macSeatsCount) {
      return devicesInSeat.filter((deviceInSeat) => {
        if (deviceInSeat.device && deviceInSeat.device.platform !== 'macos') {
          return false;
        }

        return deviceInSeat;
      }).slice(0, macSeatsCount);
    }

    return [];
  }

  getFeaturedPlansDescription() {
    const { primarySubscription, isUserFamilyMember } = this.props;

    if (primarySubscription) {
      if (
        primarySubscription.pricePlan.tierType === pricePlanTypes.SETAPP_FOR_BACKEND_TRIAL
        || primarySubscription.pricePlan.tierType === pricePlanTypes.SETAPP_FOR_BACKEND_MONTHLY
      ) {
        return null;
      }

      if (
        primarySubscription.pricePlan.tierType === pricePlanTypes.POWER_USER_ANNUAL
        || primarySubscription.pricePlan.tierType === pricePlanTypes.POWER_USER_MONTHLY
      ) {
        return (
          <div>
            <FormattedMessage
              id="devicesPage.featuredPowerUserPlan.description"
              defaultMessage="Now you’re on the most extended Setapp plan. Need even more devices? {teamsLink}"
              values={{
                teamsLink: (
                  <a href={external.teams} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="devicesPage.featuredPowerUserPlan.teamsLink" defaultMessage="Try Setapp for Teams" />
                  </a>
                ),
              }}
            />
          </div>
        );
      }

      if (isUserFamilyMember && primarySubscription.pricePlan.features) {
        return (
          <div>
            <FormattedMessage
              id="devicesPage.featuredPlan.familyPlanDescription"
              defaultMessage="You can use Setapp on {mac} Mac {mac, plural, one {device} other {devices}}{ios, plural, =0 {} one {\u00A0+ 1 iOS device} other {\u00A0+ # iOS devices}}."
              values={{
                ios: primarySubscription.pricePlan.features.iosSeatsCount,
                mac: primarySubscription.pricePlan.features.macSeatsCount,
              }}
            />
          </div>
        );
      }
    }

    return (
      <div>
        <FormattedMessage
          id="devicesPage.featuredPlan.description"
          defaultMessage="Need Setapp on more Mac and iOS devices? {upgradePlanLink}"
          values={{
            upgradePlanLink: (
              <Button
                variant="link"
                onClick={() => this.handleUpgradePlan()}
              >
                <FormattedMessage id="devicesPage.changePlanLink" defaultMessage="Change plan" />
              </Button>
            ),
          }}
        />
      </div>
    );
  }

  handleUpgradePlan = (isUpgradeButton?: boolean) => {
    const { isSubscriptionWithDiscount, showModal } = this.props;

    const eventLabel = isUpgradeButton ? 'Click Upgrade' : 'Click Change Plan';
    analytics.trackEvent(events.DEVICES_PAGE_CHANGE_PLAN_CLICK, { eventLabel });

    if (isSubscriptionWithDiscount) {
      showModal('CONFIRM_LOSE_DISCOUNT', {
        onConfirm: this.redirectToUpgradePlanPage,
      });
    } else {
      this.redirectToUpgradePlanPage();
    }
  }

  redirectToUpgradePlanPage = () => {
    const {
      history,
      primarySubscription,
    } = this.props;
    const isActiveSubscription = primarySubscription && subscriptionService.isActive(primarySubscription);
    const redirectUrl = isActiveSubscription ? urls.changePricePlan : urls.activateSubscription;

    history.push(redirectUrl);
  }
}

export { DevicesPage as PureDevicesPage };

export default connector(DevicesPage);
