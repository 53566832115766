import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';

import './account-info-row.scss';

type Props = {
  title: ReactNode;
  value: ReactNode;
  valueClass?: string;
  qaLabel: string;
};

/**
 * @deprecated use InfoField
 */
const AccountInfoRow = ({
  title, value, valueClass, qaLabel,
}: Props) => {
  const valueClasses = classNames('account-info-row-value', valueClass);

  return (
    <div className="account-info-row" data-qa={qaLabel}>
      <div className="account-info-row-title">
        {title}
        :
      </div>
      <div className={valueClasses}>{value}</div>
    </div>
  );
};

AccountInfoRow.defaultProps = {
  valueClass: '',
  qaLabel: '',
};

export default AccountInfoRow;
