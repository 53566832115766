import { defineMessages } from 'react-intl';

export default defineMessages({
  btnCloseAccessibilityLabel: {
    id: 'closeBtnAccessibilityLabel',
    defaultMessage: 'Close',
  },
  btnTooltipAccessibilityLabel: {
    id: 'btnTooltipAccessibilityLabel',
    defaultMessage: 'Open help text',
  },
});
