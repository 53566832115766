import React, { useEffect } from 'react';
import { FormattedDate, FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';

import analytics, { events } from 'utils/analytics';

import { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';

import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import { type User } from 'state/user/user-initial-state';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import InfoButton from 'components/shared/info-button/info-button';
import NextPaymentDateEstimation from 'components/shared/next-payment-date-estimation/next-payment-date-estimation';
import PaymentButton from 'components/shared/payment-button/payment-button';
import PricePerMonth from 'components/shared/price-per-month/price-per-month';
import TaxInfo from 'components/shared/tax-info/tax-info';

import './confirm-price-plan-step.scss';

type Props = {
  isProcessing: boolean;
  currentPricePlan: PricePlan;
  nextPricePlan: PricePlan;
  nextPricePlanCalculationData: NextPricePlanCalculationData;
  onSubmitNextPlan: () => Promise<void>;
  isTrialUser?: boolean;
  user: User;
} & typeof defaultProps;

const defaultProps = {
  isTrialUser: false,
};

const ConfirmPricePlanStep = (props: Props) => {
  const {
    isProcessing,
    currentPricePlan,
    nextPricePlan,
    nextPricePlanCalculationData,
    onSubmitNextPlan,
    isTrialUser,
    user,
  } = props;
  const { accountCurrency, isSetappMobileAvailable } = user;

  const {
    strategy, prorationAmount, prorationCurrency, nextPaymentDate,
  } = nextPricePlanCalculationData;

  const currentPricePlanMetadata = getPricePlanMetadata(currentPricePlan);
  const nextPricePlanMetadata = getPricePlanMetadata(nextPricePlan);
  const currentPricePlanTitle = currentPricePlanMetadata.title;
  const nextPricePlanTitle = nextPricePlanMetadata.title;
  const isChargeStrategy = strategy === 'charge';
  const showPaymentButton = ['charge', 'instant'].includes(strategy);

  useEffect(() => {
    analytics.trackEvent(events.SELECT_PLAN_PAGE_ARE_YOU_SURE_CONFIRM, {
      eventLabel: nextPricePlan.tierType,
      eventLabel2: currentPricePlan.tierType,
    });
  }, []);

  const buttonTitle = (
    <FormattedMessage
      id="changePricePlanFlow.confirmPricePlanStep.CTA.confirm"
      defaultMessage="Confirm"
    />
  );
  const paymentButtonTitle = strategy === 'instant' ? buttonTitle : undefined;

  const renderDescription = () => {
    if (isTrialUser) {
      return (
        <FormattedMessage
          id="changePricePlanFlow.confirmPricePlanStep.description.trial"
          defaultMessage="Enjoy all the features of {nextPricePlanTitle} plan during your trial! Your subscription will begin automatically after trial."
          values={{ nextPricePlanTitle }}
        />
      );
    }

    switch (strategy) {
      case 'charge':
        return (
          <span>
            <FormattedMessage
              id="changePricePlanFlow.confirmPricePlanStep.description.higherPlan"
              defaultMessage="You will be charged a part of the fee for the remaining billing cycle"
            />
            &nbsp;
            <InfoButton>
              <FormattedMessage
                id="changePricePlanFlow.confirmPricePlanStep.description.higherPlan.additionalInfo"
                defaultMessage="When switching to the {nextPricePlanTitle} plan from your current {currentPricePlanTitle} plan, you will only cover the price difference for the rest of the current billing cycle."
                values={{ currentPricePlanTitle, nextPricePlanTitle }}
              />
            </InfoButton>
          </span>
        );
      case 'scheduled':
        return (
          <FormattedMessage
            id="changePricePlanFlow.confirmPricePlanStep.description.lowerPlan"
            defaultMessage="After {nextPaymentDate}, you will be switched to the {nextPricePlanTitle} plan for {price}."
            values={{
              nextPricePlanTitle,
              nextPaymentDate: (
                <strong>
                  <FormattedDate value={nextPaymentDate * 1000} year="numeric" month="long" day="numeric" />
                </strong>
              ),
              price: <PricePerMonth pricePlan={nextPricePlan} />,
            }}
          />
        );
      case 'instant': {
        return (
          <FormattedMessage
            id="changePricePlanFlow.confirmPricePlanStep.description.equalPlan"
            defaultMessage="You will be switched to the {nextPricePlanTitle} plan right away. This won't affect your payment amount."
            values={{ nextPricePlanTitle }}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderProrationAmount = () => (
    <div className="confirm-price-plan-step__proration-amount">
      <FormattedMessage
        id="changePricePlanFlow.confirmPricePlanStep.prorationAmount.title"
        defaultMessage="Total:"
      />
      {' '}
      <FormattedPrice price={prorationAmount} currency={prorationCurrency} minimumFractionDigits={2} />
      <div className="confirm-price-plan-step__proration-amount-tax-info">
        <FormattedMessage
          id="changePricePlanFlow.confirmPricePlanStep.prorationAmount.taxInfo"
          defaultMessage="Excluding tax"
        />
      </div>
    </div>
  );

  const renderTaxInfo = () => {
    if (accountCurrency === 'EUR') {
      return (
        <FormattedHTMLMessage
          id="changePricePlanFlow.confirmPricePlanStep.vatInfo"
          defaultMessage="This is an estimated price for your location.<br/>The final amount may vary depending on applicable VAT."
        />
      );
    }
    if (accountCurrency === 'USD' && isSetappMobileAvailable) {
      return (
        <FormattedHTMLMessage
          id="changePricePlanFlow.confirmPricePlanStep.vatUsdInfo"
          defaultMessage="Tax may apply depending on your country.<br/>Check your bank statement for details."
        />
      );
    }

    return <TaxInfo />;
  };

  return (
    <>
      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="changePricePlanFlow.confirmPricePlanStep.title"
          defaultMessage="Switch to {pricePlanTitle} plan"
          values={{ pricePlanTitle: nextPricePlanMetadata.title }}
        />
      </h3>

      <div className="text_lg">
        {renderDescription()}
      </div>

      {isChargeStrategy && renderProrationAmount()}

      <div className="mt-8 mb-5">
        {showPaymentButton ? (
          <PaymentButton
            title={paymentButtonTitle}
            onClick={onSubmitNextPlan}
          />
        ) : (
          <ButtonLoading
            variant="primary"
            size="lg"
            block
            disabled={isProcessing}
            isLoading={isProcessing}
            onClick={onSubmitNextPlan}
          >
            {buttonTitle}
          </ButtonLoading>
        )}
      </div>

      <NextPaymentDateEstimation
        price={nextPricePlan.price}
        currency={nextPricePlan.currency}
        nextPaymentDate={nextPaymentDate}
      />

      <div className="mt-2">
        {renderTaxInfo()}
      </div>
    </>
  );
};

ConfirmPricePlanStep.defaultProps = defaultProps;

export default ConfirmPricePlanStep;
