import React from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';

import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';

import useIosEventHandlers from 'components/hooks/use-ios-event-handlers/use-ios-event-handlers';

import './ios-pack-card.scss';

type Props = {
  iosApp: IosApp;
  collectionName: string;
};

const IosPackCard = ({ iosApp, collectionName }: Props) => {
  const { handleIosAppClick, handleIosAppKeyDown } = useIosEventHandlers({ ownEventLabel: collectionName });

  return (
    <div
      className="ios-pack-card"
      role="button"
      tabIndex={0}
      onClick={() => handleIosAppClick(iosApp)}
      onKeyDown={(event) => handleIosAppKeyDown(event, iosApp)}
    >
      <div className="ios-pack-card__icon-container">
        <div className="ios-pack-card__icon">
          <img width="80" height="80" src={iosApp.icon} alt={iosApp.name} />
        </div>
      </div>
      <div className="ios-pack-card__info">
        <p className="ios-pack-card__description">
          {iosApp.shortDescription}
        </p>
        <div className="ios-pack-card__action-row">
          <p className="ios-pack-card__name">{iosApp.name}</p>
          <Button
            className="ios-pack-card__btn"
            variant="primary-outline"
            type="button"
            size="sm"
          >
            <FormattedMessage id="iosApps.packCard.getBtn" defaultMessage="Get" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default IosPackCard;
