export const SetappMobileProblemCodes = {
  // customer subscription doesn’t allow Setapp Mobile store usage, like Teams or Family
  APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION: 'APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION', // SHOW SORRY SCREEN
  // blocked subscription, payment is stuck
  BLOCKED_CUSTOMER: 'BLOCKED_CUSTOMER', // SHOW PRICE PLAN CARD WITHOUT TRIAL, REDIRECT TO Let's start your subscription
  // canceled subscription and no days left, can either renew or choose a new plan
  CUSTOMER_SUBSCRIPTION_INACTIVE: 'CUSTOMER_SUBSCRIPTION_INACTIVE', // THE SAME AS BLOCKED
  // “new” customer if the trial plan isn’t EU or payment info isn’t set or payment info isn’t in EUR
  EMPTY_PAYMENT_DETAILS: 'EMPTY_PAYMENT_DETAILS', // SHOW TRIAL PLAN CARD, REDIRECT TO Set up your trial
  // “new” customer if the trial plan doesn’t have access to EU marketplace, should choose a new plan
  APP_ACCESS_FORBIDDEN_FOR_TRIAL_SUBSCRIPTION: 'APP_ACCESS_FORBIDDEN_FOR_TRIAL_SUBSCRIPTION', // The same as EMPTY_PAYMENT_DETAILS
  // customer is not in EU
  EU_LOCATION_REQUIRED: 'EU_LOCATION_REQUIRED', // SHOW LOCATION ERROR SCREEN
  // customer is not on iOS
  DEVICE_ERROR: 'DEVICE_ERROR', // SHOW DEVICE ERROR SCREEN
  // customer has active plan that doesn't allow Setapp Mobile store usage
  OFFER_UPGRADE: 'OFFER_UPGRADE', // SHOW PRICE PLAN CARD, show Switch to {plan}/View plans button
  // first time visit
  FIRST_TIME_VISIT: 'FIRST_TIME_VISIT', // SHOW FIRST TIME VISIT SCREEN (NO ERROR)
  // general errors
  UNKNOWN_ERROR: 'UNKNOWN_ERROR', // General error screen
};

export type SetappMobileProblemsType = keyof typeof SetappMobileProblemCodes;
