import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import externalUrls from 'config/external-urls';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import errorIllustration from './images/error-illustration.svg';

import './invalid-family-invite-page.scss';

const InvalidFamilyInvitePage = () => (
  <FullscreenLayout>
    <FullscreenLayout.PrimaryContent withLogo>
      <div className="invalid-family-invite__primary-content">
        <h3 className="mb-2">
          <FormattedMessage id="invalidFamilyInvitePage.title" defaultMessage="Looks like this invite has expired." />
        </h3>

        <p className="text_lg mb-8">
          <FormattedMessage
            id="invalidFamilyInvitePage.description"
            defaultMessage="This Setapp Family invite has expired. But don’t worry, you can still explore Setapp on you own!"
          />
        </p>

        <Button block href={externalUrls.setappSite} size="lg">
          <FormattedMessage id="invalidFamilyInvitePage.button" defaultMessage="Go to Setapp" />
        </Button>
      </div>
    </FullscreenLayout.PrimaryContent>
    <FullscreenLayout.SecondaryContent>
      <div className="invalid-family-invite__secondary-content">
        <img
          src={errorIllustration}
          alt=""
          width={538}
          height={580}
        />
      </div>
    </FullscreenLayout.SecondaryContent>
  </FullscreenLayout>
);

export default InvalidFamilyInvitePage;
