import React, { PureComponent } from 'react';
import type { ReactElement, MouseEvent } from 'react';
import { connect } from 'react-redux';
import DesktopAppInstallerUrl from '@setapp/desktop-app-installer-url';
import { SatuCookieHelper } from '@setapp/abn-tests-client';

import { getUser } from 'state/root-reducer';

import Gtm from 'utils/analytics/gtm';

type Props = {
  // We do really need React element here
  children: ReactElement;
  onClick?: (e: MouseEvent<HTMLLinkElement>) => void;
  campaign?: string;
};

class SetappDownloadLinkWrapper extends PureComponent<Props> {
  render() {
    const { children } = this.props;

    return React.cloneElement(React.Children.only(children), {
      onClick: this.onDownloadClick,
    });
  }

  onDownloadClick = (e: MouseEvent<HTMLLinkElement>) => {
    // this event will bubble from child element
    e.preventDefault();

    const { href } = e.currentTarget;
    const { children, onClick, campaign } = this.props;

    if (children.props.onClick) {
      children.props.onClick(e);
    }

    if (onClick) {
      onClick(e);
    }

    const downloadUrl = new DesktopAppInstallerUrl(href, {
      trackingCid: Gtm.getCID(),
      satuId: SatuCookieHelper.getSatuId(),
      campaign,
    }).toString();

    window.location.assign(downloadUrl);
  }
}

const mapStateToProps = (state) => ({
  campaign: getUser(state).campaign ?? undefined,
});

export default connect(mapStateToProps)(SetappDownloadLinkWrapper);
