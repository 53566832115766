import React, { type FC, useEffect } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import analytics, { events } from 'utils/analytics';

import urls from 'config/urls';

import CustomerOauthContent from './customer-oauth-content';

const CustomerOauthNotEnoughSeats: FC = () => {
  const history = useHistory();

  useEffect(() => {
    analytics.trackEvent(events.DEVICE_LIMIT_REACHED);
  }, []);

  const handleManageDevicesClick = () => {
    history.push(urls.devices);
  };

  return (
    <CustomerOauthContent
      titleText={
        <FormattedMessage
          id="customer-oauth.seats.title"
          defaultMessage="Sorry, you’ve reached your device limit"
        />
      }
      descriptionText={
        <FormattedHTMLMessage
          id="customer-oauth.seats.description"
          defaultMessage="Please disconnect one of your iPhones to free up the place for this device. For this, go to the <b>iPhones: Setapp Mobile</b> section on the next page."
        />
      }
      buttonText={
        <FormattedMessage
          id="customer-oauth.seats.button"
          defaultMessage="Manage devices"
        />
      }
      onButtonClick={handleManageDevicesClick}
    />
  );
};

export default CustomerOauthNotEnoughSeats;
