import React, { Component } from 'react';
import type { ComponentType } from 'react';

import logger from 'utils/logger';
import createPoller from 'utils/promise-poller';

import { getInvoice } from 'services/price-plans/price-plans-api';

import type { InvoiceStatus } from 'state/invoices/invoice-statuses';

const WAIT_FOR_PAYMENT_TIMEOUT = 90000;
const INVOICE_STATUS_POLLING_INTERVAL = 3000;

type WaitForPaymentSuccess = (
  invoiceId: string,
  onSuccess: () => Promise<void>,
  onFail: (invoiceStatus: InvoiceStatus) => void,
  onError: () => void,
  logData: { [key: string]: unknown },
) => Promise<void>;

export type InjectedInvoicePollingProps = {
  waitForPaymentSuccess: WaitForPaymentSuccess;
}

/**
 * HOC to wait for invoice payment success. HOC polls for invoice status with a certain interval.
 * And stop on invoice payment success/fail or when the timeout is over.
 */
function withInvoicePolling<T extends InjectedInvoicePollingProps = InjectedInvoicePollingProps>(
  WrappedComponent: ComponentType<T>
) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  class WithInvoicePolling extends Component<Omit<T, keyof InjectedInvoicePollingProps>> {
    static displayName: string = `withInvoicePolling(${displayName})`;

    waitPaymentTimeoutId: ReturnType<typeof setTimeout> | null = null;

    stopInvoiceStatusPolling: (() => void) | null = null;

    componentWillUnmount() {
      this.cancelPendingTasks();
    }

    render() {
      return (
        <WrappedComponent
          {...this.props as T}
          waitForPaymentSuccess={this.waitForPaymentSuccess}
        />
      );
    }

    waitForPaymentSuccess: WaitForPaymentSuccess = async (
      invoiceId, onSuccess, onFail, onError, logData
    ) => {
      const task = () => getInvoice(invoiceId);

      const [startPolling, stopPolling] = createPoller(task, {
        delayFirstTask: true,
        timeout: INVOICE_STATUS_POLLING_INTERVAL,
        shouldContinue: this.shouldContinueInvoiceStatusPolling,
      });

      this.stopInvoiceStatusPolling = stopPolling;

      // Set waiting for payment success timeout
      this.waitPaymentTimeoutId = setTimeout(() => {
        // this will reject poller promise and error will appear in the wrapped component where
        // `waitForPaymentSuccess` prop was called
        this.stopInvoiceStatusPolling!();
      }, WAIT_FOR_PAYMENT_TIMEOUT);

      // Start polling
      try {
        const { status } = await startPolling();

        if (['failed', 'canceled'].includes(status)) {
          this.cancelPendingTasks();

          onFail(status);

          logger.logError(
            'Invoice failed',
            { ...logData, invoiceId, status },
          );

          return;
        }

        await onSuccess();
      } catch (err: any) {
        onError();

        logger.logError(
          'Error during waiting for payment success',
          { ...logData, ...err },
        );
      }
    }

    shouldContinueInvoiceStatusPolling = (err: any, result: any) => {
      if (err) {
        return false;
      }

      const { status } = result;

      // Stop polling if invoice is failed
      if (['failed', 'canceled'].includes(status)) {
        return false;
      }

      // Continue polling if invoice status isn't success
      const isSuccessPayment = status === 'paid';

      return !isSuccessPayment;
    }

    cancelPendingTasks = () => {
      if (this.stopInvoiceStatusPolling) this.stopInvoiceStatusPolling();
      if (this.waitPaymentTimeoutId) clearTimeout(this.waitPaymentTimeoutId);
    }
  }

  return WithInvoicePolling;
}

export default withInvoicePolling;
