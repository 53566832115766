import React from 'react';
import type { ComponentType, ReactNode } from 'react';
import { Button } from '@setapp/ui-kit';
import type { ButtonVariant } from '@setapp/ui-kit/dist/button/button';

import './promo-banner.scss';

type Props = {
  image: string;
  title: ReactNode;
  message: ReactNode;
  buttonText: ReactNode;
  buttonTag?: ComponentType<any>;
  buttonUrl?: ReactNode;
  buttonVariant?: ButtonVariant;
  onButtonClick?: () => void;
};

const PromoBanner = ({
  image,
  title,
  message,
  buttonText,
  buttonTag,
  buttonUrl,
  buttonVariant,
  onButtonClick,
}: Props) => (
  <div className="promo-banner" data-qa="promoBanner">
    <div className="promo-banner__illustration">
      <img src={image} alt="" className="promo-banner__image" />
    </div>
    <div className="promo-banner__content">
      <h5 className="mt-0">
        {title}
      </h5>
      <p className="mb-8">
        {message}
      </p>
      <Button
        className="promo-banner__button"
        size="lg"
        variant={buttonVariant}
        tag={buttonTag}
        to={buttonUrl}
        onClick={onButtonClick}
        data-qa="submitPromoBannerButton"
      >
        {buttonText}
      </Button>
    </div>
  </div>
);

PromoBanner.defaultProps = {
  buttonTag: null,
  buttonUrl: null,
  onButtonClick: null,
};

export default PromoBanner;
