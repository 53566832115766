import { InferThunkActionCreatorType } from 'react-redux';

import { apiURL } from 'config/api';

import type {
  SubscriptionActivationOrder,
  AdditionalSeatsOrder,
  CancelAdditionalSeatsOrder,
} from 'services/subscriptions/subscriptions-api';
import { getPrimarySubscription, getExtraSeatsCount } from 'services/subscriptions/subscriptions-list';

import request from 'utils/request';
import logger from 'utils/logger';
import analytics, { events } from 'utils/analytics';
import { getSubscriptionsApiClient } from 'utils/service-locators';

import { getUser } from 'state/root-reducer';
import type { Dispatch, GetState } from 'state/state-types';
import { fetchDevices } from 'state/devices/devices-reducer';
import { ChangeSubscriptionPlan } from 'state/price-plans/price-plans-types';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { fetchUser } from 'state/user/user-actions';
import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { fetchTrialStatusThunk } from 'state/trial-status/trial-status-reducer';

import type { Subscription } from './subscription-initial-state';
import * as actionTypes from './subscription-action-types';
import type { RequestOptions } from './requets-options-type';

type DeviceSeat = {
  subscriptionId: number;
  deviceId?: number;
};

const defaultRequestOptions: RequestOptions = {
  silent: false,
};

const listRequestStarted = (options?: Partial<RequestOptions>) => ({
  type: actionTypes.LIST_REQUEST,
  meta: {
    ...defaultRequestOptions,
    ...options,
  },
} as const);

const updateRequestStarted = (subscriptionId: number, options?: Partial<RequestOptions>) => ({
  type: actionTypes.UPDATE_REQUEST,
  payload: subscriptionId,
  meta: {
    ...defaultRequestOptions,
    ...options,
  },
} as const);

const updateRequestSuccess = (updatedSubscription: Subscription) => ({
  type: actionTypes.UPDATE_REQUEST_SUCCESS,
  payload: updatedSubscription,
} as const);

export const fetchAllSubscriptions = (options?: RequestOptions) => (dispatch: Dispatch) => {
  dispatch(listRequestStarted(options));

  return request.get(apiURL.subscriptions)
    .then((data) => {
      dispatch({
        type: actionTypes.LIST_REQUEST_SUCCESS,
        payload: data,
      });

      return data;
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};
export type FetchAllSubscriptionsAction = InferThunkActionCreatorType<typeof fetchAllSubscriptions>;

/**
 * @deprecated use fetchAllSubscriptions
 */
export const fetchSubscription = fetchAllSubscriptions;

export const reactivateSubscription = ({ subscriptionId }: {subscriptionId: number}) => (dispatch: Dispatch) => {
  dispatch(updateRequestStarted(subscriptionId));

  return request.post(apiURL.resumeSubscription(subscriptionId))
    // Must fetch all subscriptions because other subscriptions gets resumed when the primary is resumed
    .then(() => dispatch(fetchAllSubscriptions()))
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t resume subscription', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const resumeAllSubscriptions = () => (dispatch: Dispatch) => {
  const subscriptionsApi = getSubscriptionsApiClient();
  dispatch(listRequestStarted());

  return subscriptionsApi.resumeAllSubscriptions()
    .then((data) => {
      dispatch({
        type: actionTypes.LIST_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      logger.logError('Couldn\'t resume all subscriptions', error.getSimplifiedErrors());

      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const cancelSubscription = ({ subscriptionId }: {subscriptionId: number}) => (dispatch: Dispatch) => {
  dispatch(updateRequestStarted(subscriptionId));

  return request.post(apiURL.cancelSubscription(subscriptionId))
    // Must fetch all subscriptions because other subscriptions gets cancelled when the primary is cancelled
    .then(() => dispatch(fetchAllSubscriptions()))
    .catch((error) => {
      logger.logError('Couldn\'t cancel subscription', error.getSimplifiedErrors());

      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export const cancelAllSubscriptions = () => (dispatch: Dispatch) => {
  const subscriptionsApi = getSubscriptionsApiClient();

  dispatch(listRequestStarted());

  return subscriptionsApi.cancelAllSubscriptions()
    .then((data) => {
      dispatch({
        type: actionTypes.LIST_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      logger.logError('Couldn\'t cancel all subscriptions', error.getSimplifiedErrors());

      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      return Promise.reject(error);
    });
};

export type { SubscriptionActivationOrder };

export const activateSubscription = (subscriptionsOrder: SubscriptionActivationOrder) => (dispatch: Dispatch) => {
  const subscriptionsApi = getSubscriptionsApiClient();

  dispatch(listRequestStarted());

  return subscriptionsApi.activateSubscription(subscriptionsOrder)
    .then((subscriptions) => {
      const primarySubscription = getPrimarySubscription(subscriptions);
      const extraSeatsCount = getExtraSeatsCount(subscriptions);

      if (!primarySubscription) {
        logger.logError('No primary subscription returned in the subscription activation response', { subscriptions });
      }

      analytics.trackEvent(events.SUBSCRIPTION_ACTIVATED, {
        eventLabel: primarySubscription?.pricePlan.tierType,
        eventValue: extraSeatsCount,
      });

      dispatch({
        type: actionTypes.LIST_REQUEST_SUCCESS,
        payload: subscriptions,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t activate subscription', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};
export type ActivateSubscriptionAction = InferThunkActionCreatorType<typeof activateSubscription>;

export const retryCharge = () => (dispatch: Dispatch) => {
  dispatch(listRequestStarted());

  return request.post(apiURL.retryPayment)
    .then((data) => {
      dispatch({
        type: actionTypes.LIST_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t retry charge', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const changePricePlan = (
  subscriptionId: number,
  planId: number,
  options: { fetchSubscription: boolean } = { fetchSubscription: true },
) => (dispatch: Dispatch): Promise<ChangeSubscriptionPlan> => {
  dispatch(listRequestStarted());

  return request.put(apiURL.changePricePlan(subscriptionId), { body: { id: planId } })
    // TODO: update only one subscription when API starts returning Subscription instead of PricePLan
    .then(async (nextPricePlan: ChangeSubscriptionPlan) => {
      if (options.fetchSubscription) {
        await dispatch(fetchAllSubscriptions());
      }

      return nextPricePlan;
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t change plan', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};
export type ChangePricePlanAction = InferThunkActionCreatorType<typeof changePricePlan>;

export const changeTrialPricePlan = (priceKey: string) => (dispatch: Dispatch): Promise<PricePlan> => {
  dispatch(listRequestStarted());

  return request.post(apiURL.changeTrialPricePlan, { body: { tierType: priceKey } })
    .then((data: PricePlan) => {
      dispatch(fetchAllSubscriptions());

      return data;
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t change trial plan', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const startTrial = () => (dispatch: Dispatch) => {
  dispatch(listRequestStarted());

  return request.post(apiURL.startTrial)
    .then(() => dispatch(fetchAllSubscriptions()))
    .then(() => dispatch(fetchTrialStatusThunk()))
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t start trial', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const orderNewSubscription = (priceId: number) => (dispatch: Dispatch) => {
  dispatch(listRequestStarted());

  return request.post(apiURL.subscriptions, { body: { pricePlanId: priceId } })
    .then(() => dispatch(fetchAllSubscriptions()))
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError("Couldn't order new subscription", error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export const activateOldSuspendedSubscription = (
  subscription: Subscription,
  planId: number,
) => async (dispatch: Dispatch, getState: GetState) => {
  if (subscription.status === subscriptionStatuses.CANCELLED) {
    await dispatch(orderNewSubscription(planId));
  } else {
    await dispatch(changePricePlan(subscription.id, planId));
  }

  // for activate old suspended user flow, need to update user data
  // after successful payment, to show user a new cabinet
  const user = getUser(getState());
  if (!user.hasPriceFeatures) {
    await dispatch(fetchUser());
  }
};
export type ActivateOldSuspendedSubscriptionAction = InferThunkActionCreatorType<
  typeof activateOldSuspendedSubscription
>;

export const orderAdditionalSeats = (order: AdditionalSeatsOrder) => (dispatch: Dispatch) => {
  const subscriptionsApi = getSubscriptionsApiClient();

  dispatch({
    type: actionTypes.ORDER_REQUEST,
    meta: {
      silent: false,
    },
  });

  return subscriptionsApi.orderAdditionalSeats(order)
    .then((subscriptions: Array<Subscription>) => {
      analytics.trackEvent(events.SEAT_WAS_BOUGHT, { eventLabel: order.quantity ? order.quantity.toString() : 'empty' });

      dispatch({
        type: actionTypes.ORDER_REQUEST_SUCCESS,
        payload: subscriptions,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      analytics.trackEvent(events.BUY_SEAT_PAYMENT_ERROR, { eventLabel: error.getSimplifiedErrors() });
      logger.logError('Couldn\'t buy additional seat', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};

export type { CancelAdditionalSeatsOrder };

export const cancelAdditionalSeats = (order: CancelAdditionalSeatsOrder) => (dispatch: Dispatch) => {
  const subscriptionsApi = getSubscriptionsApiClient();

  dispatch(listRequestStarted());

  return subscriptionsApi.cancelAdditionalSeats(order)
    .then(async (subscriptions: Array<Subscription>) => {
      analytics.trackEvent(events.SEAT_CANCELED, { eventLabel: order.subscriptions.length.toString() });

      // The devices list is changed as well if there were active devices in the cancelled seats
      await dispatch(fetchDevices());

      // backend returns canceled seats, but sometimes they could be deleted from subscriptions list
      // so we need to fetch subscriptions to avoid showing deleted seat as canceled
      // TODO: remove fetch all subscriptions here, when backend will stop returning deleted subscription AFX-5591
      await dispatch(fetchAllSubscriptions());

      dispatch({
        type: actionTypes.UPDATE_REQUEST_SUCCESS,
        payload: subscriptions,
      });
    })
    .catch((error) => {
      logger.logError('Couldn\'t cancel additional seats', error);

      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      throw error;
    });
};

export const reactivateSeat = ({ subscriptionId, deviceId }: DeviceSeat) => (dispatch: Dispatch) => {
  dispatch(updateRequestStarted(subscriptionId));

  return request.post(apiURL.resumeSubscription(subscriptionId), { body: deviceId ? { deviceId } : {} })
    .then((subscription: Subscription) => {
      analytics.trackEvent(events.SEAT_REACTIVATED, { eventLabel: deviceId ? deviceId.toString() : 'empty' });

      dispatch(updateRequestSuccess(subscription));

      // TODO: resolve with the subscription instead of the devices list
      return dispatch(fetchDevices());
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REQUEST_ERROR,
        payload: error,
        error: true,
      });

      logger.logError('Couldn\'t reactivate seat', error.getSimplifiedErrors());

      return Promise.reject(error);
    });
};
