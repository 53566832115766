// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ButtonLoading, PriceRadio } from '@setapp/ui-kit';

import FormContainer from 'components/shared/form/form-container/form-container';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import { FormattedPrice } from 'components/shared/formatter/formatter';

import './buy-additional-seats-form.scss';

type FormFields = {
  seatsCount: string;
};

type Props = {
  withDescription: boolean;
  primarySubscriptionSeatsCount: number;
  maxSeatsCount: number;
  seatPricePlan: PricePlan;
  onSubmit: (fields: FormFields) => void;
};

function getSeatsCountOptions(maxSeatsCount) {
  const options: number[] = [];

  for (let seatsCount = 1; seatsCount <= maxSeatsCount; seatsCount++) {
    options.push(seatsCount);
  }

  return options;
}

const BuyAdditionalSeatsForm = (props: Props) => {
  const {
    withDescription,
    primarySubscriptionSeatsCount,
    maxSeatsCount,
    seatPricePlan,
    onSubmit,
  } = props;
  const seatsCountOptions = getSeatsCountOptions(maxSeatsCount);

  return (
    <FormContainer initialValues={{ seatsCount: '1' }} onSubmit={onSubmit}>
      {({
        fields: {
          seatsCount: selectedSeatsCount,
        },
        isProcessing,
        onFieldChange,
        onSubmit,
      }) => (
        <form noValidate onSubmit={onSubmit} data-qa="buyAdditionalSeatsForm" data-testid="buy-additional-seats-form">
          {withDescription && (
            <FormattedMessage
              id="buyAdditionalSeat.optionsDescription"
              defaultMessage="You have {primarySubscriptionSeatsCount, plural, one {1 device} other {{primarySubscriptionSeatsCount} devices}} in your Setapp plan. Add up to {maxSeatsCount, plural, one {1 extra device} other {{maxSeatsCount} extra devices}} at {price}/month each."
              values={{
                primarySubscriptionSeatsCount,
                maxSeatsCount,
                price: <FormattedPrice
                  price={seatPricePlan.price / seatPricePlan.paidMonth}
                  currency={seatPricePlan.currency}
                       />,
              }}
            />
          )}

          <div className="buy-additional-seats-form__items" data-qa="buyAdditionalSeatsItems">
            {seatsCountOptions.map((seatsCount) => {
              const isChecked = seatsCount === Number(selectedSeatsCount);
              const priceClasses = classNames({ 'text_color-secondary': !isChecked });

              return (
                <div className="mb-8" key={seatsCount} data-qa="buyAdditionalSeatsItem">
                  <PriceRadio
                    name="seatsCount"
                    value={seatsCount}
                    checked={seatsCount === Number(selectedSeatsCount)}
                    onChange={onFieldChange}
                    // @ts-expect-error PriceRadio title property type has to be fixed
                    title={(
                      <span data-qa="buyAdditionalSeatsItemQuantity">
                        <FormattedMessage
                          id="buyAdditionalSeat.optionDescription"
                          defaultMessage="{seatsCount, plural, one {{seatsCount} device} other {{seatsCount} devices}}"
                          values={{
                            seatsCount,
                          }}
                        />
                      </span>)}
                    price={(
                      <span className={priceClasses} data-qa="buyAdditionalSeatsItemPrice">
                        <FormattedMessage
                          id="buyAdditionalSeats.optionPrice"
                          defaultMessage="{price} /mo"
                          values={{
                            price: <FormattedPrice
                              currency={seatPricePlan.currency}
                              price={seatPricePlan.price * seatsCount}
                                   />,
                          }}
                        />
                      </span>
                    )}
                  />
                </div>
              );
            })}
          </div>

          <ButtonLoading
            block
            type="submit"
            size="lg"
            disabled={isProcessing}
            isLoading={isProcessing}
            loadingText=""
            data-qa="buyAdditionalSeatsConfirmButton"
          >
            <FormattedMessage id="buyAdditionalSeat.continueButton" defaultMessage="Continue" />
          </ButtonLoading>
        </form>
      )}
    </FormContainer>
  );
};

BuyAdditionalSeatsForm.defaultProps = {
  withDescription: true,
};

export default BuyAdditionalSeatsForm;
