import React, { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { hasScheduledDowngrade, isAiExpertPricePlan, isFamilyPricePlan } from 'services/price-plans/utils';
import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { Subscription } from 'state/subscription/subscription-initial-state';

const DOWNGRADE_NOT_ALLOWED_STATUSES = [
  subscriptionStatuses.NEW,
  subscriptionStatuses.TRIAL
];

type FamilyInfoType =  'owner' | 'member' | 'oldMember' | null;

export interface SubscriptionContextValue {
  hasPriceFeatures: boolean;
  isFamilyOwner: boolean;
  isFamilyMember: boolean;
  isScheduledDowngrade: boolean;
  isPaymentMethodCreated: boolean;
  taxAmount: number;
  subscription: Subscription;
  pricePlan: PricePlan;
  familyInfoType: FamilyInfoType;
  isUpgradeToAiPlanAvailable: boolean;
}

const initialValue: Partial<SubscriptionContextValue> = {
  hasPriceFeatures: false,
  isFamilyMember: false,
  isFamilyOwner: false,
  isPaymentMethodCreated: false,
  isScheduledDowngrade: false,
  pricePlan: undefined,
  subscription: undefined,
  taxAmount: 0,
  familyInfoType: null,
  isUpgradeToAiPlanAvailable: false,
};

export const SubscriptionContext = createContext<SubscriptionContextValue>(
  initialValue as SubscriptionContextValue
);

interface SubscriptionProviderProps {
  hasPriceFeatures: boolean;
  isFamilyOwner: boolean;
  isFamilyMember: boolean;
  subscription: Subscription;
  isPaymentMethodCreated: boolean;
  isSubscriptionNew: boolean;
  isSubscriptionFree: boolean;
  isSubscriptionBlocked: boolean;
  isSubscriptionCancelled: boolean;
}

export const SubscriptionProvider: FC<PropsWithChildren<SubscriptionProviderProps>> = ({
  children,
  hasPriceFeatures,
  isFamilyMember,
  isFamilyOwner,
  subscription,
  isPaymentMethodCreated,
  isSubscriptionNew,
  isSubscriptionFree,
  isSubscriptionBlocked,
  isSubscriptionCancelled,
}) => {
  const hasNextPricePlan = subscription.nextPricePlan !== null;
  // Don't show tax amount if next price plan is displayed because it's actual only for the current price plan
  const taxAmount = hasNextPricePlan ? 0 : subscription.tax;
  const pricePlan = subscription.nextPricePlan || subscription.pricePlan;

  const isScheduledDowngrade = useMemo(() => {
    if (!hasNextPricePlan) return false;

    const isSubscriptionStatusAllowed = !DOWNGRADE_NOT_ALLOWED_STATUSES.includes(subscription.status);
    const isDowngradeScheduled = hasScheduledDowngrade(subscription.pricePlan, subscription.nextPricePlan as PricePlan);

    return hasPriceFeatures && isSubscriptionStatusAllowed && isDowngradeScheduled;
  }, [subscription, hasPriceFeatures, hasNextPricePlan]);

  const familyInfoType: FamilyInfoType = useMemo(() => {
    // We shouldn't display FamilyOwner info block when family owner changes plan from family and has a scheduled plan
    // change. In this case `isUserFamilyOwner` is still `true`, but the `nextPricePlan` will no longer be a family plan.
    const isFamilyOwnerInfoAvailable = isFamilyOwner && isFamilyPricePlan(pricePlan);
    if (isFamilyOwnerInfoAvailable) return 'owner';

    if (isFamilyMember && hasPriceFeatures) return 'member';

    return isFamilyMember ? 'oldMember' : null;
  }, [isFamilyOwner, isFamilyMember, pricePlan]);

  const isCurrentAiExpertPlan = isAiExpertPricePlan(subscription.pricePlan.tierType);
  // rely on plan when user in state is not updated
  // isFamilyMember and isFamilyOwner are not updated in the state after successful registration
  const isFamilyPlan = isFamilyPricePlan(subscription.pricePlan);

  const isUpgradeToAiPlanAvailable = !isFamilyPlan
    && !isFamilyMember
    && !isFamilyOwner
    && !isSubscriptionNew
    && !isSubscriptionBlocked
    && !isSubscriptionFree
    && !isSubscriptionCancelled
    && !isCurrentAiExpertPlan;

  const contextValue = {
    hasPriceFeatures,
    isFamilyMember,
    isFamilyOwner,
    isPaymentMethodCreated,
    isScheduledDowngrade,
    taxAmount,
    familyInfoType,
    pricePlan: isScheduledDowngrade ? subscription.pricePlan : pricePlan,
    subscription,
    isUpgradeToAiPlanAvailable,
  };

  return (
    <SubscriptionContext.Provider value={contextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);

  if (context === undefined) {
    throw new Error('useSubscriptionInfo must be used within a SubscriptionInfoProvider');
  }

  return context;
};
