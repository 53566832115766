import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Button } from '@setapp/ui-kit';

import type { Device } from 'state/devices/devices-initial-state';

import type { DeviceSeat } from 'services/devices-to-seats-mapper/devices-to-seats-mapper';

import DeviceListItem from 'components/pages/devices/devices-list/device-list-item';

interface IosDevicesBlockProps {
  devicesInSeat: DeviceSeat[];
  iosSeatsCount?: number;
  iosEuSeatsCount?: number;
  isIosOnlyUser: boolean | null;
  isUserFamilyMember?: boolean;
  onDisconnectDevice: (device: Device) => void;
  onDisconnectAllIos: () => void;
}

const IosDevicesBlock: FC<IosDevicesBlockProps> = (props) => {
  const {
    devicesInSeat,
    iosSeatsCount,
    iosEuSeatsCount,
    isIosOnlyUser,
    isUserFamilyMember,
    onDisconnectDevice,
    onDisconnectAllIos,
  } = props;

  const iosDevicesInSeat = devicesInSeat.filter((deviceInSeat) => {
    if (deviceInSeat.device && deviceInSeat.device.platform !== 'ios') return false;

    return deviceInSeat;
  }).slice(0, iosSeatsCount ?? 0);
  const iosEuDevicesInSeat = devicesInSeat.filter((deviceInSeat) => {
    if (deviceInSeat.device && deviceInSeat.device.platform !== 'ios_eu') return false;

    return deviceInSeat;
  }).slice(0, iosEuSeatsCount ?? 0);

  const shouldShowIosDevices = iosDevicesInSeat.length > 0;
  const shouldShowIosEuDevices = iosEuDevicesInSeat.length > 0;

  if (!shouldShowIosDevices && !shouldShowIosEuDevices) return null;

  const getIosDescription = () => {
    if (isIosOnlyUser) {
      return (
        <FormattedMessage
          id="devicesPage.connectIosDevice.iosOnly"
          defaultMessage="Your iPhone or iPad will appear here once you install your first iOS app."
        />
      );
    }

    if (isUserFamilyMember) {
      return (
        <FormattedMessage
          id="devicesPage.connectIosDeviceFamily"
          defaultMessage="Get iOS apps via Setapp desktop app or in iOS apps tab on my.setapp.com."
        />
      );
    }

    return (
      <FormattedMessage
        id="devicesPage.connectIosDevice"
        defaultMessage="For iPhone and iPad, install iOS apps from your Setapp account."
      />
    );
  };

  return (
    <>
      <div
        className={classNames('devices-page__ios-list-title h5 mb-2', {
          'mt-10': !isIosOnlyUser,
        })}
      >
        <FormattedMessage
          id="devicesPage.iosDevices"
          defaultMessage="Your iOS {iosSeatsCount, plural, one {device} other {devices}}"
          values={{ iosSeatsCount }}
        />
        <Button
          variant="link"
          className="devices-page__disconnect-ios-btn"
          onClick={onDisconnectAllIos}
        >
          <FormattedMessage id="devicesPage.disconnectAllIos" defaultMessage="Disconnect all" />
        </Button>
      </div>
      {shouldShowIosEuDevices && (
        <>
          <div className="text_lg text_weight-bold mt-7 mb-1 devices-page__subtitle">
            <FormattedMessage
              id="devicesPage.connectIosEuDevice.subtitle"
              defaultMessage="iPhones: Setapp Mobile"
            />
            <span className="devices-page__beta-label text_xs text_weight-medium">Beta</span>
          </div>
          <div className="text_color-secondary mb-6">
            <FormattedMessage
              id="devicesPage.connectIosEuDevice.description"
              defaultMessage="For iPhone, install iOS apps via Setapp Mobile, our new alternative app marketplace for the EU."
            />
          </div>
          {iosEuDevicesInSeat.map((deviceSeat, id) => (
            <DeviceListItem
              key={id}
              deviceSeat={deviceSeat}
              onDisconnectClick={onDisconnectDevice}
              platform="ios_eu"
            />
          ))}
        </>
      )}
      {shouldShowIosDevices && (
        <>
          {shouldShowIosEuDevices && (
            <div className="text_lg text_weight-bold mt-7 mb-1">
              <FormattedMessage
                id="devicesPage.connectIosDevice.subtitle"
                defaultMessage="iPhones and iPads"
              />
            </div>
          )}
          <div className="text_color-secondary mb-6">
            {getIosDescription()}
          </div>
          <ul className="devices-page__list">
            {iosDevicesInSeat.map((deviceSeat, id) => (
              <li key={id} className="mb-2">
                <DeviceListItem
                  deviceSeat={deviceSeat}
                  onDisconnectClick={onDisconnectDevice}
                  platform="ios"
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default IosDevicesBlock;
