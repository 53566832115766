import React, { useState } from 'react';
import classnames from 'classnames';

import ButtonBack from 'components/shared/button-back/button-back';

import IntroStep from './intro-step/intro-step';
import SurveyStep from './survey-step/survey-step';
import TooExpensiveConfirmationStep from './too-expensive-confirmation-step/too-expensive-confirmation-step';
import BuyAppsSeparatelyConfirmationStep from './buy-apps-separately-confirmation-step/buy-apps-separately-confirmation-step';
import DoNotUseOftenConfirmationStep from './do-not-use-often-confirmation-step/do-not-use-often-confirmation-step';

import './cancel-subscription-flow.scss';


export enum CancellationReason {
  TOO_EXPENSIVE = 'tooExpensive',
  BUY_APPS_SEPARATELY = 'buyAppsSeparately',
  DO_NOT_USE_OFTEN = 'doNotUseOften',
  SWITCH_TO_ANOTHER_PLAN = 'switchToAnotherPlan',
  OTHER = 'other',
}

type Step = 'introStep'
  | 'surveyStep'
  | 'tooExpensiveConfirmationStep'
  | 'buyAppsSeparatelyConfirmationStep'
  | 'doNotUseOftenConfirmationStep';

const BACK_STEP_MAPPING: {[key in Step]?: Step} = {
  surveyStep: 'introStep',
  tooExpensiveConfirmationStep: 'surveyStep',
  buyAppsSeparatelyConfirmationStep: 'surveyStep',
  doNotUseOftenConfirmationStep: 'surveyStep',
};

export type Props = {
  userId: number | null;
  onCloseButtonClick: () => void;
  onCancelSubscriptionButtonClick: () => Promise<void>;
  onManagePlanButtonClick: () => void;
  onReadHowToSaveMoreButtonClick: () => void;
  onLookMoreAppsButtonClick: () => void;
  onReadWalkthroughButtonClick: () => void;
}

const CancelSubscriptionFlow = (props: Props) => {
  const [currentStep, setCurrentStep] = useState<Step>('introStep');
  const [cancellationReason, setCancellationReason] = useState<CancellationReason>();
  const [customCancellationReason, setCustomCancellationReason] = useState<string>('');

  const {
    userId,
    onCloseButtonClick,
    onCancelSubscriptionButtonClick,
    onManagePlanButtonClick,
    onReadHowToSaveMoreButtonClick,
    onLookMoreAppsButtonClick,
  } = props;

  const handleIntroStepContinueButtonClick = () => setCurrentStep('surveyStep');

  const handleSurveyStepContinueButtonClick = () => {
    switch (cancellationReason) {
      case CancellationReason.TOO_EXPENSIVE: setCurrentStep('tooExpensiveConfirmationStep'); break;
      case CancellationReason.BUY_APPS_SEPARATELY: setCurrentStep('buyAppsSeparatelyConfirmationStep'); break;
      case CancellationReason.DO_NOT_USE_OFTEN: setCurrentStep('doNotUseOftenConfirmationStep'); break;
      case CancellationReason.SWITCH_TO_ANOTHER_PLAN: onManagePlanButtonClick(); break;
      case CancellationReason.OTHER: onCancelSubscriptionButtonClick();
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'introStep': return (
        <IntroStep
          onContinueButtonClick={handleIntroStepContinueButtonClick}
          onCloseButtonClick={onCloseButtonClick}
        />
      );
      case 'surveyStep': return (
        <SurveyStep
          cancellationReason={cancellationReason}
          customCancellationReason={customCancellationReason}
          onOptionChange={setCancellationReason}
          onOtherReasonTextareaChange={setCustomCancellationReason}
          userId={userId}
          onContinueButtonClick={handleSurveyStepContinueButtonClick}
          onCloseButtonClick={onCloseButtonClick}
        />
      );
      case 'tooExpensiveConfirmationStep': return (
        <TooExpensiveConfirmationStep
          onReadHowToSaveMoreButtonClick={onReadHowToSaveMoreButtonClick}
          onManagePlanButtonClick={onManagePlanButtonClick}
          onCancelSubscriptionButtonClick={onCancelSubscriptionButtonClick}
        />
      );
      case 'buyAppsSeparatelyConfirmationStep': return (
        <BuyAppsSeparatelyConfirmationStep
          onLookMoreAppsButtonClick={onLookMoreAppsButtonClick}
          onCancelSubscriptionButtonClick={onCancelSubscriptionButtonClick}
        />
      );
      case 'doNotUseOftenConfirmationStep': return (
        <DoNotUseOftenConfirmationStep
          onLookMoreAppsButtonClick={onLookMoreAppsButtonClick}
          onCancelSubscriptionButtonClick={onCancelSubscriptionButtonClick}
        />
      );
      default: return null;
    }
  };

  const showPreviousStep = () => setCurrentStep(BACK_STEP_MAPPING[currentStep] as Step);

  return (
    <div
      className={classnames('cancel-subscription-flow', {
        'cancel-subscription-flow__without-back-button': currentStep === 'introStep',
      })}
    >
      <div className="cancel-subscription-flow__step">
        { currentStep !== 'introStep' && (
          <ButtonBack
            onClick={showPreviousStep}
            className="mb-2"
            data-qa="backButton"
          />
        )}
        { renderStep() }
      </div>
    </div>
  );
};

export default CancelSubscriptionFlow;
