import initialState, { AppsState } from './apps-initial-state';
import * as actionTypes from './apps-action-types';

const apps = (state: AppsState = initialState, action: actionTypes.AppsAction): AppsState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { list: [...state.list], isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      return { list: [...action.payload], isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { list: [...state.list], isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default apps;

export const getList = (state: AppsState) => state.list;
