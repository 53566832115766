import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PanelGroupCollapsible } from '@setapp/ui-kit';
import { FormattedPrice, FormattedPeriod } from 'components/shared/formatter/formatter';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

type Props = {
  pricePlan: PricePlan;
  maxFamilySeats?: number;
};

const FamilyPlanFaq = ({ maxFamilySeats, pricePlan: { paidMonth, price, currency } }: Props) => {
  const panels = [
    {
      title: <FormattedMessage
        id="familyPlan.faq.membersQuestion"
        defaultMessage="How many people can I add to Setapp Family?"
             />,
      body: <FormattedMessage
        id="familyPlan.faq.membersAnswer"
        defaultMessage="You can invite up to {seats} {seats, plural, one {person} other {people}}. They need to be not registered with Setapp."
        values={{
          seats: maxFamilySeats,
        }}
            />,
    },
    {
      title: <FormattedMessage
        id="familyPlan.faq.costQuestion"
        defaultMessage="How much does it cost?"
             />,
      body: <FormattedMessage
        id="familyPlan.faq.costAnswer"
        defaultMessage="Family plan costs {price}/{period}, regardless of how many people use it."
        values={{
          price: <FormattedPrice
            price={price}
            currency={currency}
                 />,
          period: <FormattedPeriod paidMonth={paidMonth} />,
        }}
            />,
    },
    {
      title: <FormattedMessage
        id="familyPlan.faq.payerQuestion"
        defaultMessage="Who pays for the Family plan?"
             />,
      body: <FormattedMessage
        id="familyPlan.faq.payerAnswer"
        defaultMessage="Family owner, the one who creates a Family. To become an owner, click “Switch to Family plan.” Invited Family members don’t pay for subscription."
            />,
    },
    {
      title: <FormattedMessage
        id="familyPlan.faq.inviteQuestion"
        defaultMessage="How do I add people to Setapp Family?"
             />,
      body: <FormattedMessage
        id="familyPlan.faq.inviteAnswer"
        defaultMessage="Once you switch to Family plan, you’ll be able to send invites by email. Keep in mind that invitees need to be new to Setapp, we can’t add already registered customers to your family yet."
            />,
    },
    {
      title: <FormattedMessage
        id="familyPlan.faq.changeQuestion"
        defaultMessage="Can I change people in the Family?"
             />,
      body: <FormattedMessage
        id="familyPlan.faq.changeAnswer"
        defaultMessage="Sure, you can add or remove members anytime."
            />,
    },
    {
      title: <FormattedMessage
        id="familyPlan.faq.membershipQuestion"
        defaultMessage="Can I invite friends, not just family members?"
             />,
      body: <FormattedMessage
        id="familyPlan.faq.membershipAnswer"
        defaultMessage="We created Family plan for households but you can invite your friends as well."
            />,
    },
  ];

  return <PanelGroupCollapsible panels={panels} allWithCaret accordion />;
};

export default FamilyPlanFaq;
