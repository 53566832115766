import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import './referral-signup-promo.scss';

import setappDesktop from 'components/shared/shared-images/mac-tablet-iphone@1x.png';
import setappDesktop2x from 'components/shared/shared-images/mac-tablet-iphone@2x.png';
import setappDesktop3x from 'components/shared/shared-images/mac-tablet-iphone@3x.png';

import giftIcon from './images/gift.svg';

type Props = {
  appsCount: number;
  trialLength: number;
  monthlyPrice: ReactNode;
};

const ReferralSignupPromo = ({ appsCount, trialLength, monthlyPrice }: Props) => {
  const appsNumber = (Math.floor(appsCount / 10)) * 10;

  return (
    <div className="referral-signup-promo">
      <div className="referral-signup-promo__container">
        <div className="referral-signup-promo__illustration">
          <div className="referral-signup-promo__label">
            <strong>
              <FormattedMessage
                id="referralSignup.promo.labelText"
                defaultMessage="Get a free month after you subscribe"
              />
            </strong>
            <img
              className="referral-signup-promo__icon ml-3"
              src={giftIcon}
              alt=""
            />
          </div>
          <img
            className="referral-signup-promo__image"
            src={setappDesktop}
            srcSet={`${setappDesktop2x} 2x, ${setappDesktop3x} 3x`}
            alt=""
            width="596"
            height="351"
          />
        </div>
        <div className="h4 referral-signup-promo__title">
          <FormattedMessage
            id="referralSignup.promo.title"
            defaultMessage="{appsNumber}+ Mac and iOS apps in one place"
            values={{ appsNumber }}
          />
        </div>
        <div className="referral-signup-promo__subtitle">
          <FormattedMessage
            id="referralSignup.promo.subtitle"
            defaultMessage="Get access to all of them in one subscription at a single price. The first {trialLength} days are free, then {monthlyPrice}/mo."
            // @ts-expect-error TS(2769): No overload matches this call.
            values={{ trialLength, monthlyPrice }}
          />
        </div>
        <p className="referral-signup-promo__advantages">
          <FormattedMessage
            id="referralSignup.promo.advantagesInfo"
            defaultMessage="If you continue with Setapp after your trial ends, both you and the friend who invited you will get a free month. That’s our referral program reward."
          />
        </p>
      </div>
    </div>
  );
};

export default ReferralSignupPromo;
