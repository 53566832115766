// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';

import InfoButton from 'components/shared/info-button/info-button';
import PaymentForm from 'components/shared/payment-form/payment-form';
import TaxInfo from 'components/shared/tax-info/tax-info';

type Props = {
  seatsCount: number;
  withPaymentDetailsForm: boolean;
  isProcessing: boolean;
  onConfirm: () => void;
  errorMessage?: ReactElement;
};

const AdditionalSeatsCheckout = (props: Props) => {
  const {
    seatsCount,
    onConfirm,
    isProcessing,
    withPaymentDetailsForm,
    errorMessage,
  } = props;

  const confirmButtonTitle = <FormattedMessage id="buyAdditionalSeat.payButtonTitle" defaultMessage="Pay now" />;
  const confirmButtonLoadingTitle = <FormattedMessage
    id="buyAdditionalSeat.paymentDetailsAreSaving"
    defaultMessage="Payment in progress…"
                                    />;

  return (
    <>
      <div className="additional-seats-checkout__description text_lg mt-6" data-qa="buyAdditionalSeatsCheckoutInfo">
        <FormattedMessage
          id="buyAdditionalSeat.checkoutDescription"
          defaultMessage="You will be charged a part of the fee for the remaining billing cycle"
        />
        &nbsp;
        <InfoButton>
          <FormattedMessage
            id="buyAdditionalSeat.checkoutPartialPaymentDescription"
            defaultMessage="To add {seatsCount, plural, one {1 device} other {{seatsCount} more devices}} right now, you need to cover the price difference for the rest of the current billing cycle."
            values={{
              seatsCount,
            }}
          />
        </InfoButton>
      </div>

      {errorMessage && (
        <div className="mt-8 text_sm text_color-danger" data-testid="errorMessage">
          {errorMessage}
        </div>
      )}


      <div className={errorMessage ? 'mt-3' : 'mt-8'}>
        {
          withPaymentDetailsForm ? (
            <PaymentForm
              braintreeFormOptions={{
                submitBtnTitle: confirmButtonTitle,
                loadingText: confirmButtonLoadingTitle,
              }}
              onSuccessSubmit={onConfirm}
            />
          ) : (
            <ButtonLoading
              block
              size="lg"
              disabled={isProcessing}
              isLoading={isProcessing}
              loadingText={confirmButtonLoadingTitle}
              onClick={onConfirm}
              data-qa="buyAdditionalSeatsCheckoutButton"
            >
              {confirmButtonTitle}
            </ButtonLoading>
          )
        }
      </div>

      <div className="mt-5">
        <TaxInfo />
      </div>
    </>
  );
};

export default AdditionalSeatsCheckout;
