import React from 'react';
import type { ReactNode } from 'react';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';


import './signup-default.scss';

type Props = {
  children?: ReactNode;
};

const SignupDefault = ({ children }: Props) => (
  <Row className="signup-default">
    <Col sm={12}>
      {children}
    </Col>
  </Row>
);

export default SignupDefault;
