import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Modal from 'components/shared/modal/modal';
import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';
import DefaultError from 'components/shared/default-error/default-error';

import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { hideModal } from 'state/modal/modal-reducer';

import { fetchActivationUrl } from 'utils/activate-ios-helpers';
import ActivateIosAppContent from './activate-ios-app-content/activate-ios-app-content';

export type Props = Readonly<{
  iosApp: IosApp;
  show: boolean;
  onHide: () => void;
  onExited?: () => void;
}>;

const ActivateIosAppModal = ({ show, iosApp, onHide, onExited }: Props) => {
  const dispatch = useDispatch();
  const [activationUrl, setActivationUrl] = useState('');

  useEffect(() => {
    const getActivationUrl = async () => {
      try {
        const activationUrl = await fetchActivationUrl(iosApp, 'qr_web');
        setActivationUrl(activationUrl);
      } catch {
        dispatch(hideModal());
        dispatch(showDangerNotification(<DefaultError />));
      }
    };

    getActivationUrl();
  }, [dispatch, iosApp]);

  const desktopTitle = (
    <FormattedMessage
      id="activateIosAppModal.desktopTitle"
      defaultMessage="Get {appName} on iOS in 2 step"
      values={{ appName: iosApp.name }}
    />
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExited}
      title={desktopTitle}
    >
      {activationUrl ? (
        <ActivateIosAppContent
          iosApp={iosApp}
          activationUrl={activationUrl}
        />
      ) : (
        <AppLayoutLoading />
      )}
    </Modal>
  );
};

export default ActivateIosAppModal;
