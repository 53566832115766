import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { getUser } from 'state/root-reducer';

import ButtonBack from 'components/shared/button-back/button-back';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentForm from 'components/shared/payment-form/payment-form';
import TaxInfo from 'components/shared/tax-info/tax-info';

import './signup-payment-details-form.scss';

export type Props = Readonly<{
  pricePlan: PricePlan;
  isUpsaleFlow: boolean;
  onPaymentDetailsAdded: () => void;
  onBackButtonClick: () => void;
}>;

const SignupPaymentDetailsForm = ({
  isUpsaleFlow,
  onPaymentDetailsAdded,
  onBackButtonClick,
  pricePlan,
}: Props) => {
  const isDesktopCcRequired = useSelector(getUser).desktopCcRequired;

  return (
    <div className="signup-payment-details-form__container">
      <div className="signup-payment-details-form">
        <ButtonBack onClick={onBackButtonClick} />
        <h3 className="mb-4">
          <PaymentDetailsActionText action="add" />
        </h3>

        { /* TODO: submit button title doesn't changes for BrainTreeCreditCardForm */ }
        <PaymentForm
          onSuccessSubmit={onPaymentDetailsAdded}
          braintreeFormOptions={{
            submitBtnTitle: isUpsaleFlow
              ? (
                <FormattedMessage
                  id="addPaymentMethod.submitBtnTitle.upsale"
                  defaultMessage="Buy now"
                />
              ) : null,
          }}
          paymentDetailsOptions={{ sync: isDesktopCcRequired }}
          paymentMethodOptions={{
            amount: isUpsaleFlow ? pricePlan.price : 0,
            currencyCode: isUpsaleFlow ? pricePlan.currency : 'USD',
          }}
        />

        <div className="mt-5">
          <TaxInfo />
        </div>
      </div>
    </div>
  );
};

export default SignupPaymentDetailsForm;
