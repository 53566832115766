import { Invoice } from './invoices-initial-state';

export const REQUEST = 'INVOICES_REQUEST';
export const REQUEST_SUCCESS = 'INVOICES_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'INVOICES_REQUEST_ERROR';

export type InvoicesRequestAction = {
  type: 'INVOICES_REQUEST';
};

export type InvoicesRequestSuccessAction = {
  type: 'INVOICES_REQUEST_SUCCESS';
  payload: Invoice[];
};

export type InvoicesRequestErrorAction = {
  type: 'INVOICES_REQUEST_ERROR';
};

export type InvoicesAction = InvoicesRequestAction | InvoicesRequestSuccessAction | InvoicesRequestErrorAction;
