import React, { Component } from 'react';
import type { ElementRef, FormEvent, ReactElement, ReactNode } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import RequestError from '@setapp/request-error';

import CaptchaField from '../captcha-field/captcha-field';
import CaptchaError from '../captcha-error/captcha-error';

type FormFields = Record<string, string | null>;

type FieldsErrors = Record<string, ReactNode>;

type State<Fields = FormFields> = {
  fields: Fields;
  fieldsErrors: FieldsErrors;
  formError: ReactNode;
  isProcessing: boolean;
  requireCaptcha: boolean;
};

type ChildrenProps<Fields> = Omit<State<Fields>, 'requireCaptcha'> & {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onFieldChange: (event: FormEvent<HTMLInputElement>) => void;
  captcha: ReactElement<typeof CaptchaField> | null;
  formContainer: {
    submitForm: () => Promise<void>;
    setField: (name: string, value: string) => void;
    setFieldsErrors: (fieldsErrors: FieldsErrors) => void;
    setFormError: (error: ReactNode) => void;
    setProcessing: (isProcessing: boolean) => void;
  };
};

type Props<Fields = FormFields> = {
  children: (child: ChildrenProps<Fields>) => ReactNode;
  initialValues: Fields;
  onSubmit: (fields: Fields) => void;
  onSubmitSuccess?: () => void;
  validate?: (fields: Fields) => FieldsErrors;
};

const CAPTCHA_FIELD_NAME = 'captcha';

class FormContainer<Fields extends object = FormFields> extends Component<Props<Fields>, State<Fields>> {
  captcha?: ElementRef<typeof ReCaptcha>;

  static defaultProps = {
    validate: null,
    onSubmitSuccess: null,
  };

  constructor(props: Props<Fields>) {
    super(props);

    this.state = {
      fields: props.initialValues,
      fieldsErrors: {},
      formError: '',
      isProcessing: false,
      requireCaptcha: false,
    };
  }

  render() {
    const {
      fields,
      fieldsErrors,
      formError,
      isProcessing,
      requireCaptcha,
    } = this.state;
    const { children } = this.props;

    return children({
      fields,
      fieldsErrors,
      formError,
      isProcessing,
      captcha: requireCaptcha ? this.renderCaptchaField() : null,
      onFieldChange: this.onFieldChange,
      onSubmit: this.onSubmit,

      formContainer: {
        submitForm: this.submitForm,
        setField: this.setField,
        setFieldsErrors: this.setFieldsErrors,
        setFormError: this.setFormError,
        setProcessing: this.setProcessing,
      },
    });
  }

  renderCaptchaField() {
    const { fieldsErrors } = this.state;
    const { captcha: captchaError } = fieldsErrors;

    return <CaptchaField
      errorMessage={captchaError}
      onChange={this.onCaptchaChange}
      setCaptchaRef={this.setCaptchaRef}
           />;
  }

  // @ts-expect-error TS(2304): Cannot find name 'SyntheticEvent'.
  onFieldChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const input = event.currentTarget;

    // TODO: boolean support
    this.setField(input.name, input.value);
  };

  onCaptchaChange = (value: string | null) => {
    if (value) {
      this.setField(CAPTCHA_FIELD_NAME, value);
    }
  };

  // @ts-expect-error TS(2304): Cannot find name 'SyntheticEvent'.
  onSubmit = (event: SyntheticEvent<HTMLFormElement>): Promise<mixed> => {
    event.preventDefault();

    return this.submitForm();
  };

  submitForm = (): Promise<void> => {
    const { onSubmit, validate } = this.props;
    const { fields, requireCaptcha } = this.state;

    const newState = {
      // remove form error on submitting
      formError: '',
      fieldsErrors: {},
      isProcessing: true,
    };

    if (requireCaptcha && !fields['captcha']) {
      newState.fieldsErrors = {
        ...newState.fieldsErrors,
        captcha: <CaptchaError.RequiredMessage />,
      };
    }

    if (validate) {
      // set validation result as fields errors
      newState.fieldsErrors = {
        ...newState.fieldsErrors,
        ...this.filterValidationResult(validate(fields)),
      };
    }

    const isFormValid = Object.keys(newState.fieldsErrors).length === 0;
    newState.isProcessing = isFormValid;

    this.setState(newState);

    if (!isFormValid) {
      return Promise.resolve();
    }

    return Promise.resolve(onSubmit(fields))
      .then(this.onSubmitSuccess)
      .catch(this.onSubmitError);
  };

  setField = (name: string, value: string) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };

  setFieldsErrors = (fieldsErrors: FieldsErrors) => {
    this.setState({ fieldsErrors });
  };

  setFormError = (error: ReactNode) => this.setState({ formError: error });

  setProcessing = (isProcessing: boolean) => {
    this.setState({ isProcessing });
  };

  /**
   * Temporary filtering. Validator returns empty string if there's no field error but this component
   * requires only fields with errors to present in "fieldsErrors" object.
   * Remove when all forms use this component and validator logic is updated
   */
  filterValidationResult(errors: FieldsErrors) {
    return Object.keys(errors).reduce((filteredErrors, fieldName) => {
      if (!errors[fieldName]) {
        return filteredErrors;
      }

      return {
        ...filteredErrors,
        [fieldName]: errors[fieldName],
      };
    }, {});
  }

  onSubmitSuccess = () => {
    // if we have history.push inside onSubmitSuccess, then
    // we don't want to change state after component is unmounted
    if (this.props.onSubmitSuccess) {
      this.props.onSubmitSuccess();
    } else {
      this.setState({ isProcessing: false });
    }
  };

  onSubmitError = (error: RequestError) => {
    if (this.captcha) {
      this.captcha.reset();
    }

    const genericError = error.getGenericError();
    const fieldsErrors = this.getRegisteredFieldsErrors(error);
    const captchaError = error.getFieldError(CAPTCHA_FIELD_NAME);

    // Rewrite error texts from the API response with the custom message
    if (captchaError) {
      (fieldsErrors as $TSFixMe).captcha = <CaptchaError error={captchaError} />;
    }

    this.setState((state) => ({
      isProcessing: false,
      fieldsErrors,
      formError: (genericError && genericError.toString())
        || Object.values(this.getExtraFieldsErrors(error)).join(' ')
        || '',
      requireCaptcha: state.requireCaptcha || Boolean(captchaError),
    }));
  };

  getRegisteredFieldsErrors(requestError: RequestError): FieldsErrors {
    const { fields } = this.state;
    const responseErrors = requestError.getSimplifiedErrors();

    return Object.keys(fields as FormFields).reduce((fieldsErrors, fieldName) => {
      if (!responseErrors.fieldsErrors || responseErrors.fieldsErrors[fieldName] == null) {
        return fieldsErrors;
      }

      return {
        ...fieldsErrors,
        [fieldName]: responseErrors.fieldsErrors[fieldName],
      };
    }, {});
  }

  getExtraFieldsErrors(requestError: RequestError): FieldsErrors {
    const { fields } = this.state;
    const responseErrors = requestError.getSimplifiedErrors();

    if (!responseErrors.fieldsErrors) {
      return {};
    }

    return Object.keys(responseErrors.fieldsErrors)
      // Keep only fields that don't exist in the form and ignore CAPTCHA error as it's handled separately
      .filter((fieldName) => !(fieldName in fields) && fieldName !== CAPTCHA_FIELD_NAME)
      .reduce((fieldsErrors, fieldName) => ({
        ...fieldsErrors,
        [fieldName]: responseErrors.fieldsErrors[fieldName],
      }), {});
  }

  // TODO: use ref forwarding after React is updated to v16.3
  setCaptchaRef = (ref: ElementRef<typeof ReCaptcha>) => {
    this.captcha = ref;
  }
}

export default FormContainer;
