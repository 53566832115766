import type { FamilyPlanState, FamilyMember } from './family-plan-initial-state';
import type { FamilyAction } from './family-plan-action-types';
import * as actionTypes from './family-plan-action-types';
import * as familyPlanStatuses from './family-plan-statuses';
import initialState from './family-plan-initial-state';

const familyPlan = (state: FamilyPlanState = initialState, action: FamilyAction): FamilyPlanState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }

      return { ...state, ...action.payload, isLoading: false };
    }

    case actionTypes.ADD_MEMBER: {
      return { ...state, members: [...state.members, { ...action.payload }], isLoading: false };
    }

    case actionTypes.REMOVE_MEMBER: {
      return { ...state, members: state.members.filter((member) => member.id !== action.payload) };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, error: action.payload, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default familyPlan;

export const isFetched = (state: FamilyPlanState): boolean => state.status != null;
export const isPendingStatus = (state: FamilyPlanState): boolean => state.status === familyPlanStatuses.PENDING;
export const isActiveStatus = (state: FamilyPlanState): boolean => state.status === familyPlanStatuses.ACTIVE;
export const isInactiveStatus = (state: FamilyPlanState): boolean => state.status === familyPlanStatuses.INACTIVE;
export const isFull = (state: FamilyPlanState): boolean => {
  const allowableSeatsNumber = state.seats,
    currentPlainMembersNumber = state.members.filter((member) => !member.isOwner).length;

  return currentPlainMembersNumber >= allowableSeatsNumber;
};
export const getMembersExceptCurrent = (state: FamilyPlanState): Array<FamilyMember> => state.members
  .filter((member) => !member.isCurrentUser);
export const availableSeats = (state: FamilyPlanState): number => {
  const allowableSeatsNumber = state.seats,
    currentPlainMembersNumber = state.members.filter((member) => !member.isOwner).length;

  return allowableSeatsNumber - currentPlainMembersNumber;
};
export const getOwner = (state: FamilyPlanState): FamilyMember | undefined => {
  return state.members.find((member) => member.isOwner);
};
