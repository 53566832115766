import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';

import urls from 'config/external-urls';

import facebookIcon from './images/facebook.svg';
import xIcon from './images/x.svg';
import instagramIcon from './images/instagram.svg';
import youtubeIcon from './images/youtube.svg';
import discordIcon from './images/discord.svg';

import './footer-social.scss';

const messages = defineMessages({
  facebookLabel: {
    id: 'footer.facebookLabel',
    defaultMessage: 'Setapp on Facebook',
  },
  xLabel: {
    id: 'footer.xLabel',
    defaultMessage: 'Setapp on X',
  },
  instagramLabel: {
    id: 'footer.instagramLabel',
    defaultMessage: 'Setapp on Instagram',
  },
  youtubeLabel: {
    id: 'footer.youtubeLabel',
    defaultMessage: 'Setapp on YouTube',
  },
  discordLabel: {
    id: 'footer.discordLabel',
    defaultMessage: 'Setapp on Discord',
  },
});

const FooterSocial = ({ intl }: InjectedIntlProps) => {
  const socialLinks = [
    {
      path: urls.x,
      ariaLabel: intl.formatMessage(messages.xLabel),
      icon: xIcon,
    },
    {
      path: urls.youtube,
      ariaLabel: intl.formatMessage(messages.youtubeLabel),
      icon: youtubeIcon,
    },
    {
      path: urls.instagram,
      ariaLabel: intl.formatMessage(messages.instagramLabel),
      icon: instagramIcon,
    },
    {
      path: urls.facebook,
      ariaLabel: intl.formatMessage(messages.facebookLabel),
      icon: facebookIcon,
    },
    {
      path: urls.discord,
      ariaLabel: intl.formatMessage(messages.discordLabel),
      icon: discordIcon,
    },
  ];

  return (
    <ul className="footer-social">
      {socialLinks.map((link) => (
        <li className="footer-social__item" key={link.path}>
          <a
            className="footer-social__link"
            href={link.path}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={link.ariaLabel}
          >
            <img src={link.icon} alt="" />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default injectIntl(FooterSocial);
