import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
} from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import type { RouteComponentProps } from 'react-router-dom';
import { AnimatedLogo, Button } from '@setapp/ui-kit';

import externalUrls from 'config/external-urls';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import goodbyeImage from './images/goodbye.svg';

import './account-deleted-page.scss';

const messages = defineMessages({
  secondaryImageAlt: {
    id: 'accountDeleted.secondaryImageAlt',
    defaultMessage: 'A man sadly waving in farewell',
  },
  logoLinkDescription: {
    id: 'accountDeleted.logoLinkDescription',
    defaultMessage: 'Take me to Setapp.com',
  },
});

type Props = RouteComponentProps & InjectedIntlProps;

const AccountDeletedPage = ({ intl }: Props) => (
  <FullscreenLayout>
    <FullscreenLayout.PrimaryContent>
      <a
        className="account-deleted-page__logo"
        href={externalUrls.setappSite}
        aria-label={intl.formatMessage(messages.logoLinkDescription)}
      >
        <AnimatedLogo hasCaption size="sm" />
      </a>
      <div className="account-deleted-page__primary-content">
        <div className="h3" data-qa="pageTitle">
          <FormattedMessage id="accountDeleted.title" defaultMessage="Your account was deleted" />
        </div>
        <p className="text_lg">
          <FormattedMessage
            id="accountDeleted.descriptionSeeEmail"
            defaultMessage="We left one last farewell message in your inbox. And that’s a wrap-up."
          />
        </p>
        <p className="mt-6 text_lg">
          <FormattedMessage id="accountDeleted.descriptionThanks" defaultMessage="Thanks for being with us." />
        </p>
        <div className="mt-5">
          <Button
            href={externalUrls.setappSite}
          >
            <FormattedMessage id="accountDeleted.homeLink" defaultMessage="Take me to Setapp.com" />
          </Button>
        </div>
      </div>
    </FullscreenLayout.PrimaryContent>
    <FullscreenLayout.SecondaryContent>
      <div className="account-deleted-page__secondary-content">
        <img
          src={goodbyeImage}
          className="account-deleted-page__secondary-image img-responsive"
          alt={intl.formatMessage(messages.secondaryImageAlt)}
        />
      </div>
    </FullscreenLayout.SecondaryContent>
  </FullscreenLayout>
);

export default injectIntl(AccountDeletedPage);
