import React, { PureComponent, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, SelectField } from '@setapp/ui-kit';

type Props = {
  currentLocale: string;
  isLoading: boolean;
  supportedLocales: Record<string, string>;
  onSaveLocale: (locale: string) => void;
};

type State = {
  locale: string;
};

class UserLocaleForm extends PureComponent<Props, State> {
  static defaultProps = {
    isLoading: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      locale: props.currentLocale,
    };
  }

  render() {
    const { locale } = this.state;
    const { isLoading, supportedLocales } = this.props;

    return (
      <div>
        <h5 className="user-language-title">
          <FormattedMessage id="accountSettings.userLocales.title" defaultMessage="Account language" />
        </h5>

        <form onSubmit={this.onFormSubmit} noValidate>
          <SelectField
            id="locale"
            name="locale"
            value={locale}
            options={supportedLocales}
            onChange={this.onLocaleChange}
          />

          <Button
            variant="secondary-outline"
            type="submit"
            size="lg"
            disabled={isLoading}
            data-qa="updateLanguageBtn"
          >
            <FormattedMessage id="accountSettings.userLocales.updateButton" defaultMessage="Change language" />
          </Button>
        </form>
      </div>
    );
  }

  onLocaleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
    this.setState({ locale: e.currentTarget.value });
  };

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { onSaveLocale } = this.props;
    const { locale } = this.state;

    onSaveLocale(locale);
  };
}

export default UserLocaleForm;
