import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import DefaultError from 'components/shared/default-error/default-error';
import { fetchFamilyPlan } from 'state/family-plan/family-plan-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import {
  getPaymentMethod,
  getPricePlansList,
  getPrimarySubscription,
  getFamilyPlan,
  hasPriceFeatures as getHasPriceFeatures,
  isUserFamilyOwner as getIsUserFamilyOwner,
  isUserPlainFamilyMember as getIsUserPlainFamilyMember,
  isFamilyFetched as getIsFamilyFetched,
  isPaymentMethodCreated as getIsPaymentMethodCreated,
} from 'state/root-reducer';

import getShouldOpenChangePlanModal from '../utils/get-should-open-change-plan-modal';

interface UseLoadSubscriptionDataInput {
  onCompleted?: () => void;
}

const useFetchSubscriptionInfoData = ({ onCompleted }: UseLoadSubscriptionDataInput = {}) => {
  const location = useLocation();

  const isUserFamilyOwner = useSelector(getIsUserFamilyOwner);
  const isUserPlainFamilyMember = useSelector(getIsUserPlainFamilyMember);
  const isPaymentMethodCreated = useSelector(getIsPaymentMethodCreated);
  const hasPriceFeatures = useSelector(getHasPriceFeatures);
  const primarySubscription = useSelector(getPrimarySubscription);
  const paymentMethod = useSelector(getPaymentMethod);
  const pricePlansList = useSelector(getPricePlansList);
  const isFamilyFetched = useSelector(getIsFamilyFetched);
  const familyPlan = useSelector(getFamilyPlan);

  const fetchSubscriptionsAction = useDispatchAction(fetchAllSubscriptions);
  const fetchPaymentMethodAction = useDispatchAction(fetchPaymentMethod);
  const fetchFamilyPlanAction = useDispatchAction(fetchFamilyPlan);
  const fetchPricePlansAction = useDispatchAction(fetchPricePlans);
  const showDangerNotificationAction = useDispatchAction(showDangerNotification);

  const isFamily = isUserFamilyOwner || isUserPlainFamilyMember;
  const isPricePlanFetched = Boolean(pricePlansList.length);
  const isSubscriptionLoading = !primarySubscription;
  const isPaymentMethodFetched = paymentMethod.isFetched;
  const isPaymentMethodLoading = !isPaymentMethodFetched || paymentMethod.isLoading;
  const isFamilyLoading = isFamily && !isFamilyFetched;

  const shouldOpenChangePlanModal = getShouldOpenChangePlanModal(location);
  const shouldFetchPricePlansForChangePlanModal = !hasPriceFeatures && shouldOpenChangePlanModal;
  const shouldFetchPricePlans = !isPricePlanFetched && (shouldFetchPricePlansForChangePlanModal || isFamily);

  const isLoading = isSubscriptionLoading
    || isPaymentMethodLoading
    || isFamilyLoading
    || (shouldFetchPricePlans ? !isPricePlanFetched : false);

  useEffect(() => {
    const dataToBeFetched = [
      ...(!primarySubscription ? [fetchSubscriptionsAction] : []),
      ...(!isPaymentMethodFetched ? [fetchPaymentMethodAction] : []),
      ...(shouldFetchPricePlans ? [fetchPricePlansAction] : []),
      ...(isFamily ? [fetchFamilyPlanAction] : []),
    ];

    Promise.all(dataToBeFetched.map((action) => action()))
      .then(() => {
        onCompleted?.();
      })
      .catch(() => {
        showDangerNotificationAction(<DefaultError />);
      });
  }, [location]);

  return {
    isLoading,
    data: {
      primarySubscription,
      paymentMethod,
      familyPricePlan: familyPlan?.price,
      isUserFamilyOwner,
      isUserPlainFamilyMember,
      isPaymentMethodCreated,
      hasPriceFeatures,
    },
  };
};

export default useFetchSubscriptionInfoData;
