import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

type Props = {
  currentPricePlan: PricePlan;
  onConfirm: () => void;
};

const ConfirmRegularPlanStep = (props: Props) => {
  const { currentPricePlan, onConfirm } = props;

  const currentPricePlanMetadata = getPricePlanMetadata(currentPricePlan);

  return (
    <>
      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="changePricePlanFlow.confirmRegularPlanStep.title"
          defaultMessage="Are you sure?"
        />
      </h3>

      <div className="mb-8 text_lg">
        <FormattedMessage
          id="changePricePlanFlow.confirmRegularPlanStep.description"
          defaultMessage="Once switched, you won't be able to get back to the {currentPlanTitle}. Still want to continue?"
          values={{ currentPlanTitle: currentPricePlanMetadata.titleFull }}
        />
      </div>

      <Button
        variant="primary"
        size="lg"
        block
        onClick={onConfirm}
      >
        <FormattedMessage
          id="changePricePlanFlow.confirmRegularPlanStep.CTA"
          defaultMessage="Confirm"
        />
      </Button>
    </>
  );
};

export default ConfirmRegularPlanStep;
