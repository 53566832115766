import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  nextPaymentDate: number;
  onConfirm: () => void;
};

const ConfirmDropFamilyStep = (props: Props) => {
  const { nextPaymentDate, onConfirm } = props;

  return (
    <>
      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="confirmDropFamilyStep.title"
          defaultMessage="Are you sure?"
        />
      </h3>

      <div className="mb-8 text_lg">
        <FormattedMessage
          id="confirmDropFamilyStep.description"
          defaultMessage="Your new plan will activate on {activationDate}. Participants of your Family plan will switch to inactive Mac plan."
          values={{
            activationDate: (
              <strong>
                <FormattedDate
                  value={nextPaymentDate * 1000}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              </strong>
            ),
          }}
        />
      </div>

      <Button
        variant="primary"
        size="lg"
        block
        onClick={onConfirm}
      >
        <FormattedMessage
          id="confirmDropFamilyStep.CTA"
          defaultMessage="Confirm"
        />
      </Button>
    </>
  );
};

export default ConfirmDropFamilyStep;
