import React from 'react';
import type { ReactElement, InputHTMLAttributes } from 'react';

import './info-radio-button.scss';

type Props = {
  children: ReactElement;
  name: string;
  value: string | number;
  qaLabel?: string;
  'aria-label'?: string;
} & InputHTMLAttributes<HTMLInputElement>

const InfoRadioButton = ({ children, 'aria-label': ariaLabel, ...inputProps }: Props) => {
  const { name, value } = inputProps;

  return (
    <div className="info-radio-button">
      <input {...inputProps} className="info-radio-button__input sr-only" type="radio" id={`${name}-${value}`} />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label className="info-radio-button__label" htmlFor={`${name}-${value}`} aria-label={ariaLabel}>
        {children}
      </label>
    </div>
  );
};

export default InfoRadioButton;
