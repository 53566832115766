import type { Campaign } from 'state/user/campaign-types';

export type CampaignConfig = {
  name: Campaign;
  discount: string;
  price: number;
  priceFull: number;
  priceCurrency: string;
  startDate: string;
  endDate: string;
};

const CAMPAIGNS: Record<Campaign, CampaignConfig> = {
  'offer_2022_setapp': {
    name: 'offer_2022_setapp',
    discount: '20%',
    price: 95.90,
    priceFull: 119.88,
    priceCurrency: 'USD',
    startDate: '2022-12-12',
    endDate: '2023-12-12',
  },
  'offer_2022_setapp_1pass': {
    name: 'offer_2022_setapp_1pass',
    discount: '30%',
    price: 109.03,
    priceFull: 155.76,
    priceCurrency: 'USD',
    startDate: '2022-12-12',
    endDate: '2023-12-12',
  },
  'offer_2022_setapp_mc': {
    name: 'offer_2022_setapp_mc',
    discount: '35%',
    price: 194.92,
    priceFull: 299.88,
    priceCurrency: 'USD',
    startDate: '2022-12-12',
    endDate: '2023-12-12',
  },
  'offer_2022_setapp_1pass_mc': {
    name: 'offer_2022_setapp_1pass_mc',
    discount: '40%',
    price: 201.46,
    priceFull: 335.77,
    priceCurrency: 'USD',
    startDate: '2022-12-12',
    endDate: '2023-12-12',
  },
};

export default CAMPAIGNS;
