import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';
import urls from 'config/urls';

import { getStoredCustomerOauthError } from 'components/pages/customer-oauth-page/utils/customer-oauth-storage';

const ChoosePricePlanAfterTrial: FC = () => (
  <div className="mt-10" data-testid="choosePricePlanAfterTrial">
    <h5>
      <FormattedMessage id="subscriptionInfo.choosePricePlanAfterTrial.title" defaultMessage="Get a Setapp plan" />
    </h5>
    <p className="mb-8">
      {getStoredCustomerOauthError() === 'TRIAL_ALREADY_USED_ON_DEVICE' ? (
        <FormattedMessage
          id="subscriptionInfo.choosePricePlanAfterTrial.description.iPhone"
          defaultMessage="Free trial has ended on your iPhone. Get a subscription plan to use Setapp on this device."
        />
      ) : (
        <FormattedMessage
          id="subscriptionInfo.choosePricePlanAfterTrial.description"
          defaultMessage="Free trial has ended on your Mac. Buy a Setapp subscription to keep using it on this device."
        />
      )}
    </p>
    <Button href={urls.activateSubscription} size="lg" variant="primary">
      <FormattedMessage id="pricePlans.choosePricePlanAfterTrial.cta" defaultMessage="Choose plan" />
    </Button>
  </div>
);

export default ChoosePricePlanAfterTrial;
