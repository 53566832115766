import React, { type FC } from 'react';

import checkIcon from './images/check-icon.svg';

import './check-icon.scss';

const CheckIcon: FC = () => (
  <div className="check-icon">
    <img src={checkIcon} alt="Available" width={16} height={16} />
  </div>
);

export default CheckIcon;
