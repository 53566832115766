import queryString from 'query-string';

import request from 'utils/request';
import { apiURL } from 'config/api';
import BaseSocialClient from './base-social-client';
import type { SocialClientInterface, SocialProfile } from './social-client-interface';

class AppleClient extends BaseSocialClient implements SocialClientInterface {
  userAccessToken?: string;

  fetchUserProfile(): Promise<SocialProfile> {
    return request.get(`${apiURL.appleUser}?${queryString.stringify({ 'access_token': this.userAccessToken })}`);
  }

  getAuthURL(state?: string): string {
    const parameters = queryString.stringify({ state });

    return `${apiURL.appleAuth}?${parameters}`;
  }

  isAuthRedirect(): boolean {
    const { 'access_token': token } = queryString.parse(window.location.hash);

    return Boolean(token);
  }

  /**
   * access_token here is a temporary code that will be exchanged to the real token
   * code will be expired after 4 minutes after creation
   */
  async processAuthRedirect() {
    const { 'access_token': accessToken = '' } = queryString.parse(window.location.hash);

    this.setUserAccessToken(accessToken as string);

    return { accessToken: accessToken as string };
  }

  getUrlState() {
    const { state } = queryString.parse(window.location.hash);

    return state || '';
  }
}

const appleClient = new AppleClient();

export default appleClient;
