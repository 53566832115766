// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'react-redux';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import * as pricePlanGroupTypes from 'state/price-plans/price-plan-groups';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { isMembershipPriceIncreaseEnabled } from 'state/root-reducer';

type Props = {
  newPlan: PricePlan;
}

const PricePlanDescription = ({ newPlan }: Props) => {
  const { percentage } = useSelector(isMembershipPriceIncreaseEnabled);

  if (newPlan.tierType === pricePlanTypes.MONTHLY) {
    return <FormattedMessage
      id="pricePlans.description.regular"
      defaultMessage="Regular subscription"
           />;
  }

  if (newPlan.group === pricePlanGroupTypes.FAMILY) {
    return <FormattedMessage
      id="pricePlans.description.shareable"
      defaultMessage="Shareable plan"
           />;
  }

  if (newPlan.paidMonth === 12) {
    return <FormattedMessage
      id="pricePlans.description.annualDiscount"
      defaultMessage="Save {percentage}"
      values={{
        percentage
      }}
           />;
  }

  return null;
};

export default PricePlanDescription;
