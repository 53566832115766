import React from 'react';
import type { ReactNode } from 'react';

import PaymentForm from 'components/shared/payment-form/payment-form';
import type { BraintreeFormOptions } from 'components/shared/payment-form/payment-form';
import Modal from 'components/shared/modal/modal';

type Props = {
  show: boolean;
  onHide: () => void;
  onExited?: () => void;
  onPaymentDetailsSaved?: () => void;
  title: ReactNode;
  braintreeFormOptions?: BraintreeFormOptions;
}

const PaymentDetailsModal = (props: Props) => {
  const {
    show,
    onHide,
    onExited,
    onPaymentDetailsSaved,
    title,
    braintreeFormOptions,
  } = props;

  return (
    <Modal
      fullScreen
      show={show}
      onHide={onHide}
      onExited={onExited}
      title={title}
    >
      <PaymentForm
        onSuccessSubmit={onPaymentDetailsSaved}
        braintreeFormOptions={braintreeFormOptions}
      />
    </Modal>
  );
};

export default PaymentDetailsModal;
