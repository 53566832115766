import React, { Fragment, useEffect } from 'react';
import { AnimatedLogo, FormSwitcher } from '@setapp/ui-kit';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { isAfter } from 'date-fns';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import Footer from 'components/layout/footer/footer';

import analytics, { events } from 'utils/analytics';

import CountdownBanner from './components/countdown-banner/countdown-banner';
import AiOfferPlanCard from './components/ai-offer-plan-card/ai-offer-plan-card';
import AiOfferBenefits from './components/ai-offer-benefits/ai-offer-benefits';
import PlansComparison from './components/plans-comparison/plans-comparison';
import faqList from './components/faq-list';
import { AI_MARATHON_1_END_DATE } from './config';

import { useAiOfferContext } from './context/ai-offer-context';

import './ai-offer-view.scss';

const AiOfferView = () => {
  const {
    plansConfig,
    selectedPlansPeriod,
    shouldShowMonthlyPlans,
    toggleSelectedPlansPeriod,
    isCountdownVisible,
    isAiSpecialOfferPage
  } = useAiOfferContext();

  useEffect(() => {
    const isEventEnded = isAfter(new Date(), AI_MARATHON_1_END_DATE);

    analytics.trackEvent(events.AI_PLUS_LANDING_PAGE_VIEW, {
      eventLabel: !isEventEnded && isAiSpecialOfferPage ? 'ai_marathon_1' : 'ordinary',
    });
  }, []);

  // Hack to fix z-index issue with Infobutton. Otherwise it is shown behind the last card
  // We reverse it back with css, so it will be displayed in the right order
  const reversedPlansConfig = [...plansConfig].reverse();

  return (
    <FullscreenLayout withLogo darkTheme>
      <div className="ai-offer">
        <div className="ai-offer__container">
          <span className="ai-offer__header">
            <h1 className="ai-offer__header-hero">
              <FormattedHTMLMessage
                id="aiOffer.header"
                defaultMessage='Achieve more with Setapp <span class="ai-offer__grad-text">AI+</span>'
              />
            </h1>
            <p className="ai-offer__header-description">
              <FormattedMessage
                id="aiOffer.description"
                defaultMessage="Streamline routines, get creative, and solve multiple tasks."
              />
            </p>
          </span>

          {isAiSpecialOfferPage && isCountdownVisible ? (
            <CountdownBanner />
          ) : <AiOfferBenefits />}

          <div className="ai-offer__plan-chooser">
            <p className="h3 ai-offer__chooser-title">
              <FormattedMessage
                id="aiOffer.planChooser.header"
                defaultMessage="Choose your plan"
              />
            </p>
            {shouldShowMonthlyPlans && (
              <FormSwitcher
                id="ai-offer-period-switcher"
                onChange={toggleSelectedPlansPeriod}
                checked={selectedPlansPeriod === 12}
              >
                <span className="ai-offer__chooser-text">
                  <FormattedMessage id="aiOffer.planChooser.annual" defaultMessage="Annual" />
                </span>
              </FormSwitcher>
            )}
          </div>

          <div className="ai-offer__plans-row">
            {plansConfig.length > 1 ? ( // There should be 2-3 plans, 1 current + 1-2 AI plans
              reversedPlansConfig.map((configItem) => (
                <AiOfferPlanCard
                  key={configItem.pricePlan.id}
                  {...configItem}
                />
              ))
            ) : (
              <div className="ai-offer__loader">
                <AnimatedLogo animate size="md" />
              </div>
            )}
          </div>

          {isCountdownVisible && isAiSpecialOfferPage && (
            <AiOfferBenefits />
          )}
        </div>

        <div className="ai-offer__inverse-background">
          <div className="ai-offer__container">
            <div className="ai-offer__plans-comparison">
              <PlansComparison />
            </div>
            <div className="ai-offer__faq">
              <h3 className="mb-8">
                <FormattedMessage
                  id="aiOffer.faq.title"
                  defaultMessage="FAQ"
                />
              </h3>
              {faqList.map(({ title, description }, idx) => (
                <Fragment key={idx}>
                  <h5 className="mb-2">{title}</h5>
                  <p className="mb-10">{description}</p>
                </Fragment>
              ))}
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </FullscreenLayout>
  );
};

export default AiOfferView;
