import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { SpecialOfferCampaign } from 'state/user/campaign-types';

type Props = {
  campaign: SpecialOfferCampaign;
};

const SpecialOfferCodeDeliveryMessage = ({ campaign }: Props) => {
  switch (campaign) {
    case 'offer_2022_setapp_1pass':
      return (
        <FormattedMessage
          id="specialOffer.productCodeDelivery.1password"
          defaultMessage="Your 1Password code is on the way to your email."
        />
      );
    case 'offer_2022_setapp_mc':
      return (
        <FormattedMessage
          id="specialOffer.productCodeDelivery.masterclass"
          defaultMessage="Your MasterClass code is on the way to your email."
        />
      );
    case 'offer_2022_setapp_1pass_mc':
      return (
        <FormattedMessage
          id="specialOffer.productCodeDelivery.1password+masterclass"
          defaultMessage="Your 1Password and MasterClass codes are on the way to your email."
        />
      );
    case 'offer_2022_setapp':
    default:
      return null;
  }
};

export default SpecialOfferCodeDeliveryMessage;
