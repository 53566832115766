import type { IosApp } from './ios-apps-initial-state';

export const REQUEST = 'IOS_APPS_REQUEST';
export const REQUEST_SUCCESS = 'IOS_APPS_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'IOS_APPS_REQUEST_ERROR';

export type IosAppsRequestAction = {
  type: 'IOS_APPS_REQUEST';
};

export type IosAppsRequestSuccessAction = {
  type: 'IOS_APPS_REQUEST_SUCCESS';
  payload: IosApp[];
};

export type IosAppsRequestErrorAction = {
  type: 'IOS_APPS_REQUEST_ERROR';
};

export type IosAppsAction = IosAppsRequestAction | IosAppsRequestSuccessAction | IosAppsRequestErrorAction;
