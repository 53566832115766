import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { FormattedPrice } from 'components/shared/formatter/formatter';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

type Props = {
  showAddPaymentDetailsStep: boolean;
  onAddPaymentDetailsClick: () => void;
  onConfirmSwitchClick: () => void;
  onModalHide: () => void;
  isTrialOrNewUser: boolean;
  switchToFamilyAmount: number;
  pricePlan: PricePlan;
  isLoading: boolean;
};

const SwitchToFamilyPlanModalContent = (props: Props) => {
  const {
    switchToFamilyAmount,
    isTrialOrNewUser,
    showAddPaymentDetailsStep,
    onAddPaymentDetailsClick,
    onConfirmSwitchClick,
    isLoading,
    onModalHide,
    pricePlan: {
      paidMonth,
      price,
      currency,
    },
  } = props;

  function getDescription() {
    if (showAddPaymentDetailsStep) {
      return (
        <FormattedMessage
          id="familyPlan.switchToFamilyPlanModal.paymentDetailsDescription"
          defaultMessage="To make the switch, we need your payment details."
        />
      );
    }

    if (isTrialOrNewUser) {
      return (
        <FormattedMessage
          id="familyPlan.switchToFamilyPlanModal.trialDescription"
          defaultMessage="You will now be switched to Family Plan and charged your first payment. Family Plan costs {price}/{paidMonth, plural, one {month} =12 {year} other {{paidMonth} months}}."
          values={{
            paidMonth,
            price: <FormattedPrice
              price={price}
              currency={currency}
                   />,
          }}
        />
      );
    }

    return (
      <FormattedMessage
        id="familyPlan.switchToFamilyPlanModal.description"
        defaultMessage="You’re about to create a Setapp Family with {paidMonth, plural, one {monthly payments of {price}} =12 {annual payments of {price}} other {payments of {price} every {paidMonth} months}}. Right now, you’ll only be charged ~{amount} + tax for the remaining part of the {paidMonth, plural, one {month} =12 {year} other {period}}."
        values={{
          paidMonth,
          amount: <FormattedPrice
            price={switchToFamilyAmount}
            currency={currency}
                  />,
          price: <FormattedPrice
            price={price}
            currency={currency}
                 />,
        }}
      />
    );
  }

  return (
    <div>
      <div className="text-center switch-to-family-plan-modal__description" data-qa="descriptionText">
        {getDescription()}
      </div>

      {showAddPaymentDetailsStep ? (
        <Button block onClick={onAddPaymentDetailsClick}>
          <FormattedMessage
            id="familyPlan.switchToFamilyPlanModal.paymentDetailsButton"
            defaultMessage="Enter payment details"
          />
        </Button>
      ) : (
        <Button block onClick={onConfirmSwitchClick} disabled={isLoading}>
          <FormattedMessage
            id="familyPlan.switchToFamilyPlanModal.confirmButton"
            defaultMessage="Confirm &amp; switch"
          />
        </Button>
      )}

      <Button
        variant="secondary-outline"
        block
        className="mt-3"
        onClick={onModalHide}
      >
        <FormattedMessage id="familyPlan.switchToFamilyPlanModal.cancelButton" defaultMessage="Cancel" />
      </Button>
    </div>
  );
};

export default SwitchToFamilyPlanModalContent;
