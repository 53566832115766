import React, { PureComponent } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

import { isFamilyPricePlan } from 'services/price-plans/utils';

import {
  getDisplayedPricePlan,
  getPrimarySubscription,
  getUser,
  hasPriceFeatures,
  isSpecialOfferCampaign,
  isSubscriptionsFetched,
} from 'state/root-reducer';
import type { State as RootState } from 'state/state-types';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';

import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import OuterLayout from 'components/layout/outer-layout/outer-layout';

import DefaultError from 'components/shared/default-error/default-error';

import SignupCcrFlow from 'components/user-flow/signup/signup-ccr-flow/signup-ccr-flow';
import SignupSpecialOfferFlow from 'components/user-flow/signup/signup-special-offer-flow/signup-special-offer-flow';
import SignupFamilyFlow from 'components/user-flow/signup/signup-family-flow/signup-family-flow';

import './signup-payment-details.scss';

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  campaign: getUser(state).campaign,
  hasPriceFeatures: hasPriceFeatures(state),
  isSpecialOfferCampaign: isSpecialOfferCampaign(state),
  isSubscriptionsFetched: isSubscriptionsFetched(state),
  mainSubscription: getPrimarySubscription(state),
  displayedPricePlan: getDisplayedPricePlan(state),
});

const mapActionsToProps = {
  fetchAllSubscriptions,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

class SignupPaymentDetails extends PureComponent<Props> {
  componentDidMount() {
    const { fetchAllSubscriptions, isSubscriptionsFetched, showDangerNotification } = this.props;

    if (!isSubscriptionsFetched) {
      fetchAllSubscriptions().catch(() => showDangerNotification(<DefaultError />));
    }
  }

  render() {
    const {
      campaign,
      hasPriceFeatures,
      isSpecialOfferCampaign,
      mainSubscription,
      displayedPricePlan,
    } = this.props;

    if (!mainSubscription || !displayedPricePlan) {
      return <FullscreenLayoutLoading />;
    }

    if (hasPriceFeatures && isFamilyPricePlan(displayedPricePlan)) {
      return (
        <OuterLayout>
          <div className="signup-payment-details">
            <SignupFamilyFlow mainSubscription={mainSubscription} />
          </div>
        </OuterLayout>
      );
    }

    if (campaign && isSpecialOfferCampaign) {
      return (
        <OuterLayout>
          <div className="signup-payment-details">
            <SignupSpecialOfferFlow campaign={campaign} />
          </div>
        </OuterLayout>
      );
    }

    return (
      <OuterLayout>
        <SignupCcrFlow />
      </OuterLayout>
    );
  }
}

export { SignupPaymentDetails as PureSignupPaymentDetails };

export default connector(SignupPaymentDetails);
