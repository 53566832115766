import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

export const PricePlanPeriod = {
  Monthly: 1,
  Annual: 12,
} as const;

export type BillingPeriodsPricePlans = {
  annualPricePlan: PricePlan | null;
  monthlyPricePlan: PricePlan | null;
}
