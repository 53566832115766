import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';

type Props = {
  isLoading: boolean;
  show: boolean;
  member: FamilyMember;
  onHide: () => void;
  onExited: () => void;
  onRemoveFamilyMemberClick: (familyMember: FamilyMember) => Promise<void>;
};

const RemoveFamilyMemberModal = ({
  member,
  isLoading,
  onHide,
  onExited,
  show,
  onRemoveFamilyMemberClick,
}: Props) => {
  const handleRemoveFamilyMemberClick = () => {
    onHide();

    return onRemoveFamilyMemberClick(member);
  };

  return (
    <Modal
      onHide={onHide}
      onExited={onExited}
      show={show}
      title={
        <FormattedMessage
          id="removeFamilyParticipantModal.title"
          defaultMessage="Remove participant"
        />
      }
    >
      <>
        <div className="mb-8">
          <FormattedMessage
            id="removeFamilyParticipantModal.description"
            defaultMessage="You are removing {email} from your Setapp Family plan."
            values={{
              email: <strong>{member.email}</strong>,
            }}
          />
        </div>

        <Button
          block
          size="lg"
          onClick={handleRemoveFamilyMemberClick}
          disabled={isLoading}
        >
          <FormattedMessage id="removeFamilyParticipantModal.submitButton" defaultMessage="Confirm remove" />
        </Button>

        <div className="text-center text_weight-medium">
          <Button size="lg" variant="link" onClick={onHide} className="mt-6">
            <FormattedMessage id="removeFamilyParticipantModal.cancelButton" defaultMessage="Cancel" />
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default RemoveFamilyMemberModal;
