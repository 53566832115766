import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Notification } from '@setapp/ui-kit';

const BrowserOfflineNotification = () => (
  <Notification type="danger">
    <FormattedMessage
      id="globalNotifications.offline"
      defaultMessage="Your browser seems to be offline. Please check your internet connection."
    />
  </Notification>
);

export default BrowserOfflineNotification;
