// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { Component, ReactNode } from 'react';
import RequestError from '@setapp/request-error';
import httpStatuses from 'http-status';
import { connect, ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import analytics, { events } from 'utils/analytics';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';

import {
  getSubscriptions,
  getSubscriptionsList,
  getAdditionalSeatPlan,
  getPurchasedSeatsCount,
  isPaymentMethodCreated,
  isPaymentMethodFetched,
} from 'state/root-reducer';

import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { fetchAllSubscriptions, orderAdditionalSeats } from 'state/subscription/subscription-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import { State as RootState } from 'state/state-types';

import { getPrimarySubscription } from 'services/subscriptions/subscriptions-list';

import FadeTransition from 'components/shared/fade-transition/fade-transition';
import ButtonBack from 'components/shared/button-back/button-back';
import AdditionalSeatsCheckout from './additional-seats-checkout/additional-seats-checkout';
import BuyAdditionalSeatsForm from './buy-additional-seats-form/buy-additional-seats-form';

const SCREEN_SEATS_COUNT = 0;
const SCREEN_CHECKOUT = 1;

const screensOrder = [SCREEN_SEATS_COUNT, SCREEN_CHECKOUT];

const mapStateToProps = (state: RootState) => ({
  subscriptions: getSubscriptionsList(state),
  seatPricePlan: getAdditionalSeatPlan(state),
  totalSeatsCount: getPurchasedSeatsCount(state),
  arePaymentDetailsAdded: isPaymentMethodCreated(state),
  arePaymentDetailsFetched: isPaymentMethodFetched(state),
  areSubscriptionsLoading: getSubscriptions(state).isLoading,
});

const mapActionsToProps = {
  fetchAllSubscriptions,
  fetchPricePlans,
  fetchPaymentMethod,
  orderAdditionalSeats,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = {
  show: boolean;
  onHide: () => void;
  onExited: () => void;
} & ConnectedProps<typeof connector>;

type State = {
  selectedSeatsCount: number;
  currentScreen: number;
  onlyOneDeviceAvailable: boolean;
  errorMessage?: ReactNode;
};

function isDataLoaded(primarySubscription, seatPricePlan): boolean {
  return primarySubscription && seatPricePlan;
}

class BuyAdditionalSeatModal extends Component<Props, State> {
  state = {
    selectedSeatsCount: 0,
    currentScreen: SCREEN_SEATS_COUNT,
    errorMessage: undefined,
    onlyOneDeviceAvailable: false,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { totalSeatsCount, seatPricePlan, subscriptions } = props;

    const primarySubscription = getPrimarySubscription(subscriptions);
    const isDataPresent = isDataLoaded(primarySubscription, seatPricePlan);
    const isCurrentScreenSeatsCount = state.currentScreen === SCREEN_SEATS_COUNT;
    const availableSeats = primarySubscription && primarySubscription.pricePlan.maxSeatsCount - totalSeatsCount;
    const showCheckoutScreenForOneSeat = isDataPresent && availableSeats === 1 && isCurrentScreenSeatsCount;

    if (showCheckoutScreenForOneSeat) {
      analytics.trackEvent(events.BUY_SEAT_AMOUNT_SELECTED, { eventLabel: '1' });

      return {
        selectedSeatsCount: 1,
        currentScreen: SCREEN_CHECKOUT,
        onlyOneDeviceAvailable: true,
      };
    }

    return null;
  }

  componentDidMount() {
    const { fetchAllSubscriptions, fetchPricePlans, fetchPaymentMethod } = this.props;

    return Promise.all([
      fetchAllSubscriptions(),
      fetchPricePlans(),
      fetchPaymentMethod(),
    ])
      .catch(this.handleRequestError);
  }

  render() {
    const {
      show,
      onHide,
      onExited,
      seatPricePlan,
      arePaymentDetailsAdded,
      areSubscriptionsLoading,
      subscriptions,
      totalSeatsCount,
      arePaymentDetailsFetched,
    } = this.props;
    const {
      currentScreen,
      selectedSeatsCount,
      errorMessage,
    } = this.state;

    const primarySubscription = getPrimarySubscription(subscriptions);
    const isProcessing = areSubscriptionsLoading;
    const showPaymentForm = arePaymentDetailsFetched && !arePaymentDetailsAdded;
    const isLoading = !isDataLoaded(primarySubscription, seatPricePlan) || !arePaymentDetailsFetched;

    return (
      <Modal
        fullScreen
        show={show}
        title={this.getModalTitle()}
        isLoading={isLoading}
        onHide={onHide}
        onExited={onExited}
      >
        {(primarySubscription && seatPricePlan) && (
          <>
            <FadeTransition current={currentScreen}>
              <BuyAdditionalSeatsForm
                withDescription={totalSeatsCount === primarySubscription.pricePlan.seatsCount}
                primarySubscriptionSeatsCount={primarySubscription.pricePlan.seatsCount}
                maxSeatsCount={primarySubscription.pricePlan.maxSeatsCount - totalSeatsCount}
                seatPricePlan={seatPricePlan}
                onSubmit={this.handleBuySeatsFormSubmit}
              />
              <AdditionalSeatsCheckout
                seatsCount={selectedSeatsCount}
                withPaymentDetailsForm={showPaymentForm}
                isProcessing={isProcessing}
                onConfirm={this.handlePaymentConfirmation}
                errorMessage={errorMessage}
              />
            </FadeTransition>
          </>
        )}
      </Modal>
    );
  }

  handleBuySeatsFormSubmit = async (fields: {seatsCount: string}) => {
    this.setState({
      selectedSeatsCount: Number(fields.seatsCount),
      currentScreen: SCREEN_CHECKOUT,
    });

    analytics.trackEvent(events.BUY_SEAT_AMOUNT_SELECTED, { eventLabel: Number(fields.seatsCount).toString() });
  }

  handlePaymentConfirmation = () => {
    const { orderAdditionalSeats, seatPricePlan, onHide } = this.props;

    this.setState({
      errorMessage: undefined,
    });

    const { selectedSeatsCount } = this.state;

    analytics.trackEvent(events.BUY_SEAT_PAYMENT_CLICK, { eventLabel: selectedSeatsCount.toString() });

    return orderAdditionalSeats({
      // Method cannot be called if seatPricePlan does not exist
      // as button which calls it will not be visible
      pricePlanId: seatPricePlan!.id,
      quantity: selectedSeatsCount,
    })
      .then(onHide)
      .catch(this.handleRequestError);
  }

  handleBackClick = () => {
    this.setState((state) => {
      const { currentScreen } = state;
      const prevScreen = screensOrder[Math.max(screensOrder.indexOf(currentScreen) - 1, 0)];

      return { currentScreen: prevScreen! };
    });
  }

  handleRequestError = (error: RequestError | TypeError) => {
    const { showDangerNotification, onHide } = this.props;
    if (error instanceof RequestError && error.status === httpStatuses.FORBIDDEN) {
      const errorMessage = <FormattedMessage
        id="buyAdditionalSeat.errorMessage"
        defaultMessage="Your payment is still pending. Please check back in minute."
                           />;
      this.setState({
        errorMessage,
      });
    } else {
      showDangerNotification(<DefaultError />, { withIcon: true });
      onHide();
    }
  }

  getModalTitle() {
    const { currentScreen, selectedSeatsCount, onlyOneDeviceAvailable } = this.state;

    const isFirstScreen = currentScreen === SCREEN_SEATS_COUNT;
    const isCheckoutScreenForOneDeviceAvailable = currentScreen === SCREEN_CHECKOUT && onlyOneDeviceAvailable;
    const showButtonBack = !isFirstScreen && !isCheckoutScreenForOneDeviceAvailable;

    return (
      <>
        <ButtonBack onClick={this.handleBackClick} visible={showButtonBack} />
        <div className="mt-5">
          <FadeTransition current={currentScreen}>
            <FormattedMessage id="buyAdditionalSeat.selectSeatsCountTitle" defaultMessage="Add extra devices" />
            <FormattedMessage
              id="buyAdditionalSeat.checkoutTitle"
              defaultMessage="Add {seatsCount, plural, one {1 extra device} other {{seatsCount} extra devices}}"
              values={{
                seatsCount: selectedSeatsCount,
              }}
            />
            <FormattedMessage
              id="buyAdditionalSeat.tryAgainTitle"
              defaultMessage="Please try again"
            />
          </FadeTransition>
        </div>
      </>
    );
  }
}

export default connector(BuyAdditionalSeatModal);
