export type IosApp = {
  id: number;
  icon: string;
  name: string;
  shortDescription: string;
  appStoreLink: string;
  activationUrlScheme: string;
}

export type IosAppsState = {
  list: IosApp[];
  isLoading: boolean;
}

const initialState: IosAppsState = {
  list: [],
  isLoading: false,
};

export default initialState;
