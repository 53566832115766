import request from '../../utils/request';
import { apiURL } from '../../config/api';
import * as actionTypes from './invoices-action-types';

// It's OK for actions to export one named function
// eslint-disable-next-line import/prefer-default-export
export function fetchInvoices() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return request.get(apiURL.invoices)
      .then((data) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REQUEST_ERROR,
          payload: error,
          error: true,
        });

        return Promise.reject(error);
      });
  };
}
