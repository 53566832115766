import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { Invoice } from 'state/invoices/invoices-initial-state';
import PaymentHistoryItem from './payment-history-item/payment-history-item';

type Props = {
  invoices: Array<Invoice>;
};

const PaymentHistoryList = (props: Props) => {
  const { invoices } = props;

  return (
    <table className="table table-hover mt-8" data-qa="paymentHistoryTable">
      <thead>
        <tr>
          <th className="payment-history__date-cell">
            <FormattedMessage id="paymentHistory.table.columns.date" defaultMessage="Date" />
          </th>
          <th>
            <FormattedMessage id="paymentHistory.table.columns.amount" defaultMessage="Amount" />
          </th>
          <th className="text-right">
            <FormattedMessage id="paymentHistory.table.columns.receipt" defaultMessage="Receipt" />
          </th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice) => <PaymentHistoryItem
          key={invoice.id}
          invoice={invoice}
                                   />)}
      </tbody>
    </table>
  );
};

export default PaymentHistoryList;
