import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

import urls from 'config/urls';
import { getReferralDocsUrl } from 'utils/support';

import {
  canUseReferralProgram as canUseReferralProgramSelector,
  getReferrals,
  getFeatureFlags,
  isMembershipPriceIncreaseEnabled,
} from 'state/root-reducer';
import { fetchReferrals } from 'state/referrals/referrals-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import ReferralCounter from 'components/pages/referral/referral-counter/referral-counter';

import PageTitle from 'components/shared/page-title/page-title';
import DefaultError from 'components/shared/default-error/default-error';
import ReferralStatus from './referral-status/referral-status';

import './referral-page.scss';

import mcPromoIllustration from './images/mc-promo-illustration.svg';

const ReferralPage = () => {
  const dispatch = useDispatch();
  const referrals = useSelector(getReferrals);
  const canUseReferralProgram = useSelector(canUseReferralProgramSelector);
  const { membershipPriceIncrease } = useSelector(isMembershipPriceIncreaseEnabled);
  const { mcLicensePromo } = useSelector(getFeatureFlags);

  useEffect(() => {
    try {
      if (!referrals.url && !referrals.isLoading) {
        dispatch(fetchReferrals());
      }
    } catch {
      showDangerNotification(<DefaultError />);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrals.isLoading, referrals.url]);

  if (!canUseReferralProgram || membershipPriceIncrease) {
    return <Redirect to={urls.subscription} />;
  }

  return (
    <>
      <PageTitle>
        <FormattedMessage
          id="referral.pageTitle"
          defaultMessage="Invite friends to Setapp"
        />
      </PageTitle>
      <h5 className="mb-10">
        <FormattedMessage
          id="referral.pageSubtitle"
          defaultMessage="Share the love of apps with people you know"
        />
      </h5>
      <ReferralStatus referrals={referrals} />
      {Boolean(referrals.usersActivated) && <ReferralCounter referralCount={referrals.usersActivated} />}
      {Boolean(mcLicensePromo.value) && (
        <div className="mc-promo-banner">
          <img
            src={mcPromoIllustration}
            width="200"
            height="164"
            alt=""
          />
          <div className="mc-promo-banner__content">
            <h5>
              <FormattedMessage
                id="referral.mcPromo.title"
                defaultMessage="Refer friends to Setapp and get a year of MasterClass"
              />
            </h5>
            <p className="mb-0">
              <FormattedMessage
                id="referral.mcPromo.text"
                defaultMessage="Invite 3 people to Setapp and once they start their paid subscriptions, we will email you a promo code for MasterClass. Deal open for successful referrals made on September 5 — October 13, 2023."
              />
            </p>
          </div>
        </div>
      )}
      <p className="referral__bottom-help">
        <FormattedMessage
          id="referral.infoText"
          defaultMessage="Learn more about this referral program {docsLink}."
          values={{
            docsLink: (
              <a
                href={getReferralDocsUrl()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="referral.infoTextLink"
                  defaultMessage="here"
                />
              </a>
            ),
          }}
        />
      </p>
    </>
  );
};

export default ReferralPage;

