import React, { useState, ComponentProps, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl';

import urls from 'config/urls';

import { getLocalStorageItem, setLocalStorageItem } from 'utils/local-storage';
import { getSupportUrl } from 'utils/support';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import {
  canUseReferralProgram as canUseReferralProgramSelector,
  getUser,
  isFreeUser as isFreeUserSelector,
  isTryFamilyPlanPageAvailable as isTryFamilyPlanPageAvailableSelector,
  isUserPlainFamilyMember as isUserPlainFamilyMemberSelector,
  isUserFamilyOwner as isUserFamilyOwnerSelector,
  hasIosSeats as hasIosSeatsSelector,
  hasPriceFeatures as hasPriceFeaturesSelector,
  isMembershipPriceIncreaseEnabled,
} from 'state/root-reducer';
import { logout } from 'state/user/user-actions';

import DefaultError from 'components/shared/default-error/default-error';
import SidebarNav from 'components/shared/sidebar-nav/sidebar-nav';

import './sidebar-navigation.scss';

const INVITE_FRIENDS_NEW_LABEL_HIDDEN = 'inviteFriendsNewLabelHidden';

export type Props = Readonly<{
  canBeExpanded: boolean;
}>;

type NavLink = {
  path: string;
  name: ReactNode;
  icon: string | null;
  available?: boolean;
  labelType?: ComponentProps<typeof SidebarNav.Item>['labelType'];
  onClick?: () => void;
};

const SidebarNavigation = ({ canBeExpanded }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const isFreeUser = useSelector(isFreeUserSelector);
  const isTryFamilyPlanPageAvailable = useSelector(isTryFamilyPlanPageAvailableSelector);
  const isUserPlainFamilyMember = useSelector(isUserPlainFamilyMemberSelector);
  const isUserFamilyOwner = useSelector(isUserFamilyOwnerSelector);
  const hasIosSeats = useSelector(hasIosSeatsSelector);
  const hasPriceFeatures = useSelector(hasPriceFeaturesSelector);
  const canUseReferralProgram = useSelector(canUseReferralProgramSelector);
  const { membershipPriceIncrease } = useSelector(isMembershipPriceIncreaseEnabled);

  const [isInviteFriendsNewLabelHidden, setInviteFriendsNewLabelHidden] = useState<boolean>(
    getLocalStorageItem(INVITE_FRIENDS_NEW_LABEL_HIDDEN)
  );

  const isActiveLink = (available: boolean, path: string) => available && history.location.pathname.startsWith(path);

  const handleInviteFriendsClick = () => {
    setInviteFriendsNewLabelHidden(true);
    setLocalStorageItem(INVITE_FRIENDS_NEW_LABEL_HIDDEN, true);
  };

  const handleLogoutClick = () => {
    try {
      dispatch(logout());
    } catch {
      dispatch(showDangerNotification(<DefaultError />));
    }
  };

  const showGiftCardsLink = !isUserPlainFamilyMember && !isFreeUser && !membershipPriceIncrease;
  const navLinks: NavLink[] = [
    {
      path: urls.subscription,
      name: <FormattedMessage id="navigation.subscription" defaultMessage="Subscription" />,
      available: true,
      icon: null,
    },
    {
      path: urls.setappMobile,
      name: <FormattedMessage id="navigation.setappMobile" defaultMessage="Setapp Mobile" />,
      available: user.isSetappMobileAvailable,
      icon: null,
      labelType: 'beta',
    },
    {
      path: urls.iosApps,
      name: <FormattedMessage id="navigation.iosApps" defaultMessage="iOS apps" />,
      available: hasIosSeats,
      icon: null,
    },
    {
      path: urls.account,
      name: <FormattedMessage id="navigation.account" defaultMessage="Account settings" />,
      available: true,
      icon: null,
    },
    {
      path: urls.devices,
      name: <FormattedMessage id="navigation.devices" defaultMessage="Manage devices" />,
      available: true,
      icon: null,
    },
    {
      path: urls.family,
      name: <FormattedMessage id="navigation.familyPlan" defaultMessage="Family plan" />,
      available: !hasPriceFeatures && isUserFamilyOwner,
      icon: null,
    },
    {
      path: urls.family,
      name: <FormattedMessage id="navigation.tryFamilyPlan" defaultMessage="Try Family plan" />,
      available: isTryFamilyPlanPageAvailable && !membershipPriceIncrease,
      icon: null,
    },
    {
      path: urls.family,
      name: <FormattedMessage id="navigation.manageFamily" defaultMessage="Manage Family" />,
      available: hasPriceFeatures && isUserFamilyOwner,
      icon: null,
    },
    {
      path: urls.referral,
      name: <FormattedMessage id="navigation.referral" defaultMessage="Invite friends" />,
      available: canUseReferralProgram && !membershipPriceIncrease,
      icon: null,
      labelType: !isInviteFriendsNewLabelHidden ? 'new' : undefined,
      onClick: handleInviteFriendsClick,
    },
    {
      path: urls.giftCards,
      name: <FormattedMessage id="navigation.giftCards" defaultMessage="Gift cards" />,
      available: showGiftCardsLink,
      icon: null,
    },
    {
      path: urls.paymentHistory,
      name: <FormattedMessage id="navigation.paymentHistory" defaultMessage="Receipts" />,
      available: !isFreeUser && !isUserPlainFamilyMember,
      icon: null,
    },
  ];

  return (
    <SidebarNav canBeExpanded={canBeExpanded}>
      <SidebarNav.Header>
        {navLinks.map((link) => isActiveLink(Boolean(link.available), link.path) && (
          <span key={link.path}>{link.name}</span>
        ))}
      </SidebarNav.Header>

      <SidebarNav.Collapse>
        <SidebarNav.Section>
          {navLinks.map((link) => link.available && (
            <LinkContainer key={link.path} to={link.path} activeClassName="sidebar-nav__link_active">
              <SidebarNav.Item iconLink={link.icon} labelType={link.labelType} onClick={link.onClick}>
                {link.name}
              </SidebarNav.Item>
            </LinkContainer>
          ))}

          <SidebarNav.Item href={getSupportUrl()} external target="_blank">
            <FormattedMessage id="navigation.support" defaultMessage="Contact support" />
          </SidebarNav.Item>

          <SidebarNav.LogOutLink onClick={handleLogoutClick} userEmail={user.email}>
            <FormattedMessage id="navigation.signOut" defaultMessage="Sign out" />
          </SidebarNav.LogOutLink>
        </SidebarNav.Section>
      </SidebarNav.Collapse>
    </SidebarNav>
  );
};

export default SidebarNavigation;
