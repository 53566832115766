// @flow

import HealthMetricsHelper from '@setapp/health-metrics-helper';

import logger from './logger';

export const healthMetricsApiRoot = process.env.HEALTH_METRICS_API_ROOT;

type Metric = {|
  name: string,
  value?: number,
|};
type MetricMethod = 'increment' | 'decrement' | 'timing';

export class HealthMetrics {
  healthMetrics: HealthMetricsHelper;

  isInitialized: boolean = false;

  initialize() {
    // $FlowFixMe
    const healthMetricsApiRootV1 = `${healthMetricsApiRoot}/v1`;

    this.healthMetrics = new HealthMetricsHelper({
      apiRoot: healthMetricsApiRootV1,
      appName: 'customer_account',
      onError: this.onTrackingError,
    });
    this.isInitialized = true;
  }

  onTrackingError(message: string) {
    logger.logError(message);
  }

  trackMetric(metricMethod: MetricMethod, payload: Metric) {
    if (!this.isInitialized) {
      return;
    }

    if (typeof this.healthMetrics[metricMethod] !== 'function') {
      this.onTrackingError(`Method ${metricMethod} is not supported by health metrics helper.`);

      return;
    }

    this.healthMetrics[metricMethod](payload);
  }

  trackIncrement(payload: Metric) {
    this.trackMetric('increment', payload);
  }

  trackDecrement(payload: Metric) {
    this.trackMetric('decrement', payload);
  }

  trackTiming(payload: Metric) {
    this.trackMetric('timing', payload);
  }
}

const healthMetrics = new HealthMetrics();
export default healthMetrics;
