import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import createPoller from 'utils/promise-poller';

import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';

const POLLING_INTERVAL = 3000;
const POLLING_TIMEOUT = 15000;

type Params = {
  shouldContinuePolling: (subscription: Subscription) => boolean;
  delayFirstTask?: boolean;
  pollingInterval?: number;
  pollingTimeout?: number;
};

/**
 * Base hook to polling for subscription changes.
 *
 * @param {function} params.shouldContinuePolling - Function to determine whether to continue polling or not.
 * @param {boolean} [params.delayFirstTask=false] - Start polling immediately or delay the start.
 * @param {number} [params.pollingInterval=3000] - Interval between polls.
 * @param {number} [params.pollingTimeout=15000] - Timeout to stop polling and throw an error.
 */
const useSubscriptionPolling = (params: Params) => {
  const {
    shouldContinuePolling,
    delayFirstTask = false,
    pollingInterval = POLLING_INTERVAL,
    pollingTimeout = POLLING_TIMEOUT,
  } = params;

  const dispatch = useDispatch();

  const task = () => dispatch(fetchAllSubscriptions());

  const shouldContinue = (err: Error, subscriptions: Array<Subscription>) => {
    if (err) {
      return false;
    }

    if (!subscriptions || !subscriptions[0]) {
      return true;
    }

    return shouldContinuePolling(subscriptions[0]);
  };

  const stopPollingRef = useRef<() => void>();
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();

  const cancelTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  };

  // stop poller and clear timeout on unmount
  useEffect(() => () => {
    // WARNING: this will reject poller promise and error will appear in the component
    // where `startSubscriptionPolling` was called
    if (stopPollingRef.current) {
      stopPollingRef.current();
    }
    cancelTimeout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startSubscriptionPolling = async () => {
    const [startPolling, stopPolling] = createPoller(task, {
      delayFirstTask,
      timeout: pollingInterval,
      shouldContinue,
    });
    stopPollingRef.current = stopPolling;

    timeoutIdRef.current = setTimeout(() => {
      // WARNING: this will reject poller promise and error will appear in the component
      // where `startSubscriptionPolling` was called
      stopPollingRef.current!();
    }, pollingTimeout);

    await startPolling();

    // on polling success clear timeout
    cancelTimeout();
  };

  return { startSubscriptionPolling };
};

export default useSubscriptionPolling;
