import usePaymentWaiting from 'components/hooks/use-payment-waiting/use-payment-waiting';
import type { PaymentWaitingStatus } from 'components/hooks/use-payment-waiting/use-payment-waiting';

/**
 * Delays between tries. By default, we will look up for payment status
 * at 5, 15 and 30 seconds after the subscription activation start.
 */
const DEFAULT_TIMEOUT_DELAYS = [5000, 10000, 15000] as const;

type Params = {
  onSuccessPayment: () => void;
  timeoutDelays?: [number, number, number];
  onFailedPayment?: () => void;
  onPendingPayment?: () => void;
};

/**
 * Hook to use to wait for payment success after the subscription activation start. This hook makes three tries
 * each times it fetch subscription data and look up for subscription status:
 * - if payment is success and subscription status is ACTIVE, then call on success callback
 * - if payment is failed redirect user to subscription page and show default error
 * - if payment is pending after last try redirect user to the subscription page where `PermanentNotificationContainer`
 * component will continue to wait for payment result
 *
 * @param {object} params - Configuration object for the hook.
 * @param {function} params.onSuccessPayment - On payment success callback.
 * @param {number[]} [params.timeoutDelays=[5000, 10000, 15000]] - Delays between tries.
 * @param {function} [params.onFailedPayment] - On payment failed callback, pass it to overwrite default behavior.
 * @param {function} [params.onPendingPayment] - On payment pending callback, pass it to overwrite default behavior.
 */
const useMultiplePaymentWaiting = (params: Params) => {
  const {
    timeoutDelays = DEFAULT_TIMEOUT_DELAYS,
    onSuccessPayment,
    onFailedPayment,
    onPendingPayment,
  } = params;

  const [firstDelay, secondDelay, thirdDelay] = timeoutDelays;

  const { waitForPaymentSuccess: waitForPaymentSuccessFirstTry } = usePaymentWaiting({
    timeoutDelay: firstDelay,
    onSuccessPayment,
    onFailedPayment,
    onPendingPayment: () => {}, // pass empty function to disable default behavior on first try
  });

  const { waitForPaymentSuccess: waitForPaymentSuccessSecondTry } = usePaymentWaiting({
    timeoutDelay: secondDelay,
    onSuccessPayment,
    onFailedPayment,
    onPendingPayment: () => {}, // pass empty function to disable default behavior on second try
  });

  const { waitForPaymentSuccess: waitForPaymentSuccessThirdTry } = usePaymentWaiting({
    timeoutDelay: thirdDelay,
    onSuccessPayment,
    onFailedPayment,
    onPendingPayment,
  });

  const shouldContinueWaiting = (status: PaymentWaitingStatus) => status === 'pending';

  const waitMultipleForPaymentSuccess = async () => {
    let status;

    status = await waitForPaymentSuccessFirstTry();
    if (!shouldContinueWaiting(status)) {
      return;
    }

    status = await waitForPaymentSuccessSecondTry();
    if (!shouldContinueWaiting(status)) {
      return;
    }

    await waitForPaymentSuccessThirdTry();
  };

  return { waitMultipleForPaymentSuccess };
};

export default useMultiplePaymentWaiting;
