import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

type FormattedPeriodProps = {
  paidMonth?: number;
};

const FormattedPeriod = ({ paidMonth = 1 }: FormattedPeriodProps) => (
  <FormattedMessage
    id="formatted.price"
    defaultMessage="{monthsNumber, plural, one {month} =12 {year} other {{monthsNumber} months}}"
    values={{ monthsNumber: paidMonth }}
  />
);

type FormattedPriceProps = {
  price?: number;
  currency?: string;
  minimumFractionDigits?: number;
};

const FormattedPrice = ({ currency = 'USD', price = 0, minimumFractionDigits = 0 }: FormattedPriceProps) => (
  <FormattedNumber value={price} style="currency" currency={currency} minimumFractionDigits={minimumFractionDigits} />
);

type FormattedTaxProps = {
  tax?: number;
  currency?: string;
};

const FormattedTax = ({ currency = 'USD', tax = 0 }: FormattedTaxProps) => (
  <FormattedNumber value={tax} style="currency" currency={currency} />
);

export { FormattedPeriod, FormattedPrice, FormattedTax };
