// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {|
  selectedExtraDevicesCount: number,
  activeExtraDevicesCount: number,
  maxExtraDevicesCount: number,
|};

const ActivationExtraDevicesDescription = (props: Props) => {
  const { selectedExtraDevicesCount, activeExtraDevicesCount, maxExtraDevicesCount } = props;

  if (selectedExtraDevicesCount === activeExtraDevicesCount && selectedExtraDevicesCount !== 0) {
    return <FormattedMessage
      id="activateSubscription.extraDevicesDescription.trialAmount"
      defaultMessage="Connected to your account"
           />;
  }

  return <FormattedMessage
    id="activateSubscription.extraDevicesDescription.maxAmount"
    defaultMessage="{maxExtraDevicesCount, plural, one {Add an extra device} other {Add up to {maxExtraDevicesCount} extra devices}}"
    values={{ maxExtraDevicesCount }}
         />;
};

export default ActivationExtraDevicesDescription;
