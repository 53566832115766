import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl';

import InfoButton from 'components/shared/info-button/info-button';

import { isAiExpertPricePlan } from 'services/price-plans/utils';

import './cell-with-info-wrapper.scss';

const WORDS_AMOUNT_IN_ONE_TOKEN = 0.75;

interface Props {
  tierType: string;
  tokensLimit: number;
}

const InfoButtonWrapper: FC<Props> = ({ tierType, tokensLimit, children }) => (
  <div className="cell-with-info-wrapper">
    {children}
    <InfoButton>
      <div className="cell-with-info-wrapper__tooltip">
        <FormattedMessage
          id="aiOffer.plansComparison.tooltip"
          defaultMessage="Approximately {wordsAmount} words you can provide as input in one interaction with AI."
          values={{ wordsAmount: tokensLimit * WORDS_AMOUNT_IN_ONE_TOKEN }}
        />
        {isAiExpertPricePlan(tierType) && (
          <>{' '}
            <FormattedMessage
              id="aiOffer.plansComparison.tooltip.expertPlanInfo"
              defaultMessage="Expert grants you 10x longer interactions!"
            />
          </>
        )}
      </div>
    </InfoButton>
  </div>
);

export default InfoButtonWrapper;
