import QrCode from 'qrcode';
import logger from 'utils/logger';

const createQrCode = async (
  value: string, width = 128, margin = 0
): Promise<string | null> => {
  if (!value) {
    return null;
  }

  return QrCode.toDataURL(value, { width, margin }).catch((error) => {
    logger.logError('Failed to create QR Code');

    return Promise.reject(error);
  });
};

export default createQrCode;
