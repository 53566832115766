import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';

import {
  hasPriceFeatures,
  isUsersEmailConfirmed,
  showSwitchToAnnualNotification,
  isUserPlainFamilyMember,
  getPrimarySubscription,
  isPaymentMethodCreated,
  getPaymentMethod,
  getTrialLength,
  getUser,
  isLastAdditionalSeatPaymentFailed,
  isAdditionalSeatPaymentPending,
  getAdditionalSeatsPaymentPendingCount,
  isSpecialOfferCampaign,
  isSuspendedSubscriptionWithOldPricePlan,
  isIosOnlyUser,
  getDevicesList,
  isSubscriptionCancelledAndInactive,
} from 'state/root-reducer';
import { fetchAllSubscriptions, retryCharge } from 'state/subscription/subscription-actions';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { MAC_PLUS_IOS_MONTHLY, MAC_PLUS_IOS_ANNUAL, MAC_PLUS_IOS_TRIAL } from 'state/price-plans/price-plans-types';
import { showModal, hideModal } from 'state/modal/modal-reducer';
import { showSuccessNotification, showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchDevices } from 'state/devices/devices-reducer';

import logger from 'utils/logger';
import createPoller from 'utils/promise-poller';

import { COOKIES_ROOT_DOMAIN } from 'config/app';

import DefaultError from 'components/shared/default-error/default-error';
import SpecialOfferCodeDeliveryMessage from 'components/shared/special-offer-code-delivery-message/special-offer-code-delivery-message';
import PaymentPendingNotification from 'components/layout/app-layout/permanent-notifications-container/payment-pending-notification/payment-pending-notification';
import AdditionalSeatsPaymentFailedNotification from 'components/layout/app-layout/permanent-notifications-container/additional-seats-payment-failed-notification/additional-seats-payment-failed-notification';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentFailedNotification from 'components/layout/app-layout/permanent-notifications-container/payment-failed-notification/payment-failed-notification';

import { isIosAdvancedPricePlan } from 'services/price-plans/utils';
import ActivateNowNotification from './activate-now-notification/activate-now-notification';
import SoonPaymentNotification from './soon-payment-notification/soon-payment-notification';
import StatusCancelledNotification from './status-cancelled-notification/status-cancelled-notification';
import SubscriptionBlockedNotification from './subscription-blocked-notification/subscription-blocked-notification';
import ContactFamilyOwnerNotification from './contact-family-owner-notification/contact-family-owner-notification';
import BrowserOfflineNotification from './browser-offline-notification/browser-offline-notification';
import SwitchToAnnualNotification from './switch-to-annual-notification/switch-to-annual-notification';
import UnconfirmedEmailNotification from './unconfirmed-email-notification/unconfirmed-email-notification';
import DesktopDownloadReminder from './desktop-download-reminder/desktop-download-reminder';
import GetPlanNotification from './get-a-plan-notification/get-a-plan-notification';

const SWITCH_PLAN_TO_ANNUAL_SKIPPED_COOKIE_NAME = 'switchPlanToAnnualSkipped';

const mapActionsToProps = {
  fetchAllSubscriptions,
  retryCharge,
  showModal,
  hideModal,
  showSuccessNotification,
  showDangerNotification,
  fetchPaymentMethod,
  fetchDevices,
};

const mapStateToProps = (state) => ({
  mainSubscription: getPrimarySubscription(state),
  devices: getDevicesList(state),
  hasPriceFeatures: hasPriceFeatures(state),
  isUsersEmailConfirmed: isUsersEmailConfirmed(state),
  isSwitchToAnnualAvailable: showSwitchToAnnualNotification(state),
  isUserPlainFamilyMember: isUserPlainFamilyMember(state),
  isPaymentMethodCreated: isPaymentMethodCreated(state),
  isPaymentProcessing: getPaymentMethod(state).isLoading,
  defaultTrialLength: getTrialLength(state),
  isLastAdditionalSeatPaymentFailed: isLastAdditionalSeatPaymentFailed(state),
  isAdditionalSeatPaymentPending: isAdditionalSeatPaymentPending(state),
  seatsPaymentPendingCount: getAdditionalSeatsPaymentPendingCount(state),
  isSuspendedSubscriptionWithOldPricePlan: isSuspendedSubscriptionWithOldPricePlan(state),
  isSubscriptionCancelledAndInactive: isSubscriptionCancelledAndInactive(state),
  isIosOnlyUser: isIosOnlyUser(state),
  campaign: getUser(state).campaign,
  desktopCcRequired: getUser(state).desktopCcRequired,
  isSpecialOfferCampaign: isSpecialOfferCampaign(state),
});

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  hideBannersOnIosPage: boolean;
};

type State = {
  isSwitchToAnnualSkipped: boolean;
  isBrowserOnLine: boolean;
  seatsCount: number;
};

class PermanentNotificationContainer extends PureComponent<Props, State> {
  startSubscriptionsPaymentResultPolling: () => Promise<void>;

  stopSubscriptionsPaymentResultPolling: () => void;

  constructor(props: Props) {
    super(props);

    const { fetchAllSubscriptions } = this.props;

    const task = () => fetchAllSubscriptions({ silent: true });

    const delayForPendingPayment = 3000;

    const [startPolling, stopPolling] = createPoller(task, {
      delayFirstTask: true,
      timeout: delayForPendingPayment,
      shouldContinue: this.shouldContinueSubscriptionsPaymentResultPolling,
    });

    this.startSubscriptionsPaymentResultPolling = startPolling;
    this.stopSubscriptionsPaymentResultPolling = stopPolling;
  }

  state = {
    isSwitchToAnnualSkipped: Boolean(Cookies.get(SWITCH_PLAN_TO_ANNUAL_SKIPPED_COOKIE_NAME)),
    isBrowserOnLine: true,
    seatsCount: 0,
  };

  static defaultProps = {
    isUsersEmailConfirmed: true,
    isPaymentMethodCreated: false,
  };

  componentDidMount() {
    const {
      fetchAllSubscriptions,
      fetchPaymentMethod,
      fetchDevices,
      mainSubscription,
      isAdditionalSeatPaymentPending,
    } = this.props;

    const dataToBeFetched = [
      fetchAllSubscriptions(),
      fetchPaymentMethod(),
      fetchDevices(),
    ];

    const isPrimarySubscriptionPaymentPending = mainSubscription && mainSubscription.paymentPending;
    const shouldWaitForPayment = isPrimarySubscriptionPaymentPending || isAdditionalSeatPaymentPending;

    if (shouldWaitForPayment) {
      this.waitForSubscriptionsFinishedPayment();
    }

    window.addEventListener('offline', this.handleOnLineStatusUpdate);
    window.addEventListener('online', this.handleOnLineStatusUpdate);

    return Promise.all(dataToBeFetched)
      .catch((error) => {
        logger.logWarn('Could not load data for the permanent notifications', error);
      });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.isMainSubscriptionPaymentStarted(prevProps) || this.isSeatsSubscriptionPaymentStarted(prevProps)) {
      this.waitForSubscriptionsFinishedPayment();
    }

    if (this.isMainSubscriptionPaymentBecameFinishedAndSuccessful(prevProps)) {
      this.showMainSubscriptionPaymentSuccessNotification();
    }

    if (this.isSeatsPaymentBecameFinishedAndSuccessful(prevProps)) {
      this.showDevicesAddedNotification();
    }

    this.updatePaymentPendingSeatsCount(prevProps);
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.handleOnLineStatusUpdate);
    window.removeEventListener('online', this.handleOnLineStatusUpdate);
    this.stopSubscriptionsPaymentResultPolling();
  }

  renderCanceledStatusNotification() {
    const {
      isUserPlainFamilyMember,
      isSuspendedSubscriptionWithOldPricePlan,
      isSubscriptionCancelledAndInactive,
    } = this.props;

    if (isSuspendedSubscriptionWithOldPricePlan || isUserPlainFamilyMember) {
      return null;
    }

    if (isSubscriptionCancelledAndInactive) {
      return <GetPlanNotification />;
    }

    return <StatusCancelledNotification />;
  }

  renderBlockedStatusNotification() {
    const { isUserPlainFamilyMember } = this.props;

    if (isUserPlainFamilyMember) {
      return null;
    }

    return <SubscriptionBlockedNotification />;
  }

  renderGraceStatusNotification() {
    const { isUserPlainFamilyMember, retryCharge } = this.props;

    if (isUserPlainFamilyMember) {
      return null;
    }

    return <PaymentFailedNotification onRetryChargeClick={retryCharge} />;
  }

  renderTrialStatusNotification() {
    const {
      mainSubscription,
      isPaymentMethodCreated,
      retryCharge,
      isPaymentProcessing,
      defaultTrialLength,
    } = this.props;
    const { lastPaymentFailed, nextPaymentDate } = mainSubscription!;

    if (!isPaymentMethodCreated && nextPaymentDate) {
      const daysUntilTrialEnds = (nextPaymentDate * 1000 - Date.now()) / (1000 * 60 * 60 * 24);
      // Trial enter payment details" is displayed when only 30% or less of trial period is left
      const showSoonPaymentNotification = (defaultTrialLength * 0.3) >= daysUntilTrialEnds;

      if (showSoonPaymentNotification) {
        return (
          <SoonPaymentNotification
            isPaymentProcessing={isPaymentProcessing}
            onAddPaymentDetailsClick={this.handleAddPaymentDetailsClick}
          />
        );
      }
    }

    if (lastPaymentFailed) {
      return <PaymentFailedNotification onRetryChargeClick={retryCharge} />;
    }

    return null;
  }

  renderNewStatusNotification() {
    const { isIosOnlyUser, desktopCcRequired, mainSubscription } = this.props;

    const isIosAdvancedPlan = mainSubscription && isIosAdvancedPricePlan(mainSubscription.pricePlan.tierType);

    if (!isIosOnlyUser && !desktopCcRequired && !isIosAdvancedPlan) {
      return <ActivateNowNotification />;
    }

    return null;
  }

  renderNotificationByStatus() {
    const { mainSubscription } = this.props;
    const { status } = mainSubscription!;

    if (status === subscriptionStatuses.CANCELLED) {
      return this.renderCanceledStatusNotification();
    }

    if (status === subscriptionStatuses.BLOCKED) {
      return this.renderBlockedStatusNotification();
    }

    if (status === subscriptionStatuses.GRACE) {
      return this.renderGraceStatusNotification();
    }

    if (status === subscriptionStatuses.TRIAL) {
      return this.renderTrialStatusNotification();
    }

    if (status === subscriptionStatuses.NEW) {
      return this.renderNewStatusNotification();
    }

    return null;
  }

  renderNotification() {
    const {
      hasPriceFeatures,
      mainSubscription,
      retryCharge,
      isLastAdditionalSeatPaymentFailed,
    } = this.props;

    // Higher priority notifications should be placed at the top of the list
    if (this.shouldShowContactFamilyOwnerNotification()) {
      return <ContactFamilyOwnerNotification />;
    }

    if (this.shouldShowPaymentPendingNotification()) {
      return <PaymentPendingNotification />;
    }

    // Notifications by subscription status
    const notificationByStatus = this.renderNotificationByStatus();
    if (notificationByStatus) {
      return notificationByStatus;
    }

    // Lower priority notifications should be placed at the bottom of the list
    if (this.shouldShowDesktopDownloadReminder()) {
      return <DesktopDownloadReminder />;
    }

    if (isLastAdditionalSeatPaymentFailed) {
      return <AdditionalSeatsPaymentFailedNotification onRetryChargeClick={retryCharge} />;
    }

    if (this.shouldShowUnconfirmedEmailNotification()) {
      return <UnconfirmedEmailNotification />;
    }

    if (this.shouldShowSwitchToAnnualNotification()) {
      return (
        <SwitchToAnnualNotification
          hasPriceFeatures={hasPriceFeatures}
          primarySubscription={mainSubscription!}
          onHide={this.hideSwitchToAnnualNotification}
        />
      );
    }

    return null;
  }

  render() {
    const { mainSubscription } = this.props;
    const { isBrowserOnLine } = this.state;

    if (!isBrowserOnLine) {
      return <BrowserOfflineNotification />;
    }

    if (!mainSubscription) {
      return null;
    }

    return this.renderNotification();
  }

  shouldShowPaymentPendingNotification() {
    const { mainSubscription, isAdditionalSeatPaymentPending } = this.props;
    const { paymentPending } = mainSubscription!;

    return paymentPending || isAdditionalSeatPaymentPending;
  }

  shouldShowContactFamilyOwnerNotification() {
    const { hasPriceFeatures, isUserPlainFamilyMember, mainSubscription } = this.props;
    const { status } = mainSubscription!;

    return !hasPriceFeatures
      && isUserPlainFamilyMember
      && ((status === subscriptionStatuses.GRACE) || (status === subscriptionStatuses.BLOCKED));
  }

  shouldShowDesktopDownloadReminder() {
    const { devices, mainSubscription } = this.props;
    const { pricePlan } = mainSubscription!;

    const noMacActivated = !devices.some((device) => device.platform === 'macos');
    const hasMacPlusIosPricePlan = [
      MAC_PLUS_IOS_TRIAL,
      MAC_PLUS_IOS_MONTHLY,
      MAC_PLUS_IOS_ANNUAL,
    ].includes(pricePlan.tierType);

    return hasMacPlusIosPricePlan && noMacActivated;
  }

  shouldShowSwitchToAnnualNotification() {
    const { isSwitchToAnnualAvailable, hideBannersOnIosPage } = this.props;
    const { isSwitchToAnnualSkipped } = this.state;

    return isSwitchToAnnualAvailable
      && !isSwitchToAnnualSkipped
      && !hideBannersOnIosPage;
  }

  shouldShowUnconfirmedEmailNotification() {
    const { isUsersEmailConfirmed, hideBannersOnIosPage } = this.props;

    return !isUsersEmailConfirmed && !hideBannersOnIosPage;
  }

  isMainSubscriptionPaymentBecameFinishedAndSuccessful(prevProps: Props) {
    const { mainSubscription } = this.props;
    const { mainSubscription: oldMainSubscription } = prevProps;

    if (!mainSubscription || !oldMainSubscription) return false;
    if (mainSubscription.lastPaymentFailed) return false;

    return oldMainSubscription.paymentPending && !mainSubscription.paymentPending;
  }

  isSeatsPaymentBecameFinishedAndSuccessful(prevProps: Props) {
    const { isAdditionalSeatPaymentPending, isLastAdditionalSeatPaymentFailed } = this.props;
    const { isAdditionalSeatPaymentPending: isOldAdditionalSeatPaymentPending } = prevProps;

    return !isAdditionalSeatPaymentPending && isOldAdditionalSeatPaymentPending && !isLastAdditionalSeatPaymentFailed;
  }

  showDevicesAddedNotification() {
    const { showSuccessNotification } = this.props;
    const { seatsCount } = this.state;

    showSuccessNotification(
      <FormattedMessage
        id="buyAdditionalSeat.successfulNotification"
        defaultMessage="{seatsCount, plural, one {Device} other {{seatsCount} devices}} added"
        values={{
          seatsCount,
        }}
      />,
      { withIcon: true },
    );
  }

  updatePaymentPendingSeatsCount(prevProps: Props) {
    const { seatsPaymentPendingCount: oldSeatsCount } = prevProps;
    const { seatsPaymentPendingCount: seatsCount } = this.props;
    if (seatsCount !== oldSeatsCount && oldSeatsCount === 0 && seatsCount !== 0) {
      this.setState({
        seatsCount,
      });
    }
  }

  waitForSubscriptionsFinishedPayment() {
    return this.startSubscriptionsPaymentResultPolling()
      .catch(this.handleSubscriptionsPoolingError);
  }

  shouldContinueSubscriptionsPaymentResultPolling = (err: any) => {
    if (err) {
      return false;
    }

    const {
      mainSubscription,
      isAdditionalSeatPaymentPending,
    } = this.props;

    const isMainSubscriptionPending = mainSubscription ? mainSubscription.paymentPending : true;

    return isMainSubscriptionPending || isAdditionalSeatPaymentPending;
  };

  handleSubscriptionsPoolingError = (error?: Error) => {
    const { showDangerNotification } = this.props;

    // poller promise is rejected with null if the polling was stopped manually
    if (error) {
      showDangerNotification(<DefaultError />);
    }
  };

  isMainSubscriptionPaymentStarted(prevProps: Props) {
    const { mainSubscription: prevMainSubscription } = prevProps;
    const { mainSubscription } = this.props;

    return (prevMainSubscription && !prevMainSubscription.paymentPending)
      && (mainSubscription && mainSubscription.paymentPending);
  }

  isSeatsSubscriptionPaymentStarted(prevProps: Props) {
    const { isAdditionalSeatPaymentPending: isOldAdditionalSeatPaymentPending } = prevProps;
    const { isAdditionalSeatPaymentPending } = this.props;

    return (isAdditionalSeatPaymentPending && !isOldAdditionalSeatPaymentPending);
  }

  showMainSubscriptionPaymentSuccessNotification() {
    const { campaign, isSpecialOfferCampaign, showSuccessNotification } = this.props;

    const paymentSuccessMessage = (
      <FormattedMessage
        id="subscriptionInfo.paymentResult.success"
        defaultMessage="Payment successful! Your subscription is now active."
      />
    );

    if (campaign && isSpecialOfferCampaign) {
      showSuccessNotification(
        <>
          {paymentSuccessMessage}
          {' '}
          <SpecialOfferCodeDeliveryMessage campaign={campaign} />
        </>
      );

      return;
    }

    showSuccessNotification(paymentSuccessMessage);
  }

  skipSwitchPlanNotification(cookieName: string) {
    Cookies.set(cookieName, '1', { domain: COOKIES_ROOT_DOMAIN, expires: 365 * 5 });
  }

  hideSwitchToAnnualNotification = () => {
    this.skipSwitchPlanNotification(SWITCH_PLAN_TO_ANNUAL_SKIPPED_COOKIE_NAME);
    this.setState({ isSwitchToAnnualSkipped: true });
  };

  handleOnLineStatusUpdate = () => {
    this.setState({ isBrowserOnLine: navigator.onLine });
  }

  handleAddPaymentDetailsClick = () => {
    const { showModal } = this.props;

    showModal('SET_PAYMENT_DETAILS', {
      title: <PaymentDetailsActionText action="add" />,
      onPaymentDetailsSaved: this.handlePaymentDetailsAdded,
    });
  };

  handlePaymentDetailsAdded = () => {
    const { showSuccessNotification, hideModal } = this.props;

    hideModal();

    showSuccessNotification(
      <FormattedMessage
        id="permanentNotifications.addModal.success"
        defaultMessage="Payment details successfully added."
      />,
    );
  };
}

export { PermanentNotificationContainer as PurePermanentNotificationContainer };
export default connector(PermanentNotificationContainer);
