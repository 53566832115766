const INSIDE_SETAPP_MOBILE_FLOW_KEY = 'insideSetappMobileFlow';
const INSIDE_SETAPP_MOBILE_FLOW_VALUE = 'true';

export const getIsInsideSetappMobileFlow = () => {
  return sessionStorage.getItem(INSIDE_SETAPP_MOBILE_FLOW_KEY) === INSIDE_SETAPP_MOBILE_FLOW_VALUE;
};

export const setIsInsideSetappMobileFlow = () => {
  sessionStorage.setItem(INSIDE_SETAPP_MOBILE_FLOW_KEY, INSIDE_SETAPP_MOBILE_FLOW_VALUE);
};

export const removeIsInsideSetappMobileFlow = () => {
  sessionStorage.removeItem(INSIDE_SETAPP_MOBILE_FLOW_KEY);
};
