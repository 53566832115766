import { StoreEnhancer, Reducer, AnyAction } from '@reduxjs/toolkit';
import { Action } from './state-types';

type ResetStoreOptions = {
  resetActionType?: string;
  preserveState?: string[];
};

const resetStoreEnhancer
  = (options: ResetStoreOptions = {}): StoreEnhancer => (next) => <S, A extends Action = AnyAction>(
    reducer: Reducer<S, A>, initialState,
  ) => {
    const { resetActionType = 'RESET_STATE', preserveState = [] } = options;

    const enhanceReducer: Reducer<S, A> = (state, action) => {
      if (action.type !== resetActionType) {
        return reducer(state, action);
      }

      const filteredState = preserveState.reduce((filteredState, key) => {
        if (!state || !state[key]) {
          return filteredState;
        }

        return {
          ...filteredState,
          [key]: state[key],
        };
      }, {} as S);

      return reducer(filteredState, action);
    };

    return next(enhanceReducer, initialState);
  };

export default resetStoreEnhancer;
