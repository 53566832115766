import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';
import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';
import PRICE_PLAN_PERIOD_MAPPER from 'services/price-plans/price-plan-period-mapper';

import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { Subscription } from 'state/subscription/subscription-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';
import successIcon from './images/icons-success.svg';


import './plan-changed-notification.scss';

type Props = {
  nextPricePlan: PricePlan;
  previousPricePlan: PricePlan;
  primarySubscription: Subscription;
  nextPricePlanCalculationData?: NextPricePlanCalculationData;
};

const PlanChangedNotification = (props: Props) => {
  const {
    nextPricePlan,
    previousPricePlan,
    primarySubscription,
    nextPricePlanCalculationData,
  } = props;

  const nextPricePlanMetadata = getPricePlanMetadata(nextPricePlan);
  const nextPricePlanTitle = nextPricePlanMetadata.title;

  const getChangeBillingPeriodMessage = () => {
    const { nextPaymentDate } = primarySubscription;
    const isMonthlyNextBillingPeriod = nextPricePlan.paidMonth === 1;

    if (nextPaymentDate) {
      const formattedNextPaymentDate = (
        <strong>
          <FormattedDate value={nextPaymentDate * 1000} year="numeric" month="short" day="numeric" />
        </strong>
      );

      return isMonthlyNextBillingPeriod ? (
        <FormattedMessage
          id="changePricePlanFlow.planChangedNotification.changeBillingPeriod.monthly"
          defaultMessage="You’ve changed your billing period. You will be charged monthly starting {date}."
          values={{ date: formattedNextPaymentDate }}
        />
      ) : (
        <FormattedMessage
          id="changePricePlanFlow.planChangedNotification.changeBillingPeriod.annual"
          defaultMessage="You’ve changed your billing period. You will be charged annually starting {date}."
          values={{ date: formattedNextPaymentDate }}
        />
      );
    }

    return isMonthlyNextBillingPeriod ? (
      <FormattedMessage
        id="changePricePlanFlow.planChangedNotification.changeBillingPeriod.monthlyNoDate"
        defaultMessage="You’ve changed your billing period. You will be charged monthly."
      />
    ) : (
      <FormattedMessage
        id="changePricePlanFlow.planChangedNotification.changeBillingPeriod.annualNoDate"
        defaultMessage="You’ve changed your billing period. You will be charged annually."
      />
    );
  };

  const getChangePlanMessage = () => {
    let message = (
      <FormattedMessage
        id="changePricePlanFlow.planChangedNotification.default"
        defaultMessage="You’ve switched to the {nextPricePlanTitle} plan."
        values={{ nextPricePlanTitle }}
      />
    );

    if (nextPricePlanCalculationData) {
      const { strategy, nextPaymentDate } = nextPricePlanCalculationData;

      if (strategy === 'scheduled') {
        message = (
          <FormattedMessage
            id="changePricePlanFlow.planChangedNotification.changePricePlan.scheduledStrategy"
            defaultMessage="You’ve switched to the {nextPricePlanTitle} plan. Your new plan will become active on {nextPaymentDate}."
            values={{
              nextPricePlanTitle,
              nextPaymentDate: <FormattedDate value={nextPaymentDate * 1000} year="numeric" month="short" day="numeric" />,
            }}
          />
        );
      }
    }

    return message;
  };

  const renderMessage = () => {
    if (primarySubscription.status === subscriptionStatuses.TRIAL) {
      return (
        <FormattedMessage
          id="changePricePlanFlow.planChangedNotification.changePricePlan.trial"
          defaultMessage="{nextPricePlanTitle} plan features on! Subscription will begin after trial."
          values={{ nextPricePlanTitle }}
        />
      );
    }

    const oppositeBillingPeriodTierType = PRICE_PLAN_PERIOD_MAPPER[previousPricePlan.tierType];
    const isBillingPeriodChange = nextPricePlan.tierType === oppositeBillingPeriodTierType;

    return isBillingPeriodChange ? getChangeBillingPeriodMessage() : getChangePlanMessage();
  };

  return (
    <div className="plan-changed-notification">
      <img width="20" height="20" src={successIcon} alt="Success icon" />
      <div className="ml-3">{renderMessage()}</div>
    </div>
  );
};

PlanChangedNotification.defaultProps = {
  nextPricePlanCalculationData: undefined,
};

export default PlanChangedNotification;
