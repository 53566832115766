function getLocalStorageItem(name: string) {
  const localStorageItem = localStorage.getItem(name);

  return localStorageItem && JSON.parse(localStorageItem);
}

function setLocalStorageItem(name: string, value: boolean | string) {
  localStorage.setItem(name, JSON.stringify(value));
}

export {
  getLocalStorageItem,
  setLocalStorageItem,
};
