import React from 'react';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';

import { getSupportUrl } from 'utils/support';
import errorIllustration from './images/error-illustration.svg';


import './oauth-error.scss';

const messages = defineMessages({
  imageAlt: {
    id: 'oauth.errorPage.imageAlt',
    defaultMessage: 'Puzzled man with laptop illustrated',
  },
});

type Props = InjectedIntlProps;

const OAuthError = ({ intl }: Props) => (
  <div className="oauth-error">
    <img
      className="oauth-error__image"
      src={errorIllustration}
      alt={intl.formatMessage(messages.imageAlt)}
    />
    <h1 className="text-center">
      <FormattedMessage
        id="oauth.errorPage.title"
        defaultMessage="We’re puzzled too"
      />
    </h1>
    <p className="oauth-error__description">
      <FormattedMessage
        id="oauth.errorPage.description"
        defaultMessage="There’s a problem with your sign in. We’re really sorry. Please come back later or contact {supportLink}."
        values={{
          supportLink: (
            <a href={getSupportUrl()}>
              <FormattedMessage id="oauth.errorPage.supportLink" defaultMessage="our support people" />
            </a>
          ),
        }}
      />
    </p>
  </div>
);

export { OAuthError as PureOAuthError };

export default injectIntl(OAuthError);
