import React, { FC } from 'react';

import * as subscriptionStatuses from 'state/subscription/statuses';

import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import { useSubscriptionContext } from 'components/pages/subscription/context/subscription-context';

import NextPaymentRow from '../next-payment-row/next-payment-row';
import PlanRow from '../plan-row/plan-row';
import PriceRow from '../price-row/price-row';
import StatusRow from '../status-row/status-row';
import VatNoteRow from '../vat-note-row/vat-note-row';
import AiCreditsRow from '../ai-credits-row/ai-credits-row';

const SubscriptionGrace: FC = () => {
  const { taxAmount, pricePlan, subscription } = useSubscriptionContext();

  const { price, tierType, currency, paidMonth } = pricePlan;
  const { expirationDate, nextPaymentDate } = subscription;

  return (
    <>
      <PlanRow value={<PricePlanTitle tierType={tierType} />} />

      <PriceRow price={price} currency={currency} paidMonth={paidMonth} tax={taxAmount} />

      <StatusRow status={subscriptionStatuses.GRACE} expirationDate={expirationDate} />

      {nextPaymentDate && <NextPaymentRow nextPaymentDate={nextPaymentDate} highlightDate />}

      <AiCreditsRow />

      {taxAmount === 0 && <VatNoteRow />}
    </>
  );
};

export default SubscriptionGrace;
