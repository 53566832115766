import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useCallback } from 'react';
import {
  hasPriceFeatures as getHasPriceFeatures,
  isChangePlanAllowed as getIsChangePlanAllowed,
} from 'state/root-reducer';
import type { PreselectedStepValue } from 'components/modals/manage-subscription/manage-subscription';
import getShouldOpenChangePlanModal from '../utils/get-should-open-change-plan-modal';
import useOpenChangePlanModal from './use-open-change-plan-modal';
import useOpenManageSubscriptionModal from './use-open-manage-subscription-modal';

const useHandleInitialModalOpening = () => {
  const hasPriceFeatures = useSelector(getHasPriceFeatures);
  const isChangePlanAllowed = useSelector(getIsChangePlanAllowed);

  const location = useLocation();
  const openChangePlanModal = useOpenChangePlanModal();
  const openManageSubscriptionModal = useOpenManageSubscriptionModal();

  const shouldOpen = getShouldOpenChangePlanModal(location);

  return useCallback(() => {
    if (!shouldOpen) return;

    if (hasPriceFeatures) {
      const { show_change_plan_modal: step, price_plan: preselectedNextPlanId } = qs.parse(location.search);

      openManageSubscriptionModal(step as PreselectedStepValue, String(preselectedNextPlanId));
    } else if (isChangePlanAllowed) {
      openChangePlanModal();
    }
  }, [location, shouldOpen, hasPriceFeatures, isChangePlanAllowed]);
};

export default useHandleInitialModalOpening;
