import RequestError from '@setapp/request-error';
import queryString from 'query-string';
import type { RouteComponentProps } from 'react-router-dom';
import { apiURL } from 'config/api';
import urls from 'config/urls';
import request from 'utils/request';
import logger from 'utils/logger';
import { removeQueryParams } from 'utils/location';
import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';


export const OPEN_ACTIVATION_QUERY_PARAM = 'openActivation';

export const fetchActivationUrl = async (
  iosApp: IosApp,
  type: 'link_web' | 'qr_web',
) => {
  try {
    const { iosAuthToken } = await request.post(apiURL.iosAuthToken);
    if (!iosAuthToken) {
      throw new Error('fetchActivationUrl: Couldn\'t get auth token');
    }
    if (iosApp && !iosApp.activationUrlScheme) {
      throw new Error('fetchActivationUrl: No activation scheme present in app');
    }

    const { WEB_ROOT } = process.env;
    const fallbackUrl = encodeURIComponent(
      `${WEB_ROOT}${urls.iosApps}?${OPEN_ACTIVATION_QUERY_PARAM}&appId=${iosApp.id}`
    );

    return `${iosApp?.activationUrlScheme}?${iosAuthToken}&activation_type=${type}&fallback_url=${fallbackUrl}`;
  } catch (err: typeof RequestError | typeof Error) {
    const message = err instanceof RequestError
      ? err.getGenericError()?.message
      : err.message;

    logger.logError(message);
    throw new Error('fetchActivationUrl: Couldn\'t fetch activation url');
  }
};

export const shouldOpenActivationForApp = (history: RouteComponentProps['history']): {
  shouldOpenActivation: boolean;
  selectedAppId: number;
} => {
  const query = queryString.parse(history.location.search);
  const { appId } = query as {appId: string};

  const shouldOpenActivation = Boolean(OPEN_ACTIVATION_QUERY_PARAM in query && appId);

  removeQueryParams(history, OPEN_ACTIVATION_QUERY_PARAM, 'appId');

  return { shouldOpenActivation, selectedAppId: Number(appId) };
};
