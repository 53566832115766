// @flow

import React, { type Node } from 'react';

import { FormFieldWrapper } from '@setapp/ui-kit';

import './activate-feature-select.scss';

type SelectOption = {|
  value: string | number,
  label: string | number,
|}

type Props = {|
  id: string,
  label: Node,
  value: number | string,
  options: Array<SelectOption>,
  price: ?Node,
  helpText: ?Node,
  onChange: SyntheticEvent<HTMLSelectElement> => mixed,
|};

const ActivationFeatureSelect = (props: Props) => {
  const {
    id,
    label,
    value,
    price,
    options,
    helpText,
    onChange,
  } = props;

  return (
    <FormFieldWrapper
      id={id}
      label={label}
      helpText={helpText}
    >
      <div className="activate-feature-select">
        <div className="activate-feature-select__select-container">
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            id={id}
            value={value}
            className="activate-feature-select__select select"
            onChange={onChange}
          >
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <div className="activate-feature-select__arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6">
              <path
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 1L5 5 1 1"
              />
            </svg>
          </div>
        </div>
        <div className="ml-3 text_lg">
          {price}
        </div>
      </div>
    </FormFieldWrapper>
  );
};

ActivationFeatureSelect.defaultProps = {
  price: null,
  helpText: undefined,
};

export default ActivationFeatureSelect;
