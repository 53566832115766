import React, { useState } from 'react';
import classnames from 'classnames';

import analytics, { events } from 'utils/analytics';

import ButtonBack from 'components/shared/button-back/button-back';
import CancelSubscriptionStepLayout from 'components/user-flow/cancel-subscription/cancel-subscription-step-layout/cancel-subscription-step-layout';

import './pause-subscription-flow.scss';

import heartImage from './images/heart.svg';
import pauseIllustration from './images/pause.png';
import pauseIllustration2x from './images/pause@2x.png';
import pauseIllustration3x from './images/pause@3x.png';
import developmentIllustration from './images/development.png';
import developmentIllustration2x from './images/development@2x.png';
import developmentIllustration3x from './images/development@3x.png';

export type Props = {
  onCloseButtonClick: () => void;
  onPauseCancelButtonClick: () => void;
}

const PauseSubscriptionFlow = (props: Props) => {
  const {
    onCloseButtonClick,
    onPauseCancelButtonClick,
  } = props;

  const [showSecondStep, setShowSecondStep] = useState<boolean>(false);
  const handleChangeStep = () => setShowSecondStep(!showSecondStep);
  const handlePauseSubscriptionButtonClick = () => {
    analytics.trackEvent(events.CANCEL_SUBSCRIPTION_PAUSE_CLICK);

    handleChangeStep();
  };
  const handlePauseCancelSubscriptionButtonClick = () => {
    analytics.trackEvent(events.CANCEL_SUBSCRIPTION_CONTINUE_CLICK, {
      eventLabel: 'Pause Subscription Screen',
    });

    onPauseCancelButtonClick();
  };
  const handleProceedCancelSubscriptionButtonClick = () => {
    analytics.trackEvent(events.CANCEL_SUBSCRIPTION_CONTINUE_CLICK, {
      eventLabel: 'Under Development Screen',
    });

    onPauseCancelButtonClick();
  };
  const handleSurveyClick = () => {
    analytics.trackEvent(events.CANCEL_SUBSCRIPTION_SURVEY_CLICK);
  };

  return (
    <div
      className={classnames('pause-subscription-flow', {
        'pause-subscription-flow__without-back-button': !showSecondStep,
      })}
    >
      <>
        {showSecondStep && (
          <ButtonBack
            onClick={handleChangeStep}
            className="mb-2"
            data-qa="backButton"
          />
        )}

        {!showSecondStep && (
          <CancelSubscriptionStepLayout
            illustration={
              <img
                src={pauseIllustration}
                srcSet={`${pauseIllustration2x} 2x, ${pauseIllustration3x} 3x`}
                alt="Pause guy illustration"
              />
            }
            title={
              <span>Did you know you can pause Setapp?</span>
            }
            onPrimaryButtonClick={handlePauseSubscriptionButtonClick}
            primaryButtonText={
              <span>Pause subscription</span>
            }
            onSecondaryButtonClick={handlePauseCancelSubscriptionButtonClick}
            secondaryButtonText={
              <span>Cancel subscription</span>
            }
          >
            <div>
              <p className="mb-6">
              <span>
                No subscription payments, no need to set Setapp back up if you need it again.
              </span>
              </p>
              <p>
              <span>
                Just hit pause and restart when you are ready!
              </span>
              </p>
            </div>
          </CancelSubscriptionStepLayout>
        )}

        {showSecondStep && (
          <CancelSubscriptionStepLayout
            illustration={
              <img
                src={developmentIllustration}
                srcSet={`${developmentIllustration2x} 2x, ${developmentIllustration3x} 3x`}
                alt="Sorry guy illustration"
              />
            }
            title={
              <span>Feature in development</span>
            }
            onPrimaryButtonClick={handleProceedCancelSubscriptionButtonClick}
            primaryButtonText={
              <span>Proceed with cancelation</span>
            }
            onSecondaryButtonClick={onCloseButtonClick}
            secondaryButtonText={
              <span>Keep using Setapp</span>
            }
          >
            <div>
              <p className="mb-6">
                Oh, wow, this is embarrassing — looks like we weren’t able to complete this feature in time.
                Please bear with us <img src={heartImage} alt="Heart" />
              </p>
              <p>
                Feel free to
                {' '}
                <a
                  onClick={handleSurveyClick}
                  href="https://www.surveymonkey.com/r/HDNC956"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  let us know
                </a>
                {' '}
                what you’d like the pause feature to be like!
                Or, choose one of the steps below.
              </p>
            </div>
          </CancelSubscriptionStepLayout>
        )}
      </>
    </div>
  );
};

export default PauseSubscriptionFlow;
