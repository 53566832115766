// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';
import { FormattedPrice } from 'components/shared/formatter/formatter';
import { getTotalPrice } from 'services/subscriptions/subscriptions-list';
import type { Subscription } from 'state/subscription/subscription-initial-state';

type Props = {
  subscriptionsToCancel: Array<Subscription>;
  isProcessing: boolean;
  onConfirm: () => void;
}

const CancelSeatConfirmation = (props: Props) => {
  const {
    subscriptionsToCancel,
    isProcessing,
    onConfirm,
  } = props;

  const seatsCount = subscriptionsToCancel.length;
  const savingsAmount = getTotalPrice(subscriptionsToCancel);

  const [firstSubscriptionToCancel] = subscriptionsToCancel;
  const nextPaymentDate = firstSubscriptionToCancel?.nextPaymentDate ?? 0;
  const currency = firstSubscriptionToCancel?.pricePlan.currency;

  return (
    <>
      <div className="text_lg mt-6" data-qa="removeDevicesConfirmationForm">
        <FormattedMessage
          id="cancelAdditionalSeats.confirmationDescription"
          defaultMessage="{seatsCount, plural, one {1 device} other {{seatsCount} devices}} device will be removed from your account on {nextPaymentDate}. You will save {savingsAmount} on your next bill."
          values={{
            seatsCount,
            nextPaymentDate: (
              <strong>
                <FormattedDate
                  value={nextPaymentDate * 1000}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              </strong>
            ),
            savingsAmount: <FormattedPrice price={savingsAmount} currency={currency} />,
          }}
        />
        <ButtonLoading
          size="lg"
          block
          className="mt-8"
          disabled={isProcessing}
          isLoading={isProcessing}
          loadingText=""
          onClick={onConfirm}
          data-qa="removeDevicesConfirmationButton"
        >
          <FormattedMessage
            id="cancelAdditionalSeats.confirmButton"
            defaultMessage="Remove {seatsCount, plural, one {device} other {devices}}"
            values={{
              seatsCount,
            }}
          />
        </ButtonLoading>
      </div>
    </>
  );
};

export default CancelSeatConfirmation;
