// @flow

import React, { PureComponent, type Node } from 'react';

import analytics, { events } from 'utils/analytics';

import { getOppositeBillingPeriodPlan } from 'services/price-plans/utils';

import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import { type ChangeSubscriptionPlan } from 'state/price-plans/price-plans-types';
import { type Subscription } from 'state/subscription/subscription-initial-state';

import ButtonBack from 'components/shared/button-back/button-back';
import DefaultError from 'components/shared/default-error/default-error';
import PlanChangedNotification from '../plan-changed-notification/plan-changed-notification';
import ConfirmBillingPeriodStep from '../confirm-billing-period-step/confirm-billing-period-step';
import ChangeBillingPeriodStep from '../change-billing-period-step/change-billing-period-step';


type Step = 'changeBillingPeriodStep'
  | 'confirmBillingPeriodStep';

type Props = {|
  currentPricePlan: PricePlan,
  primarySubscription: Subscription,
  nextPricePlan: ?PricePlan,
  pricePlans: Array<PricePlan>,
  changePricePlan: (subscriptionId: number, planId: number) => Promise<ChangeSubscriptionPlan>,
  fetchUser: () => Promise<void>,
  showDangerNotification: (Node) => mixed,
  showSuccessNotification: (Node) => mixed,
  onSelectNextPlan: (nextPricePlan: PricePlan) => void,
  onGoBackward: () => void,
  onClose: () => void,
  annualDiscountPercentage: string,
|};

type State = {|
  step: Step,
  isProcessing: boolean,
  previousPricePlan: ?PricePlan,
|};

const BACK_STEP_MAPPING: {[key: Step]: Step | 'goBackward'} = {
  changeBillingPeriodStep: 'goBackward',
  confirmBillingPeriodStep: 'changeBillingPeriodStep',
};

class ChangeBillingPeriodFlow extends PureComponent<Props, State> {
  state = {
    step: 'changeBillingPeriodStep',
    isProcessing: false,
    previousPricePlan: null,
  };

  render() {
    return (
      <>
        <div className="mb-5">
          <ButtonBack onClick={this.handleBackButtonClick} />
        </div>
        {this.renderStep()}
      </>
    );
  }

  renderStep() {
    const {
      currentPricePlan,
      primarySubscription,
      nextPricePlan,
      pricePlans,
      onSelectNextPlan,
      annualDiscountPercentage
    } = this.props;
    const { step, isProcessing } = this.state;

    const oppositeBillingPeriodPricePlan = getOppositeBillingPeriodPlan(currentPricePlan, pricePlans);

    switch (step) {
      case 'changeBillingPeriodStep': {
        if (!oppositeBillingPeriodPricePlan) {
          return null;
        }

        return (
          <ChangeBillingPeriodStep
            currentPricePlan={currentPricePlan}
            oppositeBillingPeriodPricePlan={oppositeBillingPeriodPricePlan}
            nextPricePlan={nextPricePlan}
            pricePlans={pricePlans}
            onSelectNextPlan={onSelectNextPlan}
            onConfirmNextPlan={this.handleConfirmBillingPeriod}
            annualDiscountPercentage={annualDiscountPercentage}
          />
        );
      }
      case 'confirmBillingPeriodStep': {
        if (!nextPricePlan) {
          return null;
        }

        return (
          <ConfirmBillingPeriodStep
            isProcessing={isProcessing}
            nextPricePlan={nextPricePlan}
            primarySubscription={primarySubscription}
            onSubmitNextPlan={this.changePlan}
          />
        );
      }
      default:
        return null;
    }
  }

  handleBackButtonClick = () => {
    const { onGoBackward } = this.props;
    const { step } = this.state;

    const backStep = BACK_STEP_MAPPING[step];

    if (backStep === 'goBackward') {
      onGoBackward();
    } else {
      this.setState({ step: backStep });
    }
  }

  handleConfirmBillingPeriod = () => {
    const { currentPricePlan, nextPricePlan } = this.props;

    this.setState({ step: 'confirmBillingPeriodStep' });

    analytics.trackEvent(events.SELECT_PLAN_PAGE_CHANGE_BILLING_PERIOD_SELECT, {
      eventLabel: nextPricePlan?.tierType,
      eventLabel2: currentPricePlan.tierType,
    });
  }

  changePlan = async () => {
    const {
      currentPricePlan,
      primarySubscription,
      nextPricePlan,
      changePricePlan,
      showDangerNotification,
      onClose,
    } = this.props;

    if (!nextPricePlan) {
      return;
    }

    analytics.trackEvent(events.SELECT_PLAN_PAGE_CONFIRM_CHANGE_BILLING_PERIOD, {
      eventLabel: nextPricePlan.tierType,
      eventLabel2: currentPricePlan.tierType,
    });

    this.setState({
      isProcessing: true,
      // need to remember previous price plan, because after changing price plan
      // property `currentPricePlan` will return new price plan object
      previousPricePlan: currentPricePlan,
    });

    try {
      await changePricePlan(primarySubscription.id, nextPricePlan.id);
    } catch (err) {
      showDangerNotification(<DefaultError />);
      onClose();

      return;
    }

    this.setState({ isProcessing: false });
    await this.successfulPlanChange();
  }

  successfulPlanChange = async () => {
    const {
      primarySubscription,
      nextPricePlan,
      fetchUser,
      showSuccessNotification,
      onClose,
    } = this.props;
    const { previousPricePlan } = this.state;

    if (!nextPricePlan || !previousPricePlan) {
      return;
    }

    showSuccessNotification(
      <PlanChangedNotification
        nextPricePlan={nextPricePlan}
        previousPricePlan={previousPricePlan}
        primarySubscription={primarySubscription}
      />
    );

    await fetchUser();

    onClose();
  }
}

export default ChangeBillingPeriodFlow;
