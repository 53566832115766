import React  from 'react';
import chunk from 'lodash.chunk';
import classnames from 'classnames';

import type { App } from 'state/apps/apps-initial-state';

import './successful-registration-apps-grid-section.scss';


const APPS_COLUMN_LENGTH = 4;

type Props = {
  currentApp: App;
  appsList: App[];
};

const SuccessfulRegistrationAppsGridSection = ({ currentApp, appsList }: Props) => {
  const topAppsList = appsList.slice(0, 20);

  const singleAppIndex = topAppsList.findIndex((popularApp) => popularApp.id === currentApp.id);

  if (singleAppIndex > -1) {
    topAppsList.splice(singleAppIndex, 1);
  } else {
    topAppsList.pop();
  }

  topAppsList.splice(1, 0, currentApp);

  const appColumnsData = chunk(topAppsList, APPS_COLUMN_LENGTH);

  return (
    <div className="apps-grid">
      <div className="apps-grid__content">
        { appColumnsData.map((column, index) => (
          <div className="apps-grid__column" key={index}>
            {column.map((app) => (
              <div key={app.id} className="apps-grid__icon-container">
                <img
                  className={classnames('apps-grid__icon', { 'apps-grid__icon_single-app': app.isSingleApp })}
                  src={app.icon}
                  alt={app.name}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessfulRegistrationAppsGridSection;
