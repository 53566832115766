import { setPaymentInfo } from 'state/user/user-actions';
import { isPaymentMethodCreated } from 'state/root-reducer';
import type { Dispatch, GetState } from 'state/state-types';

import analytics, { events } from 'utils/analytics';

import PaymentDetailsService, {
  BraintreePaymentDetailsPayload,
  PaddleCreatePaymentDetailsPayload,
  PaddleUpdatePaymentDetailsPayload,
} from 'services/payment-details-api/payment-details-api';
import { SetappPaymentDetailsPayload } from 'services/payment-details-api/setapp/setapp-payment-details-api';
import * as actionTypes from './payment-method-action-types';


const requestError = (error) => ({
  type: actionTypes.REQUEST_ERROR,
  payload: error,
  error: true,
} as const);

export function fetchPaymentMethod() {
  const paymentDetailsService = new PaymentDetailsService();

  return (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.REQUEST,
    });

    return paymentDetailsService.fetchPaymentDetails()
      .then((data) => {
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data,
        } as actionTypes.PaymentDetailsRequestSuccess);
      })
      .catch((error) => {
        dispatch(requestError(error));

        return Promise.reject(error);
      });
  };
}

export const removePaymentMethod = () => (dispatch: Dispatch) => {
  const paymentDetailsService = new PaymentDetailsService();
  dispatch({ type: actionTypes.REQUEST });

  return paymentDetailsService.deletePaymentDetails()
    .then(() => {
      dispatch({ type: actionTypes.REQUEST_SUCCESS });
      dispatch({ type: actionTypes.REMOVE_PAYMENT_METHOD });
    })
    .catch((error) => {
      dispatch(requestError(error));

      return Promise.reject(error);
    });
};

export const createPaddlePaymentDetails = (payload: PaddleCreatePaymentDetailsPayload) => (dispatch: Dispatch) => {
  const paymentDetailsService = new PaymentDetailsService();

  dispatch({
    type: actionTypes.REQUEST,
  });

  return paymentDetailsService.createPaddlePaymentDetails(payload)
    .then((response) => {
      analytics.trackEvent(events.PAYMENT_DETAILS_ADD);

      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: response,
      });
      dispatch(setPaymentInfo());
    })
    .catch((error) => {
      dispatch(requestError(error));

      throw error;
    });
};

export const updatePaddlePaymentDetails = (payload: PaddleUpdatePaymentDetailsPayload) => (dispatch: Dispatch) => {
  const paymentDetailsService = new PaymentDetailsService();

  dispatch({
    type: actionTypes.REQUEST,
  });

  return paymentDetailsService.updatePaddlePaymentDetails(payload)
    .then((response) => {
      analytics.trackEvent(events.PAYMENT_DETAILS_ADD);

      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: response,
      });
      dispatch(setPaymentInfo());
    })
    .catch((error) => {
      dispatch(requestError(error));

      throw error;
    });
};

export const updateSetappPaymentDetails = (payload: SetappPaymentDetailsPayload) => async (dispatch: Dispatch) => {
  const paymentDetailsService = new PaymentDetailsService();

  return paymentDetailsService.updateSetappPaymentDetails(payload)
    .then((response) => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: response,
      });
      dispatch(setPaymentInfo());
    })
    .catch((error) => {
      dispatch(requestError(error));

      throw error;
    });
};

export const createSetappPaymentDetails = (payload: SetappPaymentDetailsPayload) => async (dispatch: Dispatch) => {
  const paymentDetailsService = new PaymentDetailsService();

  return paymentDetailsService.createSetappPaymentDetails(payload)
    .then((response) => {
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: response,
      });
      dispatch(setPaymentInfo());
    })
    .catch((error) => {
      dispatch(requestError(error));

      throw error;
    });
};

export const savePaymentMethod = (payload: BraintreePaymentDetailsPayload) => (
  (dispatch: Dispatch, getState: GetState) => {
    const paymentDetailsService = new PaymentDetailsService();
    const arePaymentDetailsCreated = isPaymentMethodCreated(getState());

    dispatch({
      type: actionTypes.REQUEST,
    });

    let requestPromise;

    if (arePaymentDetailsCreated) {
      requestPromise = paymentDetailsService.updateBraintreePaymentDetails(payload);
    } else {
      requestPromise = paymentDetailsService.createBraintreePaymentDetails(payload);
    }

    return requestPromise
      .then((response) => {
        analytics.trackEvent(events.PAYMENT_DETAILS_ADD);

        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: response,
        });
        dispatch(setPaymentInfo()); // TODO: investigate if still used
      })
      .catch((error) => {
        dispatch(requestError(error));

        throw error;
      });
  });
