import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import CustomerOauthContent from './customer-oauth-content';

type CustomerOauthOpenSetappProps = {
  redirectUrl: string;
};

const CustomerOauthOpenSetapp: FC<CustomerOauthOpenSetappProps> = ({ redirectUrl }) => {
  return (
    <CustomerOauthContent
      titleText={
        <FormattedMessage
          id="customer-oauth.done.title"
          defaultMessage="Done! Let’s get you back to Setapp"
        />
      }
      descriptionText={
        <FormattedMessage
          id="customer-oauth.done.description"
          defaultMessage="You're all set to tackle tasks with our curated app collection."
        />
      }
      buttonText={
        <FormattedMessage
          id="customer-oauth.done.button"
          defaultMessage="Go to Setapp Mobile"
        />
      }
      onButtonClick={() => window.open(redirectUrl, '_blank', 'noopener,noreferrer')}
    />
  );
};

export default CustomerOauthOpenSetapp;
