import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { FormattedHTMLMessage } from 'react-intl';
import { browserName, browserVersion } from 'react-device-detect';

import Balloon from 'components/shared/balloon/balloon';

import AutoDownload from './auto-download/auto-download';

import defaultIcon from './images/icon-default.svg';
import downloadSafariIcon from './images/icon-download-safari.svg';
import downloadFirefoxIcon from './images/icon-download-firefox.svg';

import './auto-download-section.scss';

const balloonIconsSet = {
  chrome: defaultIcon,
  safari: downloadSafariIcon,
  firefox: downloadFirefoxIcon,
};

const BALLOON_DISAPPEAR_TIMEOUT = 6000;
const TARGET_CHROME_VERSION = 117;

type State = {
  isBalloonShown: boolean;
};

class AutoDownloadSection extends PureComponent<unknown, State> {
  state = {
    isBalloonShown: false,
  };

  render() {
    const { isBalloonShown } = this.state;

    const isMacOS = navigator.platform.startsWith('Mac');

    if (!isMacOS) return null;

    const currentBrowserName = browserName.toLowerCase();
    const currentBrowserVersion = Number(browserVersion);

    const isChromeVersionLessThan117 = Boolean(currentBrowserName === 'chrome' && currentBrowserVersion < TARGET_CHROME_VERSION);
    const isBrowserWithBalloon = Boolean(balloonIconsSet[currentBrowserName]);

    const balloonAnimationRevealPosition = isChromeVersionLessThan117 ? 'bottom' : 'top';
    const browser = isChromeVersionLessThan117 ? 'chrome-old-version' : currentBrowserName;

    const portalMarkup = (
      <div className={`auto-download-section auto-download-section-${browser}`}>
        <Balloon
          position={balloonAnimationRevealPosition}
          tailPosition="withoutTail"
          show={isBalloonShown}
          onEntered={this.onEnteredHandler}
        >
          <div
            className="auto-download-content"
            data-qa="autoDownloadBalloon"
          >
            <img
              src={balloonIconsSet[currentBrowserName]}
              className="auto-download-icon"
              alt="Arrow down"
            />
            <p className="text_md auto-download-content__text">
              <FormattedHTMLMessage
                id="successfulRegistration.balloonText"
                defaultMessage="Your download is right here.</br>Go ahead and launch it."
              />
            </p>
          </div>
        </Balloon>
      </div>
    );

    return (
      <div>
        <AutoDownload onAutoDownloadStart={this.onAutoDownloadStart} />

        {isBrowserWithBalloon && (
          createPortal(portalMarkup, document.body)
        )}
      </div>
    );
  }

  onAutoDownloadStart = () => {
    this.setState({ isBalloonShown: true });
  };

  onEnteredHandler = () => {
    setTimeout(() => {
      this.setState({ isBalloonShown: false });
    }, BALLOON_DISAPPEAR_TIMEOUT);
  }
}

export default AutoDownloadSection;
