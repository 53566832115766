// Seat management is deprecated functionality to be removed soon
// so no need have unit tests for this file
// istanbul ignore file

import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Notification } from '@setapp/ui-kit';
import DefaultError from 'components/shared/default-error/default-error';

import { getPaymentFailedSeatsSubscriptions, getSubscriptions } from 'state/root-reducer';
import { cancelAdditionalSeats } from 'state/subscription/subscription-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import type { CancelAdditionalSeatsOrder } from 'services/subscriptions/subscriptions-api';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import RetryChargeContainer from '../retry-charge-container/retry-charge-container';
import NotificationCancelSeatsLink from '../notification-cancel-seats-link/notification-cancel-seats-link';
import NotificationNeedHelpLink from '../notification-need-help-link/notification-need-help-link';


import './additional-seats-payment-failed-notification.scss';

type Props = {
  onRetryChargeClick: () => Promise<void>;
  cancelAdditionalSeats: (order: CancelAdditionalSeatsOrder) => Promise<void>;
  showDangerNotification: (message: ReactElement) => void;
  failedAdditionalSeatsSubscriptions: Array<Subscription>;
  areSubscriptionsProcessing: boolean;
};

class AdditionalSeatsPaymentFailedNotification extends Component<Props> {
  render() {
    const { onRetryChargeClick, areSubscriptionsProcessing } = this.props;

    return (
      <Notification type="danger">
        <div className="additional-seats-payment-failed-notification__container">
          <p className="mb-0">
            <FormattedMessage
              id="subscriptionInfo.statusNotifications.additionalSeatsFailedPayment"
              defaultMessage="We couldn’t process your extra device payment. Retry or update your payment details."
            />
          </p>
          <div className="additional-seats-payment-failed-notification__action">
            <RetryChargeContainer onButtonClick={onRetryChargeClick} />
          </div>
        </div>
        <div className="additional-seats-payment-failed-notification__help">
          <NotificationCancelSeatsLink
            isRequestProcessing={areSubscriptionsProcessing}
            onCancelSeatsClick={this.onCancelSeatsClick}
          />
          <NotificationNeedHelpLink />
        </div>
      </Notification>
    );
  }

  onCancelSeatsClick = () => {
    const { cancelAdditionalSeats, failedAdditionalSeatsSubscriptions } = this.props;

    return cancelAdditionalSeats({
      subscriptions: failedAdditionalSeatsSubscriptions.map((subscription) => subscription.id),
    })
      .catch(() => {
        const { showDangerNotification } = this.props;

        showDangerNotification(<DefaultError />);
      });
  }
}

const mapActionsToProps = {
  showDangerNotification,
  cancelAdditionalSeats,
};

const mapStateToProps = (state) => ({
  failedAdditionalSeatsSubscriptions: getPaymentFailedSeatsSubscriptions(state),
  areSubscriptionsProcessing: getSubscriptions(state).isLoading,
});

export { AdditionalSeatsPaymentFailedNotification as PureAdditionalSeatsPaymentFailedNotification };

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(AdditionalSeatsPaymentFailedNotification);
