import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ButtonLoading } from '@setapp/ui-kit';

import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';

type Props = {
  isProcessing?: boolean;
  onRetry: () => void;
  onUpdatePaymentDetails: () => void;
};

const PaymentErrorStep = (props: Props) => {
  const {
    isProcessing = false,
    onRetry,
    onUpdatePaymentDetails,
  } = props;

  return (
    <>
      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="changePricePlanFlow.paymentErrorStep.title"
          defaultMessage="Sorry, we’re having trouble processing your payment"
        />
      </h3>

      <div className="text_lg">
        <FormattedMessage
          id="changePricePlanFlow.paymentErrorStep.description"
          defaultMessage="Please try again or take a moment to update your payment details."
        />
      </div>

      <div className="mt-8 mb-3">
        <ButtonLoading
          variant="primary"
          size="lg"
          block
          isLoading={isProcessing}
          onClick={onRetry}
        >
          <FormattedMessage
            id="changePricePlanFlow.paymentErrorStep.CTA"
            defaultMessage="Try again"
          />
        </ButtonLoading>
      </div>

      <Button
        variant="secondary-outline"
        size="lg"
        block
        onClick={onUpdatePaymentDetails}
      >
        <PaymentDetailsActionText action="update" />
      </Button>
    </>
  );
};

export default PaymentErrorStep;
