// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import { FormCheckbox, DeviceIcon, DeviceIconUnused } from '@setapp/ui-kit';
import { FormattedMessage, FormattedDate } from 'react-intl';
import classNames from 'classnames';
import type { FormCheckboxProps } from '@setapp/ui-kit/src/components/form/form-checkbox/form-checkbox';

import type { Device } from 'state/devices/devices-initial-state';

import './device-seat-checkbox.scss';

type Props = {
  device?: Device;
  checked?: boolean;
  disabled?: boolean;
} & FormCheckboxProps;

const DeviceSeatCheckbox = (props: Props) => {
  const {
    device,
    checked,
    disabled,
    ...checkboxProps
  } = props;
  const itemClasses = classNames('device-seat-checkbox', { 'device-seat-checkbox_disabled': disabled });
  const nameClasses = classNames(
    'device-seat-checkbox__name',
    'text_lg',
    'text_weight-medium',
    { 'text_color-danger': checked },
  );

  return (
    <FormCheckbox {...checkboxProps} alignMiddle disabled={disabled} checked={checked}>
      <span className={itemClasses}>
        <span className="device-seat-checkbox__icon ml-2">
          { device
            ? <DeviceIcon device={device.model} isDanger={checked} size={48} />
            : <DeviceIconUnused /> }
        </span>

        <span className="device-seat-checkbox__details ml-5">
          <span className={nameClasses}>
            {device?.name ?? (
              <FormattedMessage id="cancelAdditionalSeats.unusedDeviceTitle" defaultMessage="Unused device" />
            )}
          </span>

          {device && (
            <span className="text_color-secondary text_sm">
              <FormattedMessage
                id="cancelAdditionalSeats.deviceLastUsedDate"
                defaultMessage="Last used: {lastUsedDate}"
                values={{
                  lastUsedDate: (
                    <FormattedDate
                      value={device.lastUsedDate * 1000}
                      year="numeric"
                      month="short"
                      day="numeric"
                    />
                  ),
                }}
              />
            </span>
          )}
        </span>
      </span>
    </FormCheckbox>
  );
};

export default DeviceSeatCheckbox;
