import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import AccountInfoRow from 'components/shared/account-info-row/account-info-row';

type Props = {
  additionalSeatsAmount: number;
};

const AdditionalSeatsAmountRow: FC<Props> = ({ additionalSeatsAmount }) => (
  <AccountInfoRow
    title={<FormattedMessage id="subscriptionInfo.additionalSeatsAmount.title" defaultMessage="Amount" />}
    value={(
      <FormattedMessage
        id="subscriptionInfo.additionalSeatsAmount.value"
        defaultMessage="{additionalSeatsAmount, plural, one {{additionalSeatsAmount} seat} other {{additionalSeatsAmount} seats}}"
        values={{ additionalSeatsAmount }}
      />
    )}
    qaLabel="additionalSeatsAmount"
  />
);

export default AdditionalSeatsAmountRow;
