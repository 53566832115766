import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { FormattedPrice } from 'components/shared/formatter/formatter';

type Props = {
  price: number;
  currency: string;
  paidMonth?: number;
  nextPaymentDate?: number;
};

const NextPaymentDateEstimation = (props: Props) => {
  const {
    price,
    currency,
    paidMonth = 0,
    nextPaymentDate,
  } = props;

  let dateValue;
  if (nextPaymentDate) {
    dateValue = nextPaymentDate * 1000;
  } else {
    const dateNow = new Date();
    dateValue = dateNow.setMonth(dateNow.getMonth() + paidMonth);
  }

  return (
    <FormattedMessage
      id="nextPaymentDateEstimation.message"
      defaultMessage="Setapp will renew for {priceValue} on {dateValue}"
      values={{
        priceValue: (
          <strong>
            <FormattedPrice currency={currency} price={price} />
          </strong>
        ),
        dateValue: (
          <strong>
            <FormattedDate
              value={dateValue}
              year="numeric"
              month="short"
              day="numeric"
            />
          </strong>
        ),
      }}
    />
  );
};

export default NextPaymentDateEstimation;
