import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { differenceInDays } from 'date-fns';

import warningIcon from './images/icons-warning.svg';

import './subscription-ending-message.scss';

type Props = {
  expirationDate: number;
};

const calculateDifferenceInDays = (expiration: number) => {
  const expirationDateInMs = expiration * 1000;
  const currentDate = new Date();
  const expirationDate = new Date(expirationDateInMs);

  const days = differenceInDays(expirationDate, currentDate);

  return days;
};

const SubscriptionEndingMessage: FC<Props> = ({ expirationDate }) => {
  const daysLeft = calculateDifferenceInDays(expirationDate);

  if (daysLeft <= 0) return null;

  return (
    <div className="scheduled-downgrade-message mt-5 mb-3">
      <img className="mr-2" src={warningIcon} alt="Warning" />
      <FormattedMessage
        id="subscriptionEndingMessage.text"
        defaultMessage="{daysLeft, plural, one {Subscription is cancelled and will end in # day} other {Subscription is cancelled and will end in # days}}"
        values={{ daysLeft }}
      />
    </div>
  );
};

export default SubscriptionEndingMessage;
