import React from 'react';
import { FormattedMessage } from 'react-intl';
import isAfter from 'date-fns/isAfter';
import classNames from 'classnames';

import * as subscriptionStatuses from 'state/subscription/statuses';

import type { SubscriptionStatus as SubscriptionStatusType } from 'state/subscription/statuses';

const statusTitles = {
  active: <FormattedMessage id="subscriptionInfo.statuses.active" defaultMessage="Active" />,
  inactive: <FormattedMessage id="subscriptionInfo.statuses.inactive" defaultMessage="Inactive" />,
  trial: <FormattedMessage id="subscriptionInfo.statuses.trial" defaultMessage="Free trial" />,
};

const statusTitlesMapping = {
  [subscriptionStatuses.TRIAL]: statusTitles.trial,
  [subscriptionStatuses.ACTIVE]: statusTitles.active,
  [subscriptionStatuses.FREE]: statusTitles.active,
  [subscriptionStatuses.GRACE]: statusTitles.active,
  [subscriptionStatuses.NEW]: statusTitles.inactive,
  [subscriptionStatuses.CANCELLED]: statusTitles.inactive,
  [subscriptionStatuses.BLOCKED]: statusTitles.inactive,
};

type Props = {
  status: SubscriptionStatusType;
  expirationDate?: Maybe<number>;
};

const SubscriptionStatus = ({ status, expirationDate }: Props) => {
  const isSubscriptionExpired = expirationDate != null && isAfter(Date.now(), expirationDate * 1000);
  const textClasses = classNames({ 'text-danger': status === subscriptionStatuses.BLOCKED });
  let statusText;

  if (status === subscriptionStatuses.CANCELLED && !isSubscriptionExpired) {
    statusText = statusTitles.active;
  } else {
    statusText = statusTitlesMapping[status];
  }

  return (
    <span className={textClasses}>{statusText}</span>
  );
};

export default SubscriptionStatus;
