import * as subscriptionStatuses from 'state/subscription/statuses';

import useSubscriptionPolling from 'components/hooks/use-subscription-polling/use-subscription-polling';

/**
 * Hook to waiting when subscription status changes to active.
 *
 * @param {object} params - parameters of the `useSubscriptionPolling` hook.
 */
const useSubscriptionActiveWaiting = (params: Partial<Parameters<typeof useSubscriptionPolling>[0]> = {}) => {
  const shouldContinuePolling = (subscription) => {
    return subscription.status !== subscriptionStatuses.ACTIVE;
  };

  const { startSubscriptionPolling } = useSubscriptionPolling({
    ...params,
    shouldContinuePolling,
  });

  return { waitForSubscriptionActive: startSubscriptionPolling };
};

export default useSubscriptionActiveWaiting;
