import React from 'react';
import { FormattedMessage } from 'react-intl';

import BasePermissionItem from './base-permission-item/base-permission-item';

import aiIcon from './images/ai-icon.svg';

type Props = {
  appName: string;
}

const PermissionAiItem = ({ appName }: Props) => (
  <BasePermissionItem
    icon={aiIcon}
    title={<FormattedMessage
      id="oauth.permission.ai.title"
      defaultMessage="Permission for AI requests"
           />}
    subTitle={<FormattedMessage
      id="oauth.permission.ai.subTitle"
      defaultMessage="{appName} will be able to make requests to AI platforms such as OpenAI via your Setapp account when you need it"
      values={{ appName }}
              />}
    qaLabel="permissionAiItem"
  />
);

export default PermissionAiItem;
