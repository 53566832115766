import React from 'react';
import { FormattedMessage } from 'react-intl';

const faqList = [{
  title: (
    <FormattedMessage
      id="aiOffer.faq.tokenLimit.title"
      defaultMessage="What is token limit?"
    />
  ),
  description: (
    <FormattedMessage
      id="aiOffer.faq.tokenLimit.description"
      defaultMessage="It’s the number of characters a language model can process within one interaction. 1,000 tokens approximately equals 750 words."
    />
  )
}, {
  title: (
    <FormattedMessage
      id="aiOffer.faq.howCreditsWork.title"
      defaultMessage="How do credits work?"
    />
  ),
  description: (
    <FormattedMessage
      id="aiOffer.faq.howCreditsWork.description"
      defaultMessage="Think of credits as virtual currency that lets you “buy” a certain number of interactions with text, image, and audio AI models."
    />
  )
}, {
  title: (
    <FormattedMessage
      id="aiOffer.faq.aiApps.title"
      defaultMessage="What AI apps are included?"
    />
  ),
  description: (
    <FormattedMessage
      id="aiOffer.faq.aiApps.description"
      defaultMessage="TypingMind, Elephas, Plus, PDF Pals, Ready to Send, MurmurType, Spellar AI, superwhisper, BoltAI, and Soulver. More apps will be added."
    />
  )
}];

export default faqList;
