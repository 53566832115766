import React from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import DesktopAppHelper from 'utils/desktop-app-helper';
import logger from 'utils/logger';

import urls from 'config/urls';

import illustration from './images/illustration.svg';

import './successful-ai-offer-activation.scss';

const SuccessfulAiOfferActivation = () => {
  const handleOpenSetapp = () => {
    DesktopAppHelper
      .openDesktopApp()
      .catch((error) => logger.logWarn('Couldn\'t launch Setapp desktop app', { error }));
  };

  return (
    <FullscreenLayout>
      <FullscreenLayout.PrimaryContent withLogo>
        <div className="successful-ai-offer-activation__primary-content">
          <div className="h3">
            <FormattedMessage
              id="aiOfferSuccess.title"
              defaultMessage="Done! Enjoy Setapp AI+"
            />
          </div>
          <p className="text_lg mb-0">
            <FormattedMessage
              id="aiOfferSuccess.description1"
              defaultMessage="You’ve successfully changed your plan."
            />
          </p>
          <p className="text_lg mb-8">
            <FormattedMessage
              id="aiOfferSuccess.description2"
              defaultMessage="Thanks for using Setapp!"
            />
          </p>

          <div className="successful-ai-offer-activation__buttons">
            <Button onClick={handleOpenSetapp}>
              <FormattedMessage
                id="aiOfferSuccess.openButton"
                defaultMessage="Open Setapp"
              />
            </Button>
            <Button variant="primary-outline" as={Link} href={urls.subscription}>
              <FormattedMessage
                id="aiOfferSuccess.accountButton"
                defaultMessage="Go to my account"
              />
            </Button>
          </div>
        </div>
      </FullscreenLayout.PrimaryContent>
      <FullscreenLayout.SecondaryContent>
        <div className="successful-ai-offer-activation__secondary-content">
          <img
            src={illustration}
            width={561}
            height={544}
            className="img-responsive"
            alt=""
          />
        </div>
      </FullscreenLayout.SecondaryContent>
    </FullscreenLayout>
  );
};

export default SuccessfulAiOfferActivation;
