// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedPrice } from 'components/shared/formatter/formatter';

import * as pricePlanGroupTypes from 'state/price-plans/price-plan-groups';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import { isFeaturedPricePlan } from 'services/price-plans/utils';

type Props = {
  pricePlan: PricePlan;
}

const getAdditionalInfo = (pricePlan) => {
  if (pricePlan.group === pricePlanGroupTypes.FAMILY) {
    return <FormattedMessage
      id="pricePlans.availableMembers"
      defaultMessage="Access for 5 Family members."
           />;
  }

  if (isFeaturedPricePlan(pricePlan.tierType)) {
    return (
      <>
        <FormattedMessage
          id="pricePlans.availableMacDevices"
          defaultMessage="Can&nbsp;be&nbsp;used&nbsp;on&nbsp;{macDevices, plural, one {{macDevices} Mac device} other {{macDevices} Mac devices}}{iosText}."
          values={{
            macDevices: pricePlan.features.macSeatsCount,
            iosText: pricePlan.features.iosSeatsCount ? (
              <>
                {' '}
                <FormattedMessage
                  id="pricePlans.availableIosDevices"
                  defaultMessage="and {iosDevices, plural, one {{iosDevices} iOS device} other {{iosDevices} iOS devices}}"
                  values={{
                    iosDevices: pricePlan.features.iosSeatsCount,
                  }}
                />
              </>
            ) : '',
          }}
        />
      </>
    );
  }

  return <FormattedMessage
    id="pricePlans.availableDevices"
    defaultMessage="Can&nbsp;be&nbsp;used&nbsp;on&nbsp;{availableDevices, plural, one {{availableDevices} device} other {{availableDevices} devices}}."
    values={{ availableDevices: pricePlan.seatsCount }}
         />;
};

const PricePlanDetails = ({ pricePlan }: Props) => {
  if (
    pricePlan.tierType === pricePlanTypes.NEW_MONTHLY_1_MAC
    || pricePlan.tierType === pricePlanTypes.NEW_ANNUAL_1_MAC
  ) {
    return <FormattedMessage
      id="pricePlans.details.newMacPlan"
      defaultMessage="For individuals who can’t imagine life without their one and only Mac."
           />;
  }

  if (
    pricePlan.tierType === pricePlanTypes.NEW_MONTHLY_1_MAC_UNLIM_IOS
    || pricePlan.tierType === pricePlanTypes.NEW_ANNUAL_1_MAC_UNLIM_IOS
  ) {
    return <FormattedMessage
      id="pricePlans.details.newMacPlusIosPlan"
      defaultMessage="For those who do everything on their single Mac and iOS devices."
           />;
  }

  if (
    pricePlan.tierType === pricePlanTypes.NEW_MONTHLY_4_MAC_UNLIM_IOS
    || pricePlan.tierType === pricePlanTypes.NEW_ANNUAL_4_MAC_UNLIM_IOS
  ) {
    return <FormattedMessage
      id="pricePlans.details.newPowerUserPlan"
      defaultMessage="For the true Mac geeks. Enjoy Setapp on up to 4 Macs and all iOS devices."
           />;
  }

  switch (pricePlan.paidMonth) {
    case 1: {
      return (
        <span>
          <FormattedMessage
            id="pricePlans.details.monthly"
            defaultMessage="Monthly payments."
          />
          {' '}
          {getAdditionalInfo(pricePlan)}
        </span>
      );
    }

    case 12: {
      return (
        <span>
          <FormattedMessage
            id="pricePlans.details.annual"
            defaultMessage="Yearly payments of {price}."
            values={{
              price: <FormattedPrice
                currency={pricePlan.currency}
                price={pricePlan.price}
                     />,
            }}
          />
          {' '}
          {getAdditionalInfo(pricePlan)}
        </span>
      );
    }

    default: {
      return (
        <span>
          <FormattedMessage
            id="pricePlans.details.custom"
            defaultMessage="Pay {price} every {period, plural, one {month} other {{period} months}}."
            values={{
              price: <FormattedPrice
                currency={pricePlan.currency}
                price={pricePlan.price}
                     />,
              period: pricePlan.paidMonth,
            }}
          />
          {' '}
          {getAdditionalInfo(pricePlan)}
        </span>
      );
    }
  }
};

export default PricePlanDetails;
