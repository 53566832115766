import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';
import ButtonBack from 'components/shared/button-back/button-back';

type Props = {
  onConfirm: () => void;
  onGoBackward: () => void;
};

const ConfirmLoseDiscount = (props: Props) => {
  const { onConfirm, onGoBackward } = props;

  return (
    <>
      <div className="mb-5">
        <ButtonBack
          onClick={onGoBackward}
        />
      </div>

      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="changePricePlanFlow.confirmLoseDiscount.title"
          defaultMessage="Are you sure?"
        />
      </h3>

      <div className="mb-8 text_lg">
        <FormattedMessage
          id="changePricePlanFlow.confirmLoseDiscount.description"
          defaultMessage="Once switched, you will lose your discount. Still want to continue?"
        />
      </div>

      <Button
        variant="primary"
        size="lg"
        block
        onClick={onConfirm}
      >
        <FormattedMessage
          id="changePricePlanFlow.confirmLoseDiscount.CTA"
          defaultMessage="Confirm"
        />
      </Button>
    </>
  );
};

export default ConfirmLoseDiscount;
