import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormSwitcher } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';

import { isMembershipPriceIncreaseEnabled } from 'state/root-reducer';
import PricePlanBenefits from 'components/shared/price-plan-benefits/price-plan-benefits';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import PromotedPricePlans from './promoted-price-plans/promoted-price-plans';

import './price-plans-row.scss';

type Props = Readonly<{
  switchedToAnnual: boolean;
  onChangePlanPeriod: () => void;
  onSelectPlanClick: (pricePlan: PricePlan) => void;

  title?: ReactNode;
  description?: ReactNode;
  showSwitcher?: boolean;
  activeMacDevicesCount?: number;
  currentPricePlan?: PricePlan;
  nextPricePlan?: PricePlan;
  isProcessing?: boolean;
  errorMessage?: ReactNode;
}>;

const defaultTitle = (
  <FormattedMessage
    id="pricePlansRow.title"
    defaultMessage="Choose your Setapp plan"
  />
);

const PricePlansRow = (props: Props) => {
  const {
    showSwitcher = true,
    title = defaultTitle,
    description,
    switchedToAnnual,
    onChangePlanPeriod,
    onSelectPlanClick,
    currentPricePlan,
    nextPricePlan,
    isProcessing,
    errorMessage,
  } = props;

  const { percentage } = useSelector(isMembershipPriceIncreaseEnabled);

  return (
    <>
      <div className="price-plans-row__title">
        <h1 className="h3 mb-5 mt-5">{title}</h1>
        {showSwitcher && (
          <FormSwitcher
            id="price-plans-row-period-switcher"
            onChange={onChangePlanPeriod}
            checked={switchedToAnnual}
          >
            <FormattedMessage
              id="pricePlansRow.changePlanPeriod"
              defaultMessage="Save {percentage} annually"
              values={{
                percentage,
              }}
            />
          </FormSwitcher>
        )}
      </div>

      {description && <div className="mt-2 mb-10">{description}</div>}

      <div className="price-plans-row__price-cards">
        <PromotedPricePlans
          switchedToAnnual={switchedToAnnual}
          onSelectPlanClick={onSelectPlanClick}
          currentPricePlan={currentPricePlan}
          nextPricePlan={nextPricePlan}
          isProcessing={isProcessing}
          errorMessage={errorMessage}
        />
      </div>
      <PricePlanBenefits />
    </>
  );
};

export default PricePlansRow;
