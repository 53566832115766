import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import getInitialState from './initial-state';
import resetStoreEnhancer from './reset-store-enhancer';
import { LOGOUT, DELETE_ACCOUNT } from './user/user-actions-types';

const getConfiguredStore = () => configureStore({
  reducer: rootReducer,
  preloadedState: getInitialState(),
  enhancers: [
    resetStoreEnhancer({ resetActionType: LOGOUT, preserveState: ['intl', 'featureFlags'] }),
    resetStoreEnhancer({ resetActionType: DELETE_ACCOUNT, preserveState: ['intl', 'featureFlags'] }),
  ],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export default getConfiguredStore;

export type Store = ReturnType<typeof getConfiguredStore>;
export type State = ReturnType<Store['getState']>;
export type Dispatch = Store['dispatch'];
