// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { DeviceIcon } from '@setapp/ui-kit';

import type { Device } from 'state/devices/devices-initial-state';

import './pick-active-device-item.scss';


type Props = {
  active: boolean;
  device: Device;
};

class PickActiveDeviceItem extends PureComponent<Props> {
  render() {
    const { device: { name, model, lastUsedDate }, active } = this.props;
    const pickActiveDeviceItemClasses = classnames(
      'pick-active-device-item',
      { 'pick-active-device-item_active': active }
    );

    return (
      <div className={pickActiveDeviceItemClasses}>
        <div className="pick-active-device-item__description">
          <div className="pick-active-device-item__title">{name}</div>
          <div className="pick-active-device-item__subtitle">
            <FormattedMessage
              id="pickActiveDeviceForm.lastUsedTitle"
              defaultMessage="Setapp last used on {date}"
              values={{
                date: <FormattedDate
                  value={lastUsedDate * 1000}
                  year="numeric"
                  month="short"
                  day="numeric"
                      />,
              }}
            />
          </div>
        </div>
        <DeviceIcon
          className="pick-active-device-item__icon"
          device={model}
          isDanger={active}
          size={80}
        />
      </div>
    );
  }
}

export default PickActiveDeviceItem;
