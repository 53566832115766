import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import RequestError from '@setapp/request-error';

import { apiURL } from 'config/api';

import request from 'utils/request';
import logger from 'utils/logger';

import { TrialStatus, TrialStatusState } from './trial-status-types';

export const initialState: TrialStatusState = {
  status: 'idle',
  data: null,
  error: null,
};

type FetchTrialStateInput = {
  onCompleted?: (status: TrialStatus) => void;
};

export const fetchTrialStatusThunk = createAsyncThunk<
  TrialStatus,
  FetchTrialStateInput | undefined,
  { rejectValue: Error }
>('trialStatus/fetchTrialStatus', async ({ onCompleted }: FetchTrialStateInput = {}, { rejectWithValue }) => {
  try {
    const payload: TrialStatus = await request.get(apiURL.trialCheck);

    onCompleted?.(payload);

    return payload;
  } catch (error) {
    logger.logError('Couldn\'t fetch trial availability', (error as RequestError).getSimplifiedErrors());

    return rejectWithValue(error as Error);
  }
});

const trialStatusSlice = createSlice({
  name: 'trialStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrialStatusThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTrialStatusThunk.fulfilled, (state, action: PayloadAction<TrialStatus>) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchTrialStatusThunk.rejected, (state, action: PayloadAction<Maybe<Error>>) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default trialStatusSlice.reducer;
