import React from 'react';
import { AnimatedLogo } from '@setapp/ui-kit';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import './fullscreen-layout-loading.scss';

const FullscreenLayoutLoading = () => (
  <FullscreenLayout>
    <div className="fullscreen-layout__loading">
      <AnimatedLogo animate />
    </div>
  </FullscreenLayout>
);

export default FullscreenLayoutLoading;
