import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import './tax-info.scss';

const TaxInfo = () => (
  <p className="tax-info">
    <FormattedHTMLMessage
      id="taxInfo.message"
      defaultMessage="Tax may apply depending on your country.<br/>Check your bank statement for details."
    />
  </p>
);

export default TaxInfo;
