import React from 'react';
import { FormattedMessage } from 'react-intl';

import external from 'config/external-urls';

import braintreeLock from './images/braintree-lock.svg';

import './styles/braintree-verified.scss';

type Props = {
  merchantId: string;
}

const BraintreeVerified = ({ merchantId }: Props) => (
  <a
    href={external.braintreeProfile.replace('%{merchantId}', merchantId)}
    className="braintree-verified-link"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={braintreeLock} alt="" />
    <span className="braintree-verified-text">
      <FormattedMessage
        id="braintreeVerified.label"
        defaultMessage="Payment information protected."
      />
    </span>
  </a>
);

export default BraintreeVerified;
