import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';

type ActionType = 'add' | 'update' | 'remove';

type Props = InjectedIntlProps & {
  action: ActionType;
};

export const paymentDetailsActionMessages = defineMessages<ActionType>({
  add: {
    id: 'paymentDetailsActionText.add',
    defaultMessage: 'Add payment details',
  },
  update: {
    id: 'paymentDetailsActionText.update',
    defaultMessage: 'Update payment details',
  },
  remove: {
    id: 'paymentDetailsActionText.remove',
    defaultMessage: 'Remove payment details',
  },
});

const PaymentDetailsActionText = ({ intl, action }: Props) => (
  <>
    {intl.formatMessage(paymentDetailsActionMessages[action])}
  </>
);

export default injectIntl(PaymentDetailsActionText);
