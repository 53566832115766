import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import analytics, { events } from 'utils/analytics';

import { showSuccessNotification } from 'state/notifier/notifier-reducer';

import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PromoBanner from 'components/shared/promo-banner/promo-banner';

import PaymentDetailsModal from 'components/modals/payment-details/payment-details-modal';

import giftImage from './images/gift.svg';

type Props = {
  onAddClick: () => void;
};

const ReferralPaymentDetailsBanner = ({ onAddClick }: Props) => {
  const [isPaymentDetailsModalShown, setShownModalState] = useState(false);

  const hideModal = () => setShownModalState(false);

  const onPaymentDetailsUpdateSuccess = () => {
    hideModal();

    showSuccessNotification((
      <FormattedMessage
        id="referralPaymentDetailsBanner.notifications.update"
        defaultMessage="Payment details successfully updated."
      />
    ));
  };

  const handleButtonClick = () => {
    onAddClick();
    analytics.trackEvent(
      events.PAYMENT_DETAILS_ADD_CLICK,
      {
        eventLabel2: 'Referree Banner',
        comment: 'Add Payment Details',
      },
    );
  };

  return (
    <>
      <PromoBanner
        image={giftImage}
        title={
          <FormattedMessage
            id="referralPaymentDetailsBanner.title"
            defaultMessage="Get +1 month on Setapp for free"
          />
        }
        message={
          <FormattedMessage
            id="referralPaymentDetailsBanner.text"
            defaultMessage="You came to Setapp by your friend’s invitation, so your one month is on us. Just make sure you add the payment details and start your subscription."
          />
        }
        buttonText={<PaymentDetailsActionText action="add" />}
        onButtonClick={handleButtonClick}
      />

      <PaymentDetailsModal
        show={isPaymentDetailsModalShown}
        onHide={hideModal}
        title={<PaymentDetailsActionText action="update" />}
        onPaymentDetailsSaved={onPaymentDetailsUpdateSuccess}
      />
    </>
  );
};

export default ReferralPaymentDetailsBanner;
