import React, { FC } from 'react';

import * as subscriptionStatuses from 'state/subscription/statuses';

import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import { useSubscriptionContext } from 'components/pages/subscription/context/subscription-context';

import PlanRow from '../plan-row/plan-row';
import PriceRow from '../price-row/price-row';
import StatusRow from '../status-row/status-row';
import VatNoteRow from '../vat-note-row/vat-note-row';
import TrialEndDateRow from '../trial-end-date-row/trial-end-date-row';
import AiCreditsRow from '../ai-credits-row/ai-credits-row';

const SubscriptionTrial: FC = () => {
  const { taxAmount, pricePlan, subscription, isPaymentMethodCreated } = useSubscriptionContext();

  const { price, tierType, currency, paidMonth } = pricePlan;
  const { discount, expirationDate, nextPaymentDate } = subscription;

  return (
    <div>
      <PlanRow value={<PricePlanTitle tierType={tierType} />} />

      <PriceRow price={price} currency={currency} paidMonth={paidMonth} tax={taxAmount} discount={discount} />

      <StatusRow status={subscriptionStatuses.TRIAL} expirationDate={expirationDate} />

      {nextPaymentDate && <TrialEndDateRow nextPaymentDate={nextPaymentDate} highlightDate={!isPaymentMethodCreated} />}

      <AiCreditsRow />

      {taxAmount === 0 && <VatNoteRow />}
    </div>
  );
};

export default SubscriptionTrial;
