import { isMobile } from 'react-device-detect';
import type { FeatureFlag } from '@setapp/abn-tests-client';

import featureFlags from 'utils/feature-flags';
import logger from 'utils/logger';
import { detectLocale } from 'utils/intl';

import { isEduPricePlan } from 'services/price-plans/utils';

import { getPrimarySubscription, getUser } from 'state/root-reducer';
import type { Dispatch, GetState } from 'state/state-types';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import { MAC_TRIAL, IOS_TRIAL } from 'state/price-plans/price-plans-types';

import * as actionTypes from './feature-flags-actions-types';

export const initFeatureFlags = () => async (dispatch: Dispatch, getState: GetState) => {
  dispatch({ type: actionTypes.REQUEST });

  try {
    const user = getUser(getState());

    if (user.isAuthenticated) {
      await dispatch(fetchAllSubscriptions());
    }

    const pricePlan = getPrimarySubscription(getState())?.pricePlan;
    const isMacTrial = pricePlan?.tierType === MAC_TRIAL;
    const isIosTrial = pricePlan?.tierType === IOS_TRIAL;
    const isEduUser = pricePlan ? isEduPricePlan(pricePlan) : false;

    const isEnLocale = detectLocale() === 'en-US';

    await featureFlags.init({ isEnLocale, isMacTrial, isIosTrial, isMobile, isEduUser });

    dispatch({
      type: actionTypes.REQUEST_SUCCESS,
      featureFlags: featureFlags.resolveFeatureFlags(),
    });
  } catch (error) {
    logger.logError('Couldn\'t initialize feature flags module', error);

    dispatch({ type: actionTypes.REQUEST_ERROR });
  }
};

export const resetFeatureFlags = () => async (dispatch: Dispatch, getState: GetState) => {
  await dispatch(fetchAllSubscriptions());

  const pricePlan = getPrimarySubscription(getState())?.pricePlan;
  const isMacTrial = pricePlan?.tierType === MAC_TRIAL;
  const isIosTrial = pricePlan?.tierType === IOS_TRIAL;
  const isEnLocale = detectLocale() === 'en-US';
  const isEduUser = pricePlan ? isEduPricePlan(pricePlan) : false;

  dispatch({ type: actionTypes.REQUEST });

  try {
    await featureFlags.reset({ isEnLocale, isMacTrial, isIosTrial, isMobile, isEduUser });

    dispatch({
      type: actionTypes.REQUEST_SUCCESS,
      featureFlags: featureFlags.resolveFeatureFlags(),
    });
  } catch (error) {
    logger.logError('Couldn\'t reset feature flags module', error);

    dispatch({ type: actionTypes.REQUEST_ERROR });
  }
};

export const trackFeatureFlagSplitResult = (featureFlag: FeatureFlag) => () => {
  featureFlags.trackSplitResult(featureFlag);
};
