import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Notification } from '@setapp/ui-kit';

import { getUserEmail } from 'state/root-reducer';

import useResendEmail from 'components/hooks/use-resend-email/use-resend-email';

import './unconfirmed-email-notification.scss';

const UnconfirmedEmailNotification = () => {
  const { resendEmail, isProcessing } = useResendEmail();
  const email = useSelector(getUserEmail);

  const handleResendClick = () => {
    resendEmail();
  };

  return (
    <Notification type="info">
      <span className="unconfirmed-email-notification__container">
        <FormattedMessage
          id="emailConfirmation.notificationText"
          defaultMessage="Please, confirm your email by clicking the link we sent you to {email}"
          values={{
            email: <strong>{email}</strong>,
          }}
        />
        <Button
          variant="primary-outline"
          size="sm"
          disabled={isProcessing}
          onClick={handleResendClick}
          className="unconfirmed-email-notification__button"
          data-qa="resendEmailBtn"
        >
          <FormattedMessage
            id="emailConfirmation.resendEmailButton"
            defaultMessage="Resend email"
          />
        </Button>
      </span>
    </Notification>
  );
};

export default UnconfirmedEmailNotification;
