import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import type { RouteComponentProps } from 'react-router-dom';
import httpStatuses from 'http-status';
import queryString from 'query-string';
import { Button } from '@setapp/ui-kit';

import { getSignupMetadata } from 'utils/service-locators';
import fetch from 'utils/request';
import { apiURL } from 'config/api';
import externalUrls from 'config/external-urls';
import urls from 'config/urls';

import { getAppsCount, getDefaultMonthlyPrice, getTrialLength } from 'state/root-reducer';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import signUpHoc from 'components/pages/signup/signup-page-hoc';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import DefaultError from 'components/shared/default-error/default-error';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import ReferralSignupPromo from './referral-signup-promo/referral-signup-promo';


import './referral-signup.scss';

const signUpMetadata = getSignupMetadata();

type Props = {
  signUpForm: ReactNode;
  location: RouteComponentProps['location'];
  appsCount: number;
  trialLength: number;
  monthlyPrice: number;
  emailError?: ReactNode;
}

type State = {
  errorMessage: ReactNode;
  isLoading: boolean;
}

class ReferralSignup extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorMessage: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    const { referral: urlReferral } = query;
    const { referral: cookiesReferral } = signUpMetadata.getAll();
    const referral = urlReferral || cookiesReferral;


    if (!referral) {
      this.showNoReferralState();

      return;
    }

    this.checkReferral(referral);
    signUpMetadata.save({ referral });
  }

  render() {
    const { isLoading } = this.state;
    const {
      appsCount,
      trialLength,
      monthlyPrice,
    } = this.props;

    if (isLoading) {
      return (
        <FullscreenLayoutLoading />
      );
    }

    return (
      <FullscreenLayout>
        <FullscreenLayout.PrimaryContent>
          <div className="referral-signup-primary">
            <a
              className="referral-signup-primary__logo"
              href={externalUrls.setappSite}
            >
              <AnimatedLogo hasCaption size="sm" />
            </a>
            <div className="referral-signup-primary__content" data-qa="referralSignupContent">
              {this.getPrimaryContent()}
            </div>
          </div>
        </FullscreenLayout.PrimaryContent>
        <FullscreenLayout.SecondaryContent>
          <ReferralSignupPromo
            appsCount={appsCount}
            trialLength={trialLength}
            monthlyPrice={<FormattedPrice
              price={monthlyPrice}
              currency="USD"
                          />}
          />
        </FullscreenLayout.SecondaryContent>
      </FullscreenLayout>
    );
  }

  getPrimaryContent() {
    const { signUpForm, emailError } = this.props;
    const { errorMessage } = this.state;

    if (emailError === 'This email has already been taken') {
      return (
        <div>
          <h1 className="mb-3 h3" data-qa="pageTitle">
            <FormattedMessage id="signup.referral.emailError.title" defaultMessage="We’ve met before" />
          </h1>
          <div className="mb-8">
            <FormattedMessage id="signup.referral.emailError.description" defaultMessage="Sorry, our referral program is for new members only. Nice to see you, though." />
          </div>
          <Button
            block
            size="lg"
            href={urls.login}
            className="mb-3"
          >
            <FormattedMessage id="signup.referral.emailError.signIn" defaultMessage="Sign In" />
          </Button>
          <Button
            variant="secondary-outline"
            block
            size="lg"
            href={externalUrls.setappSite}
          >
            <FormattedMessage id="signup.referral.emailError.takeHome" defaultMessage="Take me home" />
          </Button>
        </div>
      );
    }

    if (errorMessage) {
      return (
        <div>
          <h1 className="mb-3 h3" data-qa="pageTitle">
            <FormattedMessage id="signup.referral.linkError.title" defaultMessage="Wrong invitation link" />
          </h1>
          <div className="mb-8">
            {errorMessage}
          </div>
          <Button
            block
            size="lg"
            href={externalUrls.setappSite}
          >
            <FormattedMessage id="signup.referral.linkError.takeHome" defaultMessage="Take me home" />
          </Button>
        </div>
      );
    }

    return (
      <div>
        <h1 className="mb-8 h3" data-qa="pageTitle">
          <FormattedMessage id="signup.referral.title" defaultMessage="You’ve been invited to join Setapp" />
        </h1>

        {signUpForm}
      </div>
    );
  }

  checkReferral(referral: string) {
    // TODO: move to referral service
    return fetch.get(`${apiURL.referralInfo}?referral=${referral}`)
      .then(() => {
        this.setState({
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });

        if (e.status === httpStatuses.NOT_FOUND) {
          this.setInvalidReferralError();
        } else {
          this.setState({ errorMessage: <DefaultError /> });
        }
      });
  }

  showNoReferralState() {
    this.setState({
      isLoading: false,
    });

    this.setInvalidReferralError();
  }

  setInvalidReferralError() {
    this.setState({
      errorMessage: (
        <FormattedMessage
          id="signup.referral.linkError.description"
          defaultMessage="Sorry, there’s been a trouble with your invitation link. Contact your friend about resending the invite."
        />
      ),
    });
  }
}

const mapStateToProps = (state) => ({
  // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
  appsCount: getAppsCount(state),
  trialLength: getTrialLength(state),
  // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
  monthlyPrice: getDefaultMonthlyPrice(state),
});

export { ReferralSignup as PureReferralSignup };

export default signUpHoc({
  withSocial: true,
  emailDisabled: false,
  type: 'default',
  simplified: true,
  submitButtonText: (
    <FormattedMessage
      id="signup.referral.submitButtonText"
      defaultMessage="Accept Invitation"
    />
  ),
})(connect(mapStateToProps)(ReferralSignup));
