import React from 'react';
import { Button } from '@setapp/ui-kit';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import urls from 'config/urls';

import './confirm-ai-plan-jump-step.scss';

const AiAnnualJumpError = () => {
  return (
    <div className="ai-annual-jump-error">
      <h3 className="mb-6">
        <FormattedMessage
          id="aiOffer.annualError.title"
          defaultMessage="Sorry, we couldn’t switch you to annual billing"
        />
      </h3>
      <p className="mb-8">
        <FormattedMessage
          id="aiOffer.annualError.text"
          defaultMessage="Your AI+ plan is active now. To change the billing period, go to your account and choose Manage Subscription."
        />
      </p>
      <Button href={urls.subscription} as={Link} block>
        <FormattedMessage
          id="aiOffer.annualError.button"
          defaultMessage="My account"
        />
      </Button>
    </div>
  );
};

export default AiAnnualJumpError;
