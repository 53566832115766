import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Notification } from '@setapp/ui-kit';
import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';

import externalUrls from 'config/external-urls';

import analytics, { events } from 'utils/analytics';

import './activate-now-notification.scss';

const ActivateNowNotification = () => {
  return (
    <Notification type="info">
      <div className="activate-now-notification__container">
        <p className="mb-0">
          <FormattedMessage
            id="subscriptionInfo.statusNotifications.subscriptionNotStarted"
            defaultMessage="Start your subscription by signing into Setapp desktop app."
          />
        </p>
        <div className="activate-now-notification__action">
          <SetappDownloadLinkWrapper>
            <Button
              size="sm"
              href={externalUrls.downloadDesktopClient}
              onClick={() => analytics.trackEvent(events.SUBSCRIPTION_BLOCK_DOWNLOAD_CLICK)}
            >
              <FormattedMessage
                id="subscriptionInfo.statusNotifications.downloadSetapp"
                defaultMessage="Download Setapp"
              />
            </Button>
          </SetappDownloadLinkWrapper>
        </div>
      </div>
    </Notification>
  );
};

export default ActivateNowNotification;
