import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { calculateNextPricePlan } from 'services/price-plans/price-plans-api';
import type { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { FAMILY_ANNUAL, FAMILY_MONTHLY } from 'state/price-plans/price-plans-types';
import { getDisplayedPricePlan, getFamilyPricePlans, getPrimarySubscription } from 'state/root-reducer';

import DefaultError from 'components/shared/default-error/default-error';
import Modal from 'components/shared/modal/modal';

import ChangePricePlanFlow from 'components/user-flow/change-price-plan/change-price-plan-flow/change-price-plan-flow';

type Props = {
  // props from modal wrapper
  show: boolean;
  onHide?: () => void;
  onExited?: () => void;
};

const CreateFamilyForActiveUserModal = (props: Props) => {
  const { show, onHide, onExited } = props;

  const dispatch = useDispatch();
  const displayedPricePlan = useSelector(getDisplayedPricePlan);
  const familyPricePlans = useSelector(getFamilyPricePlans);
  const primarySubscription = useSelector(getPrimarySubscription);

  const [isLoading, setIsLoading] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(true);
  const [nextPricePlanCalculationData, setNextPricePlanCalculationData] = useState<NextPricePlanCalculationData>();

  const familyMonthlyPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_MONTHLY);
  const familyAnnualPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_ANNUAL);

  const nextPricePlan = primarySubscription?.pricePlan?.paidMonth === 12
    ? familyAnnualPricePlan
    : familyMonthlyPricePlan;

  const handleClose = () => {
    onExited?.();
  };

  const showDefaultError = () => {
    dispatch(showDangerNotification(<DefaultError />));
    handleClose();
  };

  // calculate next price plan on mount
  useEffect(() => {
    (async () => {
      // this check only for TS
      if (!primarySubscription || !nextPricePlan) {
        return;
      }

      try {
        const nextPricePlanCalculationData = await calculateNextPricePlan(primarySubscription.id, nextPricePlan.id);
        setNextPricePlanCalculationData(nextPricePlanCalculationData);
      } catch {
        showDefaultError();
      }

      setIsLoading(false);
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This check only for TS
  if (!displayedPricePlan || !primarySubscription || !nextPricePlan) {
    return null;
  }

  const handleStartPayment = () => setShowCloseButton(false);

  const handleStopPayment = () => setShowCloseButton(true);

  const renderContent = () => {
    // Return null when fetching calculation data so modal can display a loader
    if (!nextPricePlanCalculationData) {
      return null;
    }

    return (
      <ChangePricePlanFlow
        primarySubscription={primarySubscription}
        currentPricePlan={displayedPricePlan}
        nextPricePlan={nextPricePlan}
        nextPricePlanCalculationData={nextPricePlanCalculationData}
        onGoBackward={handleClose}
        onClose={handleClose}
        onStartPayment={handleStartPayment}
        onStopPayment={handleStopPayment}
      />
    );
  };

  return (
    <Modal
      fullScreen
      isLoading={isLoading}
      show={show}
      withCloseButton={showCloseButton}
      onHide={onHide}
      onExited={onExited}
    >
      {renderContent()}
    </Modal>
  );
};

export default CreateFamilyForActiveUserModal;
