import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import { getSupportUrl } from 'utils/support';

import PageDescription from 'components/shared/page-description/page-description';
import PageTitle from 'components/shared/page-title/page-title';

import './unknown-ui-error.scss';

type Props = {
  error: Error;
  fullscreen: boolean;
};

const UnknownUIError = ({ error, fullscreen }: Props) => {
  const classname = fullscreen ? ' unknown-ui-error_fullscreen' : '';

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div className={`unknown-ui-error ${classname}`}>
      <div className="unknown-ui-error__content">
        <PageTitle>
          <FormattedMessage id="unknownUIError.title" defaultMessage="Page failed to load." />
        </PageTitle>
        <PageDescription>
          <p className="text_lg">
            <FormattedMessage
              id="unknownUIError.description"
              defaultMessage="Please try to reload the page and {contactSupportLink} if this error appears again"
              values={{
                contactSupportLink: <a href={getSupportUrl()}><FormattedMessage id="unknownUIError.contactSupportLink" defaultMessage="contact support" /></a>,
              }}
            />
          </p>
          <FormattedMessage
            id="unknownUIError.errorMessageText"
            defaultMessage="Error message: {errorMessage}"
            values={{
              errorMessage: error.message,
            }}
          />
        </PageDescription>
        <Button className="mt-8" variant="secondary-outline" onClick={handleReloadClick}>
          <FormattedMessage id="unknownUIError.reloadPageButtonTitle" defaultMessage="Reload page" />
        </Button>
      </div>
    </div>
  );
};

export default UnknownUIError;
