// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonLoading, Button } from '@setapp/ui-kit';
import PageTitle from 'components/shared/page-title/page-title';

import './retry-subscription-activation.scss';

type Props = {|
  onRetryClick: () => Promise<mixed>,
  onBackClick: () => void,
  isLoading: boolean,
|};

const RetrySubscriptionActivation = ({ onRetryClick, isLoading, onBackClick }: Props) => (
  <div>
    <PageTitle>
      <FormattedMessage id="activatePage.tryAgain.title" defaultMessage="Please try again" />
    </PageTitle>
    <div className="retry-activation__description">
      <FormattedMessage
        id="activatePage.tryAgain.description"
        defaultMessage="We’re afraid, an issue has occurred, and we couldn’t complete your payment and activate your account."
      />
    </div>
    <ButtonLoading
      onClick={onRetryClick}
      disabled={isLoading}
      isLoading={isLoading}
      block
      className="retry-activation__retry-button"
      loadingText={
        <FormattedMessage id="activatePage.tryAgain.tryAgainLoadingText" defaultMessage="Payment in progress…" />
      }
    >
      <FormattedMessage
        id="activatePage.tryAgain.tryAgainButton"
        defaultMessage="Try again"
      />
    </ButtonLoading>
    <Button
      block
      variant="secondary-outline"
      disabled={isLoading}
      onClick={onBackClick}
    >
      <FormattedMessage
        id="activatePage.tryAgain.backButton"
        defaultMessage="Back to payment details"
      />
    </Button>
  </div>
);

export default RetrySubscriptionActivation;
