import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import { FormattedMessage } from 'react-intl';
import httpStatuses from 'http-status';
import RequestError from '@setapp/request-error';
import queryString from 'query-string';

import type { RouteComponentProps } from 'react-router-dom';

import PageTitle from 'components/shared/page-title/page-title';
import DefaultError from 'components/shared/default-error/default-error';

import {
  updateLocale, unsetLocaleChangedFlag, confirmEmail, updateUser,
} from 'state/user/user-actions';
import {
  showDangerNotification,
  showWarningNotification,
  showSuccessNotification,
} from 'state/notifier/notifier-reducer';
import { getUser } from 'state/root-reducer';

import { LOCALE_NAMES } from 'config/locales';

import { removeQueryParams } from 'utils/location';

import DeleteAccount from './delete-account/delete-account';
import ConnectedUserPassword from './user-password/user-password';
import ConnectedUserInfo from './user-info/user-info';
import UserLocaleForm from './user-locale/user-locale';


import './account-page.scss';

const CONFIRM_EMAIL_QUERY_PARAM = 'email_confirmation_token';
const RESUBSCRIBE_TO_EMAILS_QUERY_PARAM = 'resubscribe_to_emails';

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapActionsToProps = {
  updateLocale,
  unsetLocaleChangedFlag,
  confirmEmail,
  updateUser,
  showWarningNotification,
  showDangerNotification,
  showSuccessNotification,
};

const connector = connect(
  mapStateToProps,
  mapActionsToProps,
);

type Props = {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
} & ConnectedProps<typeof connector>;

class AccountPage extends PureComponent<Props> {
  componentDidMount() {
    const { user, unsetLocaleChangedFlag } = this.props;

    if (user.isLocaleChanged) {
      this.displayLocaleChangeSuccessNotification();
      unsetLocaleChangedFlag();
    }

    this.processQueryParams();
  }

  render() {
    const { user } = this.props;

    return (
      <div className="account-settings">
        <PageTitle>
          <FormattedMessage id="accountSettings.title" defaultMessage="Account settings" />
        </PageTitle>

        <Row>
          <Col md={5} lg={4}>
            <div className="account-settings-section">
              <ConnectedUserInfo />
            </div>
          </Col>

          <Col md={5} lg={4} smOffset={0} mdOffset={1}>
            <div className="account-settings-section">
              <ConnectedUserPassword />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={11} lg={9}>
            <div className="account-settings-delimiter" />
          </Col>
        </Row>

        <Row>
          <Col md={5} lg={4}>
            <div className="account-settings-section">
              <UserLocaleForm
                currentLocale={user.locale}
                supportedLocales={LOCALE_NAMES}
                onSaveLocale={this.onUpdateLocale}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={11} lg={9}>
            <div className="account-settings-delimiter" />
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={4}>
            <div className="account-settings-section">
              <DeleteAccount />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  onUpdateLocale = (locale: string): Promise<void> => {
    const { user, updateLocale, showDangerNotification } = this.props;

    if (locale === user.locale) {
      this.displayLocaleChangeSuccessNotification();

      return Promise.resolve();
    }

    return updateLocale(locale)
      .catch((error) => {
        showDangerNotification(error.message);
      });
  };

  displayLocaleChangeSuccessNotification() {
    const { showSuccessNotification } = this.props;

    showSuccessNotification(
      <FormattedMessage
        id="accountSettings.userLocales.successNotification"
        defaultMessage="Account language successfully updated."
      />,
    );
  }

  processQueryParams() {
    const { location, history } = this.props;
    const query = queryString.parse(location.search);

    const emailConfirmationToken = query[CONFIRM_EMAIL_QUERY_PARAM];
    const reSubscribeToEmails = query[RESUBSCRIBE_TO_EMAILS_QUERY_PARAM];

    if (emailConfirmationToken && reSubscribeToEmails) {
      this.confirmEmailAndReSubscribeToEmails();
    } else if (emailConfirmationToken) {
      this.confirmEmail();
    } else if (reSubscribeToEmails) {
      this.reSubscribeToEmails();
    }

    if (CONFIRM_EMAIL_QUERY_PARAM in query || RESUBSCRIBE_TO_EMAILS_QUERY_PARAM in query) {
      removeQueryParams(history, CONFIRM_EMAIL_QUERY_PARAM, RESUBSCRIBE_TO_EMAILS_QUERY_PARAM);
    }
  }

  confirmEmail(): Promise<void> {
    const { location, confirmEmail, showSuccessNotification } = this.props;
    const query = queryString.parse(location.search);

    // @ts-expect-error TS(2349): This expression is not callable.
    return confirmEmail(query[CONFIRM_EMAIL_QUERY_PARAM])
      .then(() => {
        showSuccessNotification(
          <FormattedMessage
            id="permanentNotifications.emailConfirmation.success"
            defaultMessage="Email address successfully verified."
          />,
        );
      })
      .catch((error) => this.onEmailConfirmationError(error));
  }

  onEmailConfirmationError(error: RequestError) {
    const { showWarningNotification, showDangerNotification } = this.props;
    const isTokenInvalid = error.status === httpStatuses.BAD_REQUEST && error.getFieldError('emailConfirmationToken');

    if (isTokenInvalid) {
      showWarningNotification(error.getFieldError('emailConfirmationToken').toString());
    } else {
      showDangerNotification(<DefaultError />);
    }
  }

  reSubscribeToEmails(): Promise<void> {
    const { updateUser, showDangerNotification, showSuccessNotification } = this.props;

    return updateUser({ marketingSubscribed: true })
      .then(() => {
        showSuccessNotification(
          <FormattedMessage
            id="accountSettings.reSubscriptionToEmailsSuccess"
            defaultMessage="Email confirmed. Thanks for staying in touch!"
          />,
        );
      })
      .catch(() => {
        showDangerNotification(<DefaultError />);
      });
  }

  confirmEmailAndReSubscribeToEmails(): Promise<void> {
    const { confirmEmail, location } = this.props;
    const query = queryString.parse(location.search);

    // @ts-expect-error TS(2349): This expression is not callable.
    return confirmEmail(query[CONFIRM_EMAIL_QUERY_PARAM])
      .catch(() => Promise.resolve()) // ignore email confirmation error and proceed to re-subscribing
      .then(() => this.reSubscribeToEmails());
  }
}

export { AccountPage as PureAccountPage };

export default connector(AccountPage);
