import RequestError from '@setapp/request-error';
import type { FamilyPlanState, FamilyMember } from './family-plan-initial-state';

export const REQUEST = 'FAMILY_PLAN_REQUEST';
export const REQUEST_SUCCESS = 'FAMILY_PLAN_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'FAMILY_PLAN_REQUEST_ERROR';
export const ADD_MEMBER = 'FAMILY_PLAN_ADD_MEMBER';
export const REMOVE_MEMBER = 'FAMILY_PLAN_REMOVE_MEMBER';
export const DROP = 'FAMILY_PLAN_DROP';

export type FamilyRequestAction = {
  type: 'FAMILY_PLAN_REQUEST';
};

export type FamilyRequestSuccessAction = {
  type: 'FAMILY_PLAN_REQUEST_SUCCESS';
  payload?: FamilyPlanState;
};

export type FamilyRequestErrorAction = {
  type: 'FAMILY_PLAN_REQUEST_ERROR';
  payload: typeof RequestError;
};

export type FamilyAddMemberAction = {
  type: 'FAMILY_PLAN_ADD_MEMBER';
  payload: FamilyMember;
};

export type FamilyRemoveMemberAction = {
  type: 'FAMILY_PLAN_REMOVE_MEMBER';
  payload: number;
};

export type FamilyDropAction = {
  type: 'FAMILY_PLAN_DROP';
};

export type FamilyAction =
  | FamilyRequestAction
  | FamilyRequestSuccessAction
  | FamilyRequestErrorAction
  | FamilyAddMemberAction
  | FamilyRemoveMemberAction
  | FamilyDropAction;
