import React, { ReactNode, SyntheticEvent } from 'react';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
} from 'react-intl';
import { FormField, ButtonLoading } from '@setapp/ui-kit';
import classnames from 'classnames';

export type LoginFormFields = {
  email: string;
  captcha?: string;
}

type Props = {
  onFormSubmit: (e: SyntheticEvent<HTMLFormElement>) => void;
  onEmailChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  isRequestProcessing: boolean;
  emailError: ReactNode;
  genericError: ReactNode;
  email: string;
  captcha?: ReactNode;
} & InjectedIntlProps;

const messages = defineMessages({
  emailPlaceholder: {
    id: 'oauth.recover-password.emailPlaceholder',
    defaultMessage: 'Enter your email',
  },
});

const RecoverPasswordForm = (props: Props) => {
  const {
    intl,
    captcha,
    email,
    emailError,
    genericError,
    onFormSubmit,
    onEmailChange,
    isRequestProcessing,
  } = props;

  const errorClasses = classnames('form-error text-danger text_sm', {
    hidden: !genericError,
  });

  return (
    <form onSubmit={onFormSubmit} noValidate>
      <FormField
        autoFocus
        bsSize="lg"
        id="email"
        type="email"
        name="email"
        autoComplete="email"
        placeholder={intl.formatMessage(messages.emailPlaceholder)}
        helpText={emailError}
        invalid={Boolean(emailError)}
        onChange={onEmailChange}
        value={email}
        qaLabel="emailField"
      />

      <p className={errorClasses}>{genericError}</p>

      <ButtonLoading
        block
        size="lg"
        type="submit"
        disabled={isRequestProcessing}
        isLoading={isRequestProcessing}
        data-qa="submitBtn"
      >
        <FormattedMessage id="oauth.recover-password.submitBtnCaption" defaultMessage="Reset Password" />
      </ButtonLoading>

      {captcha}
    </form>
  );
};

RecoverPasswordForm.defaultProps = {
  email: '',
};

export { RecoverPasswordForm as PureRecoverPasswordForm };

export default injectIntl(RecoverPasswordForm);
