// @flow

import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
  injectIntl,
} from 'react-intl';
import type { IntlShape } from 'react-intl';
import { SelectField, ButtonLoading } from '@setapp/ui-kit';

import type { BraintreePaymentDetailsPayload } from 'services/payment-details-api/payment-details-api';

import BraintreeVerified from 'components/shared/braintree-verified/braintree-verified';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';

import PaymentMethodFormVatContainer from '../payment-method-form-vat-container/payment-method-form-vat-container';

import './paypal-form.scss';

type Props = {
  countries: {[string]: string},
  needsCountry: boolean,
  email: string,

  merchantId: string,
  isLoading: boolean,
  formError: Node,
  fieldsWithError: {
    country: Node,
  },
  onFieldChange: (string, string) => mixed,
  onFormSubmit: SyntheticEvent<HTMLFormElement> => mixed,
  isPaymentMethodCreated: boolean,
  paymentInfo: BraintreePaymentDetailsPayload,
  submitBtnTitle?: Node,
  captcha: ?Node,
  intl: IntlShape,
  loadingText: ?Node,
  isInitialized: boolean,
};

const PaypalForm = (props: Props) => {
  const {
    onFormSubmit,
    formError,
    isLoading,
    merchantId,
    onFieldChange,
    countries,
    needsCountry,
    email,
    isPaymentMethodCreated,
    paymentInfo,
    submitBtnTitle,
    fieldsWithError,
    captcha,
    intl,
    loadingText,
    isInitialized,
  } = props;
  const { country } = paymentInfo;
  const { country: countryError } = fieldsWithError;

  const errorClasses = classnames('form-error text-danger paypal-form-error', {
    hidden: !formError,
  });

  const messages = defineMessages({
    countrySelectPlaceholder: {
      id: 'paypalForm.countryDefault',
      defaultMessage: 'Select',
    },
  });

  return (
    <form className="paypal-form" onSubmit={onFormSubmit} noValidate>
      <div>
        {getDescription()}
        {needsCountry && (
          <SelectField
            id="country"
            name="country"
            onChange={(e) => onFieldChange(e.target.name, e.target.value)}
            invalid={Boolean(countryError)}
            helpText={countryError}
            value={country}
            options={countries}
            showLabel
            label={<FormattedMessage id="paypalForm.country" defaultMessage="Select your country" />}
            defaultOptionLabel={intl.formatMessage(messages.countrySelectPlaceholder)}
            disabled={isLoading}
          />
        )}

        <div className="paypal-form__vat-fields">
          <PaymentMethodFormVatContainer
            fieldsWithError={fieldsWithError}
            paymentInfo={paymentInfo}
            isLoading={isLoading}
            onFieldChange={onFieldChange}
          />
        </div>

        {captcha}

        <p className={errorClasses}>{formError}</p>
      </div>

      <ButtonLoading
        className="paypal-form-submit-btn"
        block
        type="submit"
        disabled={isLoading}
        isLoading={isLoading}
        loadingText={isInitialized ? loadingText : ''}
      >
        {getBtnTitle()}
      </ButtonLoading>

      <BraintreeVerified merchantId={merchantId} />
    </form>
  );

  function getDescription() {
    if (email) {
      return (
        <p className="paypal-form-description">
          <FormattedMessage
            id="paypalForm.descriptionUpdate"
            defaultMessage="Logged in as {email}"
            values={{
              email: <strong>{email}</strong>,
            }}
          />
        </p>
      );
    }

    return (
      <p className="paypal-form-description">
        <FormattedHTMLMessage
          id="paypalForm.descriptionAdd"
          defaultMessage="A secure payment alternative.<br/>Start using Setapp faster."
        />
      </p>
    );
  }

  function getBtnTitle() {
    if (submitBtnTitle) {
      return submitBtnTitle;
    }

    if (!email) {
      return <FormattedMessage id="paymentDetails.paypal.submitBtnTitleProceed" defaultMessage="Proceed to PayPal" />;
    }

    return <PaymentDetailsActionText action={isPaymentMethodCreated ? 'update' : 'add'} />;
  }
};

PaypalForm.defaultProps = {
  submitBtnTitle: null,
};

export { PaypalForm as PurePaypalForm };

export default injectIntl(PaypalForm);
