import React from 'react';
import type { ReactNode } from 'react';

import classNames from 'classnames';

import { useSidebarNavContext } from '../sidebar-nav-context';

import './sidebar-nav-collapse.scss';

type Props = {
  children: ReactNode;
};

const SidebarNavCollapse = ({ children }: Props) => {
  const { expanded } = useSidebarNavContext();

  const rootClasses = classNames(
    'sidebar-nav__collapse',
    { 'sidebar-nav__collapse_expanded': expanded }
  );

  return (
    <div className={rootClasses}>
      {children}
    </div>
  );
};

export default SidebarNavCollapse;
