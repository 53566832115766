export type PricePlanFeatures = Readonly<{
  chargeAfterPaymentInfoSet?: boolean;
  macSeatsCount: number;
  iosSeatsCount?: number;
  iosEuSeatsCount?: number;
  maxMembersCount?: number;
  trialSwitchFromStrategy?: 'forbidden' | 'recalculate_remaining_days' | 'recalculate_remaining_amount';
  trialPeriodDays?: number;
  vendorApiUsageLimitInCredits?: number;
  allowedRequestSizeInTokens?: number;
}>;

export type PricePlan = Readonly<{
  id: number;
  paidMonth: number;
  price: number;
  currency: string;
  tierType: string;
  active: boolean;
  hidden: boolean;
  group: string;
  seatsCount: number;
  maxSeatsCount: number;
  features?: PricePlanFeatures;
  isTrial: boolean;
  priceKey: string;
}>;

export type PricePlanState = Readonly<{
  list: Array<PricePlan>;
  isLoading: boolean;
}>;

const initialState: PricePlanState = {
  list: [],
  isLoading: false,
};

export default initialState;
