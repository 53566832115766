import React from 'react';
import { FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl';

import * as invoiceStatuses from 'state/invoices/invoice-statuses';
import type { Invoice } from 'state/invoices/invoices-initial-state';
import { apiURL } from 'config/api';

import './payment-history-item.scss';
import { getTokenManager } from 'utils/service-locators';

type Props = {
  invoice: Invoice;
};

const PaymentHistoryItem = (props: Props) => {
  const { invoice } = props;
  const {
    id,
    paymentDate,
    amount,
    taxAmount,
    currency,
    status,
  } = invoice;
  const tokenManager = getTokenManager();

  return (
    <tr className="payment-history__item" data-qa="paymentHistoryTableItem">
      <td className="payment-history__cell">
        <span>
          <FormattedDate
            value={paymentDate * 1000}
            year="numeric"
            month="short"
            day="numeric"
          />
        </span>
      </td>
      <td className="payment-history__cell">
        {/* eslint-disable-next-line react/style-prop-object */}
        <FormattedNumber value={amount} style="currency" currency={currency} />
        {status === invoiceStatuses.PAID && taxAmount > 0 && (
          <>
            {' '}
            <FormattedMessage
              id="paymentHistory.item.amount"
              defaultMessage="({taxAmount} tax)"
              values={{
                // eslint-disable-next-line react/style-prop-object
                taxAmount: <FormattedNumber value={taxAmount} style="currency" currency={currency} />,
              }}
            />
          </>
        )}
        {status === invoiceStatuses.REFUNDED && (
          <>
            {' '}
            <FormattedMessage id="paymentHistory.item.refundedStatus" defaultMessage="(refunded)" />
          </>
        )}
      </td>
      <td className="payment-history__cell text-right">
        <a
          href={
            apiURL.downloadInvoice
              .replace('%{invoiceId}', id)
              .replace('%{token}', tokenManager.getAccessToken())
          }
        >
          <FormattedMessage id="paymentHistory.download" defaultMessage="Download" />
        </a>
      </td>
    </tr>
  );
};

export default PaymentHistoryItem;
