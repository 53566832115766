import React, { ReactNode } from 'react';
import { AnimatedLogo } from '@setapp/ui-kit';
import classnames from 'classnames';

import NotificationContainer from 'components/shared/notification/notifications-container';
import CookieBanner from 'components/shared/banners/cookie-banner/cookie-banner';

import AppHeader from '../app-layout/header/app-header';
import ModalRoot from '../app-layout/modal-root/modal-root';

import Footer from '../footer/footer';
import FullscreenLayoutPrimaryContent from './fullscreen-layout-primary-content';
import FullscreenLayoutSecondaryContent from './fullscreen-layout-secondary-content';

import './fullscreen-layout.scss';

type Props = {
  children: ReactNode;
  withHeader?: boolean;
  withLogo?: boolean;
  withFooter?: boolean;
  darkTheme?: boolean;
};

const FullscreenLayout = ({
  children,
  withHeader = false,
  withLogo = false,
  withFooter = false,
  darkTheme = false,
}: Props) => {
  const layoutClasses = classnames('fullscreen-layout', {
    'fullscreen-layout_dark': darkTheme,
  });

  return (
    <div className={layoutClasses}>
      {withHeader && (
        <header className="fullscreen-layout__header navbar-fixed-top">
          <div className="container-fluid">
            <AppHeader />
          </div>
        </header>
      )}
      {withLogo && (
        <div className="fullscreen-layout__logo-container">
          <div className="fullscreen-layout__logo">
            <AnimatedLogo size="sm" hasCaption inverse={darkTheme} />
          </div>
        </div>
      )}
      <div className="fullscreen-layout__notifications">
        <NotificationContainer />
      </div>
      <div className="fullscreen-layout__container">
        {children}
      </div>
      {withFooter && <Footer />}
      <CookieBanner />
      <ModalRoot />
    </div>
  );
};

FullscreenLayout.PrimaryContent = FullscreenLayoutPrimaryContent;
FullscreenLayout.SecondaryContent = FullscreenLayoutSecondaryContent;

export default FullscreenLayout;
