import type { PricePlan } from './price-plans-initial-state';

export const REQUEST = 'PRICE_PLANS_REQUEST';
export const REQUEST_SUCCESS = 'PRICE_PLANS_REQUEST_SUCCESS';
export const REQUEST_ERROR = 'PRICE_PLANS_REQUEST_ERROR';

export type RequestAction = {
  type: 'PRICE_PLANS_REQUEST';
};

export type RequestSuccessAction = {
  type: 'PRICE_PLANS_REQUEST_SUCCESS';
  payload: Array<PricePlan>;
};

export type RequestErrorAction = {
  type: 'PRICE_PLANS_REQUEST_ERROR';
  payload: unknown;
  error: true;
};

export type PricePlansAction =
  | RequestAction
  | RequestSuccessAction
  | RequestErrorAction;
