import React from 'react';
import { FormattedMessage } from 'react-intl';

import BasePermissionItem from '../base-permission-item/base-permission-item';
import userIcon from '../images/user.svg';

type Props = {
  appName: string;
  hasNamePermission: boolean;
  hasEmailPermission: boolean;
}

const PermissionUserDataItem = ({ appName, hasNamePermission, hasEmailPermission }: Props) => {
  const getTitle = () => {
    if (hasNamePermission && !hasEmailPermission) {
      return (
        <FormattedMessage
          id="oauth.permission.userData.nameTitle"
          defaultMessage="Your name"
        />
      );
    }

    if (!hasNamePermission && hasEmailPermission) {
      return (
        <FormattedMessage
          id="oauth.permission.userData.emailTitle"
          defaultMessage="Your email"
        />
      );
    }

    return (
      <FormattedMessage
        id="oauth.permission.userData.nameAndEmailTitle"
        defaultMessage="Your name and email"
      />
    );
  };

  const getSubTitle = () => {
    if (hasNamePermission && !hasEmailPermission) {
      return (
        <FormattedMessage
          id="oauth.permission.userData.nameSubTitle"
          defaultMessage="{appName} will read your name, registered in Setapp"
          values={{ appName }}
        />
      );
    }

    if (!hasNamePermission && hasEmailPermission) {
      return (
        <FormattedMessage
          id="oauth.permission.userData.emailSubTitle"
          defaultMessage="{appName} will read your email, registered in Setapp"
          values={{ appName }}
        />
      );
    }

    return (
      <FormattedMessage
        id="oauth.permission.userData.nameAndEmailSubTitle"
        defaultMessage="{appName} will read your name and email, registered in Setapp"
        values={{ appName }}
      />
    );
  };

  return (
    <BasePermissionItem
      icon={userIcon}
      title={getTitle()}
      subTitle={getSubTitle()}
      qaLabel="permissionItemUserData"
    />
  );
};

export default PermissionUserDataItem;
