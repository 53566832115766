import { updateIntl } from 'react-intl-redux';
import { addLocaleData } from 'react-intl';
import type { InferThunkActionCreatorType } from 'react-redux';
import type { SignUpMetadata } from '@setapp/signup-metadata';
import { FeatureFlag } from '@setapp/abn-tests-client';

import { apiURL } from 'config/api';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from 'config/locales';

import auth from 'utils/auth';
import $ from 'utils/request';
import logger from 'utils/logger';
import {
  setCookieLocale,
  serverToBrowserLocale,
  browserToServerLocale,
  loadLocaleData,
} from 'utils/intl';
import { getSignupMetadata, getTokenManager } from 'utils/service-locators';
import analytics, { events } from 'utils/analytics';

import { resetFeatureFlags } from 'state/feature-flags/feature-flags-actions';
import type { Dispatch } from 'state/state-types';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import type { User } from './user-initial-state';
import * as types from './user-actions-types';

export type CommonSignupParams = SignUpMetadata & {
  marketingSubscribed?: boolean;
  giftCard?: string | null;
  promoCode?: string | null;
  name?: string;
  captcha?: string;
  tierType?: string;
  innerRedirectUri?: string;
};

type EmailSignupParms = CommonSignupParams & {
  email: string;
  password: string;
};

export type SocialAuthParams = CommonSignupParams & {
  accessToken: string;
  email?: string;
};

export type UpdateUserParams = Partial<User> & {
  password?: string;
  currentPassword?: string;
  testSegments?: FeatureFlag[];
};

const signUpMetadataManager = getSignupMetadata();

export const setUILocale = (locale: string) => async (dispatch: Dispatch) => {
  const isLocaleSupported = SUPPORTED_LOCALES.indexOf(locale) !== -1;
  const nextLocale = isLocaleSupported ? locale : DEFAULT_LOCALE;

  const [localeData, localeMessages] = await loadLocaleData(nextLocale);
  addLocaleData(localeData.default);

  dispatch(updateIntl({
    locale: nextLocale,
    messages: localeMessages,
  }));

  setCookieLocale(nextLocale);
};

export const setUserInfo = (data: Partial<User>) => (dispatch: Dispatch) => {
  if (data.id) {
    logger.setUserData({ id: data.id.toString() });
  }

  let assimilatedLocale;

  if (data.locale) {
    assimilatedLocale = serverToBrowserLocale(data.locale);

    dispatch(setUILocale(assimilatedLocale));
  }

  dispatch({
    type: types.SET_USER_INFO,
    payload: assimilatedLocale ? { ...data, locale: assimilatedLocale } : data,
  });
};

// TODO: remove from exports and create specific actions for changing user info and password
export const updateUser = (data: UpdateUserParams) => (dispatch: Dispatch) => {
  dispatch({
    type: types.REQUEST,
  });

  return $.patch(apiURL.account, { body: data })
    .then((data) => {
      dispatch({
        type: types.REQUEST_SUCCESS,
      });
      dispatch(setUserInfo({ ...data }));
    })
    .catch((error) => {
      dispatch({
        type: types.REQUEST_ERROR,
      });

      return Promise.reject(error);
    });
};

export const setPaymentInfo = () => ({
  type: types.SET_USER_PAYMENT_INFO,
  payload: false,
} as const);

export const fetchUser = (resetFeatureFlagsClient = false) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.REQUEST,
  });

  try {
    const data = await $.get(apiURL.account);

    dispatch({
      type: types.REQUEST_SUCCESS,
    });
    dispatch(setUserInfo(data));

    if (resetFeatureFlagsClient) {
      // reset feature flags module with actual satu ID and evidences
      await dispatch(resetFeatureFlags());
    }
  } catch (error: any) {
    dispatch({
      type: types.REQUEST_ERROR,
      payload: error,
    });
    dispatch(showDangerNotification(error.getSimplifiedErrors().genericError));

    throw error;
  }
};
export type FetchUserAction = InferThunkActionCreatorType<typeof fetchUser>;

export const setUserLoggedOut = () => (dispatch: Dispatch) => {
  dispatch({
    type: types.LOGOUT,
  });

  logger.unsetUserData();
};

export const logout = () => (dispatch: Dispatch) => auth.logout()
  .then(() => {
    analytics.trackEvent(events.LOGOUT_SUCCESS);
    dispatch(setUserLoggedOut());
  });

export type LoginData = {
  email: string;
  password: string;
  captcha?: string;
}

export const login = (loginData: LoginData) => (dispatch: Dispatch) => auth.login(loginData)
  .then(() => dispatch(fetchUser(true)))
  .then(() => dispatch({ type: types.LOGIN }));

export const loginWithOneTimeToken = (accessToken: string) => (dispatch: Dispatch) => (
  auth.loginWithOneTimeToken(accessToken)
    .then(() => dispatch(fetchUser(true)))
    .then(() => {
      dispatch({ type: types.LOGIN });
      analytics.trackEvent(events.CROSS_AUTH_SUCCESS);
    })
    .catch(() => {
      analytics.trackEvent(events.CROSS_AUTH_ERROR);

      return Promise.reject();
    })
);

export const signUp = (userData: EmailSignupParms) => (dispatch: Dispatch) => {
  // Must be here because signup metadata is cleared after successful signup
  const { inviteId } = signUpMetadataManager.getAll();

  return auth.signup(userData)
    .then(() => dispatch(fetchUser(true)))
    // Should be called after fetching user's profile to have user info in analytics events
    .then(() => {
      analytics.trackEvent(events.SIGNUP_SUCCESS, { token: inviteId });

      if (userData.marketingSubscribed) {
        analytics.trackEvent(events.SIGNUP_EMAIL_OPT_IN);
      }
    })
    .then(() => dispatch({ type: types.LOGIN }));
};

export const resetPassword = (newPassword: string, resetToken: string) => (dispatch: Dispatch) => (
  auth.resetPassword(newPassword, resetToken)
    .then(() => dispatch(fetchUser(true)))
    .then(() => dispatch({ type: types.LOGIN }))
);

export const confirmEmail = (emailConfirmationToken: string) => (dispatch: Dispatch) => (
  $.post(apiURL.confirmEmail, { body: { emailConfirmationToken } })
    .then((response) => dispatch(setUserInfo(response)))
);

export const resendConfirmationEmail = ({ captcha }: {captcha?: string | null} = {}) => () => {
  const requestOptions = captcha ? { body: { captcha } } : {};

  return $.post(apiURL.resendConfirmationEmail, requestOptions);
};

export const deleteAccount = (password: string) => (dispatch: Dispatch) => {
  dispatch({
    type: types.REQUEST,
  });

  return $.post(apiURL.deleteAccount, { body: { password } })
    .then(() => {
      const tokenManager = getTokenManager();
      tokenManager.removeTokens();

      dispatch({
        type: types.DELETE_ACCOUNT,
      });

      analytics.trackEvent(events.DELETE_ACCOUNT_CONFIRM);
    })
    .catch((error) => {
      dispatch({
        type: types.REQUEST_ERROR,
        error: true,
        payload: error,
      });

      throw error;
    });
};

export const authWithSocialCredentials = (credentials: SocialAuthParams, provider: string) => (dispatch: Dispatch) => (
  auth.authWithSocialCredentials(credentials, provider)
    .then((response) => dispatch(fetchUser(true))
      .then(() => {
        // Should be called after fetching user's profile to have user info in analytics events
        if (credentials.marketingSubscribed) {
          analytics.trackEvent(events.SIGNUP_EMAIL_OPT_IN);
        }
      })
      .then(() => dispatch({ type: types.LOGIN }))
      // response is required for SocialAuthPage component
      .then(() => response))
);

export const linkSocialAccount = (credentials: {accessToken: string}, provider: string) => () => (
  auth.linkSocialAccount(credentials, provider)
);

export const updateLocale = (locale: string) => (dispatch: Dispatch) => (
  dispatch(updateUser({ locale: browserToServerLocale(locale) }))
    .then(() => {
      // for detecting locale change after full app reset caused by IntlProvider
      dispatch(setUserInfo({ isLocaleChanged: true }));
    })
);

export const unsetLocaleChangedFlag = () => (dispatch: Dispatch) => {
  dispatch(setUserInfo({ isLocaleChanged: false }));
};

export const setSignupGiftCardCode = (giftCardCode: string) => (dispatch: Dispatch) => {
  dispatch({
    type: types.SET_SIGNUP_GIFT_CARD_CODE,
    payload: giftCardCode,
  });
};

export const setSignupPromoCode = (promoCode: string) => (dispatch: Dispatch) => {
  dispatch({
    type: types.SET_SIGNUP_PROMO_CODE,
    payload: promoCode,
  });
};
