import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NOT_FOUND } from 'http-status';

import type { Dispatch } from 'state/state-types';

import { apiURL } from 'config/api';

import request from 'utils/request';
import analytics, { events } from 'utils/analytics';

import initialState, { DevicesState, Device } from './devices-initial-state';

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    startDeviceRequest(state) {
      state.isLoading = true;
    },
    onDeviceRequestSuccess(state, action: PayloadAction<Array<Device> | undefined>) {
      state.isLoading = false;
      if (action.payload) {
        state.list = action.payload;
      }
    },
    onDeviceRequestError(state) {
      state.isLoading = false;
    },
  },
});

export const { startDeviceRequest, onDeviceRequestSuccess, onDeviceRequestError } = devicesSlice.actions;

export const fetchDevices = () => (dispatch: Dispatch) => {
  dispatch(startDeviceRequest());

  return request.get(apiURL.devices)
    .then((data) => {
      dispatch(onDeviceRequestSuccess(data));
    })
    .catch(() => {
      dispatch(onDeviceRequestError());
    });
};

export const deactivateDevicesDeferred = (ids: Array<number>) => (dispatch: Dispatch) => {
  dispatch(startDeviceRequest());

  // this request will deactivate devices after new cycle starts
  return request.post(apiURL.deactivateDevicesDeferred, { body: { ids } })
    .then(() => {
      dispatch(onDeviceRequestSuccess());
    })
    .catch((error) => {
      dispatch(onDeviceRequestError());

      return Promise.reject(error);
    });
};

export const deactivateDevice = (id: number) => (dispatch: Dispatch) => {
  dispatch(startDeviceRequest());

  return request.delete(`${apiURL.devices}/${id}`)
    .catch((error) => {
      // behave like success deactivation when device has already been deactivated
      if (error.status !== NOT_FOUND) {
        throw error;
      }
    })
    .then(() => {
      analytics.trackEvent(events.DEVICE_DEACTIVATED, { eventLabel: id.toString() });

      return dispatch(fetchDevices());
    }).catch((error) => {
      dispatch(onDeviceRequestError());

      return Promise.reject(error);
    });
};

export const deactivateAllIosDevices = () => (dispatch: Dispatch) => {
  dispatch(startDeviceRequest());

  return request.post(apiURL.deactiveAllIosDevices)
    .then(() => {
      analytics.trackEvent(events.DEVICE_ALL_IOS_DEACTIVATED);

      return dispatch(fetchDevices());
    }).catch((error) => {
      dispatch(onDeviceRequestError());

      return Promise.reject(error);
    });
};

export const getList = (state: DevicesState) => state.list;
export const getMacList = (state: DevicesState): Array<Device> => {
  const list = getList(state);
  if (!list) {
    return [];
  }

  return list.filter(
    (device) => device.model !== 'iPhone' && device.model !== 'iPad'
  );
};

export default devicesSlice.reducer;
