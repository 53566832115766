import isAfter from 'date-fns/isAfter';

import type { Subscription } from 'state/subscription/subscription-initial-state';
import * as subscriptionStatuses from 'state/subscription/statuses';
import * as pricePlanGroup from 'state/price-plans/price-plan-groups';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';

export const isStatusCancelled = (subscription: Subscription) => subscription.status === subscriptionStatuses.CANCELLED;

export const isStatusTrial = (subscription: Subscription) => subscription.status === subscriptionStatuses.TRIAL;

export const isStatusNew = (subscription: Subscription) => subscription.status === subscriptionStatuses.NEW;

export const isStatusActive = (subscription: Subscription) => subscription.status === subscriptionStatuses.ACTIVE;

export const isStatusFree = (subscription: Subscription) => subscription.status === subscriptionStatuses.FREE;

export const isStatusBlocked = (subscription: Subscription) => subscription.status === subscriptionStatuses.BLOCKED;

export const getIsTrialUser = (subscription: Subscription) => {
  const isTrialStatus = subscription.status === subscriptionStatuses.TRIAL;
  const isTrialPricePlan = subscription.pricePlan.isTrial;

  return isTrialStatus || isTrialPricePlan;
};

export const getIsTrialExpired = (subscription: Subscription) => {
  const isTrial = getIsTrialUser(subscription);

  if (!isTrial) {
    return false;
  }

  const currentTimestamp = Math.round(Date.now() / 1000);

  return Number(subscription.expirationDate) < currentTimestamp;
};

export const isEduCurrentPricePlan = (state: Subscription) => state.pricePlan.tierType === pricePlanTypes.EDU;
export const isEduNextPricePlan = (state: Subscription) => (
  Boolean(state.nextPricePlan && state.nextPricePlan.tierType === pricePlanTypes.EDU)
);

export const isAdditionalSeat = (subscription: Subscription) => (
  subscription.pricePlan.group === pricePlanGroup.ADDITIONAL_SEAT
);

export const getTrialSeatsCount = (subscription: Subscription): number => {
  if (!isStatusTrial(subscription) && !isStatusNew(subscription)) {
    return 0;
  }

  if (!subscription.nextPricePlan) {
    return 0;
  }

  return Math.max(subscription.pricePlan.seatsCount - subscription.nextPricePlan.seatsCount, 0);
};

export const hasTrialSeats = (subscription: Subscription): boolean => getTrialSeatsCount(subscription) > 0;

export const isActive = (subscription: Subscription) => ![
  subscriptionStatuses.CANCELLED,
  subscriptionStatuses.BLOCKED,
  subscriptionStatuses.NEW,
].includes(subscription.status);

// TODO: rename to "canBeActivated"???
export const isActivationAllowed = (subscription: Subscription): boolean => [
  subscriptionStatuses.TRIAL,
  subscriptionStatuses.NEW,
].includes(subscription.status) && !subscription.paymentPending;

export const canBeResumed = (subscription: Subscription): boolean => (
  isStatusCancelled(subscription) && !subscription.paymentPending
);

export const isSuspendedSubscription = (subscription: Subscription): boolean => (
  subscription.status === subscriptionStatuses.BLOCKED
);

export const isSuspendedSubscriptionWithOldPricePlan = (subscription: Subscription): boolean => {
  const { status, expirationDate } = subscription;
  const isSubscriptionExpired = expirationDate != null && isAfter(Date.now(), expirationDate * 1000);

  if (status === subscriptionStatuses.BLOCKED) {
    return true;
  }

  if (status === subscriptionStatuses.CANCELLED) {
    return isSubscriptionExpired;
  }

  return false;
};

// This selector is used to detect subscriptions with specific price plans like EDU and loyal
export const hasSubscriptionHiddenPricePlan = (subscription: Subscription): boolean => subscription.pricePlan.hidden;
