import { AnimatedLogo } from '@setapp/ui-kit';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import DesktopAppHelper from 'utils/desktop-app-helper';
import { getUserEmail } from 'state/root-reducer';
import AuthDesktop from './auth-desktop/auth-desktop';
import AuthDesktopError from './auth-desktop-error/auth-desktop-error';

const AuthDesktopPage = ({ email }) => {
  const [isInitialTokenRequestProcessing, setIsInitialTokenRequestProcessing] = useState<boolean>(false);
  const [isErrorFailed, setIsErrorFailed] = useState<boolean>(false);
  const [isTokenRequestProcessing, setIsTokenRequestProcessing] = useState<boolean>(false);

  useEffect(() => {
    const openDesktopClient = async () => {
      setIsInitialTokenRequestProcessing(true);

      try {
        const url = await DesktopAppHelper.getDesktopAuthUrl(email);

        setIsInitialTokenRequestProcessing(false);

        window.location.assign(url);
      } catch (error) {
        setIsInitialTokenRequestProcessing(false);
        setIsErrorFailed(true);
      }
    };

    openDesktopClient();
  }, [email]);

  const onButtonClick = async () => {
    setIsTokenRequestProcessing(true);

    try {
      const url = await DesktopAppHelper.getDesktopAuthUrl(email);

      window.location.assign(url);
    } catch (error) {
      setIsErrorFailed(true);
    } finally {
      setIsTokenRequestProcessing(false);
    }
  };

  if (isInitialTokenRequestProcessing) {
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <AnimatedLogo animate={isInitialTokenRequestProcessing} />
      </div>
    );
  }

  if (isErrorFailed) {
    return (
      <AuthDesktopError />
    );
  }

  return (
    <AuthDesktop
      isLoading={isTokenRequestProcessing}
      onButtonClick={onButtonClick}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  email: getUserEmail(state),
});

export { AuthDesktopPage as PureAuthDesktopPage };

export default connect(mapStateToProps)(AuthDesktopPage);
