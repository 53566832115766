import { decamelize } from 'humps';

import analytics, { events } from 'utils/analytics';
import healthMetrics from 'utils/health-metrics';
import PaymentDetailsService from 'services/payment-details-api/payment-details-api';
import type { PaddleCheckoutEvent } from './paddle-checkout-events';

export default function trackEvent(paddleEvent: PaddleCheckoutEvent): boolean {
  switch (paddleEvent.event) {
    case 'Checkout.Loaded': {
      analytics.trackEvent(events.PAYMENT_DETAILS_CHECKOUT_LOADED, { eventLabel: 'Paddle' });
      healthMetrics.trackIncrement({
        name: 'paddle_form.event.loaded',
      });

      return true;
    }

    case 'Checkout.Location.Submit': {
      analytics.trackEvent(events.PAYMENT_DETAILS_COUNTRY_SELECT, {
        eventLabel: paddleEvent.eventData.country,
      });

      return true;
    }

    case 'Checkout.PaymentMethodSelected': {
      analytics.trackEvent(events.PAYMENT_DETAILS_PAYMENT_METHOD_SELECT, {
        eventLabel: paddleEvent.eventData.payment_method,
      });

      return true;
    }

    case 'Checkout.Error': {
      // eslint-disable-next-line camelcase
      const { error, error_type = 'unknown' } = paddleEvent.eventData;

      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR, {
        eventLabel: error,
      });

      healthMetrics.trackIncrement({
        name: `paddle_form.event.error.${decamelize(error_type)}`,
      });

      const paymentDetailsService = new PaymentDetailsService();

      // eslint-disable-next-line camelcase
      if (error_type === 'unknown') {
        paymentDetailsService.sendPaymentDeclineType({ declineReason: 'hard' });
        analytics.trackEvent(events.PAYMENT_DETAILS_DECLINE_TYPE_SENT, {
          eventLabel: 'hard',
        });
      } else {
        paymentDetailsService.sendPaymentDeclineType({ declineReason: 'soft' });
        analytics.trackEvent(events.PAYMENT_DETAILS_DECLINE_TYPE_SENT, {
          eventLabel: 'soft',
        });
      }

      return true;
    }

    case 'Checkout.Complete': {
      healthMetrics.trackIncrement({
        name: 'paddle_form.event.complete',
      });

      return true;
    }

    default: {
      return false;
    }
  }
}
