import React from 'react';
import type { ComponentType } from 'react';

import './gift-cards-list.scss';

type ItemComponent<Item, OnClick> = ComponentType<Item & { onClick: OnClick }>;

type ItemClickHandler = () => unknown | undefined;

type ItemId = string | number;

type Props<Item> = {
  itemComponent: ItemComponent<Item & {id: ItemId}, ItemClickHandler>;
  items: Array<Item & {id: ItemId}>;
  qaLabel: string;
  onItemClick: ItemClickHandler;
};

const GiftCardsList = <Item, >(props: Props<Item>) => {
  const {
    items, itemComponent: ItemComponent, onItemClick, qaLabel,
  } = props;

  return (
    <ul className="gift-cards-list" data-qa={qaLabel}>
      {items.map((item) => (
        <li className="gift-cards-list__item" key={item.id}>
          <ItemComponent {...item} onClick={onItemClick} />
        </li>
      ))}
    </ul>
  );
};

GiftCardsList.defaultProps = {
  onItemClick: undefined,
  qaLabel: undefined,
};

export default GiftCardsList;
