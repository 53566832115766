import { createSlice } from '@reduxjs/toolkit';
import { isAvailableSilently as isSetappAvailable } from '@setapp/desktop-app-helper';

import type { Dispatch, GetState } from 'state/state-types';

import initialState from './desktop-app-initial-state';
import type { DesktopAppState } from './desktop-app-initial-state';

/* istanbul ignore next */
const desktopAppSlice = createSlice({
  name: 'desktopApp',
  initialState,
  reducers: {
    detect(state) {
      state.isDetectionInProgress = true;
    },
    detectSuccess(state) {
      state.hasAlreadyDetected = true;
      state.isAvailable = true;
      state.isDetectionInProgress = false;
    },
    detectError(state) {
      state.hasAlreadyDetected = true;
      state.isAvailable = false;
      state.isDetectionInProgress = false;
    },
  },
});

export default desktopAppSlice.reducer;

// Selectors
export const hasAlreadyDetected = (state: DesktopAppState) => state.hasAlreadyDetected;
export const isAvailable = (state: DesktopAppState) => state.isAvailable;
export const isDetectionInProgress = (state: DesktopAppState) => state.isDetectionInProgress;

// Actions
const {
  detect,
  detectSuccess,
  detectError,
} = desktopAppSlice.actions;

export const detectDesktopApp = () => (dispatch: Dispatch, getState: GetState) => {
  // Prevent from simultaneous detection
  if (isDetectionInProgress(getState().desktopApp)) {
    return;
  }

  dispatch(detect());

  isSetappAvailable({
    onSuccess: () => dispatch(detectSuccess()),
    onError: () => dispatch(detectError()),
  });
};
