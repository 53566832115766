import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import messages from 'components/payment-details/business-account-info-messages';

import './business-account-details.scss';

export type Props = {
  data: {
    vatId: string;
    company: string;
    streetName: string;
    addressDetail: string;
    buildingNumber: string;
    city: string;
    postalCode: string;
  };
}

const BusinessAccountDetails = (props: Props & InjectedIntlProps) => {
  const {
    data: {
      vatId, company, streetName, addressDetail, buildingNumber, city, postalCode,
    }, intl,
  } = props;

  const items = [
    { title: intl.formatMessage(messages.vatNumber), value: vatId },
    { title: intl.formatMessage(messages.company), value: company },
    { title: intl.formatMessage(messages.street), value: streetName },
    { title: intl.formatMessage(messages.address), value: addressDetail },
    { title: intl.formatMessage(messages.building), value: buildingNumber },
    { title: intl.formatMessage(messages.city), value: city },
    { title: intl.formatMessage(messages.postalCode), value: postalCode },
  ].filter((item) => item.value);

  if (!items.length) {
    return null;
  }

  return (
    <div className="business-account-details">
      {items.map((item) => (
        <div className="business-account-details__item" key={item.title}>
          <div className="business-account-details__title">
            {item.title}
            :
          </div>
          <div className="business-account-details__value">{item.value}</div>
        </div>
      ))}
    </div>
  );
};

export default injectIntl(BusinessAccountDetails);
