import queryString from 'query-string';

import { apiURL } from 'config/api';
import { getTokenManager } from 'utils/service-locators';
import request from './request';

type OAuthClientInfo = {
  name: string;
  iconUrl: string;
}

type OAuthClientOptions = {
  mayBeSkipped: boolean;
}

export type OAuthScope = 'application.access' | 'userinfo.name' | 'userinfo.email' | 'ai.openai';

export const VALID_OAUTH_SCOPE: Array<OAuthScope> = ['application.access', 'userinfo.name', 'userinfo.email', 'ai.openai'];

const tokenManager = getTokenManager();

export async function getOAuthClientInfo(clientId: string): Promise<OAuthClientInfo> {
  return request.get(`${apiURL.oauthClient}/${clientId}`);
}

export async function getOAuthClientOptions(clientId: string, scope: Array<OAuthScope>): Promise<OAuthClientOptions> {
  const searchParams = queryString.stringify({ 'client_id': clientId, scope }, { arrayFormat: 'bracket' });

  return request.get(`${apiURL.oauthOptions}/?${searchParams}`);
}

export function redirectToOAuthServer(oauthCallbackUrl: string, authorized: boolean) {
  const authToken: string = tokenManager.getAccessToken();

  const url = new URL(oauthCallbackUrl);
  url.searchParams.set('authentication_token', authToken);
  url.searchParams.set('authorized', authorized.toString());

  window.location.assign(url.toString());
}
