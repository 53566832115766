import { createContext, useContext } from 'react';

export type SidebarNavContextType = {
  expanded: boolean;
  onToggle?: () => void;
  onSelect?: () => void;
};

const SidebarNavContext = createContext<SidebarNavContextType>({ expanded: false });

export const useSidebarNavContext = () => useContext(SidebarNavContext);

export default SidebarNavContext;
