import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Notification } from '@setapp/ui-kit';

const PaymentPendingNotification = () => (
  <Notification type="info">
    <FormattedMessage
      id="subscriptionInfo.statusNotifications.pending"
      defaultMessage="Your payment is being processed, we’ll send you an email once it's ready."
    />
  </Notification>
);

export default PaymentPendingNotification;
