import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type { Discount } from 'state/subscription/subscription-initial-state';

import AccountInfoRow from 'components/shared/account-info-row/account-info-row';
import SubscriptionPrice from './subscription-price/subscription-price';

type Props = {
  tax: number;
  price: number;
  currency: string;
  paidMonth: number;
  discount?: Discount;
};

const PriceRow: FC<Props> = ({
  price,
  tax,
  currency,
  paidMonth,
  discount,
}) => (
  <AccountInfoRow
    title={<FormattedMessage id="subscriptionInfo.priceTitle" defaultMessage="Price" />}
    value={(
      <SubscriptionPrice
        price={price}
        currency={currency}
        paidMonth={paidMonth}
        tax={tax}
        discount={discount}
      />
      )}
    qaLabel="planPrice"
  />
);

export default PriceRow;
