import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { DeviceIcon, Button } from '@setapp/ui-kit';
import * as subscriptionService from 'services/subscriptions/subscription';

import type { Device } from 'state/devices/devices-initial-state';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import './device-item.scss';

type Props = {
  subscription: Subscription;
  device: Device;
  // TODO: get rid of this prop as we now always have a subscription object and can check it's group
  isMainPlanSeat: boolean;
  isTrial: boolean;
  onReactivateClick: (subscription: Subscription, device?: Device) => void;
  onDisconnectClick: (device: Device) => void;
  isPrimarySubscriptionActive?: boolean;
};

class DeviceItem extends PureComponent<Props> {
  render() {
    const {
      device,
      subscription,
      isMainPlanSeat,
      isTrial,
      isPrimarySubscriptionActive,
    } = this.props;

    const {
      model,
      name,
      lastUsedDate,
    } = device;
    const isCancelled = subscriptionService.isStatusCancelled(subscription);
    // handle case with monthly -> edu -> monthly plan switch
    const isReactivateAvailable = isCancelled && !isMainPlanSeat && isPrimarySubscriptionActive;

    return (
      <div className="device-item">
        <div className="mr-5">
          <DeviceIcon device={model} size={60} />
        </div>
        <div className="device-item__content">
          <div className="text_lg text_weight-medium">{name}</div>

          <div className="text_color-secondary" data-qa="activated-device">
            <FormattedMessage
              id="devicesPage.loginDateTitle"
              defaultMessage="Last used: {lastUsedDate}"
              values={{
                lastUsedDate: (
                  <FormattedDate
                    value={lastUsedDate * 1000}
                    year="numeric"
                    month="short"
                    day="numeric"
                  />
                ),
              }}
            />
          </div>

          {isCancelled && (
            <div className="text_color-danger mt-1" data-qa="canceled-device">
              <FormattedMessage
                id="devicesPage.deviceItem.expirationDate.description"
                defaultMessage="Available until {expirationDate}"
                values={{
                  expirationDate: (
                    <FormattedDate
                      value={(subscription.expirationDate ?? 0) * 1000}
                      year="numeric"
                      month="short"
                      day="numeric"
                    />
                  ),
                }}
              />
            </div>
          )}

          {isTrial && (
            <div className="text_color-success">
              <FormattedMessage
                id="devicesPage.deviceItem.trialDescription"
                defaultMessage="Free on a trial period"
              />
            </div>
          )}

          {this.renderDisconnectButton()}

          {isReactivateAvailable && this.renderReactivateButton()}
        </div>
      </div>
    );
  }

  renderReactivateButton() {
    return (
      <div className="device-item__button">
        <Button
          variant="link"
          onClick={this.handleReactivateClick}
          data-qa="reactivate-device-button"
          className="mt-3"
        >
          <FormattedMessage id="devicesPage.deviceItem.reactivateBtn" defaultMessage="Reactivate" />
        </Button>
      </div>
    );
  }

  renderDisconnectButton() {
    return (
      <div className="device-item__button">
        <Button
          variant="link"
          onClick={this.handleDisconnectClick}
          data-qa="disconnect-device-button"
          className="mt-3"
        >
          <FormattedMessage id="devicesPage.deviceItem.disconnectBtn" defaultMessage="Disconnect" />
        </Button>
      </div>
    );
  }

  handleReactivateClick = () => {
    const { onReactivateClick, device, subscription } = this.props;

    onReactivateClick(subscription, device);
  };

  handleDisconnectClick = () => {
    const { onDisconnectClick, device } = this.props;

    onDisconnectClick(device);
  };
}

export default DeviceItem;
