import React, { useState } from 'react';
import { Button } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getFeaturedPricePlans } from 'state/root-reducer';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import Logger from 'utils/logger';
import PaymentDetailsPlanCard from './payment-details-plan-card';

import './signup-payment-details-plans.scss';

type Props = {
  onNextClick: (pricePlan: PricePlan) => void;
}

const SignupPaymentDetailsPlans = ({ onNextClick }: Props) => {
  const featuredPricePlans = useSelector(getFeaturedPricePlans);
  const iosPlan = featuredPricePlans.find((plan) => plan.tierType === pricePlanTypes.IOS_MONTHLY);
  const macPlusIosPlan = featuredPricePlans.find((plan) => plan.tierType === pricePlanTypes.MAC_PLUS_IOS_MONTHLY);

  const [selectedPricePlan, setSelectedPricePlan] = useState<PricePlan>(macPlusIosPlan!);

  // it is highly unlikely that we will not have iosPlan or macPlusIosPlan
  // but just in case
  if (!iosPlan || !macPlusIosPlan) {
    Logger.logError('SignupPaymentDetailsPlans: iosPlan or macPlusIosPlan is not defined');

    return null;
  }

  const handleButtonClick = () => onNextClick(selectedPricePlan);

  return (
    <section className="payments-details-plans">
      <h3 className="mb-2">
        <FormattedMessage
          id="signup.payment-details.plans.title"
          defaultMessage="Choose your plan"
        />
      </h3>
      <p className="mb-5">
        <FormattedMessage
          id="signup.payment-details.plans.description"
          defaultMessage="No strings attached. Pay only if you like to continue."
        />
      </p>
      <PaymentDetailsPlanCard
        plan={macPlusIosPlan}
        onClick={setSelectedPricePlan}
        checked={selectedPricePlan.tierType === macPlusIosPlan.tierType}
      />
      <PaymentDetailsPlanCard
        plan={iosPlan}
        onClick={setSelectedPricePlan}
        checked={selectedPricePlan.tierType === iosPlan.tierType}
      />
      <div className="payments-details-plans__button-container">
        <Button
          block
          size="md"
          className="payments-details-plans__button"
          onClick={handleButtonClick}
        >
          <FormattedMessage
            id="signup.payment-details.plans.button"
            defaultMessage="Next"
          />
        </Button>
      </div>
    </section>
  );
};

export default SignupPaymentDetailsPlans;
