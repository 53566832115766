// @flow

/* eslint-disable react/no-array-index-key */
import React from 'react';
import type { Node } from 'react';

import './payment-method-presentation.scss';

type Props = {
  details: Array<Node>,
};

const PaymentMethodPresentation = ({ details }: Props) => (
  <div className="payment-method-presentation">
    <div className="payment-method-presentation__details" data-qa="paymentDetailsPresentation">
      {details.map((item, id) => <div className="payment-method-presentation__row" key={id}>{item}</div>)}
    </div>
  </div>
);

export default PaymentMethodPresentation;
