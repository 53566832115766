import React, { useEffect, useState } from 'react';
import { Row, Col, AnimatedLogo, Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Tabs from 'components/shared/tabs/custom-tabs';
import DefaultError from 'components/shared/default-error/default-error';

import { hideModal } from 'state/modal/modal-reducer';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';

import createQrCode from 'utils/qr-code';
import analytics, { events } from 'utils/analytics';

import ActivateIosAppTitle from '../activate-ios-app-title/activate-ios-app-title';

import './activate-ios-app-content.scss';

export type Props = Readonly<{
  iosApp: IosApp;
  activationUrl: string;
}>;

export enum Step {
  First = 'first-step',
  Second = 'second-step',
}

const ActivateIosAppContent = ({ iosApp, activationUrl }: Props) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<Step>(Step.First);

  const [appStoreQrCode, setAppStoreQrCode] = useState('');
  const [activationQrCode, setActivationQrCode] = useState('');
  const [secondTabEventSent, setSecondTabEventSent] = useState(false);

  useEffect(() => {
    analytics.trackEvent(events.GET_APP_IOS_FORM_FORM_SHOWN, {
      eventLabel: iosApp.name
    });
    let isCancelled = false;

    const fetchQrCodes = async () => {
      try {
        const createdAppStoreQr = await createQrCode(iosApp.appStoreLink);
        if (!isCancelled && createdAppStoreQr) {
          setAppStoreQrCode(createdAppStoreQr);
        }
        const createdActivationQr = await createQrCode(activationUrl);
        if (!isCancelled && createdActivationQr) {
          setActivationQrCode(createdActivationQr);
        }
      } catch {
        if (!isCancelled) {
          dispatch(showDangerNotification(<DefaultError />));
        }
      }
    };

    fetchQrCodes();

    return () => {
      isCancelled = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabSelect = (key) => {
    if (key === Step.Second && !secondTabEventSent) {
      analytics.trackEvent(events.GET_APP_IOS_FORM_STEP_2_SHOWN, {
        eventLabel: iosApp.name
      });
      setSecondTabEventSent(true);
    }
    setActiveTab(key);
  };

  const handleSecondTabSelect = () => {
    handleTabSelect(Step.Second);
  };

  const handleDoneButtonClick = () => {
    analytics.trackEvent(events.GET_APP_IOS_FORM_STEP_DONE_CLICK, {
      eventLabel: iosApp.name
    });
    dispatch(hideModal());
  };

  return (
    <Tabs
      activeKey={activeTab}
      onTabSelect={handleTabSelect}
      tabsId="activate-ios-app"
      isContentAnimation={false}
      tabs={[{
        key: Step.First,
        title: (
          <ActivateIosAppTitle isActive={activeTab === Step.First}>
            <FormattedMessage
              id="activateIosAppModal.firstStep"
              defaultMessage="1. Install iOS app"
            />
          </ActivateIosAppTitle>
        ),
        content: (
          <>
            <Row className="activate-ios-app-content__container">
              <Col xs={5}>
                {appStoreQrCode ? (
                  <img src={appStoreQrCode} alt="QR Code" />
                ) : (
                  <div className="activate-ios-app-content__logo">
                    <AnimatedLogo animate size="md" />
                  </div>
                )}
              </Col>
              <Col xs={7}>
                <FormattedMessage
                  id="activateIosAppModal.appStoreQR"
                  defaultMessage="Scan QR Code to install {appName} on your iOS device"
                  values={{ appName: iosApp.name }}
                />
              </Col>
            </Row>
            <Button block size="lg" onClick={handleSecondTabSelect}>
              <FormattedMessage
                id="activateIosAppModal.nextStepBtn"
                defaultMessage="Next step"
              />
            </Button>
          </>
        ),
      }, {
        key: Step.Second,
        title: (
          <ActivateIosAppTitle isActive={activeTab === Step.Second}>
            <FormattedMessage
              id="activateIosAppModal.secondStep"
              defaultMessage="2. Unlock full version"
            />
          </ActivateIosAppTitle>
        ),
        content: (
          <>
            <Row className="activate-ios-app-content__container">
              <Col xs={5}>
                {activationQrCode ? (
                  <img src={activationQrCode} alt="QR Code" />
                ) : (
                  <div className="activate-ios-app-content__logo">
                    <AnimatedLogo animate size="md" />
                  </div>
                )}
              </Col>
              <Col xs={7}>
                <FormattedMessage
                  id="activateIosAppModal.activationQR"
                  defaultMessage="Scan this QR Code to activate the full version of {appName}. We’ll send a notification when the activation succeeds."
                  values={{ appName: iosApp.name }}
                />
              </Col>
            </Row>
            <Button block size="lg" onClick={handleDoneButtonClick}>
              <FormattedMessage
                id="activateIosAppModal.doneBtn"
                defaultMessage="Done"
              />
            </Button>
          </>
        ),
      }]}
    />
  );
};

export default ActivateIosAppContent;
