import React from 'react';
import { FormattedMessage } from 'react-intl';

import CancelSubscriptionStepLayout from '../cancel-subscription-step-layout/cancel-subscription-step-layout';

import tasksGuyIllustration from './images/tasks-guy.png';
import tasksGuyIllustration2x from './images/tasks-guy@2x.png';
import tasksGuyIllustration3x from './images/tasks-guy@3x.png';


export type Props = {
  onLookMoreAppsButtonClick: () => void;
  onCancelSubscriptionButtonClick: () => void;
};

const BuyAppsSeparatelyConfirmationStep = ({
  onLookMoreAppsButtonClick,
  onCancelSubscriptionButtonClick,
}: Props) => (
  <CancelSubscriptionStepLayout
    illustration={
      <img
        src={tasksGuyIllustration}
        srcSet={`${tasksGuyIllustration2x} 2x, ${tasksGuyIllustration3x} 3x`}
        alt="Tasks guy illustration"
        width="220"
        height="158"
      />
    }
    title={
      <FormattedMessage
        id="cancelSubscription.buyAppsSeparatelyConfirmation.title"
        defaultMessage="Thanks for your input!"
      />
    }
    onPrimaryButtonClick={onCancelSubscriptionButtonClick}
    primaryButtonText={
      <FormattedMessage
        id="cancelSubscription.buyAppsSeparatelyConfirmation.readHowToButton"
        defaultMessage="Nah, let’s cancel"
      />
    }
    onSecondaryButtonClick={onLookMoreAppsButtonClick}
    secondaryButtonText={
      <FormattedMessage
        id="cancelSubscription.buyAppsSeparatelyConfirmation.cancelSubscriptionButton"
        defaultMessage="Okay, I’ll look at more apps"
      />
    }
  >
    <p className="mb-6">
      <FormattedMessage
        id="cancelSubscription.buyAppsSeparatelyConfirmation.description.firstLine"
        defaultMessage="We totally get the allure of paying for apps only when you use each one of them!"
      />
    </p>

    <p>
      <FormattedMessage
        id="cancelSubscription.buyAppsSeparatelyConfirmation.description.secondLine"
        defaultMessage="Right now, we are focused on delivering value within one bulk subscription, so saving on Setapp really is about using multiple tools."

      />
    </p>
  </CancelSubscriptionStepLayout>
);

export default BuyAppsSeparatelyConfirmationStep;
