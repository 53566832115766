import { apiURL } from 'config/api';

import logger from 'utils/logger';
import request from 'utils/request';

import type { Invoice } from 'state/invoices/invoices-initial-state';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { ChangePlanStrategy } from 'state/price-plans/price-plans-types';

export type NextPricePlanCalculationData = {
  strategy: ChangePlanStrategy;
  prorationAmount: number;
  prorationCurrency: string;
  nextPaymentDate: number;
  nextPrice: PricePlan;
};

export function calculateNextPricePlan(
  subscriptionId: number,
  nextPriceId: number,
): Promise<NextPricePlanCalculationData> {
  return request.post(apiURL.calculateNextPricePlan(subscriptionId), { body: { nextPriceId } })
    .catch((err) => {
      logger.logError('Could not calculate next price plan', err.getSimplifiedErrors());

      return Promise.reject(err);
    });
}

export function getInvoice(invoiceId: string): Promise<Invoice> {
  return request.get(apiURL.invoice.replace('%{invoiceId}', invoiceId))
    .catch((err) => {
      logger.logError('Could not get invoice', err.getSimplifiedErrors());

      return Promise.reject(err);
    });
}
