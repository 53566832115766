/* istanbul ignore file */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import analytics, { events } from 'utils/analytics';
import desktopAppHelper from 'utils/desktop-app-helper';
import logger from 'utils/logger';

import OuterLayout from 'components/layout/outer-layout/outer-layout';

import './successful-trial-activation-page.scss';

import macAndIphoneImage from 'components/shared/shared-images/mac-tablet-iphone@1x.png';
import macAndIphoneImage2x from 'components/shared/shared-images/mac-tablet-iphone@2x.png';
import macAndIphoneImage3x from 'components/shared/shared-images/mac-tablet-iphone@3x.png';

const SuccessfulTrialActivationPage = () => {
  const handleOpenSetapp = () => {
    analytics.trackEvent(events.DESKTOP_CCR_TEST_CLICK, { eventLabel: 'Open Setapp' });

    desktopAppHelper
      .openDesktopApp()
      .catch((error) => logger.logWarn('Couldn\'t launch Setapp desktop app', { error }));
  };

  return (
    <OuterLayout>
      <div className="successful-trial-activation-page">
        <div>
          <img
            className="successful-trial-activation-page__image"
            src={macAndIphoneImage}
            srcSet={`${macAndIphoneImage2x} 2x, ${macAndIphoneImage3x} 3x`}
            alt="Setapp application in the different Apple devices"
            width="480"
            height="259"
          />
          <h3 className="mb-2">
            <FormattedMessage
              id="successfulTrialActivationPage.title"
              defaultMessage="Your trial is ready"
            />
          </h3>
          <p className="mb-8">
            <FormattedMessage
              id="successfulTrialActivationPage.description"
              defaultMessage="All done! Now it’s time to discover and use apps on Setapp."
            />
          </p>
          <Button
            block
            size="lg"
            onClick={handleOpenSetapp}
          >
            <FormattedMessage
              id="successfulTrialActivationPage.cta"
              defaultMessage="Open Setapp"
            />
          </Button>
        </div>
      </div>
    </OuterLayout>
  );
};

export default SuccessfulTrialActivationPage;
