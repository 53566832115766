import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonBack as ButtonBackUi } from '@setapp/ui-kit';

type Props = {
  children?: ReactElement;
  disabled?: boolean;
  visible?: boolean;
  [x: string]: any;
};

const ButtonBack = (props: Props) => {
  const {
    children,
    disabled = false,
    visible = true,
    ...buttonProps
  } = props;
  const text = children || (
    <FormattedMessage
      id="backButton.ctaText"
      defaultMessage="Back"
    />
  );

  return (
    <ButtonBackUi
      visible={visible}
      disabled={disabled}
      {...buttonProps}
    >
      {text}
    </ButtonBackUi>
  );
};

export default ButtonBack;
