import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonClose } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';

import { getFeatureFlags } from 'state/root-reducer';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/local-storage';
import externalUrls from 'config/external-urls';
import warningIcon from './images/warning-icon.svg';

import './emergency-banner.scss';

const EMERGENCY_BANNER_HIDDEN = 'emergencyBannerHidden';

const EmergencyBanner = () => {
  const [isHidden, setIsHidden] = useState(false);
  const hiddenFlag = getLocalStorageItem(EMERGENCY_BANNER_HIDDEN);
  const featureFlags = useSelector(getFeatureFlags);

  const isEmergencyMessageOn = Boolean(featureFlags.emergencyMessage.value);

  const handleCloseClick = useCallback(() => {
    setIsHidden(true);
    setLocalStorageItem(EMERGENCY_BANNER_HIDDEN, true);
  }, []);


  if (!isEmergencyMessageOn || isHidden || hiddenFlag) {
    return null;
  }

  return (
    <div className="emergency-banner">
      <p className="emergency-banner__text mb-0">
        <img className="emergency-banner__icon" src={warningIcon} alt="Warning icon" />
        <FormattedMessage
          id="emergencyBanner.text"
          defaultMessage="Russia has attacked Ukraine. {emergencyNewsLink} what MacPaw and Setapp are doing to make sure our team members and infrastructure are safe."
          values={{
            emergencyNewsLink: (
              <a href={externalUrls.macpawEmergencyNews} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="emergencyBanner.newsLink" defaultMessage="Here’s" />
              </a>
            ),
          }}
        />
      </p>
      <div className="emergency-banner__close ml-8">
        <ButtonClose size="sm" onClick={handleCloseClick} />
      </div>
    </div>
  );
};

export default EmergencyBanner;
