import React, { Component } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';
import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';

import { removePaymentMethod } from 'state/payment-method/payment-method-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';

import analytics, { events } from 'utils/analytics';


const mapActionsToProps = {
  removePaymentMethod,
  showDangerNotification,
};

const connector = connect(undefined, mapActionsToProps);

type Props = {
  onHide: () => void;
  onExited: () => void;
  show: boolean;
} & ConnectedProps<typeof connector>

type State = {
  isRequestProcessing: boolean;
};

class RemovePaymentDetailsModal extends Component<Props, State> {
  state: State = {
    isRequestProcessing: false,
  };

  render() {
    const { show, onHide, onExited } = this.props;
    const { isRequestProcessing } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        title={<FormattedMessage id="paymentDetails.removeModal.title" defaultMessage="Payment details removal" />}
      >
        <div>
          <p className="payment-details-modal-content text-center">
            <FormattedMessage
              id="paymentDetails.removeModal.content"
              defaultMessage="Are you sure you want to remove your payment details?"
            />
          </p>

          <AccountControlButtons>
            <Button
              variant="secondary-outline"
              block
              onClick={onHide}
              disabled={isRequestProcessing}
            >
              <FormattedMessage id="paymentDetails.removeModal.btnCancel" defaultMessage="Cancel" />
            </Button>
            <Button
              variant="danger-outline"
              block
              onClick={this.onSubmitClick}
              disabled={isRequestProcessing}
              className="mt-3"
            >
              <FormattedMessage id="paymentDetails.removeModal.btnRemove" defaultMessage="Yes, remove" />
            </Button>
          </AccountControlButtons>
        </div>
      </Modal>
    );
  }

  onSubmitClick = () => {
    const { showDangerNotification, removePaymentMethod } = this.props;

    this.setState({ isRequestProcessing: true });

    return removePaymentMethod()
      .then(() => {
        analytics.trackEvent(events.REMOVE_PAYMENT_DETAILS_CONFIRM);
      })
      .catch(() => {
        this.setState({ isRequestProcessing: false });
        showDangerNotification(<DefaultError />);
      })
      .finally(() => {
        const { onHide } = this.props;

        onHide();
      });
  };
}

export default connector(RemovePaymentDetailsModal);
