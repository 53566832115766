/* eslint-disable no-underscore-dangle */
// @flow

import Cookies from 'js-cookie';

import {
  GTM_CONTAINER_ID, GTM_TEST_ENV_AUTH, GTM_TEST_ENV_PREVIEW, GTM_DEBUG,
} from '../../config/analytics';
import { COOKIES_ROOT_DOMAIN } from '../../config/app';
import gtmEvents from './gtm-events';


export default class Gtm {
  static GA_COOKIE_NAME = '_ga';

  constructor() {
    this._initGTM();
  }

  static initialize() {
    return new Gtm();
  }

  static getCID(): ?string {
    const cidRegex = /^GA\d\.\d\.(.*)/;
    const gaCookie = Cookies.get(Gtm.GA_COOKIE_NAME);

    if (!gaCookie) {
      return undefined;
    }

    const matches = gaCookie.match(cidRegex);

    if (!matches) {
      return undefined;
    }

    return matches[1];
  }

  static saveCID(cid: string) {
    const cidCookie = `GA1.2.${cid}`;

    Cookies.set(Gtm.GA_COOKIE_NAME, cidCookie, { domain: COOKIES_ROOT_DOMAIN, expires: 365 * 2 });
  }

  trackPageView(userInfo: {}) {
    const event = {
      ...userInfo,
      event: 'virtualPageView',
    };

    this._log('Sending page view', event);
    window.dataLayer.push(event);
  }

  trackEvent(event: string, payload: ?{}) {
    let gtmEvent = gtmEvents[event];

    if (!gtmEvent) {
      this._log(`GTM config for event '${event}' not found`);

      return;
    }

    gtmEvent = {
      event: 'setapp',
      eventNonInteraction: false,
      eventLabel: '', // Set default value to avoid preserving in data layer from the previous event, see AFX-5123
      ...payload,
      ...gtmEvent,
    };

    this._log('Sending event', gtmEvent);
    window.dataLayer.push(gtmEvent);
  }

  trackExperimentMatch(experiment: string, variant: string) {
    const testCaseValue = experiment + variant,
      gtmEvent = {
        event: 'Setapp User Cabinet Tests',
        'Setapp User Cabinet Tests': testCaseValue,
      };

    this._log('Sending event', gtmEvent);
    window.dataLayer.push(gtmEvent);
    Cookies.set(`split${experiment}`, testCaseValue, { domain: COOKIES_ROOT_DOMAIN });
  }

  /**
   * @private
   */
  _getGtmSrcUrl = () => {
    const url = process.env.GTM_URL;

    if (!url) {
      throw new Error('Cannot initialize GTM in this environment (GTM_URL is undefined)');
    }

    return url;
  }

  /**
   * @private
   */
  _initGTM() {
    const gtmEnvOptions = !process.env.USE_PRODUCTION_GTM
      ? `&gtm_auth=${GTM_TEST_ENV_AUTH}&gtm_preview=${GTM_TEST_ENV_PREVIEW}&gtm_cookies_win=x`
      : '';
    const gtmSrcUrl = this._getGtmSrcUrl();

    /* eslint-disable */
    /* Google Tag Manager */

    /* WARNING this is not standard snippet. It modified for GTM Environments support */
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(), event: 'gtm.js',
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = `${gtmSrcUrl}/gtm.js?id=${i}${dl}${gtmEnvOptions}`;

      // $FlowFixMe
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTM_CONTAINER_ID);
    /* End Google Tag Manager */
    /* eslint-disable */
  }

  /**
   * @private
   */
  _log(...args: Array<mixed>) {
    if (GTM_DEBUG) {
      console.log.apply(console, ['GTM DEBUG:', ...args]);
    }
  }
}
