import request from 'utils/request';
import { apiURL } from 'config/api';
import type { InvoicingInformation, CardDetails } from '../payment-details-api';

export type PaymentDetailsPayload = {
  checkoutId: string;
};

export type UpdatePaymentDetailsPayload = {
  subscriptionId: string;
};

type CardPaymentDetails = InvoicingInformation & {
  provider: 'paddle';
  type: 'card';
  details: CardDetails;
};

// The old users still has no saved card info
type LegacyCardPaymentDetails = InvoicingInformation & {
  provider: 'paddle';
  type: null;
  details: null;
};

type PayPalPaymentDetails = InvoicingInformation & {
  provider: 'paddle';
  type: 'paypal';
  // Paddle still doesn't provide PayPal details
  details: null;
};

export type PaddlePaymentDetails = CardPaymentDetails | LegacyCardPaymentDetails | PayPalPaymentDetails;

class PaddlePaymentDetailsApi {
  createPaymentDetails(paymentDetails: PaymentDetailsPayload): Promise<PaddlePaymentDetails> {
    return request.post(apiURL.paddlePaymentDetails, {
      body: paymentDetails,
    });
  }

  updatePaymentDetails(paymentDetails: UpdatePaymentDetailsPayload): Promise<PaddlePaymentDetails> {
    return request.patch(apiURL.paddlePaymentDetails, {
      body: paymentDetails,
    });
  }
}

export default PaddlePaymentDetailsApi;
