import type { Subscription } from 'state/subscription/subscription-initial-state';
import * as pricePlanGroup from 'state/price-plans/price-plan-groups';
import * as subscriptionService from './subscription';

export const getPrimarySubscription = (subscriptions: Array<Subscription>) => subscriptions
  .find((subscription) => [pricePlanGroup.REGULAR, pricePlanGroup.FAMILY].includes(subscription.pricePlan.group));

export const getTotalSeatsCount = (subscriptions: Array<Subscription>): number => (
  subscriptions.reduce((seatsCount, subscription) => seatsCount + subscription.pricePlan.seatsCount, 0)
);

// TODO: rename to "getAdditionalSeatsSubscriptions"
export const getAdditionalSeats = (subscriptions: Array<Subscription>): Array<Subscription> => (
  subscriptions.filter(subscriptionService.isAdditionalSeat)
);

export const getExtraSeatsCount = (subscriptions: Array<Subscription>): number => (
  getTotalSeatsCount(getAdditionalSeats(subscriptions))
);

export const getTotalPrice = (subscriptions: Array<Subscription>): number => (
  subscriptions.reduce((price, subscription) => price + subscription.pricePlan.price, 0)
);

export const isAdditionalSeatPaymentPending = (subscriptions: Array<Subscription>) => (
  subscriptions.filter((subscription) => subscription.paymentPending).length > 0
);

export const getAdditionalSeatsPaymentPendingCount = (subscriptions: Array<Subscription>): number => (
  subscriptions.filter((subscription) => subscription.paymentPending).length
);

export const getPaymentFailedSeatsSubscriptions = (subscriptions: Array<Subscription>): Array<Subscription> => (
  subscriptions.filter((subscription) => subscription.lastPaymentFailed)
);
