import React from 'react';
import { AnimatedLogo } from '@setapp/ui-kit';

import './modal-loading-state.scss';

const ModalLoadingState = () => (
  <div className="modal-loading-state" data-testid="modal-loading-state">
    <AnimatedLogo animate />
  </div>
);

export default ModalLoadingState;
