import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './panel-box-content.scss';

type Props = {
  children: ReactNode;
  className?: string;
};

const PanelBoxContent = ({ children, className }: Props) => (
  <div className={classNames('panel-box-content', className)}>
    {children}
  </div>
);

export default PanelBoxContent;
