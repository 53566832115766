import React from 'react';
import type { ReactNode, HTMLProps } from 'react';
import { FormattedMessage } from 'react-intl';

import { getSupportUrl } from 'utils/support';

type Props = {
  url?: string;
  text?: ReactNode;
} & HTMLProps<HTMLAnchorElement>;

const SupportLink = ({
  url = getSupportUrl(),
  text = (
    <FormattedMessage
      id="subscriptionInfo.helpLink"
      defaultMessage="Need help?"
    />
  ),
  ...linkProps
}: Props) => (
  <a {...linkProps} href={url} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);


export default SupportLink;
