import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { getIsSetappMobileAvailable } from 'state/root-reducer';

import InfoBlock from '../info-block/info-block';

type Props = InjectedIntlProps & {
  canChangePricePlan: boolean;
  pricePlan: PricePlan;
  onChangePlan: () => void;
};

const PricePlanInfo = (props: Props) => {
  const {
    intl,
    canChangePricePlan,
    pricePlan,
    onChangePlan,
  } = props;

  const isSetappMobileAvailable = useSelector(getIsSetappMobileAvailable);

  if (!pricePlan.features) {
    return null;
  }

  const { macSeatsCount, iosSeatsCount, maxMembersCount, vendorApiUsageLimitInCredits } = pricePlan.features;
  const pricePlanMetadata = getPricePlanMetadata(pricePlan);

  const changePlanButton = canChangePricePlan && (
    <Button
      variant="link"
      className="text_lg"
      onClick={onChangePlan}
    >
      <FormattedMessage
        id="manageSubscriptionModal.currentPlanStep.changePlanCTA"
        defaultMessage="Change plan"
      />
    </Button>
  );

  return (
    <InfoBlock
      title={pricePlanMetadata.titleFull}
      description={
        intl.formatMessage(
          pricePlanMetadata.availableDevicesMessage,
          {
            macSeatsCount,
            iosSeatsCount,
            maxMembersCount,
            vendorApiUsageLimitInCredits,
            isSetappMobileAvailable
          },
        )
      }
      rightBlock={changePlanButton}
    />
  );
};

export default injectIntl(PricePlanInfo);
