import React from 'react';
import { FormattedMessage } from 'react-intl';

import CancelSubscriptionStepLayout from '../cancel-subscription-step-layout/cancel-subscription-step-layout';

import setappGuyIllustration from './images/setapp-guy.png';
import setappGuyIllustration2x from './images/setapp-guy@2x.png';
import setappGuyIllustration3x from './images/setapp-guy@3x.png';

import './do-not-use-often-confirmation-step.scss';


export type Props = {
  onLookMoreAppsButtonClick: () => void;
  onCancelSubscriptionButtonClick: () => void;
};

const DoNotUseOftenConfirmationStep = ({
  onLookMoreAppsButtonClick,
  onCancelSubscriptionButtonClick,
}: Props) => (
  <CancelSubscriptionStepLayout
    illustration={
      <img
        src={setappGuyIllustration}
        srcSet={`${setappGuyIllustration2x} 2x, ${setappGuyIllustration3x} 3x`}
        alt="Tasks guy illustration"
        width="220"
        height="158"
      />
    }
    title={
      <FormattedMessage
        id="cancelSubscription.doNotUseOftenConfirmation.title"
        defaultMessage="Done!"
      />
    }
    onPrimaryButtonClick={onCancelSubscriptionButtonClick}
    primaryButtonText={
      <FormattedMessage
        id="cancelSubscription.doNotUseOftenConfirmation.readHowToButton"
        defaultMessage="Nah, let’s cancel"
      />
    }
    onSecondaryButtonClick={onLookMoreAppsButtonClick}
    secondaryButtonText={
      <FormattedMessage
        id="cancelSubscription.doNotUseOftenConfirmation.cancelSubscriptionButton"
        defaultMessage="Okay, I’ll look at more apps"
      />
    }
  >
    <p className="mb-6 do-not-use-often-confirmation-step__content">
      <FormattedMessage
        id="cancelSubscription.doNotUseOftenConfirmation.description.firstLine"
        defaultMessage="Thanks for your feedback! We know exploring 230+ apps takes a minute. You might be missing out on exactly the apps you’ve been looking for this whole time."
      />
    </p>

    <p>
      <FormattedMessage
        id="cancelSubscription.doNotUseOftenConfirmation.description.secondLine"
        defaultMessage="Feel like checking if there are any tools you could could use on Setapp but have missed?"
      />
    </p>
  </CancelSubscriptionStepLayout>
);

export default DoNotUseOftenConfirmationStep;
