// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { Component } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import analytics, { events } from 'utils/analytics';

import Modal from 'components/shared/modal/modal';
import FadeTransition from 'components/shared/fade-transition/fade-transition';
import ButtonBack from 'components/shared/button-back/button-back';
import DefaultError from 'components/shared/default-error/default-error';

import { getDevicesInSeat, getSubscriptions } from 'state/root-reducer';
import { fetchAllSubscriptions, cancelAdditionalSeats } from 'state/subscription/subscription-actions';
import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import { fetchDevices } from 'state/devices/devices-reducer';
import type { DeviceSeat } from 'services/devices-to-seats-mapper/devices-to-seats-mapper';
import { getAdditionalSeats } from 'services/subscriptions/subscriptions-list';
import * as subscriptionService from 'services/subscriptions/subscription';
import * as devicesToSeatsMapper from 'services/devices-to-seats-mapper/devices-to-seats-mapper';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import ConfirmationScreen from './cancel-seats-confirmation/cancel-seat-confirmation';
import SelectSeatsScreen from './select-seats-screen/select-seats-screen';


const mapStateToProps = (state) => ({
  devicesSeats: getDevicesInSeat(state),
  areSubscriptionsProcessing: getSubscriptions(state).isLoading,
});

const mapActionsToProps = {
  fetchAllSubscriptions,
  fetchDevices,
  showDangerNotification,
  showSuccessNotification,
  cancelAdditionalSeats,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = {
  show: boolean;
  onHide: () => void;
  onExited: () => void;
} & ConnectedProps<typeof connector>;

type State = {
  currentScreen: number;
  selectedSeats: Array<DeviceSeat>;
  isDataFetching: boolean;
}

const SCREEN_SELECT_SEATS = 0;
const SCREEN_CONFIRMATION = 1;

class CancelAdditionalSeatModal extends Component<Props, State> {
  state: State = {
    currentScreen: SCREEN_SELECT_SEATS,
    selectedSeats: [],
    isDataFetching: true,
  };

  componentDidMount() {
    const { fetchAllSubscriptions, fetchDevices } = this.props;

    return Promise.all([
      fetchAllSubscriptions(),
      fetchDevices(),
    ])
      .then(() => {
        this.setState({ isDataFetching: false });
      })
      .catch(() => {
        const { showDangerNotification, onHide } = this.props;

        showDangerNotification(<DefaultError />);
        onHide();
      });
  }

  render() {
    const {
      show,
      onHide,
      onExited,
      devicesSeats,
      areSubscriptionsProcessing,
    } = this.props;
    const { currentScreen, isDataFetching } = this.state;
    const activeSeats = devicesSeats.filter((seat) => subscriptionService.isActive(seat.subscription));

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        fullScreen
        title={this.renderModalTitle()}
        isLoading={isDataFetching}
      >
        <FadeTransition current={currentScreen}>
          <SelectSeatsScreen
            deviceSeats={activeSeats}
            onConfirm={this.handleSelectedSeatsConfirmation}
          />
          <ConfirmationScreen
            subscriptionsToCancel={this.getSubscriptionsToCancel()}
            isProcessing={areSubscriptionsProcessing}
            onConfirm={this.handleCancellationConfirmation}
          />
        </FadeTransition>
      </Modal>
    );
  }

  renderModalTitle() {
    const { currentScreen, selectedSeats } = this.state;

    return (
      <>
        <ButtonBack onClick={this.handleBackClick} visible={currentScreen !== SCREEN_SELECT_SEATS} />
        <div className="mt-5">
          <FadeTransition current={currentScreen}>
            <FormattedMessage
              id="cancelAdditionalSeats.modalTitleSelectDevices"
              defaultMessage="Select device to remove"
            />
            <FormattedMessage
              id="cancelAdditionalSeats.modalTitleConfirmation"
              defaultMessage="Remove {seatsCount, plural, one {1 extra device} other {{seatsCount} extra devices}}"
              values={{
                seatsCount: selectedSeats.length,
              }}
            />
          </FadeTransition>
        </div>
      </>
    );
  }

  handleSelectedSeatsConfirmation = (selectedSeats: Array<DeviceSeat>) => {
    this.setState({
      currentScreen: SCREEN_CONFIRMATION,
      selectedSeats,
    });

    analytics.trackEvent(events.CANCEL_SEAT_AMOUNT_SELECTED, { eventLabel: selectedSeats.length.toString() });
  }

  handleBackClick = () => {
    this.setState((state) => {
      const { currentScreen } = state;
      const prevScreen = Math.max(currentScreen - 1, 0);

      return { currentScreen: prevScreen };
    });
  }

  handleCancellationConfirmation = () => {
    const { cancelAdditionalSeats } = this.props;
    const { selectedSeats } = this.state;

    const subscriptionsToCancel = this.getSubscriptionsToCancel();
    const devicesToCancel = selectedSeats.reduce<number[]>((devices, seat) => {
      if (seat.device == null) {
        return devices;
      }

      return [
        ...devices,
        seat.device.id,
      ];
    }, []);

    return cancelAdditionalSeats({
      subscriptions: subscriptionsToCancel.map((subscription) => subscription.id),
      devices: devicesToCancel,
    })
      .then(() => {
        const { showSuccessNotification } = this.props;
        const [firstCancelledSubscription] = subscriptionsToCancel;
        const nextPaymentDate = firstCancelledSubscription?.nextPaymentDate ?? 0;

        showSuccessNotification(
          <FormattedMessage
            id="cancelAdditionalSeats.seatsCancelledNotification"
            defaultMessage="{seatsAmount, plural, one {1 device} other {{seatsAmount} devices}} will be removed on {nextPaymentDate}"
            values={{
              seatsAmount: selectedSeats.length,
              nextPaymentDate: (
                <strong>
                  <FormattedDate
                    value={nextPaymentDate * 1000}
                    year="numeric"
                    month="short"
                    day="numeric"
                  />
                </strong>
              ),
            }}
          />,
          { withIcon: true },
        );
      })
      .catch(() => {
        const { showDangerNotification } = this.props;

        showDangerNotification(<DefaultError />);
      })
      .finally(() => {
        const { onHide } = this.props;

        onHide();
      });
  }

  getSubscriptionsToCancel(): Array<Subscription> {
    const { devicesSeats } = this.props;
    const { selectedSeats } = this.state;

    /**
     * There might be a seat with a primary subscription selected but we can't cancel the primary subscription
     * because it will cancel all the subscriptions in the account.
     * So we count the amount of the selected seats and pick the same amount of the additional seats subscriptions
     */
    const additionalSeatsSubscriptions = getAdditionalSeats(devicesToSeatsMapper.getSubscriptions(devicesSeats));

    return additionalSeatsSubscriptions.slice(0, selectedSeats.length);
  }
}

export default connector(CancelAdditionalSeatModal);
