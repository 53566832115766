export type DesktopAppState = {
  hasAlreadyDetected: boolean;
  isAvailable: boolean;
  isDetectionInProgress: boolean;
}

const initialState: DesktopAppState = {
  hasAlreadyDetected: false,
  isAvailable: false,
  isDetectionInProgress: false,
};

export default initialState;
