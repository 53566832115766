/* eslint-disable no-underscore-dangle */

// @flow

import type { Node } from 'react';
import React, { Component } from 'react';
import client from 'braintree-web/client';
import paypal from 'braintree-web/paypal';
import type { IntlShape } from 'react-intl';
import { defineMessages, injectIntl } from 'react-intl';

import type { BrainTreeError, Client as BrainTreeClient, PayPal } from 'braintree-web';
import * as paymentMethodTypes from 'state/payment-method/payment-method-types';
import logger from 'utils/logger';
import { browserToServerLocale } from 'utils/intl';
import analytics, { events } from 'utils/analytics/index';
import type { BraintreePaymentDetailsPayload } from 'services/payment-details-api/payment-details-api';
import wrapPaymentForm from '../payment-method-form-wrapper/payment-method-form-wrapper';
import PayPalForm from './paypal-form';


// Should be inexact because PaymentMethodFormWrapper injects several props related only to the payment card form
type Props = {
  paymentInfo: BraintreePaymentDetailsPayload,
  brainTreeAuthToken: string,
  setLoading: boolean => mixed,
  setGenericError: Node => mixed,
  setErrorFields: ({[string]: Node}) => mixed,
  onFieldChange: (string, string) => mixed,
  onFormSubmit: () => Promise<mixed>,
  merchantId: string,
  isLoading: boolean,
  formError: Node,
  fieldsWithError: {
    [string]: Node,
  },
  isPaymentMethodCreated: boolean,
  intl: IntlShape,
  captcha: Node,
  userLocale: string,
  countries: {[string]: string},
  loadingText: Node,
  submitBtnTitle: Node,
  paymentMethodOptions: {
    amount: number,
    currencyCode: string,
  },
};

type State = {|
  needsCountry: boolean,
  isPaypalLoaded: boolean,
  payPalEmail: string,
|};

const messages = defineMessages({
  braintreeInitializationError: {
    id: 'paypalForm.braintreeErrors.cantCreateClient',
    defaultMessage: 'An error occurred while loading your form. Please try again later.',
  },
  unsupportedBrowser: {
    id: 'paypalForm.braintreeErrors.browserUnsupported',
    defaultMessage: 'Sorry, PayPal doesn’t work in this browser. Add a credit card instead, or open this link in another browser.',
  },
  countryRequired: {
    id: 'paypalForm.validation.countryEmpty',
    defaultMessage: 'Country is required',
  },
  payPalAgreement: {
    id: 'paypalForm.agreement',
    defaultMessage: 'SETAPP CUSTOMER TERMS OF USE https://setapp.com/terms-of-use',
  },
  selectCountry: {
    id: 'paypalForm.braintreeErrors.needCountry',
    defaultMessage: 'Please choose your country to continue.',
  },
  tokenizationError: {
    id: 'paypalForm.braintreeErrors.unknownPayPalError',
    defaultMessage: 'An unknown PayPal error has occurred. Please try again later or contact support.',
  },
});

class BraintreePaypalForm extends Component<Props, State> {
  paypalInstance: ?PayPal = null;

  _isMounted = false;

  nonceExpirationTimeout: ?TimeoutID = null;

  state = {
    needsCountry: false,
    isPaypalLoaded: false,
    payPalEmail: '',
  };

  componentDidMount() {
    this._isMounted = true;
    const { brainTreeAuthToken, onFieldChange } = this.props;

    onFieldChange('type', paymentMethodTypes.PAYPAL);

    if (brainTreeAuthToken) {
      this.createBraintreeClient(brainTreeAuthToken);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { brainTreeAuthToken } = this.props;

    if (!prevProps.brainTreeAuthToken && brainTreeAuthToken) {
      this.createBraintreeClient(brainTreeAuthToken);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    const { setLoading } = this.props;

    this.destroyPaypalInstance();
    this.destroyNonceExpirationTimer();

    // Remove loading if unmounted when Braintree request is being processed
    setLoading(false);
  }

  render() {
    const { isPaypalLoaded, needsCountry, payPalEmail } = this.state;
    const { isLoading } = this.props;

    return (
      <PayPalForm
        {...this.props}
        email={payPalEmail}
        needsCountry={needsCountry}
        onFormSubmit={this.onFormSubmit}
        isLoading={isLoading || !isPaypalLoaded}
        isInitialized={isPaypalLoaded}
      />
    );
  }

  createBraintreeClient(authorization: string) {
    client.create({ authorization }, this.onBrainTreeClientCreated);
  }

  onBrainTreeClientCreated = (error: ?BrainTreeError, client: BrainTreeClient) => {
    if (error) {
      const { intl, setGenericError } = this.props;
      logger.logError('Couldn\'t create Brain Tree client instance', error);
      setGenericError(intl.formatMessage(messages.braintreeInitializationError));
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });

      return;
    }

    // fix for paypal initialization errors after logout
    if (this._isMounted) {
      paypal.create({ client }, this.onPaypalCreated);
    }
  };

  onPaypalCreated = (error: ?BrainTreeError, paypalInstance: PayPal) => {
    if (error) {
      const { intl, setGenericError } = this.props;

      if (error.code === 'PAYPAL_BROWSER_NOT_SUPPORTED') {
        setGenericError(intl.formatMessage(messages.unsupportedBrowser));
      } else {
        logger.logError('Can\'t create paypal form instance', error);
        setGenericError(intl.formatMessage(messages.braintreeInitializationError));
      }

      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });

      return;
    }

    if (this._isMounted) {
      this.paypalInstance = paypalInstance;
      this.enableForm();
    }
  };

  destroyPaypalInstance() {
    if (this.paypalInstance) {
      this.paypalInstance.teardown((error) => {
        if (error) {
          logger.logError('Couldn\'t destroy PayPal instance', error);
        }
      });
    }
  }

  enableForm() {
    this.setState({ isPaypalLoaded: true });
    analytics.trackEvent(events.PAYMENT_DETAILS_CHECKOUT_LOADED, { eventLabel: 'Braintree PayPal' });
  }

  setupNonceExpirationTimer() {
    const hourInMilliseconds = 60 * 60 * 1000;

    if (!this.nonceExpirationTimeout) {
      this.nonceExpirationTimeout = setTimeout(this.destroyNonceExpirationTimer.bind(this), hourInMilliseconds);
    }
  }

  destroyNonceExpirationTimer() {
    if (this.nonceExpirationTimeout) {
      clearTimeout(this.nonceExpirationTimeout);
      this.nonceExpirationTimeout = null;
    }
  }

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      intl,
      userLocale,
      onFormSubmit,
      paymentInfo,
      setLoading,
      setErrorFields,
    } = this.props;

    if (this.isNonceActive()) {
      if (paymentInfo.country) {
        onFormSubmit();
      } else {
        setErrorFields({ country: intl.formatMessage(messages.countryRequired) });
        analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
          eventLabel: intl.formatMessage(messages.countryRequired),
        });
        setLoading(false);
      }

      return;
    }

    if (!this.paypalInstance) {
      throw new Error('PayPal is not initialized');
    }

    this.paypalInstance.tokenize({
      flow: 'vault',
      locale: browserToServerLocale(userLocale),
      enableShippingAddress: false,
      billingAgreementDescription: intl.formatMessage(messages.payPalAgreement),
    }, (tokenizeErr, payload) => {
      if (tokenizeErr) {
        this.handlePayPalError(tokenizeErr);

        return;
      }

      const {
        onFormSubmit,
        setLoading,
        setErrorFields,
        onFieldChange,
      } = this.props;

      this.setupNonceExpirationTimer();

      onFieldChange('nonce', payload.nonce);
      this.setState({ payPalEmail: payload.details.email });

      if (!payload.details.countryCode) {
        this.setState({ needsCountry: true });
        setLoading(false);
        setErrorFields({ country: intl.formatMessage(messages.selectCountry) });
        analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
          eventLabel: intl.formatMessage(messages.selectCountry),
        });

        return;
      }

      onFieldChange('country', payload.details.countryCode);

      onFormSubmit();
    });

    setLoading(true);
  };

  handlePayPalError(error: BrainTreeError) {
    const { intl, setLoading, setGenericError } = this.props;
    setLoading(false);

    if (error.code !== 'PAYPAL_POPUP_CLOSED') {
      setGenericError(intl.formatMessage(messages.tokenizationError));

      logger.logError('PayPal tokenization error', error);
    }

    analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
      eventLabel: `[${error.code}] ${error.message || ''}`,
    });
  }

  isNonceActive() {
    const { paymentInfo } = this.props;

    return Boolean(paymentInfo.nonce);
  }
}

export { BraintreePaypalForm };

export default wrapPaymentForm(injectIntl(BraintreePaypalForm));
