import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import './drop-family-section.scss';

type Props = {
  familyPricePlan: PricePlan | null;
  onDropFamilyClick: () => void;
};

const getDropFamilySectionDescription = (paidMonth: number) => {
  switch (paidMonth) {
    case 1:
      return (<FormattedMessage
        id="familyPlan.dropFamilySection.monthlyPlan.description"
        defaultMessage="This will switch you and all the Family members to a regular Monthly plan."
              />);
    case 12:
      return (<FormattedMessage
        id="familyPlan.dropFamilySection.annualPlan.description"
        defaultMessage="This will switch you to an Annual plan and your Family members to a default Monthly plan."
              />);
    default:
      return (<FormattedMessage
        id="familyPlan.dropFamilySection.customPlan.description"
        defaultMessage="This will switch you to a regular plan with payments every {paidMonth} months."
        values={{ paidMonth }}
              />);
  }
};

const DropFamilySection = (props: Props) => {
  const { familyPricePlan, onDropFamilyClick } = props;

  return (
    <div data-qa="dismissFamilySection">
      <p className="h5">
        <FormattedMessage
          id="dropFamilySection.title"
          defaultMessage="Dismiss Setapp Family"
        />
      </p>
      <div className="drop-family-section__description">
        {familyPricePlan && getDropFamilySectionDescription(familyPricePlan.paidMonth)}
      </div>
      <AccountControlButtons>
        <Button
          variant="danger-outline"
          onClick={onDropFamilyClick}
          data-qa="dismissFamilyButton"
        >
          <FormattedMessage
            id="dropFamilySection.submitButton"
            defaultMessage="Dismiss Family"
          />
        </Button>
      </AccountControlButtons>
    </div>
  );
};

export default DropFamilySection;
