import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Notification, Button } from '@setapp/ui-kit';

import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';

import DefaultError from 'components/shared/default-error/default-error';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentFormModal from 'components/modals/payment-details/payment-details-modal';

import NotificationNeedHelpLink from '../notification-need-help-link/notification-need-help-link';
import RetryChargeContainer from '../retry-charge-container/retry-charge-container';

import './payment-failed-notification.scss';

type Props = {
  onRetryChargeClick: () => Promise<void>;
}

const PaymentFailedNotification = ({ onRetryChargeClick }: Props) => {
  const dispatch = useDispatch();
  const [isModalShown, setIsModalShown] = useState(false);

  const makeCharge = async () => {
    try {
      await onRetryChargeClick();
    } catch (error) {
      dispatch(showDangerNotification(<DefaultError />));
    }
  };

  const onModalHide = () => {
    setIsModalShown(false);
  };

  const handlePaymentDetailsUpdateSuccess = () => {
    onModalHide();

    dispatch(showSuccessNotification((
      <FormattedMessage
        id="subscriptionInfo.retryCharge.paymentInfoUpdated"
        defaultMessage="Payment details successfully updated."
      />
    )));

    makeCharge();
  };

  const handleAddPaymentDetailsClick = () => {
    setIsModalShown(true);
  };

  return (
    <Notification type="danger">
      <div className="payment-failed-notification__container">
        <p className="mb-0">
          <FormattedMessage
            id="subscriptionInfo.statusNotifications.failedPayment"
            defaultMessage="We couldn’t process your payment. Retry, and if that doesn’t help, update your payment details."
          />
        </p>
        <div className="payment-failed-notification__action">
          <RetryChargeContainer onButtonClick={onRetryChargeClick} />
        </div>
      </div>
      <div className="payment-failed-notification__help">
        <div className="mt-4">
          <Button
            className="notification-update-details-link"
            variant="link"
            onClick={handleAddPaymentDetailsClick}
          >
            <PaymentDetailsActionText action="update" />
          </Button>
        </div>
        <NotificationNeedHelpLink />
      </div>
      <PaymentFormModal
        show={isModalShown}
        onHide={onModalHide}
        onPaymentDetailsSaved={handlePaymentDetailsUpdateSuccess}
        title={<PaymentDetailsActionText action="update" />}
      />
    </Notification>
  );
};

export default PaymentFailedNotification;
