import React from 'react';
import type { ReactNode } from 'react';

import './page-title.scss';

type Props = {
  children: ReactNode;
}

const PageTitle = ({ children }: Props) => (
  <h1 className="page-title h3 mt-0" data-qa="pageTitle">
    {children}
  </h1>
);

export default PageTitle;
