import React, { FC } from 'react';
import * as subscriptionStatuses from 'state/subscription/statuses';
import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import { Subscription } from 'state/subscription/subscription-initial-state';
import PlanRow from '../../plan-row/plan-row';
import PriceRow from '../../price-row/price-row';
import StatusRow from '../../status-row/status-row';
import NextPaymentRow from '../../next-payment-row/next-payment-row';
import VatNoteRow from '../../vat-note-row/vat-note-row';
import AiCreditsRow from '../../ai-credits-row/ai-credits-row';

export type Props = {
  tax: number;
  pricePlan: Subscription['pricePlan'];
  subscription: Subscription;
};

const FamilyOwner: FC<Props> = ({ tax, pricePlan, subscription }) => {
  const { status, expirationDate, nextPaymentDate  } = subscription;
  const { price, currency, paidMonth, tierType } = pricePlan;

  const isNextPaymentDateVisible = status !== subscriptionStatuses.BLOCKED && nextPaymentDate;

  return (
    <div data-testid="familyOwner">
      <PlanRow value={<PricePlanTitle tierType={tierType} />} />

      <PriceRow
        price={price}
        currency={currency}
        paidMonth={paidMonth}
        tax={tax}
      />

      <StatusRow
        status={status}
        expirationDate={expirationDate}
      />

      {isNextPaymentDateVisible && (
        <NextPaymentRow
          nextPaymentDate={nextPaymentDate}
          highlightDate={status === subscriptionStatuses.GRACE}
        />
      )}

      <AiCreditsRow />

      {tax === 0 && <VatNoteRow />}
    </div>
  );
};

export default FamilyOwner;
