import React from 'react';
import type { ReactNode } from 'react';

import './text-delimiter.scss';

type Props = {
  children: ReactNode;
};

const TextDelimiter = ({ children }: Props) => (
  <div className="text-delimiter">
    <span className="text-delimiter-text">
      {children}
    </span>
  </div>
);

export default TextDelimiter;
