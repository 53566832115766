import React from 'react';
import type { ReactNode } from 'react';
import { Tooltip } from '@setapp/ui-kit';

import InfoIcon from './images/info-icon.svg';

import './info-button.scss';

export type Props = {
  children: ReactNode;
};

const InfoButton = (props: Props) => {
  const { children } = props;

  return (
    <Tooltip
      trigger="click"
      position="bottom"
      distance="sm"
      content={
        <div className="info-button__tooltip text_sm">
          {children}
        </div>
      }
      appendTo="parent"
    >
      <button
        type="button"
        className="btn info-button"
      >
        <img src={InfoIcon} alt="" width={14} height={14} />
      </button>
    </Tooltip>
  );
};

export default InfoButton;
