import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import type { SubscriptionStatus as SubscriptionStatusType } from 'state/subscription/statuses';

import AccountInfoRow from 'components/shared/account-info-row/account-info-row';

import SubscriptionStatus from './subscription-status/subscription-status';

type Props = {
  status: SubscriptionStatusType;
  expirationDate?: Maybe<number>;
};

const StatusRow: FC<Props> = ({ status, expirationDate }) => (
  <AccountInfoRow
    title={<FormattedMessage id="subscriptionInfo.accountStatus" defaultMessage="Account status" />}
    value={<SubscriptionStatus status={status} expirationDate={expirationDate} />}
    qaLabel="subscriptionStatusText"
  />
);

export default StatusRow;
