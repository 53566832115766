import * as pricePlanTypes from 'state/price-plans/price-plans-types';

const PRICE_PLAN_PERIOD_MAPPER = {
  [pricePlanTypes.MAC_MONTHLY]: pricePlanTypes.MAC_ANNUAL,
  [pricePlanTypes.MAC_ANNUAL]: pricePlanTypes.MAC_MONTHLY,
  [pricePlanTypes.MAC_PLUS_IOS_MONTHLY]: pricePlanTypes.MAC_PLUS_IOS_ANNUAL,
  [pricePlanTypes.MAC_PLUS_IOS_ANNUAL]: pricePlanTypes.MAC_PLUS_IOS_MONTHLY,
  [pricePlanTypes.TWO_MACS_MONTHLY]: pricePlanTypes.TWO_MACS_ANNUAL,
  [pricePlanTypes.TWO_MACS_ANNUAL]: pricePlanTypes.TWO_MACS_MONTHLY,
  [pricePlanTypes.POWER_USER_MONTHLY]: pricePlanTypes.POWER_USER_ANNUAL,
  [pricePlanTypes.POWER_USER_ANNUAL]: pricePlanTypes.POWER_USER_MONTHLY,
  [pricePlanTypes.IOS_MONTHLY]: pricePlanTypes.IOS_ANNUAL,
  [pricePlanTypes.IOS_ANNUAL]: pricePlanTypes.IOS_MONTHLY,
  [pricePlanTypes.FAMILY_MONTHLY]: pricePlanTypes.FAMILY_ANNUAL,
  [pricePlanTypes.FAMILY_ANNUAL]: pricePlanTypes.FAMILY_MONTHLY,
};

export default PRICE_PLAN_PERIOD_MAPPER;
