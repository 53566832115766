import Cookies from 'js-cookie';
import { COOKIES_ROOT_DOMAIN } from 'config/app';

const COOKIE_NAME = 'isAlreadyVisitedSetappMobile';

export const isAlreadyVisitedSetappMobile = (): boolean => {
  const cookieValue = Cookies.get(COOKIE_NAME);

  return Boolean(cookieValue);
};

export const setAlreadyVisitedSetappMobile = (): void => {
  Cookies.set(COOKIE_NAME, '1', { domain: COOKIES_ROOT_DOMAIN, expires: 365 * 5 });
};
