import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { FormattedMessage, defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';

import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { getAppsCount } from 'state/root-reducer';

import './payment-details-plan-card.scss';

const messages = defineMessages({
  appsCount: {
    id: 'signup.payment-details.plans.apps-count',
    defaultMessage: '{appsCount}+ Mac and Web apps',
  },
  iosAppsCount: {
    id: 'signup.payment-details.plans.ios-apps-count',
    defaultMessage: '35+ iOS apps',
  },
  macDevicesCount: {
    id: 'signup.payment-details.plans.mac-count',
    defaultMessage: '1 Mac',
  },
  iosDevicesCount: {
    id: 'signup.payment-details.plans.ios-count',
    defaultMessage: '{iosCount} iOS devices',
  }
});

type PlanCardProps = InjectedIntlProps & {
  plan: PricePlan;
  checked: boolean;
  onClick: (pricePlan: PricePlan) => void;
};

const PaymentDetailsPlanCard = (({ intl, plan, checked, onClick }: PlanCardProps) => {
  const appsCount = useSelector(getAppsCount);
  const planName = plan.tierType === pricePlanTypes.IOS_MONTHLY ? 'iOS' : 'Mac + iOS';

  const features = [{
    label: intl.formatMessage(messages.appsCount, { appsCount }),
    checked: plan.tierType === pricePlanTypes.MAC_PLUS_IOS_MONTHLY,
  }, {
    label: intl.formatMessage(messages.iosAppsCount),
    checked: true,
  }, {
    label: intl.formatMessage(messages.macDevicesCount),
    checked: plan.tierType === pricePlanTypes.MAC_PLUS_IOS_MONTHLY,
  }, {
    label: intl.formatMessage(messages.iosDevicesCount, { iosCount: plan.features?.iosSeatsCount }),
    checked: true,
  }];

  const handlePlanClick = () => onClick(plan);

  return (
    <button
      className={classnames('payment-price-plan', { 'payment-price-plan_checked': checked })}
      onClick={handlePlanClick}
      aria-checked={checked}
      role="radio"
    >
      <span className="payment-price-plan__header">
        <span className="payment-price-plan__tag">
          <FormattedMessage
            id="signup.payment-details.plans.tag"
            defaultMessage="7-day free trial"
          />
        </span>
        <p>
          <FormattedMessage
            id="signup.payment-details.plans.price"
            defaultMessage="then ${price} /mo"
            values={{ price: plan.price }}
          />
        </p>
      </span>
      <h5>
        <FormattedMessage
          id="signup.payment-details.plans.name"
          defaultMessage="{planName} apps"
          values={{ planName }}
        />
      </h5>
      <ul className="payment-price-plan__features">
        {features.map((feature) => (
          <li
            key={feature.label}
            className={classnames('payment-price-plan__item', {
              'payment-price-plan__item_checked': feature.checked,
            })}
          >
            {feature.label}
          </li>
        ))}
      </ul>
    </button>
  );
});

export default injectIntl(PaymentDetailsPlanCard);
