import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { detectDesktopApp } from 'state/desktop-app/desktop-app-reducer';
import {
  hasDesktopAppAlreadyDetected as hasDesktopAppAlreadyDetectedSelector,
  isDesktopAppAvailable as isDesktopAppAvailableSelector,
  isDesktopAppDetectionInProgress as isDesktopAppDetectionInProgressSelector,
} from 'state/root-reducer';

/**
 * Hook to automatically detect the desktop app on component mount. Hook checks if the desktop app has already been
 * detected and if not, dispatches the detectDesktopApp action.
 *
 * @returns {boolean} isDesktopAppAvailable - Whether the desktop app is available
 * @returns {boolean} isDesktopAppDetectionInProgress - Whether the desktop app detection is in progress
 */
const useDesktopAppDetection = () => {
  const dispatch = useDispatch();
  const hasDesktopAppAlreadyDetected = useSelector(hasDesktopAppAlreadyDetectedSelector);
  const isDesktopAppAvailable = useSelector(isDesktopAppAvailableSelector);
  const isDesktopAppDetectionInProgress = useSelector(isDesktopAppDetectionInProgressSelector);

  useEffect(() => {
    if (!hasDesktopAppAlreadyDetected) {
      dispatch(detectDesktopApp());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isDesktopAppAvailable,
    isDesktopAppDetectionInProgress,
  };
};

export default useDesktopAppDetection;
