import React from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';

import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';

import useIosEventHandlers from 'components/hooks/use-ios-event-handlers/use-ios-event-handlers';

import './ios-apps-list.scss';

export type Props = {
  appsList: IosApp[];
}

const IosAppsList = ({ appsList }: Props) => {
  const { handleIosAppClick, handleIosAppKeyDown } = useIosEventHandlers();

  return (
    <div className="ios-apps-list" data-qa="ios-apps-list">
      {appsList.map((item) => (
        <div
          className="ios-apps-list__item"
          key={item.id}
          role="button"
          tabIndex={0}
          onClick={() => handleIosAppClick(item)}
          onKeyDown={(event) => handleIosAppKeyDown(event, item)}
        >
          <div className="ios-apps-list__icon-container">
            <div className="ios-apps-list__icon">
              <img
                width="96"
                height="96"
                src={item.icon}
                alt={item.name}
              />
            </div>
          </div>
          <div className="ios-apps-list__info">
            <p className="ios-apps-list__description">{item.shortDescription}</p>
            <p className="ios-apps-list__name">{item.name}</p>
            <Button
              className="ios-apps-list__btn"
              variant="primary-outline"
              type="button"
              size="sm"
            >
              <FormattedMessage
                id="iosApps.list.getBtn"
                defaultMessage="Get"
              />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IosAppsList;
