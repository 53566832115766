import type { InvoiceStatus } from './invoice-statuses';

export type Invoice = {
  id: string;
  paymentDate: number;
  currency: string;
  amount: number;
  amountFormatted: string;
  taxAmount: number;
  taxAmountFormatted: string;
  status: InvoiceStatus;
};

export type InvoicesState = {
  isLoading: boolean;
  list: ReadonlyArray<Invoice>;
};

const initialState: InvoicesState = {
  isLoading: false,
  list: [],
};

export default initialState;
