// @flow

/* eslint-disable no-underscore-dangle, no-undef */

import React, { Component } from 'react';
import client from 'braintree-web/client';
import applePay from 'braintree-web/apple-pay';
import googlePayment from 'braintree-web/google-payment';
import hostedFields from 'braintree-web/hosted-fields';
import threeDSecure from 'braintree-web/three-d-secure';
import { defineMessages, injectIntl } from 'react-intl';
import Script from 'react-load-script';
import cookies from 'js-cookie';

import type { Node } from 'react';
import type { IntlShape } from 'react-intl';
import type {
  Client as BrainTreeClient,
  HostedFields,
  HostedFieldsState,
  HostedFieldsTokenizePayload,
  BrainTreeError,
  ThreeDSecure,
  ThreeDSecureVerificationPayload,
  ApplePay,
  ApplePayPaymentAuthorizedEvent,
  ApplePayTokenizePayload,
  ApplePayError,
  GooglePayment,
  PaymentsClient,
  PaymentData,
  GooglePaymentTokenizePayload,
} from 'braintree-web';

import type { BraintreePaymentDetailsPayload } from 'services/payment-details-api/payment-details-api';
import logger from 'utils/logger';
import { type ResolvedFeatureFlags } from 'utils/feature-flags';
import analytics, { events } from 'utils/analytics';
import * as paymentMethodTypes from 'state/payment-method/payment-method-types';
import wrapPaymentForm from '../payment-method-form-wrapper/payment-method-form-wrapper';
import CreditCardForm from './credit-card-form';
import fieldsMessages from './form-fields-messages';
import BrainTreeFieldsContext, { type BrainTreeFieldsContextType } from './braintree-fields-context';

const GOOGLE_PAY_SDK_URL = 'https://pay.google.com/gp/p/js/pay.js';

// Should be inexact because PaymentMethodFormWrapper injects several props related only to the PayPal form
// TODO: how to avoid duplication of the props definition with CreditCardForm?
type Props = {
  paymentInfo: BraintreePaymentDetailsPayload,
  brainTreeAuthToken: string,
  threeDSecureRequired: boolean,
  setLoading: boolean => mixed,
  setGenericError: Node => mixed,
  setErrorFields: ({[string]: Node}) => mixed,
  onFieldChange: (string, string) => mixed,
  onFormSubmit: () => Promise<mixed>,
  merchantId: string,
  isLoading: boolean,
  formError: Node,
  fieldsWithError: {
    [string]: Node,
  },
  isPaymentMethodCreated: boolean,
  intl: IntlShape,
  captcha: Node,
  loadingText: Node,
  submitBtnTitle: Node,
  paymentMethodOptions: {
    amount: number,
    currencyCode: string,
  },
  userLocale: string,
  featureFlags: ResolvedFeatureFlags,
};

type State = {
  ...BrainTreeFieldsContextType,
  withApplePay: boolean,
  isApplePayInit: boolean,
  isApplePayLoading: boolean,
  isGooglePayScriptLoaded: boolean,
  withGooglePay: boolean,
  isGooglePayInit: boolean,
};

const messages = defineMessages({
  cantCreateClient: {
    id: 'creditCardForm.braintreeErrors.cantCreateClient',
    defaultMessage: 'An error occurred while loading your form. Please try again later.',
  },
  unknownHostedFieldsError: {
    id: 'creditCardForm.braintreeErrors.unknownHostedFieldsError',
    defaultMessage: 'An unknown error has occurred. Please try again later or contact support.',
  },
  numberEmpty: {
    id: 'creditCardForm.validation.numberEmpty',
    defaultMessage: 'Credit card number is required',
  },
  numberInvalid: {
    id: 'creditCardForm.validation.numberInvalid',
    defaultMessage: 'Invalid credit card number',
  },
  expirationDateEmpty: {
    id: 'creditCardForm.validation.expirationDateEmpty',
    defaultMessage: 'Date is required',
  },
  expirationDateInvalid: {
    id: 'creditCardForm.validation.expirationDateInvalid',
    defaultMessage: 'Invalid date',
  },
  cvvEmpty: {
    id: 'creditCardForm.validation.cvvEmpty',
    defaultMessage: 'CVV is required',
  },
  cvvInvalid: {
    id: 'creditCardForm.validation.cvvInvalid',
    defaultMessage: 'Invalid CVV',
  },
  appleInitializationError: {
    id: 'applePay.init.error',
    defaultMessage: 'Can\'t create apple pay form instance: {error}'
  },
  appleValidationError: {
    id: 'applePay.validation.merchant',
    defaultMessage: 'Error validating merchant: {error}'
  },
  appleTokenizationError: {
    id: 'applePay.validation.tokenizationError',
    defaultMessage: 'Error tokenizing Apple Pay: {error}'
  },
  googleInitializationError: {
    id: 'googlePay.init.error',
    defaultMessage: 'Can\'t create google pay form instance: {error}'
  },
  googleLoadDataError: {
    id: 'googlePay.loadData.error',
    defaultMessage: 'Can\'t load Google payment data: {error}'
  },
});

class BrainTreeCreditCardForm extends Component<Props, State> {
  state = {
    isInitialized: false,
    focusedField: null,
    withApplePay: false,
    isApplePayInit: false,
    isApplePayLoading: false,
    withGooglePay: false,
    isGooglePayScriptLoaded: false,
    isGooglePayInit: false,
  };

  _isMounted = false;

  hostedFields: ?HostedFields = null;

  threeDSecure: ?ThreeDSecure = null;

  applePayInstance: ?ApplePay = null;

  googlePaymentInstance: ?GooglePayment = null;

  braintreeClient: ?BrainTreeClient = null;

  googlePaymentsClient: ?PaymentsClient = null;

  componentDidMount() {
    const { brainTreeAuthToken, onFieldChange, paymentMethodOptions, featureFlags } = this.props;

    const showApplePay = paymentMethodOptions && (
      // $FlowFixMe
      window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()
    );

    const isGooglePayTest = featureFlags.googlePayBraintreeTest.value
      && cookies.get('googlePayTest') === 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9';

    this.setState({ withGooglePay: Boolean(isGooglePayTest) });
    this.setState({ withApplePay: Boolean(showApplePay) });

    if (brainTreeAuthToken) {
      this.createBraintreeClient(brainTreeAuthToken);
    }
    this._isMounted = true;

    onFieldChange('type', paymentMethodTypes.CREDIT_CARD);
  }

  componentDidUpdate(prevProps: Props) {
    const { brainTreeAuthToken } = this.props;

    if (!prevProps.brainTreeAuthToken && brainTreeAuthToken) {
      this.createBraintreeClient(brainTreeAuthToken);
    }
  }

  componentWillUnmount() {
    const { setLoading } = this.props;
    this._isMounted = false;

    this.destroyBrainTreeComponents();
    this.destroyApplePayInstance();
    this.destroyGooglePayInstance();

    // Remove loading if unmounted when Braintree request is being processed
    setLoading(false);
  }

  render() {
    const { isLoading } = this.props;
    const {
      isInitialized,
      withApplePay,
      isApplePayLoading,
      isApplePayInit,
      withGooglePay,
      isGooglePayScriptLoaded,
      isGooglePayInit,
    } = this.state;

    return (
      <>
        <BrainTreeFieldsContext.Provider value={this.getContext()}>
          <CreditCardForm
            {...this.props}
            onFormSubmit={this.onFormSubmit}
            onApplePayButtonClick={this.onApplePayButtonClick}
            isLoading={isLoading || !isInitialized}
            isInitialized={isInitialized}
            withApplePay={withApplePay}
            withGooglePay={isGooglePayScriptLoaded && withGooglePay}
            isApplePayLoading={isApplePayLoading || !isApplePayInit}
            isGooglePayInit={isGooglePayInit}
          />
        </BrainTreeFieldsContext.Provider>
        {withGooglePay && (
          <Script
            url={GOOGLE_PAY_SDK_URL}
            onLoad={this.handleGoogleScriptLoaded}
            onError={this.handleGoogleScriptLoadingError}
          />
        )}
      </>
    );
  }

  handleGoogleScriptLoadingError = () => {
    logger.logError('Can\'t load google pay script');
  }

  handleGoogleScriptLoaded = () => {
    this.setState({ isGooglePayScriptLoaded: true });
    if (this.braintreeClient) {
      this.initGooglePay();
    }
  }

  initGooglePay = () => {
    const client = this.braintreeClient;

    // $FlowFixMe
    this.googlePaymentsClient = new google.payments.api.PaymentsClient({
      environment: process.env.ENVIRONMENT === 'production' ? 'PRODUCTION' : 'TEST',
    });

    googlePayment.create({
      client,
      googlePayVersion: 2,
      googleMerchantId: process.env.GOOGLE_PAY_MERCHANT_ID,
    }, this.onGooglePayCreated);
  }

  onGooglePayCreated = (googlePaymentErr: ?BrainTreeError, googlePaymentInstance: GooglePayment) => {
    const { userLocale } = this.props;
    const [locale] = userLocale.split('-');

    if (!this.googlePaymentsClient) {
      throw new Error('Google pay API client is not initialized');
    }

    if (googlePaymentErr) {
      const { intl, setGenericError } = this.props;

      logger.logError('Can\'t create google pay form instance', googlePaymentErr);
      setGenericError(intl.formatMessage(messages.googleInitializationError, googlePaymentErr));

      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${googlePaymentErr.code}] ${googlePaymentErr.message || ''}`,
      });

      return;
    }

    this.googlePaymentInstance = googlePaymentInstance;

    this.googlePaymentsClient.isReadyToPay({
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
      existingPaymentMethodRequired: true,
    }).then((response: { result: boolean, paymentMethodPresent?: boolean }) => {
      if (response.result) {
        this.setState({ isGooglePayInit: true });

        const container = document.getElementById('google-pay-container');

        if (!this.googlePaymentsClient) {
          throw new Error('Google pay API client is not initialized');
        }

        const button = this.googlePaymentsClient.createButton({
          buttonColor: 'default',
          buttonType: 'subscribe',
          buttonSizeMode: 'fill',
          buttonLocale: locale,
          onClick: this.onGooglePayButtonClick,
        });

        if (!container) {
          logger.logError('Container for google pay button not found');
          analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
            eventLabel: 'Container for google pay button not found',
          });

          return;
        }

        container.appendChild(button);
        analytics.trackEvent(events.PAYMENT_DETAILS_CHECKOUT_LOADED, { eventLabel: 'Braintree Google Pay' });
      }
    }).catch((error: { statusCode: string }) => {
      logger.logError('Google payment client is not ready for payment', error);
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: error.statusCode,
      });
    });
  }

  disableGooglePayButton = () => {
    const button = document.getElementsByClassName('gpay-button')[0];
    if (button) {
      button.setAttribute('disabled', 'true');
    }
  }

  enableGooglePayButton = () => {
    const button = document.getElementsByClassName('gpay-button')[0];
    if (button) {
      button.removeAttribute('disabled');
    }
  }

  onGooglePayButtonClick = (event: Event) => {
    const {
      onFormSubmit,
      onFieldChange,
      paymentMethodOptions,
      setGenericError,
      intl,
    } = this.props;

    event.preventDefault();

    this.disableGooglePayButton();

    if (!this.googlePaymentInstance) {
      throw new Error('Google pay API client is not initialized');
    }

    const paymentDataRequest = this.googlePaymentInstance.createPaymentDataRequest({
      transactionInfo: {
        currencyCode: paymentMethodOptions.currencyCode,
        totalPriceStatus: 'FINAL',
        totalPrice: paymentMethodOptions.amount.toString(),
      }
    });

    analytics.trackEvent(events.PAYMENT_DETAILS_PAYMENT_METHOD_SELECT, { eventLabel: 'Google Pay' });

    const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
    cardPaymentMethod.parameters.billingAddressRequired = true;
    cardPaymentMethod.parameters.billingAddressParameters = {
      format: 'FULL',
      phoneNumberRequired: true
    };

    if (!this.googlePaymentsClient) {
      throw new Error('Google pay API client is not initialized');
    }

    this.googlePaymentsClient.loadPaymentData(paymentDataRequest).then((paymentData: PaymentData) => {
      if (!this.googlePaymentInstance) {
        throw new Error('Google pay API client is not initialized');
      }

      this.googlePaymentInstance.parseResponse(paymentData, (
        error: ?BrainTreeError,
        payload: GooglePaymentTokenizePayload
      ) => {
        if (error) {
          this.enableGooglePayButton();
          setGenericError(intl.formatMessage(messages.googleLoadDataError, error.message));
          logger.logError('Couldn\'t load Google payment data', error);
          analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
            eventLabel: `[${error.code}] ${error.message || ''}`,
          });

          return;
        }

        onFieldChange('nonce', payload.nonce);

        onFormSubmit().then(() => {
          this.enableGooglePayButton();
        });
      });
    }).catch((error: { statusCode: string }) => {
      this.enableGooglePayButton();
      logger.logError('Couldn\'t load Google payment data', error);
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: error.statusCode,
      });
    });
  }

  getContext(): BrainTreeFieldsContextType {
    const { focusedField, isInitialized } = this.state;

    return {
      focusedField,
      isInitialized,
    };
  }

  createBraintreeClient(authorization: string) {
    client.create({ authorization }, this.onBrainTreeClientCreated);
  }

  onBrainTreeClientCreated = (error: ?BrainTreeError, client: BrainTreeClient) => {
    const { setGenericError, intl } = this.props;
    const { withApplePay, isGooglePayScriptLoaded } = this.state;

    if (error) {
      setGenericError(intl.formatMessage(messages.cantCreateClient));
      logger.logError('Couldn\'t create Brain Tree client instance', error);
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });

      return;
    }

    // fix for hosted fields initialization errors after logout
    if (!this._isMounted) {
      return;
    }

    this.braintreeClient = client;
    this.createHostedFields(client);
    this.createThreeDSecure(client);
    if (withApplePay) {
      applePay.create({ client }, this.onApplePayCreated);
    }
    if (isGooglePayScriptLoaded) {
      this.initGooglePay();
    }
  };

  createHostedFields(client: BrainTreeClient) {
    const { intl } = this.props;

    return hostedFields.create({
      client,
      styles: {
        input: {
          color: '#26262b',
          'font-size': '16px',
          'font-family': 'Avenir, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif',
        },
      },
      fields: {
        number: {
          selector: '#number',
        },
        cvv: {
          selector: '#cvv',
        },
        expirationDate: {
          selector: '#expirationDate',
          placeholder: intl.formatMessage(fieldsMessages.cardExpirationPlaceholder),
        },
      },
    }, this.onHostedFieldsCreated);
  }

  onHostedFieldsCreated = (error: ?BrainTreeError, hostedFieldsInstance: HostedFields) => {
    const { setGenericError, intl } = this.props;

    if (error) {
      /**
       * The "HOSTED_FIELDS_TIMEOUT" error occurs after 60-sec timeout when the component is unmounted
       * before hosted fields initialization is finished. There's no ability to cancel hosted fields initialization.
       * This is a Braintree SDK issue so we don't display and log the error in such case.
       */
      const shouldDisplayError = !(error.code === 'HOSTED_FIELDS_TIMEOUT' && !this._isMounted);

      if (shouldDisplayError) {
        setGenericError(intl.formatMessage(messages.unknownHostedFieldsError));
        logger.logError('Can\'t create hosted fields instance', error);
        analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
          eventLabel: `[${error.code}] ${error.message || ''}`,
        });
      }

      return;
    }

    this.hostedFields = hostedFieldsInstance;

    if (this.threeDSecure) {
      this.setUpHostedFieldsBehaviour(hostedFieldsInstance);
      this.enableForm();
    }
  };

  onApplePayCreated = (error: ?BrainTreeError, applePayInstance: ApplePay) => {
    if (error) {
      const { intl, setGenericError } = this.props;

      logger.logError('Can\'t create apple pay form instance', error);
      setGenericError(intl.formatMessage(messages.appleInitializationError, error));

      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });

      return;
    }

    this.applePayInstance = applePayInstance;
    this.enableApplePayForm();
  };

  createThreeDSecure(client: BrainTreeClient) {
    return threeDSecure.create({
      client,
      version: 2,
    }, this.onThreeDSecureCreated);
  }

  onThreeDSecureCreated = (error: ?BrainTreeError, threeDSecure: ThreeDSecure) => {
    const { setGenericError, intl } = this.props;

    if (error) {
      setGenericError(intl.formatMessage(messages.unknownHostedFieldsError));
      logger.logError('Can\'t create Braintree 3D secure instance', error);
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });
    }

    this.threeDSecure = threeDSecure;

    if (this.hostedFields) {
      this.setUpHostedFieldsBehaviour(hostedFields);
      this.enableForm();
    }
  };

  destroyBrainTreeComponents() {
    if (this.hostedFields) {
      this.hostedFields.teardown(this.onHostedFieldsDestroyed);
    }

    if (this.threeDSecure) {
      this.threeDSecure.teardown(this.onThreeDSecureDestroyed);
    }
  }

  destroyApplePayInstance() {
    if (this.applePayInstance) {
      this.applePayInstance.teardown((error: ?BrainTreeError) => {
        if (error) {
          logger.logError('Couldn\'t destroy Apple Pay instance', error);
        }
      });
    }
  }

  destroyGooglePayInstance() {
    if (this.googlePaymentInstance) {
      this.googlePaymentInstance.teardown((error: ?BrainTreeError) => {
        if (error) {
          logger.logError('Couldn\'t destroy Google Pay instance', error);
        }
      });
    }
  }

  onHostedFieldsDestroyed(error: ?BrainTreeError) {
    if (error) {
      logger.logError('Couldn\'t destroy Braintree hosted fields', error);
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });
    }
  }

  onThreeDSecureDestroyed(error: ?BrainTreeError) {
    if (error) {
      logger.logError('Couldn\'t destroy Braintree 3D secure object', error);
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });
    }
  }

  enableApplePayForm() {
    this.setState({ isApplePayInit: true });
    analytics.trackEvent(events.PAYMENT_DETAILS_CHECKOUT_LOADED, { eventLabel: 'Braintree Apple Pay' });
  }

  enableForm() {
    this.setState({ isInitialized: true });
    analytics.trackEvent(events.PAYMENT_DETAILS_CHECKOUT_LOADED, { eventLabel: 'Braintree' });
  }

  setUpHostedFieldsBehaviour(fields: HostedFields) {
    fields.on('focus', this.onHostedFieldsFocus);
    fields.on('blur', this.onHostedFieldsBlur);
    fields.on('inputSubmitRequest', this.savePaymentMethod);
  }

  onHostedFieldsFocus = (event: HostedFieldsState) => {
    this.setState({ focusedField: event.emittedBy });

    /**
     * Hide field tooltip on focus into the hosted field.
     * Our <Tooltip/> component adds global "click" listener to detect whether to show or hide the tooltip.
     * We trigger click on the document to make it hide all the tooltips.
     */
    document.dispatchEvent(new Event('click'));
  };

  onHostedFieldsBlur = () => {
    this.setState({ focusedField: null });
  };

  onApplePayButtonClick = () => {
    const {
      onFormSubmit,
      setGenericError,
      intl,
      onFieldChange,
      paymentMethodOptions,
    } = this.props;

    if (!this.applePayInstance) {
      throw new Error('ApplePay is not initialized');
    }

    const paymentRequest = this.applePayInstance.createPaymentRequest({
      total: {
        label: 'Setapp',
        amount: paymentMethodOptions.amount.toString(),
      },
      currencyCode: paymentMethodOptions.currencyCode,
      requiredBillingContactFields: ['postalAddress']
    });

    this.setState({ isApplePayLoading: true });

    // $FlowFixMe
    const session = new ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = (event: { validationURL: string }) => {
      if (this.applePayInstance) {
        this.applePayInstance.performValidation({
          validationURL: event.validationURL,
          displayName: 'Setapp',
        }).then((merchantSession) => {
          session.completeMerchantValidation(merchantSession);
        }).catch((validationErr: ApplePayError) => {
          logger.logError('Error validating merchant:', validationErr);
          setGenericError(intl.formatMessage(messages.appleValidationError, { error: validationErr }));
          analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
            eventLabel: `[${validationErr.code}] ${validationErr.message || ''}`,
          });
          this.setState({ isApplePayLoading: false });
          session.abort();
        });
      }
    };

    session.onpaymentauthorized = (event: ApplePayPaymentAuthorizedEvent) => {
      if (this.applePayInstance) {
        this.applePayInstance.tokenize({
          token: event.payment.token,
        }).then((payload: ApplePayTokenizePayload) => {
          onFieldChange('nonce', payload.nonce);

          onFormSubmit();

          session.completePayment(ApplePaySession.STATUS_SUCCESS);
          this.setState({ isApplePayLoading: false });
        }).catch((tokenizeErr: ApplePayError) => {
          logger.logError('Error tokenizing Apple Pay:', tokenizeErr);
          setGenericError(intl.formatMessage(messages.appleTokenizationError, { error: tokenizeErr }));
          analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
            eventLabel: `[${tokenizeErr.code}] ${tokenizeErr.message || ''}`,
          });
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          this.setState({ isApplePayLoading: false });
        });
      }
    };

    session.oncancel = () => {
      this.setState({ isApplePayLoading: false });
    };

    analytics.trackEvent(events.PAYMENT_DETAILS_PAYMENT_METHOD_SELECT, { eventLabel: 'Apple Pay' });

    session.begin();
  };

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.savePaymentMethod();
  };

  savePaymentMethod = () => {
    const { threeDSecureRequired, setLoading } = this.props;

    if (!this.hostedFields) {
      throw new Error('Hosted fields are not initialized');
    }

    this.hostedFields.tokenize((err, payload) => {
      if (err) {
        this.handleHostedFieldsError(err);

        return;
      }

      if (!threeDSecureRequired) {
        this.saveNonceAndSavePaymentMethod(payload.nonce);
      } else {
        this.doThreeDSecureVerification(payload);
      }
    });

    setLoading(true);
  };

  doThreeDSecureVerification(hostedFieldsPayload: HostedFieldsTokenizePayload) {
    if (!this.threeDSecure) {
      throw new Error('3D secure is not initialized');
    }

    this.threeDSecure.verifyCard({
      amount: 1,
      nonce: hostedFieldsPayload.nonce,
      bin: hostedFieldsPayload.details.bin,
      onLookupComplete(data, next) {
        next();
      },
    }, this.onThreeDSecureVerificationEnd);
  }

  onThreeDSecureVerificationEnd = (error: ?BrainTreeError, payload: ThreeDSecureVerificationPayload) => {
    const { setLoading, setGenericError, intl } = this.props;

    if (error) {
      setLoading(false);
      setGenericError(intl.formatMessage(messages.unknownHostedFieldsError));
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });

      return;
    }

    this.saveNonceAndSavePaymentMethod(payload.nonce);
  };

  saveNonceAndSavePaymentMethod(nonce: string) {
    const { onFieldChange, onFormSubmit } = this.props;

    onFieldChange('nonce', nonce);
    onFormSubmit();
  }

  handleHostedFieldsError(error: BrainTreeError) {
    const { setLoading, setGenericError, intl } = this.props;
    const fieldsValidityErrors = ['HOSTED_FIELDS_FIELDS_EMPTY', 'HOSTED_FIELDS_FIELDS_INVALID'];

    setLoading(false);

    if (fieldsValidityErrors.indexOf(error.code) !== -1) {
      this.handleHostedFieldsValidityError();
    } else {
      setGenericError(intl.formatMessage(messages.unknownHostedFieldsError));
      logger.logError('Hosted fields tokenization error', error);
      analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
        eventLabel: `[${error.code}] ${error.message || ''}`,
      });
    }
  }

  handleHostedFieldsValidityError() {
    if (!this.hostedFields) {
      throw new Error('Hosted fields are not initialized');
    }

    const { intl, setErrorFields } = this.props;
    const errorFields = {},
      { fields } = this.hostedFields.getState();

    Object.keys(fields).forEach((id) => {
      const hasError = !fields[id].isValid;

      if (hasError) {
        errorFields[id] = fields[id].isEmpty ? intl.formatMessage(messages[`${id}Empty`]) : intl.formatMessage(messages[`${id}Invalid`]);
        if (fields[id].isEmpty) {
          analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
            eventLabel: intl.formatMessage(messages[`${id}Empty`]),
          });
        } else {
          analytics.trackEvent(events.PAYMENT_DETAILS_FORM_ERROR_BRAINTREE, {
            eventLabel: intl.formatMessage(messages[`${id}Invalid`]),
          });
        }
      }
    });

    setErrorFields(errorFields);
  }
}

export { BrainTreeCreditCardForm as PureBrainTreeCreditCardForm };

export default wrapPaymentForm(injectIntl(BrainTreeCreditCardForm));
