const externalUrls = {
  downloadDesktopClient: `${process.env.INSTALLER_URL}/download/client`,
  support: 'https://support.setapp.com/hc/%{locale}',
  supportMail: 'mailto:support@setapp.com',
  businessMail: 'mailto:business@setapp.com',
  createSupportRequest: 'https://support.setapp.com/hc/%{locale}/requests/new',
  braintreeProfile: 'https://www.braintreegateway.com/merchants/%{merchantId}/verified',
  braintreeBage: 'https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png',
  setappSite: 'https://setapp.com/',
  howItWorks: 'https://setapp.com/how-it-works',
  allApps: 'https://setapp.com/apps',
  pricing: 'https://setapp.com/pricing',
  about: 'https://setapp.com/about',
  blog: 'https://setapp.com/blog',
  blogHowTo: 'https://setapp.com/blog/how-to',
  howItWorksVideo: 'https://www.youtube.com/watch?v=V2f8HBJ0I5Q',
  termsOfUse: 'https://setapp.com/terms-of-use',
  podcast: 'https://setapp.com/podcast',
  privacy: 'https://setapp.com/privacy-policy',
  cookiePolicy: 'https://setapp.com/cookie-policy',
  facebook: 'https://www.facebook.com/setapp',
  x: 'https://twitter.com/setapp',
  instagram: 'https://www.instagram.com/setapp/',
  youtube: 'https://www.youtube.com/channel/UCZysXYIvNhkVnfiA5MMxD2g',
  discord: 'https://discord.gg/D7Kc9eQjx6',
  google: 'https://plus.google.com/112674418256024355452/posts',
  siteGiftCardsStore: `${process.env.SITE_URL}/gift-cards`,
  paddleSite: 'https://paddle.com/',
  teams: 'https://setapp.com/teams',
  referralDocs: 'https://support.setapp.com/hc/%{locale}/articles/115002153345-Earn-free-months-of-Setapp-with-the-Bring-a-friend-program',
  gracePeriodDocs: 'https://support.setapp.com/hc/en-us/articles/115004305069-Grace-period',
  setappMobilePlans: 'https://support.setapp.com/hc/en-us/articles/13689506166556-Setapp-Mobile-for-EU-and-subscription-plans-in-euros',
  macpawSite: 'https://macpaw.com/',
  macpawEmergencyNews: 'https://macpaw.foundation',
  howToSearchInSetappArticle: 'https://setapp.com/how-to/how-to-search-in-setapp',
  howToMasterSetappArticle: 'https://setapp.com/how-to/how-to-master-setapp',
  partnershipStore: 'https://setapp.com/gift-cards-partnership',
};

export default externalUrls;
