import React, { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { AiOfferPlanConfig } from 'components/pages/ai-offer/hooks/use-ai-offer-plans-config';

import { PRICE_PLAN_METADATA_MAPPER } from 'services/price-plans/price-plan-metadata-mapper';
import { isAiExpertPricePlan } from 'services/price-plans/utils';

import CheckIcon from './check-icon/check-icon';
import InfoButtonWrapper from './cell-with-info-wrapper/cell-with-info-wrapper';
import CellWithSoonLabelWrapper from './cell-with-soon-label-wrapper/cell-with-soon-label-wrapper';

const DEFAULT_TOKENS_LIMIT = 1600;

type Row = {
  title: ReactNode;
  getValue: (pricePlan: AiOfferPlanConfig) => ReactNode;
}

const tableRows: Array<Row> = [{
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.headerTitle"
      defaultMessage="Compare plans"
    />
  ),
  getValue: ({ pricePlan }) => PRICE_PLAN_METADATA_MAPPER[pricePlan.tierType]?.title,
}, {
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.creditsTitle"
      defaultMessage="Credits"
    />
  ),
  getValue: ({ pricePlan, aiCreditsLimit }) => (
    <InfoButtonWrapper
      tierType={pricePlan.tierType}
      tokensLimit={pricePlan.features?.allowedRequestSizeInTokens ?? DEFAULT_TOKENS_LIMIT}
    >
      {pricePlan.features?.vendorApiUsageLimitInCredits ?? aiCreditsLimit}
    </InfoButtonWrapper>
  ),
}, {
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.tokensTitle"
      defaultMessage="Token limit per message"
    />
  ),
  getValue: ({ pricePlan }) => (
    <InfoButtonWrapper
      tierType={pricePlan.tierType}
      tokensLimit={pricePlan.features?.allowedRequestSizeInTokens ?? DEFAULT_TOKENS_LIMIT}
    >
      {pricePlan.features?.allowedRequestSizeInTokens ?? DEFAULT_TOKENS_LIMIT}
    </InfoButtonWrapper>
  ),
}, {
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.devicesTitle"
      defaultMessage="Devices"
    />
  ),
  getValue: ({ pricePlan }) => (
    <>
      <FormattedMessage
        id="aiOffer.plansComparison.devices.mac"
        defaultMessage="{macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}}"
        values={{ macSeatsCount: pricePlan.features?.macSeatsCount }}
      />
      {pricePlan.features?.iosSeatsCount && (
        <FormattedMessage
          id="aiOffer.plansComparison.devices.ios"
          defaultMessage=", {iosSeatsCount} iOS devices"
          values={{ iosSeatsCount: pricePlan.features.iosSeatsCount }}
        />
      )}
    </>
  ),
}, {
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.iOsTitle"
      defaultMessage="iOS"
    />
  ),
  getValue: ({ pricePlan }) => (pricePlan.features?.iosSeatsCount ? <CheckIcon /> : null),
}, {
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.gptTitle"
      defaultMessage="GPT-3.5/4o"
    />
  ),
  getValue: () => <CheckIcon />,
}, {
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.whisperTitle"
      defaultMessage="Whisper"
    />
  ),
  getValue: () => <CheckIcon />,
}, {
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.aiAppsCollectionTitle"
      defaultMessage="Collection of AI apps for work and growth"
    />
  ),
  getValue: () => <CheckIcon />,
}, {
  title: (
    <FormattedMessage
      id="aiOffer.plansComparison.gptWithImagesTitle"
      defaultMessage="DALL-E 3"
    />
  ),
  getValue: () => <CheckIcon />,
}, {
  title: (
    <CellWithSoonLabelWrapper>
      <FormattedMessage
        id="aiOffer.plansComparison.claudeTitle"
        defaultMessage="Claude 3"
      />
    </CellWithSoonLabelWrapper>
  ),
  getValue: ({ pricePlan }) => (isAiExpertPricePlan(pricePlan.tierType) ? <CheckIcon /> : null),
}, {
  title: (
    <CellWithSoonLabelWrapper>
      <FormattedMessage
        id="aiOffer.plansComparison.stableDiffusionTitle"
        defaultMessage="Stable Diffusion"
      />
    </CellWithSoonLabelWrapper>
  ),
  getValue: ({ pricePlan }) => (isAiExpertPricePlan(pricePlan.tierType) ? <CheckIcon /> : null),
}];

export default tableRows;
