import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './activate-ios-app-title.scss';

type Props = {
  children: ReactNode;
  isActive?: boolean;
}

const ActivateIosAppTitle = ({ children, isActive }: Props) => {
  const titleClasses = classNames('activate-ios-app-title', {
    'activate-ios-app-title_active': isActive,
  });

  return (
    <div className={titleClasses}>
      {children}
    </div>);
};

export default ActivateIosAppTitle;
