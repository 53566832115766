import React, { FC } from 'react';
import isAfter from 'date-fns/isAfter';

import * as subscriptionStatuses from 'state/subscription/statuses';
import { POWER_USER_TRIAL } from 'state/price-plans/price-plans-types';

import PricePlanTitle from 'components/shared/price-plan-title/price-plan-title';
import { useSubscriptionContext } from 'components/pages/subscription/context/subscription-context';

import ExpirationDateRow from '../expiration-date-row/expiration-date-row';
import PlanRow from '../plan-row/plan-row';
import PriceRow from '../price-row/price-row';
import StatusRow from '../status-row/status-row';
import AiCreditsRow from '../ai-credits-row/ai-credits-row';
import SubscriptionEndingMessage from '../subscription-ending-message/subscription-ending-message';

const SubscriptionCancelled: FC = () => {
  const { taxAmount, pricePlan, subscription } = useSubscriptionContext();
  const { price, tierType, currency, paidMonth } = pricePlan;
  const { discount, expirationDate } = subscription;

  const currentTierType = subscription.pricePlan.tierType;

  // show trial status for canceled trial users
  const isSubscriptionExpired = expirationDate != null && isAfter(Date.now(), expirationDate * 1000);
  const status = currentTierType === POWER_USER_TRIAL && !isSubscriptionExpired
    ? subscriptionStatuses.TRIAL
    : subscriptionStatuses.CANCELLED;

  return (
    <>
      <PlanRow value={<PricePlanTitle tierType={tierType} />} />

      <PriceRow discount={discount} paidMonth={paidMonth} currency={currency} price={price} tax={taxAmount} />

      <StatusRow status={status} expirationDate={expirationDate} />

      {expirationDate && <ExpirationDateRow expirationDate={expirationDate} />}

      <AiCreditsRow />

      {expirationDate && <SubscriptionEndingMessage expirationDate={expirationDate} />}
    </>
  );
};

export default SubscriptionCancelled;
