import React, { ReactNode } from 'react';

import './base-permission-item.scss';

type Props = {
  icon: string;
  title: ReactNode;
  subTitle: ReactNode;
  qaLabel?: string;
}

const BasePermissionItem = ({
  icon, title, subTitle, qaLabel,
}: Props) => (
  <div className="permission-item" data-qa={qaLabel}>
    <img className="permission-item__icon" src={icon} alt="" />
    <div className="permission-item__content">
      <h6 className="mt-0 mb-1">{title}</h6>
      <p className="mb-0 text_color-secondary">{subTitle}</p>
    </div>
  </div>
);

export default BasePermissionItem;
