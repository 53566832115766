import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import './vat-note-row.scss';

const VatNoteRow: FC = () => (
  <p className="vat-note-row">
    <FormattedMessage
      id="subscriptionInfo.vatNote"
      defaultMessage="Tax may apply, depending on your country of residence."
    />
  </p>
);

export default VatNoteRow;
