import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';

import desktopAppHelper from 'utils/desktop-app-helper';
import logger from 'utils/logger';
import analytics, { events } from 'utils/analytics';
import request from 'utils/request';
import { apiURL } from 'config/api';
import urls from 'config/urls';

import { getUser, isAppLandingFlow } from 'state/root-reducer';
import SuccessfulRegistrationDefault from './successful-registration-default/successful-registration-default';
import SuccessfulRegistrationDesktopCcr from './successful-registration-desktop-ccr/successful-registration-desktop-ccr';
import SuccessfulRegistrationFromDesktop
  from './successful-registration-from-desktop-app/successful-registration-from-desktop-app';
import SuccessfulRegistrationMobile from './successful-registration-mobile/successful-registration-mobile';


import './successful-registration-page.scss';

const mapStateToProps = (state) => {
  const user = getUser(state);

  return {
    userEmail: user.email,
    flowParams: user.registrationFlowParams,
    isDesktopCcRequired: user.desktopCcRequired,
    isTrialAvailable: Boolean(user.trialPeriodLength),
    isAppLandingFlow: isAppLandingFlow(state),
  };
};

const connector = connect(mapStateToProps);

type Props = {
  history: {
    push: RouteComponentProps['history']['push'];
    replace: RouteComponentProps['history']['replace'];
  };
} & ConnectedProps<typeof connector>;

type State = {
  isDesktopOpened: boolean;
  isTokenRequestProcessing: boolean;
  isFromDesktop: boolean;
  showDefault: boolean;
};

class SuccessfulRegistrationPage extends PureComponent<Props, State> {
  static defaultProps = {
    flowParams: {},
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isDesktopOpened: false,
      isTokenRequestProcessing: false,
      isFromDesktop: desktopAppHelper.hasOpenedCurrentSession(),
      showDefault: false,
    };
  }

  componentDidMount() {
    const {
      flowParams,
      isAppLandingFlow,
      history,
    } = this.props;

    if (isAppLandingFlow) {
      return request.get(apiURL.appInfo + flowParams.appId)
        .then(() => history.replace(urls.successfulRegistrationSingleApp))
        .catch(() => this.setState({ showDefault: true }));
    }

    return Promise.resolve();
  }

  render() {
    return (
      <div className="successful-registration" data-qa="successful-registration-page">
        {this.getContentVariant()}
      </div>
    );
  }

  getContentVariant() {
    const { isAppLandingFlow, isDesktopCcRequired } = this.props;
    const {
      isFromDesktop,
      isTokenRequestProcessing,
      isDesktopOpened,
      showDefault,
    } = this.state;

    if (isAppLandingFlow && !showDefault) {
      return <AnimatedLogo animate />;
    }
    if (isFromDesktop) {
      return (
        <SuccessfulRegistrationFromDesktop
          isTokenRequestProcessing={isTokenRequestProcessing}
          isDesktopOpened={isDesktopOpened}
          onOpenSetappClick={this.onOpenSetappClick}
        />);
    }
    if (isMobile) {
      return <SuccessfulRegistrationMobile onGoToMyAccountClick={this.redirectToAccountPage} />;
    }

    if (isDesktopCcRequired) {
      return <SuccessfulRegistrationDesktopCcr />;
    }

    return (
      <SuccessfulRegistrationDefault
        onDirectDownloadClick={this.onDirectDownloadClick}
        onLaunchSetappClick={this.handleLaunchSetappClick}
      />
    );
  }

  onDirectDownloadClick = () => {
    analytics.trackEvent(events.WELCOME_PAGE_DIRECT_DOWNLOAD_CLICK);
  };

  redirectToAccountPage = () => {
    const { history } = this.props;
    history.push(urls.account);
  }

  onOpenSetappClick = () => {
    const { isDesktopOpened } = this.state;

    if (isDesktopOpened) {
      this.redirectToAccountPage();

      return;
    }

    this.openDesktopClient();
  };

  openDesktopClient() {
    const { userEmail } = this.props;

    this.setState({ isTokenRequestProcessing: true });

    return desktopAppHelper.openWithAuth({
      email: userEmail,
      isSignUp: true,
    })
      .then(() => {
        this.setState({
          isTokenRequestProcessing: false,
          isDesktopOpened: true,
        });

        desktopAppHelper.removeSession();

        analytics.trackEvent(events.WELCOME_PAGE_OPEN_DESKTOP);
      })
      .catch(() => {
        // TODO: maybe remove this if it breaks flow after the error and page reloading
        desktopAppHelper.removeSession();

        this.setState({ isTokenRequestProcessing: false });
      });
  }

  handleLaunchSetappClick = () => desktopAppHelper.openDesktopApp()
    .catch((error) => {
      logger.logWarn('Couldn\'t launch Setapp desktop app', { error });
    });
}

export default connector(SuccessfulRegistrationPage);
