import React, { useState, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';

import logger from 'utils/logger';

import PRICE_PLAN_PERIOD_MAPPER from 'services/price-plans/price-plan-period-mapper';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { ActivateOldSuspendedSubscriptionAction } from 'state/subscription/subscription-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import FullscreenLayoutWide from 'components/layout/fullscreen-layout/fullscreen-layout-wide';

import ButtonBack from 'components/shared/button-back/button-back';
import DefaultError from 'components/shared/default-error/default-error';
import PricePlansRow from 'components/shared/price-plans-row/price-plans-row';
import withPaymentWaiting from 'components/shared/with-payment-waiting/with-payment-waiting';

import CheckoutPricePlanStep from '../checkout-price-plan-step/checkout-price-plan-step';

type Step = 'choosePlanStep'
  | 'checkoutPricePlanStep';

const BACK_STEP_MAPPING: {[key in Step]: Step | null} = {
  choosePlanStep: null,
  checkoutPricePlanStep: 'choosePlanStep',
};

type Props = {
  primarySubscription: Subscription;
  featuredPricePlans: PricePlan[];
  activateOldSuspendedSubscription: ActivateOldSuspendedSubscriptionAction;
  showDangerNotification: (message: ReactElement) => void;
};

const ActivateOldSuspendedUserFlow = (props: Props) => {
  const {
    primarySubscription,
    featuredPricePlans,
    activateOldSuspendedSubscription,
    showDangerNotification,
  } = props;

  const history = useHistory();

  const [step, setStep] = useState<Step>('choosePlanStep');
  const [isProcessing, setIsProcessing] = useState(false);
  const [switchedToAnnual, setSwitchedToAnnual] = useState(false);
  const [selectedPricePlan, setSelectedPricePlan] = useState<PricePlan | null>(null);
  // force old user to update payment method
  const [isPaymentMethodCreated, setIsPaymentMethodCreated] = useState(false);

  const handleSelectPricePlan = (selectedPlan: PricePlan) => {
    setSelectedPricePlan(selectedPlan);
    setStep('checkoutPricePlanStep');
  };

  const handleChangePlanPeriodOnCheckoutStep = () => {
    const oppositePricePlanTierType = PRICE_PLAN_PERIOD_MAPPER[selectedPricePlan!.tierType];
    const oppositePricePlan = featuredPricePlans.find((plan) => plan.tierType === oppositePricePlanTierType);

    if (!oppositePricePlan) {
      throw new Error("Can't find new price plan with correct tierType");
    }

    setSelectedPricePlan(oppositePricePlan);
    setSwitchedToAnnual(!switchedToAnnual);
  };

  const handleCheckoutPricePlan = async () => {
    setIsProcessing(true);

    try {
      await activateOldSuspendedSubscription(primarySubscription, selectedPricePlan!.id);
    } catch (err) {
      setIsProcessing(false);

      logger.logError(
        'Activate old suspended user flow: Error during subscription activation',
        err,
      );

      showDangerNotification(<DefaultError />);
      history.push(urls.subscription);
    }
  };

  const handlePaymentDetailsSaved = async () => {
    setIsPaymentMethodCreated(true);
    await handleCheckoutPricePlan();
  };

  const renderBackButton = () => {
    const backStep = BACK_STEP_MAPPING[step];

    if (!backStep) {
      return null;
    }

    return (
      <>
        <div className="mb-5">
          <ButtonBack
            disabled={isProcessing}
            onClick={() => setStep(backStep)}
          />
        </div>
      </>
    );
  };

  const renderStep = () => {
    switch (step) {
      case 'choosePlanStep':
        return (
          <PricePlansRow
            switchedToAnnual={switchedToAnnual}
            onChangePlanPeriod={() => setSwitchedToAnnual(!switchedToAnnual)}
            onSelectPlanClick={handleSelectPricePlan}
          />
        );
      case 'checkoutPricePlanStep':
        return (
          <CheckoutPricePlanStep
            isPaymentMethodCreated={isPaymentMethodCreated}
            isProcessing={isProcessing}
            switchedToAnnual={switchedToAnnual}
            pricePlan={selectedPricePlan!}
            gracePeriod={primarySubscription.gracePeriod}
            onChangePlanPeriod={handleChangePlanPeriodOnCheckoutStep}
            onCheckoutPricePlan={handleCheckoutPricePlan}
            onPaymentDetailsSaved={handlePaymentDetailsSaved}
          />
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (step === 'choosePlanStep') {
      return (
        <FullscreenLayoutWide withLogo withFooter>
          {renderStep()}
        </FullscreenLayoutWide>
      );
    }

    return (
      <FullscreenLayout withLogo>
        <FullscreenLayout.PrimaryContent wideContainer>
          {renderBackButton()}
          {renderStep()}
        </FullscreenLayout.PrimaryContent>
      </FullscreenLayout>
    );
  };

  return renderContent();
};

export { ActivateOldSuspendedUserFlow as PureActivateOldSuspendedUserFlow };

export default withPaymentWaiting(ActivateOldSuspendedUserFlow);
