import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';
import useReactivateSubscription from 'components/hooks/use-reactivate-subscription/use-reactivate-subscription';
import VatNoteRow from '../../subscription-info/vat-note-row/vat-note-row';

const RenewSubscriptionAfterCancel: FC = () => {
  const { onReactivateButtonClick } = useReactivateSubscription();

  return (
    <>
      <VatNoteRow />
      <AccountControlButtons>
        <Button size="lg" onClick={onReactivateButtonClick}>
          <FormattedMessage
            id="renewSubscriptionAfterCancel.CTA"
            defaultMessage="Renew subscription"
          />
        </Button>
      </AccountControlButtons>
    </>
  );
};

export default RenewSubscriptionAfterCancel;
