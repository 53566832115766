import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';
import { FormattedPrice } from 'components/shared/formatter/formatter';
import DefaultError from 'components/shared/default-error/default-error';

import logger from 'utils/logger';
import { reactivateSeat } from 'state/subscription/subscription-actions';
import { getDevices, getSubscriptions } from 'state/root-reducer';

import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import type { Device } from 'state/devices/devices-initial-state';
import type { Subscription } from 'state/subscription/subscription-initial-state';


const mapStateToProps = (state) => ({
  isDeviceProcessing: getDevices(state).isLoading,
  isSubscriptionProcessing: getSubscriptions(state).isLoading,
});

const mapActionsToProps = {
  reactivateSeat,
  showSuccessNotification,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = {
  onHide: () => void;
  onExited: () => void;
  show: boolean;
  device: Device | null;
  subscription: Subscription;
} & ConnectedProps<typeof connector>;

class ReactivateSeatModal extends PureComponent<Props> {
  render() {
    const {
      show,
      onHide,
      onExited,
      isDeviceProcessing,
      isSubscriptionProcessing,
      device,
      subscription,
    } = this.props;
    const { price, currency } = subscription.pricePlan;
    const isProcessing = isDeviceProcessing || isSubscriptionProcessing;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={onExited}
        title={
          <FormattedMessage
            id="devicesPage.reactivateSeatModal.title"
            defaultMessage="Reactivating device"
          />
        }
      >
        <div className="reactivate-seat-modal__content">
          <div className="mb-8 reactivate-seat-modal__description">
            {device ? (
              <FormattedMessage
                id="devicesPage.reactivateSeatModal.description"
                defaultMessage="{deviceName} will be reactivated. You will see {price} added to your bill at the next billing cycle."
                values={{
                  price: <FormattedPrice price={price} currency={currency} />,
                  deviceName: <span className="h6">{device.name}</span>,
                }}
              />
            ) : (
              <FormattedMessage
                id="devicesPage.reactivateSeatModal.emptySeat.description"
                defaultMessage="This device will be reactivated. You will see {price} added to your bill at the next billing cycle."
                values={{
                  price: <FormattedPrice price={price} currency={currency} />,
                }}
              />
            )}
          </div>

          <Button
            block
            onClick={this.onReactivateClick}
            disabled={isProcessing || !show}
            data-qa="reactivate-seat-confirm-btn"
            size="lg"
          >
            <FormattedMessage
              id="devicesPage.reactivateSeatModal.btnReactivate"
              defaultMessage="Reactivate device"
            />
          </Button>
          <Button
            variant="secondary-outline"
            block
            onClick={onHide}
            disabled={isProcessing || !show}
            className="mt-3"
            size="lg"
          >
            <FormattedMessage
              id="devicesPage.reactivateSeatModal.emptySeat.btnCancel"
              defaultMessage="Cancel"
            />
          </Button>
        </div>
      </Modal>
    );
  }

  onReactivateClick = () => {
    const { subscription, device, reactivateSeat } = this.props;

    return reactivateSeat({
      subscriptionId: subscription.id,
      deviceId: device?.id,
    })
      .then(this.onReactivateSeatSuccess)
      .catch(this.onReactivateSeatFailed);
  };

  onReactivateSeatSuccess = () => {
    const { onHide } = this.props;
    const { showSuccessNotification } = this.props;

    showSuccessNotification(
      <FormattedMessage
        id="devicesPage.messages.reactivationSuccess"
        defaultMessage="Device reactivated"
      />,
      { withIcon: true }
    );

    onHide();
  };

  onReactivateSeatFailed = (error) => {
    const { showDangerNotification } = this.props;

    logger.logError('Couldn\'t reactivate seat', error);

    showDangerNotification(<DefaultError />);
  };
}

export default connector(ReactivateSeatModal);
