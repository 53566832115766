import React from 'react';

import type { OAuthScope } from 'utils/oauth';
import PermissionAiItem from './permission-ai';
import PermissionAccessItem from './permission-access';
import PermissionAppDataItem from './permission-app-data';
import PermissionUserDataItem from './permission-user-data/permission-user-data';

import './permission-list.scss';

type Props = {
  scope: Array<OAuthScope>;
  appName: string;
  iconUrl: string;
}

const PermissionList = ({ scope, appName, iconUrl }: Props) => {
  const hasAppDataPermission = scope.includes('application.access');
  const hasUserNamePermission = scope.includes('userinfo.name');
  const hasUserEmailPermission = scope.includes('userinfo.email');
  const hasAiPermission = scope.includes('ai.openai');

  return (
    <div className="permission-list">
      <PermissionAccessItem icon={iconUrl} appName={appName} />

      {(hasUserNamePermission || hasUserEmailPermission) && (
        <PermissionUserDataItem
          appName={appName}
          hasNamePermission={hasUserNamePermission}
          hasEmailPermission={hasUserEmailPermission}
        />
      )}

      {hasAppDataPermission && <PermissionAppDataItem appName={appName} />}
      {hasAiPermission && <PermissionAiItem appName={appName} />}
    </div>
  );
};

export default PermissionList;
