// @flow

type TableFields = {
  [string]: string | number,
};

const INSERT_API_ENDPOINT = 'https://stats.setapp.com/insert';

class DataHubClient {
  sendCancelSubscriptionReason(reason: string, userId: number) {
    return this.insertData('cancel_subscription_reason', {
      text: reason,
      'setapp_uid': userId,
    });
  }

  insertData(tableName: string, data: TableFields) {
    const payload = {
      _table: tableName,
      _data: data,
    };

    return fetch(INSERT_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(payload),
      // TODO: uncomment when DataHub team adds support for CORS preflight requests
      // headers: {
      //   'Content-Type': 'application/json',
      // },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Request failed');
        }
      });
  }
}

export default DataHubClient;
