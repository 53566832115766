import type { UserState } from './user-initial-state';

export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_PAYMENT_INFO = 'SET_USER_PAYMENT_INFO';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const LOGOUT = 'LOGOUT_USER';
export const LOGIN = 'LOGIN_USER';
export const REQUEST = 'USER_REQUEST';
export const REQUEST_ERROR = 'USER_REQUEST_ERROR';
export const REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';
export const SET_SIGNUP_GIFT_CARD_CODE = 'SET_SIGNUP_GIFT_CARD_CODE';
export const SET_SIGNUP_PROMO_CODE = 'SET_SIGNUP_PROMO_CODE';

export type LoginAction = {
  type: 'LOGIN_USER';
};

export type LogOutAction = {
  type: 'LOGOUT_USER';
};

export type SetUserInfoAction = {
  type: 'SET_USER_INFO';
  payload: Partial<UserState>;
};

export type SetUserPaymentInfoRequirement = {
  type: 'SET_USER_PAYMENT_INFO';
  payload: boolean;
}

export type UserRequestAction = {
  type: 'USER_REQUEST';
};

export type UserRequestSuccessAction = {
  type: 'USER_REQUEST_SUCCESS';
};

export type UserRequestErrorAction = {
  type: 'USER_REQUEST_ERROR';
};

export type DeleteAccountAction = {
  type: 'DELETE_ACCOUNT';
};

export type SetSignupGiftCardCodeAction = {
  type: 'SET_SIGNUP_GIFT_CARD_CODE';
  payload: string;
};

export type SetSignupPromoCodeAction = {
  type: 'SET_SIGNUP_PROMO_CODE';
  payload: string;
};

export type UserAction =
  | LoginAction
  | LogOutAction
  | SetUserInfoAction
  | SetUserPaymentInfoRequirement
  | UserRequestAction
  | UserRequestSuccessAction
  | UserRequestErrorAction
  | DeleteAccountAction
  | SetSignupGiftCardCodeAction
  | SetSignupPromoCodeAction;
