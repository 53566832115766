import React, { useMemo } from 'react';
import type { MouseEvent } from 'react';
import { withRouter } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

import { SOCIAL_PROVIDERS } from 'config/social'; // TODO: pass providers via props
import OauthStateFactory from 'utils/oauth-state/oauth-state-factory';
import OauthStateManager from 'utils/oauth-state/oauth-state-manager';
import type { CommonSignupParams } from 'state/user/user-actions';
import SocialAuthButton from '../social-auth-button/social-auth-button';
import type { ButtonSize } from '../social-auth-button/social-auth-button';


import './social-auth-buttons.scss';

const createOauthState = (location, additionalState) => {
  const oauthState = OauthStateFactory.create(location, additionalState).toString();
  OauthStateManager.save(oauthState);

  return oauthState;
};

type Props = RouteComponentProps & {
  onButtonClick?: (event: MouseEvent<HTMLAnchorElement>) => unknown;
  className: string;
  signupParams?: CommonSignupParams;
  size?: ButtonSize;
}

const SocialAuthButtons = (props: Props) => {
  const {
    size,
    className,
    onButtonClick,
    location,
    signupParams,
  } = props;

  const oauthState = useMemo(
    () => createOauthState(location, { signupParams }),
    [location, signupParams],
  );

  return (
    <div className={`social-auth-buttons ${className}`}>
      {(SOCIAL_PROVIDERS.map((provider) => (
          <div className="social-auth-buttons-item" key={provider}>
            <SocialAuthButton
              size={size}
              providerName={provider}
              onClick={onButtonClick}
              oauthState={oauthState}
            />
          </div>
        )))}
    </div>
  );
};

export default withRouter(SocialAuthButtons);
