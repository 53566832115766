import React from 'react';
import { FormattedMessage } from 'react-intl';

const DefaultError = () => (
  <FormattedMessage
    id="defaultError"
    defaultMessage="An unknown error has occurred. Please try again later or contact Setapp support team"
  />
);

export default DefaultError;
