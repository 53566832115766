import request from 'utils/request';
import { apiURL } from 'config/api';

// TODO: move subscription types here
import type { Subscription } from 'state/subscription/subscription-initial-state';

export type SubscriptionActivationOrder = {
  primaryPricePlanId: number;
  seats?: {
    pricePlanId: number;
    // TODO: rename to quantity
    count: number;
  };
};

export type CancelAdditionalSeatsOrder = {
  subscriptions: Array<number>;
  devices?: Array<number>;
};

export type AdditionalSeatsOrder = {
  pricePlanId: number;
  quantity?: number;
};

export default class SubscriptionsApi {
  activateSubscription(subscriptionsOrder: SubscriptionActivationOrder): Promise<Array<Subscription>> {
    return request.post(apiURL.activateSubscription, {
      body: subscriptionsOrder,
    });
  }

  cancelAllSubscriptions(): Promise<Array<Subscription>> {
    return request.post(apiURL.cancelAllSubscriptions);
  }

  resumeAllSubscriptions(): Promise<Array<Subscription>> {
    return request.post(apiURL.resumeAllSubscriptions);
  }

  orderAdditionalSeats(order: AdditionalSeatsOrder): Promise<Array<Subscription>> {
    const { pricePlanId, quantity = 1 } = order;

    return request.post(apiURL.orderSeatsSubscriptions, {
      body: {
        pricePlanId,
        count: quantity,
      },
    });
  }

  cancelAdditionalSeats(order: CancelAdditionalSeatsOrder): Promise<Array<Subscription>> {
    return request.post(apiURL.cancelSeatsSubscriptions, {
      body: order,
    });
  }
}
