import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import ReCaptcha from 'react-google-recaptcha';

import type { InjectedIntlProps } from 'react-intl';
import type { ReactNode, ElementRef, SyntheticEvent } from 'react';
import OuterForm from 'components/shared/outer-form/outer-form';
import CurrentPasswordField from 'components/shared/form/current-password-field/current-password-field';
import CaptchaField from 'components/shared/form/captcha-field/captcha-field';

type Props = {
  provider: string;
  isProcessing: boolean;
  password: string;
  email: string;
  passwordError?: ReactNode;
  formError?: ReactNode;
  captchaError?: ReactNode;
  onFieldChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  onCaptchaChange: (captcha: string | null) => void;
  onSubmit: (e: SyntheticEvent<HTMLFormElement>) => void;
  showCaptcha: boolean;
  setCaptchaRef: (captcha: ElementRef<typeof ReCaptcha>) => void;
} & InjectedIntlProps;

const messages = defineMessages({
  title: {
    id: 'socialAuth.passwordForm.title',
    defaultMessage: 'Enter your Setapp password.',
  },
  subTitle: {
    id: 'socialAuth.passwordForm.subTitle',
    defaultMessage: 'There’s already a Setapp account with this email. Just enter your password to join it with {provider}.',
  },
  submitBtnTitle: {
    id: 'socialAuth.passwordForm.submitBtnTitle',
    defaultMessage: 'Continue',
  },
  label: {
    id: 'socialAuth.passwordForm.label',
    defaultMessage: 'Password',
  },
  placeholder: {
    id: 'socialAuth.passwordForm.placeholder',
    defaultMessage: 'Your password',
  },
});

const SocialAuthPasswordForm = (props: Props) => {
  const {
    provider, isProcessing, onSubmit, formError, passwordError, onFieldChange, email, password, intl,
  } = props;
  const {
    showCaptcha, setCaptchaRef, captchaError, onCaptchaChange,
  } = props;

  return (
    <OuterForm
      title={intl.formatMessage(messages.title)}
      subTitle={intl.formatMessage(messages.subTitle, { provider })}
      submitBtnCaption={intl.formatMessage(messages.submitBtnTitle)}
      onSubmit={onSubmit}
      errorMessage={formError}
      isSubmitProcessing={isProcessing}
      qaLabel="socialAuthPasswordForm"
    >
      <CurrentPasswordField
        id="password"
        type="password"
        name="password"
        email={email}
        label={intl.formatMessage(messages.label)}
        placeholder={intl.formatMessage(messages.placeholder)}
        helpText={passwordError}
        invalid={Boolean(passwordError)}
        value={password}
        onChange={onFieldChange}
      />

      {showCaptcha && (
        <CaptchaField
          onChange={onCaptchaChange}
          setCaptchaRef={setCaptchaRef}
          errorMessage={captchaError}
        />
      )}
    </OuterForm>
  );
};

SocialAuthPasswordForm.defaultProps = {
  passwordError: '',
  formError: '',
  captchaError: '',
};

export { SocialAuthPasswordForm as PureSocialAuthPasswordForm };

export default injectIntl(SocialAuthPasswordForm);
