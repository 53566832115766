import type { Action } from 'state/state-types';
import * as redeemCodeActionTypes from './redeem-code-action-types';
import initialState, { RedeemCodeState } from './redeem-code-initial-state';

const redeemCode = (state: RedeemCodeState = initialState, action: Action) => {
  switch (action.type) {
    case redeemCodeActionTypes.REDEEM_CODE_REQUEST: {
      return { ...state, isLoading: true };
    }

    case redeemCodeActionTypes.REDEEM_CODE_REQUEST_SUCCESS: {
      if (!action.payload) {
        return { ...state, isLoading: false };
      }

      return { ...state, ...action.payload, isLoading: false };
    }

    case redeemCodeActionTypes.REDEEM_CODE_REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default redeemCode;

export const isGiftCardRedeemCode = (state: RedeemCodeState) => Boolean(state.giftCard) || false;
export const isPromoCodeRedeemCode = (state: RedeemCodeState) => Boolean(state.promoCode) || false;

