// @flow

import type { Location } from 'react-router-dom';
import queryString from 'query-string';

import OauthStateFactory from 'utils/oauth-state/oauth-state-factory';
import OauthStateManager from 'utils/oauth-state/oauth-state-manager';
import { DEPRECATED_SOCIAL_PROVIDER_NAMES } from 'config/social';
import googleClient from './social-clients/google-client';
import appleClient from './social-clients/apple-client';

const socialAuthClients = {
  google: googleClient,
  apple: appleClient,
};

export default class SocialAuthInitiator {
  // TODO: remove turnOffDeprecatedProvider param after removing Facebook provider
  initiateIfProviderInURL(location: Location) {
    const { authService } = queryString.parse(location.search);
    const socialAuthClient = socialAuthClients[authService];

    const isDeprecatedProvider = authService in DEPRECATED_SOCIAL_PROVIDER_NAMES;

    if (!socialAuthClient || isDeprecatedProvider) {
      return false;
    }

    const oauthState = OauthStateFactory.create(location);
    OauthStateManager.save(oauthState.toString());

    const authURL = socialAuthClient.getAuthURL(oauthState.toString());

    window.location.assign(authURL);

    return true;
  }
}
