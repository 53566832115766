// @flow
/* eslint-disable no-underscore-dangle */


import logger from 'utils/logger';
import Gtm from './gtm';
import DataHubClient from './data-hub-client';
import * as events from './events';

type PreparePayload = (?{}, ?string) => {};

type Options = {|
  preparePayload?: PreparePayload,
  cid?: string,
|};

type ExtraEventPayload = {
  eventLabel?: ?string,
  eventValue?: number,
  ...
};

export class Analytics {
  _gtm: Gtm;

  dataHubClient: DataHubClient;

  _isInitialized: boolean = false;

  preparePayload: ?PreparePayload;

  static overwriteCID(cid: string) {
    Gtm.saveCID(cid);
  }

  constructor() {
    this.dataHubClient = new DataHubClient();
  }

  initialize({ preparePayload, cid }: Options = {}) {
    if (cid) {
      Analytics.overwriteCID(cid);
    }
    this.preparePayload = preparePayload;
    this._gtm = Gtm.initialize();
    this._isInitialized = true;
  }

  trackEvent(event: string, payload?: ExtraEventPayload) {
    if (!this._isInitialized) {
      return;
    }

    if (Object.values(events).indexOf(event) === -1) {
      logger.logError(`Analytics: Unknown event '${event}'`);

      return;
    }

    const eventPayload = this.preparePayload ? this.preparePayload(payload, event) : payload;

    this._gtm.trackEvent(event, eventPayload);
  }

  trackPageView() {
    if (!this._isInitialized) {
      return;
    }

    const payload = this.preparePayload ? this.preparePayload() : {};

    this._gtm.trackPageView(payload);
  }

  trackExperimentMatch(experiment: string, variant: string) {
    if (!this._isInitialized) {
      return;
    }

    this._gtm.trackExperimentMatch(experiment, variant);
  }

  sendCancelSubscriptionReason(reason: string, userId: number) {
    if (!this._isInitialized) {
      return Promise.resolve();
    }

    return this.dataHubClient.sendCancelSubscriptionReason(reason, userId)
      .catch((error) => {
        logger.logWarn('Failed to send subscription cancellation reason', { error: error.message });
      });
  }

  /**
   * @private
   */
  static _getReferrer() {
    return document.referrer;
  }
}

const analytics = new Analytics();
export default analytics;

export { events };
