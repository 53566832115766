import request from 'utils/request';
import { apiURL } from 'config/api';
import type { Dispatch } from '../state-types';
import * as redeemCodeActionTypes from './redeem-code-action-types';

// eslint-disable-next-line import/prefer-default-export
export const fetchRedeemCode = (redeemCode: string) => (dispatch: Dispatch) => {
  dispatch({
    type: redeemCodeActionTypes.REDEEM_CODE_REQUEST,
  });

  return request.get(apiURL.checkRedeemCode(redeemCode))
    .then((data) => {
      dispatch({
        type: redeemCodeActionTypes.REDEEM_CODE_REQUEST_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: redeemCodeActionTypes.REDEEM_CODE_REQUEST_ERROR,
        error: true,
        payload: error,
      });

      return Promise.reject(error);
    });
};
