import { isAvailableSilently } from '@setapp/desktop-app-helper';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { COOKIES_ROOT_DOMAIN } from '../config/app';
import auth from './auth';


const FROM_DESKTOP_COOKIE_NAME = 'from_desktop_client';
export const desktopLoginUrl = 'setapp://system/sign_in';

type OpenWithAuthArguments = {
  email?:string;
  isSignUp?: boolean;
};

const DesktopAppHelper = {
  /**
   * Opens desktop client with cross auth token
   */
  openWithAuth({
    email = '',
    isSignUp = false,
  }: OpenWithAuthArguments) {
    return auth.requestCrossAuthToken()
      .then((resp) => {
        const query = queryString.stringify({
          'access_token': resp.accessToken,
          'user_email': email,
          'is_sign_up': Number(isSignUp),
        });
        // Update location only after all 'then' calls completed
        setTimeout(() => {
          window.location.assign(`${desktopLoginUrl}?${query}`);
        }, 0);
      });
  },

  /**
   * Returns Setapp desktop link with auth token for autologin to desktop with Setapp web
   */
  getDesktopAuthUrl(email = ''): Promise<string> {
    return auth.requestCrossAuthToken()
      .then((resp) => {
        const query = queryString.stringify({
          'access_token': resp.accessToken,
          'user_email': email,
        });

        return `${desktopLoginUrl}?${query}`;
      });
  },

  /**
   * Opens Setapp desktop client on the app details screen
   * or on the main screen if the appId is not provided.
   *
   * The default appId is null because of the requirement from the desktop client:
   * there won't be any action when opening setapp://launch instead of setapp://launch/null
   */
  openDesktopApp({ appId = null }: {appId?: number | null} = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      isAvailableSilently({
        onSuccess: () => {
          window.location.assign(`setapp://launch/${appId}`);

          resolve();
        },
        onError: reject,
      });
    });
  },

  /**
   * Checks if current session is initiated by the desktop app
   * @return {Boolean}
   */
  hasOpenedCurrentSession(): boolean {
    return Boolean(Cookies.get(FROM_DESKTOP_COOKIE_NAME));
  },

  /**
   * Removes cookies identifying opening from desktop app
   */
  removeSession(): void {
    Cookies.remove(FROM_DESKTOP_COOKIE_NAME, { domain: COOKIES_ROOT_DOMAIN });
  },
};

export default DesktopAppHelper;
