import React, { SyntheticEvent } from 'react';
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl';
import { FormField } from '@setapp/ui-kit';

import OuterForm from 'components/shared/outer-form/outer-form';

const messages = defineMessages({
  title: {
    id: 'socialAuth.emailForm.title',
    defaultMessage: 'Looks like your {provider} account doesn\'t have an email.',
  },
  subTitle: {
    id: 'socialAuth.emailForm.subTitle',
    defaultMessage: 'We need an email to finish your profile, so please enter it below.',
  },
  submitBtnTitle: {
    id: 'socialAuth.emailForm.submitBtnTitle',
    defaultMessage: 'Continue',
  },
  label: {
    id: 'socialAuth.emailForm.label',
    defaultMessage: 'Email',
  },
  placeholder: {
    id: 'socialAuth.emailForm.placeholder',
    defaultMessage: 'Your email',
  },
});

type Props = {
  email?: string;
  emailError?: string;
  formError?: string;
  isProcessing?: boolean;
  provider: string;
  onFieldChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  onSubmit: (e: SyntheticEvent<HTMLFormElement>) => void;
} & InjectedIntlProps;

const SocialAuthEmailForm = (props: Props) => {
  const {
    provider, isProcessing, onSubmit, formError, emailError, onFieldChange, email, intl,
  } = props;

  return (
    <OuterForm
      title={intl.formatMessage(messages.title, { provider })}
      subTitle={intl.formatMessage(messages.subTitle)}
      submitBtnCaption={intl.formatMessage(messages.submitBtnTitle)}
      onSubmit={onSubmit}
      errorMessage={formError}
      isSubmitProcessing={isProcessing}
      qaLabel="socialAuthEmailForm"
    >
      <FormField
        id="email"
        type="email"
        name="email"
        label={intl.formatMessage(messages.label)}
        placeholder={intl.formatMessage(messages.placeholder)}
        invalid={Boolean(emailError)}
        helpText={emailError}
        onChange={onFieldChange}
        value={email}
        autoFocus
      />
    </OuterForm>
  );
};

SocialAuthEmailForm.defaultProps = {
  isProcessing: false,
  email: '',
  emailError: '',
  formError: '',
};

export { SocialAuthEmailForm };

export default injectIntl(SocialAuthEmailForm);
