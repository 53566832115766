import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';
import DefaultError from 'components/shared/default-error/default-error';

import type { Device } from 'state/devices/devices-initial-state';
import { getDevices } from 'state/root-reducer';
import { deactivateAllIosDevices, deactivateDevice } from 'state/devices/devices-reducer';

import { showSuccessNotification, showDangerNotification } from 'state/notifier/notifier-reducer';

import logger from 'utils/logger';

export type Props = {
  device: Device | 'all-ios-devices';
  show: boolean;
  onHide: () => void;
  onExited?: () => void;
  onSuccessfulDisconnect?: () => void;
};

const DisconnectDeviceModal = ({
  device,
  show,
  onHide,
  onExited,
  onSuccessfulDisconnect
}: Props) => {
  const dispatch = useDispatch();

  const isDeviceProcessing = useSelector(getDevices).isLoading;

  const onDeactivateDeviceSuccess = () => {
    dispatch(showSuccessNotification(
      <FormattedMessage
        id="devicesPage.messages.deviceDisconnectionSuccess"
        defaultMessage="Device disconnected"
      />,
      { withIcon: true }
    ));
    onHide();
    onSuccessfulDisconnect?.();
  };

  const onDeactivateAllIosDevicesSuccess = () => {
    dispatch(showSuccessNotification(
      <FormattedMessage
        id="devicesPage.messages.iosDevicesDisconnectionSuccess"
        defaultMessage="All iOS devices have been successfully disconnected"
      />,
      { withIcon: true }
    ));
    onHide();
    onSuccessfulDisconnect?.();
  };

  const onDeactivateDeviceFailed = (error) => {
    logger.logError('Couldn\'t deactivate device', error);
    dispatch(showDangerNotification(<DefaultError />, { withIcon: true }));
    onHide();
  };

  const handleDeactivateClick = async () => {
    try {
      if (device === 'all-ios-devices') {
        await dispatch(deactivateAllIosDevices());
        onDeactivateAllIosDevicesSuccess();
      } else {
        await dispatch(deactivateDevice(device.id));
        onDeactivateDeviceSuccess();
      }
    } catch (error) {
      onDeactivateDeviceFailed(error);
    }
  };

  const title = device === 'all-ios-devices' ? (
    <FormattedMessage
      id="devicesPage.disconnectDeviceModal.titleAllIos"
      defaultMessage="Disconnecting all iOS devices"
    />
  ) : (
    <FormattedMessage
      id="devicesPage.disconnectDeviceModal.title"
      defaultMessage="Disconnecting device"
    />
  );

  const description = device === 'all-ios-devices' ? (
    <FormattedMessage
      id="devicesPage.disconnectDeviceModal.descriptionAllIos"
      defaultMessage="Are you sure you want to disconnect all your iOS devices? This won’t affect your payment plan."
    />
  ) : (
    <FormattedMessage
      id="devicesPage.disconnectDeviceModal.description"
      defaultMessage="Disconnect {deviceName} to add another device on its place. This won’t affect your payment plan."
      values={{ deviceName: <span className="h6">{device.name}</span> }}
    />
  );

  const buttonTitle = device === 'all-ios-devices' ? (
    <FormattedMessage
      id="devicesPage.disconnectDeviceModal.btnDisconnectAllIos"
      defaultMessage="Disconnect all iOS devices"
    />
  ) : (
    <FormattedMessage
      id="devicesPage.disconnectDeviceModal.btnDisconnect"
      defaultMessage="Disconnect device"
    />
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExited}
      title={title}
    >
      <div className="disconnect-device-modal__content">

        <div className="mb-8 disconnect-device-modal__description">
          {description}
        </div>

        <Button
          block
          size="lg"
          onClick={handleDeactivateClick}
          disabled={isDeviceProcessing}
          data-qa="disconnect-device-confirm-btn"
          data-testid="disconnect-button"
        >
          {buttonTitle}
        </Button>
        <Button
          block
          variant="secondary-outline"
          onClick={onHide}
          disabled={isDeviceProcessing}
          className="mt-3"
          size="lg"
        >
          <FormattedMessage id="devicesPage.disconnectDeviceModal.btnBack" defaultMessage="Cancel" />
        </Button>
      </div>
    </Modal>
  );
};

export default DisconnectDeviceModal;
