import { type CustomerOauthErrors } from './customer-oauth-errors';

const CUSTOMER_OAUTH_ERROR_KEY = 'customerOauthError';
const CUSTOMER_OAUTH_PARAMS_KEY = 'customerOauthParams';

export const storeCustomerOauthError = (error: string) => {
  sessionStorage.setItem(CUSTOMER_OAUTH_ERROR_KEY, error);
};

export const getStoredCustomerOauthError = () => {
  return sessionStorage.getItem(CUSTOMER_OAUTH_ERROR_KEY) as keyof typeof CustomerOauthErrors | null;
};

export const clearCustomerOauthStorage = () => {
  sessionStorage.removeItem(CUSTOMER_OAUTH_ERROR_KEY);
  sessionStorage.removeItem(CUSTOMER_OAUTH_PARAMS_KEY);
};

export const storeCustomerOauthParams = (params: { deviceId: string; clientId: string; redirectUrl: string }) => {
  sessionStorage.setItem(CUSTOMER_OAUTH_PARAMS_KEY, JSON.stringify(params));
};

export const getStoredCustomerOauthParams = (): { deviceId: string; clientId: string; redirectUrl: string } | null => {
  const params = sessionStorage.getItem(CUSTOMER_OAUTH_PARAMS_KEY);

  return params ? JSON.parse(params) : null;
};
