import React, { useState } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { PricePlanPeriod } from 'services/price-plans/types';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { User } from 'state/user/user-initial-state';

import PricePlansRow from 'components/shared/price-plans-row/price-plans-row';

import './choose-price-plan-step.scss';

type Props = {
  user: User;
  currentPricePlan: PricePlan;
  isProcessing: boolean;
  isTrialUser: boolean;
  onConfirmNextPlan: (pricePlan: PricePlan) => Promise<void>;
  nextPricePlan?: PricePlan;
  errorMessage?: ReactNode;
  switchedToAnnualInitValue?: boolean | undefined;
};

const ChoosePricePlanStep = (props: Props) => {
  const {
    user,
    currentPricePlan,
    isProcessing,
    nextPricePlan,
    errorMessage,
    onConfirmNextPlan,
    isTrialUser,
    switchedToAnnualInitValue,
  } = props;
  const { accountCurrency, isSetappMobileAvailable } = user;

  const [switchedToAnnual, setSwitchedToAnnual] = useState(
    switchedToAnnualInitValue ?? currentPricePlan.paidMonth === PricePlanPeriod.Annual
  );
  const switchPlanPeriod = () => setSwitchedToAnnual(!switchedToAnnual);

  const title = (
    <FormattedMessage
      id="changePricePlanPage.title"
      defaultMessage="Change plan"
    />
  );

  let description: JSX.Element | null = null;

  if (accountCurrency === 'EUR') {
    description = (
      <FormattedMessage
        id="changePricePlanPage.choosePlan.vatNotice"
        defaultMessage="Price includes estimated VAT for your location."
      />
    );
  }
  if (isSetappMobileAvailable) {
    description = (
      <FormattedMessage
        id="changePricePlanPage.choosePlan.vatUsdNotice"
        defaultMessage="Tax may apply, depending on your country of residence."
      />
    );
  }

  return (
    <PricePlansRow
      title={title}
      description={description}
      switchedToAnnual={switchedToAnnual}
      showSwitcher={isTrialUser}
      onChangePlanPeriod={switchPlanPeriod}
      onSelectPlanClick={onConfirmNextPlan}
      currentPricePlan={currentPricePlan}
      nextPricePlan={nextPricePlan}
      isProcessing={isProcessing}
      errorMessage={errorMessage}
    />
  );
};

export default ChoosePricePlanStep;
