import React from 'react';
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import type { CampaignConfig } from 'config/campaigns';

import type { SpecialOfferCampaign } from 'state/user/campaign-types';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import PaymentFormTotal from 'components/shared/payment-form-total/payment-form-total';

import './special-offer-details.scss';

type Props = {
  campaign: SpecialOfferCampaign;
  campaignConfig: CampaignConfig;
};

const SpecialOfferDetails = (props: Props) => {
  const {
    campaign,
    campaignConfig: { price, priceCurrency },
  } = props;

  const periodText = (
    <FormattedMessage
      id="specialOfferDetails.pricePlan.period"
      defaultMessage="12 months"
    />
  );

  const itemMacPlan = (
    <>
      <span className="text_weight-bold">
        <FormattedMessage
          id="specialOfferDetails.pricePlan.title"
          defaultMessage="Setapp Mac plan"
        />
      </span>
      <span className="special-offer-details__item-period">{periodText}</span>
    </>
  );
  const item1password = (
    <>
      <span className="text_weight-bold">1Password</span>
      <span className="special-offer-details__item-period">{periodText}</span>
    </>
  );
  const itemMasterclass = (
    <>
      <span className="text_weight-bold">MasterClass</span>
      <span className="special-offer-details__item-period">{periodText}</span>
    </>
  );

  let items: ReactElement[] = [];
  if (campaign === 'offer_2022_setapp') {
    items = [itemMacPlan];
  } else if (campaign === 'offer_2022_setapp_1pass') {
    items = [itemMacPlan, item1password];
  } else if (campaign === 'offer_2022_setapp_mc') {
    items = [itemMacPlan, itemMasterclass];
  } else if (campaign === 'offer_2022_setapp_1pass_mc') {
    items = [itemMacPlan, item1password, itemMasterclass];
  }

  return (
    <>
      {items.map((item, index) => (
        <p key={index} className="special-offer-details__item">
          {item}
        </p>
      ))}

      <div className="special-offer-details__price">
        <PaymentFormTotal
          boldText={false}
          totalPrice={<FormattedPrice currency={priceCurrency} price={price} />}
        />
      </div>
    </>
  );
};

export default SpecialOfferDetails;
