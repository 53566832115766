import * as subscriptionStatuses from 'state/subscription/statuses';

import useSubscriptionPolling from 'components/hooks/use-subscription-polling/use-subscription-polling';

/**
 * Hook to waiting when subscription status changes to blocked.
 *
 * @param {object} params - parameters of the `useSubscriptionPolling` hook.
 */
const useSubscriptionBlockedWaiting = (params: Partial<Parameters<typeof useSubscriptionPolling>[0]> = {}) => {
  const shouldContinuePolling = (subscription) => {
    return subscription.status !== subscriptionStatuses.BLOCKED;
  };

  const { startSubscriptionPolling } = useSubscriptionPolling({
    ...params,
    shouldContinuePolling,
  });

  return { waitForSubscriptionBlocked: startSubscriptionPolling };
};

export default useSubscriptionBlockedWaiting;
