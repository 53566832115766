import React, { ElementRef, PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { isMobile } from 'react-device-detect';
import { FormFieldWrapper } from '@setapp/ui-kit';
import ReCaptcha from 'react-google-recaptcha';

import validate from 'utils/auth-validation';
import type { ValidationRules } from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';
import { getSignupMetadata, getTokenManager } from 'utils/service-locators';
import type { ResolvedFeatureFlags } from 'utils/feature-flags';

import urls from 'config/urls';
import { CAPTCHA_SITE_KEY } from 'config/auth';
import { apiURL } from 'config/api';

import { getUserSignupGiftCardCode, getUserSignupPromoCode, getFeatureFlags } from 'state/root-reducer';
import { signUp } from 'state/user/user-actions';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import { SETAPP_MOBILE_CAMPAIGN } from 'state/user/campaign-types';

import PasswordErrorMessage from 'components/shared/password-error-message/password-error-message';

import SignUpForm from './signup-form/signup-form';


const signUpMetadataManager = getSignupMetadata();
const tokenManager = getTokenManager();

type SignupFormFields = {
  name: string;
  email: string;
  password: string;
  captcha: string;
  marketingSubscribed: boolean;
  termsAccepted: boolean;
  inviteId: string;
};

type SignupParams = SignupFormFields & Partial<{
  campaign: string | null | undefined;
  registrationFlow: {
    type?: string;
  };
  giftCard?: string;
  promoCode?: string;
  tierType?: string;
}>;

type SignupFormFieldsErrors = {
  name?: ReactNode;
  email?: ReactNode;
  password?: ReactNode;
  captcha?: ReactNode;
  termsAccepted?: ReactNode;
};

type SignupFormOptions = {
  withSocial: boolean;
  emailDisabled: boolean;
  type: 'default' | 'family';
  simplified: boolean;
  submitButtonText?: ReactNode;
  withPasswordRequirementsPopup?: boolean;
};

type State = {
  fields: SignupFormFields;
  fieldsErrors: SignupFormFieldsErrors;
  genericError: ReactNode;
  isRequestProcessing: boolean;
  isPasswordInvalid: boolean;
  validation: ValidationRules;
  requireCaptcha?: boolean;
  captchaRef?: ElementRef<typeof ReCaptcha>;
  isAuthCheckProcessing?: boolean;
};

type InjectedProps = {
  signUpForm: ReactNode;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  match: RouteComponentProps['match'];
  setFields: (fields: Partial<SignupFormFields>) => void;
  emailError?: ReactNode;
  isLoading?: boolean;
};

interface Props extends RouteComponentProps {
  featureFlags: ResolvedFeatureFlags;
  signUp: (SignupParams) => Promise<void>;
  signUpGiftCardCode?: string;
  signUpPromoCode?: string;
}

const signUpHoc = (options: SignupFormOptions = {
  withSocial: true,
  emailDisabled: false,
  type: 'default',
  simplified: false,
  withPasswordRequirementsPopup: true,
}) => function injectSignupForm(WrappedComponent: React.ComponentType<InjectedProps>) {
  class SignupPageHoc extends PureComponent<Props, State> {
    constructor(props: Props) {
      super(props);

      this.state = {
        fields: {
          name: '',
          email: '',
          password: '',
          captcha: '',
          marketingSubscribed: false,
          termsAccepted: false,
          inviteId: '',
        },
        fieldsErrors: {
          name: '',
          email: '',
          password: '',
          captcha: '',
          termsAccepted: '',
        },
        genericError: '',
        isRequestProcessing: false,
        isPasswordInvalid: false,
        validation: {
          email: {
            required: <FormattedMessage id="outer.signup.validation.emptyEmail" defaultMessage="Email is required" />,
            emailFormat: <FormattedMessage id="outer.signup.validation.invalidEmail" defaultMessage="Invalid email" />,
          },
          termsAccepted: {
            required: <FormattedMessage id="outer.signup.validation.termsAccepted" defaultMessage="Please agree with policies to continue" />,
          },
          password: {
            required: <FormattedMessage id="outer.signup.validation.emptyNewPassword" defaultMessage="Password is required" />,
            passwordFormat: <FormattedMessage id="outer.signup.validation.invalidConfirmPassword" defaultMessage="Invalid password" />,
          },
        }
      };
    }

    componentDidMount() {
      const { location, history } = this.props;
      const query = queryString.parse(location.search);

      if (query.campaign === SETAPP_MOBILE_CAMPAIGN) {
        this.setState({ isAuthCheckProcessing: true });
        // Cannot use fetchUser action here because it will redirect to login page
        // and show dangerous notification if user is not logged in
        fetch(apiURL.account, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${tokenManager.getAccessToken()}`,
          }
        })
          .then((response) => {
            if (response.ok) {
              history.push(urls.setappMobile);
            }
            this.setState({ isAuthCheckProcessing: false });
          });
      }

      if (query.gift_card) {
        history.push(`${urls.redeem}?code=${query.gift_card}`);
      }

      if (query.campaign) {
        signUpMetadataManager.save({ campaign: query.campaign });
      }
    }

    render() {
      const { match, location, history } = this.props;
      const { fieldsErrors, isAuthCheckProcessing } = this.state;
      const { email: emailError } = fieldsErrors;

      return (
        <WrappedComponent
          signUpForm={this.getSignupForm()}
          location={location}
          history={history}
          match={match}
          setFields={this.setFields}
          emailError={emailError}
          isLoading={isAuthCheckProcessing}
        />
      );
    }

    setFields = (fields: Partial<SignupFormFields>) => {
      this.setState((prevState: State) => ({
        fields: {
          ...prevState.fields,
          ...fields,
        },
      }));
    };

    getSignupForm = () => {
      const {
        fieldsErrors,
        fields,
        genericError,
        isRequestProcessing,
        isPasswordInvalid,
        requireCaptcha,
      } = this.state;
      const { signUpGiftCardCode, signUpPromoCode, location } = this.props;
      const {
        name: nameError,
        email: emailError,
        password: passwordError,
        termsAccepted: termsAcceptedError,
      } = fieldsErrors;
      const {
        name,
        email,
        password,
        marketingSubscribed,
        termsAccepted,
      } = fields;
      const {
        withSocial,
        emailDisabled,
        simplified,
        submitButtonText,
        withPasswordRequirementsPopup,
      } = options;

      const searchParams = new URLSearchParams(location.search);
      const loginNextLocation = searchParams.get('campaign') === SETAPP_MOBILE_CAMPAIGN ? urls.setappMobile : undefined;

      return (
        <div className="signup-form-container">
          <SignUpForm
            onTextFieldChange={this.onTextFieldChange}
            captcha={requireCaptcha ? this.getCaptcha() : undefined}
            onFormSubmit={this.onFormSubmit}
            onSubscribedChange={this.onSubscribedChange}
            onTermsAcceptedChange={this.onTermsAcceptedChange}
            nameError={nameError}
            emailError={emailError}
            passwordError={passwordError && <PasswordErrorMessage password={fields.password} />}
            genericError={genericError}
            isPasswordInvalid={isPasswordInvalid}
            termsAcceptedError={termsAcceptedError}
            isRequestProcessing={isRequestProcessing}
            name={name}
            email={email}
            password={password}
            isSubscribed={marketingSubscribed}
            termsAccepted={termsAccepted}
            giftCard={signUpGiftCardCode} // is needed for social signup with gift card
            promoCode={signUpPromoCode} // is needed for social signup with promo code
            withSocial={withSocial}
            emailDisabled={emailDisabled}
            simplified={simplified}
            submitButtonText={submitButtonText}
            withPasswordRequirementsPopup={withPasswordRequirementsPopup}
            loginNextLocation={loginNextLocation}
          />
        </div>
      );
    };

    onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.currentTarget;

      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          [name]: value,
        },
        isPasswordInvalid: false,
      }));
    };

    onSubscribedChange = () => {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          marketingSubscribed: !prevState.fields.marketingSubscribed,
        },
      }));
    };

    onTermsAcceptedChange = () => {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          termsAccepted: !prevState.fields.termsAccepted,
        },
      }));
    };

    onFormSubmit = (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();

      const {
        history,
        location,
        signUp,
        signUpGiftCardCode,
        signUpPromoCode,
        featureFlags,
      } = this.props;
      const { fields, validation } = this.state;

      const fieldsErrors = validate(fields, validation);
      const formHasError = Object.keys(fieldsErrors).some((field) => fieldsErrors[field]);

      this.setState({ fieldsErrors });

      if (formHasError) {
        this.setState({ genericError: '' });

        if (fieldsErrors.password) {
          this.setState({ isPasswordInvalid: true });
        }

        return Promise.resolve();
      }

      this.setProcessing(true);

      const signupParams: SignupParams = fields;
      const query = queryString.parse(location.search);

      const { referral: urlReferral } = query;
      const { referral: cookiesReferral } = signUpMetadataManager.getAll();
      const referral = urlReferral || cookiesReferral;

      const { membershipPriceIncrease, signupMobileAsIos } = featureFlags;
      const isPriceIncreaseEnabled = Boolean(membershipPriceIncrease.value)
        && !signUpGiftCardCode
        && !signUpPromoCode
        && !referral;

      if (query.campaign) {
        signupParams.campaign = Array.isArray(query.campaign) ? query.campaign[0] : query.campaign;
      }

      if (query.registration_flow) {
        signupParams.registrationFlow = {
          type: Array.isArray(query.registration_flow)
            ? query.registration_flow?.[0] ?? ''
            : query?.registration_flow,
        };
      }

      if (isPriceIncreaseEnabled) {
        signupParams.tierType = pricePlanTypes.MAC_MONTHLY_PRICING_2024;
      }

      if (signUpGiftCardCode) {
        signupParams.giftCard = signUpGiftCardCode;
      }

      if (signUpPromoCode) {
        signupParams.promoCode = signUpPromoCode;
      }

      if (isMobile && Boolean(signupMobileAsIos.value)) {
        signupParams.tierType = pricePlanTypes.IOS_MONTHLY;
      }

      return signUp(signupParams)
        .then(() => {
          if (options.type === 'family') {
            analytics.trackEvent(events.FAMILY_PLAN_MEMBER_CREATED_ACCOUNT);
          }

          let nextLocation = urls.successfulRegistration;

          if (query.campaign === SETAPP_MOBILE_CAMPAIGN) {
            nextLocation = urls.setappMobile;
          }

          history.push(nextLocation);
        })
        .catch((error) => {
          const { captchaRef } = this.state;

          this.setProcessing(false);

          const simplifiedError = error.getSimplifiedErrors();

          if (simplifiedError.fieldsErrors && simplifiedError.fieldsErrors.captcha) {
            this.setState((prevState) => ({
              fields: {
                ...prevState.fields,
                captcha: '',
              },
              requireCaptcha: true,
              validation: {
                ...prevState.validation,
                captcha: {
                  required: <FormattedMessage id="outer.signup.validation.captcha" defaultMessage="Captcha is required" />
                }
              }
            }));
            captchaRef?.reset();
          }

          // display inviteId error as generic
          if (simplifiedError.fieldsErrors && simplifiedError.fieldsErrors.inviteId) {
            this.setState({ genericError: simplifiedError.fieldsErrors.inviteId, ...simplifiedError });
          } else {
            this.setState({ ...simplifiedError });
          }
        });
    };

    setProcessing = (isProcessing: boolean) => {
      this.setState({ isRequestProcessing: isProcessing });
    }

    getCaptcha = () => {
      const { captcha: captchaError } = this.state.fieldsErrors;

      if (!CAPTCHA_SITE_KEY) return undefined;

      return (
        <FormFieldWrapper
          id="captcha"
          label="Captcha"
          helpText={captchaError}
          invalid={Boolean(captchaError)}
        >
          <ReCaptcha
            sitekey={CAPTCHA_SITE_KEY}
            onChange={this.onCaptchaChange}
            ref={this.setCaptchaRef}
          />
        </FormFieldWrapper>
      );
    }

    onCaptchaChange = (value) => {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          captcha: value,
        },
      }));
    };

    setCaptchaRef = (captchaRef: ElementRef<typeof ReCaptcha>) => {
      this.setState({ captchaRef });
    }
  }

  return SignupPageHoc;
};

const mapStateToProps = (state) => ({
  signUpGiftCardCode: getUserSignupGiftCardCode(state),
  signUpPromoCode: getUserSignupPromoCode(state),
  featureFlags: getFeatureFlags(state),
});

/* istanbul ignore next */
const mapActionsToProps = {
  signUp,
};

export { signUpHoc as PureSignUpHoc };

const signupPageHOC = (options?: SignupFormOptions) => (
  (Component: React.FC<InjectedProps>): React.FC<RouteComponentProps> => (
    connect(mapStateToProps, mapActionsToProps)(signUpHoc(options)(Component))
  )
);

export default signupPageHOC;
