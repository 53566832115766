import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import './payment-form-total.scss';

type Props = {
  totalPrice: ReactElement;
  boldText?: boolean;
  isTrialUser?: boolean;
};

const PaymentFormTotal = (props: Props) => {
  const {
    totalPrice,
    boldText = true,
    isTrialUser = false,
  } = props;

  const text = isTrialUser ? (
    <FormattedMessage id="paymentForm.totalForTrial" defaultMessage="After trial:" />
  ) : (
    <FormattedMessage id="paymentForm.total" defaultMessage="Total:" />
  );
  const TextTag = boldText ? 'strong' : 'span';

  return (
    <div className="payment-form-total" data-qa="totalPaymentForm">
      <div className="text_lg">
        <TextTag>{text}</TextTag>
        {' '}
        <strong>{totalPrice}</strong>
      </div>
      <div className="payment-form-total__vat text_xs">
        <FormattedMessage id="paymentForm.total.vatInfo" defaultMessage="Excluding tax" />
      </div>
    </div>
  );
};

export default PaymentFormTotal;
