import { useSelector } from 'react-redux';

import { SETAPP_FOR_BACKEND_TRIAL } from 'state/price-plans/price-plans-types';
import {
  isActivateSubscriptionAllowed as getIsActivateSubscriptionAllowed,
  isSuspendedSubscriptionWithOldPricePlan as getIsSuspendedSubscriptionWithOldPricePlan,
  isSubscriptionCancelledAndActive as getIsSubscriptionCancelledAndActive,
  isSubscriptionCancelledAndInactive as getIsSubscriptionCancelledAndInactive,
} from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { TrialAvailabilityStatus } from 'state/trial-status/trial-status-types';

import useTrialAvailabilityStatus from 'components/hooks/use-trial-availability-status/use-trial-availability-status';

import { useSubscriptionContext } from '../context/subscription-context';

export const TRIAL_UNAVAILABLE_STATUSES = [
  subscriptionStatuses.NEW,
  subscriptionStatuses.BLOCKED,
];

export const subscriptionCtaCasesTypes = {
  activateTrial: 'activateTrial',
  activateNow: 'activateNow',
  choosePricePlan: 'choosePricePlan',
  choosePricePlanAfterTrial: 'choosePricePlanAfterTrial',
  fineTunePricePlan: 'fineTunePricePlan',
  suspendedUserActivation: 'suspendedUserActivation',
  renewSubscriptionAfterCancel: 'renewSubscriptionAfterCancel',
  trialUnknown: 'trialUnknown',
} as const;

export type SubscriptionCtaCasesType = Maybe<keyof typeof subscriptionCtaCasesTypes>;

const useSubscriptionCtaType = (): SubscriptionCtaCasesType => {
  const { hasPriceFeatures, subscription, isPaymentMethodCreated, isFamilyMember } = useSubscriptionContext();

  const { status: trialAvailabilityStatus } = useTrialAvailabilityStatus();

  const isActivateSubscriptionAllowed = useSelector(getIsActivateSubscriptionAllowed);
  const isSuspendedSubscriptionWithOldPricePlan = useSelector(getIsSuspendedSubscriptionWithOldPricePlan);
  const isSubscriptionCancelledAndActive = useSelector(getIsSubscriptionCancelledAndActive);
  const isSubscriptionCancelledAndInactive = useSelector(getIsSubscriptionCancelledAndInactive);

  if (isFamilyMember) return null;

  const { status: subscriptionStatus, pricePlan } = subscription;

  const isTrialNotAllowed = trialAvailabilityStatus === TrialAvailabilityStatus.trialNotAllowed;
  const isTrialAllowed = trialAvailabilityStatus === TrialAvailabilityStatus.trialAllowed;
  const isTrialUnknown = subscriptionStatus === subscriptionStatuses.NEW
    && trialAvailabilityStatus === TrialAvailabilityStatus.unknown;

  const isSubscriptionBlocked = subscriptionStatus === subscriptionStatuses.BLOCKED;
  const isSubscriptionTrial = subscriptionStatus === subscriptionStatuses.TRIAL;

  const isSetappForBackendTrial = pricePlan.tierType === SETAPP_FOR_BACKEND_TRIAL;
  const isChoosePlanAfterTrial = TRIAL_UNAVAILABLE_STATUSES.includes(subscription.status) && isTrialNotAllowed;
  const isSuspendedUserActivation = isSuspendedSubscriptionWithOldPricePlan
    || isSubscriptionBlocked
    || isSubscriptionCancelledAndInactive;

  if (isTrialAllowed) return subscriptionCtaCasesTypes.activateTrial;

  if (isTrialUnknown) return subscriptionCtaCasesTypes.trialUnknown;

  if (isChoosePlanAfterTrial) return subscriptionCtaCasesTypes.choosePricePlanAfterTrial;

  if (isSuspendedUserActivation) return subscriptionCtaCasesTypes.suspendedUserActivation;

  if (isSetappForBackendTrial) return null;

  if (hasPriceFeatures && isSubscriptionTrial && pricePlan.features?.trialSwitchFromStrategy !== 'forbidden') {
    return isPaymentMethodCreated
      ? subscriptionCtaCasesTypes.fineTunePricePlan
      : subscriptionCtaCasesTypes.choosePricePlan;
  }

  if (isActivateSubscriptionAllowed) return subscriptionCtaCasesTypes.activateNow;

  if (isSubscriptionCancelledAndActive) return subscriptionCtaCasesTypes.renewSubscriptionAfterCancel;

  return null;
};


export default useSubscriptionCtaType;
