import React, { Component, SyntheticEvent, ReactElement } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  FormattedMessage, defineMessages, injectIntl,
} from 'react-intl';
import { FormField, Button } from '@setapp/ui-kit';

import type { InjectedIntlProps } from 'react-intl';

import CurrentPasswordField from 'components/shared/form/current-password-field/current-password-field';

import { getUserEmail } from 'state/root-reducer';
import { showSuccessNotification } from 'state/notifier/notifier-reducer';

import validate, { validators } from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';

import PasswordRequirementsPopup from 'components/shared/password-requirements-popup/password-requirements-popup';
import PasswordErrorMessage from 'components/shared/password-error-message/password-error-message';
import { updateUser } from 'state/user/user-actions';
import type { UpdateUserParams } from 'state/user/user-actions';

const initialState = {
  fields: {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  },
  fieldsErrors: {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  },
  genericError: '',
  isPasswordFieldOnFocus: false,
  isPasswordInvalid: false,
};

const messages = defineMessages({
  currentPasswordRequired: {
    id: 'accountSettings.editPassword.validation.emptyPassword',
    defaultMessage: 'Password is required',
  },
  newPasswordRequired: {
    id: 'accountSettings.personalInfo.validation.emptyNewPassword',
    defaultMessage: 'Password is required',
  },
  newPasswordInvalid: {
    id: 'accountSettings.personalInfo.validation.invalidNewPassword',
    defaultMessage: 'At least 1 uppercase and lowercase letter. 8 characters minimum.',
  },
  confirmPasswordRequired: {
    id: 'accountSettings.editPassword.validation.emptyConfirmPassword',
    defaultMessage: 'Password is required',
  },
  confirmPasswordInvalid: {
    id: 'accountSettings.personalInfo.validation.invalidConfirmPassword',
    defaultMessage: 'At least 1 uppercase and lowercase letter. 8 characters minimum.',
  },
  passwordsMismatch: {
    id: 'accountSettings.editPassword.validation.mismatchPassword',
    defaultMessage: 'Passwords don\'t match',
  },
  currentPasswordLabel: {
    id: 'accountSettings.editPassword.currentPasswordLabel',
    defaultMessage: 'Current password',
  },
  currentPasswordPlaceholder: {
    id: 'accountSettings.editPassword.currentPassPlaceholder',
    defaultMessage: 'Enter current password',
  },
  newPasswordLabel: {
    id: 'accountSettings.editPassword.newPasswordLabel',
    defaultMessage: 'New password',
  },
  newPasswordPlaceholder: {
    id: 'accountSettings.editPassword.newPasswordPlaceholder',
    defaultMessage: 'Enter new password',
  },
  confirmPasswordLabel: {
    id: 'accountSettings.editPassword.confirmPasswordLabel',
    defaultMessage: 'Confirm password',
  },
  confirmPasswordPlaceholder: {
    id: 'accountSettings.editPassword.confirmPasswordPlaceholder',
    defaultMessage: 'Repeat new password',
  },
  successUpdateNotification: {
    id: 'accountSettings.editPassword.successNotification',
    defaultMessage: 'Password successfully updated.',
  },
});

type Props = {
  updateUser: (params: UpdateUserParams) => Promise<void>;
  email: string;
  showSuccessNotification: (message: ReactElement | string) => void;
  isLoading?: boolean;
} & InjectedIntlProps;

type State = {
  fields: {
    currentPassword: string;
    password: string;
    confirmPassword: string;
  };
  fieldsErrors: {
    currentPassword: string;
    password: string;
    confirmPassword: string;
  };
  genericError: string;
  isPasswordFieldOnFocus: boolean;
  isPasswordInvalid: boolean;
};

class UserPassword extends Component<Props, State> {
  static defaultProps = {
    isLoading: false,
    email: '',
  };

  state = initialState;

  render() {
    const { intl, email, isLoading } = this.props;
    const {
      genericError,
      fields,
      fieldsErrors,
      isPasswordFieldOnFocus,
      isPasswordInvalid,
    } = this.state;
    const errorClasses = classnames('form-error text-danger', {
      hidden: !genericError,
    });
    const showPasswordRequirementsPopup = isPasswordFieldOnFocus || isPasswordInvalid;

    return (
      <div>
        <h5 className="account-settings-section__title">
          <FormattedMessage id="accountSettings.editPassword.title" defaultMessage="Change password" />
        </h5>

        <form onSubmit={this.onFormSubmit} noValidate>
          <CurrentPasswordField
            id="currentPassword"
            name="currentPassword"
            type="password"
            email={email}
            label={intl.formatMessage(messages.currentPasswordLabel)}
            placeholder={intl.formatMessage(messages.currentPasswordPlaceholder)}
            onChange={this.onPasswordChange}
            helpText={fieldsErrors.currentPassword}
            invalid={Boolean(fieldsErrors.currentPassword)}
            autoComplete="new-password"
            value={fields.currentPassword}
            showLabel
          />
          <div className="account-settings-section__password-field">
            <FormField
              id="newPassword"
              name="password"
              type="password"
              label={intl.formatMessage(messages.newPasswordLabel)}
              placeholder={intl.formatMessage(messages.newPasswordPlaceholder)}
              onChange={this.onPasswordChange}
              helpText={fieldsErrors.password && <PasswordErrorMessage password={fields.password} />}
              invalid={Boolean(fieldsErrors.password)}
              autoComplete="new-password"
              value={fields.password}
              showLabel
              onFocus={this.handleFieldFocus}
              onBlur={this.handleFieldBlur}
            />
            <PasswordRequirementsPopup
              isMatchMinLength={validators.passwordMatchMinLength(fields.password)}
              isContainsLower={validators.passwordContainsLower(fields.password)}
              isContainsUpper={validators.passwordContainsUpper(fields.password)}
              showErrorState={isPasswordInvalid}
              showPopup={showPasswordRequirementsPopup}
              className="mt-6"
              position="left"
            />
          </div>
          <FormField
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            label={intl.formatMessage(messages.confirmPasswordLabel)}
            placeholder={intl.formatMessage(messages.confirmPasswordPlaceholder)}
            onChange={this.onPasswordChange}
            helpText={fieldsErrors.confirmPassword}
            invalid={Boolean(fieldsErrors.confirmPassword)}
            autoComplete="new-password"
            value={fields.confirmPassword}
            showLabel
          />
          <p className={errorClasses}>{genericError}</p>

          <Button
            variant="secondary-outline"
            type="submit"
            size="lg"
            disabled={isLoading}
            data-qa="updatePasswordBtn"
          >
            <FormattedMessage id="accountSettings.editPassword.updateButton" defaultMessage="Update password" />
          </Button>
        </form>
      </div>
    );
  }

  handleFieldFocus = () => {
    this.setState({ isPasswordFieldOnFocus: true });
  }

  handleFieldBlur = () => {
    this.setState({ isPasswordFieldOnFocus: false });
  }

  getValidation() {
    const { intl } = this.props;

    return {
      currentPassword: {
        required: intl.formatMessage(messages.currentPasswordRequired),
      },
      password: {
        required: intl.formatMessage(messages.newPasswordRequired),
        passwordFormat: intl.formatMessage(messages.newPasswordInvalid),
      },
      confirmPassword: {
        required: intl.formatMessage(messages.confirmPasswordRequired),
        passwordFormat: intl.formatMessage(messages.confirmPasswordInvalid),
        isPasswordsMatch: {
          fields: ['password', 'confirmPassword'],
          message: intl.formatMessage(messages.passwordsMismatch),
        },
      },
    };
  }

  onPasswordChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    this.setState((prevState) => ({
      fields: { ...prevState.fields, [name]: value },
      isPasswordInvalid: false,
    }));
  };

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    const { fields } = this.state;
    const { updateUser, intl } = this.props;
    const { showSuccessNotification } = this.props;

    e.preventDefault();

    // eslint-disable-next-line react/no-access-state-in-setstate
    const fieldsErrors = validate(fields, this.getValidation());
    const formHasError = Object.keys(fieldsErrors).some((field) => fieldsErrors[field]);

    // @ts-expect-error TS(2739): Type 'Messages<string>' is missing the following p... Remove this comment to see the full error message
    this.setState({ fieldsErrors });

    if (formHasError) {
      this.setState({ genericError: '' });

      if (fieldsErrors.password) {
        this.setState({ isPasswordInvalid: true });
      }

      return Promise.resolve();
    }

    const { password, currentPassword } = fields;

    return updateUser({ password, currentPassword })
      .then(() => {
        this.setState(initialState);

        showSuccessNotification(intl.formatMessage(messages.successUpdateNotification));

        analytics.trackEvent(events.ACCOUNT_PASSWORD_CHANGED);
      })
      .catch((error) => {
        this.setState({ ...error.getSimplifiedErrors() });
      });
  };
}

export { UserPassword };

const mapStateToProps = (state) => ({ email: getUserEmail(state) });

const mapActionsToProps = {
  updateUser,
  showSuccessNotification,
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(UserPassword));
