import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';
import ModalRoot from 'components/layout/app-layout/modal-root/modal-root';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { showModal } from 'state/modal/modal-reducer';
import { fetchApps } from 'state/apps/apps-actions';
import {
  getUser, getApps, getAppsList, isAppLandingFlow,
} from 'state/root-reducer';
import type { App } from 'state/apps/apps-initial-state';

import analytics, { events } from 'utils/analytics';
import request from 'utils/request';
import urls from 'config/urls';
import { apiURL } from 'config/api';
import SuccessfulRegistrationSingleAppPageContent
  from './successful-registration-single-app-page-content/successful-registration-single-app-page-content';

import './successful-registration-single-app.scss';


const mapStateToProps = (state) => ({
  flowParams: getUser(state).registrationFlowParams,
  appsList: getAppsList(state),
  isUserLoading: getUser(state).isLoading,
  isAppsLoading: getApps(state).isLoading,
  isAppLandingFlow: isAppLandingFlow(state),
});

const mapActionsToProps = {
  fetchApps,
  showDangerNotification,
  showModal,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = RouteComponentProps & ConnectedProps<typeof connector>;

type State = {
  landingAppInfo?: App;
};

class SuccessfulRegistrationSingleApp extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      landingAppInfo: undefined,
    };
  }

  componentDidMount() {
    const {
      flowParams,
      fetchApps,
      history,
      isAppLandingFlow,
    } = this.props;

    if (!isAppLandingFlow) {
      history.replace(urls.successfulRegistration);
    }

    return request.get(apiURL.appInfo + flowParams.appId)
      .then(
        (landingAppInfo) => {
          this.setState({ landingAppInfo });
          fetchApps();
        },
        () => history.replace(urls.successfulRegistration)
      );
  }

  render() {
    const {
      isUserLoading,
      isAppsLoading,
      appsList,
    } = this.props;
    const { landingAppInfo } = this.state;

    if (isUserLoading || isAppsLoading || !landingAppInfo) {
      return <FullscreenLayoutLoading />;
    }

    return (
      <div className="successful-registration-single-app" data-qa="successful-registration-page">
        <SuccessfulRegistrationSingleAppPageContent
          onDirectDownloadClick={this.onDirectDownloadClick}
          onHowItWorksButtonClick={this.handleHowItWorksButtonClick}
          app={landingAppInfo}
          appsList={appsList}
        />
        <ModalRoot />
      </div>
    );
  }

  onDirectDownloadClick = () => {
    analytics.trackEvent(events.WELCOME_PAGE_DIRECT_DOWNLOAD_CLICK);
  };

  handleHowItWorksButtonClick = () => {
    const { showModal } = this.props;

    showModal('HOW_IT_WORKS_VIDEO', {
      title: <FormattedMessage id="successfulRegistrationSingleApp.howItWorks.title" defaultMessage="How Setapp works" />,
    });
  }
}

export { SuccessfulRegistrationSingleApp as PureSuccessfulRegistrationSingleApp };

export default connector(SuccessfulRegistrationSingleApp);
