// @flow

import cookies from 'js-cookie';
import { COOKIES_ROOT_DOMAIN } from 'config/app';

export default class OauthStateManager {
  static save(state: string) {
    cookies.set('oauthState', state, { domain: COOKIES_ROOT_DOMAIN });
  }

  static get(): string {
    return cookies.get('oauthState');
  }
}
