import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import { FormattedPrice } from 'components/shared/formatter/formatter';

type Props = {
  pricePlan: PricePlan;
};

const PricePerMonth = ({ pricePlan }: Props) => (
  <FormattedMessage
    id="pricePerMonth.text"
    defaultMessage="{price}/mo"
    values={{
      price: (
        <FormattedPrice
          currency={pricePlan.currency}
          price={pricePlan.price / pricePlan.paidMonth}
        />
      ),
    }}
  />
);

export default PricePerMonth;
