import React, { FC } from 'react';

import * as subscriptionStatuses from 'state/subscription/statuses';
import { useSubscriptionContext } from '../../context/subscription-context';
import FamilySubscriptionInfo from './family-subscription-info/family-subscription-info';
import SubscriptionActive from './subscription-active/subscription-active';
import SubscriptionBlocked from './subscription-blocked/subscription-blocked';
import SubscriptionCancelled from './subscription-cancelled/subscription-cancelled';
import SubscriptionFree from './subscription-free/subscription-free';
import SubscriptionGrace from './subscription-grace/subscription-grace';
import SubscriptionNew from './subscription-new/subscription-new';
import SubscriptionTrial from './subscription-trial/subscription-trial';

const SUBSCRIPTION_INFO_BY_STATUS = {
  [subscriptionStatuses.NEW]: SubscriptionNew,
  [subscriptionStatuses.TRIAL]: SubscriptionTrial,
  [subscriptionStatuses.ACTIVE]: SubscriptionActive,
  [subscriptionStatuses.CANCELLED]: SubscriptionCancelled,
  [subscriptionStatuses.GRACE]: SubscriptionGrace,
  [subscriptionStatuses.BLOCKED]: SubscriptionBlocked,
  [subscriptionStatuses.FREE]: SubscriptionFree,
};

const SubscriptionInfo: FC = () => {
  const { pricePlan, subscription, familyInfoType } = useSubscriptionContext();

  const SubscriptionInfoDetails = SUBSCRIPTION_INFO_BY_STATUS[subscription.status];

  if (!pricePlan || !subscription.status) return null;

  switch (true) {
    case Boolean(familyInfoType):
      return <FamilySubscriptionInfo />;
    case Boolean(SubscriptionInfoDetails): {
      return <SubscriptionInfoDetails />;
    }
    default:
      return null;
  }
};

export default SubscriptionInfo;
