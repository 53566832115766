// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import './activate-subscription-promo.scss';

import setappDesktop from 'components/shared/shared-images/mac-tablet-iphone@1x.png';
import setappDesktop2x from 'components/shared/shared-images/mac-tablet-iphone@2x.png';
import setappDesktop3x from 'components/shared/shared-images/mac-tablet-iphone@3x.png';
import discoverIcon from './images/discover.svg';
import updatesIcon from './images/updates.svg';
import supportIcon from './images/support.svg';

type Props = {|
  appsCount: number,
|};

const ActivateSubscriptionPromo = ({ appsCount }: Props) => {
  const appsNumber = (Math.floor(appsCount / 10)) * 10;

  return (
    <div className="activate-page-promo">
      <div className="activate-page-promo__container">
        <img
          className="activate-page-promo__image"
          src={setappDesktop}
          srcSet={`${setappDesktop2x} 2x, ${setappDesktop3x} 3x`}
          alt=""
          width="596"
          height="351"
        />
        <div className="h4 activate-page-promo__title">
          <FormattedMessage
            id="activatePage.promo.apps"
            defaultMessage="{appsNumber}+ apps."
            values={{ appsNumber }}
          />
        </div>
        <div className="h4 activate-page-promo__title">
          <FormattedMessage
            id="activatePage.promo.title"
            defaultMessage="Setapp helps you stay in your flow."
          />
        </div>
        <div className="activate-page-promo__features">
          <div className="activate-page-promo__feature">
            <img className="activate-page-promo__feature-icon" src={discoverIcon} alt="" />
            <div className="activate-page-promo__feature-text">
              <FormattedMessage
                id="activatePage.promo.feature.discover"
                defaultMessage="Discover tools that make life efficient"
              />
            </div>
          </div>
          <div className="activate-page-promo__feature">
            <img className="activate-page-promo__feature-icon" src={updatesIcon} alt="" />
            <div className="activate-page-promo__feature-text">
              <FormattedMessage
                id="activatePage.promo.feature.update"
                defaultMessage="Have all the latest app updates"
              />
            </div>
          </div>
          <div className="activate-page-promo__feature">
            <img className="activate-page-promo__feature-icon" src={supportIcon} alt="" />
            <div className="activate-page-promo__feature-text">
              <FormattedMessage
                id="activatePage.promo.feature.support"
                defaultMessage="Work smoothly with 24/7 support"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivateSubscriptionPromo;
