// @flow

import React, { Component, type Node } from 'react';
import Col from 'react-bootstrap/lib/Col';
import Row from 'react-bootstrap/lib/Row';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { FormField } from '@setapp/ui-kit';

import type { BraintreePaymentDetailsPayload } from 'services/payment-details-api/payment-details-api';

import Expandable from 'components/shared/expandable/expandable';

import messages from 'components/payment-details/business-account-info-messages';


type Props = {|
  paymentInfo: BraintreePaymentDetailsPayload,
  fieldsWithError: {
    [string]: Node,
  },
  intl: IntlShape,
  isLoading: boolean,
  onFieldChange: (string, string) => mixed,
|};

type State = {
  isExpandableOpen: boolean,
};

class PaymentMethodFormVatContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isExpandableOpen: PaymentMethodFormVatContainer.isVatFieldsInPaymentInfo(props.paymentInfo),
    };
  }

  static isVatFieldsInPaymentInfo(paymentInfo: BraintreePaymentDetailsPayload) {
    const listOfVatFields = ['vatId', 'company', 'streetName', 'addressDetail', 'buildingNumber', 'city', 'postalCode'];

    return Object.keys(paymentInfo).some((fieldName) => (listOfVatFields.indexOf(fieldName) > -1 && paymentInfo[fieldName] !== ''));
  }

  static getDerivedStateFromProps(props: Props) {
    if (props.paymentInfo && PaymentMethodFormVatContainer.isVatFieldsInPaymentInfo(props.paymentInfo)) {
      return {
        isExpandableOpen: true,
      };
    }

    return null;
  }

  render() {
    const {
      isLoading, onFieldChange, paymentInfo, fieldsWithError, intl,
    } = this.props;
    const {
      vatId, company, streetName, addressDetail, buildingNumber, city, postalCode,
    } = paymentInfo;
    const {
      vatId: vatIdError,
      company: companyError,
      streetName: streetNameError,
      addressDetail: addressDetailError,
      buildingNumber: buildingNumberError,
      city: cityError,
      postalCode: postalCodeError,
    } = fieldsWithError;
    const { isExpandableOpen } = this.state;

    return (
      <Expandable
        label={<FormattedMessage id="vat.title" defaultMessage="I need an invoice" />}
        isOpen={isExpandableOpen}
        onExpandableClick={this.onExpandableClick}
        type="checkbox"
        controlPosition="bottom"
      >
        <Row>
          <Col sm={7}>
            <FormField
              id="vat-company"
              name="company"
              invalid={Boolean(companyError)}
              helpText={companyError}
              label={intl.formatMessage(messages.company)}
              showLabel
              disabled={isLoading}
              onChange={(e) => onFieldChange(e.target.name, e.target.value)}
              value={company}
            />
          </Col>

          <Col sm={5}>
            <FormField
              id="vat-number"
              name="vatId"
              label={intl.formatMessage(messages.vatNumber)}
              invalid={Boolean(vatIdError)}
              helpText={vatIdError}
              showLabel
              disabled={isLoading}
              onChange={(e) => onFieldChange(e.target.name, e.target.value)}
              value={vatId}
            />
          </Col>
        </Row>

        <FormField
          id="vat-street"
          name="streetName"
          invalid={Boolean(streetNameError)}
          helpText={streetNameError}
          label={intl.formatMessage(messages.street)}
          showLabel
          disabled={isLoading}
          onChange={(e) => onFieldChange(e.target.name, e.target.value)}
          value={streetName}
        />

        <Row>
          <Col sm={7}>
            <FormField
              id="vat-address"
              name="addressDetail"
              invalid={Boolean(addressDetailError)}
              helpText={addressDetailError}
              label={intl.formatMessage(messages.address)}
              showLabel
              disabled={isLoading}
              onChange={(e) => onFieldChange(e.target.name, e.target.value)}
              value={addressDetail}
            />
          </Col>

          <Col sm={5}>
            <FormField
              id="vat-building"
              name="buildingNumber"
              invalid={Boolean(buildingNumberError)}
              helpText={buildingNumberError}
              label={intl.formatMessage(messages.building)}
              showLabel
              disabled={isLoading}
              onChange={(e) => onFieldChange(e.target.name, e.target.value)}
              value={buildingNumber}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={7}>
            <FormField
              id="vat-city"
              name="city"
              invalid={Boolean(cityError)}
              helpText={cityError}
              label={intl.formatMessage(messages.city)}
              showLabel
              disabled={isLoading}
              onChange={(e) => onFieldChange(e.target.name, e.target.value)}
              value={city}
            />
          </Col>

          <Col sm={5}>
            <FormField
              id="vat-postal"
              name="postalCode"
              invalid={Boolean(postalCodeError)}
              helpText={postalCodeError}
              label={intl.formatMessage(messages.postalCode)}
              showLabel
              disabled={isLoading}
              onChange={(e) => onFieldChange(e.target.name, e.target.value)}
              value={postalCode}
            />
          </Col>
        </Row>
      </Expandable>
    );
  }

  onExpandableClick = () => {
    this.setState((prevState) => ({ isExpandableOpen: !prevState.isExpandableOpen }));
  }
}

export { PaymentMethodFormVatContainer as PurePaymentMethodFormVatContainer };

export default injectIntl(PaymentMethodFormVatContainer);
