export type App = {
  id: number;
  name: string;
  icon: string;
  installerUrl: string;
  isSingleApp?: boolean;
}

export type AppsState = {
  list: App[];
  isLoading: boolean;
}

const initialState: AppsState = {
  list: [],
  isLoading: false,
};

export default initialState;
