import { useSelector } from 'react-redux';
import {
  isActivateSubscriptionAllowed as selectIsActivateSubscriptionAllowed,
  isPaymentMethodCreated as selectIsPaymentMethodCreated,
  getPrimarySubscription,
  canUseReferralProgram,
  isReferralUser,
} from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';

const NOT_ALLOWED_SUBSCRIPTION_STATUSES = [
  subscriptionStatuses.BLOCKED,
  subscriptionStatuses.TRIAL,
  subscriptionStatuses.NEW,
];

const useBannersViewState = () => {
  const primarySubscription = useSelector(getPrimarySubscription);
  const isUserReferral = useSelector(isReferralUser);
  const isActivateSubscriptionAllowed = useSelector(selectIsActivateSubscriptionAllowed);
  const isUseReferralProgramAllowed = useSelector(canUseReferralProgram);
  const isPaymentMethodCreated = useSelector(selectIsPaymentMethodCreated);

  const isReferralBannerVisible = !NOT_ALLOWED_SUBSCRIPTION_STATUSES.includes(Number(primarySubscription?.status))
    && isUseReferralProgramAllowed
    && !isUserReferral;
  const isFreeMonthActivationBannerVisible = isUserReferral
    && isActivateSubscriptionAllowed
    && isPaymentMethodCreated;
  const isReferralPaymentDetailsBannerVisible = isUserReferral
    && isActivateSubscriptionAllowed
    && !isPaymentMethodCreated;

  return {
    isReferralBannerVisible,
    isFreeMonthActivationBannerVisible,
    isReferralPaymentDetailsBannerVisible,
  };
};

export default useBannersViewState;
