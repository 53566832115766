import React  from 'react';
import type { ReactElement } from 'react';

import { Button } from '@setapp/ui-kit';

import './cancel-subscription-step-layout.scss';


export type Props = {
  illustration: ReactElement;
  title: ReactElement;
  children: ReactElement | ReactElement[];
  onPrimaryButtonClick: () => void;
  primaryButtonText: ReactElement;
  isPrimaryButtonDisabled?: boolean;
  onSecondaryButtonClick: () => void;
  secondaryButtonText: ReactElement;
};

const CancelSubscriptionStepLayout = ({
  illustration,
  title,
  children,
  onPrimaryButtonClick,
  primaryButtonText,
  isPrimaryButtonDisabled,
  onSecondaryButtonClick,
  secondaryButtonText,
}: Props) => (
  <>
    <div className="cancel-subscription-step-layout__banner">
      <div className="cancel-subscription-step-layout__banner-illustration">
        { illustration }
      </div>
    </div>

    <h3 className="mb-4">{ title }</h3>

    <div className="text_lg">{ children }</div>

    <Button
      onClick={onPrimaryButtonClick}
      size="lg"
      block
      className="mt-8"
      disabled={isPrimaryButtonDisabled}
      data-qa="continueButton"
    >
      { primaryButtonText }
    </Button>
    <Button
      onClick={onSecondaryButtonClick}
      variant="primary-outline"
      size="lg"
      block
      className="mt-4"
      data-qa="cancelButton"
    >
      { secondaryButtonText }
    </Button>
  </>
);

export default CancelSubscriptionStepLayout;
