import initialState, { ReferralsState } from './referrals-initial-state';
import * as actionTypes from './referrals-action-types';

const referrals = (state: ReferralsState = initialState, action: actionTypes.ReferralsAction): ReferralsState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      return { ...state, ...action.payload, isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default referrals;
