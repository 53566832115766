import React, { FC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { FormattedPrice } from 'components/shared/formatter/formatter';

type Props = {
  price: number;
  currency: string;
  paymentDate?: number;
  isSubscriptionActive?: boolean;
}

const PlanChangedNotification: FC<Props> = ({ price, paymentDate, currency, isSubscriptionActive }) => {
  const messageSubscriptionActive = (
    <FormattedMessage
      id="pricePlans.changePlanModal.changePlanSuccess"
      defaultMessage="Plan successfully changed. You will be charged {price} on {paymentDate}."
      values={{
        price: <FormattedPrice price={price} currency={currency} />,
        paymentDate: (
          <FormattedDate
            value={paymentDate! * 1000}
            year="numeric"
            month="short"
            day="numeric"
          />
        ),
      }}
    />
  );

  const messageSubscriptionInactive = (
    <FormattedMessage
      id="pricePlans.changePlanModal.planChangedWhenSubscriptionInactive"
      defaultMessage="Plan changed successfully. You will be charged {price} when your subscription is renewed."
      values={{
        price: <FormattedPrice price={price} currency={currency} />,
      }}
    />
  );

  const messageTrialNotStarted = (
    <FormattedMessage
      id="pricePlans.changePlanModal.planChangedBeforeTrialStarted"
      defaultMessage="Plan successfully changed."
    />
  );

  if (paymentDate) {
    if (isSubscriptionActive) {
      return messageSubscriptionActive;
    }

    return messageSubscriptionInactive;
  }

  return messageTrialNotStarted;
};

export default PlanChangedNotification;
