import React  from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  CustomView,
  MobileView,
  isMobile,
} from 'react-device-detect';
import { Button } from '@setapp/ui-kit';

import type { App } from 'state/apps/apps-initial-state';

import urls from 'config/urls';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import AutoDownloadSection from 'components/shared/auto-download-section/auto-download-section';
import SuccessfulRegistrationAppsGridSection
  from '../successful-registration-apps-grid-section/successful-registration-apps-grid-section';
import SetappDescriptionSectionForDesktop
  from '../setapp-description-section-for-desktop/setapp-description-section-for-desktop';


import './successful-registration-single-app-page-content.scss';

type Props = {
  onDirectDownloadClick: () => void;
  onHowItWorksButtonClick: () => void;
  appsList: App[];
  app: App;
};

const SuccessfulRegistrationSingleAppPageContent = ({
  onDirectDownloadClick,
  appsList,
  onHowItWorksButtonClick,
  app,
}: Props) => (
  <FullscreenLayout withHeader>
    <FullscreenLayout.PrimaryContent wideContainer>
      <div className="successful-registration-single-app-page-content">
        <MobileView>
          <h1 className="h4">
            <FormattedMessage
              id="setappDescriptionSectionForMobile.title"
              defaultMessage="A link to download {appName} on Mac was sent to your email."
              values={{ appName: app.name }}
            />
          </h1>
          <Button
            tag={Link}
            to={urls.account}
            className="installation-instructions-for-mobile__button mt-4"
          >
            <FormattedMessage
              id="setappDescriptionSectionForMobile.goToAccountBtnTitle"
              defaultMessage="Go to my account"
            />
          </Button>
        </MobileView>
        <CustomView condition={!isMobile}>
          <SetappDescriptionSectionForDesktop
            appName={app.name}
            onDirectDownloadClick={onDirectDownloadClick}
            onHowItWorksButtonClick={onHowItWorksButtonClick}
          />
          <AutoDownloadSection />
        </CustomView>
      </div>
    </FullscreenLayout.PrimaryContent>
    <FullscreenLayout.SecondaryContent>
      <SuccessfulRegistrationAppsGridSection
        appsList={appsList}
        currentApp={app}
      />
    </FullscreenLayout.SecondaryContent>
  </FullscreenLayout>
);

export default SuccessfulRegistrationSingleAppPageContent;
