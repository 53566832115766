import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';

import { getPrimarySubscription, getUser } from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { TrialAvailabilityStatus } from 'state/trial-status/trial-status-types';

import useTrialAvailabilityStatus from 'components/hooks/use-trial-availability-status/use-trial-availability-status';

type Params = {
  shouldIgnore?: boolean;
}

const useTrialAvailabilityStatusGuard = ({ shouldIgnore }: Params = {}) => {
  const history = useHistory();

  const user = useSelector(getUser);
  const subscription = useSelector(getPrimarySubscription);
  const { status: trialAvailabilityStatus } = useTrialAvailabilityStatus();

  if (!subscription || !trialAvailabilityStatus || shouldIgnore) return;

  const isDesktopCcRequired = user.desktopCcRequired;
  const isSubscriptionNew = subscription.status === subscriptionStatuses.NEW;

  const isTrialUnknown = trialAvailabilityStatus === TrialAvailabilityStatus.unknown;
  const isTrialNotAllowed = trialAvailabilityStatus === TrialAvailabilityStatus.trialNotAllowed;
  const shouldRedirectByTrialStatus = isTrialUnknown || isTrialNotAllowed;

  const shouldRedirectToSubscriptionPage = isDesktopCcRequired
    && isSubscriptionNew
    && shouldRedirectByTrialStatus;

  if (shouldRedirectToSubscriptionPage) {
    history.replace(urls.subscription);
  }
};

export default useTrialAvailabilityStatusGuard;
