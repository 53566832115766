import React from 'react';
import type { ChangeEvent, FormEvent } from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import Form from 'react-bootstrap/lib/Form';
import { FormField, Button } from '@setapp/ui-kit';

import './redeem-gift-card.scss';

const messages = defineMessages({
  placeholder: {
    id: 'giftCard.redeem.form.placeholder',
    defaultMessage: 'Enter code here',
  },
  label: {
    id: 'giftCard.redeem.form.label',
    defaultMessage: 'Gift card code',
  },
});

type Props = InjectedIntlProps & {
  code: string;
  codeError: string;
  isProcessing: boolean;
  onFieldChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const RedeemGiftCard = ({
  isProcessing, onSubmit, intl, code, codeError, onFieldChange,
}: Props) => (
  <div className="redeem-gift-card">
    <Form
      onSubmit={onSubmit}
      className="redeem-gift-card__form"
      noValidate
    >
      <FormField
        id="gift-card-code"
        name="gift-card-code"
        type="text"
        bsSize="lg"
        placeholder={intl.formatMessage(messages.placeholder)}
        helpText={codeError}
        invalid={Boolean(codeError)}
        label={intl.formatMessage(messages.label)}
        onChange={onFieldChange}
        value={code}
      />
      <Button
        className="redeem-gift-card__submit-button"
        type="submit"
        size="lg"
        disabled={isProcessing}
      >
        <FormattedMessage
          id="giftCard.redeem.activateCodeButton"
          defaultMessage="Activate code"
        />
      </Button>
    </Form>
  </div>
);

export { RedeemGiftCard as PureRedeemGiftCard };

export default injectIntl(RedeemGiftCard);
