import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import PricePlanInfo from '../price-plan-info/price-plan-info';
import BillingPeriodInfo from '../billing-period-info/billing-period-info';

import './current-plan-step.scss';

type Props = {
  canCancelSubscription: boolean;
  canChangeBillingPeriod: boolean;
  canChangePricePlan: boolean;
  primarySubscription: Subscription;
  currentPricePlan: PricePlan;
  onChangePlan: () => void;
  onChangePeriod: () => void;
  onCancelSubscription: () => void;
};

const CurrentPlanStep = (props: Props) => {
  const {
    canCancelSubscription,
    canChangeBillingPeriod,
    canChangePricePlan,
    primarySubscription,
    currentPricePlan,
    onChangePlan,
    onChangePeriod,
    onCancelSubscription,
  } = props;

  return (
    <div className="current-plan-step">
      <div>
        <div className="mb-10">
          <FormattedMessage
            id="manageSubscriptionModal.currentPlanStep.description"
            defaultMessage="Edit your current plan or switch to another one."
          />
        </div>
        <PricePlanInfo
          canChangePricePlan={canChangePricePlan}
          pricePlan={currentPricePlan}
          onChangePlan={onChangePlan}
        />
        <div className="current-plan-step__billing-period">
          <BillingPeriodInfo
            canChangeBillingPeriod={canChangeBillingPeriod}
            primarySubscription={primarySubscription}
            pricePlan={currentPricePlan}
            onChangePeriod={onChangePeriod}
          />
        </div>
      </div>

      {canCancelSubscription && (
        <div className="current-plan-step__cancel">
          <Button
            className="current-plan-step__cancel-button"
            variant="link"
            onClick={onCancelSubscription}
          >
            <FormattedMessage
              id="manageSubscriptionModal.currentPlanStep.cancelSubscriptionButton"
              defaultMessage="Cancel subscription"
            />
          </Button>

          <div className="current-plan-step__cancel-description">
            <FormattedMessage
              id="manageSubscriptionModal.currentPlanStep.cancelDescription"
              defaultMessage="If you end your subscription now, you can still use Setapp until the end of this billing period."
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentPlanStep;
