import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import type { NextPricePlanCalculationData } from 'services/price-plans/price-plans-api';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import {
  hasSubscriptionHiddenPricePlan as hasSubscriptionHiddenPricePlanSelector,
  isUserFamilyOwner as isUserFamilyOwnerSelector,
} from 'state/root-reducer';

import ButtonBack from 'components/shared/button-back/button-back';

import DowngradeFlow from 'components/user-flow/change-price-plan/downgrade-flow/downgrade-flow';
import UpgradeFlow from 'components/user-flow/change-price-plan/upgrade-flow/upgrade-flow';

import ConfirmDropFamilyStep from '../confirm-drop-family-step/confirm-drop-family-step';
import ConfirmRegularPlanStep from '../confirm-regular-plan-step/confirm-regular-plan-step';

type Step = 'confirmDropFamilyStep'
  | 'confirmRegularPlanStep'
  | 'downgradeFlow'
  | 'upgradeFlow';

type Props = {
  primarySubscription: Subscription;
  currentPricePlan: PricePlan;
  nextPricePlan: PricePlan;
  nextPricePlanCalculationData: NextPricePlanCalculationData;
  shouldHideBackButton?: boolean;
  onGoBackward: () => void;
  onClose: () => void;
  onStartPayment?: () => void;
  onStopPayment?: () => void;
  onSuccessPlanChange?: () => void;
};

/**
 * Flow for changing price plan with the following logic:
 * - show confirm drop family message if user is a family owner and want to change price plan
 * - show confirm regular price plan message if user has a special plan that cannot be returned to after changing
 * - show downgrade or upgrade flow depend on change price plan strategy
 */
const ChangePricePlanFlow = (props: Props) => {
  const {
    primarySubscription,
    currentPricePlan,
    nextPricePlan,
    nextPricePlanCalculationData,
    shouldHideBackButton,
    onGoBackward,
    onClose,
    onStartPayment,
    onStopPayment,
    onSuccessPlanChange,
  } = props;

  const isUserFamilyOwner = useSelector(isUserFamilyOwnerSelector);
  const hasSubscriptionHiddenPricePlan = useSelector(hasSubscriptionHiddenPricePlanSelector);

  const changePriceFlow = nextPricePlanCalculationData.strategy === 'charge'
    ? 'upgradeFlow'
    : 'downgradeFlow';

  const getInitialStep = (): Step => {
    if (isUserFamilyOwner) {
      return 'confirmDropFamilyStep';
    }

    if (hasSubscriptionHiddenPricePlan) {
      return 'confirmRegularPlanStep';
    }

    return changePriceFlow;
  };

  const [step, setStep] = useState<Step>(getInitialStep());

  const handleConfirmChangePlan = () => {
    setStep(changePriceFlow);
  };

  const renderBackButton = () => {
    if (shouldHideBackButton) {
      return null;
    }

    if (['confirmDropFamilyStep', 'confirmRegularPlanStep'].includes(step)) {
      return (
        <div className="mb-5">
          <ButtonBack onClick={onGoBackward} />
        </div>
      );
    }

    return null;
  };

  const renderStep = () => {
    switch (step) {
      case 'confirmDropFamilyStep':
        return (
          <ConfirmDropFamilyStep
            nextPaymentDate={nextPricePlanCalculationData.nextPaymentDate}
            onConfirm={handleConfirmChangePlan}
          />
        );
      case 'confirmRegularPlanStep':
        return (
          <ConfirmRegularPlanStep
            currentPricePlan={currentPricePlan}
            onConfirm={handleConfirmChangePlan}
          />
        );
      case 'downgradeFlow':
        return (
          <DowngradeFlow
            primarySubscription={primarySubscription}
            currentPricePlan={currentPricePlan}
            nextPricePlan={nextPricePlan}
            nextPricePlanCalculationData={nextPricePlanCalculationData}
            onGoBackward={onGoBackward}
            onClose={onClose}
            onSuccessPlanChange={onSuccessPlanChange}
            shouldHideBackButton={shouldHideBackButton}
          />
        );
      case 'upgradeFlow':
        return (
          <UpgradeFlow
            primarySubscription={primarySubscription}
            currentPricePlan={currentPricePlan}
            nextPricePlan={nextPricePlan}
            nextPricePlanCalculationData={nextPricePlanCalculationData}
            onGoBackward={onGoBackward}
            onClose={onClose}
            onStartPayment={onStartPayment}
            onStopPayment={onStopPayment}
            onSuccessPlanChange={onSuccessPlanChange}
            shouldHideBackButton={shouldHideBackButton}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderBackButton()}
      {renderStep()}
    </>
  );
};

export default ChangePricePlanFlow;
