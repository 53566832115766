import React from 'react';
import Modal from 'components/shared/modal/modal';

import './how-it-works-video-modal.scss';

type Props = {
  show: boolean;
  onHide: () => void;
  title: string;
}

const HowItWorksVideoModal = (props: Props) => {
  const {
    show,
    onHide,
    title,
  } = props;

  return (
    <Modal
      fullScreen
      fullSizeBody
      show={show}
      onHide={onHide}
      title
    >
      <div className="how-it-works-video-modal__content">
        <div className="how-it-works-video-modal__video">
          <iframe
            data-testid="how-it-works-video-iframe"
            className="how-it-works-video-modal__iframe"
            title={title}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/RZDDDyNssrc?autoplay=1&amp;amp;rel=0&amp;amp;showinfo=0&amp;amp;vq=hd720&amp;amp;enablejsapi=1&amp;"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </Modal>
  );
};

export default HowItWorksVideoModal;
