import React, { type FC } from 'react';

import { useAiOfferContext } from 'components/pages/ai-offer/context/ai-offer-context';

import tableRows from './table-rows';

import './plans-comparison.scss';

const [headRow, ...bodyRows] = tableRows;

const PlansComparison: FC = () => {
  const { plansConfig } = useAiOfferContext();

  // "plansConfig" returns either configs for [currentPlan, aiExpertPlan]
  // or [currentPlan, aiEnthusiastPlan, aiExpertPlan]. If "plansConfig" contains
  // AI Enthusiast Plan config we should compare it with AI Expert Plan,
  // otherwise we should compare current plan with AI Expert Plan.
  const columnPlans = plansConfig.slice(-2);

  return (
    <table className="plans-comparison">
      <thead>
        {headRow && (
          <tr className="plans-comparison__row">
            <th className="plans-comparison__cell">
              <h3 className="plans-comparison__title">
                {headRow.title}
              </h3>
            </th>
            {columnPlans.map((planConfig) => (
              <th key={planConfig.pricePlan.tierType} className="plans-comparison__cell">
                <h4 className="plans-comparison__title">
                  {headRow.getValue(planConfig)}
                </h4>
              </th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>
        {bodyRows.map(({ title, getValue }, idx) => (
          <tr key={idx} className="plans-comparison__row">
            <td className="plans-comparison__cell">{title}</td>
            {columnPlans.map((planConfig) => (
              <td key={planConfig.pricePlan.tierType} className="plans-comparison__cell">
                {getValue(planConfig)}
              </td>
            ))}
          </tr>)
        )}
      </tbody>
    </table>
  );
};

export default PlansComparison;
