import React from 'react';
import { FormattedMessage } from 'react-intl';

import './family-plan-benefits.scss';
import handSignEmoji from '../images/hand-sign.png';
import pointingEmoji from '../images/pointing.png';
import raisedHandEmoji from '../images/raised-hand.png';
import thumbsUpEmoji from '../images/thumbs-up.png';

type Props = {
  maxFamilySeats?: number;
};

const FamilyPlanBenefits = ({ maxFamilySeats }: Props) => {
  const benefits = [
    {
      icon: raisedHandEmoji,
      text: <FormattedMessage
        id="familyPlan.benefits.people"
        defaultMessage="Setapp Family is you + up to {maxFamilySeats} {maxFamilySeats, plural, one {person} other {people}}"
        values={{ maxFamilySeats }}
            />,
    },
    {
      icon: pointingEmoji,
      text: <FormattedMessage
        id="familyPlan.benefits.manage"
        defaultMessage="All accounts are managed from one"
            />,
    },
    {
      icon: thumbsUpEmoji,
      text: <FormattedMessage
        id="familyPlan.benefits.macPerPerson"
        defaultMessage="Family members have 1 device per person"
            />,
    },
    {
      icon: handSignEmoji,
      text: <FormattedMessage
        id="familyPlan.benefits.additionSeat"
        defaultMessage="Family owner can purchase extra devices"
            />,
    },
  ];

  return (
    <div>
      {benefits.map((benefit) => (
        <div className="family-benefit" key={benefit.icon}>
          <p>
            <img className="family-benefit__emoji" src={benefit.icon} width="22" alt="" />
            {benefit.text}
          </p>
        </div>
      ))}
    </div>
  );
};

export default FamilyPlanBenefits;
