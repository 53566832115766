import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AI_MARATHON_1_END_DATE } from '../../config';
import { useAiOfferContext } from '../../context/ai-offer-context';

import './countdown-banner.scss';

const CountdownBanner = () => {
  const { isCountdownVisible } = useAiOfferContext();
  const [countdown, setCountdown] = useState<string[]>([]);
  const [countTimeout, setCountTimeout] = useState<NodeJS.Timeout | null>(null);

  const padNumber = (num: number) => {
    return num.toString().padStart(2, '0');
  };

  const updateCountdown = () => {
    const diffMillis = AI_MARATHON_1_END_DATE - Date.now();

    const diffSeconds = Math.floor(diffMillis / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    const seconds = diffSeconds % 60;
    const minutes = diffMinutes % 60;
    const hours = diffHours % 24;
    const days = diffDays;

    setCountdown([days, hours, minutes, seconds].map(padNumber));
  };

  useEffect(() => {
    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
    setCountTimeout(interval);

    return () => {
      if (countTimeout) {
        clearInterval(countTimeout);
      }
    };
  }, []);

  if (!isCountdownVisible) {
    return null;
  }

  return (
    <div className="countdown">
      <div className="countdown__container">
        <div className="countdown__text">
          <p className="countdown__text-header">
            <FormattedMessage
              id="aiOffer.countdown.header"
              defaultMessage="25% off the annual plan"
            />
          </p>
          <p className="countdown__text-description">
            <FormattedMessage
              id="aiOffer.countdown.description"
              defaultMessage="Grab our limited AI+ Marathon offer"
            />
          </p>
        </div>

        <div className="countdown__num-container">
          {countdown.map((num, idx) => (
            <span className="countdown__number" key={idx + num}>{num}</span>
          ))}
          <p className="countdown__num-text">
            <FormattedMessage
              id="aiOffer.countdown.days"
              defaultMessage="days"
            />
          </p>
          <p className="countdown__num-text">
          <FormattedMessage
              id="aiOffer.countdown.hours"
              defaultMessage="hrs"
          />
          </p>
          <p className="countdown__num-text">
            <FormattedMessage
              id="aiOffer.countdown.minutes"
              defaultMessage="mins"
            />
          </p>
          <p className="countdown__num-text">
            <FormattedMessage
              id="aiOffer.countdown.seconds"
              defaultMessage="secs"
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default CountdownBanner;
