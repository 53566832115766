import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from 'components/shared/modal/modal';
import PaymentForm from 'components/shared/payment-form/payment-form';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import SwitchToFamilyPlanModalContent from './switch-to-family-plan-modal-content';


import './switch-to-family-plan-modal.scss';

type Props = {
  isModalShown: boolean;
  isTrialOrNewUser: boolean;
  onModalHide: () => void;
  pricePlan: PricePlan;
  switchToFamilyAmount: number;
  onConfirmSwitchClick: () => void;
  showAddPaymentDetailsStep: boolean;
  showPaymentDetailsForm: boolean;
  onAddPaymentDetailsClick: () => void;
  onAddPaymentDetailsSuccess: () => void;
  isPaymentMethodLoading: boolean;
  isFamilyLoading: boolean;
};

const SwitchToFamilyPlanModal = (props: Props) => {
  const {
    isModalShown,
    onModalHide,
    onConfirmSwitchClick,
    showAddPaymentDetailsStep,
    showPaymentDetailsForm,
    onAddPaymentDetailsClick,
    onAddPaymentDetailsSuccess,
    isPaymentMethodLoading,
    switchToFamilyAmount,
    isTrialOrNewUser,
    pricePlan,
    isFamilyLoading,
  } = props;

  return (
    <Modal
      show={isModalShown}
      onHide={onModalHide}
      // @ts-expect-error TS(2322): Type '{ children: Element; show: any; onHide: any;... Remove this comment to see the full error message
      size="sm"
      title={
        <FormattedMessage
          id="familyPlan.switchToFamilyPlanModal.title"
          defaultMessage="Switching to Family plan"
        />
      }
    >
      {getContent()}
    </Modal>
  );

  function getContent() {
    if (showPaymentDetailsForm) {
      return (
        <PaymentForm
          onSuccessSubmit={onAddPaymentDetailsSuccess}
          braintreeFormOptions={{
            submitBtnTitle: <FormattedMessage
              id="familyPlan.switchToFamilyPlanModal.paymentFormButton"
              defaultMessage="Add &amp; Switch"
                            />,
          }}
        />
      );
    }

    if (isPaymentMethodLoading) {
      return (
        <div className="text-center">
          <AnimatedLogo animate />
        </div>
      );
    }

    return (
      <SwitchToFamilyPlanModalContent
        showAddPaymentDetailsStep={showAddPaymentDetailsStep}
        onAddPaymentDetailsClick={onAddPaymentDetailsClick}
        onConfirmSwitchClick={onConfirmSwitchClick}
        onModalHide={onModalHide}
        switchToFamilyAmount={switchToFamilyAmount}
        isTrialOrNewUser={isTrialOrNewUser}
        pricePlan={pricePlan}
        isLoading={isFamilyLoading}
      />
    );
  }
};

export default SwitchToFamilyPlanModal;
