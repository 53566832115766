import React from 'react';
import type { ReactNode } from 'react';
import { Modal as BaseModal } from '@setapp/ui-kit';
import type { ModalProps as BaseModalProps } from '@setapp/ui-kit/src/components/modal/modal';
import { injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';

import globalMessages from '../../../global-messages';
import ModalLoadingState from './modal-loading-state';

type Props = InjectedIntlProps & BaseModalProps & {
  title?: ReactNode;
  fullSizeBody?: boolean;
  show?: boolean;
  withCloseButton?: boolean;
  header?: ReactNode;
  hideHeader?: boolean;
  children?: ReactNode;
  qaLabel?: string;
  isLoading?: boolean;
  onHide?: () => void;
  onExited?: () => void;
};

const Modal = ({
  fullSizeBody,
  show,
  withCloseButton = true,
  header,
  hideHeader,
  title,
  children,
  intl,
  qaLabel,
  onHide,
  onExited,
  isLoading,
  ...modalProps
}: Props) => (
  <BaseModal
    {...modalProps}
    isOpen={show}
    ariaContentLabelledBy="modal-title"
    data-qa={qaLabel}
    onRequestClose={onHide}
    onAfterClose={onExited}
    fullSizeBody={fullSizeBody}
  >

    {!hideHeader && (
      <BaseModal.Header
        withCloseButton={withCloseButton}
        onCloseClick={onHide}
        ariaCloseButtonLabel={intl.formatMessage(globalMessages.btnCloseAccessibilityLabel)}
      />
    )}

    <BaseModal.Body>
      {!isLoading && header}

      {title && (
        <BaseModal.Title>
          {isLoading ? '' : title}
        </BaseModal.Title>
      )}

      {isLoading ? <ModalLoadingState /> : children}
    </BaseModal.Body>
  </BaseModal>
);

export default injectIntl(Modal);
