import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

const RetryChargeButton = ({ disabled = false, onClick }: Props) => {
  return (
    <Button
      size="sm"
      disabled={disabled}
      onClick={onClick}
    >
      <FormattedMessage
        id="subscriptionInfo.statusNotifications.retryChargeButton"
        defaultMessage="Retry payment"
      />
    </Button>
  );
};

export default RetryChargeButton;
