import request from 'utils/request';
import { apiURL } from 'config/api';
import type { InvoicingInformation, CardDetails } from '../payment-details-api';

export type BraintreePaymentDetailsPayload = InvoicingInformation & {
  nonce: string;
  // Country is added when PayPal doesn't return user's country to provide additional evidence for Taxamo service
  country?: string;
  sync?: boolean;
};

export type BraintreePayPalPaymentDetails = InvoicingInformation & {
  provider: 'braintree';
  type: 'paypal';
  details: {
    email: string;
  };
};

export type BraintreeCardPaymentDetails = InvoicingInformation & {
  provider: 'braintree';
  type: 'card';
  details: CardDetails;
};

export type BraintreePaymentDetails = BraintreePayPalPaymentDetails | BraintreeCardPaymentDetails;

export default class BraintreePaymentDetailsApi {
  createPaymentDetails(payload: BraintreePaymentDetailsPayload): Promise<BraintreePaymentDetails> {
    return request.post(apiURL.payment, { body: payload });
  }

  updatePaymentDetails(payload: BraintreePaymentDetailsPayload): Promise<BraintreePaymentDetails> {
    return request.patch(apiURL.payment, { body: payload });
  }
}
