// Seat management is deprecated functionality to be removed soon
// so no need have unit tests for this file
// istanbul ignore file

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@setapp/ui-kit';

import './notification-cancel-seats-link.scss';

type Props = {
  isRequestProcessing: boolean;
  onCancelSeatsClick: () => Promise<void>;
}

const NotificationCancelSeatsLink = ({ isRequestProcessing, onCancelSeatsClick }: Props) => (
  <div className="mt-4">
    <Button
      className="notification-cancel-seats-link"
      variant="link"
      onClick={onCancelSeatsClick}
      disabled={isRequestProcessing}
    >
      <FormattedMessage
        id="subscriptionInfo.statusNotifications.cancelAdditionalSeatsButton"
        defaultMessage="I don’t want to add extra devices"
      />
    </Button>
  </div>
);

export default NotificationCancelSeatsLink;
