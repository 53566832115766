import React, { FC, ReactNode } from 'react';
import { Button } from '@setapp/ui-kit';

import './customer-oauth-content.scss';

type CustomerOauthContentProps = {
  titleText: ReactNode;
  descriptionText?: ReactNode;
  buttonText?: ReactNode;
  children?: ReactNode;
  onButtonClick?: () => void;
};

const CustomerOauthContent: FC<CustomerOauthContentProps> = ({
  titleText,
  descriptionText,
  buttonText,
  children,
  onButtonClick,
}) => {
  return (
    <div className="customer-oauth__container">
      <h2 className="customer-oauth__title">{titleText}</h2>
      {descriptionText && <p className="customer-oauth__description">{descriptionText}</p>}
      {buttonText && onButtonClick && (
        <Button block className="mt-7" size="lg" onClick={onButtonClick}>{buttonText}</Button>
      )}
      {children}
    </div>
  );
};

export default CustomerOauthContent;
