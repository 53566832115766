import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import type {
  InvoicingInformation,
  PaymentDetails as PaymentDetailsType,
} from 'services/payment-details-api/payment-details-api';

import * as paymentMethodTypes from 'state/payment-method/payment-method-types';
import { showSuccessNotification } from 'state/notifier/notifier-reducer';
import { getFeatureFlags, getPrimarySubscription } from 'state/root-reducer';

import PanelBox from 'components/shared/panel-box/panel-box';
import PaymentDetailsActionText, {
  paymentDetailsActionMessages,
} from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentDetailsModal from 'components/modals/payment-details/payment-details-modal';

import PaymentMethodPresentation from './payment-method-details/payment-method-presentation/payment-method-presentation';
import BusinessAccountDetails from './business-account-details/business-account-details';
import PaymentMethodDetails from './payment-method-details/payment-method-details';
import CardInfoNotification from './card-info-notification/card-info-notification';

import './payment-details-view.scss';

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  featureFlags: getFeatureFlags(state),
  primarySubscription: getPrimarySubscription(state),
});

const mapActionsToProps = {
  showSuccessNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

const warningMessageMapping = {
  paymentFailed: (
    <FormattedMessage
      id="paymentDetails.paymentFailed"
      defaultMessage="Please update your payment details to continue using Setapp."
    />
  ),
  indianBankRestrictions: (
    <FormattedMessage
      id="paymentDetails.indianBankRestrictions"
      defaultMessage="Banks in India may prevent recurring payments. You can pay for Setapp with a gift card."
    />
  ),
};

type WarningMessageType = 'paymentFailed' | 'indianBankRestrictions' | null;
type PanelBoxType = 'normal' | 'warning';

type Props = InjectedIntlProps & ConnectedProps<typeof connector> & {
  paymentMethod: PaymentDetailsType | null;
  businessAccountDetails: InvoicingInformation | null;
  allowRemoving: boolean;
  allowUpdating: boolean;
  creditCardExpiration: {
    isExpired: boolean;
    isAboutToExpire: boolean;
  };
  onAddClick: () => void;
  onRemoveClick: () => void;
};

type State = {
  showUpdateModal: boolean;
};

class PaymentDetails extends PureComponent<Props, State> {
  state = {
    showUpdateModal: false,
  };

  renderPaymentDetailsTitle = () => (
    <h5 className="payment-details__title">
      <FormattedMessage id="paymentDetails.title" defaultMessage="Payment details" />
    </h5>
  )

  renderWarningMessage() {
    const warningMessageType = this.getWarningMessageType();

    if (warningMessageType === null) {
      return null;
    }

    return (
      <div className="payment-details__warning-message">
        {warningMessageMapping[warningMessageType]}
      </div>
    );
  }

  renderViewGiftCardButton = () => {
    const warningMessageType = this.getWarningMessageType();

    if (warningMessageType !== 'indianBankRestrictions') {
      return null;
    }

    return (
      <Link
        className="mr-4"
        to={urls.giftCards}
      >
        <FormattedMessage id="paymentDetails.giftCardsLink" defaultMessage="View gift cards" />
      </Link>
    );
  }

  renderEmptyPaymentDetails = () => {
    const { onAddClick } = this.props;

    return (
      <div className="payment-details">
        <PanelBox type={this.getPanelBoxType()}>
          <PanelBox.Content className="payment-details__content">
            {this.renderPaymentDetailsTitle()}

            <PaymentMethodPresentation
              details={[
                <svg width="175" height="36" viewBox="0 0 175 36" fill="none" key={1}>
                  <rect width="40" height="12" rx="2" fill="#F5F5F5" />
                  <rect y="24" width="60" height="12" rx="2" fill="#F5F5F5" />
                  <rect x="45" width="40" height="12" rx="2" fill="#F5F5F5" />
                  <rect x="135" width="40" height="12" rx="2" fill="#F5F5F5" />
                  <rect x="90" width="40" height="12" rx="2" fill="#F5F5F5" />
                </svg>,
              ]}
            />

            {this.renderWarningMessage()}

            <div className="payment-details__buttons">
              {this.renderViewGiftCardButton()}

              <Button
                variant="link"
                onClick={onAddClick}
                data-qa="add-payment-method-btn"
              >
                <FormattedMessage id="paymentDetails.addLink" defaultMessage="Add payment method" />
              </Button>
            </div>
          </PanelBox.Content>
        </PanelBox>
      </div>
    );
  }

  renderPaymentDetails = () => {
    const {
      paymentMethod,
      businessAccountDetails,
      allowRemoving,
      allowUpdating,
      intl,
      onRemoveClick,
    } = this.props;
    const { showUpdateModal } = this.state;

    return (
      <div className="payment-details">
        {this.getCardNotification()}
        <PanelBox type={this.getPanelBoxType()}>
          <PanelBox.Content className="payment-details__content">
            {this.renderPaymentDetailsTitle()}

            <PaymentMethodDetails paymentMethod={paymentMethod} />

            {this.renderWarningMessage()}

            {businessAccountDetails && <BusinessAccountDetails data={businessAccountDetails} />}

            <div className="payment-details__buttons">
              {this.renderViewGiftCardButton()}

              {allowUpdating && (
                <Button
                  className="mr-4"
                  variant="link"
                  onClick={this.onUpdateClick}
                  data-qa="update-payment-details-btn"
                  aria-label={intl.formatMessage(paymentDetailsActionMessages.update)}
                >
                  <FormattedMessage id="paymentDetails.updateLink" defaultMessage="Update" />
                </Button>
              )}

              {allowRemoving && (
                <Button
                  variant="link"
                  onClick={onRemoveClick}
                  data-qa="remove-payment-details-btn"
                  aria-label={intl.formatMessage(paymentDetailsActionMessages.remove)}
                >
                  <FormattedMessage id="paymentDetails.removeLink" defaultMessage="Remove" />
                </Button>
              )}
            </div>
          </PanelBox.Content>
        </PanelBox>

        <PaymentDetailsModal
          show={showUpdateModal}
          onHide={this.onUpdateModalHide}
          title={<PaymentDetailsActionText action="update" />}
          onPaymentDetailsSaved={this.onPaymentDetailsUpdateSuccess}
          braintreeFormOptions={{
            paymentMethod: paymentMethod!.type ?? undefined,
          }}
        />
      </div>
    );
  }

  render() {
    const { paymentMethod } = this.props;

    return paymentMethod ? this.renderPaymentDetails() : this.renderEmptyPaymentDetails();
  }

  shouldShowPaymentFailedMessage() {
    const { primarySubscription } = this.props;

    return Boolean(primarySubscription?.lastPaymentFailed);
  }

  shouldShowIndianBankRestrictions() {
    const { featureFlags } = this.props;

    return Boolean(featureFlags.indianBankRestrictions.value);
  }

  getWarningMessageType(): WarningMessageType {
    const { paymentMethod } = this.props;
    const showPaymentFailedMessage = this.shouldShowPaymentFailedMessage();
    const showIndianBankRestrictions = this.shouldShowIndianBankRestrictions();

    if (paymentMethod) {
      switch (true) {
        case showPaymentFailedMessage:
          return 'paymentFailed';
        case showIndianBankRestrictions:
          return 'indianBankRestrictions';
        default:
          return null;
      }
    }

    // If there is no payment method, we don't want to show the payment failed message
    switch (true) {
      case showIndianBankRestrictions:
        return 'indianBankRestrictions';
      default:
        return null;
    }
  }

  getPanelBoxType(): PanelBoxType {
    const warningMessageType = this.getWarningMessageType();

    return warningMessageType ? 'warning' : 'normal';
  }

  getCardNotification() {
    const { paymentMethod, creditCardExpiration } = this.props;

    if (!paymentMethod || paymentMethod.type !== paymentMethodTypes.CREDIT_CARD) {
      return null;
    }

    return <CardInfoNotification creditCardExpiration={creditCardExpiration} />;
  }

  onUpdateClick = () => {
    this.setState({ showUpdateModal: true });
  };

  onPaymentDetailsUpdateSuccess = () => {
    const { showSuccessNotification } = this.props;

    this.onUpdateModalHide();

    showSuccessNotification(
      <FormattedMessage
        id="paymentDetails.notifications.update"
        defaultMessage="Payment details successfully updated."
      />
    );
  };

  onUpdateModalHide = () => {
    this.setState({ showUpdateModal: false });
  };
}

export { PaymentDetails as PurePaymentDetails };

export default connector(injectIntl(PaymentDetails));
