import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { isValidHttpURL } from 'utils/location';
import { base64ToArrayBuffer, verifySignature } from 'utils/data-crypter';

import { VALID_OAUTH_SCOPE, OAuthScope } from 'utils/oauth';

type InputScope = string[];
type ValidScope = Array<OAuthScope>;

export type OAuthParams = {
  redirectUrl: string;
  clientId: string;
  scope: InputScope;
}

type SignatureQueryParam = {
  signature: string;
}

type ValidParams = OAuthParams & SignatureQueryParam & { scope: ValidScope | '' };

const publicKey = process.env.OAUTH_SIGNATURE_PUBLIC_KEY as string;

function isValidAuthScope(scope: InputScope) {
  return scope.length > 0 && scope.every((i) => VALID_OAUTH_SCOPE.includes(i as OAuthScope));
}

function validateParams({ redirectUrl, clientId, scope }: OAuthParams): boolean {
  return isValidHttpURL(redirectUrl) && Boolean(clientId) && isValidAuthScope(scope);
}

function verifyParams(signature: string, data: OAuthParams) {
  // object must have identical key order for signature validation
  const payload = JSON.stringify({
    'client_id': data.clientId,
    scope: data.scope,
    'redirect_url': data.redirectUrl,
  });
  const typedData = Uint8Array.from(payload, (c) => c.charCodeAt(0));
  const typedSignature: Uint8Array = base64ToArrayBuffer(signature);
  const typedPublicKey: Uint8Array = base64ToArrayBuffer(publicKey);

  return verifySignature(
    typedPublicKey,
    typedSignature,
    typedData,
  );
}

export default function useOAuthQueryParams(): { isError: boolean; params: ValidParams } {
  const [isError, setIsError] = useState(false);
  const [params, setParams] = useState<ValidParams>({
    redirectUrl: '',
    clientId: '',
    scope: [],
    signature: '',
  });
  const location = useLocation();

  useEffect(() => {
    const query = queryString.parse(location.search, { arrayFormat: 'index' });
    const signature = query['signature'] as string;
    const redirectUrl = query['redirect_url'] as string;
    const clientId = query['client_id'] as string;
    const scopeQuery = query['scope'] as string | Array<OAuthScope>;
    const scope: InputScope  = ([] as string[]).concat(scopeQuery);

    (async function () {
      const isParamsVerified = await verifyParams(
        signature,
        {
          clientId,
          scope,
          redirectUrl,
        },
      );

      const isParamsValid = Boolean(isParamsVerified)
        && validateParams({
          redirectUrl,
          clientId,
          scope,
        });

      if (isParamsValid) {
        setParams({
          redirectUrl,
          clientId,
          scope,
          signature,
        } as ValidParams);
        setIsError(false);
      } else {
        setIsError(true);
      }
    })();
  }, [location.search]);

  return { isError, params };
}
