export type Referrals = {
  id: string;
  url: string;
  usersInvited: number;
  usersActivated: number;
};

export type ReferralsState = Referrals & {
  isLoading: boolean;
};

const initialState: ReferralsState = {
  isLoading: false,
  id: '',
  url: '',
  usersInvited: 0,
  usersActivated: 0,
};

export default initialState;
