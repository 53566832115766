/* eslint-disable no-underscore-dangle */
import { SatuCookieHelper } from '@setapp/abn-tests-client';
import cookies from 'js-cookie';
import { apiURL } from 'config/api';
import { getAuthenticator, getTokenManager, getSignupMetadata } from 'utils/service-locators';
import Gtm from './analytics/gtm';

import $ from './request';
import { detectLocale, browserToServerLocale } from './intl';

const tokenManager = getTokenManager();
const signUpMetaData = getSignupMetadata();

function saveAuthTokens(response, remember = true) {
  tokenManager.saveAccessToken(response.token);
  tokenManager.saveRefreshToken(response.refreshToken, remember);
}

function saveSetappTestUserId(response) {
  const satuId = response.satu || null;

  if (satuId) {
    SatuCookieHelper.setSatuId(satuId);
  }
}

const auth = {
  isLoggedIn() {
    return !!tokenManager.getRefreshToken();
  },

  loginWithOneTimeToken(accessToken) {
    return $.post(apiURL.loginCrossAuthToken, {
      withoutTokenAuth: true,
      body: this._addParamsFromCookies({ accessToken }),
    })
      .then((resp) => {
        saveAuthTokens(resp);
        saveSetappTestUserId(resp);

        return resp;
      });
  },

  login({
    email, password, remember = true, captcha,
  }) {
    const requestOptions = {
      withoutTokenAuth: true,
      body: this._addParamsFromCookies({
        email,
        password,
        remember: Boolean(remember),
        captcha,
      }),
    };

    return $.post(apiURL.auth, requestOptions)
      .then((resp) => {
        saveAuthTokens(resp, remember);
        saveSetappTestUserId(resp);

        return resp;
      });
  },

  logout() {
    return $.post(apiURL.logout)
      .then(() => tokenManager.removeTokens());
  },

  _addParamsFromCookies(body) {
    const params = {
      satu: SatuCookieHelper.getSatuId(),
    };

    if (cookies.get('_ga')) {
      params.ga = cookies.get(Gtm.GA_COOKIE_NAME);
    }

    return {
      ...body,
      ...params,
    };
  },

  signup(data) {
    return getAuthenticator()
      .signUp(data, {
        language: browserToServerLocale(detectLocale()),
      });
  },

  requestPasswordReset(payload) {
    const { email, captcha } = payload;

    return $.post(apiURL.requestPasswordReset, { body: { email, captcha } });
  },

  resetPassword(password, token) {
    return $.patch(`${apiURL.restorePass}/${token}`, { body: { password } })
      .then((response) => {
        saveAuthTokens(response);
        saveSetappTestUserId(response);

        return response;
      });
  },

  requestNewAccessToken() {
    const refreshToken = tokenManager.getRefreshToken(),
      requestOptions = {
        withoutTokenAuth: true,
        body: { refreshToken },
      };

    if (!refreshToken) {
      return Promise.reject(new Error('Refresh token is not found'));
    }

    return $.post(apiURL.token, requestOptions)
      .then(
        (response) => tokenManager.saveAccessToken(response.token),
        (error) => {
          tokenManager.removeTokens();

          return Promise.reject(error);
        },
      );
  },

  /**
   * Authenticates user (does either signup or login) using social credentials.
   * @param {Object} data
   * @param {String} data.accessToken - provider accessToken
   * @param {String} [data.marketingSubscribed]
   * @param {String} [data.giftCardId]
   * @param {String} provider - social provider (google, apple)
   */
  authWithSocialCredentials(data = {}, provider) {
    return $.put(`${apiURL.socialAuth}/${provider}`, {
      body: {
        ...data,
        marketingSubscribed: data.marketingSubscribed || false,
        ...signUpMetaData.getAll(),
      },
      withoutTokenAuth: true,
    })
      .then(this._onSignUpSuccess);
  },

  /**
   * Links social account to the current user's account
   * @param {Object} credentials
   * @param {String} provider - social provider (google, apple)
   */
  linkSocialAccount(credentials, provider) {
    return $.put(`${apiURL.linkSocialAccount}/${provider}`, {
      body: credentials,
    });
  },

  requestCrossAuthToken() {
    return $.post(apiURL.crossAuthToken);
  },

  validateInvitationToken(token = '') {
    return $.get(`${apiURL.invitation}/${token}`);
  },

  validateRestorePassToken(token = '') {
    return $.get(`${apiURL.restorePass}/${token}`);
  },

  /**
   * @param {object} response
   * @returns {object}
   * @private
   */
  _onSignUpSuccess(response) {
    saveAuthTokens(response, true);
    saveSetappTestUserId(response);
    signUpMetaData.cleanAll();

    return response;
  },
};

export default auth;
