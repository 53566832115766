import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { DeviceIconUnused, Button } from '@setapp/ui-kit';

import InfoButton from 'components/shared/info-button/info-button';
import type { Subscription } from 'state/subscription/subscription-initial-state';
import * as subscriptionService from 'services/subscriptions/subscription';

const defaultTitle = <FormattedMessage id="devicesPage.emptySlotTitle" defaultMessage="Available" />;

export type Props = {
  subscription: Subscription;
  isTrial: boolean;
  onReactivateClick: (subscription: Subscription) => void;
  isPrimarySubscriptionActive?: boolean;
};

class EmptySeatItem extends PureComponent<Props> {
  render() {
    const { isTrial } = this.props;

    return (
      <div className="device-item">
        <div className="mr-5">
          <DeviceIconUnused size={60} />
        </div>
        <div className="device-item__content">
          {this.renderDescription()}

          {isTrial && (
            <div className="text_color-success">
              <FormattedMessage
                id="devicesPage.emptySlotTrialDescription"
                defaultMessage="Free on a trial period"
              />
            </div>
          )}

          {this.renderReactivateButton()}
        </div>
      </div>
    );
  }

  renderDescription() {
    const { subscription } = this.props;
    const {
      paymentPending,
      lastPaymentFailed,
    } = subscription;

    if (paymentPending) {
      return this.renderPendingDescription();
    }

    if (lastPaymentFailed) {
      return this.renderPaymentFailedDescription();
    }

    if (subscriptionService.isStatusCancelled(subscription)) {
      return this.renderCancelledDescription();
    }

    return this.renderDefaultDescription();
  }

  renderReactivateButton() {
    const { subscription, isPrimarySubscriptionActive } = this.props;
    const { canBeResumed } = subscriptionService;

    if (!canBeResumed(subscription) || !isPrimarySubscriptionActive) {
      return null;
    }

    return (
      <div className="device-item__button">
        <Button
          variant="link"
          onClick={this.handleReactivateClick}
          data-qa="reactivate-slot-button"
          className="mt-3"
        >
          <FormattedMessage id="devicesPage.emptySeat.reactivateBtn" defaultMessage="Reactivate" />
        </Button>
      </div>
    );
  }

  renderDefaultDescription() {
    return (
      <div>
        <div className="text_lg text_weight-medium" data-qa="empty-slot">
          {defaultTitle}
          {this.renderInfoButton()}
        </div>
        <div className="text_color-secondary">
          <FormattedMessage
            id="devicesPage.emptySlotDefaultDescription"
            defaultMessage="Connected Mac, iPhone, or iPad will appear here."
          />
        </div>
      </div>
    );
  }

  renderPaymentFailedDescription() {
    return (
      <div>
        <div className="text_lg text_weight-medium" data-qa="payment-failed-slot">
          {defaultTitle}
        </div>
        <div className="text_color-secondary">
          <FormattedMessage
            id="devicesPage.emptySlotFailedPaymentDescription"
            defaultMessage="Retry payment to use this seat."
          />
        </div>
      </div>
    );
  }

  renderPendingDescription() {
    return (
      <div>
        <div className="text_lg text_weight-medium" data-qa="payment-pending-slot">
          <FormattedMessage id="devicesPage.emptySlotPending" defaultMessage="Pending device" />
        </div>
        <div className="text_color-success">
          <FormattedMessage
            id="devicesPage.emptySlotPendingDescription"
            defaultMessage="Your payment is on its way, hold on a sec."
          />
        </div>
      </div>
    );
  }

  renderCancelledDescription() {
    const { subscription: { expirationDate } } = this.props;

    if (!expirationDate) {
      return null;
    }

    return (
      <div>
        <div className="text_lg text_weight-medium" data-qa="payment-canceled-slot">
          {defaultTitle}
          {this.renderInfoButton()}
        </div>
        <div className="text_color-secondary">
          <FormattedMessage
            id="devicesPage.emptySlotDescription"
            defaultMessage="Connected Mac, iPhone, or iPad will appear here."
          />
        </div>
        <div className="text_color-danger mt-1">
          <FormattedMessage
            id="devicesPage.emptySlotCancelledDescription"
            defaultMessage="Available until {expirationDate}"
            values={{
              expirationDate: (
                <FormattedDate
                  value={(expirationDate ?? 0) * 1000}
                  year="numeric"
                  month="short"
                  day="numeric"
                />
              ),
            }}
          />
        </div>
      </div>
    );
  }

  renderInfoButton() {
    return (
      <>
        &nbsp;
        <InfoButton>
          <FormattedMessage
            id="devicesPage.emptySlotHint"
            defaultMessage="Sign in to Setapp on a Mac to connect it. For iPhone & iPad, install any iOS app using QR codes in Setapp on Mac."
          />
        </InfoButton>
      </>
    );
  }

  handleReactivateClick = () => {
    const { onReactivateClick, subscription } = this.props;
    onReactivateClick(subscription);
  }
}

export default EmptySeatItem;
