// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import ErrorBoundary from 'components/shared/error-boundary/error-boundary';
import configureStore from 'state/configure-store';
import type { Store } from 'state/state-types';
import AppRouter from './app-router';

class App extends Component {
  store: Store;

  constructor(props) {
    super(props);

    this.store = configureStore();
  }

  render() {
    return (
      <ErrorBoundary fullscreen>
        <ReduxProvider store={this.store}>
          <AppRouter />
        </ReduxProvider>
      </ErrorBoundary>
    );
  }
}

export default hot(App);
