import React from 'react';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';
import OAuthAuthenticationGuard from 'components/shared/access-control/oauth-authentication-guard';
import OAuthAuthorizationContainer from './authorization-container/authorization-container';

const OAuthAuthorizationPage = () => (
  <FullscreenLayout>
    <OAuthAuthenticationGuard>
      <FullscreenLayout.PrimaryContent withLogo wideContainer>
        <OAuthAuthorizationContainer />
      </FullscreenLayout.PrimaryContent>
    </OAuthAuthenticationGuard>
  </FullscreenLayout>
);

export default OAuthAuthorizationPage;
