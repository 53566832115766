import React, { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';

import './auth-desktop.scss';

type Props = {
  isLoading: boolean;
  onButtonClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const AuthDesktop = ({ isLoading, onButtonClick }: Props) => (
  <div className="auth-desktop">
    <h3>
      <FormattedMessage
        id="authDesktop.title"
        defaultMessage="Done! Let’s get you back to Setapp."
      />
    </h3>
    <div className="mb-10">
      <FormattedMessage
        id="authDesktop.subTitle"
        defaultMessage="You are signed in and ready to tackle tasks with the handy tools we got for you."
      />
    </div>
    <ButtonLoading
      block
      size="lg"
      isLoading={isLoading}
      disabled={isLoading}
      onClick={onButtonClick}
    >
      {!isLoading && (
        <FormattedMessage id="authDesktop.goToAccountLink" defaultMessage="Go to Setapp" />
      )}
    </ButtonLoading>
  </div>
);

export default AuthDesktop;
