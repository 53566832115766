import { BASE_PRICE_PLAN, FAMILY_PRICE_PLANS } from 'config/price-plans';

import logger from 'utils/logger';

import type { BillingPeriodsPricePlans } from 'services/price-plans/types';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import type { PriceKeyData, TierTypeData } from 'state/price-plans/price-plans-types';
import type { GracePeriod } from 'state/subscription/subscription-initial-state';

enum PriceKeyPlanPeriod {
  Monthly = ':monthly',
  Annual = ':annual',
}

const featuredEduPricePlans = [
  pricePlanTypes.EDU_TRIAL,
  pricePlanTypes.EDU_ANNUAL,
];

const iosOnlyPricePlans = [
  pricePlanTypes.IOS_TRIAL,
  pricePlanTypes.IOS_MONTHLY,
  pricePlanTypes.IOS_ANNUAL,
];

const featuredIosPricePlans = [
  pricePlanTypes.IOS_MONTHLY,
  pricePlanTypes.IOS_ANNUAL,
];

const featuredFamilyPricePlans = FAMILY_PRICE_PLANS.map((plan) => plan.tierType);

export const iosAdvancedPricePlans = [
  pricePlanTypes.IOS_ADVANCED_TRIAL,
  pricePlanTypes.IOS_ADVANCED_MONTHLY,
  pricePlanTypes.IOS_ADVANCED_ANNUAL,
];

const powerUserPricePlans = [
  pricePlanTypes.POWER_USER_MONTHLY,
  pricePlanTypes.POWER_USER_ANNUAL,
];

// ↓ Price Plans from https://setapp.com/pricing landing page
const promotedPricePlans = [
  pricePlanTypes.MAC_MONTHLY,
  pricePlanTypes.MAC_ANNUAL,
  pricePlanTypes.MAC_PLUS_IOS_MONTHLY,
  pricePlanTypes.MAC_PLUS_IOS_ANNUAL,
  ...powerUserPricePlans,
];

const membershipPriceIncreasePlans = [
  pricePlanTypes.MAC_TRIAL,
  pricePlanTypes.MAC_PLUS_IOS_TRIAL,
  pricePlanTypes.POWER_USER_TRIAL,
  ...promotedPricePlans,
];

const featuredPricePlans = [
  pricePlanTypes.POWER_USER_TRIAL,
  pricePlanTypes.TWO_MACS_ANNUAL,
  pricePlanTypes.TWO_MACS_MONTHLY,
  ...promotedPricePlans,
  ...featuredIosPricePlans,
  ...featuredEduPricePlans,
  ...featuredFamilyPricePlans,
  ...iosAdvancedPricePlans,
];

const aiEnthusiastPricePlans = [
  pricePlanTypes.AI_ENTHUSIAST_TRIAL,
  pricePlanTypes.AI_ENTHUSIAST_MONTHLY,
  pricePlanTypes.AI_ENTHUSIAST_ANNUAL,
  pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_PREV,
  pricePlanTypes.AI_ENTHUSIAST_ANNUAL_DISCOUNT_TO_FULL
];

const aiExpertPricePlans = [
  pricePlanTypes.AI_EXPERT_TRIAL,
  pricePlanTypes.AI_EXPERT_MONTHLY,
  pricePlanTypes.AI_EXPERT_ANNUAL,
  pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_PREV,
  pricePlanTypes.AI_EXPERT_ANNUAL_DISCOUNT_TO_FULL
];

const aiPricePlans = [
  ...aiEnthusiastPricePlans,
  ...aiExpertPricePlans,
];

export const setappMobilePricePlans = [
  ...aiExpertPricePlans,
  ...powerUserPricePlans,
  ...iosAdvancedPricePlans,
];

export const getPriceWithGracePeriod = (pricePlan: PricePlan, gracePeriod?: Maybe<GracePeriod>) => {
  let totalAmount = pricePlan.price;

  if (gracePeriod) {
    totalAmount += gracePeriod.debtAmount;
  }

  return totalAmount;
};

export function decodeTierType(pricePlan: PricePlan): TierTypeData {
  if (!pricePlan.features) {
    return {};
  }

  const { tierType } = pricePlan;
  const [group, type, billingPeriod] = tierType.split(':');

  if (!group || !type || !billingPeriod) {
    logger.logWarn(`Incorrect tier type: "${tierType}"`);
  }

  return { group, type, billingPeriod };
}

export function decodePriceKey(pricePlan: PricePlan): PriceKeyData {
  if (!pricePlan.features) {
    return {};
  }

  const { priceKey } = pricePlan;
  const [group, type, billingPeriod, customName] = priceKey.split(':');

  if (!group || !type || !billingPeriod) {
    logger.logWarn(`Incorrect price key: "${priceKey}"`);
  }

  return { group, type, billingPeriod, customName };
}

export function hasLessMacDevicesInNextPlan(currentPlan: PricePlan, nextPlan: PricePlan): boolean {
  const currentPlanFeatures = currentPlan.features;
  const nextPlanFeatures = nextPlan.features;

  if (!currentPlanFeatures || !nextPlanFeatures) {
    return false;
  }

  return nextPlanFeatures.macSeatsCount < currentPlanFeatures.macSeatsCount;
}

export function hasLessIosDevicesInNextPlan(currentPlan: PricePlan, nextPlan: PricePlan): boolean {
  const currentPlanFeatures = currentPlan.features;
  const nextPlanFeatures = nextPlan.features;

  if (!currentPlanFeatures || !nextPlanFeatures) {
    return false;
  }

  const currentPlanSeatsCount = currentPlanFeatures.iosSeatsCount ?? 0;
  const nextPlanSeatsCount = nextPlanFeatures.iosSeatsCount ?? 0;

  return nextPlanSeatsCount < currentPlanSeatsCount;
}

export function hasLessDevisesInNextPlan(currentPlan: PricePlan, nextPlan: PricePlan): boolean {
  const currentPlanFeatures = currentPlan.features;
  const nextPlanFeatures = nextPlan.features;

  if (!currentPlanFeatures || !nextPlanFeatures) {
    return false;
  }

  return hasLessMacDevicesInNextPlan(currentPlan, nextPlan) || hasLessIosDevicesInNextPlan(currentPlan, nextPlan);
}

export function hasLessAiCreditsInNextPlan(currentPlan: PricePlan, nextPlan: PricePlan): boolean {
  const currentPlanAiCredits = currentPlan.features?.vendorApiUsageLimitInCredits ?? 0;
  const nextPlanAiCredits = nextPlan.features?.vendorApiUsageLimitInCredits ?? 0;

  return currentPlanAiCredits > nextPlanAiCredits;
}

export function hasScheduledDowngrade(currentPlan: PricePlan, nextPlan: PricePlan): boolean {
  // Ensure the user has new featured price plan
  if (!currentPlan.features || !nextPlan.features) {
    return false;
  }

  const { type: currentPricePlanType } = decodeTierType(currentPlan);
  const { type: nextPricePlanType } = decodeTierType(nextPlan);

  // Case when changing the billing period within the same plan
  if (currentPricePlanType === nextPricePlanType) {
    return false;
  }

  // TODO: Improve this logic, because annual plan has 10% discount
  const currentPricePlanMonthlyPrice = currentPlan.price / currentPlan.paidMonth;
  const nextPricePlanMonthlyPrice = nextPlan.price / nextPlan.paidMonth;

  return nextPricePlanMonthlyPrice < currentPricePlanMonthlyPrice;
}

const CUSTOM_SUFFIXES = [
  ':loyal_user',
  ':discounted_to_full',
  ':discounted_to_prev',
  ':regional_eu',
  ':pricing2024',
];

export function getOppositeBillingPeriodPlan(
  currentPlan: PricePlan, pricePlans: Array<PricePlan>
): PricePlan | undefined {
  let desiredOppositeType: string;
  let pricePlanBase: string;

  const { priceKey } = currentPlan;

  const currentPlanWithSuffix = CUSTOM_SUFFIXES
    .find((suffix) => priceKey.includes(suffix)) ?? '';

  if (currentPlan.priceKey.includes(PriceKeyPlanPeriod.Monthly)) {
    desiredOppositeType = PriceKeyPlanPeriod.Annual;
    pricePlanBase = priceKey.replace(PriceKeyPlanPeriod.Monthly, '').replace(currentPlanWithSuffix, '');
  } else if (currentPlan.priceKey.includes(PriceKeyPlanPeriod.Annual)) {
    desiredOppositeType = PriceKeyPlanPeriod.Monthly;
    pricePlanBase = priceKey.replace(PriceKeyPlanPeriod.Annual, '').replace(currentPlanWithSuffix, '');
  } else {
    desiredOppositeType = '';
    pricePlanBase = priceKey.replace(currentPlanWithSuffix, '');
  }

  let oppositePlan = pricePlans.find((plan) => plan.priceKey.includes(`${pricePlanBase}${desiredOppositeType}${currentPlanWithSuffix}`));

  if (!oppositePlan && currentPlanWithSuffix) {
    oppositePlan = pricePlans.find((plan) => plan.priceKey.includes(`${pricePlanBase}${desiredOppositeType}`));
  }

  return oppositePlan;
}

export function isEduPricePlan(pricePlan: PricePlan): boolean {
  if (!pricePlan.features) {
    return pricePlan.tierType === pricePlanTypes.EDU;
  }

  const { type } = decodeTierType(pricePlan);

  return type === 'edu';
}

export function isFamilyPricePlan(pricePlan: PricePlan): boolean {
  const { type } = decodeTierType(pricePlan);

  // for featured price plans check the price plan's type
  if (type) {
    return type === 'family';
  }

  // for old price plans
  return pricePlan.tierType.includes('family');
}

export function isFeaturedPricePlan(tierType: string): boolean {
  return featuredPricePlans.includes(tierType);
}

export function isFeaturedIosPricePlan(tierType: string): boolean {
  return featuredIosPricePlans.includes(tierType);
}

/**
 * @deprecated better to use the `isFamilyPricePlan` function which is not tied to specific tier types
 */
export function isFeaturedFamilyPricePlan(tierType: string): boolean {
  return featuredFamilyPricePlans.includes(tierType);
}

export function isPromotedPricePlan(tierType: string): boolean {
  return promotedPricePlans.includes(tierType);
}

export function isMembershipPriceIncreasePlan(tierType: string): boolean {
  return membershipPriceIncreasePlans.includes(tierType);
}

export function isCheapPricePlan(pricePlan: PricePlan): boolean {
  if (pricePlan.paidMonth === 12) {
    return pricePlan.price <= BASE_PRICE_PLAN.annualPrice;
  }

  return pricePlan.price <= BASE_PRICE_PLAN.monthlyPrice;
}

/**
 * Return period price plan (monthly and annual) depends on current user's price plan
 * if user has MAC_MONTHLY or MAC_ANNUAL price plan – this helper will return both MAC_MONTHLY and MAC_ANNUAL
 * @param featuredPricePlans - all featured price plans from getFeaturedPricePlans selector
 * @param pricePlan - current user's price plan
 */
export function getBillingPeriodsPricePlans(
  featuredPricePlans: PricePlan[],
  pricePlan: PricePlan
) : BillingPeriodsPricePlans {
  const { type: pricePlanType } = decodeTierType(pricePlan);

  const similarTypePricePlans = featuredPricePlans.filter((item) => {
    const { type: featuredPlanType } = decodeTierType(item);

    return featuredPlanType === pricePlanType;
  });

  const annualPricePlan = similarTypePricePlans.find((pricePlan) => pricePlan.paidMonth === 12) ?? null;
  const monthlyPricePlan = similarTypePricePlans.find((pricePlan) => pricePlan.paidMonth === 1) ?? null;

  return {
    annualPricePlan,
    monthlyPricePlan,
  };
}

export const isAiEnthusiastPricePlan = (tierType: string) => aiEnthusiastPricePlans.includes(tierType);
export const isAiExpertPricePlan = (tierType: string) => aiExpertPricePlans.includes(tierType);
export const isAiPricePlan = (tierType: string) => aiPricePlans.includes(tierType);

export const isPowerUserPricePlan = (tierType: string) => powerUserPricePlans.includes(tierType);
export const isIosOnlyPricePlan = (tierType: string) => iosOnlyPricePlans.includes(tierType);
export const isIosAdvancedPricePlan = (tierType: string) => iosAdvancedPricePlans.includes(tierType);
