import React  from 'react';
import {
  FormattedMessage,
  injectIntl,
  defineMessages,
  InjectedIntlProps,
} from 'react-intl';
import { FormField, FormGroup, FormRadio } from '@setapp/ui-kit';

import analytics, { events } from 'utils/analytics';

import { CancellationReason } from '../cancel-subscription-flow';
import CancelSubscriptionStepLayout from '../cancel-subscription-step-layout/cancel-subscription-step-layout';

import questionGuyIllustration from './images/question-guy.png';
import questionGuyIllustration2x from './images/question-guy@2x.png';
import questionGuyIllustration3x from './images/question-guy@3x.png';

import './survey-step.scss';


const messages = defineMessages({
  tooExpensiveOption: {
    id: 'cancelSubscription.survey.option.expensive',
    defaultMessage: 'Make Setapp cheaper',
  },
  buyAppsSeparatelyOption: {
    id: 'cancelSubscription.survey.option.apps',
    defaultMessage: 'Allow subscribing to individual apps',
  },
  doNotUseOftenOption: {
    id: 'cancelSubscription.survey.option.notUseOften',
    defaultMessage: 'Give reason to use Setapp more often',
  },
  switchToAnotherPlanOption: {
    id: 'cancelSubscription.survey.option.anotherPlan',
    defaultMessage: 'Make plan switching easier',
  },
  otherOption: {
    id: 'cancelSubscription.survey.option.other',
    defaultMessage: 'Other',
  },
  otherReasonPlaceholder: {
    id: 'cancelSubscription.survey.otherReasonPlaceholder',
    defaultMessage: 'Tell us what you think',
  },
});

export type Props = {
  cancellationReason?: CancellationReason;
  customCancellationReason?: string;
  onOptionChange: (reason: CancellationReason) => void;
  onOtherReasonTextareaChange: (reason: string) => void;
  userId: number | null;
  onContinueButtonClick: () => void;
  onCloseButtonClick: () => void;
}

const SurveyStep = (props: Props & InjectedIntlProps) => {
  const {
    cancellationReason,
    customCancellationReason,
    onOptionChange,
    onOtherReasonTextareaChange,
    onContinueButtonClick,
    onCloseButtonClick,
    intl,
  } = props;

  const isContinueButtonDisabled = !cancellationReason
    || (cancellationReason === CancellationReason.OTHER && !customCancellationReason);

  const handleContinueButtonClick = () => {
    analytics.trackEvent(events.CANCEL_SUBSCRIPTION_CHOOSE_REASON, { eventLabel: cancellationReason });

    if (cancellationReason === CancellationReason.OTHER) {
      const { userId } = props;
      analytics.sendCancelSubscriptionReason(customCancellationReason, userId);
    }

    onContinueButtonClick();
  };

  return (
    <CancelSubscriptionStepLayout
      illustration={
        <img
          src={questionGuyIllustration}
          srcSet={`${questionGuyIllustration2x} 2x, ${questionGuyIllustration3x} 3x`}
          alt="Question guy illustration"
          width="220"
          height="158"
        />
      }
      title={
        <div className="survey-step__title">
          <FormattedMessage
            id="cancelSubscription.survey.title"
            defaultMessage="One last thing — anything we can improve?"
          />
        </div>
      }
      onPrimaryButtonClick={handleContinueButtonClick}
      primaryButtonText={
        <FormattedMessage id="cancelSubscription.survey.finishCancellationButton" defaultMessage="Cancel subscription" />
      }
      isPrimaryButtonDisabled={isContinueButtonDisabled}
      onSecondaryButtonClick={onCloseButtonClick}
      secondaryButtonText={
        <FormattedMessage id="cancelSubscription.survey.cancelButton" defaultMessage="Keep using Setapp" />
      }
    >
      <>
        { Object.values(CancellationReason).map((reason) => (
          <FormGroup key={reason}>
            <FormRadio
              id={reason}
              name="cancellationReason"
              value={reason}
              checked={cancellationReason === reason}
              onChange={() => onOptionChange(reason)}
              required
            >
              { intl.formatMessage(messages[`${reason}Option`]) }
            </FormRadio>
          </FormGroup>
        )) }

        { cancellationReason === CancellationReason.OTHER && (
          <FormField
            name="otherReason"
            componentClass="textarea"
            placeholder={intl.formatMessage(messages.otherReasonPlaceholder)}
            onChange={(e) => onOtherReasonTextareaChange(e.target.value)}
            value={customCancellationReason}
            maxLength="500"
            autoFocus
            className="no-resize"
          />
        ) }
      </>
    </CancelSubscriptionStepLayout>
  );
};

export { SurveyStep as PureSurveyStep };

export default injectIntl(SurveyStep);
