import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';

type Props = {
  onActivation: () => void;
};

const SuspendedSubscriptionAction: FC<Props> = ({ onActivation }) => (
  <>
    <h5 className="mt-10" data-testid="suspendedSubscriptionActionTitle">
      <FormattedMessage
        id="suspendedSubscriptionAction.title"
        defaultMessage="Start your Setapp subscription now"
      />
    </h5>

    <FormattedMessage
      id="suspendedSubscriptionAction.description"
      defaultMessage="Jump on one of our plans to use Setapp’s curated collection of amazing tools for productivity and workflow."
    />

    <AccountControlButtons>
      <Button
        size="lg"
        onClick={onActivation}
      >
        <FormattedMessage
          id="suspendedSubscriptionAction.CTA"
          defaultMessage="Get a plan"
        />
      </Button>
    </AccountControlButtons>
  </>
);

export default SuspendedSubscriptionAction;
