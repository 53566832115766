import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getFeaturedPricePlans, isIosOnlyUser as isIosOnlyUserSelector } from 'state/root-reducer';
import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import type { PricePlan } from 'state/price-plans/price-plans-initial-state';

import { PROMOTED_PRICE_PLANS, PROMOTED_IOS_PRICE_PLANS } from 'config/price-plans';

import PricePlanCard from 'components/shared/price-plan-card/price-plan-card';

type Props = Readonly<{
  switchedToAnnual: boolean;
  onSelectPlanClick: (pricePlan: PricePlan) => void;
  isIosOnlyUser?: boolean;
  activeMacDevicesCount?: number;
  currentPricePlan?: PricePlan;
  nextPricePlan?: PricePlan;
  isProcessing?: boolean;
  errorMessage?: ReactNode;
}>;

const PromotedPricePlans = ({
  activeMacDevicesCount = 0,
  switchedToAnnual,
  onSelectPlanClick,
  currentPricePlan,
  nextPricePlan,
  isProcessing,
  errorMessage,
}: Props) => {
  const isIosOnlyUser = useSelector(isIosOnlyUserSelector);
  const featuredPricePlans = useSelector(getFeaturedPricePlans);

  const planPeriod = switchedToAnnual ? 12 : 1;

  const promotedPricePlans = isIosOnlyUser ? PROMOTED_IOS_PRICE_PLANS : PROMOTED_PRICE_PLANS;

  return (
    <>
      {promotedPricePlans.map((item) => {
        const isPrimary = activeMacDevicesCount >= 2 ? (
          item.tierType === pricePlanTypes.POWER_USER_MONTHLY
        || item.tierType === pricePlanTypes.POWER_USER_ANNUAL
        ) : (
          item.tierType === pricePlanTypes.MAC_PLUS_IOS_MONTHLY
        || item.tierType === pricePlanTypes.MAC_PLUS_IOS_ANNUAL
        );

        const priceCardClasses = classNames(
          'price-plans-row__price-cards-item',
          { 'price-plans-row__price-cards-item_primary': isPrimary },
        );

        const pricePlan = featuredPricePlans.find(
          (plan) => plan.paidMonth === planPeriod && plan.tierType === item.tierType
        );

        if (!pricePlan) {
          return null;
        }

        const isCurrentPlan = pricePlan.tierType === currentPricePlan?.tierType;
        const isNextPricePlan = pricePlan.tierType === nextPricePlan?.tierType;

        const buttonTitle = isCurrentPlan ? (
          <FormattedMessage
            id="promotedPricePlans.selectButton.current"
            defaultMessage="Your current plan"
          />
        ) : (
          <FormattedMessage
            id="promotedPricePlans.selectButton"
            defaultMessage="Get started"
          />
        );

        return (
          <div key={item.tierType} className={priceCardClasses}>
            <PricePlanCard
              isCurrent={isCurrentPlan}
              isLoading={isProcessing && isNextPricePlan}
              isPrimary={isPrimary}
              pricePlan={pricePlan}
              title={item.title}
              description={item.description}
              buttonTitle={buttonTitle}
              errorMessage={isNextPricePlan && errorMessage}
              onSelectPlanClick={onSelectPlanClick}
            />
          </div>
        );
      })}
    </>);

};

export default PromotedPricePlans;
