import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import { showModal } from 'state/modal/modal-reducer';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPricePlans } from 'state/price-plans/price-plans-actions';
import { FAMILY_ANNUAL, FAMILY_MONTHLY } from 'state/price-plans/price-plans-types';
import {
  getFamilyPricePlans,
  getPricePlansList,
  getPrimarySubscription,
  isTryFamilyPlanPageAvailable as isTryFamilyPlanPageAvailableSelector,
} from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';

import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';
import DefaultError from 'components/shared/default-error/default-error';
import { FormattedPrice } from 'components/shared/formatter/formatter';
import PageTitle from 'components/shared/page-title/page-title';
import FaqList from './faq-list/faq-list';

import './try-family-plan-page.scss';
import familyPlanHero from './images/family-plan-hero.svg';
import billIcon from './images/bill-icon.svg';
import priceTagIcon from './images/price-tag-icon.svg';
import membersIcon from './images/members-icon.svg';
import devicesIcon from './images/devices-icon.svg';

const TryFamilyPlanPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const familyPricePlans = useSelector(getFamilyPricePlans);
  const pricePlansList = useSelector(getPricePlansList);
  const primarySubscription = useSelector(getPrimarySubscription);
  const isTryFamilyPlanPageAvailable = useSelector(isTryFamilyPlanPageAvailableSelector);

  // Redirect if page is not available
  useEffect(() => {
    if (!isTryFamilyPlanPageAvailable) {
      history.replace(urls.subscription);
    }
  }, [history, isTryFamilyPlanPageAvailable]);

  // Fetch necessary data
  useEffect(() => {
    try {
      dispatch(fetchPricePlans());
    } catch {
      dispatch(showDangerNotification(<DefaultError />));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for family price plan
  useEffect(() => {
    // Do nothing if price plans not loaded already
    if (!pricePlansList.length) {
      return;
    }

    // Redirect to subscription page if BE doesn't return new family price plans
    if (!familyPricePlans.length) {
      history.replace(urls.subscription);
    }
  }, [history, pricePlansList, familyPricePlans]);

  if (!pricePlansList.length) {
    return <AppLayoutLoading />;
  }

  const familyMonthlyPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_MONTHLY);
  const familyAnnualPricePlan = familyPricePlans.find((item) => item.tierType === FAMILY_ANNUAL);

  // At this point we must have a family plan. This check only for TS
  if (!primarySubscription || !familyMonthlyPricePlan || !familyMonthlyPricePlan.features) {
    return null;
  }

  const handleGetPlanClick = () => {
    if ([subscriptionStatuses.ACTIVE, subscriptionStatuses.GRACE].includes(primarySubscription.status)) {
      dispatch(showModal('CREATE_FAMILY_FOR_ACTIVE_USER'));

      return;
    }

    history.push(urls.activateFamilySubscription);
  };

  const isCurrentPlanAnnual = primarySubscription.pricePlan.paidMonth === 12;
  const familyPlan = isCurrentPlanAnnual ? familyAnnualPricePlan : familyMonthlyPricePlan;
  // At this point we must have a family plan
  const { currency, price } = familyPlan!;
  const { macSeatsCount, iosSeatsCount = 0, maxMembersCount = 0 } = familyMonthlyPricePlan.features;

  const isGetPlanButtonDisabled = primarySubscription.status === subscriptionStatuses.CANCELLED;
  const priceFormatted = <FormattedPrice price={price} currency={currency} />;

  return (
    <>
      <div className="try-family-plan-page__hero">
        <div>
          <PageTitle>
            <FormattedMessage
              id="tryFamilyPlan.title"
              defaultMessage="Family plan"
            />
          </PageTitle>
          <h5 className="mb-4">
            {isCurrentPlanAnnual ? (
              <FormattedMessage
                id="tryFamilyPlan.infoAnnual"
                defaultMessage="You + {maxMembersCount} people = {price}/year"
                values={{
                  maxMembersCount,
                  price: priceFormatted,
                }}
              />
            ) : (
              <FormattedMessage
                id="tryFamilyPlan.info"
                defaultMessage="You + {maxMembersCount} people = {price}/mo"
                values={{
                  maxMembersCount,
                  price: priceFormatted,
                }}
              />
            )}
          </h5>
          <p className="mb-10">
            <FormattedMessage
              id="tryFamilyPlan.description"
              defaultMessage="Love Setapp? Share it with friends and family, with a discount."
            />
          </p>
          <Button
            className="try-family-plan-page__hero-cta"
            size="lg"
            disabled={isGetPlanButtonDisabled}
            onClick={handleGetPlanClick}
          >
            <FormattedMessage
              id="tryFamilyPlan.cta"
              defaultMessage="Get plan"
            />
          </Button>
        </div>
        <div className="try-family-plan-page__hero-image">
          <img src={familyPlanHero} width={484} height={276} alt="" />
        </div>
      </div>

      <div className="try-family-plan-page__features">
        <h5 className="mt-0 mb-8">
          <FormattedMessage
            id="tryFamilyPlan.features.title"
            defaultMessage="Why jump on Family"
          />
        </h5>
        <ul className="try-family-plan-page__features-list">
          <li className="try-family-plan-page__features-item">
            <img src={billIcon} width={80} height={80} alt="" />
            <FormattedMessage
              id="tryFamilyPlan.features.item1"
              defaultMessage="One family, one bill"
            />
          </li>
          <li className="try-family-plan-page__features-item">
            <img src={priceTagIcon} width={80} height={80} alt="" />
            {isCurrentPlanAnnual ? (
              <FormattedMessage
                id="tryFamilyPlan.features.item2.annual"
                defaultMessage="Only {price} for a year"
                values={{ price: priceFormatted }}
              />
            ) : (
              <FormattedMessage
                id="tryFamilyPlan.features.item2"
                defaultMessage="Only {price}"
                values={{ price: priceFormatted }}
              />
            )}
          </li>
          <li className="try-family-plan-page__features-item">
            <img src={membersIcon} width={80} height={80} alt="" />
            <FormattedMessage
              id="tryFamilyPlan.features.item3"
              defaultMessage="Four participants"
            />
          </li>
          <li className="try-family-plan-page__features-item">
            <img src={devicesIcon} width={80} height={80} alt="" />
            <FormattedMessage
              id="tryFamilyPlan.features.item4"
              defaultMessage="{macSeatsCount, plural, one {1 Mac} other {{macSeatsCount} Macs}} + {iosSeatsCount, plural, one {1 iOS device} other {{iosSeatsCount} iOS devices}} each"
              values={{ macSeatsCount, iosSeatsCount }}
            />
          </li>
        </ul>
      </div>

      <div className="try-family-plan-page__faq">
        <h5>
          <FormattedMessage id="tryFamilyPlan.faq.title" defaultMessage="FAQ" />
        </h5>
        <div className="try-family-plan-page__faq-list">
          <FaqList />
        </div>
      </div>
    </>
  );
};

export default injectIntl(TryFamilyPlanPage);
