import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

// all of it is kinda temporary, because it is hardcoded for test
// if test wins, this data will be acquired from backend

// UPD: for now as we don't develop much into this direction, i will just refactor it
// to make it more expandable

type IosAppPack = {
  id: number;
  name: string;
  title: string | ReactNode;
  description: string | ReactNode;
  pictureType: 'start' | 'stress';
  appIds: number[];
}

type IosCollectionBanner = Omit<IosAppPack, 'pictureType'>;

const isProduction = process.env.USE_PRODUCTION_GTM;
// 553 and 23871 are Curio
// 557 and 24808 are Craft
const starterIds = isProduction ? [557, 553, 468, 560] : [24808, 23871, 2784, 19091];
const stressIds = isProduction ? [461, 530, 525, 444] : [7573, 9430, 2784];
const getDoneIds = isProduction ? [429, 464, 456, 410] : [7573, 9430, 2784, 19091];

const getIosAppPacksConfig = (): Array<IosAppPack> => [{
  id: 0,
  name: 'Starter kit',
  title: <FormattedMessage id="iosApps.packTitle.start" defaultMessage="Starter kit" />,
  description: (
    <FormattedMessage
      id="iosApps.packDesc.start"
      defaultMessage="Get iPhone must-haves to enhance your workflow"
    />
  ),
  pictureType: 'start',
  appIds: starterIds,
}, {
  id: 1,
  name: 'Stress less',
  title: <FormattedMessage id="iosApps.packTitle.stress" defaultMessage="Stress less" />,
  description: (
    <FormattedMessage
      id="iosApps.packDesc.stress"
      defaultMessage="Become healthier and more productive"
    />
  ),
  pictureType: 'stress',
  appIds: stressIds,
}];

const getIosCollectionBannersConfig = (): Array<IosCollectionBanner> => [
  {
    id: 2,
    name: 'Get things done',
    title: <FormattedMessage id="iosApps.bannerTitle.getDone" defaultMessage="Get things done" />,
    appIds: getDoneIds,
    description: (
      <FormattedMessage
        id="iosApps.bannerDesc.getDone"
        defaultMessage="Mind mapping · Task manager · Copy & paste tool · Pomodoro timer"
      />
    ),
  }
];

const useIosCollectionsConfig = () => {
  return {
    appPacks: getIosAppPacksConfig(),
    collectionBanners: getIosCollectionBannersConfig(),
  };
};

export default useIosCollectionsConfig;
