import React, { ReactNode } from 'react';

import './account-control-buttons.scss';

type Props = {
  children: ReactNode;
}

const AccountControlButtons = ({ children }: Props) => (
  <div className="account-control-buttons mt-8">
    {children}
  </div>
);

export default AccountControlButtons;
