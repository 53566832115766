import React, { PureComponent, ReactNode, ElementRef, KeyboardEvent, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { BAD_REQUEST } from 'http-status';
import ReCaptcha from 'react-google-recaptcha';
import type { RouteComponentProps } from 'react-router-dom';

import urls from 'config/urls';

import CaptchaError from 'components/shared/form/captcha-error/captcha-error';

import auth from 'utils/auth';
import validate from 'utils/auth-validation';
import analytics, { events } from 'utils/analytics';

import RecoverPasswordForm from './recover-password-form';

type Props = RouteComponentProps<any, any, { previousPage: string }>;

type State = {
  fields: {
    email: string | string[];
    captcha: string;
  };
  fieldsErrors: {
    email: ReactNode;
    captcha: ReactNode;
  };
  genericError: ReactNode;
  isRequestProcessing: boolean;
  isRequestConfirmed: boolean;
  requireCaptcha: boolean;
};

class RecoverPasswordPage extends PureComponent<Props, State> {
  validation = {
    email: {
      required: <FormattedMessage id="outer.recoverPassword.validation.emptyEmail" defaultMessage="Email is required" />,
      emailFormat: <FormattedMessage id="outer.recoverPassword.validation.invalidEmail" defaultMessage="Invalid email" />,
    },
  };

  captcha?: ElementRef<typeof ReCaptcha> | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      fields: {
        email: '',
        captcha: '',
      },
      fieldsErrors: {
        email: '',
        captcha: '',
      },
      genericError: '',
      isRequestProcessing: false,
      isRequestConfirmed: false,
      requireCaptcha: false,
    };
  }

  render() {
    const {
      genericError,
      isRequestProcessing,
      isRequestConfirmed,
      fields,
      fieldsErrors,
      requireCaptcha,
    } = this.state;
    const { email: emailError, captcha: captchaError } = fieldsErrors;
    const { location } = this.props;
    const previousPage = location?.state?.previousPage ?? urls.account;

    return <RecoverPasswordForm
      onEmailChange={this.onEmailChange}
      onFormSubmit={this.onFormSubmit}
      emailError={emailError}
      genericError={genericError}
      previousPage={previousPage}
      isRequestProcessing={isRequestProcessing}
      isRequestConfirmed={isRequestConfirmed}
      // @ts-expect-error TS(2322): Type 'string | string[]' is not assignable to type... Remove this comment to see the full error message
      email={fields.email}
      showCaptcha={requireCaptcha}
      captchaError={captchaError}
      // @ts-expect-error TS(2322): Type '(value: string) => void' is not assignable t... Remove this comment to see the full error message
      onCaptchaChange={this.onCaptchaChange}
      setCaptchaRef={this.setCaptchaRef}
           />;
  }

  onEmailChange = (e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        email: value,
      },
    }));
  };

  onCaptchaChange = (value: string) => {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        captcha: value,
      },
    }));
  };

  setProcessing(isRequestProcessing: boolean) {
    this.setState({ isRequestProcessing });
  }

  displaySuccessMessage() {
    this.setState({ isRequestConfirmed: true });
  }

  onFormSubmit = (e: SyntheticEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    const { fields } = this.state;
    const validationResult = validate(fields, this.validation);

    const formHasError = Object.keys(validationResult).some((field) => validationResult[field]);

    this.setState((prevState) => ({
      fieldsErrors: {
        ...prevState.fieldsErrors,
        ...validationResult,
      },
    }));

    if (formHasError) {
      this.setState({ genericError: '' });

      return Promise.resolve();
    }

    this.setProcessing(true);

    return auth.requestPasswordReset(fields)
      .then(() => {
        analytics.trackEvent(events.NEW_PASSWORD_REQUEST);

        this.setProcessing(false);
        this.displaySuccessMessage();
      })
      .catch((error) => {
        if (this.captcha) {
          this.captcha.reset();
        }

        const { genericError, fieldsErrors } = error.getSimplifiedErrors();
        const captchaError = error.getFieldError('captcha');
        const isBadRequest = error.status === BAD_REQUEST;
        const displayProcessedMessage = fieldsErrors?.email && isBadRequest;

        this.setProcessing(false);
        if (displayProcessedMessage && !captchaError) {
          this.displaySuccessMessage();
        }

        this.setState((prevState) => ({
          requireCaptcha: prevState.requireCaptcha || Boolean(captchaError),
          genericError,
          fieldsErrors: {
            ...prevState.fieldsErrors,
            captcha: captchaError ? <CaptchaError error={captchaError} /> : '',
          },
        }));
      });
  };

  setCaptchaRef = (ref: ElementRef<typeof ReCaptcha>) => {
    this.captcha = ref;
  }
}

export { RecoverPasswordPage as PureRecoverPasswordPage };

export default RecoverPasswordPage;
