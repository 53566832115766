// @flow

import type { Location } from 'react-router-dom';
import queryString from 'query-string';
import logger from 'utils/logger';
import OauthState, { type OAuthStatePayload } from './oauth-state';

export default class OauthStateFactory {
  static create(location: Location, payload?: OAuthStatePayload = {}) {
    const oauthState = new OauthState({
      timestamp: Date.now(),
      payload,
    });
    const query = queryString.parse(location.search);

    if (query.campaign) {
      oauthState.payload = {
        ...oauthState.payload,
        campaign: query.campaign,
      };
    }

    if (query.signupParams) {
      try {
        const urlSignupParams = JSON.parse(query.signupParams);

        if (typeof urlSignupParams === 'object' && !Array.isArray(urlSignupParams)) {
          oauthState.payload = {
            // signupParams are read in social auth page and passed to signup API
            signupParams: {
              ...urlSignupParams,
              ...oauthState.payload.signupParams,
            },
          };
        }
      } catch (e) {
        logger.captureException(e);
      }
    }

    return oauthState;
  }
}
