import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';
import { PanelGroupCollapsible } from '@setapp/ui-kit';

import './collapsible-group.scss';

type Panel = {
  title: ReactNode;
  body: ReactNode;
};

type Props = {
  panels: Panel[];
  withBullet?: boolean;
  withCaret?: boolean;
};

// TODO: Consider to move to UI Kit
const CollapsibleGroup = (props: Props) => {
  const {
    panels,
    withBullet = false,
    withCaret = false,
  } = props;

  const rootClasses = classNames('collapsible-group', {
    'collapsible-group_with-bullet': withBullet,
    'collapsible-group_with-caret': withCaret,
  });

  return (
    <div className={rootClasses}>
      <PanelGroupCollapsible panels={panels} />
    </div>
  );
};

export default CollapsibleGroup;
