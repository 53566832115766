import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import { isSubscriptionWithDiscount as isSubscriptionWithDiscountSelector } from 'state/root-reducer';
import { showModal } from 'state/modal/modal-reducer';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';

const FineTunePricePlan: FC = () => {
  const history = useHistory();

  const showModalAction = useDispatchAction(showModal);
  const isSubscriptionWithDiscount = useSelector(isSubscriptionWithDiscountSelector);

  const redirectToChangePlanPage = () => history.push(urls.changePricePlan);

  const handleViewPlans = () => {
    if (isSubscriptionWithDiscount) {
      showModalAction('CONFIRM_LOSE_DISCOUNT', {
        onConfirm: redirectToChangePlanPage,
      });
    } else {
      redirectToChangePlanPage();
    }
  };

  return (
    <div className="mt-10" data-testid="fineTunePricePlan">
      <h5>
        <FormattedMessage
          id="subscriptionInfo.fineTunePricePlan.title"
          defaultMessage="Fine-tune your Setapp plan"
        />
      </h5>
      <p className="mb-8">
        <FormattedMessage
          id="subscriptionInfo.fineTunePricePlan.description"
          defaultMessage="After your free trial, your subscription will begin automatically and your card will be charged. Want a different plan? Make an easy switch now!"
        />
      </p>
      <Button
        variant="primary"
        className="mr-3"
        onClick={handleViewPlans}
      >
        <FormattedMessage
          id="subscriptionInfo.fineTunePricePlan.viewPlansBtn"
          defaultMessage="View plans"
        />
      </Button>
      <Link to={urls.cancelSubscription}>
        <Button
          variant="secondary-outline"
        >
          <FormattedMessage
            id="subscriptionInfo.fineTunePricePlan.cancelTrialBtn"
            defaultMessage="Cancel trial"
          />
        </Button>
      </Link>
    </div>
  );
};

export default FineTunePricePlan;
