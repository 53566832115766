import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import setappIcon from '../images/setapp-icon.svg';

import './successful-registration-mobile.scss';

type Props = {
  onGoToMyAccountClick: () => void;
};

const SuccessfulRegistrationMobile = ({ onGoToMyAccountClick }: Props) => (
  <div className="successful-registration-mobile" data-testid="mobile-view">
    <img
      className="successful-registration-mobile__setapp-icon"
      src={setappIcon}
      alt="Setapp icon"
      width={100}
      height={100}
    />
    <h3 className="mt-5 mb-8 successful-registration-mobile__title">
      <FormattedMessage
        id="successfulRegistration.mobile.title"
        defaultMessage="A link to download Setapp on Mac was sent to your email."
      />
    </h3>
    <Button
      className="successful-registration-mobile__button"
      onClick={onGoToMyAccountClick}
    >
      <FormattedMessage
        id="successfulRegistration.mobile.goToMyAccountBtn"
        defaultMessage="Go to my account"
      />
    </Button>
  </div>
);

export default SuccessfulRegistrationMobile;
