import { useSelector } from 'react-redux';
import { SETAPP_FOR_BACKEND_MONTHLY } from 'state/price-plans/price-plans-types';
import {
  getUser,
  isChangePlanAllowed as getIsChangePlanAllowed,
  isCancelSubscriptionAllowed as getIsCancelSubscriptionAllowed,
  isManageSubscriptionAllowed as getIsManageSubscriptionAllowed,
  isSuspendedSubscriptionWithOldPricePlan as getIsSuspendedSubscriptionWithOldPricePlan,
} from 'state/root-reducer';
import { getIsTrialUser } from 'services/subscriptions/subscription';
import { useSubscriptionContext } from '../context/subscription-context';

const useSubscriptionActionsVisibility = () => {
  const { subscription, hasPriceFeatures, isFamilyMember, isFamilyOwner } = useSubscriptionContext();

  const user = useSelector(getUser);
  const isManageSubscriptionAllowed = useSelector(getIsManageSubscriptionAllowed);
  const isCancelSubscriptionAllowed = useSelector(getIsCancelSubscriptionAllowed);
  const isChangePlanAllowed = useSelector(getIsChangePlanAllowed);
  const isSuspendedSubscriptionWithOldPricePlan = useSelector(getIsSuspendedSubscriptionWithOldPricePlan);

  const isTrial = getIsTrialUser(subscription);
  const isBackendMonthly = subscription.pricePlan.tierType === SETAPP_FOR_BACKEND_MONTHLY;
  const canShowBasedOnFeatures = !hasPriceFeatures && !isSuspendedSubscriptionWithOldPricePlan;

  return {
    showManageSubscription: hasPriceFeatures && isManageSubscriptionAllowed && !isBackendMonthly && !isTrial,
    showChangePlan: canShowBasedOnFeatures && isChangePlanAllowed,
    showCancel: canShowBasedOnFeatures && isCancelSubscriptionAllowed,
    showLeaveFamily: isFamilyMember && !user.isTeamMember,
    showDropFamily: !hasPriceFeatures && isFamilyOwner && !user.isTeamMember,
  };
};

export default useSubscriptionActionsVisibility;
