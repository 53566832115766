import React from 'react';
import { Button } from '@setapp/ui-kit';
import { FormattedMessage } from 'react-intl';
import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';

type Props = {
  iosApp: IosApp;
  onActivateButtonClick: () => void;
  goToAppStore: () => void;
}

const SecondStep = ({ iosApp, onActivateButtonClick, goToAppStore }: Props) => {
  return (
    <>
      <h3 className="mb-2">
        <FormattedMessage
          id="activateIosPage.secondStep.final"
          defaultMessage="Final step"
        />
      </h3>
      <div className="activate-ios-page__step activate-ios-page__step_activation">
        <p className="text_weight-bold mb-3 activate-ios-page__step-number">
            <FormattedMessage
              id="activateIosPage.secondStep.stepTwo"
              defaultMessage="Step 2"
            />
        </p>
        <h5 className="activate-ios-page__step-header activate-ios-page__step-header_wider">
          <FormattedMessage
            id="activateIosPage.secondStep.header"
            defaultMessage="Activate the app via Setapp"
          />
        </h5>
        <div className="activate-ios-page__icon-container">
          <img src={iosApp.icon} alt={iosApp.name} width="166" height="166" />
        </div>
        <Button block size="lg" onClick={onActivateButtonClick}>
          <FormattedMessage
            id="activateIosPage.secondStep.activate"
            defaultMessage="Activate"
          />
        </Button>
      </div>
      <p className="text_sm text_weight-bold mt-8 mb-0">
        <FormattedMessage
          id="activateIosPage.secondStep.dontHave"
          defaultMessage="Haven’t installed the app yet?"
        />
      </p>
      <div className="text_sm text_weight-medium activate-ios-page__install-text mt-1">
        <FormattedMessage
          id="activateIosPage.secondStep.installText"
          defaultMessage="Install {appName} on {appStore}"
          values={{
            appName: iosApp.name,
            appStore: (
              <Button
                variant="link"
                className="text_sm activate-ios-page__app-store-btn"
                onClick={goToAppStore}
              >
                App Store
              </Button>
            )
          }}
        />
      </div>
    </>
  );
};

export default SecondStep;
