import React, { type ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { MagicFrameInnerProvider } from '@mwkt/react-magic-frame';

import { getFeatureFlags } from 'state/root-reducer';

import EmbedPage from './components/embed-page/embed-page';

type Props = {
  children: ReactNode;
};

const EmbedLayout = ({ children }: Props) => {
  const { o10rReleaseSep2024 } = useSelector(getFeatureFlags);
  const isO10rEnabled = Boolean(o10rReleaseSep2024.value);

  if (!isO10rEnabled) return null;

  return (
    <MagicFrameInnerProvider allowedOrigin="*">
      <EmbedPage>
        {children}
      </EmbedPage>
    </MagicFrameInnerProvider>
  );
};

export default EmbedLayout;
