import type { Action } from 'state/state-types';
import { Subscription } from 'state/subscription/subscription-initial-state';
import { isFeaturedFamilyPricePlan, isFeaturedPricePlan } from 'services/price-plans/utils';
import { IOS_PRICE_PLANS } from 'config/price-plans';
import initialState, { PricePlanState, PricePlan } from './price-plans-initial-state';
import * as actionTypes from './price-plans-action-types';

const pricePlans = (state: PricePlanState = initialState, action: Action): PricePlanState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { ...state, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      return { list: action.payload, isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { ...state, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default pricePlans;

const iosPricePlans = IOS_PRICE_PLANS.map((plan) => plan.tierType);

export const getList = (state: PricePlanState) => state.list;
export const getAllActive = (state: PricePlanState): Array<PricePlan> => getList(state).filter((plan) => plan.active);
export const getActiveByGroup = (state: PricePlanState, group: string | null): Array<PricePlan> => (
  getAllActive(state).filter((plan) => plan.group === group)
);
export const getFirstActiveByType = (state: PricePlanState, type: string): PricePlan | undefined => (
  getAllActive(state).find((plan) => plan.tierType === type)
);
export const getFeatured = (state: PricePlanState): Array<PricePlan> => (
  getAllActive(state).filter((plan) => isFeaturedPricePlan(plan.tierType))
);
export const getIos = (state: PricePlanState): Array<PricePlan> => (
  getAllActive(state).filter((plan) => iosPricePlans.includes(plan.tierType))
);
export const getFamilyPlans = (state: PricePlanState): Array<PricePlan> => (
  getAllActive(state).filter((plan) => isFeaturedFamilyPricePlan(plan.tierType))
);
export const getAvailableByFeatures = (pricePlans: Array<PricePlan>, hasPriceFeatures: boolean): Array<PricePlan> => (
  pricePlans.filter((plan) => Boolean(plan.features) === hasPriceFeatures)
);
export const getDisplayed = (primarySubscription: Subscription | undefined): PricePlan | undefined => {
  if (!primarySubscription) return undefined;

  return primarySubscription.nextPricePlan ?? primarySubscription.pricePlan;
};
