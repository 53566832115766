import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import { showModal } from 'state/modal/modal-reducer';
import { isSuspendedSubscriptionWithOldPricePlan as selectIsSuspendedSubscriptionWithOldPricePlan } from 'state/root-reducer';
import useSuspendedActivationHandler from './use-suspended-activation-handler';
import useHandlePaymentDetailsAdded from './use-handle-payment-details-added';

const useAddPaymentDetails = () => {
  const isSuspendedSubscriptionWithOldPricePlan = useSelector(selectIsSuspendedSubscriptionWithOldPricePlan);

  const showModalAction = useDispatchAction(showModal);

  const handleSuspendedActivation = useSuspendedActivationHandler();
  const onPaymentDetailsSaved = useHandlePaymentDetailsAdded();

  return useCallback(() => {
    if (isSuspendedSubscriptionWithOldPricePlan) {
      handleSuspendedActivation();

      return;
    }

    showModalAction('SET_PAYMENT_DETAILS', {
      title: <PaymentDetailsActionText action="add" />,
      onPaymentDetailsSaved,
    });
  }, [isSuspendedSubscriptionWithOldPricePlan]);
};

export default useAddPaymentDetails;
