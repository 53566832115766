import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormattedPrice } from 'components/shared/formatter/formatter';

import { getDefaultMonthlyPrice } from 'state/root-reducer';

type Props = {
  defaultMonthlyPrice: number;
}

const FirstStepSecondaryContent = ({ defaultMonthlyPrice }: Props) => (
  <>
    <h4 className="redeem__secondary-title">
      <FormattedMessage
        id="redeem.secondary.title"
        defaultMessage="Versatile collection of Mac and iOS tools"
      />
    </h4>
    <p>
      <FormattedMessage
        id="redeem.secondary.subscriptionDescription"
        defaultMessage="One subscription, many tools. Streamline your workflow with Setapp app collection. From {price}/month."
        values={{
          price: (
            <FormattedPrice
              price={defaultMonthlyPrice}
              currency="USD"
            />
          ),
        }}
      />
    </p>
    <div className="redeem__secondary-label">
      <FormattedMessage
        id="redeem.secondary.activateNote"
        defaultMessage="Got a code? Enter it on the left side of the screen and click Continue to activate."
      />
    </div>
  </>
);

export { FirstStepSecondaryContent as PureFirstStepSecondaryContent };

const mapStateToProps = () => ({
  defaultMonthlyPrice: getDefaultMonthlyPrice(),
});

export default connect(mapStateToProps)(FirstStepSecondaryContent);
