import type { ReactNode, ElementRef, SyntheticEvent } from 'react';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import queryString from 'query-string';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import type { InjectedIntlProps } from 'react-intl';
import ReCaptcha from 'react-google-recaptcha';
import { isMobile } from 'react-device-detect';
import RequestError from '@setapp/request-error';
import type { RouteComponentProps } from 'react-router-dom';

import DefaultError from 'components/shared/default-error/default-error';
import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import CaptchaError from 'components/shared/form/captcha-error/captcha-error';

import * as pricePlanTypes from 'state/price-plans/price-plans-types';
import {
  authWithSocialCredentials,
  linkSocialAccount,
  login,
  setSignupGiftCardCode,
  type SocialAuthParams,
} from 'state/user/user-actions';
import { getUserEmail, getFeatureFlags } from 'state/root-reducer';

import urls from 'config/urls';
import { SOCIAL_PROVIDER_NAMES } from 'config/social';
import type { SupportedSocialProvider } from 'config/social';

import validateUserEmail from 'services/user/user-api';

import type { SocialClientInterface } from 'utils/social-clients/social-client-interface';
import validate from 'utils/auth-validation';
import desktopAppHelper from 'utils/desktop-app-helper';
import analytics, { events as analyticsEvents } from 'utils/analytics';
import googleClient from 'utils/social-clients/google-client';
import appleClient from 'utils/social-clients/apple-client';
import OauthState from 'utils/oauth-state/oauth-state';
import OauthStateManager from 'utils/oauth-state/oauth-state-manager';
import logger from 'utils/logger';
import { SETAPP_MOBILE_CAMPAIGN } from 'state/user/campaign-types';
import SocialAuthSuccessfulFromDesktop from './social-auth-successful-from-desktop/social-auth-successful-from-desktop';
import SocialAuthNewsletterForm from './social-auth-newsletter-form/social-auth-newsletter-form';
import PasswordForm from './social-auth-password-form/social-auth-password-form';
import EmailForm from './social-auth-email-form/social-auth-email-form';

type SocialAuthResponse = {
  isSignUp: boolean;
  registrationFlow: {
    type: string;
  };
};

const mapStateToProps = (state) => ({
  email: getUserEmail(state),
  featureFlags: getFeatureFlags(state),
});

const mapActionsToProps = {
  authWithSocialCredentials,
  linkSocialAccount,
  login,
  setSignupGiftCardCode,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type State = {
  isEmailRequired: boolean;
  isAccountExist: boolean;
  isAuthFromDesktopFinished: boolean;
  email: string;
  password: string;
  captcha: string | null;
  emailError: string;
  passwordError: ReactNode;
  genericError: ReactNode;
  captchaError: ReactNode;
  requireCaptcha: boolean;
  isProcessing: boolean;
  accessToken: string;
  shouldShowNewsletterPage: boolean;
};

type Props = RouteComponentProps<{ provider: string }> & InjectedIntlProps & ConnectedProps<typeof connector>;

const messages = defineMessages({
  accessDeniedError: {
    id: 'socialAuth.accessDeniedError',
    defaultMessage: 'Access not granted by {provider}',
  },
});

class SocialAuthPage extends PureComponent<Props, State> {
  state = {
    isEmailRequired: false,
    isAccountExist: false,
    isAuthFromDesktopFinished: false,
    email: '',
    password: '',
    captcha: '',
    emailError: '',
    passwordError: '',
    genericError: '',
    captchaError: '',
    requireCaptcha: false,
    isProcessing: false,
    accessToken: '',
    shouldShowNewsletterPage: false,
  };

  validation = {
    email: {
      required: <FormattedMessage id="socialAuth.validation.emptyEmail" defaultMessage="Email is required" />,
      emailFormat: <FormattedMessage id="socialAuth.validation.invalidEmail" defaultMessage="Invalid email" />,
    },
    password: {
      required: <FormattedMessage id="socialAuth.validation.emptyPassword" defaultMessage="Password is required" />,
    },
  };

  captcha?: ElementRef<typeof ReCaptcha>;

  socialClient: SocialClientInterface;

  static defaultProps = {
    email: '',
  };

  static socialClients: { [key in SupportedSocialProvider]: SocialClientInterface } = {
    apple: appleClient,
    google: googleClient,
  };

  constructor(props: Props) {
    super(props);

    this.socialClient = SocialAuthPage.socialClients[props.match.params.provider];
  }

  async componentDidMount() {
    if (!this.socialClient) {
      const { history } = this.props;
      history.replace(urls.login);

      return;
    }

    try {
      await this.processResponse();
    } catch (error) {
      this.onAuthError(error);
    }
  }

  render() {
    const {
      isAuthFromDesktopFinished,
      isEmailRequired,
      email,
      emailError,
      password,
      passwordError,
      genericError,
      captchaError,
      requireCaptcha,
      isProcessing,
      isAccountExist,
      shouldShowNewsletterPage,
    } = this.state;

    if (isAuthFromDesktopFinished) {
      return <SocialAuthSuccessfulFromDesktop />;
    }

    if (isEmailRequired) {
      return (
        <EmailForm
          provider={this.getProviderName()}
          email={email}
          emailError={emailError}
          formError={genericError}
          isProcessing={isProcessing}
          onFieldChange={this.onFormFieldChange}
          onSubmit={this.onEmailFormSubmit}
        />
      );
    }

    if (isAccountExist) {
      return (
        <PasswordForm
          provider={this.getProviderName()}
          email={email}
          password={password}
          passwordError={passwordError}
          formError={genericError}
          isProcessing={isProcessing}
          onFieldChange={this.onFormFieldChange}
          onSubmit={this.onPasswordFormSubmit}
          captchaError={captchaError}
          showCaptcha={requireCaptcha}
          onCaptchaChange={this.onCaptchaChange}
          setCaptchaRef={this.setCaptchaRef}
        />
      );
    }

    if (shouldShowNewsletterPage) {
      return (
        <SocialAuthNewsletterForm
          onSubmit={this.onNewsletterFormSubmit}
          isProcessing={isProcessing}
          genericError={genericError}
        />
      );
    }

    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <AnimatedLogo animate={isProcessing} />
        <p className="text-danger">{genericError}</p>
      </div>
    );
  }

  setCaptchaRef = (ref: ElementRef<typeof ReCaptcha>) => {
    this.captcha = ref;
  };

  onCaptchaChange = (captcha: string | null) => {
    this.setState({
      captchaError: '',
      captcha,
    });
  };

  getSignupParams() {
    const { location } = this.props;
    const { state: urlState } = queryString.parse(location.search || location.hash);

    let signupParams = {};
    let campaign = '';

    try {
      const oauthState = OauthState.parse(urlState);
      ({ signupParams, campaign } = oauthState.payload);
    } catch (e) {
      logger.logError('Invalid oauth state');
    }

    return { ...signupParams, campaign };
  }

  async processResponse() {
    const { history } = this.props;

    // Handle cancelled auth first
    if (!this.socialClient.isAuthRedirect()) {
      history.replace(urls.login);

      return;
    }

    const urlState = this.socialClient.getUrlState();
    const cookieState = OauthStateManager.get();

    if (!urlState || cookieState !== urlState) {
      this.setState({
        genericError: (
          <FormattedMessage
            id="socialAuth.invalidStateError"
            defaultMessage="Invalid state or state doesn't exist. Please contact support."
          />
        ),
      });

      return;
    }

    const signupParams = this.getSignupParams();

    const { accessToken } = await this.socialClient.processAuthRedirect();

    this.setState({ accessToken });

    this.setProcessing(true);

    // set state with email from social provider
    await this.fetchProfile();
    const { email } = this.state;

    let isEmailTaken = false;

    try {
      // we check if user is already created, mail validation is not relevant
      await validateUserEmail(email, false);
      isEmailTaken = false;
    } catch {
      isEmailTaken = true;
    } finally {
      this.setProcessing(false);
    }

    if (!isEmailTaken) {
      this.setState({ shouldShowNewsletterPage: true });

      return;
    }

    await this.authWithCredentials({
      ...signupParams,
      accessToken,
    });
  }

  redirectToSetappMobile() {
    const { history } = this.props;

    history.replace(urls.setappMobile);
  }

  redirectToWelcomePage() {
    const { history } = this.props;

    history.replace(urls.socialAuthWelcome);
  }

  redirectToRootPage() {
    const { history } = this.props;

    history.replace(urls.root);
  }

  authWithCredentials(credentials: SocialAuthParams) {
    const {
      authWithSocialCredentials,
      match,
      featureFlags,
    } = this.props;

    this.setProcessing(true);

    if (isMobile && Boolean(featureFlags.signupMobileAsIos.value)) {
      credentials.tierType = pricePlanTypes.IOS_MONTHLY;
    }

    return this.fetchProfile()
      .then(() => authWithSocialCredentials(credentials, match.params.provider))
      .then((response: SocialAuthResponse) => this.onAuthSuccess(response, credentials))
      .catch((error) => this.onAuthError(error));
  }

  onAuthSuccess(response: SocialAuthResponse, credentials: Partial<SocialAuthParams> = {}) {
    const { setSignupGiftCardCode } = this.props;

    const authEvent = response.isSignUp ? analyticsEvents.SIGNUP_SUCCESS : analyticsEvents.LOGIN_SUCCESS;

    analytics.trackEvent(authEvent, { eventLabel: this.getProviderName() });

    if (credentials.giftCard) {
      setSignupGiftCardCode(credentials.giftCard);
    }

    if (desktopAppHelper.hasOpenedCurrentSession()) {
      this.setState({ isAuthFromDesktopFinished: true });

      const { email: emailFromState } = this.state;
      const { email: emailFromProps } = this.props;

      desktopAppHelper.openWithAuth({
        email: emailFromState || emailFromProps,
        isSignUp: response.isSignUp,
      });

      desktopAppHelper.removeSession();
    } else if (credentials.innerRedirectUri) {
      const { history } = this.props;
      const { innerRedirectUri } = credentials;

      history.replace(innerRedirectUri);
    } else if (response.isSignUp) {
      if (credentials.campaign === SETAPP_MOBILE_CAMPAIGN) {
        this.redirectToSetappMobile();

        return;
      }

      this.redirectToWelcomePage();
    } else {
      this.redirectToRootPage();
    }
  }

  onAuthError(error: RequestError | Error) {
    this.setProcessing(false);

    if (error instanceof Error) {
      this.setState({ genericError: error.message });

      logger.logError('Social auth did not succeed', error);

      return;
    }

    const emailError = error.getFieldError('email');
    const isEmailRequired = emailError && emailError.isRequired();
    const isAccountExist = emailError && emailError.isConflicted();

    this.setState({ isEmailRequired, isAccountExist });

    if (!isEmailRequired && !isAccountExist) {
      logger.logError('Social auth did not succeed', error);

      this.setState({ genericError: error.getGenericError().toString() });
    }
  }

  setProcessing(isProcessing: boolean) {
    this.setState({ isProcessing });
  }

  onFormFieldChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = currentTarget;

    this.setState({ [name]: value } as unknown as Pick<State, keyof State>);
  };

  // this is used for Google, not for Apple (Apple always gives back an email)
  onEmailFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, accessToken } = this.state;

    // eslint-disable-next-line react/no-access-state-in-setstate
    const emailError = validate({ email }, this.validation).email as unknown as string;

    this.setState({ emailError });

    if (emailError) {
      this.setState({ genericError: '' });

      return;
    }

    const signupParams = this.getSignupParams();

    this.authWithCredentials({
      ...signupParams,
      accessToken,
      email,
    });
  };

  onNewsletterFormSubmit = (marketingSubscribed = false) => {
    const { accessToken } = this.state;

    this.setState({ genericError: '' });

    const signupParams = this.getSignupParams();

    this.authWithCredentials({
      ...signupParams,
      accessToken,
      marketingSubscribed,
    });
  };

  onPasswordFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { password, requireCaptcha, captcha } = this.state;

    const passwordError = validate({ password }, this.validation).password;
    let formHasError = false;

    if (passwordError) {
      this.setState({ passwordError });
      formHasError = true;
    }

    if (requireCaptcha && !captcha) {
      this.setState({ captchaError: <CaptchaError.RequiredMessage /> });
      formHasError = true;
    }

    if (formHasError) {
      this.setState({ genericError: '' });
    } else {
      this.loginAndLinkSocialAccount();
    }
  };

  loginAndLinkSocialAccount() {
    const {
      email,
      password,
      captcha,
      accessToken,
    } = this.state;
    const {
      login,
      linkSocialAccount,
      match,
    } = this.props;

    this.setProcessing(true);

    return login({ email, password, captcha })
      .then(() => linkSocialAccount({ accessToken }, match.params.provider))
      .then((response: SocialAuthResponse) => this.onAuthSuccess(response))
      .catch((error) => this.onAccountLinkingError(error));
  }

  fetchProfile(): Promise<void> {
    const { email } = this.state;
    const { intl } = this.props;

    // do not fetch if email already present
    if (email) {
      return Promise.resolve();
    }

    return this.socialClient.fetchUserProfile()
      .then(({ email }) => {
        this.setState({ email });
      })
      .catch(() => {
        throw new Error(intl.formatMessage(messages.accessDeniedError, { provider: this.getProviderName() }));
      });
  }

  onAccountLinkingError(error: RequestError | Error) {
    this.setProcessing(false);

    if (error instanceof RequestError) {
      if (this.captcha) {
        this.captcha.reset();
      }

      const captchaError = error.getFieldError('captcha');
      const genericError = error.getGenericError();

      if (captchaError) {
        this.setState({
          requireCaptcha: true,
          captchaError: <CaptchaError error={captchaError} />,
        });
      }

      if (genericError) {
        this.setState({ genericError: genericError.message });
      }

      return;
    }

    this.setState({
      genericError: <DefaultError />,
    });
  }

  getProviderName() {
    const { match } = this.props;

    return SOCIAL_PROVIDER_NAMES[match.params.provider];
  }
}

export { SocialAuthPage as PureSocialAuthPage };

export default connector(injectIntl<Props>(SocialAuthPage));
