/* istanbul ignore file */

import { useRef, useEffect } from 'react';

export function makeCancelable(promise) {
  let isCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) => (!isCanceled && resolve(val)))
      .catch((error) => (!isCanceled && reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
}

export default function useCancellablePromise() {
  const promises = useRef<any>();

  useEffect(() => {
    promises.current = promises.current || [];

    return function cancel() {
      promises.current.forEach((promise) => promise.cancel());
      promises.current = [];
    };
  }, []);

  function cancellablePromise(promise) {
    const cancelablePromise = makeCancelable(promise);
    promises.current.push(cancelablePromise);

    return cancelablePromise.promise;
  }

  return { cancellablePromise };
}
