import React, { type FC, type ReactNode, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { osVersion, isDesktop } from 'react-device-detect';

import analytics, { events } from 'utils/analytics';

import DefaultError from 'components/shared/default-error/default-error';

import urls from 'config/urls';

import { getPrimaryPricePlan } from 'state/root-reducer';

import { isAiExpertPricePlan, isPowerUserPricePlan } from 'services/price-plans/utils';

import { SetappMobileProblemsType } from '../../utils/setapp-mobile-problem-codes';

import ProblemBanner from './problem-banner';
import DesktopQrCode from './desktop-qr-code';

import './problem-screen.scss';

type Props = {
  problemType: SetappMobileProblemsType;
};

const SetappMobileProblems: FC<Props> = ({ problemType }) => {
  const pricePlan = useSelector(getPrimaryPricePlan);

  useEffect(() => {
    if (problemType === 'EU_LOCATION_REQUIRED') {
      analytics.trackEvent(events.SETAPP_MOBILE_EU_ERROR_OCCURED, { eventLabel: 'Location Error' });
    }

    if (problemType === 'DEVICE_ERROR') {
      analytics.trackEvent(events.SETAPP_MOBILE_EU_ERROR_OCCURED, {
        eventLabel: 'Device Error',
        eventLabel2: osVersion,
      });
    }
  }, []);

  if (problemType === 'UNKNOWN_ERROR') {
    return <DefaultError />;
  }

  if (problemType === 'APP_ACCESS_FORBIDDEN_FOR_ACTIVE_SUBSCRIPTION') {
    return <Redirect to={urls.restrictSetappMobile} />;
  }

  const isPowerOrExpertPlan = pricePlan
    && (isPowerUserPricePlan(pricePlan?.tierType) || isAiExpertPricePlan(pricePlan?.tierType));

  const shouldShowIncludesMessage = problemType === 'FIRST_TIME_VISIT' && isPowerOrExpertPlan;
  const shouldShowFreeDuringBetaMessage = problemType === 'FIRST_TIME_VISIT' && !isPowerOrExpertPlan;
  const shouldShowTaxMessage = [
    'EMPTY_PAYMENT_DETAILS',
    'APP_ACCESS_FORBIDDEN_FOR_TRIAL_SUBSCRIPTION',
    'CUSTOMER_SUBSCRIPTION_INACTIVE',
    'BLOCKED_CUSTOMER'
  ].includes(problemType);

  let title = <FormattedMessage id="setappMobilePage.title" defaultMessage="Setapp Mobile" />;

  if (shouldShowIncludesMessage) {
    title = <FormattedMessage id="setappMobilePage.titleIncludes" defaultMessage="Your plan now includes access to Setapp Mobile!" />;
  } else if (shouldShowFreeDuringBetaMessage) {
    title = <FormattedMessage id="setappMobilePage.titleFreeDuringBeta" defaultMessage="Try Setapp Mobile for free during the invite-only beta" />;
  } else if (problemType === 'OFFER_UPGRADE') {
    title = <FormattedMessage id="setappMobilePage.upgradeTitle" defaultMessage="Upgrade your plan to use Setapp Mobile" />;
  }

  let description: ReactNode = (
    <FormattedMessage
      id="setappMobilePage.description"
      defaultMessage="Power up your iPhone with dozens of curated apps. Install Setapp Mobile and discover your new favorites."
    />
  );

  if (problemType === 'OFFER_UPGRADE') {
    description = null; // Description is rendered inside UpgradePlanCard
  }

  if (shouldShowFreeDuringBetaMessage) {
    description = (
      <FormattedMessage
        id="setappMobilePage.descriptionFreeDuringBeta"
        defaultMessage="Explore our alternative app marketplace for iPhones. No commitment, no extra charges. After the invite-only beta, you can upgrade your plan to keep using Setapp Mobile."
      />
    );
  }

  if (isDesktop) {
    return <DesktopQrCode />;
  }

  return (
    <>
      <h1 className="setapp-mobile-problems__title">
        {title}
      </h1>
      <p className="setapp-mobile-problems__description mb-0 mb-5">
        {description}
      </p>
      <ProblemBanner problemType={problemType} />

      <p className="text_weight-bold mt-6">
        <FormattedMessage id="setappMobilePage.featureList.title" defaultMessage="Make sure that:" />
      </p>
      <ul className="setapp-mobile-problems__feature-list">
        <li className={cls('mb-2 setapp-mobile-problems__list-item', {
          'setapp-mobile-problems__list-item_error': problemType === 'EU_LOCATION_REQUIRED'
          })}
        >
          <FormattedMessage
            id="setappMobilePage.featureList.euLocation"
            defaultMessage="You’re physically located in the EU"
          />
        </li>
        <li className={cls('mb-2 setapp-mobile-problems__list-item', {
          'setapp-mobile-problems__list-item_error': problemType === 'DEVICE_ERROR'
          })}
        >
          <FormattedMessage
            id="setappMobilePage.featureList.iosVersion"
            defaultMessage="Your iPhone runs iOS 17.4 or later"
          />
        </li>
        <li className="mb-2 setapp-mobile-problems__list-item">
          <FormattedMessage
            id="setappMobilePage.featureList.appleIdCountry"
            defaultMessage="Your Apple Account country is one of the EU states"
          />
        </li>
        <li className="mb-2 setapp-mobile-problems__list-item">
          <FormattedMessage
            id="setappMobilePage.featureList.browser"
            defaultMessage="You use Safari (download may not work in other browsers yet)"
          />
        </li>
      </ul>
      {shouldShowTaxMessage && (
        <p className="mt-7">
          <FormattedMessage
            id="setappMobilePage.tax"
            defaultMessage="Price after trial is an estimate for your location. The final amount may vary depending on applicable VAT."
          />
        </p>
      )}
    </>
  );
};

export default SetappMobileProblems;
