import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, FormCheckbox } from '@setapp/ui-kit';
import classNames from 'classnames';

import Modal from 'components/shared/modal/modal';

import { setLocalStorageItem } from 'utils/local-storage';
import analytics, { events } from 'utils/analytics';

import './activation-guide-modal.scss';

import activationVideo from './media/how-to-activate.mp4';
import thumbnail from './media/videoThumbnail.inline.png';
import thumbnail2x from './media/videoThumbnail@2x.inline.png';
import thumbnail3x from './media/videoThumbnail@3x.inline.png';

export type Props = Readonly<{
  show: boolean;
  iosAppName: string;
  onAppStoreClick: (checkedDontShow: boolean) => void;
  onHideItself: () => void;
}>;

export const DONT_SHOW_STORAGE_KEY = 'dontShowActivationGuide';

const ActivationGuideModal = ({ show, iosAppName, onHideItself, onAppStoreClick }: Props) => {
  const [checked, setChecked] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    if (show) {
      analytics.trackEvent(events.IOS_ACTIVATION_REMINDER_SHOWN, { eventLabel: iosAppName });
    }
  }, [iosAppName, show]);

  const handleAppStoreClick = () => {
    if (checked) {
      setLocalStorageItem(DONT_SHOW_STORAGE_KEY, true);
    }

    onAppStoreClick(checked);
    onHideItself();
  };

  const handleCheckboxChange = () => setChecked(!checked);

  const handleLoadedData = () => setIsVideoLoaded(true);

  const thumbnailClasses = classNames('activation-guide__thumbnail', {
    'activation-guide__thumbnail_loaded': isVideoLoaded,
  });

  const modalTitle = (
    <div className="mt-4 h3 mr-1 ml-1">
      <FormattedMessage id="activationGuide.title" defaultMessage="App Store is not enough" />
    </div>
  );

  return (
    <Modal
      show={show}
      title={modalTitle}
      withCloseButton={false}
      hideHeader
    >
      <p className="mb-5 mr-1 ml-1">
        <FormattedMessage
          id="activationGuide.activateReminder"
          defaultMessage="After you install the app on the App Store, please go back to this page to activate the app via Setapp."
        />
      </p>
      <div className="activation-guide__instruction-container">
        <img
          className={thumbnailClasses}
          src={thumbnail}
          srcSet={`${thumbnail2x} 2x, ${thumbnail3x} 3x`}
          width={418}
          height={324}
          decoding="sync"
          alt=""
        />
        <video
          className="activation-guide__instruction"
          playsInline
          autoPlay
          muted
          loop
          onLoadedData={handleLoadedData}
        >
          <source type="video/mp4" src={activationVideo} />
        </video>
      </div>
      <FormCheckbox
        id="guide-check"
        checked={checked}
        onChange={handleCheckboxChange}
        className="mr-1 ml-1"
      >
        <FormattedMessage id="activationGuide.checkbox" defaultMessage="Got it, don’t show it again" />
      </FormCheckbox>

      <Button block size="lg" className="mt-6 mb-1 mr-1 ml-1" onClick={handleAppStoreClick}>
        <FormattedMessage id="activationGuide.appStore" defaultMessage="Go to the App Store" />
      </Button>
    </Modal>
  );
};

export default ActivationGuideModal;
