import React, { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import FormContainer from 'components/shared/form/form-container/form-container';
import externalUrls from 'config/external-urls';
import validate from 'utils/auth-validation';
import LoginForm from './login-form/login-form';


import './login-step.scss';

export type LoginFormFields = {
  email: string;
  password: string;
  captcha?: string;
}

type LoginFormFieldsErrors = {
  email?: ReactNode;
  password?: ReactNode;
  captcha?: ReactNode;
}

type Props = {
  onSubmit: (value: LoginFormFields) => void;
  onForgotPassword: (email: string) => void;
  email: string;
  socialInnerRedirectUrl: string;
};

const LoginStep = (props: Props) => {
  const {
    email,
    onSubmit,
    onForgotPassword,
    socialInnerRedirectUrl,
  } = props;

  const validateForm = useCallback((fields: LoginFormFields): LoginFormFieldsErrors => validate(fields, {
    email: {
      required: <FormattedMessage
        id="oauth.login.validation.emptyEmail"
        defaultMessage="Email is required"
                />,
      emailFormat: <FormattedMessage
        id="oauth.login.validation.invalidEmail"
        defaultMessage="Invalid email"
                   />,
    },
    password: {
      required: <FormattedMessage
        id="oauth.login.validation.emptyPassword"
        defaultMessage="Password is required"
                />,
    },
  }), []);

  return (
    <div className="oauth-login-step__container">
      <FormContainer
        initialValues={{ email, password: '' }}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        {({
          fields,
          fieldsErrors,
          formError,
          isProcessing,
          onSubmit,
          onFieldChange,
          captcha,
        }) => <LoginForm
          email={fields.email}
          password={fields.password}
          emailError={fieldsErrors.email}
          passwordError={fieldsErrors.password}
          onEmailChange={onFieldChange}
          onPasswordChange={onFieldChange}
          genericError={formError}
          isRequestProcessing={isProcessing}
          onFormSubmit={onSubmit}
          onForgotPassword={onForgotPassword}
          captcha={captcha}
          innerRedirectUri={socialInnerRedirectUrl}
              />}
      </FormContainer>

      <div className="text-center oauth-login-step__footer">
        <Button variant="link" href={externalUrls.setappSite}>
          <FormattedMessage id="oauth.login.whatIsSetappButton" defaultMessage="What is Setapp?" />
        </Button>
      </div>
    </div>
  );
};

LoginStep.defaultProps = {
  email: '',
};

export { LoginStep as PureLoginForm };

export default LoginStep;
