import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl';

import './cell-with-soon-label-wrapper.scss';

const CellWithSoonLabelWrapper: FC = ({ children }) => (
  <>
    {children}
    <span className="soon-label">
      <FormattedMessage
        id="aiOffer.plansComparison.soonLabel"
        defaultMessage="Soon"
      />
    </span>
  </>
);

export default CellWithSoonLabelWrapper;
