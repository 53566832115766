import React from 'react';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isAiPricePlan, setappMobilePricePlans } from 'services/price-plans/utils';

import urls from 'config/urls';

import type { PricePlan } from 'state/price-plans/price-plans-initial-state';
import {
  AI_ENTHUSIAST_ANNUAL,
  AI_ENTHUSIAST_MONTHLY,
  AI_EXPERT_ANNUAL,
  AI_EXPERT_MONTHLY
} from 'state/price-plans/price-plans-types';
import { getFeatureFlags, getShowEstimatedVatIncludedWarning } from 'state/root-reducer';

import ErrorMessage from 'components/shared/error-message/error-message';
import { getIsInsideSetappMobileFlow } from 'components/pages/setapp-mobile/utils/setapp-mobile-flow-storage';
import { getStoredCustomerOauthError } from 'components/pages/customer-oauth-page/utils/customer-oauth-storage';

import PricePlanRadio from '../price-plan-radio/price-plan-radio';

import './change-plan-step.scss';

const pricePlansOrder = [AI_ENTHUSIAST_MONTHLY, AI_ENTHUSIAST_ANNUAL, AI_EXPERT_MONTHLY, AI_EXPERT_ANNUAL];

const pricePlansSortFn = (pricePlanA: PricePlan, pricePlanB: PricePlan) => {
  const pricePlanAIndex = pricePlansOrder.findIndex((aiTierType) => pricePlanA.tierType.includes(aiTierType));
  const pricePlanBIndex = pricePlansOrder.findIndex((aiTierType) => pricePlanB.tierType.includes(aiTierType));

  if (pricePlanAIndex !== -1 && pricePlanBIndex !== -1) {
    return pricePlanAIndex - pricePlanBIndex;
  }

  if (pricePlanAIndex !== -1) return -1;
  if (pricePlanBIndex !== -1) return 1;

  return 0;
};

type Props = {
  isProcessing: boolean;
  currentPricePlan: PricePlan;
  nextPricePlan?: PricePlan;
  pricePlans: PricePlan[];
  errorMessage?: ReactNode;
  onSelectNextPlan: (nextPricePlan: PricePlan) => void;
  onConfirmNextPlan: () => Promise<void>;
  isUserFamilyOwner?: boolean;
};

const ChangePlanStep: FC<Props> = (props) => {
  const history = useHistory();
  const {
    isProcessing,
    currentPricePlan,
    nextPricePlan,
    pricePlans,
    errorMessage,
    onSelectNextPlan,
    onConfirmNextPlan,
    isUserFamilyOwner,
  } = props;

  const showEstimatedVatIncludedWarning = useSelector(getShowEstimatedVatIncludedWarning);
  const { enableAiOfferPlans } = useSelector(getFeatureFlags);

  const { priceKey, paidMonth } = currentPricePlan;
  const nextPricePlanPriceKey = nextPricePlan?.priceKey || priceKey;
  const isCurrentPricePlanSelected = nextPricePlanPriceKey === priceKey;
  const isCurrentPlanCustom = pricePlans.every(
    (pricePlan: PricePlan) => pricePlan.priceKey !== priceKey
  );

  const doNotShowAiPlans = !enableAiOfferPlans.value || isUserFamilyOwner;

  const showOnlySetappMobilePlans = getIsInsideSetappMobileFlow() && history.location.pathname === urls.setappMobile
    || getStoredCustomerOauthError() === 'OFFER_UPGRADE' && history.location.pathname === urls.customerOauth;

  const filteredPricePlans = pricePlans.filter((pricePlan: PricePlan) => {
    if (pricePlan.paidMonth !== paidMonth) {
      return false;
    }

    if (doNotShowAiPlans && isAiPricePlan(pricePlan.tierType)) {
      return false;
    }

    if (showOnlySetappMobilePlans) {
      const isMoreExpensiveOrEqualPlan = pricePlan.price >= currentPricePlan.price;
      const isCurrentPlan = pricePlan.tierType === currentPricePlan.tierType;

      return isMoreExpensiveOrEqualPlan && setappMobilePricePlans.includes(pricePlan.tierType)
        || isCurrentPlan;
    }

    return true;
  });
  const suggestedPricePlans = [
    isCurrentPlanCustom ? currentPricePlan : null,
    ...filteredPricePlans,
  ].filter(Boolean) as PricePlan[];

  const handlePricePlanSelect = ({
    currentTarget,
  }: SyntheticEvent<HTMLInputElement>) => {
    const { value } = currentTarget;
    const nextPricePlan = suggestedPricePlans.find(
      (pricePlan: PricePlan) => pricePlan.priceKey === value
    ) as PricePlan;

    onSelectNextPlan(nextPricePlan);
  };

  return (
    <>
      <p className="mb-2">
        <FormattedMessage
          id="manageSubscriptionModal.changePlanStep.description"
          defaultMessage="Choose Setapp that’s best for you."
        />
      </p>
      {showEstimatedVatIncludedWarning && (
        <FormattedMessage
          id="manageSubscriptionModal.changePlanStep.includesVAT"
          defaultMessage="Price includes estimated VAT for your location."
        />
      )}

      <ul className="change-plan-step__price-plans-list">
        {suggestedPricePlans.sort(pricePlansSortFn).map((pricePlan: PricePlan) => (
          <li key={pricePlan.tierType}>
            <PricePlanRadio
              isSelected={pricePlan.priceKey === nextPricePlanPriceKey}
              pricePlan={pricePlan}
              currentPricePlan={currentPricePlan}
              onChange={handlePricePlanSelect}
            />
          </li>
        ))}
      </ul>

      <ButtonLoading
        variant="primary"
        size="lg"
        block
        isLoading={isProcessing}
        disabled={isCurrentPricePlanSelected || isProcessing}
        onClick={onConfirmNextPlan}
      >
        <FormattedMessage
          id="manageSubscriptionModal.changePlanStep.CTA"
          defaultMessage="Continue"
        />
      </ButtonLoading>

      {errorMessage && (
        <ErrorMessage className="mt-4">{errorMessage}</ErrorMessage>
      )}
    </>
  );
};

export default ChangePlanStep;
