import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@setapp/ui-kit';

import externalUrls from 'config/external-urls';
import urls from 'config/urls';

import analytics, { events } from 'utils/analytics';
import desktopAppHelper from 'utils/desktop-app-helper';
import logger from 'utils/logger';

import { detectDesktopApp } from 'state/desktop-app/desktop-app-reducer';
import {
  hasDesktopAppAlreadyDetected as hasDesktopAppAlreadyDetectedSelector,
  isDesktopAppAvailable as isDesktopAppAvailableSelector,
} from 'state/root-reducer';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';

import './family-subscription-activated.scss';
import welcomeImage from './images/welcome.svg';

const FamilySubscriptionActivated = () => {
  const dispatch = useDispatch();
  const hasDesktopAppAlreadyDetected = useSelector(hasDesktopAppAlreadyDetectedSelector);
  const isDesktopAppAvailable = useSelector(isDesktopAppAvailableSelector);

  useEffect(() => {
    if (!hasDesktopAppAlreadyDetected) {
      dispatch(detectDesktopApp());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLaunchSetappClick = async () => {
    try {
      await desktopAppHelper.openDesktopApp();
      analytics.trackEvent(
        events.SUBSCRIPTION_ACTIVATED_LAUNCH_DESKTOP,
        { eventLabel: 'Click Button' },
      );
    } catch (error) {
      logger.logWarn("Couldn't launch Setapp desktop app", { error });
    }
  };

  const handleDownloadSetappClick = () => {
    analytics.trackEvent(
      events.SUBSCRIPTION_ACTIVATED_DOWNLOAD_START,
      { eventLabel: 'Click Button' },
    );
  };

  return (
    <FullscreenLayout>
      <FullscreenLayout.PrimaryContent withLogo>
        <div className="family-subscription-activated__primary-content">
          <h3 className="h3 family-subscription-activated__title">
            <FormattedMessage
              id="familySubscriptionActivated.title"
              defaultMessage="Welcome to the family!"
            />
          </h3>
          <p className="mb-8 text_lg">
            <FormattedMessage
              id="familySubscriptionActivated.description"
              defaultMessage="Your subscription is now active. Start using Setapp or invite your Family plan participants."
            />
          </p>

          <Button
            className="mb-3"
            block
            size="lg"
            href={urls.family}
          >
            <FormattedMessage
              id="familySubscriptionActivated.inviteParticipantsButton"
              defaultMessage="Invite participants"
            />
          </Button>

          {isDesktopAppAvailable ? (
            <Button
              block
              variant="secondary-outline"
              size="lg"
              onClick={handleLaunchSetappClick}
            >
              <FormattedMessage
                id="familySubscriptionActivated.launchSetappButton"
                defaultMessage="Launch Setapp"
              />
            </Button>
          ) : (
            <SetappDownloadLinkWrapper>
              <Button
                block
                variant="secondary-outline"
                size="lg"
                href={externalUrls.downloadDesktopClient}
                onClick={handleDownloadSetappClick}
              >
                <FormattedMessage
                  id="familySubscriptionActivated.downloadSetappButton"
                  defaultMessage="Download Setapp"
                />
              </Button>
            </SetappDownloadLinkWrapper>
          )}
        </div>
      </FullscreenLayout.PrimaryContent>

      <FullscreenLayout.SecondaryContent>
        <div className="family-subscription-activated__secondary-content">
          <img
            src={welcomeImage}
            className="img-responsive"
            width={560}
            height={542}
            alt=""
          />
        </div>
      </FullscreenLayout.SecondaryContent>
    </FullscreenLayout>
  );
};

export default FamilySubscriptionActivated;
