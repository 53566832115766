import { DEFAULT_LOCALE } from 'config/locales';
import auth from 'utils/auth';

import userInitialState from 'state/user/user-initial-state';

const initialState = () => {
  const isUserAuthenticated = auth.isLoggedIn();

  return {
    intl: {
      defaultLocale: DEFAULT_LOCALE,
    },
    user: {
      ...userInitialState,
      isAuthenticated: isUserAuthenticated,
    },
  };
};

export default initialState;
