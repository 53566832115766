import React from 'react';
import type { ReactNode } from 'react';

import './info-block.scss';

type Props = {
  title: ReactNode;
  description: ReactNode;
  rightBlock: ReactNode;
};

const InfoBlock = ({ title, description, rightBlock }: Props) => (
  <>
    <div className="info-block__main mb-2">
      <div className="text_xl">
        {title}
      </div>
      <div>
        {rightBlock}
      </div>
    </div>
    <div className="info-block__description">
      {description}
    </div>
  </>
);

export default InfoBlock;
