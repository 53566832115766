// @flow

import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';

import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import { type Subscription } from 'state/subscription/subscription-initial-state';

import NextPaymentDateEstimation from 'components/shared/next-payment-date-estimation/next-payment-date-estimation';
import TaxInfo from 'components/shared/tax-info/tax-info';

type Props = {|
  isProcessing: boolean,
  nextPricePlan: PricePlan,
  primarySubscription: Subscription,
  onSubmitNextPlan: () => Promise<void>,
|};

const ConfirmBillingPeriodStep = (props: Props) => {
  const {
    isProcessing,
    nextPricePlan,
    primarySubscription,
    onSubmitNextPlan,
  } = props;

  const isMonthlyNextBillingPeriod = nextPricePlan.paidMonth === 1;

  const { nextPaymentDate } = primarySubscription;
  let description;

  if (nextPaymentDate) {
    const formattedNextPaymentDate = (
      <strong>
        <FormattedDate value={nextPaymentDate * 1000} year="numeric" month="short" day="numeric" />
      </strong>
    );

    description = isMonthlyNextBillingPeriod ? (
      <FormattedMessage
        id="changePricePlanFlow.confirmBillingPeriodStep.description.monthly"
        defaultMessage="Starting {date}, you will be charged once a month."
        values={{ date: formattedNextPaymentDate }}
      />
    ) : (
      <FormattedMessage
        id="changePricePlanFlow.confirmBillingPeriodStep.description.annual"
        defaultMessage="Starting {date}, you will be charged once a year."
        values={{ date: formattedNextPaymentDate }}
      />
    );
  } else {
    description = isMonthlyNextBillingPeriod ? (
      <FormattedMessage
        id="changePricePlanFlow.confirmBillingPeriodStep.descriptionNoPaymentDate.monthly"
        defaultMessage="You will be charged once a month."
      />
    ) : (
      <FormattedMessage
        id="changePricePlanFlow.confirmBillingPeriodStep.descriptionNoPaymentDate.annual"
        defaultMessage="You will be charged once a year."
      />
    );
  }

  return (
    <>
      <h3 className="mt-0 mb-3">
        {isMonthlyNextBillingPeriod ? (
          <FormattedMessage
            id="changePricePlanFlow.confirmBillingPeriodStep.title.monthly"
            defaultMessage="Switch to monthly billing"
          />
        ) : (
          <FormattedMessage
            id="changePricePlanFlow.confirmBillingPeriodStep.title.annual"
            defaultMessage="Switch to annual billing"
          />
        )}
      </h3>

      <div className="text_lg">
        {description}
      </div>

      <div className="mt-8 mb-5">
        <ButtonLoading
          variant="primary"
          size="lg"
          block
          isLoading={isProcessing}
          onClick={onSubmitNextPlan}
        >
          <FormattedMessage
            id="changePricePlanFlow.confirmBillingPeriodStep.CTA"
            defaultMessage="Confirm"
          />
        </ButtonLoading>
      </div>

      {nextPaymentDate && (
        <>
          <NextPaymentDateEstimation
            price={nextPricePlan.price}
            currency={nextPricePlan.currency}
            nextPaymentDate={nextPaymentDate}
          />
          <div className="mt-2">
            <TaxInfo />
          </div>
        </>
      )}
    </>
  );
};

export default ConfirmBillingPeriodStep;
