import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './gift-card.scss';

import smallGift from './images/small-gift.png';
import smallGift2x from './images/small-gift@2x.png';
import smallGift3x from './images/small-gift@3x.png';
import mediumGift from './images/medium-gift.png';
import mediumGift2x from './images/medium-gift@2x.png';
import mediumGift3x from './images/medium-gift@3x.png';
import bigGift from './images/big-gift.png';
import bigGift2x from './images/big-gift@2x.png';
import bigGift3x from './images/big-gift@3x.png';

// WARNING: we hardcode 3 gift card types for now. In production we expect only 1, 3 and 12 months gift cards
const cardNameMap = new Map([
  [1, 'Setapp Gift Card'],
  [3, 'Setapp Gift Card+'],
  [12, 'Better Value Gift'],
]);

const cardImagesMap = new Map([
  [1, [smallGift, smallGift2x, smallGift3x]],
  [3, [mediumGift, mediumGift2x, mediumGift3x]],
  [12, [bigGift, bigGift2x, bigGift3x]],
]);

type Props = {
  paidMonths: number;
  isActive?: boolean;
};

const GiftCard = (props: Props) => {
  const { paidMonths, isActive = true } = props;

  const defaultCardName = (
    <FormattedMessage
      id="giftCards.giftCardPeriod"
      defaultMessage="{paidMonths, plural, one {{paidMonths} month} other {{paidMonths} months}}"
      values={{ paidMonths }}
    />
  );

  const cardName = cardNameMap.get(paidMonths) || defaultCardName;
  const cardImages = cardImagesMap.get(paidMonths) ?? cardImagesMap.get(3)!;

  const cardClasses = classNames('gift-card', { 'gift-card_inactive': !isActive });

  return (
    <div className={cardClasses}>
      <h5 className="gift-card__title">{cardName}</h5>
      <img
        className="img-responsive center-block"
        src={cardImages[0]}
        srcSet={`${cardImages[1]} 2x, ${cardImages[2]} 3x`}
        width="300"
        height="200"
        alt=""
      />
    </div>
  );
};

export default GiftCard;
