import React, { ReactNode, Component } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import httpStatuses from 'http-status';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  InjectedIntlProps,
} from 'react-intl';
import { FormField } from '@setapp/ui-kit';

import validate from 'utils/auth-validation';
import { getSupportUrl } from 'utils/support';
import analytics, { events } from 'utils/analytics';

import { fetchRedeemCode } from 'state/redeem-code/redeem-code-actions';
import { setSignupGiftCardCode, setSignupPromoCode } from 'state/user/user-actions';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import {
  isGiftCardRedeemCode,
  isPromoCodeRedeemCode
} from 'state/root-reducer';

import DefaultError from 'components/shared/default-error/default-error';
import SimplifiedForm from 'components/shared/simplified-form/simplified-form';

const messages = defineMessages({
  redeemCodePlaceholder: {
    id: 'redeem.primary.redeemCodeField.placeholder',
    defaultMessage: 'Enter your code',
  },
  invalidRedeemCodeError: {
    id: 'redeem.primary.redeemCodeField.error.invalid',
    defaultMessage: 'Uh-oh, that doesn’t look like our code! Try again.',
  },
  forbiddenRedeemCodeError: {
    id: 'redeem.primary.redeemCodeField.error.forbidden',
    defaultMessage: 'Oh no, this code has already been used.',
  },
  emptyFieldError: {
    id: 'redeem.primary.redeemCodeField.error.empty',
    defaultMessage: 'Oops, looks like you left it empty! Try again.',
  },
});

const mapStateToProps = (state) => ({
  isGiftCardRedeemCode: isGiftCardRedeemCode(state),
  isPromoCodeRedeemCode: isPromoCodeRedeemCode(state),
});

const mapActionsToProps = {
  fetchRedeemCode,
  setSignupGiftCardCode,
  setSignupPromoCode,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PureProps {
  defaultRedeemCode?: string;
}

type Props = PropsFromRedux & RouteComponentProps & InjectedIntlProps & PureProps;

type State = {
  redeemCode: string;
  isRedeemCodeProcessing: boolean;
  redeemCodeFieldError: ReactNode | null;
}

class FirstStepPrimaryContent extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      redeemCode: '',
      isRedeemCodeProcessing: false,
      redeemCodeFieldError: null,
    };
  }

  componentDidMount() {
    const { defaultRedeemCode } = this.props;

    if (defaultRedeemCode) {
      this.setState({ redeemCode: defaultRedeemCode });
    }
  }

  render() {
    const { intl } = this.props;
    const { redeemCode, isRedeemCodeProcessing, redeemCodeFieldError } = this.state;

    return (
      <>
        <h3 className="redeem__primary-title">
          <FormattedMessage id="redeem.primary.redeemCodeTitle" defaultMessage="Redeem promo code or gift card." />
        </h3>
        <div className="mt-5 mb-10">
          <SimplifiedForm
            submitBtnCaption={<FormattedMessage
              id="redeem.primary.btnCaption.redeemCode"
              defaultMessage="Continue"
                              />}
            onSubmit={this.checkRedeemCodeValidity}
            isSubmitProcessing={isRedeemCodeProcessing}
          >
            <FormField
              id="redeemCode"
              type="text"
              name="redeemCode"
              value={redeemCode}
              label={<FormattedMessage
                id="redeem.primary.redeemCodeField.label"
                defaultMessage="Redeem code"
                     />}
              placeholder={intl.formatMessage(messages.redeemCodePlaceholder)}
              helpText={redeemCodeFieldError}
              invalid={Boolean(redeemCodeFieldError)}
              onChange={this.handleChangeRedeemCode}
              autoFocus
              qaLabel="redeemCodeInput"
            />
          </SimplifiedForm>
          <p className="mt-5">
            <FormattedMessage
              id="redeem.primary.note.learnMore"
              defaultMessage="Learn more about Setapp promo codes and gift cards {link}."
              values={{
                link: (
                  <a
                    href={`${getSupportUrl()}/articles/360001349780-How-do-I-activate-my-Setapp-gift-card-`}
                    onClick={this.handleLearnAboutRedeemCodeLinkClick}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="redeem.primary.note.learnAboutCodes.link"
                      defaultMessage="here"
                    />
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </>
    );
  }

  handleChangeRedeemCode = (e) => {
    this.setState({
      redeemCode: e.currentTarget.value,
      redeemCodeFieldError: null,
    });
  };

  checkRedeemCodeValidity = (e) => {
    e.preventDefault();

    const {
      intl,
      fetchRedeemCode,
      showDangerNotification,
    } = this.props;
    const { redeemCode } = this.state;

    analytics.trackEvent(events.REDEEM_CODE_CLICK);

    const validationResult = validate({ redeemCode }, {
      redeemCode: { required: messages.emptyFieldError },
    });

    if (validationResult.redeemCode) {
      const errorMessage = intl.formatMessage(validationResult.redeemCode);
      this.setState({ redeemCodeFieldError: errorMessage });
      analytics.trackEvent(events.REDEEM_CODE_ERROR, { eventLabel: errorMessage });

      return null;
    }

    this.setState({ isRedeemCodeProcessing: true });

    return fetchRedeemCode(redeemCode)
      .then(() => this.handleFetchRedeemCodeSuccess(redeemCode))
      .catch((error) => {
        this.setState({ isRedeemCodeProcessing: false });
        let errorSource = '';

        if (error.errors) {
          const firstError = error.errors[0];
          errorSource = firstError.field;
        }

        if (error.status === httpStatuses.NOT_FOUND) {
          const errorMessage = intl.formatMessage(messages.invalidRedeemCodeError);
          this.setState({ redeemCodeFieldError: errorMessage });
          analytics.trackEvent(events.REDEEM_CODE_ERROR, { eventLabel: errorMessage, eventLabel2: errorSource });
        } else if (error.status === httpStatuses.FORBIDDEN) {
          const errorMessage = intl.formatMessage(messages.forbiddenRedeemCodeError);
          this.setState({ redeemCodeFieldError: errorMessage });
          analytics.trackEvent(events.REDEEM_CODE_ERROR, { eventLabel: errorMessage, eventLabel2: errorSource });
        } else {
          showDangerNotification(<DefaultError />);
          analytics.trackEvent(events.REDEEM_CODE_ERROR, { eventLabel: 'Unknown error' });
        }
      });
  };

  handleFetchRedeemCodeSuccess = (redeemCode: string) => {
    const {
      setSignupGiftCardCode,
      setSignupPromoCode,
      isGiftCardRedeemCode,
      isPromoCodeRedeemCode,
    } = this.props;

    if (isGiftCardRedeemCode) {
      setSignupGiftCardCode(redeemCode);
      analytics.trackEvent(events.REDEEM_CODE_SUCCESS, { eventLabel: 'Gift Card' });
    }

    if (isPromoCodeRedeemCode) {
      setSignupPromoCode(redeemCode);
      analytics.trackEvent(events.REDEEM_CODE_SUCCESS, { eventLabel: 'Promo Code' });
    }
  }

  handleLearnAboutRedeemCodeLinkClick = () => analytics.trackEvent(events.LEARN_ABOUT_REDEEM);
}

export { FirstStepPrimaryContent as PureFirstStepPrimaryContent };

export default connector(injectIntl<PureProps>(FirstStepPrimaryContent));
