import React, { PureComponent } from 'react';
import type { ReactNode } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import auth from 'utils/auth';
import analytics, { events } from 'utils/analytics';
import { getSignupMetadata } from 'utils/service-locators';

import AnimatedLogo from 'components/shared/animated-logo/animated-logo';
import SignupDefault from './signup-default/signup-default';
import SignupInvitationError from './signup-invitation-error/signup-invitation-error';
import signUpHoc from './signup-page-hoc';


import './signup-page.scss';

const signUpMetadata = getSignupMetadata();

type Props = {
  match: RouteComponentProps<{ invitation: string }>['match'];
  location: RouteComponentProps['location'];
  setFields: ({ inviteId }: { inviteId: string }) => void;
  signUpForm: ReactNode;
  isLoading?: boolean;
};

type State = {
  isInvitationValid: boolean | null;
  isInvitationCheckingProcessing: boolean;
};

class SignUpPage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isInvitationValid: null,
      isInvitationCheckingProcessing: false,
    };
  }

  componentDidMount() {
    const { location, match: { params: { invitation: invitationFromParams } } } = this.props;
    const query = queryString.parse(location.search);
    const { invitation: invitationFromQuery } = query;

    this.checkInvitation(invitationFromQuery as string || invitationFromParams);
  }

  render() {
    const { isInvitationCheckingProcessing, isInvitationValid } = this.state;
    const { signUpForm, isLoading } = this.props;

    if (isInvitationCheckingProcessing || isInvitationValid === null || isLoading) {
      return (
        <div className="signup-page__loading">
          <AnimatedLogo animate />
        </div>
      );
    }
    if (isInvitationValid) {
      return (
        <SignupDefault>
          {signUpForm}
        </SignupDefault>
      );
    }

    return <SignupInvitationError />;
  }

  checkInvitation(token?: string): Promise<void> {
    if (!token) {
      this.setState({ isInvitationValid: true });

      return Promise.resolve();
    }

    const { setFields } = this.props;
    this.setState({ isInvitationCheckingProcessing: true });

    return auth.validateInvitationToken(token)
      .then(() => {
        analytics.trackEvent(events.SIGNUP_TOKEN_VALID);

        setFields({ inviteId: token });
        signUpMetadata.save({ inviteId: token });
        this.setState({ isInvitationValid: true, isInvitationCheckingProcessing: false });
      })
      .catch(() => {
        analytics.trackEvent(events.SIGNUP_TOKEN_INVALID);

        this.setState({ isInvitationValid: false, isInvitationCheckingProcessing: false });
      });
  }
}

export { SignUpPage as PureSignupPage };
// @ts-expect-error TS(2345): Argument of type 'typeof SignUpPage' is not assign... Remove this comment to see the full error message
export default signUpHoc()(SignUpPage);
