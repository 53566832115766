import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DesktopAppInstallerUrl from '@setapp/desktop-app-installer-url';
import { SatuCookieHelper } from '@setapp/abn-tests-client';

import externalUrls from 'config/external-urls';

import { getUser } from 'state/root-reducer';

import analytics, { events } from 'utils/analytics/index';
import Gtm from 'utils/analytics/gtm';

type Props = {
  onAutoDownloadStart: () => void;
  autoDownloadDelay?: number;
  campaign?: string;
};

type State = {
  startAutoDownload: boolean;
}

class AutoDownload extends PureComponent<Props, State> {
  state = {
    startAutoDownload: false,
  };

  autoDownloadTimeoutId;

  static defaultProps = {
    autoDownloadDelay: 3000,
  };

  componentDidMount() {
    this.setAutoDownloadTimeout();
  }

  componentWillUnmount() {
    this.clearAutoDownloadTimeout();
  }

  render() {
    const { startAutoDownload } = this.state;

    return startAutoDownload && (
      // eslint-disable-next-line jsx-a11y/iframe-has-title
      <iframe
        style={{ position: 'absolute' }}
        width="0"
        height="0"
        frameBorder="0"
        src={this.getSrc(externalUrls.downloadDesktopClient)}
        data-qa="autoDownload"
      />
    );
  }

  getSrc(url: string) {
    const { campaign } = this.props;

    return new DesktopAppInstallerUrl(url, {
      trackingCid: Gtm.getCID(),
      satuId: SatuCookieHelper.getSatuId(),
      campaign,
    }).toString();
  }

  clearAutoDownloadTimeout() {
    clearTimeout(this.autoDownloadTimeoutId);
  }

  setAutoDownloadTimeout() {
    const { autoDownloadDelay } = this.props;

    this.autoDownloadTimeoutId = setTimeout(() => this.startAutoDownload(), autoDownloadDelay);
  }

  startAutoDownload() {
    const { onAutoDownloadStart } = this.props;

    this.setState({ startAutoDownload: true });

    analytics.trackEvent(events.WELCOME_PAGE_DOWNLOAD_STARTED);

    if (onAutoDownloadStart) {
      onAutoDownloadStart();
    }
  }
}

const mapStateToProps = (state) => ({
  campaign: getUser(state).campaign ?? undefined,
});

export default connect(mapStateToProps)(AutoDownload);
