import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import urls from 'config/urls';

import logger from 'utils/logger';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { getPrimarySubscription } from 'state/root-reducer';
import * as subscriptionStatuses from 'state/subscription/statuses';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';
import type { Subscription } from 'state/subscription/subscription-initial-state';

import DefaultError from 'components/shared/default-error/default-error';

const DEFAULT_TIMEOUT_DELAY = 3000;

export type PaymentWaitingStatus = 'success' | 'pending' | 'failed';

type Params = {
  timeoutDelay?: number;
  onSuccessPayment: () => void;
  onFailedPayment?: () => void;
  onPendingPayment?: () => void;
};

/**
 * Utility hook for `useMultiplePaymentWaiting` hook. This hook waits for defined time then fetch subscription data
 * and look up for subscription status:
 * - if payment is success and subscription status is ACTIVE, then call on success callback
 * - if payment is failed redirect user to subscription page and show default error
 * - if payment is pending redirect user to the subscription page where `PermanentNotificationContainer` component
 * will continue to wait for payment result
 *
 * @param {object} params - Configuration object for the hook.
 * @param {number} [params.timeoutDelay=3000] - Delay before fetching subscription data.
 * @param {function} params.onSuccessPayment - On payment success callback.
 * @param {function} [params.onFailedPayment] - On payment failed callback, pass it to overwrite default behavior.
 * @param {function} [params.onPendingPayment] - On payment pending callback, pass it to overwrite default behavior.
 */
const usePaymentWaiting = (params: Params) => {
  const {
    timeoutDelay = DEFAULT_TIMEOUT_DELAY,
    onSuccessPayment,
    onFailedPayment,
    onPendingPayment,
  } = params;

  const history = useHistory();
  const dispatch = useDispatch();

  // Use ref to have an updated subscription object in the callback
  const primarySubscriptionRef = useRef<Subscription>();
  primarySubscriptionRef.current = useSelector(getPrimarySubscription);

  const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);

  // Clear timeout on unmount
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    return () => {
      const timeoutId = timeoutIdRef.current;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const redirectToSubscriptionPage = () => {
    history.replace(urls.subscription);
  };

  const defaultPendingPaymentHandler = () => {
    // redirect to subscription page to continue to wait for payment result
    redirectToSubscriptionPage();
  };
  const defaultFailedPaymentHandler = () => {
    dispatch(showDangerNotification(<DefaultError />));
    redirectToSubscriptionPage();
  };

  const handlePendingPayment = onPendingPayment ?? defaultPendingPaymentHandler;
  const handleFailedPayment = onFailedPayment ?? defaultFailedPaymentHandler;

  const waitForPaymentSuccess = async () => {
    return new Promise<PaymentWaitingStatus>((resolve) => {
      setIsWaitingForPayment(true);

      timeoutIdRef.current = setTimeout(async () => {
        await dispatch(fetchAllSubscriptions());

        setIsWaitingForPayment(false);

        const primarySubscription = primarySubscriptionRef.current;
        if (!primarySubscription) {
          return;
        }

        const isPaymentNotStarted = !primarySubscription.paymentPending
          && primarySubscription.status !== subscriptionStatuses.ACTIVE;

        // Failed payment
        if (primarySubscription.lastPaymentFailed) {
          handleFailedPayment();
          resolve('failed');

          return;
        }

        // After timeout payment is still pending or payment not started yet (when timeout delay is too short).
        if (primarySubscription.paymentPending || isPaymentNotStarted) {
          handlePendingPayment();
          resolve('pending');

          return;
        }

        // Payment is success and subscription is active. Call on success handler
        if (primarySubscription.status === subscriptionStatuses.ACTIVE) {
          onSuccessPayment();
          resolve('success');

          return;
        }

        // Unknown case
        handleFailedPayment();
        logger.logError(
          'Payment waiting hook: unhandled case',
          { primarySubscription },
        );
        resolve('failed');
      }, timeoutDelay);
    });
  };

  return { isWaitingForPayment, waitForPaymentSuccess };
};

export default usePaymentWaiting;
