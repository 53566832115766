import React, { ReactNode, SyntheticEvent } from 'react';
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
} from 'react-intl';

import { Button } from '@setapp/ui-kit';

import CurrentPasswordField from 'components/shared/form/current-password-field/current-password-field';

type Props = {
  onSubmit: (e: SyntheticEvent<HTMLFormElement>) => void;
  onFieldChange: (e: SyntheticEvent<HTMLInputElement>) => void;
  isProcessing: boolean;
  fieldsErrors: {
    password: ReactNode;
  };
  fields: {
    password: ReactNode;
  };
  formError: ReactNode;
  email?: string;
} & InjectedIntlProps;

const messages = defineMessages({
  confirmPasswordPlaceholder: {
    id: 'deleteAccountPage.passwordPlaceholder',
    defaultMessage: 'Enter current password',
  },
});

const DeleteAccountForm = (props: Props) => {
  const {
    onFieldChange, onSubmit, intl, fieldsErrors, formError, isProcessing, fields, email,
  } = props;

  return (
    <form
      onSubmit={onSubmit}
      noValidate
    >
      <CurrentPasswordField
        id="currentPassword"
        name="password"
        type="password"
        email={email}
        label={<FormattedMessage id="deleteAccountPage.passwordLabel" defaultMessage="Enter your password to confirm:" />}
        placeholder={intl.formatMessage(messages.confirmPasswordPlaceholder)}
        onChange={onFieldChange}
        helpText={fieldsErrors.password}
        invalid={Boolean(fieldsErrors.password)}
        value={fields.password}
        showLabel
        required
        qaLabel="currentPasswordField"
      />

      {formError && <div className="form-error text-danger">{formError}</div>}

      <Button
        variant="danger-outline"
        block
        type="submit"
        disabled={isProcessing}
        className="mt-5"
        data-qa="deleteAccountButton"
      >
        <FormattedMessage id="deleteAccountPage.btnSubmit" defaultMessage="Delete account" />
      </Button>
    </form>
  );
};

export { DeleteAccountForm as PureDeleteAccountForm };

export default injectIntl(DeleteAccountForm);
