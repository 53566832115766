import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import urls from 'config/urls';

import { isCheapPricePlan } from 'services/price-plans/utils';

import { getDisplayedPricePlan  } from 'state/root-reducer';

import { FormattedPrice, FormattedPeriod } from 'components/shared/formatter/formatter';
import CancelSubscriptionStepLayout from '../cancel-subscription-step-layout/cancel-subscription-step-layout';

import coinGuyIllustration from './images/coin-guy.png';
import coinGuyIllustration2x from './images/coin-guy@2x.png';
import coinGuyIllustration3x from './images/coin-guy@3x.png';


export type Props = {
  onManagePlanButtonClick: () => void;
  onCancelSubscriptionButtonClick: () => void;
  onReadHowToSaveMoreButtonClick: () => void;
};

const TooExpensiveConfirmationStep = ({
  onManagePlanButtonClick,
  onCancelSubscriptionButtonClick,
  onReadHowToSaveMoreButtonClick,
}: Props) => {
  const pricePlan = useSelector(getDisplayedPricePlan);

  /* istanbul ignore next */
  if (!pricePlan) {
    return null;
  }

  const isUserPlanCheap = isCheapPricePlan(pricePlan);

  const priceFormatted = (
    <FormattedPrice
      price={pricePlan.price}
      currency={pricePlan.currency}
    />
  );
  const periodFormatted = <FormattedPeriod paidMonth={pricePlan.paidMonth} />;

  const title = isUserPlanCheap ? (
      <FormattedMessage
        id="cancelSubscription.tooExpensiveConfirmationTryReferral.title"
        defaultMessage="Pay less with Setapp!"
      />
  ) : (
      <FormattedMessage
        id="cancelSubscription.tooExpensiveConfirmation.title"
        defaultMessage="Your wish  — our command!"
      />
  );

  const primaryButtonText = isUserPlanCheap ? (
    <FormattedMessage id="cancelSubscription.tooExpensiveConfirmationTryReferral.cancelSubscriptionButton" defaultMessage="Nah, let’s cancel" />
  ) : (
    <FormattedMessage id="cancelSubscription.tooExpensiveConfirmation.cancelSubscriptionButton" defaultMessage="Nah, let’s cancel" />
  );

  const secondaryButtonClickHandler = isUserPlanCheap ? onReadHowToSaveMoreButtonClick : onManagePlanButtonClick;
  const secondaryButtonText = isUserPlanCheap ? (
    <FormattedMessage id="cancelSubscription.tooExpensiveConfirmationTryReferral.managePlanButton" defaultMessage="Maybe I can invite a friend" />
  ) : (
    <FormattedMessage id="cancelSubscription.tooExpensiveConfirmation.managePlanButton" defaultMessage="Okay, I’ll look at cheaper plans" />
  );

  const content = isUserPlanCheap ? (
    <>
      <p>
        <FormattedMessage
          id="cancelSubscription.tooExpensiveConfirmationTryReferral.description.firstLine"
          defaultMessage="On average (as of September 2022), a Setapp user saves $10/month on app subscriptions. But you can save even more!"
          values={{
            price: priceFormatted,
            period: periodFormatted,
          }}
        />
      </p>
      <p className="mb-6">
        <FormattedMessage
          id="cancelSubscription.tooExpensiveConfirmationTryReferral.description.secondLine"
          defaultMessage="Get {link} for referring friends to Setapp — send a few invites out and see what happens."
          values={{
          link: (
            <Link className="text_color-warning" to={urls.referral}>
              <FormattedMessage id="devicesPage.tooExpensiveConfirmationTryReferral.link" defaultMessage="free months" />
            </Link>
          ),
          }}
        />
      </p>
    </>
  ) : (
    <>
      <p>
        <FormattedMessage
          id="cancelSubscription.tooExpensiveConfirmation.description.firstLine"
          defaultMessage="Right now, your membership runs you {price}/{period}."
          values={{
            price: priceFormatted,
            period: periodFormatted,
          }}
        />
      </p>
      <p className="mb-6">
        <FormattedMessage
          id="cancelSubscription.tooExpensiveConfirmation.description.secondLine"
          defaultMessage="Let’s cut that down, stat."
        />
      </p>
    </>
  );

  return (
    <CancelSubscriptionStepLayout
      illustration={
        <img
          src={coinGuyIllustration}
          srcSet={`${coinGuyIllustration2x} 2x, ${coinGuyIllustration3x} 3x`}
          alt="Coin guy illustration"
          width="220"
          height="158"
        />
      }
      title={title}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      onPrimaryButtonClick={onCancelSubscriptionButtonClick}
      onSecondaryButtonClick={secondaryButtonClickHandler}
    >
      {content}
    </CancelSubscriptionStepLayout>
  );
};

export default TooExpensiveConfirmationStep;
