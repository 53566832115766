/* istanbul ignore file */

import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button } from '@setapp/ui-kit';

import analytics, { events } from 'utils/analytics';
import desktopAppHelper from 'utils/desktop-app-helper';
import logger from 'utils/logger';

import { showModal } from 'state/modal/modal-reducer';

import useDesktopAppDetection from 'components/hooks/use-desktop-app-detection/use-desktop-app-detection';

import AutoDownloadSection from 'components/shared/auto-download-section/auto-download-section';
import ImmediatelyDownloadLink from 'components/shared/immediately-download-link/immediately-download-link';

import './successful-registration-desktop-ccr.scss';

import stepOne from './images/step-1.png';
import stepOne2x from './images/step-1@2x.png';
import stepOne3x from './images/step-1@3x.png';
import stepTwo from './images/step-2.png';
import stepTwo2x from './images/step-2@2x.png';
import stepTwo3x from './images/step-2@3x.png';
import stepThree from './images/step-3.png';
import stepThree2x from './images/step-3@2x.png';
import stepThree3x from './images/step-3@3x.png';

const SuccessfulRegistrationDesktopCcr = () => {
  const { isDesktopAppAvailable, isDesktopAppDetectionInProgress } = useDesktopAppDetection();

  const dispatch = useDispatch();
  const hasReminderShown = useRef(false);

  useEffect(() => {
    const handleLeavePage = () => {
      if (!hasReminderShown.current) {
        hasReminderShown.current = true;
        dispatch(showModal('INSTALL_DESKTOP_REMAINDER'));
      }
    };

    document.documentElement.addEventListener('mouseleave', handleLeavePage);

    return () => {
      document.documentElement.removeEventListener('mouseleave', handleLeavePage);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLaunchSetappClick = () => {
    desktopAppHelper.openDesktopApp()
      .catch((error) => {
        logger.logWarn('Couldn\'t launch Setapp desktop app', { error });
      });
  };

  const handleDirectDownloadClick = () => {
    analytics.trackEvent(events.WELCOME_PAGE_DIRECT_DOWNLOAD_CLICK);
  };

  return (
    <div className="successful-registration-desktop-ccr" data-testid="from-desktop-ccr-view">
      <div>
        <h2 className="successful-registration-desktop-ccr__title">
          <FormattedMessage
            id="successfulRegistration.desktopCcr.title"
            defaultMessage="Done! Now, install and use Setapp"
          />
        </h2>

        <section className="successful-registration-desktop-ccr__steps">
          <div className="successful-registration-desktop-ccr__step">
            <div className="successful-registration-desktop-ccr__step-image-container">
              <div className="successful-registration-desktop-ccr__step-number">
                1
              </div>
              <img
                className="successful-registration-desktop-ccr__step-image"
                src={stepOne}
                srcSet={`${stepOne2x} 2x, ${stepOne3x} 3x`}
                alt=""
                width="268"
                height="116"
              />
            </div>
            <strong className="successful-registration-desktop-ccr__step-title">
              <FormattedMessage
                id="successfulRegistration.desktopCcr.instruction.step1.title"
                defaultMessage="Install Setapp"
              />
            </strong>
            <p>
              <FormattedMessage
                id="successfulRegistration.desktopCcr.instruction.step1.description"
                defaultMessage="Open InstallSetapp.zip to install Setapp desktop app on your Mac."
              />
            </p>
          </div>

          <div className="successful-registration-desktop-ccr__step">
            <div className="successful-registration-desktop-ccr__step-image-container">
              <div className="successful-registration-desktop-ccr__step-number">
                2
              </div>
              <img
                className="successful-registration-desktop-ccr__step-image"
                src={stepTwo}
                srcSet={`${stepTwo2x} 2x, ${stepTwo3x} 3x`}
                alt=""
                width="260"
                height="80"
              />
            </div>
            <strong className="successful-registration-desktop-ccr__step-title">
              <FormattedMessage
                id="successfulRegistration.desktopCcr.instruction.step2.title"
                defaultMessage="Find apps"
              />
            </strong>
            <p>
              <FormattedMessage
                id="successfulRegistration.desktopCcr.instruction.step2.description"
                defaultMessage="Use AI search, Setapp Assistant, and collections to discover handy apps on Setapp."
              />
            </p>
          </div>

          <div className="successful-registration-desktop-ccr__step">
            <div className="successful-registration-desktop-ccr__step-image-container">
              <div className="successful-registration-desktop-ccr__step-number">
                3
              </div>
              <img
                className="successful-registration-desktop-ccr__step-image"
                src={stepThree}
                srcSet={`${stepThree2x} 2x, ${stepThree3x} 3x`}
                alt=""
                width="288"
                height="90"
              />
            </div>
            <strong className="successful-registration-desktop-ccr__step-title">
              <FormattedMessage
                id="successfulRegistration.desktopCcr.instruction.step3.title"
                defaultMessage="Install and use apps"
              />
            </strong>
            <p>
              <FormattedMessage
                id="successfulRegistration.desktopCcr.instruction.step3.description"
                defaultMessage="Tackle your daily tasks with the apps you get on Setapp, whenever you need."
              />
            </p>
          </div>
        </section>

        <div className="successful-registration-desktop-ccr__cta-container">
          {isDesktopAppAvailable ? (
            <Button
              className="successful-registration-desktop-ccr__button"
              size="lg"
              disabled={isDesktopAppDetectionInProgress}
              onClick={handleLaunchSetappClick}
            >
              <FormattedMessage
                id="successfulRegistration.desktopCcr.cta"
                defaultMessage="Launch Setapp"
              />
            </Button>
          ) : (
            <>
              <p className="text_color-secondary">
                <ImmediatelyDownloadLink
                  customClass="secondary-text"
                  onDirectDownloadClick={handleDirectDownloadClick}
                />
              </p>
              <AutoDownloadSection />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessfulRegistrationDesktopCcr;
