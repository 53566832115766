import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Notification, Button } from '@setapp/ui-kit';

import urls from 'config/urls';

import { showSuccessNotification } from 'state/notifier/notifier-reducer';

import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import SupportLink from 'components/shared/support-link/support-link';

import PaymentFormModal from 'components/modals/payment-details/payment-details-modal';

import RetryChargeButton from '../retry-charge-button/retry-charge-button';

import './subscription-blocked-notification.scss';

const SubscriptionBlockedNotification = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [isModalShown, setIsModalShown] = useState(false);

  const handleRetryChargeClick = () => {
    history.push(urls.paymentRetry);
  };

  const handleModalHide = () => {
    setIsModalShown(false);
  };

  const handlePaymentDetailsUpdateSuccess = () => {
    handleModalHide();

    dispatch(showSuccessNotification((
      <FormattedMessage
        id="subscriptionInfo.blockedStatus.paymentInfoUpdated"
        defaultMessage="Payment details successfully updated."
      />
    )));
  };

  const handleAddPaymentDetailsClick = () => {
    setIsModalShown(true);
  };

  return (
    <Notification type="danger">
      <div className="mb-3">
        <FormattedMessage
          id="subscriptionInfo.blockedStatus.statusNotifications"
          defaultMessage="We couldn’t process your payment, so your subscription in now on hold. Please update payment details to use Setapp again."
        />
      </div>

      <div className="subscription-blocked-notification__actions">
        <RetryChargeButton onClick={handleRetryChargeClick} />
        <Button
          className="subscription-blocked-notification__update-details-link"
          variant="link"
          onClick={handleAddPaymentDetailsClick}
        >
          <PaymentDetailsActionText action="update" />
        </Button>
        <SupportLink />
      </div>

      <PaymentFormModal
        show={isModalShown}
        onHide={handleModalHide}
        onPaymentDetailsSaved={handlePaymentDetailsUpdateSuccess}
        title={<PaymentDetailsActionText action="update" />}
      />
    </Notification>
  );
};

export default SubscriptionBlockedNotification;
