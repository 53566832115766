import React from 'react';
import { useSelector } from 'react-redux';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import { showModal } from 'state/modal/modal-reducer';
import { isSuspendedSubscriptionWithOldPricePlan as getIsSuspendedSubscriptionWithOldPricePlan } from 'state/root-reducer';
import analytics, { events } from 'utils/analytics';
import useSuspendedActivationHandler from './use-suspended-activation-handler';
import useHandlePaymentDetailsAdded from './use-handle-payment-details-added';

const useManagePaymentDetails = () => {
  const handleSuspendedUserActivation = useSuspendedActivationHandler();
  const isSuspendedSubscriptionWithOldPricePlan = useSelector(getIsSuspendedSubscriptionWithOldPricePlan);

  const showModalAction = useDispatchAction(showModal);

  const onPaymentDetailsSaved = useHandlePaymentDetailsAdded();

  const addPaymentDetails = () => {
    if (isSuspendedSubscriptionWithOldPricePlan) {
      handleSuspendedUserActivation();

      return;
    }

    showModalAction('SET_PAYMENT_DETAILS', {
      title: <PaymentDetailsActionText action="add" />,
      onPaymentDetailsSaved,
    });
  };

  const removePaymentDetails = () => {
    showModalAction('REMOVE_PAYMENT_DETAILS');
    analytics.trackEvent(events.REMOVE_PAYMENT_DETAILS_MODAL_OPEN);
  };

  return {
    addPaymentDetails,
    removePaymentDetails,
  };
};

export default useManagePaymentDetails;
