import type { ResolvedFeatureFlags } from 'utils/feature-flags';

export type FeatureFlagsState = Readonly<{
  isFetched: boolean;
  isLoading: boolean;
  featureFlags: ResolvedFeatureFlags;
}>;

const initialState: FeatureFlagsState = {
  isFetched: false,
  isLoading: false,
  featureFlags: {} as ResolvedFeatureFlags,
};

export default initialState;
