import React, { FC } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';

import AccountInfoRow from 'components/shared/account-info-row/account-info-row';

type Props = {
  expirationDate: number;
  isSubscriptionBlocked?: boolean;
};

const ExpirationDateRow: FC<Props> = ({ expirationDate, isSubscriptionBlocked = false }) => {
  const title = isSubscriptionBlocked
    ? <FormattedMessage id="subscriptionInfo.expirationDateBlocked" defaultMessage="Blocked on" />
    : <FormattedMessage id="subscriptionInfo.expirationDate" defaultMessage="Expiration date" />;

  return (
    <AccountInfoRow
      title={title}
      value={(
        <FormattedDate
          value={expirationDate * 1000}
          year="numeric"
          month="short"
          day="numeric"
        />
      )}
      valueClass={isSubscriptionBlocked ? 'text-danger' : ''}
      qaLabel="subscriptionExpirationDateText"
    />
  );
};

export default ExpirationDateRow;
