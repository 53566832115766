import ResendEmailCaptchaModal from './resend-email-captcha/resend-email-captcha-modal';
import OldRemoveFamilyMemberModal from './old-remove-family-member/old-remove-family-member-modal';
import CancelAdditionalSeatModal from './cancel-additional-seat/cancel-additional-seat-modal';
import ConfirmLoseDiscountModal from './confirm-lose-discount-modal/confirm-lose-discount-modal';
import ReactivateAdditionalSeatModal from './reactivate-seat/reactivate-seat-modal';
import DisconnectDeviceModal from './disconnect-device/disconnect-device-modal';
import RemovePaymentDetailsModal from './remove-payment-details/remove-payment-details-modal';
import PaymentDetailsModal from './payment-details/payment-details-modal';
import LeaveFamilyModal from './leave-family/leave-family-modal';
import DropFamilyModal from './drop-family/drop-family-modal';
import BuyAdditionalSeatModal from './buy-additional-seat/buy-additional-seat-modal';
import ChangePlanModal from './change-plan/change-plan-modal';
import HowItWorksVideoModal from './how-it-works-video/how-it-works-video-modal';
import ManageSubscription from './manage-subscription/manage-subscription';
import ActivateIosAppModal from './activate-ios-app/activate-ios-app-modal';
import RemoveFamilyMemberModal from './remove-family-member/remove-family-member-modal';
import RevokeFamilyInviteModal from './revoke-family-invite/revoke-family-invite-modal';
import CreateFamilyForActiveUserModal from './create-family-for-active-user-modal/create-family-for-active-user-modal';
import FamilyMemberSwitchOwnPlanModal from './family-member-switch-own-plan-modal/family-member-switch-own-plan-modal';
import InstallDesktopRemainderModal from './install-desktop-remainder-modal/install-desktop-remainder-modal';

export type ModalType =
  | 'RESEND_EMAIL_CAPTCHA'
  | 'CANCEL_ADDITIONAL_SEAT'
  | 'CONFIRM_LOSE_DISCOUNT'
  | 'REACTIVATE_ADDITIONAL_SEAT'
  | 'DISCONNECT_DEVICE'
  | 'REMOVE_PAYMENT_DETAILS'
  | 'SET_PAYMENT_DETAILS'
  | 'BUY_ADDITIONAL_SEAT'
  | 'CHANGE_PLAN'
  | 'HOW_IT_WORKS_VIDEO'
  | 'MANAGE_SUBSCRIPTION'
  | 'ACTIVATE_IOS_APP'
  | 'INSTALL_DESKTOP_REMAINDER'
  // Family
  | 'OLD_REMOVE_FAMILY_MEMBER'
  | 'LEAVE_FAMILY'
  | 'DROP_FAMILY'
  | 'REMOVE_FAMILY_MEMBER'
  | 'REVOKE_FAMILY_INVITE'
  | 'CREATE_FAMILY_FOR_ACTIVE_USER'
  | 'FAMILY_MEMBER_SWITCH_OWN_PLAN'
  ;

const modalComponentsByModalType = {
  RESEND_EMAIL_CAPTCHA: ResendEmailCaptchaModal,
  CANCEL_ADDITIONAL_SEAT: CancelAdditionalSeatModal,
  CONFIRM_LOSE_DISCOUNT: ConfirmLoseDiscountModal,
  REACTIVATE_ADDITIONAL_SEAT: ReactivateAdditionalSeatModal,
  DISCONNECT_DEVICE: DisconnectDeviceModal,
  REMOVE_PAYMENT_DETAILS: RemovePaymentDetailsModal,
  SET_PAYMENT_DETAILS: PaymentDetailsModal,
  BUY_ADDITIONAL_SEAT: BuyAdditionalSeatModal,
  CHANGE_PLAN: ChangePlanModal,
  HOW_IT_WORKS_VIDEO: HowItWorksVideoModal,
  MANAGE_SUBSCRIPTION: ManageSubscription,
  ACTIVATE_IOS_APP: ActivateIosAppModal,
  INSTALL_DESKTOP_REMAINDER: InstallDesktopRemainderModal,
  // Family
  OLD_REMOVE_FAMILY_MEMBER: OldRemoveFamilyMemberModal,
  LEAVE_FAMILY: LeaveFamilyModal,
  DROP_FAMILY: DropFamilyModal,
  REMOVE_FAMILY_MEMBER: RemoveFamilyMemberModal,
  REVOKE_FAMILY_INVITE: RevokeFamilyInviteModal,
  CREATE_FAMILY_FOR_ACTIVE_USER: CreateFamilyForActiveUserModal,
  FAMILY_MEMBER_SWITCH_OWN_PLAN: FamilyMemberSwitchOwnPlanModal,
};

export default modalComponentsByModalType;
