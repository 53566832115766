import React from 'react';
import { FormattedMessage } from 'react-intl';

import BasePermissionItem from './base-permission-item/base-permission-item';

type Props = {
  icon: string;
  appName: string;
}

const PermissionAccessItem = ({ icon, appName }: Props) => (
  <BasePermissionItem
    icon={icon}
    title={appName}
    subTitle={<FormattedMessage
      id="oauth.permission.access.subTitle"
      defaultMessage="wants to access your Setapp account and get the info below"
              />}
    qaLabel="permissionItemAccess"
  />
);

export default PermissionAccessItem;
