import React, { useEffect, useRef, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatedLogo } from '@setapp/ui-kit';
import { useHistory } from 'react-router-dom';
import NextPaymentDateEstimation from 'components/shared/next-payment-date-estimation/next-payment-date-estimation';
import PaymentButton from 'components/shared/payment-button/payment-button';
import PaymentDetailsActionText from 'components/shared/payment-details-action-text/payment-details-action-text';
import PaymentForm from 'components/shared/payment-form/payment-form';
import TaxInfo from 'components/shared/tax-info/tax-info';
import { NextPricePlanCalculationData, calculateNextPricePlan } from 'services/price-plans/price-plans-api';
import { PricePlan } from 'state/price-plans/price-plans-initial-state';
import { Subscription } from 'state/subscription/subscription-initial-state';
import { changePricePlan } from 'state/subscription/subscription-actions';
import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';
import { FormattedPrice } from 'components/shared/formatter/formatter';
import { getOppositeBillingPeriodPlan } from 'services/price-plans/utils';
import {
  getAvailablePricePlans,
  getDisplayedPricePlan,
  isPaymentMethodCreated as getIsPaymentMethodCreated
} from 'state/root-reducer';
import withInvoicePolling, { InjectedInvoicePollingProps } from 'components/shared/with-invoice-polling/with-invoice-polling';
import logger from 'utils/logger';
import analytics, { events } from 'utils/analytics';
import { ChangeSubscriptionPlan } from 'state/price-plans/price-plans-types';
import DefaultError from 'components/shared/default-error/default-error';
import { showDangerNotification } from 'state/notifier/notifier-reducer';
import useSubscriptionPolling from 'components/hooks/use-subscription-polling/use-subscription-polling';
import urls from 'config/urls';

import AiAnnualJumpError from './ai-annual-jump-error';

import './confirm-ai-plan-jump-step.scss';

type Props = {
  primarySubscription: Subscription;
  nextPricePlan: PricePlan;
  nextPricePlanCalculationData: NextPricePlanCalculationData;
} & InjectedInvoicePollingProps;

const ConfirmAiPlanJumpStep = ({
  primarySubscription,
  nextPricePlan,
  nextPricePlanCalculationData,
  waitForPaymentSuccess,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [shouldShowPaymentForm, setShouldShowPaymentForm] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [shouldShowAnnualError, setShouldShowAnnualError] = useState(false);
  const [oppositeCalculationData, setOppositeCalculationData] = useState<NextPricePlanCalculationData | null>(null);
  // hack to set updated subscription after first change plan, usual selector is not updating in time
  const updatedSubscription = useRef<Subscription | null>(null);
  const pricePlans = useSelector(getAvailablePricePlans);
  const isPaymentMethodCreated = useSelector(getIsPaymentMethodCreated);
  const displayedPricePlan = useSelector(getDisplayedPricePlan);

  const nextPricePlanTitle = getPricePlanMetadata(nextPricePlan).title;

  const oppositeMonthlyPlan = getOppositeBillingPeriodPlan(nextPricePlan, pricePlans);

  const shouldContinuePolling = (subscription) => {
    if (subscription.id !== primarySubscription.id) {
      updatedSubscription.current = subscription;

      return false;
    }

    return true;
  };

  const { startSubscriptionPolling } = useSubscriptionPolling({
    shouldContinuePolling
  });

  useEffect(() => {
    if (!isPaymentMethodCreated) {
      setShouldShowPaymentForm(true);
    }

    (async () => {
      if (oppositeMonthlyPlan && !oppositeCalculationData) {
        const data = await calculateNextPricePlan(
          primarySubscription.id,
          oppositeMonthlyPlan.id,
        );

        setOppositeCalculationData(data);
        setIsPageLoading(false);
      }
    })();
  }, []);

  const renderProrationAmount = () => {
    if (!oppositeCalculationData) {
      return null;
    }

    return (
      <div className="confirm-ai-jump-step__proration-amount">
        <FormattedMessage
          id="aiPlanJump.prorationAmount.title"
          defaultMessage="Total:"
        />
        {' '}
        <FormattedPrice
          price={oppositeCalculationData.prorationAmount}
          currency={oppositeCalculationData.prorationCurrency}
          minimumFractionDigits={2}
        />
        <div className="confirm-ai-jump-step__proration-amount-tax-info">
          <FormattedMessage
            id="aiPlanJump.prorationAmount.taxInfo"
            defaultMessage="Excluding tax"
          />
        </div>
      </div>
    );
  };

  const showDefaultError = () => {
    dispatch(showDangerNotification(<DefaultError />));
  };

  const successfulPlanChange = async () => {
    analytics.trackEvent(events.AI_PLUS_CONFIRM_CHANGE_PLAN, {
      eventLabel: nextPricePlan.tierType,
      eventLabel2: displayedPricePlan?.tierType,
    });
    history.push(urls.successfulAiOfferActivation);
  };

  const changePlan = async () => {
    let isFirstRequestFailed = false;

    try {
      setIsProcessing(true);

      const isPlansHaveTheSameKey = primarySubscription.pricePlan.priceKey === oppositeMonthlyPlan?.priceKey;

      const planId = isPlansHaveTheSameKey ? nextPricePlan.id : oppositeMonthlyPlan!.id;

      const changePlanData = await dispatch(changePricePlan(
        primarySubscription.id,
        planId,
        { fetchSubscription: true }
      ));

      const { invoiceId, strategy } = changePlanData as unknown as ChangeSubscriptionPlan;

      if (strategy === 'scheduled') {
        await dispatch(changePricePlan(
          primarySubscription.id,
          nextPricePlan.id,
          { fetchSubscription: false }
        ));

        await successfulPlanChange();

        return;
      }

      const onFirstTryError = () => {
        showDefaultError();
        isFirstRequestFailed = true;
      };

      if (invoiceId) {
        await waitForPaymentSuccess(
          invoiceId,
          () => Promise.resolve(), // do nothing, continue the flow further
          onFirstTryError,
          onFirstTryError,
          { userFlow: 'ai-plan-first-try' }
        );
      }
    } catch (err) {
      setIsProcessing(false);
      dispatch(showDangerNotification(<DefaultError />));
      logger.logError('AI Offer flow: Error during first change plan', err);
      isFirstRequestFailed = true;

      return;
    }

    if (isFirstRequestFailed) {
      setIsProcessing(false);

      return;
    }

    try {
      await startSubscriptionPolling();

      const secondChangePlanData = await dispatch(changePricePlan(
        updatedSubscription.current!.id,
        nextPricePlan.id,
        { fetchSubscription: false }
      ));

      const { invoiceId: secondInvoiceId } = secondChangePlanData as unknown as ChangeSubscriptionPlan;

      // second invoice is unlikelly to appear because second time we swith to annual plan,
      // which will be activated only after a month, so strategy is `scheduled`
      if (secondInvoiceId) {
        await waitForPaymentSuccess(
          secondInvoiceId,
          successfulPlanChange,
          showDefaultError,
          showDefaultError,
          { userFlow: 'ai-plan-second-try' }
        );
      }

      await successfulPlanChange();
    } catch (error) {
      setIsProcessing(false);
      setShouldShowAnnualError(true);
      logger.logError('AI Offer flow: Error during second change plan', error);
    }
  };

  if (isPageLoading) {
    return (
      <div style={{ margin: 'auto' }}>
        <AnimatedLogo animate />
      </div>
    );
  }

  if (!oppositeMonthlyPlan) {
    return null;
  }

  if (shouldShowAnnualError) {
    return <AiAnnualJumpError />;
  }

  if (shouldShowPaymentForm) {
    return (
      <>
        <h3 className="mt-0 mb-3">
          <PaymentDetailsActionText action="add" />
        </h3>
        <PaymentForm onSuccessSubmit={() => setShouldShowPaymentForm(false)} />
      </>
    );
  }

  return (
    <>
      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="aiPlanJump.title"
          defaultMessage="Switch to {pricePlanTitle} plan"
          values={{ pricePlanTitle: nextPricePlanTitle }}
        />
      </h3>

      <div className="text_lg">
        <FormattedMessage
          id="aiPlanJump.description.changePeriodAndPlan"
          defaultMessage="Right now, you will only be charged the difference for the remaining days of your current billing cycle. Starting {nextPaymentDate}, you will be switched to annual billing."
          values={{ nextPaymentDate: (
            <strong>
              <FormattedDate
                value={primarySubscription.nextPaymentDate! * 1000}
                year="numeric"
                month="short"
                day="numeric"
              />
            </strong>
          ) }}
        />
      </div>

      {renderProrationAmount()}

      <div className="mt-8 mb-5">
        <PaymentButton onClick={changePlan} forceProcessing={isProcessing} />
      </div>

      <NextPaymentDateEstimation
        price={nextPricePlan.price}
        currency={nextPricePlan.currency}
        nextPaymentDate={nextPricePlanCalculationData?.nextPaymentDate}
      />

      <div className="mt-2">
        <TaxInfo />
      </div>
    </>
  );
};

export default withInvoicePolling(ConfirmAiPlanJumpStep);
