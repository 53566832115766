import React, { ReactNode } from 'react';
import { AnimatedLogo } from '@setapp/ui-kit';

import Footer from 'components/layout/footer/footer';
import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import './fullscreen-layout-wide.scss';

type Props = {
  withLogo?: boolean;
  withFooter?: boolean;
  children: ReactNode;
};

const FullscreenLayoutWide = ({ withLogo, withFooter, children }: Props) => (
  <FullscreenLayout>
    <div className="fullscreen-layout-wide">
      <div className="fullscreen-layout-wide__container">
        <div className="fullscreen-layout-wide__content">
          {withLogo && (
            <div className="fullscreen-layout-wide__setapp-logo">
              <AnimatedLogo size="sm" hasCaption />
            </div>
          )}
          {children}
        </div>
      </div>
      {withFooter && <Footer />}
    </div>
  </FullscreenLayout>
);

export default FullscreenLayoutWide;
