import React, { type FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';

import analytics, { events } from 'utils/analytics';

import { getPrimaryPricePlan } from 'state/root-reducer';

import FullscreenLayout from 'components/layout/fullscreen-layout/fullscreen-layout';

import urls from 'config/urls';

import './restrict-setapp-mobile-page.scss';

const RestrictSetappMobilePage: FC = () => {
  const history = useHistory();
  const currentPricePlan = useSelector(getPrimaryPricePlan);

  useEffect(() => {
    analytics.trackEvent(events.SETAPP_MOBILE_EU_ERROR_OCCURED, {
      eventLabel: 'Unsupported Plan',
      eventLabel2: currentPricePlan?.tierType,
    });
  }, []);

  const handleGoToAccount = () => {
    history.push(urls.subscription);
  };

  return (
    <FullscreenLayout.PrimaryContent withLogo>
      <div className="restrict-setapp-mobile__container">
        <h2 className="restrict-setapp-mobile__title">
          <FormattedMessage
            id="restrict-setapp-mobile.title"
            defaultMessage="Sorry, it won’t work with your plan"
          />
        </h2>
        <p className="restrict-setapp-mobile__description">
          <FormattedMessage
            id="restrict-setapp-mobile.description"
            defaultMessage="If you have a Team or Family plan, please create a different account to try Setapp Mobile."
          />
        </p>
        <Button block className="mt-7" size="lg" onClick={handleGoToAccount}>
          <FormattedMessage
            id="restrict-setapp-mobile.button"
            defaultMessage="Go to my account"
          />
        </Button>
      </div>
    </FullscreenLayout.PrimaryContent>
  );
};

export default RestrictSetappMobilePage;
