import React, { FC } from 'react';
import { Button } from '@setapp/ui-kit';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import analytics, { events } from 'utils/analytics';

import { FormattedPrice } from 'components/shared/formatter/formatter';

import { IOS_ADVANCED_MONTHLY } from 'state/price-plans/price-plans-types';
import { getFeaturedPricePlans } from 'state/root-reducer';

import urls from 'config/urls';

import { setIsInsideSetappMobileFlow } from '../../utils/setapp-mobile-flow-storage';

import './ios-advanced-plan-card.scss';

type Props = {
  shouldShowTrial: boolean;
}

const IosAdvancedPlanCard: FC<Props> = ({ shouldShowTrial }) => {
  const history = useHistory();
  const featurePricePlans = useSelector(getFeaturedPricePlans);
  const iosAdvancedPlan = featurePricePlans.find((plan) => plan.tierType === IOS_ADVANCED_MONTHLY);

  if (!iosAdvancedPlan) {
    return null;
  }

  const handleSetUpClick = () => {
    setIsInsideSetappMobileFlow();
    analytics.trackEvent(events.SETAPP_MOBILE_EU_SET_UP_FREE_TRIAL);
    if (shouldShowTrial) {
      history.push(urls.signupAddPaymentMethod);
    } else {
      history.push(urls.activateSubscription);
    }
  };

  return (
    <div className="ios-advanced-card__price-plan">
        <div className="ios-advanced-card__plan-header">
          <div className="ios-advanced-card__plan-left">
            {shouldShowTrial && (
              <span className="ios-advanced-card__plan-label">
                <FormattedMessage
                  id="setappMobilePage.pricePlan.label"
                  defaultMessage="Free for {trialPlanLength} days"
                  values={{ trialPlanLength: iosAdvancedPlan.features?.trialPeriodDays }}
                />
              </span>
            )}
            <p className="ios-advanced-card__plan-title">
              <FormattedMessage
                id="setappMobilePage.pricePlan.title"
                defaultMessage="iOS Advanced Plan"
              />
            </p>
          </div>
          <span className="ios-advanced-card__plan-right">
            {shouldShowTrial ? (
              <FormattedMessage
                id="setappMobilePage.pricePlan.tiralPrice"
                defaultMessage="Then {price}/mo"
                values={{ price: <FormattedPrice price={iosAdvancedPlan.price} currency={iosAdvancedPlan.currency} /> }}
              />
            ) : (
              <FormattedMessage
                id="setappMobilePage.pricePlan.price"
                defaultMessage="{price}/mo"
                values={{ price: <FormattedPrice price={iosAdvancedPlan.price} currency={iosAdvancedPlan.currency} /> }}
              />
            )}
          </span>
        </div>
        <p>
          <FormattedMessage
            id="setappMobilePage.pricePlan.description"
            defaultMessage="For 2 iPhones"
          />
        </p>
        <Button block size="lg" className="mt-5" onClick={handleSetUpClick}>
          {shouldShowTrial ? (
            <FormattedMessage id="setappMobilePage.btn.setTrial" defaultMessage="Set up free trial" />
          ) : (
            <FormattedMessage id="setappMobilePage.btn.subscription" defaultMessage="Set up your subscription" />
          )}
        </Button>
    </div>
  );
};

export default IosAdvancedPlanCard;
