import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  className: string;
  children: ReactNode;
}

const ErrorMessage = ({ className, children }: Props) => {
  const rootClasses = classNames(
    'text_sm',
    'text_color-danger',
    className,
  );

  return <div className={rootClasses}>{children}</div>;
};

ErrorMessage.defaultProps = {
  className: '',
};

export default ErrorMessage;
