import { Button } from '@setapp/ui-kit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { IosApp } from 'state/ios-apps/ios-apps-initial-state';

type Props = {
  iosApp: IosApp;
  onAppStoreButtonClick: () => void;
  goToActivationStep: () => void;
}

const FirstStep = ({ iosApp, onAppStoreButtonClick, goToActivationStep }: Props) => (
  <>
    <h3 className="mb-8">
      <FormattedMessage
        id="activateIosPage.firstStep.title"
        defaultMessage="Get {appName} in 2 steps"
        values={{ appName: iosApp.name }}
      />
    </h3>
    <div className="activate-ios-page__step">
      <p className="text_weight-bold mb-3 activate-ios-page__step-number">
        <FormattedMessage
          id="activateIosPage.firstStep.stepOne"
          defaultMessage="Step 1"
        />
      </p>
      <h5 className="mb-5 activate-ios-page__step-header">
        <FormattedMessage
          id="activateIosPage.firstStep.firstStep"
          defaultMessage="Go to the App store to install {appName}"
          values={{ appName: iosApp.name }}
        />
      </h5>
      <Button
        block
        size="lg"
        onClick={onAppStoreButtonClick}
        className="activate-ios-page__btn"
      >
        <FormattedMessage
          id="activateIosPage.firstStep.appStoreBtn"
          defaultMessage="Go to the App Store"
        />
      </Button>
    </div>
    <div className="mt-3 activate-ios-page__step activate-ios-page__step_inactive">
      <p className="text_weight-bold mb-3 activate-ios-page__step-number">
        <FormattedMessage
          id="activateIosPage.firstStep.stepTwo"
          defaultMessage="Step 2"
        />
      </p>
      <h5 className="mb-5 activate-ios-page__step-header">
        <FormattedMessage
          id="activateIosPage.firstStep.getBack"
          defaultMessage="Go back here to activate the app"
        />
      </h5>
    </div>
    <p className="text_sm text_weight-bold mt-8 mb-0">
      <FormattedMessage
        id="activateIosPage.firstStep.alreadyHave"
        defaultMessage="Already have this app on your device?"
      />
    </p>
    <Button
      variant="link"
      className="text_sm text_weight-medium mt-1 mb-1"
      onClick={goToActivationStep}
    >
      <FormattedMessage
        id="activateIosPage.firstStep.activateLink"
        defaultMessage="Activate the app via Setapp"
      />
    </Button>
  </>
);

export default FirstStep;
