/* istanbul ignore file */
import React from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import './referral-counter.scss';

import activatedIcon from '../images/activated.svg';

type Props = {
  referralCount: number;
}

const ReferralCounter: FC<Props> = ({ referralCount }) => {
  return (
    <div className="referral-counter text_weight-bold">
      <img
        src={activatedIcon}
        width="20"
        height="20"
        alt=""
      />
      <FormattedMessage
        id="referral.successCount"
        defaultMessage="Yay! You have {count, plural, one {1 successful referral} other {{count} successful referrals}}."
        values={{ count: referralCount }}
      />
    </div>
  );
};

export default ReferralCounter;
