import React, { ReactNode } from 'react';

import classNames from 'classnames';

import PanelBoxContent from './panel-box-content/panel-box-content';

import './panel-box.scss';

export type Props = {
  children: ReactNode;
  fullHeight?: boolean;
  type?: 'normal' | 'warning';
};

const PanelBox = ({ children, fullHeight = false, type = 'normal' }: Props) => {
  const boxClasses = classNames('panel-box', {
    'panel-box_full-height': fullHeight,
    'panel-box_type-warning': type === 'warning',
  });

  return (
    <div className={boxClasses}>
      {children}
    </div>
  );
};

PanelBox.Content = PanelBoxContent;

export default PanelBox;
