// @flow

import { useState, useEffect } from 'react';

import useOAuthQueryParams from 'components/hooks/use-oauth-query-params/use-oauth-query-params';
import { getOAuthClientInfo, getOAuthClientOptions, redirectToOAuthServer } from 'utils/oauth';

type UseOAuthClientInfoParams = {
  allowRedirect?: boolean,
};

export default function useOAuthClientInfo({ allowRedirect = false }: UseOAuthClientInfoParams = {}) {
  const { isError: isParamsError, params } = useOAuthQueryParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(isParamsError);

  useEffect(() => {
    async function fetchClientInfo() {
      setIsError(isParamsError);
      setIsLoading(true);
      try {
        if (!isParamsError && params.clientId) {
          const { mayBeSkipped } = await getOAuthClientOptions(params.clientId, params.scope);

          if (allowRedirect && mayBeSkipped) {
            redirectToOAuthServer(params.redirectUrl, true);
          } else {
            const data = await getOAuthClientInfo(params.clientId);
            setData(data);
          }
        }
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    }

    fetchClientInfo();
  }, [params, isParamsError]);

  return {
    data,
    isError,
    isLoading,
    redirectUrl: params.redirectUrl,
    authScope: params.scope,
  };
}
