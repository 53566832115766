import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { FC, ReactNode } from 'react';
import AccountInfoRow from 'components/shared/account-info-row/account-info-row';

type Props = {
  value: ReactNode;
};

const PlanRow: FC<Props> = ({ value }) => (
  <AccountInfoRow
    title={<FormattedMessage id="subscriptionInfo.planTitle" defaultMessage="Your plan" />}
    value={value}
    qaLabel="planTitle"
  />
);

export default PlanRow;
