import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import ReferralBanner from 'components/shared/banners/referral-banner/referral-banner';
import FreeMonthActivationBanner from 'components/shared/banners/free-month-activation-banner/free-month-activation-banner';
import ReferralPaymentDetailsBanner from 'components/shared/banners/referral-payment-details-banner/referral-payment-details-banner';
import { getPrimarySubscription } from 'state/root-reducer';
import { SubscriptionStatus } from 'state/subscription/statuses';
import useBannersViewState from '../../hooks/use-banners-view-state';
import useAddPaymentDetails from '../../hooks/use-add-payment-details';

const SubscriptionBanners: FC = () => {
  const primarySubscription = useSelector(getPrimarySubscription);

  const {
    isReferralBannerVisible,
    isFreeMonthActivationBannerVisible,
    isReferralPaymentDetailsBannerVisible
  } = useBannersViewState();

  const handleAddPaymentDetails = useAddPaymentDetails();

  return (
    <>
      {isReferralBannerVisible && (
        <ReferralBanner status={primarySubscription?.status as SubscriptionStatus} />
      )}
      {isFreeMonthActivationBannerVisible && (
        <FreeMonthActivationBanner />
      )}
      {isReferralPaymentDetailsBannerVisible && (
        <ReferralPaymentDetailsBanner onAddClick={handleAddPaymentDetails} />
      )}
    </>
  );
};

export default SubscriptionBanners;
