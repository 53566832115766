import React from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';

import './info-field.scss';

type Props = {
  title: ReactNode;
  value: ReactNode;
  vertical?: boolean;
  qaLabel?: string;
};

const InfoField = ({
  title, value, vertical = false, qaLabel = '',
}: Props) => {
  const fieldClasses = classNames('info-field', {
    'info-field_horizontal': !vertical,
  });

  return (
    <div className={fieldClasses} data-qa={qaLabel}>
      <div className="info-field__title">
        {title}
        :
      </div>
      <div className="info-field__value">{value}</div>
    </div>
  );
};

export default InfoField;
