import React, { FC } from 'react';
import { Button } from '@setapp/ui-kit';
import FullscreenLayoutLoading from 'components/layout/fullscreen-layout/fullscreen-layout-loading';

import useMobileInstallationUrl from './utils/get-mobile-installation-url';

const HiddenInstallationPage: FC = () => {
  const { mobileInstallationUrl, urlError } = useMobileInstallationUrl();

  if (urlError) {
    return <p>Error happened: {urlError}</p>;
  }

  if (!mobileInstallationUrl) {
    return <FullscreenLayoutLoading />;
  }

  return (
    <div>
      <Button block size="lg" className="mt-5" href={mobileInstallationUrl}>
        Install Setapp Mobile
      </Button>
    </div>
  );
};

export default HiddenInstallationPage;
