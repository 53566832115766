import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useIosCollectionsConfig from 'components/hooks/use-ios-collections/use-ios-collections';
import DefaultError from 'components/shared/default-error/default-error';
import AppLayoutLoading from 'components/layout/app-layout/app-layout-loading';
import ButtonBack from 'components/shared/button-back/button-back';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchIosApps } from 'state/ios-apps/ios-apps-actions';
import { getIosApps } from 'state/root-reducer';

import urls from 'config/urls';

import IosAppsList from '../ios-apps/ios-apps-list/ios-apps-list';

import './ios-collection-page.scss';

const IosCollectionPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { list: appsList } = useSelector(getIosApps);
  const { id } = useParams<{id: string}>();
  const { appPacks, collectionBanners } = useIosCollectionsConfig();
  const allCollections = [...appPacks, ...collectionBanners];

  useEffect(() => {
    try {
      if (!appsList.length) {
        dispatch(fetchIosApps());
      }
    } catch {
      dispatch(showDangerNotification(<DefaultError />));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!appsList.length) {
    return <AppLayoutLoading />;
  }

  const choosenAppCollection = allCollections.find((collection) => collection.id === Number(id));

  if (!choosenAppCollection) {
    history.push(urls.iosApps);

    return null;
  }

  const { title, appIds, description } = choosenAppCollection;
  // property is not present in collection banner
  const pictureType = choosenAppCollection['pictureType'];
  const appsToUse = appsList.filter((app) => appIds.includes(app.id)).sort((a, b) => b.id - a.id);

  return (
    <div className="ios-collection-page">
      <ButtonBack onClick={() => history.push(urls.iosApps)} />
      {pictureType && (
        <div className="ios-collection-page__illustration-container">
          <div className={`ios-collection-page__illustration ios-collection-page__illustration_${pictureType}`} />
        </div>
      )}
      <h3 className="mt-8">{title}</h3>
      {description && (
        <p className="mb-3">{description}</p>
      )}
      <IosAppsList appsList={appsToUse} />
    </div>
  );
};

export default IosCollectionPage;
