import initialState, { IosAppsState } from './ios-apps-initial-state';
import * as actionTypes from './ios-apps-action-types';

const iosApps = (state: IosAppsState = initialState, action: actionTypes.IosAppsAction): IosAppsState => {
  switch (action.type) {
    case actionTypes.REQUEST: {
      return { list: state.list, isLoading: true };
    }

    case actionTypes.REQUEST_SUCCESS: {
      return { list: action.payload, isLoading: false };
    }

    case actionTypes.REQUEST_ERROR: {
      return { list: state.list, isLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default iosApps;

export const getList = (state: IosAppsState) => state.list;
