import { Button, Notification } from '@setapp/ui-kit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';

import SetappDownloadLinkWrapper from 'components/shared/setapp-download-link-wrapper/setapp-download-link-wrapper';
import externalUrls from 'config/external-urls';

import analytics, { events } from 'utils/analytics';

import downloadIcon from './images/download-icon.svg';

import './desktop-download-reminder.scss';

const DesktopDownloadReminder = () => {
  return (
    <Notification type="success">
      <div className="desktop-download-reminder__container">
        <img
          className="desktop-download-reminder__icon"
          src={downloadIcon}
          height="60"
          width="60"
          alt=""
        />
        <span>
          <p className="mb-1 text_weight-bold">
            <FormattedMessage
              id="subscriptionInfo.statusNotifications.downloadReminder.title"
              defaultMessage="Make the most of your Setapp plan"
            />
          </p>
          <p className="mb-0">
            <FormattedMessage
              id="subscriptionInfo.statusNotifications.downloadReminder.description"
              defaultMessage="To use Mac and Web apps already included in your plan, install the Setapp desktop app on your Mac. It gives you access to it all. Check your inbox, we've sent you an installation link."
            />
          </p>
        </span>
        {!isMobile && (
          <div className="desktop-download-reminder__action">
            <SetappDownloadLinkWrapper>
              <Button
                size="sm"
                variant="primary-outline"
                href={externalUrls.downloadDesktopClient}
                onClick={() => analytics.trackEvent(events.SUBSCRIPTION_BLOCK_DOWNLOAD_CLICK)}
              >
                <FormattedMessage
                  id="subscriptionInfo.statusNotifications.downloadReminder.button"
                  defaultMessage="Download Setapp"
                />
              </Button>
            </SetappDownloadLinkWrapper>
          </div>
        )}
      </div>
    </Notification>
  );
};

export default DesktopDownloadReminder;
