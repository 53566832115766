// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import logger from 'utils/logger';

import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import BillingPeriodRadio from '../billing-period-radio/billing-period-radio';


type Props = {|
  currentPricePlan: PricePlan,
  oppositeBillingPeriodPricePlan: PricePlan,
  nextPricePlan: ?PricePlan,
  pricePlans: Array<PricePlan>,
  onSelectNextPlan: (nextPricePlan: PricePlan) => void,
  onConfirmNextPlan: () => void,
  annualDiscountPercentage: string,
|}

const ChangeBillingPeriodStep = (props: Props) => {
  const {
    currentPricePlan,
    oppositeBillingPeriodPricePlan,
    nextPricePlan,
    pricePlans,
    onSelectNextPlan,
    onConfirmNextPlan,
    annualDiscountPercentage
  } = props;

  let monthlyPlan,
    annualPlan;
  if (currentPricePlan.paidMonth === 1) {
    monthlyPlan = currentPricePlan;
    annualPlan = oppositeBillingPeriodPricePlan;
  } else {
    monthlyPlan = oppositeBillingPeriodPricePlan;
    annualPlan = currentPricePlan;
  }

  const nextPricePlanTierType = nextPricePlan?.tierType || currentPricePlan.tierType;
  const isCurrentPricePlanSelected = nextPricePlanTierType === currentPricePlan.tierType;

  const handlePricePlanSelect = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const nextPricePlan = pricePlans.find((pricePlan: PricePlan) => pricePlan.tierType === value);

    if (nextPricePlan) {
      onSelectNextPlan(nextPricePlan);
    } else {
      logger.logError(`Couldn't find price plan with ${value} tier type`);
    }
  };

  return (
    <>
      <h3 className="mt-0 mb-3">
        <FormattedMessage
          id="changePricePlanFlow.changeBillingPeriodStep.title"
          defaultMessage="Change billing period"
        />
      </h3>

      <FormattedMessage
        id="changePricePlanFlow.changeBillingPeriodStep.description"
        defaultMessage="Change the way your subscription renews."
      />

      <div className="mt-8 mb-8">
        <BillingPeriodRadio
          isSelected={monthlyPlan.tierType === nextPricePlanTierType}
          pricePlan={monthlyPlan}
          onChange={handlePricePlanSelect}
          annualDiscountPercentage={annualDiscountPercentage}
        />
        <BillingPeriodRadio
          isSelected={annualPlan.tierType === nextPricePlanTierType}
          pricePlan={annualPlan}
          onChange={handlePricePlanSelect}
          annualDiscountPercentage={annualDiscountPercentage}
        />
      </div>

      <Button
        variant="primary"
        size="lg"
        block
        disabled={isCurrentPricePlanSelected}
        onClick={onConfirmNextPlan}
      >
        <FormattedMessage
          id="changePricePlanFlow.changeBillingPeriodStep.CTA"
          defaultMessage="Continue"
        />
      </Button>
    </>
  );
};

export default ChangeBillingPeriodStep;
