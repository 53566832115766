import React from 'react';
import type { ReactNode } from 'react';

type Props = {
  title: ReactNode;
};

const OuterPageMessage = ({ title }: Props) => (
  <div style={{ margin: 'auto' }} className="h4 text-center">
    {title}
  </div>
);

export default OuterPageMessage;
