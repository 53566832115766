import React, { PureComponent } from 'react';
import type { ReactNode } from 'react';
import classNames from 'classnames';

import SidebarNavHeader from './sidebar-nav-header/sidebar-nav-header';
import SidebarNavCollapse from './sidebar-nav-collapse/sidebar-nav-collapse';
import SidebarNavSection from './sidebar-nav-section/sidebar-nav-section';
import SidebarNavItem from './sidebar-nav-item/sidebar-nav-item';
import SidebarNavLogOutLink from './sidebar-nav-log-out-link/sidebar-nav-log-out-link';

import SidebarNavContext from './sidebar-nav-context';

import './sidebar-nav.scss';

type Props = {
  children: ReactNode;
  canBeExpanded: boolean;
};
type State = {
  expanded: boolean;
};

class SidebarNav extends PureComponent<Props, State> {
  static Header = SidebarNavHeader;

  static Collapse = SidebarNavCollapse;

  static Section = SidebarNavSection;

  static Item = SidebarNavItem;

  static LogOutLink = SidebarNavLogOutLink;

  static defaultProps = {
    canBeExpanded: true,
  };

  state = {
    expanded: false,
  };

  static getDerivedStateFromProps(props: Props) {
    const { canBeExpanded } = props;

    if (!canBeExpanded) {
      return { expanded: false };
    }

    return null;
  }

  render() {
    const { children, canBeExpanded } = this.props;
    const { expanded } = this.state;
    const navClasses = classNames(
      'sidebar-nav',
      { 'sidebar-nav_expanded': expanded && canBeExpanded },
    );

    return (
      <SidebarNavContext.Provider value={this.getContext()}>
        <nav className={navClasses}>
          {children}
        </nav>
      </SidebarNavContext.Provider>
    );
  }

  getContext() {
    const { expanded } = this.state;

    return {
      expanded,
      onToggle: this.onToggle,
      onSelect: this.onSelect,
    };
  }

  onToggle = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  onSelect = () => {
    const { expanded } = this.state;

    if (expanded) {
      this.onToggle();
    }
  };
}

export default SidebarNav;
