// Old family modals are deprecated and are going to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import Modal from 'components/shared/modal/modal';

import { showDangerNotification, showSuccessNotification } from 'state/notifier/notifier-reducer';
import { dropFamily } from 'state/family-plan/family-plan-actions';
import { getFamilyPlan } from 'state/root-reducer';
import DefaultError from '../../shared/default-error/default-error';

import './drop-family-modal.scss';


const mapStateToProps = (state) => ({
  isFamilyProcessing: getFamilyPlan(state).isLoading,
});

const mapActionsToProps = {
  dropFamily,
  showSuccessNotification,
  showDangerNotification,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = {
  show: boolean;
  onHide: () => void;
} & ConnectedProps<typeof connector>

class DropFamilyModal extends PureComponent<Props> {
  render() {
    const {
      show,
      onHide,
      isFamilyProcessing,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        title={
          <FormattedMessage
            id="dropFamilyModal.title"
            defaultMessage="Dismissing Family"
          />
        }
      >
        <div>
          <div className="text-center drop-family-modal__description">
            <FormattedMessage
              id="dropFamilyModal.description"
              defaultMessage="You are about to stop your Family plan in Setapp. You'll be switched to a regular plan."
            />
          </div>

          <Button
            variant="danger-outline"
            block
            onClick={this.handleSubmit}
            data-qa="dropFamilySubmit"
            disabled={isFamilyProcessing}
          >
            <FormattedMessage id="dropFamilyModal.submitButton" defaultMessage="Confirm & Switch" />
          </Button>

          <Button
            block
            onClick={onHide}
            className="mt-3"
          >
            <FormattedMessage id="dropFamilyModal.cancelButton" defaultMessage="Cancel" />
          </Button>
        </div>
      </Modal>
    );
  }

  handleSubmit = () => {
    const {
      dropFamily,
      showSuccessNotification,
      showDangerNotification,
      onHide,
    } = this.props;

    return dropFamily()
      .then(() => {
        onHide();
        showSuccessNotification(<FormattedMessage
          id="dropFamilyModal.succeedNotificationText"
          defaultMessage="Family plan dismissed successfully."
                                />);
      })
      .catch(() => {
        onHide();
        showDangerNotification(<DefaultError />);
      });
  };
}

export default connector(DropFamilyModal);
