import React from 'react';
import { FormattedMessage } from 'react-intl';

import BillIcon from './images/bill-icon.svg';

import './payment-history-empty-state.scss';

const PaymentHistoryEmptyState = () => (
  <div className="payment-history-empty-state" data-qa="emptyPaymentHistory">
    <img src={BillIcon} alt="" className="payment-history-empty-state__icon" width={20} height={20} />
    <FormattedMessage id="paymentHistory.emptyListMessage" defaultMessage="You have no recent payments" />
  </div>
);

export default PaymentHistoryEmptyState;
