import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import ButtonBack from 'components/shared/button-back/button-back';
import FormContainer from 'components/shared/form/form-container/form-container';
import validate from 'utils/auth-validation';
import RecoverPasswordForm from './recover-password-form/recover-password-form';


import './recover-password-step.scss';

export type RecoverPasswordFields = {
  email: string;
  captcha?: string;
}

type RecoverPasswordFieldsErrors = {
  email: ReactNode;
  captcha?: ReactNode;
}

type Props = {
  email: string;
  onBack: () => void;
  onSubmit: (fields: RecoverPasswordFields) => void;
};

const RecoverPasswordStep = (props: Props) => {
  const {
    email,
    onBack,
    onSubmit,
  } = props;

  const [isRequestConfirmed, setRequestConfirmed] = useState(false);

  // @ts-expect-error TS(2741): Property 'email' is missing in type 'Messages<stri... Remove this comment to see the full error message
  const validateForm = useCallback((fields: RecoverPasswordFields): RecoverPasswordFieldsErrors => validate(fields, {
    email: {
      required: <FormattedMessage id="oauth.recoverPassword.validation.emptyEmail" defaultMessage="Email is required" />,
      emailFormat: <FormattedMessage id="oauth.recoverPassword.validation.invalidEmail" defaultMessage="Invalid email" />,
    },
  }), []);

  const handleFormSubmit = useCallback(async (fields: RecoverPasswordFields) => {
    try {
      await onSubmit(fields);
      setRequestConfirmed(true);
    } catch (error) {
      setRequestConfirmed(false);
      throw error;
    }
  }, [onSubmit]);

  const formTitle = useMemo(
    () => (isRequestConfirmed
      ? <FormattedHTMLMessage id="oauth.recover-password.titleSuccess" defaultMessage="Sent!<br />Please check your email" />
      : <FormattedMessage id="oauth.recover-password.title" defaultMessage="Password recovery" />),
    [isRequestConfirmed]
  );

  const formSubTitle = useMemo(
    () => (isRequestConfirmed
      ? <FormattedHTMLMessage
        id="oauth.recover-password.subTitleSuccess"
        defaultMessage="The link to reset your password is on its way to <strong>{email}</strong>"
        values={{ email }}
        />
      : <FormattedMessage
        id="oauth.recover-password.subTitle"
        defaultMessage="Enter your email address and we’ll send a link to reset your password."
        />),
    [isRequestConfirmed, email]
  );

  return (
    <div>
      <div className="oauth-recover-password-step__back-button">
        <ButtonBack onClick={onBack} />
      </div>

      <h3 className="oauth-recover-password-step__title" data-qa="recoveryPasswordStepTitle">
        {formTitle}
      </h3>

      <p className="oauth-recover-password-step__sub-title">
        {formSubTitle}
      </p>

      {isRequestConfirmed ? (
        <Button
          block
          size="lg"
          onClick={onBack}
        >
          <FormattedMessage id="oauth.recover-password.signInBtnCaption" defaultMessage="Sign In" />
        </Button>
      ) : (
        <FormContainer
          initialValues={{ email }}
          onSubmit={handleFormSubmit}
          validate={validateForm}
        >
          {({
            fields: { email },
            fieldsErrors: { email: emailError },
            formError,
            isProcessing,
            onSubmit,
            onFieldChange,
            captcha,
          }) => <RecoverPasswordForm
            email={email}
            emailError={emailError}
            onEmailChange={onFieldChange}
            genericError={formError}
            isRequestProcessing={isProcessing}
            onFormSubmit={onSubmit}
            captcha={captcha}
                />}
        </FormContainer>
      )}
    </div>
  );
};

RecoverPasswordStep.defaultProps = {
  email: '',
};

export { RecoverPasswordStep as PureRecoverPasswordStep };

export default RecoverPasswordStep;
