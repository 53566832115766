// @flow

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Notification } from '@setapp/ui-kit';

import './card-info-notification.scss';

type Props = {|
  creditCardExpiration: {|
    isExpired: boolean,
    isAboutToExpire: boolean,
  |},
|};

class CardInfoNotification extends Component<Props> {
  render() {
    return (
      <div className="card-info-notification">
        {this.getNotification()}
      </div>
    );
  }

  getNotification() {
    const { creditCardExpiration } = this.props;
    const { isExpired, isAboutToExpire } = creditCardExpiration;

    if (isExpired) {
      return (
        <Notification type="danger">
          <FormattedMessage
            id="paymentDetails.notifications.cardExpired"
            defaultMessage="Your card has expired! To keep using Setapp, update your payment details."
          />
        </Notification>
      );
    }

    if (isAboutToExpire) {
      return (
        <Notification type="info">
          <FormattedMessage
            id="paymentDetails.notifications.cardExpiresInTwoWeeks"
            defaultMessage="Seems like your card will expire soon. Update your payment details to keep using Setapp."
          />
        </Notification>
      );
    }

    return null;
  }
}

export default CardInfoNotification;
