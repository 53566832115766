import React, { useEffect, useRef, useState } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLoading } from '@setapp/ui-kit';

type ButtonLoadingProps = ComponentProps<typeof ButtonLoading>;

type Props = Omit<ButtonLoadingProps, 'children' | 'isLoading' | 'title'> & {
  forceProcessing?: boolean;
  title?: ReactNode;
  onClick: () => Promise<void>;
};

// business logic guess to update loading text to keep user's focus
const FIRST_CHANGE_LOADING_TEXT_TIMEOUT = 5000;
const SECOND_CHANGE_LOADING_TEXT_TIMEOUT = 15000;

const PaymentButton = (props: Props) => {
  const {
    forceProcessing = false,
    title,
    onClick,
    ...buttonProps
  } = props;

  const buttonPropsWithDefault: Partial<ButtonLoadingProps> = {
    block: true,
    variant: 'primary',
    size: 'lg',
    ...buttonProps,
  };

  const initialLoadingText = (
    <FormattedMessage
      id="paymentButton.waitingForPaymentText.first"
      defaultMessage="Payment in progress…"
    />
  );

  // clear timeouts on unmount
  const firstChangeLoadingTextTimeoutIdRef = useRef<ReturnType<typeof setTimeout>>();
  const secondChangeLoadingTextTimeoutIdRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => () => {
    if (firstChangeLoadingTextTimeoutIdRef.current) {
      clearTimeout(firstChangeLoadingTextTimeoutIdRef.current);
    }
    if (secondChangeLoadingTextTimeoutIdRef.current) {
      clearTimeout(secondChangeLoadingTextTimeoutIdRef.current);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [disabled, setDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<ReactNode>(initialLoadingText);

  const startProcessing = () => {
    if (isLoading) {
      return;
    }

    setDisabled(true);
    setIsLoading(true);

    firstChangeLoadingTextTimeoutIdRef.current = setTimeout(() => {
      setLoadingText(
        <FormattedMessage
          id="paymentButton.waitingForPaymentText.second"
          defaultMessage="Almost there…"
        />
      );
    }, FIRST_CHANGE_LOADING_TEXT_TIMEOUT);

    secondChangeLoadingTextTimeoutIdRef.current = setTimeout(() => {
      setLoadingText(
        <FormattedMessage
          id="paymentButton.waitingForPaymentText.third"
          defaultMessage="A little more time…"
        />
      );
    }, SECOND_CHANGE_LOADING_TEXT_TIMEOUT);
  };

  const stopProcessing = () => {
    setDisabled(false);
    setIsLoading(false);
    setLoadingText(initialLoadingText);
  };

  // Force button to loading state without user interaction
  useEffect(() => {
    if (forceProcessing) {
      startProcessing();
    } else {
      stopProcessing();
    }
  }, [forceProcessing]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    startProcessing();
    onClick();
  };

  return (
    <ButtonLoading
      {...buttonPropsWithDefault}
      disabled={disabled}
      isLoading={isLoading}
      loadingText={loadingText}
      onClick={handleClick}
    >
      {title || (
        <FormattedMessage
          id="paymentButton.title"
          defaultMessage="Pay now"
        />
      )}
    </ButtonLoading>
  );
};

export default PaymentButton;
