import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@setapp/ui-kit';

import urls from 'config/urls';

const ChoosePricePlan: FC = () => (
  <div className="mt-10" data-testid="choosePricePlan">
    <h5>
      <FormattedMessage
        id="subscriptionInfo.changePricePlan.title"
        defaultMessage="Choose your Setapp plan"
      />
    </h5>
    <p className="mb-8">
      <FormattedMessage
        id="subscriptionInfo.changePricePlan.description"
        defaultMessage="Enjoying Setapp? Pick the best plan for your needs now! Your subscription will begin after the free trial."
      />
    </p>
    <Button
      variant="primary"
      href={urls.activateSubscription}
      className="mr-3"
    >
      <FormattedMessage
        id="subscriptionInfo.changePricePlan.selectPlanBtn"
        defaultMessage="Select a plan"
      />
    </Button>
    <Button
      variant="secondary-outline"
      href={urls.cancelSubscription}
    >
      <FormattedMessage
        id="subscriptionInfo.changePricePlan.cancelTrialBtn"
        defaultMessage="Cancel trial"
      />
    </Button>
  </div>
);

export default ChoosePricePlan;
