import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Button } from '@setapp/ui-kit';

import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';

import type { FamilyMember } from 'state/family-plan/family-plan-initial-state';
import * as statuses from 'state/family-plan/family-member-statuses';

import './member-item.scss';

type Props = {
  member: FamilyMember;
  isFamilyProcessing: boolean;
  onRemoveMemberClick: (member: FamilyMember) => void;
  onRevokeInviteClick: (member: FamilyMember) => Promise<void>;
  onResendInviteClick: (member: FamilyMember) => Promise<void>;
};

const MemberItem = ({
  member, isFamilyProcessing, onResendInviteClick, onRevokeInviteClick, onRemoveMemberClick,
}: Props) => {
  const {
    status, email, name, avatarUrl,
  } = member;
  const title = name || email;
  const statusTitles = {
    [statuses.ACTIVE]: <FormattedMessage id="familyMemberItem.status.active" defaultMessage="Active" />,
    [statuses.PENDING]: <FormattedMessage id="familyMemberItem.status.pending" defaultMessage="Invite sent" />,
  };
  const statusClasses = classnames('family-member-item__status', {
    'family-member-item__status_light': status === statuses.PENDING,
  });

  return (
    <div className="family-member-item" data-qa="familyMemberItem">
      {avatarUrl ? (
        <img
          src={avatarUrl}
          className="family-member-item__avatar"
          alt=""
        />
      ) : (
        <div className="family-member-item__avatar">
          <span className="h5">{title.slice(0, 1).toUpperCase()}</span>
        </div>
      )}
      <div className="family-member-item__content">
        <div className="text_lg family-member-item__title" data-qa="familyMemberItemTitle"><strong>{title}</strong></div>
        <div className={statusClasses}>{statusTitles[status]}</div>
        {status === statuses.ACTIVE ? (
          <AccountControlButtons>
            <Button
              variant="danger-outline"
              size="sm"
              disabled={isFamilyProcessing}
              onClick={() => onRemoveMemberClick(member)}
              data-qa="removeMemberButton"
            >
              <FormattedMessage id="familyMemberItem.removeMemberButton" defaultMessage="Remove" />
            </Button>
          </AccountControlButtons>
        ) : (
          <AccountControlButtons>
            <Button
              className="family-member-item__btn"
              variant="secondary-outline"
              size="sm"
              disabled={isFamilyProcessing}
              onClick={() => onResendInviteClick(member)}
              data-qa="resendInviteButton"
            >
              <FormattedMessage id="familyMemberItem.resendInviteStatus" defaultMessage="Resend invite" />
            </Button>
            <Button
              className="family-member-item__btn"
              variant="danger-outline"
              size="sm"
              disabled={isFamilyProcessing}
              onClick={() => onRevokeInviteClick(member)}
              data-qa="revokeInviteButton"
            >
              <FormattedMessage id="familyMemberItem.revokeInviteButton" defaultMessage="Revoke invite" />
            </Button>
          </AccountControlButtons>
        )}
      </div>
    </div>
  );
};

export default MemberItem;
