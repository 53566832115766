import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { showDangerNotification } from 'state/notifier/notifier-reducer';
import { fetchPaymentMethod } from 'state/payment-method/payment-method-actions';
import {
  getPrimarySubscription,
  isPaymentMethodCreated as isPaymentMethodCreatedSelector,
  isPaymentMethodFetched as isPaymentMethodFetchedSelector,
} from 'state/root-reducer';
import { fetchAllSubscriptions } from 'state/subscription/subscription-actions';

import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';

import DefaultError from 'components/shared/default-error/default-error';

const useFetchDataForPaymentRetry = () => {
  const [isLoading, setIsLoading] = useState(true);

  const isPaymentMethodCreated = useSelector(isPaymentMethodCreatedSelector);
  const isPaymentMethodFetched = useSelector(isPaymentMethodFetchedSelector);
  const primarySubscription = useSelector(getPrimarySubscription);

  const fetchPaymentMethodAction = useDispatchAction(fetchPaymentMethod);
  const fetchSubscriptionsAction = useDispatchAction(fetchAllSubscriptions);
  const showDangerNotificationAction = useDispatchAction(showDangerNotification);

  useEffect(() => {
    const dataToBeFetched = [
      ...(!isPaymentMethodFetched ? [fetchPaymentMethodAction] : []),
      ...(!primarySubscription ? [fetchSubscriptionsAction] : []),
    ];

    Promise.all(dataToBeFetched.map((action) => action()))
      .catch(() => {
        showDangerNotificationAction(<DefaultError />);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    isLoading,
    data: {
      isPaymentMethodCreated,
      primarySubscription: primarySubscription!,
    },
  };
};

export default useFetchDataForPaymentRetry;
