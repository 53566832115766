import queryString from 'query-string';
import { GOOGLE_AUTH_SCOPE, GOOGLE_CLIENT_ID, GOOGLE_REDIRECT_URL } from 'config/social';
import BaseSocialClient from './base-social-client';

import type { SocialClientInterface, SocialProfile } from './social-client-interface';

export class GoogleClient extends BaseSocialClient implements SocialClientInterface {
  fetchUserProfile(): Promise<SocialProfile> {
    const profileApiUrl = 'https://www.googleapis.com/oauth2/v3/userinfo';
    const options = { method: 'GET' };

    return fetch(`${profileApiUrl}?${queryString.stringify({ 'access_token': this.userAccessToken })}`, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Can\'t fetch user profile.');
        }

        return response.json();
      });
  }

  getAuthURL(state?: string): string {
    const oAuthURL = 'https://accounts.google.com/o/oauth2/v2/auth';
    const parameters = queryString.stringify({
      'client_id': GOOGLE_CLIENT_ID,
      'redirect_uri': GOOGLE_REDIRECT_URL,
      scope: GOOGLE_AUTH_SCOPE,
      'response_type': 'token',
      state,
    });

    return `${oAuthURL}?${parameters}`;
  }

  isAuthRedirect(): boolean {
    const { 'access_token': token } = queryString.parse(window.location.hash);

    return Boolean(token);
  }

  async processAuthRedirect() {
    const { 'access_token': accessToken } = queryString.parse(window.location.hash);

    this.setUserAccessToken(accessToken as string);

    return { accessToken: accessToken as string };
  }

  getUrlState() {
    const { state } = queryString.parse(window.location.hash);

    return state || '';
  }
}

const googleClient = new GoogleClient();
export default googleClient;
