// Device management is deprecated functionality to be removed soon
// so no need have unit tests for this file

// istanbul ignore file

import React, { Component } from 'react';
import type { ReactElement, ChangeEvent, FormEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import type { DeviceSeat } from 'services/devices-to-seats-mapper/devices-to-seats-mapper';
import * as subscriptionsListService from 'services/subscriptions/subscriptions-list';
import SelectSeatsForm from './select-seats-form';

type Props = {
  deviceSeats: Array<DeviceSeat>;
  onConfirm: (deviceSeats: Array<DeviceSeat>) => void;
}

type State = {
  selectedSeats: Array<string>;
  formError?: ReactElement;
}

class SelectSeatsScreen extends Component<Props, State> {
  state: State = {
    selectedSeats: [],
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    // Reset selected seats if there are no such ids in the new deviceSeats list
    const actualSelectedSeats = SelectSeatsScreen.getActualSelectedSeats(props.deviceSeats, state.selectedSeats);

    return {
      selectedSeats: actualSelectedSeats,
    };
  }

  static getActualSelectedSeats(devicesSeats: Array<DeviceSeat>, selectedSeats: Array<string>) {
    if (selectedSeats.length === 0) {
      return [];
    }

    // Yes, it's n^2 :( Shouldn't be a big issue because there are not too much seats available in a single account
    return selectedSeats.reduce<string[]>((actualSeatsIds, seatId) => {
      // The selected seat id is considered actual if the devicesSeats list contains a seat with a such id
      const isSelectedSeatActual = devicesSeats.some((deviceSeat) => deviceSeat.id === seatId);

      if (!isSelectedSeatActual) {
        return actualSeatsIds;
      }

      return [
        ...actualSeatsIds,
        seatId,
      ];
    }, []);
  }

  render() {
    const { deviceSeats } = this.props;
    const { selectedSeats, formError } = this.state;

    const seatsSubscriptions = deviceSeats.map((seat) => seat.subscription);
    const additionalSeatsCount = subscriptionsListService.getExtraSeatsCount(seatsSubscriptions);
    const canSelectSeats = selectedSeats.length < additionalSeatsCount;

    const additionalSubscriptions = subscriptionsListService.getAdditionalSeats(seatsSubscriptions);

    if (additionalSubscriptions.length === 0) {
      throw new Error('No additional seats to cancel');
    }

    const [firstAdditionalSubscription] = additionalSubscriptions;
    /**
     * nextPaymentDate can be null for a free subscription
     * I hope that there won't be such a situation but leave a primitive fallback to the zero date.
     */
    const nextSeatsPaymentDate = firstAdditionalSubscription!.nextPaymentDate ?? 0;

    return (
      <SelectSeatsForm
        deviceSeats={deviceSeats}
        nextSeatsPaymentDate={nextSeatsPaymentDate}
        selectedSeats={selectedSeats}
        canSelectSeats={canSelectSeats}
        formError={formError}
        onSeatCheckboxChange={this.handleSeatCheckboxChange}
        onSubmit={this.handleSeatsFormSubmit}
      />
    );
  }

  handleSeatCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { deviceSeats } = this.props;
    const { checked, value: changedSeatId } = event.currentTarget;

    const changedDeviceSeat = deviceSeats.find((deviceSeat) => deviceSeat.id === changedSeatId);

    if (!changedDeviceSeat) {
      throw new Error('Selected device seat does not exists in the seats lists');
    }

    this.setState(({
      selectedSeats,
    }) => {
      const newSelectedSeats = new Set(selectedSeats);

      if (checked) {
        newSelectedSeats.add(changedSeatId);
      } else {
        newSelectedSeats.delete(changedSeatId);
      }

      return {
        selectedSeats: Array.from(newSelectedSeats),
        formError: undefined,
      };
    });
  }

  handleSeatsFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    const { selectedSeats } = this.state;

    if (selectedSeats.length === 0) {
      const formError = <FormattedMessage
        id="cancelAdditionalSeats.noSelectedSeatsError"
        defaultMessage="Please select device to remove"
                        />;

      this.setState({ formError });

      return;
    }

    const { onConfirm, deviceSeats } = this.props;
    const seatsToCancel = deviceSeats.filter((seat) => selectedSeats.includes(seat.id));

    onConfirm(seatsToCancel);
  }
}

export default SelectSeatsScreen;
