import type { ReactElement } from 'react';

export type Notification = {
  id: string;
  message: ReactElement | string;
  type: 'info' | 'warning' | 'danger' | 'success';
  permanent?: boolean;
  // show close button in notification
  hasCloseButton?: boolean;
  // dont hide the notification automatically
  delay?: number;
  withIcon?: boolean;
};

export type NotifierState = {
  list: Array<Notification>;
}

const initialState: NotifierState = {
  list: [],
};

export default initialState;
