import React, { FC } from 'react';
import { FormattedMessage, injectIntl, type InjectedIntlProps,  } from 'react-intl';
import { AnimatedLogo, Button } from '@setapp/ui-kit';

import { FormattedPrice } from 'components/shared/formatter/formatter';
import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import useGetPlanForUpgrade from 'components/hooks/use-upgrade-plan-setapp-mobile/use-upgrade-plan-setapp-mobile';
import { setIsInsideSetappMobileFlow } from 'components/pages/setapp-mobile/utils/setapp-mobile-flow-storage';
import { storeCustomerOauthError } from 'components/pages/customer-oauth-page/utils/customer-oauth-storage';

import { showModal } from 'state/modal/modal-reducer';

import getPricePlanMetadata from 'services/price-plans/price-plan-metadata-mapper';
import { isIosAdvancedPricePlan } from 'services/price-plans/utils';

import './setapp-mobile-upgrade-card.scss';

interface SetappMobileUpgradeCardProps extends InjectedIntlProps {
  isCustomerOauthVariant?: boolean;
}

const SetappMobileUpgradeCard: FC<SetappMobileUpgradeCardProps> = ({ isCustomerOauthVariant }) => {
  const showModalAction = useDispatchAction(showModal);

  const { currentPricePlan, planForUpgrade } = useGetPlanForUpgrade();

  const handleSwitchPlan = () => {
    if (isCustomerOauthVariant) {
      storeCustomerOauthError('OFFER_UPGRADE');
    } else {
      setIsInsideSetappMobileFlow();
    }
    showModalAction('MANAGE_SUBSCRIPTION', { selectedStep: 'change_to_selected_plan', selectedNextPlan: planForUpgrade });
  };

  const handleViewPlans = () => {
    if (isCustomerOauthVariant) {
      storeCustomerOauthError('OFFER_UPGRADE');
    } else {
      setIsInsideSetappMobileFlow();
    }
    showModalAction('MANAGE_SUBSCRIPTION', { selectedStep: 'manage_plan' });
  };

  if (!planForUpgrade || !currentPricePlan) {
    return <div className="upgrade-card__logo"><AnimatedLogo animate /></div>;
  }

  const planForUpgradeMetadata = getPricePlanMetadata(planForUpgrade);
  const currentPlanMetadata = getPricePlanMetadata(currentPricePlan);

  const defaultDescription = (
    <FormattedMessage
      id="setappMobilePage.upgradeDescription"
      defaultMessage="Your {currentPlan} doesn’t include Setapp Mobile. Upgrade to {upgradePlan} or choose another plan."
      values={{
        currentPlan: <span className="text_weight-bold">{currentPlanMetadata.titleFull}</span>,
        upgradePlan: <span className="text_weight-bold">{planForUpgradeMetadata.titleFull}</span>,
      }}
    />
  );

  const descriptionForBetaEnd = (
    <FormattedMessage
      id="setappMobilePage.upgradeDescription.betaEnd"
      defaultMessage="Free beta access to Setapp Mobile has ended for your {currentPlan}. Upgrade to {upgradePlan} or choose another plan."
      values={{
        currentPlan: <span className="text_weight-bold">{currentPlanMetadata.titleFull}</span>,
        upgradePlan: <span className="text_weight-bold">{planForUpgradeMetadata.titleFull}</span>,
      }}
    />
  );

  const description = isCustomerOauthVariant ? descriptionForBetaEnd : defaultDescription;

  const iosDevicesMessage = isIosAdvancedPricePlan(planForUpgrade.tierType) ? (
    <FormattedMessage
      id="setappMobilePage.upgradeCard.advancedIos"
      defaultMessage="For {iosNumber} {iosNumber, plural, one {iPhone} other {iPhones}}"
      values={{ iosNumber: planForUpgrade.features?.iosSeatsCount }}
    />
  ) : (
    <FormattedMessage
      id="setappMobilePage.upgradeCard.iosNumbers"
      defaultMessage="For {iosNumber} iOS {iosNumber, plural, one {device} other {devices}}"
      values={{ iosNumber: planForUpgrade.features?.iosSeatsCount }}
    />
  );

  const priceFormatted = <FormattedPrice price={planForUpgrade.price} currency={planForUpgrade.currency} />;

  const price = planForUpgrade.paidMonth === 12 ? (
    <FormattedMessage
      id="setappMobilePage.upgradeCard.infoAnnual"
      defaultMessage="{price}/year"
      values={{ price: priceFormatted }}
    />
  ) : (
    <FormattedMessage
      id="setappMobilePage.upgradeCard.info"
      defaultMessage="{price}/mo"
      values={{ price: priceFormatted }}
    />
  );

  return (
    <>
      <p className="mt-3 mb-6 upgrade-card__description">{description}</p>
      <div className="upgrade-card__price-plan">
        <div className="upgrade-card__plan-header">
          <p className="upgrade-card__plan-title">{planForUpgradeMetadata.titleFull}</p>
          <span className="upgrade-card__plan-right">
            {price}
          </span>
        </div>
        <ul className="upgrade-card__list mt-3">
          {Boolean(planForUpgrade.features?.vendorApiUsageLimitInCredits) && (
            <li className="upgrade-card__list-item">
              <FormattedMessage
                id="setappMobilePage.upgradeCard.apiCredits"
                defaultMessage="{apiCredits} AI credits"
                values={{ apiCredits: planForUpgrade.features?.vendorApiUsageLimitInCredits }}
              />
            </li>
          )}
          {Boolean(planForUpgrade.features?.macSeatsCount) && (
            <li className="upgrade-card__list-item">
              <FormattedMessage
                id="setappMobilePage.upgradeCard.macNumbers"
                defaultMessage="For {macNumber} {macNumber, plural, one {Mac} other {Macs}}"
                values={{ macNumber: planForUpgrade.features?.macSeatsCount }}
              />
            </li>
          )}
          {Boolean(planForUpgrade.features?.iosSeatsCount) && (
            <li className="upgrade-card__list-item">
              {iosDevicesMessage}
            </li>
          )}
          <li className="upgrade-card__list-item">
            <FormattedMessage
              id="setappMobilePage.upgradeCard.access"
              defaultMessage="Access to Setapp Mobile"
            />
          </li>
        </ul>
        <Button block size="lg" className="mt-5" onClick={handleSwitchPlan}>
          <FormattedMessage
            id="setappMobilePage.upgradeCard.switch"
            defaultMessage="Switch to {title}"
            values={{ title: planForUpgradeMetadata.title }}
          />
        </Button>
        <Button variant="primary-outline" block size="lg" className="mt-3" onClick={handleViewPlans}>
          <FormattedMessage id="setappMobilePage.upgradeCard.viewPlans" defaultMessage="View other plans" />
        </Button>
      </div>
    </>
  );
};

export default injectIntl(SetappMobileUpgradeCard);
