import type { GiftCard } from 'state/gift-cards/gift-cards-reducer';

export type RedeemCode = {
  giftCard?: GiftCard;
  promoCode?: {
    code: string;
  };
  isLoading: boolean;
};

export type RedeemCodeState = RedeemCode;

const initialState: RedeemCodeState = {
  isLoading: false,
};

export default initialState;
