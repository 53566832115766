import React, { FC, PropsWithChildren, createContext, useContext, useState, useEffect } from 'react';

import { isAfter } from 'date-fns';
import { type Subscription } from 'state/subscription/subscription-initial-state';
import { type PricePlan } from 'state/price-plans/price-plans-initial-state';
import { type User } from 'state/user/user-initial-state';

import { AI_MARATHON_1_END_DATE, AI_MARATHON_1_START_DATE } from 'components/pages/ai-offer/config';
import useAiOfferPlansConfig from '../hooks/use-ai-offer-plans-config';

type PlansPeriod = 1 | 12;

interface AiOfferContextValue {
  plansConfig: ReturnType<typeof useAiOfferPlansConfig>;
  selectedPlansPeriod: PlansPeriod;
  shouldShowMonthlyPlans: boolean;
  toggleSelectedPlansPeriod: () => void;
  isAiSpecialOfferPage: boolean;
  isCountdownVisible: boolean;
  hideCountdown: () => void;
  showCountdown: () => void;
}

const initialValue: Partial<AiOfferContextValue> = {
  plansConfig: [],
  selectedPlansPeriod: 12,
  shouldShowMonthlyPlans: true,
  toggleSelectedPlansPeriod: () => {},
  isAiSpecialOfferPage: false,
  isCountdownVisible: false,
  hideCountdown: () => {},
  showCountdown: () => {},
};

const AiOfferContext = createContext<AiOfferContextValue>(initialValue as AiOfferContextValue);

interface ProviderProps {
  availablePricePlans: PricePlan[];
  primarySubscription: Subscription | undefined;
  user: User;
  isAiSpecialOfferPage: boolean;
}

export const AiOfferProvider: FC<PropsWithChildren<ProviderProps>> = ({
  children,
  user,
  availablePricePlans,
  primarySubscription,
  isAiSpecialOfferPage,
}) => {
  const [selectedPlansPeriod, setSelectedPlansPeriod] = useState<PlansPeriod>(12);
  const [isCountdownVisible, setIsCountdownVisible] = useState<boolean>(false);

  const toggleSelectedPlansPeriod = () => {
    setSelectedPlansPeriod(selectedPlansPeriod === 1 ? 12 : 1);
  };

  const showCountdown = () => {
    setIsCountdownVisible(true);
  };

  const hideCountdown = () => {
    setIsCountdownVisible(false);
  };

  const shouldShowMonthlyPlans = primarySubscription?.pricePlan.paidMonth === 1;

  const plansConfig = useAiOfferPlansConfig({
    user,
    primarySubscription,
    selectedPlansPeriod,
    shouldShowMonthlyPlans,
    pricePlans: availablePricePlans,
    isAiSpecialOfferPage,
  });

  const contextValue = {
    plansConfig,
    selectedPlansPeriod,
    shouldShowMonthlyPlans,
    toggleSelectedPlansPeriod,
    isAiSpecialOfferPage,
    isCountdownVisible,
    hideCountdown,
    showCountdown,
  };

  const updateIsCountdownVisible = () => {
    const isEventEnded = isAfter(new Date(), AI_MARATHON_1_END_DATE);

    if (isEventEnded) {
      hideCountdown();

      return;
    }

    const isEventStarted = isAfter(new Date(), AI_MARATHON_1_START_DATE);

    if (isEventStarted) {
      showCountdown();
    }
  };

  useEffect(() => {
    updateIsCountdownVisible();
    const interval = setInterval(updateIsCountdownVisible, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AiOfferContext.Provider value={contextValue}>
      {children}
    </AiOfferContext.Provider>
  );
};

export const useAiOfferContext = () => {
  const context = useContext(AiOfferContext);

  if (context === undefined) {
    throw new Error('useAiOfferContext must be used within a AiOfferProvider');
  }

  return context;
};
