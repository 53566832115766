import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '@setapp/ui-kit';

import useDispatchAction from 'components/hooks/use-dispatch-action/use-dispatch-action';
import AccountControlButtons from 'components/shared/account-control-buttons/account-control-buttons';
import DropFamilySection from 'components/shared/drop-family/drop-family-section/drop-family-section';

import urls from 'config/urls';

import { showModal } from 'state/modal/modal-reducer';
import { getFamilyPlan } from 'state/root-reducer';

import analytics, { events } from 'utils/analytics';

import { useSubscriptionContext } from '../../context/subscription-context';
import useOpenChangePlanModal from '../../hooks/use-open-change-plan-modal';
import useOpenManageSubscriptionModal from '../../hooks/use-open-manage-subscription-modal';
import useSubscriptionActionsVisibility from '../../hooks/use-subscription-actions-visibility';
import useSubscriptionCtaType from '../../hooks/use-subscription-cta-type';
import useSuspendedActivationHandler from '../../hooks/use-suspended-activation-handler';

import TrialUnknownAction from './trial-unknown-action/trial-unknown-action';
import ActivateSubscriptionNow from './activate-subscription-now/activate-subscription-now';
import ChoosePricePlan from './choose-price-plan/choose-price-plan';
import FineTunePricePlan from './fine-tune-price-plan/fine-tune-price-plan';
import SuspendedSubscriptionAction from './suspended-subscription-action/suspended-subscription-action';
import ChoosePricePlanAfterTrial from './choose-price-plan-after-trial/choose-price-plan-after-trial';
import ActivateSubscriptionTrial from './activate-subscription-trial/activate-subscription-trial';
import RenewSubscriptionAfterCancel from './renew-subscription-after-cancel/renew-subscription-after-cancel';

const SubscriptionAction: FC = () => {
  const { hasPriceFeatures } = useSubscriptionContext();
  const {
    showManageSubscription,
    showChangePlan,
    showCancel,
    showLeaveFamily,
    showDropFamily,
  } = useSubscriptionActionsVisibility();

  const handleSuspendedUserActivation = useSuspendedActivationHandler();
  const handleManageSubscriptionClick = useOpenManageSubscriptionModal();
  const handleChangePlanClick = useOpenChangePlanModal();

  const callToActionType = useSubscriptionCtaType();

  const familyPlan = useSelector(getFamilyPlan);

  const showModalAction = useDispatchAction(showModal);

  const handleLeaveFamilyClick = () => {
    analytics.trackEvent(events.FAMILY_PLAN_LEAVE_CLICK);

    showModalAction(hasPriceFeatures ? 'FAMILY_MEMBER_SWITCH_OWN_PLAN' : 'LEAVE_FAMILY');
  };

  const handleDropFamilyClick = () => {
    showModalAction('DROP_FAMILY');

    analytics.trackEvent(events.FAMILY_PLAN_DROP_CLICK, {
      eventLabel: 'Subscription page',
    });
  };

  const renderCallToActionSection = () => {
    switch (callToActionType) {
      case 'trialUnknown':
        return <TrialUnknownAction />;
      case 'choosePricePlan':
        return <ChoosePricePlan />;
      case 'choosePricePlanAfterTrial':
        return <ChoosePricePlanAfterTrial />;
      case 'fineTunePricePlan':
        return <FineTunePricePlan />;
      case 'activateNow':
        return <ActivateSubscriptionNow />;
      case 'activateTrial':
        return <ActivateSubscriptionTrial />;
      case 'suspendedUserActivation':
        return <SuspendedSubscriptionAction onActivation={handleSuspendedUserActivation} />;
      case 'renewSubscriptionAfterCancel':
        return <RenewSubscriptionAfterCancel />;
      default:
        return null;
    }
  };

  return (
    <div>
      <AccountControlButtons>
        {showManageSubscription && (
          <Button block={isMobile} size="lg" onClick={() => handleManageSubscriptionClick()} variant="primary">
            <FormattedMessage id="pricePlans.manageSubscriptionBtn" defaultMessage="Manage subscription" />
          </Button>
        )}

        {showChangePlan && (
          <Button
            onClick={handleChangePlanClick}
            variant="secondary-outline"
            className="mr-3 mb-3"
            data-qa="changePlanBtn"
          >
            <FormattedMessage id="pricePlans.changePlanBtn" defaultMessage="Change plan" />
          </Button>
        )}

        {showCancel && (
          <Button href={urls.cancelSubscription} variant="secondary-outline" data-qa="cancelSubscriptionBtn">
            <FormattedMessage id="subscriptionInfo.btnCancel" defaultMessage="Cancel subscription" />
          </Button>
        )}

        {showLeaveFamily && (
          <Button variant="secondary-outline" disabled={familyPlan.isLoading} onClick={handleLeaveFamilyClick}>
            {hasPriceFeatures ? (
              <FormattedMessage id="leaveFamilySection.newFamily.button" defaultMessage="Switch to own plan" />
            ) : (
              <FormattedMessage id="leaveFamilySection.button" defaultMessage="Leave Family" />
            )}
          </Button>
        )}
      </AccountControlButtons>

      {renderCallToActionSection()}

      {showDropFamily && (
        <div className="mt-10">
          <DropFamilySection familyPricePlan={familyPlan.price} onDropFamilyClick={handleDropFamilyClick} />
        </div>
      )}
    </div>
  );
};

export default SubscriptionAction;
