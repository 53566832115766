import React, { useState } from 'react';
import { Button } from '@setapp/ui-kit';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import RequestError from '@setapp/request-error';

import { updateSetappPaymentDetails, createSetappPaymentDetails } from 'state/payment-method/payment-method-actions';
import type { AddSetappPaymentDetailsStrategy } from 'services/payment-details-api/setapp/setapp-payment-details-api';
import { getPaymentMethod } from 'state/root-reducer';

const mapStateToProps = (state) => ({
  paymentMethod: getPaymentMethod(state),
});

const mapActionsToProps = {
  updatePaymentDetails: updateSetappPaymentDetails,
  createPaymentDetails: createSetappPaymentDetails,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type Props = ConnectedProps<typeof connector> & {
  onSuccessSubmit?: () => unknown;
};

const SetappFakePaymentForm = ({
  paymentMethod,
  updatePaymentDetails,
  createPaymentDetails,
  onSuccessSubmit,
}: Props) => {
  const [formError, setFormError] = useState('');
  const handleClick = async (strategy: AddSetappPaymentDetailsStrategy) => {
    try {
      if (paymentMethod.data) {
        await updatePaymentDetails({ strategy });
      } else {
        await createPaymentDetails({ strategy });
      }

      onSuccessSubmit?.();
    } catch (error) {
      const [firstError] = (error as typeof RequestError).getAll();

      setFormError(firstError.message);
    }
  };

  const handleSuccessClick = () => (
    handleClick('success')
  );

  const handleFailClick = () => (
    handleClick('fail')
  );

  return (
    <div>
      <Button block onClick={handleSuccessClick} data-qa="submitPaymentDetailsSuccess">
        Success
      </Button>
      <Button block onClick={handleFailClick} data-qa="submitPaymentDetailsFail" className="mt-5">
        Fail
      </Button>
      {formError && (
        <div className="mt-3 text-danger">
          {formError}
        </div>
      )}
    </div>
  );
};

export default connector(SetappFakePaymentForm);
